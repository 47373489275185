<div *ngIf="showSpinner"
     class="d-flex justify-content-center align-items-center w-100 h-100 position-fixed bg-dark"
     style="z-index: 11; opacity: 0.6;">
  <mat-spinner></mat-spinner>
</div>

<div class="p-3 d-flex justify-content-between sticky-head" style="flex-flow: wrap;">
  <div class="mt-2">
    <button class="border-radius-20" mat-stroked-button (click)="navbarService.createPRVideo()">
      <mat-icon class="material-icons-outlined mr-1">
        add
      </mat-icon>
      {{'New Clypp'|translate}}
    </button>
  </div>

  <div>
    <button (click)="massClyppUpload()"
            [disabled]="!is_creator"
            matTooltipPosition="left"
            [matTooltip]="is_creator ? '' : ('You need Creator rights' | translate)"
            class="mr-2 text-nunito" mat-button>
      <mat-icon class="material-icons-outlined">file_upload</mat-icon>
      {{'Bulk upload'|translate}}
    </button>

    <mat-form-field appearance="outline" class="mr-3">
      <mat-label translate>Search for…</mat-label>
      <input #input (keyup)="applyFilter();" [(ngModel)]="searchQuery" autocomplete="off" matInput
        placeholder="{{'Titel' | translate}}">
    </mat-form-field>

    <mat-form-field appearance="outline" class="mr-3">
      <mat-label translate>Sort</mat-label>
      <mat-select (selectionChange)="applyFilter()" [(ngModel)]="sort_option" value="last_edited_on">
        <mat-option value="added_on">{{'Newest'|translate}}</mat-option>
        <mat-option value="last_edited_on">{{'Last edited'|translate}}</mat-option>
        <mat-option value="views">{{'Ansichten'|translate}}</mat-option>
        <mat-option value="duration">{{'Longest'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label translate>Filter</mat-label>
      <mat-select (selectionChange)="applyFilter();" [(ngModel)]="filter_option" value="all">
        <mat-option value="all"><span translate>Alle</span></mat-option>
        <mat-option value="drafts"><span translate>Drafts</span></mat-option>
        <mat-option value="published"><span translate>Veröffentlicht</span></mat-option>
        <mat-option value="external"><span translate>External</span></mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<!--In case of no clypps, show text-->
<mat-card-subtitle class="mx-2 text-nunito"
                   *ngIf="all_videos.length == 0"
                   translate>
  You have not created a Clypp yet
</mat-card-subtitle>

<!--all clypps-->
<mat-grid-list cols="1" gutterSize="1rem" rowHeight="160">
  <mat-grid-tile *ngFor="let video of filteredVideos" class="videoCard-tile">
    <mat-card class="video-card w-100 bg-transparent inherit-height d-flex justify-content-between">
      <mat-card-header>
        <!-- image part -->
        <div [style.background-image]="'url(' + video.thumbnail + ')'"
             class="imageContainer mr-3 d-none d-md-flex">
          <!--edit overlay-->
          <div class="edit-video-div cursor-pointer" (click)="editVideo(video)">
            <span class="d-flex align-items-center text-nunito" style="color:white" translate>
              <mat-icon class="mr-1 material-icons-outlined">edit
              </mat-icon>
              Bearbeiten
            </span>
          </div>
          <!--duration-->
          <div *ngIf="video.duration" class="video-duration px-1">
            {{ video.duration | vidDurationFormat }}
          </div>

          <div *ngIf="video.state == 'PR'" class="text-nunito text-gray loading-overlay">
            <div class="text-nunito text-gray overlay-content">
              <mat-spinner [diameter]="40"></mat-spinner>
              <span class="d-none d-lg-block" style="padding-top: -1rem" translate>
                Please refresh the page after some time
              </span>
            </div>
          </div>
        </div>

        <!-- text part -->
        <div class="ml-2 mt-2" style="overflow: hidden;">
          <mat-card-title [title]="video.title"
                          (click)="viewVideo(video)"
                          class="card-title text-nunito cursor-pointer"
                          style="font-size: 15px;">
            {{video.title}}
          </mat-card-title>
          <mat-card-subtitle class="text-nunito">
            <span matTooltipPosition="right"
                  matTooltip="{{'Erstellt am'|translate}}&nbsp;{{video.added_on | date:'dd.MM.y'}}">
              {{'Updated' | translate}}
              {{utilityService.timeSince(video.last_edited_on, "ago", false) |async}}
            </span>
          </mat-card-subtitle>

          <div class="text-nunito text-gray">
            <mat-icon class="mr-1 material-icons-outlined" matTooltip="{{ 'Ansichten' | translate }}"
              style="vertical-align: middle">
              visibility
            </mat-icon>
            {{ video.views }}&nbsp;

            <mat-icon class="mr-1 material-icons-outlined" matTooltip="{{ 'Marked as helpful' | translate }}"
              style="vertical-align: middle">
              thumb_up
            </mat-icon>
            {{ video.up_votes }}&nbsp;

            <mat-icon *ngIf="video.is_external && !video.is_access_key_needed" class="mr-1"
              matTooltip="{{'Public'| translate}}" style="vertical-align: middle">
              public
            </mat-icon>

            <mat-icon *ngIf="video.is_external && video.is_access_key_needed" class="mr-1"
              matTooltip="{{'Unlisted'| translate}}" style="vertical-align: middle">
              vpn_lock
            </mat-icon>

            <mat-icon *ngIf="video.is_held_for_review" class="mr-1" matTooltip="{{ 'Wird überprüft' | translate }}"
              style="vertical-align: middle">
              pending_actions
            </mat-icon>

            <mat-icon *ngIf="video.is_restricted" class="mr-1"
              matTooltip="{{'Restricted visibility inside the workspace'| translate}}" style="vertical-align: middle">
              network_locked
            </mat-icon>

            <button (click)="transcriptPopup(video);" *ngIf="video.vtt_file" mat-icon-button>
              <mat-icon class="material-icons-outlined" matTooltip="{{ 'Transkript ändern' | translate }}">
                closed_caption
              </mat-icon>
            </button>

            <button (click)="savePassword(video.access_key)" *ngIf="video.access_key" mat-icon-button>
              <!--access key may be empty, only show this if there is only-->
              <mat-icon class="material-icons-outlined" matTooltip="{{'Click to copy code'| translate}}">
                vpn_key
              </mat-icon>
            </button>
          </div>
        </div>
      </mat-card-header>

      <mat-card-content class="d-flex flex-wrap align-items-center justify-content-end"
                        style="max-width: 50%; min-width: 150px;">
        <span *ngIf="video.state == 'FA'" class="badge mx-2 text-nunito font-medium"
          style="background-color: #E9E9E9; color: red;" translate>
          Failed
        </span>
        <span *ngIf="video.is_held_for_review"
              matTooltipPosition="above"
              matTooltip="{{'Content under review is not visible to the other users and outside the organization until approved by a manager.'|translate}}"
              class="badge mx-2 text-nunito font-medium"
          style="background-color: #E9E9E9; color: #24513E;" translate>
          Wird überprüft
        </span>
        <span *ngIf="!video.is_draft" class="badge mx-2 text-nunito font-medium"
          style="background-color: #DCF4E8; color: darkgreen;" translate>
          Veröffentlicht
        </span>
        <span *ngIf="video.is_draft" class="badge mx-2 text-nunito font-medium"
          style="background-color: #FEE2E2; color: darkred;" translate>
          Draft
        </span>

        <button [matMenuTriggerFor]="menu" aria-label="more options" class="mx-2" mat-icon-button>
          <mat-icon>more_horiz</mat-icon>
        </button>

        <!-- Options part -->
        <mat-menu #menu="matMenu">
          <button (click)="editVideo(video)" mat-menu-item>
            <mat-icon class="mr-2 material-icons-outlined" mat-list-icon>edit</mat-icon>
            <span class="text-nunito" translate>Bearbeiten</span>
          </button>
          <button (click)="draftChange(false, video)"
                  *ngIf="video.is_draft"
                  [disabled]="video.state != 'CO'" mat-menu-item>
            <mat-icon class="mr-2 material-icons-outlined" mat-list-icon>cloud_done</mat-icon>
            <span class="text-nunito" translate>Veröffentlichen</span>
          </button>
          <button (click)="draftChange(true, video)"
                  *ngIf="!video.is_draft" mat-menu-item>
            <mat-icon class="mr-2" mat-list-icon>cloud_off</mat-icon>
            <span class="text-nunito" translate>Save as draft</span>
          </button>
          <button (click)="ownThumbnail(video)" mat-menu-item>
            <mat-icon class="mr-2 material-icons-outlined" mat-list-icon>photo_library</mat-icon>
            <span class="text-nunito" translate>Update thumbnail</span>
          </button>
          <hr class="m-0">
          <button (click)="addtoPlaylist(video)"
                  [disabled]="video.state != 'CO'" mat-menu-item>
            <mat-icon class="mr-2 material-icons-outlined" mat-list-icon>queue</mat-icon>
            <span class="text-nunito" translate>Zu Playlist hinzufügen</span>
          </button>
          <button (click)="openChapterPopup(video)"
                  [disabled]="video.state != 'CO'"
                  mat-menu-item>
            <mat-icon class="mr-2 material-icons-outlined" mat-list-icon>description</mat-icon>
            <span *ngIf="!video.chapter_file" class="text-nunito" translate>Add chapters</span>
            <span *ngIf="video.chapter_file" class="text-nunito" translate>Edit chapters</span>
          </button>
          <button (click)="transcriptPopup(video)" [disabled]="!video.vtt_file" mat-menu-item>
            <mat-icon class="mr-2" mat-list-icon>edit_note</mat-icon>
            <span class="text-nunito" translate>Transkript ändern</span>
          </button>
          <hr class="m-0">
          <!--Only drafts can be re-assigned by a creator-->
          <button (click)="changeUploader(video)"
                  matTooltipPosition="left"
                  [disabled]="!(video.is_draft && is_creator)"
                  [matTooltip]="is_creator ? (video.is_draft ? '' : ('Only drafts can be transferred to others'|translate)) : ('You need Creator rights'|translate)"
                  mat-menu-item>
            <mat-icon class="mr-2" mat-list-icon>drive_file_move_outline</mat-icon>
            <span class="text-nunito" translate>Transfer</span>
          </button>
          <button (click)="duplicateVideo(video);"
                  [disabled]="video.type!='PR'" mat-menu-item>
            <mat-icon class="mr-2 material-icons-outlined" mat-list-icon>content_copy</mat-icon>
            <span class="text-nunito" translate>Create new version</span>
          </button>
          <button (click)="deleteVideo(video)" mat-menu-item>
            <mat-icon class="mr-2 material-icons-outlined" mat-list-icon>delete</mat-icon>
            <span class="text-nunito" translate>Löschen</span>
          </button>
        </mat-menu>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
