<!-- <h1 mat-dialog-title class="fontStyle">{{data.title}}</h1> -->
<!-- <mat-divider></mat-divider> -->
<mat-dialog-content style="height: 80%;">
    <mat-grid-list [cols]="colsVideo" #grid1 [gutterSize]="'1rem'" class="mx-2" rowHeight="1:1.1" >

        <mat-grid-tile  *ngFor="let video of videoObject" [ngStyle]="video.status ? {'border':'2px solid green'}:{'border':''}">
          <mat-card class="card-mv-style-s1">
            <button *ngIf="video.status == undefined" mat-mini-fab class="no-hover-background" (click)="addVideo(video)" style="position: absolute;top: 40%;right: 40%;z-index: 100;">
                <mat-icon>add</mat-icon>
            </button>
            <img mat-card-image [src]=" video.thumbnail" alt="user" style="border-radius: 5px 5px 0px 0px;">
            <mat-card-header>
              <div mat-card-avatar style="background-image: url({{video.uploader.userprofile.profile_pic}}); background-size: cover;"></div>
              <mat-card-subtitle class="fontStyle" style="color:black;"><b>{{video.title}}</b></mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </mat-grid-tile>

    </mat-grid-list>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions  align="end">
    <!-- <button mat-stroked-button class="fontNormal" [mat-dialog-close]="true" >{{data.actionText ? data.actionText : 'Ok'}}</button> -->
</mat-dialog-actions>
