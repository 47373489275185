import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PlaylistCard} from 'src/app/models/playlist/playlist';
import {ChecklistItem, TopicCard, VideoCardManager} from 'src/app/models/video/video.interface';
import {AuthService} from 'src/app/services/auth.service';
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";

@Component({
  selector: 'app-uploader-change-dialog',
  templateUrl: './uploader-change-dialog.component.html',
  styleUrls: ['./uploader-change-dialog.component.scss']
})
export class UploaderChangeDialogComponent {

  user_list: ChecklistItem[] = [];  // to store all potential users
  checklist_form_control = new FormControl();
  currently_selected_item: ChecklistItem = null;
  filteredOptions: Observable<ChecklistItem[]>;

  constructor(
    public dialogRef: MatDialogRef<UploaderChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PlaylistCard | VideoCardManager | TopicCard,
    private authService: AuthService,
  ) {
    // on init, load the data into checklist and then filter selected users
    this.loadChecklistUserData();
  }

  // this method loads all users and then filters the users only
  loadChecklistUserData() {
    // filter users only
    this.user_list = this.authService.checklist_data.filter((item) => item.type == 'user');
    let default_item = this.user_list.find(e => e.id == this.data.uploader.id);
    this.checklist_form_control.setValue(default_item.name);
    this.filteredOptions = this.checklist_form_control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );
  }

  private _filter(value: string): ChecklistItem[] {
    const filterValue = value.toLowerCase().trim();
    return this.user_list.filter(option =>
      option.name.toLowerCase().includes(filterValue) || option.string.toLowerCase().includes(filterValue)
    );
  }

  onItemSelect(item: ChecklistItem) {
    // console.log(this.checklist_form_control);
    this.currently_selected_item = item;
  }

  // return new uploader id
  save(): void {
    this.dialogRef.close(this.currently_selected_item.id);
  }
}
