import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "../../services/auth.service";
import {FormControl} from "@angular/forms";
import {map, startWith} from "rxjs/operators";
import {ChecklistItem, Topic, VideoView} from "../../models/video/video.interface";
import {DataService} from "../../services/data.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import {HttpErrorResponse} from "@angular/common/http";
import {MatDatepickerInputEvent, MatDatepickerModule} from "@angular/material/datepicker";
import {DateAdapter} from "@angular/material/core";


export interface PopupData {
  type: 'topic' | 'video';
  title: string;
  thumbnail: string;
  access_key: string | undefined;  // access key undefined tells us if the user is uploader or not
  departments: number[];
  groups: number[];
  id: string;  // id is number in case of playlist
  is_access_key_needed: boolean;
  is_external: boolean;
  is_restricted: boolean;
  locations: number[];
  teams: number[];
  users: number[];
}

export interface MaterialOption {
  name: string,
  value: string,
  icon: string,
  hint: string
}


/*
('intl', 'make it internal'),
('publ', 'publish it'),
('dept', 'remove dept'),
('team', 'remove team'),
('user', 'remove user'),
('loc', 'remove location'),
('grp', 'remove group')
*/
interface AccessControlSchedule {
  id: number,
  action: 'intl' | 'publ' | 'dept' | 'team' | 'user' | 'loc' | 'grp',
  id_link: number,  // can be zero
  execute_on: string,  // yyyy-mm-dd
  video: string | null,
  topic: string | null,
}


@Component({
  selector: 'app-share-popup',
  templateUrl: './share-popup.component.html',
  styleUrls: ['./share-popup.component.scss'],
  providers: [MatDatepickerModule,]
})
export class SharePopupComponent implements OnInit {

  form_data: any = null;
  myControl = new FormControl('');
  checklist_data: ChecklistItem[] = [];  // values are popped when an item is added to selected_items
  filteredOptions: Observable<ChecklistItem[]>;
  selected_items: ChecklistItem[] = [];  // if length is zero, then shared with everyone in the company
  video_or_topic: VideoView | Topic = null;  // stays null if user does not change anything

  selected_external_option: MaterialOption = null;
  external_restriction_options: MaterialOption[] = [];

  // manages the FE
  is_video_creator: boolean = false;
  is_anything_changed: boolean = false;
  in_progress: boolean = false;
  search_placeholder: string = '';
  is_external_sharing_disabled: boolean = false;
  disable_internal_tab: boolean = false;  // in case of public page only
  selected_tab_index: number = 0;  // internal tab is default
  is_free_user: boolean = true;  // for access control buttons
  schedules: AccessControlSchedule[] = [];
  minDate: Date;
  maxDate: Date;
  // below one is used to mimic a chip, used to manage calendar
  publicSchedule: ChecklistItem = {
    id: 0,
    name: '',
    string: '',
    type: 'intl'
  };

  // links
  external_view_link: string = "";
  internal_view_link: string = "";
  embed_video_link: string = "";
  iframe_code: string = "";  // same for both cases

  constructor(
    public dialogRef: MatDialogRef<SharePopupComponent>,
    private authService: AuthService,
    private dataService: DataService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DIALOG_DATA) public data: PopupData
  ) {
    // if user is not authenticated, then there is no checklist data
    if (this.authService.isAuthenticated()) {
      this._adapter.setLocale(this.authService.userDetails.email_language);
      this.is_free_user = this.authService.company.max_upload_video_height == '720';
      this.checklist_data = JSON.parse(JSON.stringify(this.authService.checklist_data));
      this.is_external_sharing_disabled = this.authService.company.is_external_sharing_disabled;
      this.translateService.get('_search_placeholder', {
        dept_alias: this.authService.company.dept_alias,
        team_alias: this.authService.company.team_alias,
      }).subscribe((res: string) => {
        this.search_placeholder = res;
      });
    } else {
      this.disable_internal_tab = true;
      this.selected_tab_index = 1;
    }

    this.is_video_creator = this.data.access_key != undefined;

    // update the dates for calendar
    const today = new Date();
    this.minDate = new Date();
    this.minDate.setDate(today.getDate() + 1);
    this.maxDate = new Date();
    this.maxDate.setDate(today.getDate() + 90);

    // create an empty form data
    this.form_data = {
      is_restricted: true,
      users: [],
      departments: [],
      teams: [],
      locations: [],
      groups: [],
    };

    this.external_restriction_options = [
      {
        name: this.translateService.instant('Privat'),
        value: 'private',
        icon: 'public_off',
        hint: this.translateService.instant("Only members of this workspace can see this content.")
      },
      {
        name: this.translateService.instant('Public'),
        value: 'public',
        icon: 'public',
        hint: this.translateService.instant("Everyone can see this content")
      },
      {
        name: this.translateService.instant('Unlisted'),
        value: 'accessCode',
        icon: 'vpn_lock',
        hint: this.translateService.instant("Anyone with the link can see this content. It won't appear on the workspace's public page.")
      }];

    // outside organization
    if (this.data.is_external) {
      this.selected_external_option = this.external_restriction_options[1];
      if (this.data.is_access_key_needed) {
        this.selected_external_option = this.external_restriction_options[2];
      }
    } else {
      this.selected_external_option = this.external_restriction_options[0];
    }

    // initiate links
    this._initiateLinks();
  }

  ngOnInit(): void {
    // load filtered options with all values
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    // load existing data if user is authenticated, also works for users
    if (!this.disable_internal_tab){
      this.loadExistingData();
    }

    this.dialogRef.backdropClick().subscribe(() => {
      this.saveAndClose();
    });

    this.loadSchedules();
  }

  private loadSchedules() {
    if (this.is_video_creator && !this.is_free_user) {
      // url like https://localhost:8000/api/user/schedules/?video=31585693-ffa3-4cd6-b127-d57b0ca9ab87
      this.dataService.getURL(`user/schedules/?${this.data.type}=${this.data.id}`, {
        responseType: 'json',
        observe: 'body'
      }).subscribe((res: AccessControlSchedule[]) => {
        this.schedules = res;
      });
      // ignore in case of error
    }
  }

  deleteSchedule(chip: ChecklistItem) {
    const message = this.translateService.instant('Clear expiry?');
    if (window.confirm(message)) {
      // ok
    } else {
      return;
    }

    // find first, it must exist
    let action = chip.type;
    if (action == 'location') {
      action = 'loc';
    } else if (action == 'group') {
      action = 'grp';
    }
    const id = this.schedules.find(schedule =>
      schedule.id_link == chip.id &&
      schedule.action == action).id;

    // remove from list
    const index = this.schedules.findIndex(schedule => schedule.id == id);
    this.schedules.splice(index, 1);
    this.dataService.deleteURL(`user/schedules/?id=${id}`, {observe: 'response'}).subscribe(() => {
      // deleted
      this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 1000});
    });
  }

  addSchedule(chip: ChecklistItem, event: MatDatepickerInputEvent<any>) {
    // can not use iso date, as it returns one day less or one day more due to time zone
    // interestingly, en-ca follows iso date format
    const execute_on = new Date(event.value).toLocaleDateString('en-CA');
    // find the action
    let action = chip.type;
    if (action == 'location') {
      action = 'loc';
    } else if (action == 'group') {
      action = 'grp';
    }
    const id_link: number = chip.id;
    const formData: FormData = new FormData();
    formData.append('execute_on', execute_on);
    formData.append('action', action);
    formData.append('id_link', id_link.toString());
    formData.append(this.data.type, this.data.id);

    this.dataService.postURL('user/schedules/', formData, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: AccessControlSchedule) => {
      this.schedules.push(res);
      this.snackBar.open(
        this.translateService.instant('Erfolgreich'),
        this.translateService.instant('Undo'),
        {duration: 3500}).onAction().subscribe(() => {
        // delete the schedule
        this.deleteSchedule(chip);
      });
    });
  }

  // finds a schedule for an item, returns exp date if exists
  findSchedule(chip: ChecklistItem): string {
    let action = chip.type;
    if (action == 'location') {
      action = 'loc';
    } else if (action == 'group') {
      action = 'grp';
    }

    const schedule = this.schedules.find(schedule =>
      schedule.id_link == chip.id &&
      schedule.action == action);
    if (schedule) {
      return schedule.execute_on;
    } else {
      return null;
    }
  }

  private _initiateLinks() {
    switch (this.data.type) {
      case "topic":
        // set view links
        this.internal_view_link = environment.baseURL + "pages/view/" + this.data.id;
        this.external_view_link = this.internal_view_link;
        if (this.data.access_key) {
          this.external_view_link += "?access_key=" + encodeURIComponent(this.data.access_key);
        }
        // set embed codes
        this.iframe_code = "<iframe style='aspect-ratio: 16/9; width: 100%; height: 100%;' " +
          `src='${this.external_view_link}' ` +
          `title='${encodeURIComponent(this.data.title)}'></iframe>`;
        break;
      case "video":
        // in case of a video, view links are different but embed links are same
        this.internal_view_link = environment.baseURL + "view/" + this.data.id;
        // external view link in public pages currently does not support reading access key from query parameters
        this.external_view_link = environment.baseURL + "publicVideoView/" + this.data.id;

        this.embed_video_link = environment.baseURL + "embed/video/" + this.data.id;
        if (this.data.access_key) {
          this.embed_video_link += "?access_key=" + encodeURIComponent(this.data.access_key);
        }
        this.iframe_code = "<iframe style='aspect-ratio: 16/9; width: 100%; height: 100%; border-radius: 10px;' " +
          `src='${this.embed_video_link}' ` +
          `title='${encodeURIComponent(this.data.title)}' allowfullscreen></iframe>`;
        break;
      // there is no default needed
    }
  }

  private _filter(value: string): ChecklistItem[] {
    let filterValue = "";
    try {
      filterValue = value.toLowerCase();
    }
    catch (e) {
      // toLowerCase does not work when an object is provided, hence above block
    }
    return this.checklist_data.filter(element =>
      element.name.toLowerCase().includes(filterValue) || element.string.toLowerCase().includes(filterValue));
  }

  // when an auto-select item is selected
  itemSelected(option: ChecklistItem) {
    // empty the input field
    this.myControl.setValue("");

    // since the list only contains the items not in selected items list, we do not need to check
    this.selected_items.unshift(option);
    // remove from checklist data
    this.checklist_data = this.checklist_data.filter(element => element !== option);

    switch (option.type) {
      case 'dept':
        this.form_data.departments.push(option.id);
        break;
      case 'team':
        this.form_data.teams.push(option.id);
        break;
      case 'user':
        this.form_data.users.push(option.id);
        break;
      case 'location':
        this.form_data.locations.push(option.id);
        break;
      case 'group':
        this.form_data.groups.push(option.id);
        break;
    }
    this.form_data.is_restricted = true;
    this.is_anything_changed = true;
  }

  // when a chip list item is removed
  itemRemoved(option: ChecklistItem) {
    // add to checklist items
    this.checklist_data.push(option);
    // remove from selected items
    this.selected_items = this.selected_items.filter(element => element !== option);

    let index = -1;
    switch (option.type) {
      case 'dept':
        index = this.form_data.departments.indexOf(option.id);
        this.form_data.departments.splice(index, 1);
        break;
      case 'team':
        index = this.form_data.teams.indexOf(option.id);
        this.form_data.teams.splice(index, 1);
        break;
      case 'user':
        index = this.form_data.users.indexOf(option.id);
        this.form_data.users.splice(index, 1);
        break;
      case 'location':
        index = this.form_data.locations.indexOf(option.id);
        this.form_data.locations.splice(index, 1);
        break;
      case 'group':
        index = this.form_data.groups.indexOf(option.id);
        this.form_data.groups.splice(index, 1);
        break;
    }
    this.form_data.is_restricted = this.selected_items.length > 0;
    // if the list is empty, then is_restricted becomes false
    this.is_anything_changed = true;
  }

  // load existing data and ensure that nothing is changed
  loadExistingData() {
    for (let i of this.data.departments) {
      const temp_item = this.checklist_data.find(element => element.type == 'dept' && element.id == i);
      this.itemSelected(temp_item);
    }

    for (let i of this.data.teams) {
      const temp_item = this.checklist_data.find(element => element.type == 'team' && element.id == i);
      this.itemSelected(temp_item);
    }

    for (let i of this.data.users) {
      const temp_item = this.checklist_data.find(element => element.type == 'user' && element.id == i);
      this.itemSelected(temp_item);
    }

    for (let i of this.data.locations) {
      const temp_item = this.checklist_data.find(element => element.type == 'location' && element.id == i);
      this.itemSelected(temp_item);
    }

    for (let i of this.data.groups) {
      // there may be a possibility that group is not visible for this particular user
      let temp_item = this.checklist_data.find(element => element.type == 'group' && element.id == i);
      // if temp_item is null, then we add a new element to checklist and select that
      if (temp_item == undefined) {
        // item does not exist, create a new group entry
        this.checklist_data.push({
          id: i,
          name: 'Unknown group',
          string: 'Group',
          type: 'group'
        });
        // now find it again
        temp_item = this.checklist_data.find(element => element.type == 'group' && element.id == i);
      }
      this.itemSelected(temp_item);
    }
    // after selecting all items
    this.is_anything_changed = false;
  }

  // save data
  submitRestriction(form_data: any, auto_close: boolean = false) {
    // ensure that correct api is called
    let base_url: string = "user/videos";
    if (this.data.type == 'topic') {
      base_url = "user/topics";
    }
    this.in_progress = true;
    this.dataService.putURL<VideoView | Topic>(`${base_url}/${this.data.id}/`, form_data, {
        responseType: 'json',
        observe: 'body'
      }).subscribe((res: VideoView | Topic) => {
        this.video_or_topic = res;
        this.snackBar.open(this.translateService.instant('Gespeichert'), '', {duration: 1000});
        if (auto_close) {
          this.dialogRef.close(this.video_or_topic);
        }
      }, (err: HttpErrorResponse) => {
        this.snackBar.open(err.error, '', {duration: 2500});
        console.error(err);
      }, () => {
        this.in_progress = false;
    });
  }

  async copyToClipboard(text: string, share: boolean = false) {
    // share: should we open os' share popup or not
    await navigator.clipboard.writeText(text).then(() => {
      this.snackBar.open(this.translateService.instant('Kopiert'), '', {
        duration: 2000,
      });
    });

    if (share) {
      // additionally open the share popup
      const data: ShareData = {
        text: this.translateService.instant('Check this out on Clypp'),  // this is mostly ignored
        url: text,
        title: this.data.title,  // this is always present
      }

      // also attach thumbnail if present
      if (this.data.thumbnail) {
        // not null, fetch the thumbnail
        fetch(this.data.thumbnail).then(res => {
          res.blob().then(blob => {
            const file_name = `${this.data.title}.png`;
            data.files = [new File([blob], file_name, {type: blob.type})];
            this.openOSNativePopup(data);
          });
        })
      } else {
        // just open it without thumbnail
        this.openOSNativePopup(data);
      }
    }
  }

  // this method ensures that code is not duplicated
  openOSNativePopup(data: ShareData) {
    try {
      if (navigator.canShare(data)) {
        navigator.share(data).then(() => {
          // close popup
          // todo: sendBeacon
          // https://www.educative.io/answers/what-is-sendbeacon-in-javascript
          this.saveAndClose();
        }).catch(e => {
          // AbortError
          console.error(e);
        });
      }
    } catch (err) {
      console.error(err);
      // do nothing
      // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share#browser_compatibility
      // not compatible with Chrome-Mac, Firefox-Mac/PC and Webview-Android
      // compatible with Chrome-Windows/ChromeOS/Android, Edge, Safari, Samsung
    }
  }

  downloadQR(parent: any, share_url: string) {
    let parentElement = parent.qrcElement.nativeElement.querySelector("canvas").toDataURL("image/png");
    // converts base 64 encoded image to blobData
    let blobData = convertBase64ToBlob(parentElement)
    // saves as image
    const blob = new Blob([blobData], {type: "image/png"})
    const download_url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    const file_name = `${this.data.title}.png`;
    link.href = download_url;
    // name of the file
    link.download = file_name;
    link.click();

    // now try the share api
    const data: ShareData = {
      title: this.translateService.instant('Check this out on Clypp'),  // ignored
      text: this.data.title,  // always present
      files: [new File([blob], file_name, {type: "image/png"})],
      url: share_url
    }
    this.openOSNativePopup(data);
  }

  // will be called on click of save button or enter
  saveAccessKey() {
    // save video, even if access key is empty, then the video will be unlisted
    let form_data = {
      is_external: true,
      is_access_key_needed: true,
      access_key: this.data.access_key,
    }
    this.submitRestriction(form_data);
    this._initiateLinks();
  }

  // called when external restrictions are changed
  externalChange(event) {
    let form_data = {
      is_external: false,
      is_access_key_needed: false,
      access_key: this.data.access_key,
    }

    if (event.value.value === 'private') {
      // form data stays unchanged
      this.data.is_external = false;
      this.data.is_access_key_needed = false;
      this.submitRestriction(form_data);
    } else if (event.value.value === 'public') {
      this.data.is_external = true;
      this.data.is_access_key_needed = false;
      form_data.is_external = true;
      this.submitRestriction(form_data);
    } else {
      // accessCode
      this.data.is_external = true;
      this.data.is_access_key_needed = true;
      form_data.is_external = true;
      form_data.is_access_key_needed = true;
      this.submitRestriction(form_data);
    }
  }

  saveAndClose() {
    if (this.is_anything_changed) {
      this.submitRestriction(this.form_data, true);
    } else {
      this.dialogRef.close(this.video_or_topic);
    }
  }
}


export function convertBase64ToBlob(Base64Image: string) {
  // split into two parts
  const parts = Base64Image.split(";base64,")
  // hold the content type
  const imageType = parts[0].split(":")[1]
  // decode base64 string
  const decodedData = window.atob(parts[1])
  // create unit8array of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length)
  // insert all character code into uint8array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i)
  }
  // return blob image after conversion
  return new Blob([uInt8Array], {type: imageType})
}


// this func loads js as they are not loaded in html
export function loadScript(url: string) {
  const body = <HTMLDivElement>document.body;
  let script = document.createElement('script');
  script.src = url;
  body.appendChild(script);
}
