import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {VideoView, ChecklistItem} from "../models/video/video.interface";
import {Observable} from "rxjs";
import {FormControl} from "@angular/forms";
import {filter, map, startWith} from "rxjs/operators";


@Component({
  selector: 'app-restriction-dialog',
  templateUrl: './restriction-dialog.component.html',
  styleUrls: ['./restriction-dialog.component.scss']
})
export class RestrictionDialogComponent implements OnInit {

  form_data: any = null;
  myControl = new FormControl('');
  checklist_data: ChecklistItem[] = [];
  video_object: VideoView = null;
  filteredOptions: Observable<ChecklistItem[]>;
  selected_items: ChecklistItem[] = [];

  constructor(
    public dialogRef: MatDialogRef<RestrictionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.checklist_data = data['checklist_data'];
    this.video_object = data['video_object'];

    this.form_data = {
      is_restricted: true,
      users: [],
      departments: [],
      teams: [],
      locations: [],
      groups: []
    }
  }

  ngOnInit(): void {
    // load filtered options with all values
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    // load the already selected restriction items as well
    this.loadExistingData();
  }

  loadExistingData() {
    for (let i of this.video_object.departments) {
      let temp_item = this.checklist_data.find(element => element.type == 'dept' && element.id == i)
      this.itemSelected(temp_item);
    }

    for (let i of this.video_object.teams) {
      let temp_item = this.checklist_data.find(element => element.type == 'team' && element.id == i)
      this.itemSelected(temp_item);
    }

    for (let i of this.video_object.users) {
      let temp_item = this.checklist_data.find(element => element.type == 'user' && element.id == i)
      this.itemSelected(temp_item);
    }

    for (let i of this.video_object.locations) {
      let temp_item = this.checklist_data.find(element => element.type == 'location' && element.id == i)
      this.itemSelected(temp_item);
    }

    for (let i of this.video_object.groups) {
      // there may be a possibility that group is not visible for this particular manager
      let temp_item = this.checklist_data.find(element => element.type == 'group' && element.id == i);
      // if temp_item is null, then we add a new element to checklist and select that
      if (temp_item == undefined){
        // item does not exist, create a new group entry
        this.checklist_data.push({
          id: i,
          name: 'Unknown group',
          string: 'Group',
          type: 'group'
        });
        // now find it again
        temp_item = this.checklist_data.find(element => element.type == 'group' && element.id == i);
      }
      this.itemSelected(temp_item);
    }
  }

  save(): void {
    this.form_data.is_restricted = this.selected_items.length > 0;
    // if the list is empty, then is_restricted becomes false
    this.dialogRef.close(this.form_data);
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  // when a chip list item is removed
  itemRemoved(option: ChecklistItem) {
    this.selected_items = this.selected_items.filter(element => element !== option);
    let index = -1;
    switch (option.type) {
      case 'dept':
        index = this.form_data.departments.indexOf(option.id);
        if (index > -1) {
          this.form_data.departments.splice(index, 1);
        }
        break;
      case 'team':
        index = this.form_data.teams.indexOf(option.id);
        if (index > -1) {
          this.form_data.teams.splice(index, 1);
        }
        break;
      case 'user':
        index = this.form_data.users.indexOf(option.id);
        if (index > -1) {
          this.form_data.users.splice(index, 1);
        }
        break;
      case 'location':
        index = this.form_data.locations.indexOf(option.id);
        if (index > -1) {
          this.form_data.locations.splice(index, 1);
        }
        break;
      case 'group':
        index = this.form_data.groups.indexOf(option.id);
        if (index > -1) {
          this.form_data.groups.splice(index, 1);
        }
        break;
    }
  }

  // when an auto-select item is selected
  itemSelected(option: ChecklistItem) {
    // empty the input field
    this.myControl.setValue("");

    // if item does not exist, then, push it
    let temp_index = this.selected_items.findIndex(element => element == option);
    if (temp_index > -1) {
      // item does exist
      return;
    }
    // item does not exist
    this.selected_items.push(option);
    switch (option.type) {
      case 'dept':
        this.form_data.departments.push(option.id);
        break;
      case 'team':
        this.form_data.teams.push(option.id);
        break;
      case 'user':
        this.form_data.users.push(option.id);
        break;
      case 'location':
        this.form_data.locations.push(option.id);
        break;
      case 'group':
        this.form_data.groups.push(option.id);
        break;
    }
  }

  private _filter(value: string): ChecklistItem[] {
    let filterValue = "";
    try {
      filterValue = value.toLowerCase();
    }
    catch (e) {
      // toLowerCase does not work when an object is provided, hence above block
    }
    return this.checklist_data.filter(element =>
      element.name.toLowerCase().includes(filterValue) || element.string.toLowerCase().includes(filterValue));
  }

}
