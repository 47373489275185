<div #scroll class="p-3 w-100">

  <!--top block with name and search-->
  <div class="align-items-center mx-1 row justify-content-between">
    <!--name-->
    <mat-card-title *ngIf="!searchCase" [title]="authService.currentUser?.email"
                    class="col-lg-6 col-md-12 col-sm-12 col-12 fontStyle">
      {{greetings_text}},&nbsp;{{authService.currentUser?.firstName}}&nbsp;👋
    </mat-card-title>

    <!--search count-->
    <mat-card-title *ngIf="searchCase"
                    class="d-flex align-items-center col-lg-6 col-md-12 col-sm-12 col-12">
      <button (click)="back()" mat-button
              class="mr-1" style="min-width: auto;"
              matPrefix>
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span class="fontStyle">
        {{searchLength}}
        <span *ngIf="searchLength==1">{{'Search result'|translate}}</span>
        <span *ngIf="searchLength!=1">{{'Search results'|translate}}</span>
      </span>
    </mat-card-title>

    <!--search field-->
    <mat-form-field appearance="outline" class="col-lg-6 col-md-12 col-sm-12 col-12"
                    style="line-height: 2rem; flex: 1;">
      <mat-icon (click)="applyFilter()" class="pointer" matPrefix>search</mat-icon>
      <input (keyup.enter)="applyFilter()" [(ngModel)]="searchQuery" autocomplete="off" matInput
             cdkFocusInitial autofocus
             placeholder="{{'Search your organization\'s content…'|translate}}">
      <mat-icon (click)="clearInput();" *ngIf="searchQuery || searchCase" class="pointer" matSuffix>close</mat-icon>
    </mat-form-field>
  </div>

  <div *ngIf="userActivityResp && !searchCase" class="row mx-1 mb-4">
    <!--left block-->
    <div class="col-lg-3 col-md-6 col-sm-12 col-12 d-inline-block">
      <div class="simple-card" style="margin-top: 10px;">
        <p class="text-nunito mt-4" style="font-size:large;" translate>
          Your activities
        </p>
        <div class="d-flex mt-4">
          <mat-icon class="material-symbols-outlined" color="primary"
                    style="font-size: 35px;width: 35px;height: 35px;">
            sentiment_very_satisfied
          </mat-icon>
          <div class="fontStyle ml-2 align-self-center" style="font-size: xx-large;">
            {{userActivityResp.n_7_days}}
          </div>
        </div>
        <div *ngIf="increasePercentage" class="mt-4">
          <!--hide in case of 0-->
          <div *ngIf="increasePercentage > 0" class="d-flex align-items-center" style="font-size:large;">
            <mat-icon class="mr-1" style="color: mediumseagreen;">arrow_upward</mat-icon>
            <span style="color:mediumseagreen;">{{increasePercentage}}%</span>
            <span class="fontNormal ml-2" translate>more than last 7 days</span>
          </div>
          <div *ngIf="increasePercentage < 0" class="d-flex align-items-center" style="font-size:large;">
            <mat-icon class="mr-1" color="warn">arrow_downward</mat-icon>
            <!--todo: show absolute value-->
            <span style="color:red;">{{increasePercentage}}%</span>
            <span class="fontNormal ml-2" translate>less than last 7 days</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-12 col-12 d-inline-block">
      <div class="simple-card" style="margin-top: 10px;">
        <p class="text-nunito mt-4" style="font-size:large;" translate>
          Days active
        </p>
        <span class="text-nunito mt-4">
          <mat-icon color="primary" style="vertical-align: bottom;">
            event_available
          </mat-icon>
          {{active_days_label.length}}&nbsp;{{'of the last 7 days'|translate}}
        </span>
        <div class="d-flex mt-4">
          <div *ngFor="let i of seven_days_label; let index = index;"
               class="d-flex flex-column align-items-center mx-1">
            <span class="text-nunito">{{i|translate}}</span>
            <mat-icon *ngIf="!active_days_label.includes(i)" color="primary">radio_button_unchecked</mat-icon>
            <mat-icon *ngIf="active_days_label.includes(i) && index != 6" color="primary">check_circle</mat-icon>
            <mat-icon *ngIf="index == 6" style="color: goldenrod;">check_circle</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- featured clypps-->
  <div *ngIf="featured_videos.length && !searchCase" class="border-radius-10 m-3">
    <div class="d-flex justify-content-between align-items-center">
      <h2 class="fontStyle d-flex align-items-center m-0">
        <mat-icon class="material-icons-outlined mr-2" color="primary">grade</mat-icon>
        {{'Featured Clypps'|translate}}
      </h2>
    </div>

    <!--slider block-->
    <!--show two buttons on the extreme, content in between, each button scrolls-->
    <div class="left-right-div" id="featured-clypps">
      <!--scrollable videos-->
      <app-video-card (click)="playVideo(video['id'])" *ngFor="let video of featured_videos;"
                      [backendUrl]="backendUrl" [disableClick]=true [videoObject]="video"
                      style="margin-right: 20px; max-width: 320px;"></app-video-card>
      <!--button 1 with absolute position to the left-->
      <button (click)="scrollToLeftRight('featured-clypps',true);" class="left-right-button left-scroll-button"
              mat-button>
        <mat-icon class="text-white">
          chevron_left
        </mat-icon>
      </button>
      <!--button 2 with absolute position to the left-->
      <button (click)="scrollToLeftRight('featured-clypps');" class="left-right-button right-scroll-button"
              mat-button>
        <mat-icon class="text-white">
          chevron_right
        </mat-icon>
      </button>
    </div>
  </div>

  <!-- continue watching-->
  <div *ngIf="history_videos.length && !searchCase" class="border-radius-10 m-3 bg-primary-555">
    <div class="d-flex justify-content-between align-items-center">
      <h2 class="fontStyle d-flex align-items-center m-0">
        <mat-icon class="material-icons-outlined mr-2" color="primary">history</mat-icon>
        {{'Continue watching'|translate}}
      </h2>
    </div>
    <div class="left-right-div" id="history">
      <!--scrollable videos-->
      <app-video-card-history (click)="playVideo(video.video['id'])" *ngFor="let video of history_videos;"
                              [backendUrl]="backendUrl" [disableClick]=true [videoObject]="video"
                              style="margin-right: 20px; max-width: 320px;"></app-video-card-history>
      <!--button 1 with absolute position to the left-->
      <button (click)="scrollToLeftRight('history',true);" class="left-right-button left-scroll-button" mat-button>
        <mat-icon class="text-white">
          chevron_left
        </mat-icon>
      </button>
      <!--button 2 with absolute position to the left-->
      <button (click)="scrollToLeftRight('history');" class="left-right-button right-scroll-button" mat-button>
        <mat-icon class="text-white">
          chevron_right
        </mat-icon>
      </button>
    </div>
  </div>

  <!--  Chips  -->
  <div *ngIf="!searchCase" class="chip-list mx-3 mb-4">
    <mat-chip-list aria-label="tags">
      <!-- load all videos with cleared search -->
      <mat-chip (click)="getAllClypps()">
        <mat-icon style="font-size: large;">play_circle_outline</mat-icon>
        {{'All Clypps' | translate}}
      </mat-chip>
      <mat-chip (click)="partnerSelected(partner);" *ngFor="let partner of partner_companies">
        {{partner.sharing_company.name}}
      </mat-chip>
      <mat-chip (click)="tagSelected(tag);" *ngFor="let tag of tags">
        {{tag.name}}
      </mat-chip>
    </mat-chip-list>
  </div>

  <!--All Clypps text-->
  <div *ngIf="!searchCase && videos.length"
       class="d-flex justify-content-between align-items-center mb-2 mx-2 px-3">
    <h2 class="fontStyle d-flex align-items-center m-0">
      <mat-icon class="material-icons-outlined mr-2" color="primary">bookmark_border</mat-icon>
      {{page_title}}
    </h2>
  </div>

  <!--newest Clypps or search results or tag results-->
  <div (scrolled)="getInternalVideos();" [fromRoot]="true" [infiniteScrollContainer]="'mat-sidenav-content'"
       [infiniteScrollDistance]="1" [infiniteScrollThrottle]="500" [scrollWindow]="false" infiniteScroll>
    <div class="d-flex justify-content-center" style="flex-flow: wrap;">
      <!--video card grid-->
      <app-video-card (click)="playVideo(video['id'])" *ngFor="let video of videos" [backendUrl]="backendUrl"
                      [disableClick]=true [videoObject]="video"
                      class="mx-2"></app-video-card>
    </div>

    <div *ngIf="videos.length == 0 && !showSpinner" class="d-flex justify-content-center align-items-center"
         style="height: 300px;">
      <h3>
        {{'No results. Search in other categories or'|translate}}
        <u (click)="navbarService.openVideoRequestDialog();" class="pointer">
          {{'ask your colleagues'|translate}}
        </u>
      </h3>
    </div>

    <div *ngIf="showSpinner" class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <!--scroll top button-->
  <div *ngIf="videos.length" class="scroll-to-top">
    <button (click)="scrollTop()" mat-fab>
      <mat-icon>vertical_align_top</mat-icon>
    </button>
  </div>
</div>
