<div class="d-flex justify-content-between flex-wrap align-items-center">
  <h2 class="fontStyle" mat-dialog-title translate>Select a file</h2>

  <mat-form-field appearance="outline">
    <mat-label translate>Search for…</mat-label>
    <input [(ngModel)]="search_text" autocomplete="off" id="search_input" matInput
           (keydown.enter)="getSearchResult();"
           maxlength="50" placeholder="{{'My files' | translate}}">
  </mat-form-field>
</div>

<mat-card-subtitle *ngIf="message">
  {{message}}
</mat-card-subtitle>

<mat-progress-bar mode="indeterminate" *ngIf="show_spinner"></mat-progress-bar>

<mat-dialog-content class="mb-4 d-flex overflow-hidden">
  <div class="w-25 d-flex flex-column" style="overflow-y: scroll;">
    <mat-selection-list [multiple]="false">
      <mat-list-option (click)="loadRecent();" class="my-1">
        <mat-icon mat-list-icon>history</mat-icon>
        <div mat-line translate>Recent</div>
      </mat-list-option>
      <mat-list-option (click)="loadShared();" class="my-1">
        <mat-icon mat-list-icon class="material-icons-outlined">supervisor_account</mat-icon>
        <div mat-line translate>Shared</div>
      </mat-list-option>
      <mat-list-option (click)="loadMyFiles();" class="my-1">
        <mat-icon mat-list-icon>folder_open</mat-icon>
        <div mat-line translate>My files</div>
      </mat-list-option>

      <ng-container *ngFor="let team of all_teams">
        <mat-list-option (click)="teamSelected(team.id);" class="my-1">
          <img [src]="'https://avatar-service-platform.personio.de/'+team.displayName[0]" mat-list-icon>
          <div mat-line>{{ team.displayName }}</div>
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
  </div>

  <div class="w-75" style="overflow-y: scroll;">
    <mat-selection-list [multiple]="false">
      <ng-container *ngFor="let item of all_items">
        <mat-list-option (click)="fileSelected(item);"
                         *ngIf="item.file"
                         class="my-1">
          <mat-icon mat-list-icon class="material-icons-outlined">note</mat-icon>
          <div mat-line>{{ item.name }}</div>
          <div mat-line>{{ item.file.mimeType }}</div>
        </mat-list-option>

        <mat-list-option (click)="folderSelected(item);"
                         *ngIf="item.folder"
                         [disabled]="item.folder.childCount == 0"
                         class="my-1">
          <mat-icon mat-list-icon>folder_open</mat-icon>
          <div mat-line>{{ item.name }}</div>
          <div mat-line>{{ item.folder.childCount }}</div>
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
  </div>
</mat-dialog-content>
