import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-mat-stepper-footer',
  templateUrl: './mat-stepper-footer.component.html',
  styleUrls: ['./mat-stepper-footer.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('in', style({
        opacity: 1
      })),
      state('out', style({
        opacity: 0
      })),
      transition('in => out', animate('1s ease')),
      transition('out => in', animate('2s ease'))
    ])
  ]
})
export class MatStepperFooterComponent {

  showBackButton = true;
  showNextButton = true;
  nextBtnLabel = 'Weiter';
  backBtnLabel = 'Neu aufnehmen';
  disableNextButton = false;
  disableBackButton = false;

  @Input()
  index : any;
  
  // tslint:disable-next-line:variable-name
  _state = 'out';

  @Input()
  set state(value: string) {
    setTimeout(() => this._state = value, 500);
  }

  constructor() {
  }

  ngOnInit(): void {
  }
}
