import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {UtilityService} from "../services/utility.service";
import {PlaylistCard} from "../models/playlist/playlist";


@Component({
  selector: 'app-video-card-playlist',
  templateUrl: './video-card-playlist.component.html',
  styleUrls: ['./video-card-playlist.component.scss']
})
export class VideoCardPlaylistComponent {
  @Input('playlist_object') playlist_object: PlaylistCard;
  @Input('routeUrl') routeUrl: string = "playlistView";

  constructor(private router: Router, public utilityService: UtilityService) {
  }

  videoSelect(playlist_id) {
    this.router.navigate([this.routeUrl, playlist_id]);
  }
}
