<div class="d-flex justify-content-between">
  <h2 mat-dialog-title class="text-nunito w-75 overflow-hidden">
    {{pdfTitle}}
  </h2>
  <div class="d-flex">
    <a mat-icon-button [href]="pdfSrc" [title]="pdfTitle" target="_blank">
      <mat-icon>open_in_new</mat-icon>
    </a>
    <button mat-icon-button>
      <mat-icon autofocus mat-dialog-close>close</mat-icon>
    </button>
  </div>
</div>

<div>
  <pdf-viewer [src]="pdfSrc" autoresize="true" fit-to-page="true" original-size="true" render-text="true"
              show-all="true" show-borders="true" style="width: 100%;height: 80vh;"></pdf-viewer>
</div>
