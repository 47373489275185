import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { VideoCard } from "../models/video/video.interface";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-clypps-page',
  templateUrl: './clypps-page.component.html',
  styleUrls: ['./clypps-page.component.scss']
})
export class ClyppsPageComponent implements OnInit {

  videos: VideoCard[] = [];
  backendUrl: string;
  sortValue: string = 'added_on';
  page: number = 1;
  maxPages: number = 1;
  showSpinner: boolean = false;
  isFeatured: boolean = false;
  query_subscription: Subscription = null;

  constructor(public dataService: DataService,
    public route: ActivatedRoute,
    private router: Router,
    public authService: AuthService) {
  }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    this.query_subscription = this.route.queryParams.subscribe(params => {
      this.isFeatured = JSON.parse(params.is_featured);
      this.sortValue = params.sort_by;
      this.page = 1;
      this.videos = [];
      this.getAllVideos();  // fetch new page 1 results
    });
  }

  getAllVideos(): void {
    // load results if max pages limit is not reached
    if (this.page <= this.maxPages && !this.showSpinner) {
      let queryConstruct = `user/videos/?sort_by=${this.sortValue}&page=${this.page}`;
      if (this.isFeatured) {
        queryConstruct += `&is_featured=${this.isFeatured}`;
      }

      this.showSpinner = true;
      this.dataService.getURL(queryConstruct, {observe: 'body', responseType: 'json'}).subscribe((resp: any) => {
        this.maxPages = Math.ceil(resp.length / resp.pageSize);
        this.videos.push(...resp['data']);
        this.showSpinner = false;
        this.page = resp.pageIndex + 1;
        // load more videos if we are on page 1
        if (this.page == 2) {
          this.getAllVideos();
        }
      }), (err) => {
        console.error(err);
        // this.router.navigate(['/start']);
        this.showSpinner = false;
      };
    }
  }

  sortedBy(event) {
    this.sortValue = event.value;
    this.router.navigate(['clypps'], {
      queryParams: {
        is_featured: this.isFeatured,
        sort_by: this.sortValue
      }
    });
  }

  ngOnDestroy(): void {
    this.query_subscription.unsubscribe();
  }

  featuredToggle(event) {
    this.isFeatured = event.checked;
    this.router.navigate(['clypps'], {
      queryParams: {
        is_featured: this.isFeatured,
        sort_by: this.sortValue
      }
    });
  }
}
