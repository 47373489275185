<div class="backgroundpng">
  <div class="d-flex justify-content-center align-items-center f-pswd">
    <!-- enter mail ID -->
    <div *ngIf="email.length == 0">

      <form [formGroup]="mailIDForm">
        <mat-card style="background-color:transparent !important;padding: 0px !important;">
          <mat-card-header class="justify-content-center">
            <mat-card-title class="fontStyle" style="font-weight: bold;margin-bottom: 2rem;" translate>
              Neu registrieren
            </mat-card-title>
          </mat-card-header>

          <mat-card-header class="justify-content-center">
            <mat-card-subtitle class="fontNormal text-center" translate>
              Bitte gib hier deine geschäftliche Email-Adresse an
            </mat-card-subtitle>
          </mat-card-header>

          <mat-card-content style="display: flex;justify-content: center;">
            <mat-form-field appearance="outline" class="fontNormal" hideRequiredMarker style="height: 60px;width:75%">
              <mat-label translate>Email-Adresse</mat-label>
              <input email formControlName="email" matInput
                     placeholder="abc@firma.de" (keydown.enter)="mailFormClicked();"
                     autocomplete="email" required type="email"/>
            </mat-form-field>
          </mat-card-content>

          <mat-card-header>
            <mat-card-subtitle class="desc">
              <span translate>
                If your organization is registered on Clypp, you will be automatically added to their workspace.
              </span>
              <span class="mt-1" translate>
                Otherwise, you can simply create a new workspace using your email address.
              </span>
            </mat-card-subtitle>
          </mat-card-header>

          <div class="d-flex w-100 justify-content-center align-items-stretch">
            <mat-checkbox color="primary" class="mr-2" formControlName="is_privacy_accepted">
            </mat-checkbox>
            <a class="text-dark font-medium text-nunito" href="{{'POLICY_URL' | translate}}" target="_blank">
              {{'I agree to the' | translate}}&nbsp;{{'Clypp privacy policy' | translate}}
            </a>
          </div>

          <mat-card-actions class="d-flex justify-content-around">
            <button (click)="mailFormBackClicked()" mat-stroked-button type="button">
              {{'Zurück'|translate}}
            </button>
            <button (click)="mailFormClicked()"
                    cdkFocusInitial
                    [disabled]="mailIDForm.invalid"
                    color="primary"
                    mat-flat-button
                    id="next">
              {{'Weiter'|translate}}
            </button>
          </mat-card-actions>
        </mat-card>
      </form>
    </div>

    <!-- verify otp -->
    <div>
      <div *ngIf="(otp.toString().length == 0 && email.length > 0) || showPass">
        <form (ngSubmit)="otpFormClicked()" [formGroup]="otpForm">
          <mat-card style="background-color:transparent !important;padding: 0px !important;">
            <mat-card-header class="justify-content-center">
              <mat-card-title class="fontStyle" style="font-weight: bold;" translate>Neu registrieren</mat-card-title>
            </mat-card-header>
            <mat-card-header style="justify-content: center;">
              <mat-card-subtitle [style.color]="showPass ? '#979797' : '#000000'" class="fontNormal"
                                 style="font-size:15px; text-align: center" translate>
                Bitte gib hier den 4-stelligen PIN-Code ein,<br>
                der soeben an deine geschäftliche Email-Adresse gesendet wurde:
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content style="display:flex;justify-content:center;">
              <!-- <div fxFlex="70%"> -->
              <div id="divOuter">
                <div id="divInner">
                  <input [readonly]="isEditable" [style.color]="showPass ? '#979797' : '#000000'" class="fontNormal"
                         formControlName="otp" id="partitioned" maxlength="4"
                         autocomplete="one-time-code"
                         onKeyPress="if(this.value.length==4) return false;"
                         oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                         type="text"/>
                </div>
              </div>
              <!-- </div> -->

              <!-- <div fxFlex="30%"> -->
              <span *ngIf="doneButton" [style.background-color]="showPass ? '#979797' : 'green'" class="w-20"
                    style="width:25px;height:25px">
                            <mat-icon style="color:white">done</mat-icon>
                        </span>
              <span *ngIf="closeButton && (otpForm.get('otp').value)"
                    style="width:25px;height:25px;background-color:red;border-radius:20px">
                            <mat-icon style="color:white">close</mat-icon>
                        </span>
              <!-- </div> -->
            </mat-card-content>

            <mat-card-header style="justify-content: center;">
              <mat-card-subtitle [style.color]="showPass ? '#979797' : '#000000'" class="fontNormal"
                                 style="font-size:15px; text-align: center" translate>
                Noch keinen PIN-Code erhalten?<br>
                Bitte sieh im Spam-Ordner nach<br>
                oder
              </mat-card-subtitle>
            </mat-card-header>

            <mat-list-item>
              <a (click)="sendOTPClicked()" [ngClass]="showPass ? 'disableLink' : 'forgotPassword'" class="w-100"
                 id="otp" translate>PIN erneut senden</a>
            </mat-list-item>

            <mat-card-actions *ngIf="!showPass">
              <button (click)="otpFormBackClicked()" mat-stroked-button type="button">
                {{'Zurück'|translate}}
              </button>
            </mat-card-actions>
          </mat-card>
        </form>
      </div>
      <div *ngIf="showPass">
        <form [formGroup]="regForm">
          <mat-divider></mat-divider>
          <mat-card style="background-color:transparent !important;">
            <mat-card-content style="display:flex;justify-content:center;" class="my-4">
              <mat-form-field appearance="outline" class="font-medium text-nunito w-100" hideRequiredMarker>
                <mat-label translate>New password</mat-label>
                <input [type]="hide ? 'password' : 'text'" #password  matInput formControlName="password"
                       (keydown.enter)="registered();"
                       autocomplete="new-password">
                <button (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" mat-icon-button
                        matSuffix>
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="regForm.get('password').hasError('required')">
                  <span translate>Required</span>
                </mat-error>
                <mat-error *ngIf="regForm.get('password').hasError('minlength')">
                  <span translate>Das Passwort muss aus mindestens 10 Zeichen bestehen</span>
                </mat-error>
                <mat-error *ngIf="regForm.get('password').hasError('maxlength')">
                  <span translate>Das Passwort sollte weniger als 30 Zeichen lang sein</span>
                </mat-error>
                <mat-error *ngIf="regForm.get('password').hasError('pattern')">
                  <span translate>(at least one uppercase, one lowercase letter, one special character and a number)</span>
                </mat-error>
              </mat-form-field>
            </mat-card-content>

            <mat-card-actions class="d-flex justify-content-between">
              <button (click)="otpFormBackClicked()"
                      mat-stroked-button type="button">
                {{'Zurück'|translate}}
              </button>
              <button [disabled]="regForm.get('password').invalid"
                      mat-flat-button
                      color="primary"
                      (click)="registered()"
                      class="text-white">
                {{'Jetzt registrieren'|translate}}
              </button>
            </mat-card-actions>
          </mat-card>
        </form>
      </div>
    </div>

  </div>
</div>

