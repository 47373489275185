import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss']
})
export class TagsComponent {

    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    @Input()
    style = 'w-100';

    @Input()
    prefix: string;

    @Input()
    label: string;

    @Input()
    infoText: string;

    @Input()
    tags: Array<string> = [];

    @Output()
    tagCtrlChange = new EventEmitter<UntypedFormControl>();

    @Output()
    tagOutput = new EventEmitter<string[]>();

    tagCtrl = new UntypedFormControl();

    filteredTags: Observable<string[]>;

    allTags: Array<string> = [] // ['Apple', 'Microsoft', 'Outlook', 'Mail', 'Office'];

    selectable = true;
    removable = true;

    // FF_SEMICOLON, SEMICOLON do not work: https://github.com/angular/material/issues/8542
    separatorKeysCodes: number[] = [ENTER, COMMA, TAB];

    constructor() {
        this.filteredTags = this.tagCtrl.valueChanges.pipe(
            startWith(''),
            map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our tags
        if ((value || '').trim()) {
            this.tags.push(value.trim());
            this.tagOutput.emit(this.tags);
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.tagCtrl.setValue(null);
    }

    remove(tag: string): void {
        const index = this.tags.indexOf(tag);

        if (index >= 0) {
            this.tags.splice(index, 1);
        }
        this.tagOutput.emit(this.tags);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.tags.push(event.option.viewValue);
        this.tagOutput.emit(this.tags);
        this.tagInput.nativeElement.value = '';
        this.tagCtrl.setValue(null);
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
    }

}
