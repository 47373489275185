<span style="display: inline">
  <mat-icon (click)="closePopup()" style="font-size: 25px; position: relative; cursor: pointer; float: right">close
  </mat-icon>
  <h2 class="fontStyle m-3" translate>Allowed IP Addresses</h2>
  <div class="m-3">
    <p translate>Add IP Addresses to restrict access to your organizations content on Clypp.</p>
    <span translate>This restriction is only valid if the following option in Global Settings in active</span>:&nbsp;<br>
    <i translate>Restrict login to Clypp to specific IP addresses only</i>
    <p *ngIf="!ip_addresses.length" class="mt-2">
      <span>{{'Attention! Do not activate this option without adding your own IP in the table below.' | translate}}</span>
    </p>
  </div>
</span>

<mat-dialog-content style="height: auto;">
  <div class="ip-table ml-3 mb-3">
    <table #ip_table [dataSource]="ip_addresses" mat-table>
      <!-- ip address --> <!-- action -->
      <ng-container matColumnDef="ip">
        <th *matHeaderCellDef mat-header-cell translate>IP Address</th>

        <td *matCellDef="let element" mat-cell>{{element.ip}}</td>

        <td *matFooterCellDef mat-footer-cell>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label translate>Add new</mat-label>
            <input [(ngModel)]="ip_address_input" matInput maxlength="15" minlength="7"
                   pattern="^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$"
                   placeholder="125.456.1.1" size="15" type="text">
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th *matHeaderCellDef mat-header-cell></th>

        <td *matCellDef="let element" mat-cell>
          <button (click)="deleteIPAddress(element)" class="px-1 blocked ml-3" mat-stroked-button>
            <mat-icon mat-list-icon>delete_outline</mat-icon>
            <span translate>Löschen</span>
          </button>
        </td>

        <td *matFooterCellDef mat-footer-cell>
          <button (click)="addIPAddress()" class="ml-3" mat-stroked-button>
            <mat-icon class="material-icons-outlined">add</mat-icon>
            <span translate>Add</span>
          </button>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="['ip', 'action']" mat-header-row></tr>
      <tr *matRowDef="let row; columns: ['ip', 'action'];" mat-row></tr>
      <tr *matFooterRowDef="['ip', 'action']" mat-footer-row></tr>
    </table>
  </div>
</mat-dialog-content>
