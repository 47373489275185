import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { MatDialog } from "@angular/material/dialog";
import { environment } from '../../../environments/environment';
import {UploaderDetails, VideoCard} from '../../models/video/video.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatGridList } from '@angular/material/grid-list';


interface DepartmentDetails {
  title: string;
  restricted_videos: VideoCard[];
  unrestricted_videos: VideoCard[];
  members: UploaderDetails[];
  managers: UploaderDetails[];
}


@Component({
  selector: 'app-team-dept-groups-view',
  templateUrl: './team-dept-groups-view.component.html',
  styleUrls: ['./team-dept-groups-view.component.scss']
})
export class TeamDeptGroupsViewComponent implements OnInit {
  member_cols: number = 10;
  department_or_team: DepartmentDetails;
  cols: number;
  @ViewChild('grid') grid: MatGridList;
  backendUrl: string;
  adminTooltip: string = "";
  show_spinner: boolean = false;

  gridByBreakpoint = {
    xl: 5,
    lg: 4,
    md: 3,
    sm: 2,
    xs: 1
  }

  constructor(public dataService: DataService,
    public dialog: MatDialog,
    public translateService: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    public authService: AuthService,
    private router: Router) {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.cols = this.gridByBreakpoint.xs;
        }
        if (result.breakpoints[Breakpoints.Small]) {
          this.cols = this.gridByBreakpoint.sm;
        }
        if (result.breakpoints[Breakpoints.Medium]) {
          this.cols = this.gridByBreakpoint.md;
        }
        if (result.breakpoints[Breakpoints.Large]) {
          this.cols = this.gridByBreakpoint.lg;
        }
        if (result.breakpoints[Breakpoints.XLarge]) {
          this.cols = this.gridByBreakpoint.xl;
        }
      }
    })
  }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    this.route.paramMap.subscribe(map => {
      const paramValue: 'team' | 'department' = map['params'].param;
      if (paramValue == 'team') {
        this.translateService.get('Teams_Management', {
            val_1: this.authService.company.team_alias,
          }).subscribe((res: string) => {
            this.adminTooltip = res;
          });
      } else {
        this.translateService.get('Dept_Management', {
            val_1: this.authService.company.dept_alias,
          }).subscribe((res: string) => {
            this.adminTooltip = res;
          });
      }
      // show spinner
      this.show_spinner = true;
      this.dataService.getURL(`user/${paramValue}/details/`).subscribe((data: any) => {
        this.department_or_team = data;
        // stop spinner
        this.show_spinner = false;
      }, () => this.show_spinner = false);
    });
  }

  backToGroups(): void {
    this.router.navigate(['groups'])
  }

  resize(innerWidth) {
    let cw = 320; // card width
    let gs = 20;  // gutter size
    let sn = 50; // small side nav width = 50 approx
    this.cols = Math.floor((innerWidth - sn) / (cw + gs));
  }

  onResize(event) {
    this.resize(event.target.innerWidth);
  }

  // check if user is an admin
  checkIfManager(id: number): boolean {
    // if == -1, then returns false
    return this.department_or_team.managers.findIndex(e => e.id == id) > -1;
  }
}
