import {Component, OnInit, ViewChild} from '@angular/core';
import {DataService} from '../../services/data.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {UserProfileWithName} from '../../models/video/video.interface';
import {MembersListPopupComponent} from '../../shared/members-list-popup/members-list-popup.component';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from "../../services/auth.service";
import {GroupOverview} from "../../groups/groups-view/groups-view.component";
import {NavbarService} from "../../services/navbar.service";
import {HttpErrorResponse} from "@angular/common/http";


@Component({
  selector: 'groups-management',
  templateUrl: './groups-management.component.html',
  styleUrls: ['./groups-management.component.scss']
})
export class GroupsManagementComponent implements OnInit {
  displayedColumns: string[] = ['title', 'users', 'actions'];
  dataSource: MatTableDataSource<GroupOverview>;
  all_groups: GroupOverview[] = [];
  authorised: boolean = false; // profile.is_global_manager or profile.is_company_manager or profile.is_quality_manager
  my_user_id: number = 0;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private dialog: MatDialog,
              private translateService: TranslateService,
              public navbarService: NavbarService,
              private authService: AuthService,
              private dataService: DataService) {

  }

  ngOnInit(): void {
    this.authorised = this.authService.userDetails.is_global_manager ||
      this.authService.userDetails.is_company_manager || this.authService.userDetails.is_quality_manager;
    this.my_user_id = this.authService.userDetails.user;
    if(this.authorised){
      this.getGroups();
    }
  }

  getGroups(): void {
    this.dataService.getURL<GroupOverview[]>(`manager/groups/`).subscribe((res: GroupOverview[]) => {
      this.all_groups = res;
      this.initiateTable();
    });
  }

  customFilterPredicate() {
    const myFilterPredicate = (data: GroupOverview, filter: string): boolean => {
      let searchString = filter.trim().toLowerCase();
      // search in title or email
      const member_search_results = data.members.filter(e => e.email.toLowerCase().includes(searchString));
      if (data.title.toLowerCase().includes(searchString) || member_search_results.length) {
        return true;
      }
      return false;
    }
    return myFilterPredicate;
  }

  initiateTable() {
    this.dataSource = new MatTableDataSource(this.all_groups);
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue;
  }

  viewGroup(group_id: number) {
    // open group in new tab
    window.open(`/groups/${group_id}`, '_blank');
  }

  deleteGroup(group_id: number) {
    let message = this.translateService.instant("Are you sure? Group admin(s) will be informed about this action.");
    if(window.confirm(message)){
      let url = `manager/groups/?gr_id=${group_id}`;
      this.dataService.deleteURL(url).subscribe(() => {
        window.alert(this.translateService.instant("Erfolgreich gelöscht"));
        // slice array
        const deleted_group_index = this.all_groups.findIndex(el => el.id == group_id);
        this.all_groups.splice(deleted_group_index, 1);
        this.initiateTable();
      }, )
    }
  }

  checkMyPresenceInThisGroup(group: GroupOverview): boolean {
    // returns true if present
    return group.admins.includes(this.my_user_id);
  }

  // any manager can join any group
  joinGroup(group_id: number) {
    const message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      let url = `manager/groups/?gr_id=${group_id}`;
      this.dataService.patchURL(url, {}, {observe: 'body', responseType: 'json'})
        .subscribe((res: GroupOverview) => {
          window.alert(this.translateService.instant("Erfolgreich"));
          // update array
          const updated_group_index = this.all_groups.findIndex(el => el.id == group_id);
          this.all_groups[updated_group_index] = res;
          this.initiateTable();
        }, (err: HttpErrorResponse) => {
          console.error(err);
      });
    }
  }

  viewMembers(value: GroupOverview) {
    let members = value.members;
    let data_to_send: UserProfileWithName[] = [];
    if (members.length) {
      // prepare data in proper format
      for (let i of members){
        // check if that member is an admin
        let is_group_admin: boolean = value.admins.includes(i.id);
        data_to_send.push({
          id: 0,
          is_company_manager: false,
          is_department_manager: is_group_admin,  // re-using this variable for group admin tooltip
          is_global_manager: false,
          is_quality_manager: false,
          is_team_manager: false,
          user: i
        })
      }
      const dialogRef = this.dialog.open(MembersListPopupComponent, {
        width: "40vw",
        minWidth: "350px",
        height: "80vh",
        data: {
          res: data_to_send,
          adminBadgeTooltip: this.translateService.instant('Group Admin'),
          title: this.translateService.instant("Mitwirkende"),
        } // 1 = visible for dept , 0 = visible for team , -1 = invisible, 2 = group
      });
    }
  }
}
