<div class="container-fluid loc_pos">
  <div class="d-flex align-items-center">
    <h2 class="fontStyle m-0" translate>Positions</h2>
    <button (click)="addPosition();"
            [disabled]="!authorised"
            mat-stroked-button class="ml-4 border-radius-20">
      <mat-icon mat-icon>add</mat-icon>
      <span translate>Add Position</span>
    </button>
  </div>
  <hr>

  <div fluidHeight="auto" class="pos-list">
    <table #posTableSort="matSort" [dataSource]="posDataSource" mat-table matSort matSortActive="users"
      matSortDirection="desc">

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'Name' | translate}}</th>
        <td (click)="renamePosition(element);" *matCellDef="let element" class="pointer" mat-cell
          matTooltip="{{'Bearbeiten'|translate}}" matTooltipPosition="left">
          {{element.name}}
        </td>
      </ng-container>


      <ng-container matColumnDef="users">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'User count' | translate}}</th>
        <td (click)="userCountPosition(element)" *matCellDef="let element" mat-cell
            matTooltipPosition="left" matTooltip="{{'View members'|translate}}" class="cursor-pointer">
          {{element.users ? element.users : '-'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th *matHeaderCellDef mat-header-cell mat-sort-header style="width: 160px;">{{''}}</th>
        <td *matCellDef="let element" mat-cell class="w-100">
          <button (click)="deletePosition(element);"
                  [disabled]="!authorised"
                  class="px-1 blocked" mat-icon-button>
            <mat-icon mat-list-icon>delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
  </div>
</div>
