<div class="m-3 d-flex align-items-center">
  <span class="fontStyle mr-3" style="font-size: x-large;">
    {{'Keyword' | translate}}:&nbsp;{{tagName}}
  </span>
  <button (click)="backTo()" class="border-radius-20" mat-stroked-button>
    <mat-icon>chevron_left</mat-icon>
    <span translate>Back</span>
  </button>
</div>

<!--Clypps or videos-->
<div class="d-flex align-items-center ml-3">
  <mat-icon class="material-icons-outlined mr-2" color="primary">play_circle</mat-icon>
  <h2 class="text-nunito m-0">Clypps</h2>
</div>

<div class="p-3 d-flex justify-content-around" style="flex-flow: wrap;">
  <span *ngIf="tagVideos.length ==0" translate>
    No items found
  </span>

  <app-video-card *ngFor="let video of tagVideos"
                  [backendUrl]="backendUrl"
                  [videoObject]="video">
  </app-video-card>
</div>

<!--Topics-->
<div class="d-flex align-items-center ml-3">
  <mat-icon class="material-icons-outlined mr-2" color="primary">sticky_note_2</mat-icon>
  <h2 class="text-nunito m-0">{{'Pages'|translate}}</h2>
</div>

<div class="p-3 d-flex justify-content-around" style="flex-flow: wrap;">
  <span *ngIf="tagTopics.length ==0" translate>
    No items found
  </span>
  <app-topic-card [topic_object]="topic" *ngFor="let topic of tagTopics"
                  style="max-width: 320px;"></app-topic-card>
</div>
