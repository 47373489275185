<div class="container-fluid mt-3 mb-5 playlist">
  <div class="d-flex justify-content-between align-items-baseline flex-wrap">
    <mat-form-field appearance="outline" class="mr-3">
      <mat-label translate>Search for…</mat-label>
      <input (keyup)="applyFilter();" [(ngModel)]="search_query" autocomplete="off" matInput
             placeholder="{{'Title or email' | translate}}">
    </mat-form-field>

    <!-- allow user to filter it-->
    <mat-form-field appearance="outline" class="ml-2"
                  matTooltipPosition="above">
    <mat-label translate>Filter</mat-label>
    <mat-select (selectionChange)="updateFilter();" [(ngModel)]="filter_option">
      <mat-option value="published">{{'Veröffentlicht'|translate}}</mat-option>
      <mat-option value="featured">{{'Hervorgehoben'|translate}}</mat-option>
      <mat-option value="drafts">{{'Privat'|translate}}</mat-option>
    </mat-select>
  </mat-form-field>
  </div>

  <div class="playlist-table">
    <table [dataSource]="dataSource" fixedLayout="true" mat-table matSort>

      <!-- name Column -->
      <ng-container matColumnDef="title">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          {{'Titel' | translate}}
        </th>
        <td *matCellDef="let element" [title]="element.title" mat-cell>
          <button (click)="viewPlaylist(element.id);"
                  [disabled]="element.is_draft"
                  mat-menu-item>
            <mat-icon *ngIf="element.is_featured"
                      class="mr-2 d-none d-md-inline"
                      style="color: gold; vertical-align: middle;"
                      title="{{ 'Hervorgehoben' | translate }}">
              star
            </mat-icon>
            <span [ngClass]="element.is_draft ? '' : 'primary-color-text'" class="text-nunito">
              {{element.title}}
            </span>
          </button>
        </td>
      </ng-container>

      <!--added on -->
      <ng-container matColumnDef="added_on">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          {{'Erstellt am' | translate}}
        </th>
        <td *matCellDef="let element" class="pr-2"
            matTooltip="{{'Updated on' | translate}}&nbsp;{{element.last_edited_on | date: 'dd.MM.yyyy HH:mm'}}"
            matTooltipPosition="left"
            mat-cell>
          <span>{{element.added_on | date: 'dd.MM.yyyy'}}</span>
        </td>
      </ng-container>

      <!-- creator Column -->
      <ng-container matColumnDef="creator">
        <th *matHeaderCellDef mat-header-cell mat-sort-header translate>Creator</th>
        <td *matCellDef="let element" class="pr-2" mat-cell>
          <a (click)="changeUploader(element);"
             class="creatorRow d-flex align-items-center text-dark cursor-pointer">
            {{element.uploader.first_name}}&nbsp;{{element.uploader.last_name}}
            <button class="showEditButton text-gray" mat-icon-button>
              <mat-icon>edit</mat-icon>
            </button>
          </a>
        </td>
      </ng-container>

      <!-- action buttons Column -->
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell style="width: 80px;"></th>
        <td *matCellDef="let element" mat-cell>
          <!-- Draft -->
          <button (click)="managerPlaylistAction(element, 'draft')"
                  [disabled]="element.is_draft"
                  class="px-1" mat-icon-button
                  [matTooltip]="element.is_draft ? ('Privat'|translate) : ('Save as private'|translate)"
                  matTooltipPosition="left">
            <mat-icon>
              cloud_off
            </mat-icon>
          </button>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
    <div *ngIf="showSpinner" class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
    <h2 *ngIf="!authorised" class="text-nunito m-4" translate>
      You need management rights
    </h2>
  </div>
</div>
