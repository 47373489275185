import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { NavbarService } from '../services/navbar.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {Invitation} from "./groups-view/groups-view.component";
import {GroupOverview} from "../models/group";
@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  cols: number;
  groups: Observable<GroupOverview[]>;
  teams:any = [];
  departments: any = [];
  subscription: Subscription;
  backendUrl: string;
  overallgroups = [];
  friendCompanies : any[];
  groupsval: GroupOverview[] = [];
  pageSize: number = 10;
  invitations: Invitation[] = [];
  // tslint:disable-next-line:max-line-length
  constructor(public dataService: DataService,
    public dialog: MatDialog,
    public translateService: TranslateService,
    private snackBar: MatSnackBar,
    public navbarService: NavbarService,
    public authService: AuthService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    this.navbarService.getUserProfileDetails();
    this.navbarService.disableCreationMode();
    this.getGroups();
    this.getInvitations();
    this.getFriendCompanies();
    this.resize(window.innerWidth);
  }

  getInvitations(): void {
    this.dataService.getURL(`user/groups/invitations/`).subscribe((data: Invitation[]) => {
      this.invitations = data;
    });
  }

  acceptRejectInvite(id: number, action='accept'){
    this.dataService.putURL(`user/groups/invitations/${id}/`, {action: action})
      .subscribe(() => {
        // 200 OK
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
        this.getInvitations();
        this.getGroups();
      }, (err) => {
        console.error(err);
        this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
      }
    )
  }

  stopPropogation(event) {
    event.stopPropagation();
  }

  getGroups(): void {
    this.dataService.getURL(`user/groups/`).subscribe((data: any) => {
      this.teams = [];
      this.departments = [];
      this.overallgroups =[];
      this.groups = data;
      this.groupsval = data;

      this.dataService.getURL(`user/team/`).subscribe((data: any) => {
        this.teams.push(data);
        this.dataService.getURL(`user/department/`).subscribe((data: any) => {
          this.departments.push(data);
          this.overallgroups.push(...this.departments,...this.teams,...this.groupsval);
        });
      });
    });
  }

  viewGroups(value){
    if (value.last_edited_on) {
      this.router.navigate(['groups', value.id]);
    } else if (value.type == 'team') {
      this.router.navigate(['group', value.type]);
    } else if (value.type == 'department') {
      this.router.navigate(['group', value.type]);
    }
  }

  getFriendCompanies() {
    this.dataService.getURL(`user/friends/`, {observe: 'body', responseType: 'json'})
      .subscribe((res: any) => {
        this.friendCompanies = res;
    });
  }

  viewFriendCompany(friendship_id) {
    this.router.navigate(['partnership', friendship_id]);
  }

  ngOnDestroy(): void {
  }

  resize(innerWidth) {
    let cw = 320; // card width
    let gs = 20;  // gutter size
    let sn = 50; // small side nav width = 50 approx
    this.cols = Math.floor((innerWidth - sn) / (cw + gs));
  }

  onResize(event) {
    this.resize(event.target.innerWidth);
  }
}
