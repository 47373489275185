<footer [@fadeIn]="_state">
  <mat-toolbar class="backcolor">
    <div [ngClass]="!showBackButton ? 'justify-content-end' : 'justify-content-between'" class="d-flex w-100">
      <button *ngIf="showBackButton" [disabled]="disableBackButton" class="btn-back" color="accent" mat-button
              matStepperPrevious style="color: black !important;font-weight: normal;">
        {{backBtnLabel}}
      </button>

      <button *ngIf="showNextButton" [disabled]="disableNextButton" [ngClass]="disableNextButton ? 'bgColor' : 'bgEnabled'" [style.color]="disableNextButton ? 'black' : 'white'" color="accent"
              mat-button
              matStepperNext style="font-weight: normal;">
        {{nextBtnLabel}}
      </button>
    </div>
  </mat-toolbar>
</footer>
