import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-user-profile-error-details-dialog',
  templateUrl: './user-profile-error-details-dialog.component.html',
  styleUrls: ['./user-profile-error-details-dialog.component.scss']
})
export class UserProfileErrorDetailsDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialog: MatDialog, public dialogRef: MatDialogRef<UserProfileErrorDetailsDialogComponent>) { }

  ngOnInit(): void {
  }
}
