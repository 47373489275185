import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {

  color: string;
  stateValue:any;
  interval: any;
  currentTime = 0;
  display: any = '00:00';
  minutesChange:any;

  timerModes = TimerMode;

  @Input()
  timerMode: TimerMode = TimerMode.SPINNER;
  @Input()
  timeLimit: number;

  constructor() {
  }

  ngOnInit(): void {

  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    let sec: any = (value - minutes * 60);
    if(sec < 10) {
      sec = '0' + sec;
    }
    if(minutes < 10){
       this.minutesChange = '0' + minutes + ':' + sec;
    }else{
        this.minutesChange = minutes + ':' + sec;
    }
    return this.minutesChange;
  }


    async startTimer(): Promise<void> {
        this.resetTimer();
        this.interval = setInterval(() => {
            // if (this.currentTime < (this.timeLimit + 60)) {
                this.currentTime++;
                if (this.currentTime + 30 === this.timeLimit) {
                    this.color = 'yellow';
                } else if (this.currentTime + 10 === this.timeLimit) {
                    this.color = 'red';
                }
                this.display = this.transform( this.currentTime);
            // } else {
            //     this.pauseTimer();
            // }
        }, 1000);
    }

    pauseTimer(): void {
        clearInterval(this.interval);
    }
    async resumeTimer(): Promise<void> {
        this.interval = setInterval(() => {
            // if (this.currentTime < (this.timeLimit)) {
                this.currentTime++;
                if (this.currentTime + 30 === this.timeLimit) {
                    this.color = 'yellow';
                } else if (this.currentTime + 10 === this.timeLimit) {
                    this.color = 'red';
                }
                this.display = this.transform( this.currentTime);
            // } else {
            //     this.pauseTimer();
            // }
        }, 1000);
    }
    resetTimer(): void {
        this.pauseTimer();
        this.currentTime = 0;
    }

}


export enum TimerMode {
  SPINNER,
  TIME
}
