import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NavbarService} from '../services/navbar.service';
import {DataService} from '../services/data.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UtilityService} from "../services/utility.service";
import {inIframe} from "../login/login.component";

@Component({
  selector: 'app-video-creation-modal-popup',
  templateUrl: './video-creation-modal-popup.component.html',
  styleUrls: ['./video-creation-modal-popup.component.scss'],
})
export class VideoCreationModalPopupComponent implements OnInit {
  video_resp: any;
  title: string = "";
  desc: string = "";
  script: string = "";
  disable_webcam: boolean = false;
  disable_sc_bo: boolean = false;
  card_id: string = "";  // this is used when user comes from a trello card
  issue_id: string = "";  // sent by jira
  disabled_button_tooltip: string = "";

  constructor(
    private dataService: DataService,
    protected navbarService: NavbarService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<VideoCreationModalPopupComponent>,
    private router: Router,
    public utilityService: UtilityService,
  ) {
  }


  ngOnInit(): void {
    if (navigator.userAgent.indexOf('Chrome') == -1 && navigator.userAgent.indexOf('Firefox') == -1) {
      // safari_browser
      this.disable_sc_bo = true;
    }

    if (navigator.userAgent.indexOf('TrelloDesktop') != -1) {
      // trello-desktop in either mac or windows
      this.disable_sc_bo= true;
      if (navigator.userAgent.indexOf('Macintosh') != -1) {
        // trello-desktop-mac
        this.disable_webcam = true;  // disable Webcam too, as mic access is not provided here
      }
    }

    // for teams, just enable upload
    if (navigator.userAgent.indexOf('Teams') > -1) {
      this.disable_webcam = true;
      this.disable_sc_bo= true;
    }

    this.disabled_button_tooltip = this.translateService.instant("Please use one of the following browsers: Google Chrome, Microsoft Edge, Mozilla Firefox");

    if(this.utilityService.mobileOS()){
      this.disable_sc_bo= true;
      // todo: this tooltip is not visible in mobile
      this.disabled_button_tooltip = this.translateService.instant("Please use a desktop browser");
    }
  }

  postAPiType(typeValue) {

    this.route.queryParams.subscribe(params => {
      this.title = params['title'];
      this.desc = params['desc'];
      this.script = params['script'];
      this.card_id = params['card_id'];
      this.issue_id = params['issue_id'];
    });

    if (this.title) {
      this.title = this.title.substring(0, 98);
    } else {
      let d = new Date();
      let locale;
      if (this.translateService.currentLang == "en") {
        locale = 'en-US';
      } else {
        locale = 'de-DE';
      }
      let options: any = {year: 'numeric', month: 'short', day: 'numeric'};
      this.translateService.get('_RECORDING_TITLE', {
        val_1: d.toLocaleDateString(locale, options),
        val_2: d.toLocaleTimeString(locale, {hour: '2-digit', minute: '2-digit'})
      }).subscribe((res: string) => {
        this.title = res;
      });
    }

    let postData = {
      title: this.title,
      type: typeValue,
      desc: this.desc,
      script: this.script
    }

    if (this.card_id) {
      postData['integration_parameters'] = {
        'card_id': this.card_id
      };
    } else if (this.issue_id) {
      // both card or issue id can not be present together
      postData['integration_parameters'] = {
        'issue_id': this.issue_id
      };
    }

    this.dataService.postURL<string>(`user/videos/`, postData, {observe: 'body', responseType: 'json'})
      .subscribe((res) => {
        this.dialog.closeAll();
        if(res['reason']){
          window.alert(res['reason']);
        }
        else{
          this.video_resp = res;
          let record_url = 'create-video/' + this.video_resp['id'] + '/record';
          if((typeValue == 'SC' || typeValue == 'BO' || typeValue == 'PR') && inIframe()){
            // if I am in an iframe, then open SC, BO & PR types in a new tab,
            // because screen sharing is not allowed in an iframe
            window.open(record_url, '_blank');
          }
          else{
            this.router.navigate([record_url]);
            // window.location.href = record_url;
          }
        }
      });
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
