import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'vidDurationFormat' })
export class vidDurationFormat implements PipeTransform {

  transform(val: number): string {
    let result = new Date(val * 1000).toISOString();
    if(val >= 3600) {
      // hh:mm:ss mode
      result = result.slice(11, 19);
    }
    else{
      // mm:ss.s mode
      result = result.slice(14, 19);
    }
    return result
  }

}

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(public translateService: TranslateService) {}

  timeSince(date, ago = '', edited = false) {
    let now: any = new Date();
    let seconds = Math.floor((now - date) / 1000);
    if (typeof date !== 'object') {
      date = new Date(date);
    }
    let intervalType;
    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      intervalType = 'Jahr';
    } else {
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        intervalType = 'Monat';
      } else {
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
          intervalType = 'Tag';
        } else {
          interval = Math.floor(seconds / 3600);
          if (interval >= 1) {
            intervalType = 'Stunde';
          } else {
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
              intervalType = 'Minute';
            } else {
              interval = seconds;
              intervalType = 'Sekunde';
            }
          }
        }
      }
    }
    if (interval > 1 || interval === 0) {
      if (
        intervalType === 'Sekunde' ||
        intervalType === 'Minute' ||
        intervalType === 'Stunde'
      ) {
        intervalType += 'n';
      } else {
        intervalType += 'en';
      }
    }
    let intervaltext = '';
    if (ago) {
      // here, we show time like "5 months ago" / "vor 5 monaten"
      // user in tooltips and comments
      intervaltext = edited ? '_EDIT_TIME' : '_CREATE_TIME';
    } else {
      // used in video cards
      intervaltext = '_SINCE_TIME';
    }
    return this.translateService.get(intervaltext, {
      val_1: interval,
      val_2: this.translateService.instant(intervalType),
      val_3: ago,
    });
  }

  mobileOS() {
    //Sends true when devices in the following regex is used by checking from the navigator.userAgent string OR iPad (which is obviously a touchscreen)
    return (
      /Android|webOS|iPad|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      (navigator?.maxTouchPoints > 1 && navigator.platform === 'MacIntel')
    );
    // return [
    //   'iPad Simulator',
    //   'iPhone Simulator',
    //   'iPod Simulator',
    //   'iPad',
    //   'iPhone',
    //   'iPod'
    // ].includes(navigator.platform)
    //   || (navigator.platform.indexOf("arm") > -1)
    //   || (/Android|webOS|iPad|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    //   // iPad on iOS 13 detection
    //   || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    //   // || (/iPad|iPhone|iPod/.test(navigator.platform)
    //   // || (navigator.platform === 'MacIntel' && navigator?.maxTouchPoints > 1))
  }
}
