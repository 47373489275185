<div class="d-flex flex-column viewContainer">

    <!-- <mat-grid-list *ngIf="!permissionDenied" [cols]="1" rowHeight="150px" >
    <mat-grid-tile  style="background: transparent !important;">
      <mat-card rowHeight="150px" style="margin-bottom:30px;background: transparent !important;width:100%;height: 100%;">
        <div style="width: 100%;margin-top:-5px; z-index: 10;background-color:transparent">
            <video class="video-view w-100 screen-container"  #webcamVideoScrcst autoplay [muted]="true" [srcObject]="srcScrcst" >
            </video>
        </div>
        <div class="bottom-right-overlay" style="width: 20%;z-index: 100; background-color:transparent;position: absolute;height: 100px;top:43%;left:80%">
            <video class="video-view w-100 screen-container" #webcamVideo autoplay [muted]="true" [srcObject]="src">
            </video>
        </div>

    </mat-card>
    </mat-grid-tile>
    </mat-grid-list> -->
    <div *ngIf="!permissionDenied " class="jumbotron jumbotron-fluid mb-0 text-center flex-grow-1 recording-window">
        <div style="width: 100%;height: 175px;background-color:transparent;padding-left: 70%;">
            <video style="background-color:transparent;" class="video-view w-100 screen-container" #webcamVideoTest
                autoplay [muted]="true" [srcObject]="srcTestVid">
            </video>
        </div>
    </div>
    <div style="width: 100%;height: 100%;background-color:transparent" *ngIf="permissionDenied"
        class="jumbotron jumbotron-fluid mb-0 text-center flex-grow-1 recording-window1" (click)="startButtonClicked()">
        <button mat-raised-button color="primary" class="fontNormal" style="color: white" translate>{{'Recheck webcam mic' | translate}}</button>
        <p style="align-items: center;padding-top: 1.2rem;" translate>Please allow the application to access your microphone and / or your camera.</p>
        <p translate>This can be done in the settings of your browser.</p>
    </div>
</div>
