import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-shape-overlay-div',
  templateUrl: './shape-overlay-div.component.html',
  styleUrls: ['./shape-overlay-div.component.scss']
})

export class ShapeOverlayDivComponent implements OnInit, OnChanges {

  @Input('shapeoverlayObj') shapeoverlayObj: any;
  @Input('border') border: any;
  @Output() onDrag = new EventEmitter<{ id: any, x: number, y: number }>();
  @Output() onResize = new EventEmitter<{ id: any, x: number, y: number, width: number, height: number }>();
  @Output() selectBlur = new EventEmitter<{ id: any }>();
  @Input('videoSize') videoSize: any;
  @Input('videoNativeSize') videoNativeSize: any;
  @Input('blurId') blurId: any;
  public style: object = {};
  @ViewChild('resizeBox') resizeBox: ElementRef;
  width = 0;
  height = 0;
  left = 0;
  top = 0;
  videoWidth = 0;
  videoHeight = 0;
  borderColor: any;
  fillColor: any;
  visibility = 'visible';
  id: any;

  constructor(public cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.videoWidth = this.videoSize.width;
    this.videoHeight = this.videoSize.height;
    this.height = this.shapeoverlayObj.shapeHeight;
    this.width = this.shapeoverlayObj.shapeWidth;
    this.left = this.shapeoverlayObj.overlayXPosition;
    this.top = this.shapeoverlayObj.overlayYPosition;
    this.id = this.shapeoverlayObj.id;
    this.cd.detectChanges();
    this.style = {
      'transform': 'none'
    }
    // (document.getElementById(this.blurId) as HTMLInputElement).style.border =  "aqua solid 3px";
  }

  update(type, value): void {
    switch (type) {
      case 'shapeHeight':
        this.height = value;
        break;
      case 'shapeWidth':
        this.width = value;
        break;
      case 'overlayXPosition':
        this.left = value;
        break;
      case 'overlayYPosition':
        this.top = value;
        break;
      case 'visibility':
        this.visibility = value;
        break;
      case 'border':
        this.borderColor = value;
        break;
    }
  }

  updateResize(type, value): void {
    switch (type) {
      case 'border':
        this.fillColor = value;
    }
  }

  ngOnChanges() {
    this.videoWidth = this.videoSize.width;
    this.videoHeight = this.videoSize.height;
    this.cd.detectChanges();
  }

  onDragEnded(event, id) {
    this.onDrag.emit({
      'id': id,
      'x': this.resizeBox.nativeElement.getBoundingClientRect().x,
      'y': this.resizeBox.nativeElement.getBoundingClientRect().y
    });
  }

  onResizeStop(event, id) {
    this.onResize.emit({
      'id': id,
      'x': this.resizeBox.nativeElement.getBoundingClientRect().x,
      'y': this.resizeBox.nativeElement.getBoundingClientRect().y,
      'width': this.resizeBox.nativeElement.getBoundingClientRect().width,
      'height': this.resizeBox.nativeElement.getBoundingClientRect().height
    });
  }

  selectDiv(id) {
    this.selectBlur.emit({
      'id': id
    });
  }

  onDragBegin(event, id) {
    this.selectBlur.emit({
      'id': id
    });
  }
}










