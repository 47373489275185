import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatGridList} from '@angular/material/grid-list';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {RestrictedSelectionDialogComponent} from 'src/app/quickwin-creation/restricted-selection-dialog/restricted-selection-dialog.component';
import {environment} from '../../../environments/environment';
import {ChecklistItem, UploaderDetails, VideoCard} from '../../models/video/video.interface';
import {AuthService} from '../../services/auth.service';
import {DataService} from '../../services/data.service';
import {NavbarService} from '../../services/navbar.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import { AlertDialogComponent } from 'src/app/shared/alert-dialog/alert-dialog.component';
import {Company} from "../../dashboard/global-administration/partner-organizations/partner-organizations.component";


interface GroupView {
  added_on: string;
  admins: UploaderDetails[];
  company: Company;
  external_viewers: UploaderDetails[];
  id: number;
  last_edited_on: string;
  members: UploaderDetails[];
  title: string;
  uploader: UploaderDetails;
}

export interface GroupOverview {
  members: UploaderDetails[],
  admins: number[],
  title: string;
  company: number,
  id: number;
  last_edited_on: string;
}


export interface Invitation {
  id: number;
  invited_by: UploaderDetails;
  invited_group: GroupOverview;
  invited_on: string;
  recipient_email: string;
}


@Component({
  selector: 'app-groups-view',
  templateUrl: './groups-view.component.html',
  styleUrls: ['./groups-view.component.scss']
})
export class GroupsViewComponent implements OnInit {
  @ViewChild('grid') grid: MatGridList;
  cols: number;
  member_cols: number = 10;
  gridByBreakpoint = {
    xl: 5,
    lg: 4,
    md: 3,
    sm: 2,
    xs: 1
  }

  videos: VideoCard[] = [];
  gutterSize: number;
  subscription: Subscription;
  isGroupEmpty: boolean = true;
  backendUrl: string;
  pageSize: number = 10;
  pageSizeOptions: number[] = [this.pageSize];
  group_object: GroupView = null;
  id: number;
  title: string;
  subTitle: string;
  combineRestrict: any = [];
  restrictionValue: ChecklistItem[] = [];
  overallFinalValue: any = [];
  userId: number = 0;
  //invitees: any;
  invitees: MatTableDataSource<Invitation>;
  includingActionCol: string[] = ['recipient_email', 'invited_by','invited_on', 'action'];
  displayedColumns: string[] = [];
  userIsAdmin: boolean = false;
  isUserMember: boolean = false;
  selectedIndex = 0;
  show_spinner: boolean = false;
  // tslint:disable-next-line:max-line-length

  constructor(public dataService: DataService,
              private breakpointObserver: BreakpointObserver,
              private route: ActivatedRoute,
              private router: Router,
              public snackBar: MatSnackBar,
              private dialog: MatDialog,
              private translateService: TranslateService,
              private navbarService: NavbarService,
              public authService: AuthService) {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.cols = this.gridByBreakpoint.xs;
        }
        if (result.breakpoints[Breakpoints.Small]) {
          this.cols = this.gridByBreakpoint.sm;
        }
        if (result.breakpoints[Breakpoints.Medium]) {
          this.cols = this.gridByBreakpoint.md;
        }
        if (result.breakpoints[Breakpoints.Large]) {
          this.cols = this.gridByBreakpoint.lg;
        }
        if (result.breakpoints[Breakpoints.XLarge]) {
          this.cols = this.gridByBreakpoint.xl;
        }

      }
    })

  }

  ngOnInit(): void {
    this.navbarService.getUserProfileDetails();
    this.backendUrl = environment.backendURL;
    this.navbarService.disableCreationMode();
    this.userId = parseInt(this.authService.currentUserId);
    this.resize(window.innerWidth);
    this.title = this.translateService.instant('Personen zur Gruppe hinzufügen');
    this.subTitle = this.translateService.instant('Search for…');

    this.route.params.subscribe(params => {
      this.id = +params['id'];
      // we need to call this under this section, as query params may update while searching for a group
      this.getGroup();
      this.getVideos();
      this.getInvitees();
    });
  }

  getGroup() {
    this.show_spinner = true;
    this.dataService.getURL(`user/groups/${this.id}/`).subscribe((data: GroupView) => {
        this.show_spinner = false;
        this.group_object = data;
        this.userIsAdmin = this.group_object.admins.some(el => el.id === this.userId);
        if (this.userIsAdmin) {
          this.displayedColumns = this.includingActionCol;
        } else {
          this.displayedColumns = this.includingActionCol.slice(0, 2);
        }

        // top 3 admins can view the group, even if they are not group members
        this.isUserMember = this.group_object.members.some(el => el.id === this.userId);
      }
      , (err: HttpErrorResponse) => {
        this.show_spinner = false;
        this.router.navigate(['groups']);
      });
  }

  getInvitees() {
    this.dataService.getURL(`user/groups/invitations/${this.id}/`).subscribe((data: Invitation[]) => {
        this.invitees = new MatTableDataSource(data);
      }
      , (err: HttpErrorResponse) => {
        this.router.navigate(['groups']);
      });
  }

  leaveGroup() {
    let message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
    this.dataService.deleteURL(`user/groups/${this.id}/`).subscribe((data: any) => {
      this.router.navigate(['groups'])
    });
    }
  }

  // remove all members one by one and leave the group at last
  deleteGroup(){
    // confirm first
    let message = this.translateService.instant("Mitwirkende");
    message += " "
    message += this.translateService.instant("will be informed about this.");
    message += "\n\n";
    message += this.translateService.instant("Would you like to continue?");
    if (window.confirm(message)) {
      // remove all members one by one
      let all_members: UploaderDetails[] = this.group_object.members.concat(this.group_object.external_viewers);
      for(let i of all_members){
        if(i.id != this.userId) {
          // remove everyone except myself
          this.removeMember(i.id, false).then();
        }
      }
      // remove myself now
      this.removeMember(this.userId, false).then();
      this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2500});
      this.backToGroups();
    }
  }

  editGroup() {
    const dialogData = {
      title: this.translateService.instant('Gruppenname ändern'),
      subtitle: '',
      placeholder: this.translateService.instant('z.B. Gruppe 1'),
      fieldText: this.group_object.title,
      actionText: this.translateService.instant("Speichern")
    };

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      autoFocus: false,
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResponse: any) => {
      if (dialogResponse?.clickedSubmit) {
        let body = {
          action: "rename",
          id: dialogResponse.fieldText
        }
        this.dataService.putURL(`user/groups/${this.id}/`, body, {observe: 'body', responseType: 'json'})
          .subscribe((resp) => {
          let message = this.translateService.instant('Erfolgreich');
          this.snackBar.open(message, null, {duration: 2500});
          this.getGroup();
        });
      }
    });
  }

  // fetches all group member emails and opens a mailto link
  sendEmail() {
    let link = "mailto:";
    // load internal viewers
    for (let member of this.group_object.members) {
      link += member.email;
      link += "; ";
    }
    // load external viewers
    for (let member of this.group_object.external_viewers) {
      link += member.email;
      link += "; ";
    }
    // set group title as subject
    link += "?subject=";
    link += encodeURIComponent(this.group_object.title);
    window.open(link);
  }

  addMember() {
    this.restrictionValue = this.authService.checklist_data;
    // filter users
    for (let i in this.restrictionValue) {
      if (this.restrictionValue[i]['type'] == 'user') {
        this.combineRestrict.push(this.restrictionValue[i]);
      }
    }

    let currentGroupList = [];
    this.group_object.members.forEach(element => {
      currentGroupList.push({
        id: element.id,
        name: `${element.first_name} ${element.last_name}`,
        type: 'user',
        is_admin: this.group_object.admins.some(el => el.id === element.id),
        string: element.email
      })
    });
    this.overallFinalValue = JSON.parse(JSON.stringify(this.combineRestrict));
    this.combineRestrict = [];

    const dialogRef = this.dialog.open(RestrictedSelectionDialogComponent, {
      width: "580px",
      height: "550px",
      hasBackdrop: true,
      disableClose: false,
      data: {
        title: this.title,
        showBtn: true,
        subtitle: this.subTitle,
        message: '',
        checklist: this.overallFinalValue,
        userlist: currentGroupList,
        group: this.id
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res.deletedHimself == true) {
        this.router.navigate(['groups']);
      } else {
        this.getGroup();
      }
    });

    dialogRef.backdropClick().subscribe(res => {
      this.getGroup();
    });
  }

  invite() {
    let message = this.translateService.instant("Invite someone outside of this organisation");
    message += "\n\n";
    message += this.translateService.instant("New Users will receive an email invitation to your organization on Clypp.");
    let resValue = window.prompt(message);
    // todo: check for email format
    if (resValue) {
      this.dataService.postURL(`user/groups/invitations/${this.id}/`, {recipient_email: resValue}, {
        observe: 'body',
        responseType: 'json'
      }).subscribe(
        (resp: any) => {
          if (resp['reason']) {
            // an error occurred
            window.alert(resp['reason']);
          } else {
            // success
            this.snackBar.open(this.translateService.instant('Du hast die Person erfolgreich eingeladen'), '', {duration: 2500});
            this.getInvitees();
          }
        }, (err) => {
          console.log(err);
          this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2500});
        });
    }
  }

  async removeMember(id: number, confirm: boolean = true) {
    let confirmed = !confirm;
    if(confirm){
      // ask user first
      confirmed = window.confirm(this.translateService.instant("Bist du sicher?"));
    }

    if(confirmed){
      // remove user
      this.dataService.putURL(`user/groups/${this.id}/`, {id, action: 'remove'}).subscribe((data: any) => {
        if(confirm){
          // only get group data if user was asked
          this.getGroup();
        }
      });
    }
  }

  sendExternalEmail(invite: Invitation) {
    let link = `mailto:${invite.recipient_email}`;
    // set group title as subject
    link += "?subject=";
    link += encodeURIComponent(this.group_object.title);
    link += "&body=";
    link += this.translateService.instant("Hey, I'd like to invite you to join my group on Clypp.");
    window.open(link);
  }

  deleteExternalInvitation(invite: Invitation){
    let message = invite.recipient_email;
    message += ' ';
    message += this.translateService.instant("will also be notified about this.");
    message += '\n\n';
    message += this.translateService.instant("Would you like to continue?");

    if (window.confirm(message)) {
      this.dataService.putURL(`user/groups/invitations/${invite.id}/`, {action: 'delete'})
        .subscribe(() => {
            // 200 OK
            this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
            // reload data
            this.getInvitees();
          }, (err) => {
            console.error(err);
            this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
          }
        )
    }
  }

  getVideos(): void {
    this.dataService.getURL<VideoCard[]>(`user/groups/${this.id}/videos/`).subscribe((data: VideoCard[]) => {
      this.videos = data;
      if (data.length > 0) {
        this.isGroupEmpty = false;
      }
    });
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }

  backToGroups(): void {
    this.router.navigate(['groups'])
  }

  resize(innerWidth) {
    let cw = 320; // card width
    let gs = 20;  // gutter size
    let sn = 50; // small side nav width = 50 approx
    this.cols = Math.floor((innerWidth - sn) / (cw + gs));
    this.member_cols = Math.floor((innerWidth - 100) / (120));
  }

  onResize(event) {
    this.resize(event.target.innerWidth);
  }

  // check if user is an admin
  checkIfManager(id: number): boolean {
    // if == -1, then returns false
    return this.group_object.admins.findIndex(e => e.id == id) > -1;
  }
}
