import {Component, Inject, OnInit} from '@angular/core';
import {VideoView} from '../../models/video/video.interface';
import {DataService} from '../../services/data.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-transcript-popup',
  templateUrl: './transcript-popup.component.html',
  styleUrls: ['./transcript-popup.component.scss']
})
export class TranscriptPopupComponent implements OnInit {

  videoObj: VideoView;
  transcript_form = this.formBuilder.group({
    transcriptInput: ['']
  });
  video_player: HTMLVideoElement;

  constructor(private snackBar: MatSnackBar, private dataService: DataService,
              private httpClient: HttpClient, public dialogRef: MatDialogRef<TranscriptPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public formBuilder: UntypedFormBuilder,
              private translateService: TranslateService) {
    this.videoObj = data;
  }

  ngOnInit(): void {
    this.readTextFile(this.videoObj?.vtt_file);
  }

  metaLoaded() {
    this.video_player = <HTMLVideoElement>document.querySelector("#subtitle-player");
  }

  seekForward(){
    this.video_player.currentTime += 5;
  }

  seekBackward(){
    this.video_player.currentTime -= 5;
  }

  readTextFile(file) {
    this.httpClient.get(file, {responseType: 'text' as const}).subscribe(data => {
      this.transcript_form.get('transcriptInput').setValue(data);
    })
  }

  saveTranscript() {
    let transcriptFormObj = this.transcript_form.value;
    const textToBLOB = new Blob([transcriptFormObj.transcriptInput], {type: 'text/plain'});
    let file = new File([textToBLOB], `${this.videoObj['id']}.vtt`);
    let formData = new FormData();
    formData.append('vtt_file', file);
    this.httpClient.put(`${environment.backendURL}/user/videos/${this.videoObj['id']}/`, formData, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: VideoView) => {
      if (res['response'] == 'failure') {
        window.alert(res['reason']);
      } else {
        this.snackBar.open(this.translateService.instant('Gespeichert'), '', {duration: 2500});
        this.dialogRef.close(res);
      }
    }, (error) => {
      window.alert(error.error);
    });
  }

  deleteSubtitlePopup() {
    let message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      this.dataService.deleteURL(`user/videos/${this.videoObj.id}/state/`)
        .subscribe((res) => {
            this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
            this.dialogRef.close('subtitles_deleted');
          }, (err) => {
            window.alert(err.error);
          }
        );
    }
  }
}
