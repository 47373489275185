import {Component, OnInit} from '@angular/core';
import {FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DataService} from 'src/app/services/data.service';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from "../../../services/auth.service";
import {PrimaryColorSetupService} from "../../../services/primary-color-setup.service";
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AllowedIpAddressesComponent} from '../allowed-ip-addresses/allowed-ip-addresses.component';
import {WatermarkPreviewComponent} from './watermark-preview/watermark-preview.component';
import {FileValidator} from 'ngx-material-file-input';
import {COMMA, ENTER, TAB} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {Company} from "../../../models/interfaces/auth-response";
import {
  ProgressSpinnerDialogComponent
} from "../../../shared/progress-spinner-dialog/progress-spinner-dialog.component";
import {FeedbackDialogComponent} from "../../../shared/feedback-dialog/feedback-dialog.component";
import {VideoProfileComponent} from "./video-profile/video-profile.component";


// this interface stores all the sections in the page
export interface SectionLink {
  name: string;
  id: string;
  active: boolean;
  icon: string;
}

@Component({
  selector: 'company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss']
})
export class CompanyManagementComponent implements OnInit {
  section_links: SectionLink[] = [];
  settings: FormGroup; // form group

  // to keep track of name of files
  attachmentName = {
    bg_image: '',
    overlay_logo: '',
    font_file: '',
    company_logo: '',
    intro_video: '',
    outro_video: '',
  }

  observer: IntersectionObserver = null;  // needed to observe when video element is in sight

  creditDropdown: any = [];  // intro outro etc
  companyDetails: Company = null;
  is_global_manager: boolean = false; // used to disable save and subscription button
  disable_settings_message: string = "";
  maxSize500KB: number = 512000; // 100 MB (=100 * 2 ** 20) byte value 500KB
  maxSize4MB: number = 4194304;  // 1024 * 1024 * 4
  maxSize5MB: number = 5242880;  // 1024 * 1024 * 5

  phrases: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA, TAB];

  constructor(private formBuilder: UntypedFormBuilder,
              private dataService: DataService,
              private snackBar: MatSnackBar,
              private authService: AuthService,
              private translateService: TranslateService,
              private dialog: MatDialog,
              private primaryColorSetupService: PrimaryColorSetupService,
  ) {
    this.creditDropdown = [
      {key: 'none', value: this.translateService.instant('Keine')},
      {key: 'intro', value: this.translateService.instant('Nur Intro')},
      {key: 'outro', value: this.translateService.instant('Nur Outro')},
      {key: 'both', value: this.translateService.instant('Beides')}
    ];
  }

  ngOnInit(): void {
    this.settings = this.formBuilder.group({
      phrases: [''],
      auto_approve_user: [''],
      auto_creator_user: [''],
      bg_image: [undefined, FileValidator.maxContentSize(this.maxSize4MB)],
      ci_video_code: [''],
      company_logo: [undefined, FileValidator.maxContentSize(this.maxSize500KB)],
      font_file: [''],
      //disable_vanilla_registration: [''],
      //domain_names: [''],
      //is_jira_allowed: [''],
      id: [''],
      intro_video: [undefined, FileValidator.maxContentSize(this.maxSize5MB)],
      is_external_sharing_disabled: [''],
      is_external_views_shown: [''],
      is_external_group_disabled: [''],
      is_strict_quality_control: [''],
      is_transcription_service_enabled: [''],
      is_ip_restriction_enabled: [''],
      jira_consumer_key: [''],
      jira_request_url: [''],
      custom_privacy_url: [''],
      site_notice: [''],
      name: [''],
      outro_video: [undefined, FileValidator.maxContentSize(this.maxSize5MB)],
      overlay_logo: [undefined, FileValidator.maxContentSize(this.maxSize500KB)],
      primary_color: [''],
      secondary_color: [{value: '', disabled: true}],
      dept_alias: [''],
      team_alias: [''],
      overlay_code: [''],
      is_comment_feature_enabled: [''],
      is_partner_content_shown: [''],
      n_token_valid_days: ['', [Validators.required, Validators.min(1), Validators.max(365)]]
    });

    // to avoid delay, load the company data
    this.dataService.getURL<Company>('user/company/').subscribe((company) => {
      this.loadCompanyDetails(company);
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initiateObserver();
    }, 100);
  }

  initiateObserver() {
    this.section_links = [];

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('id');
        let this_section = this.section_links.find(element => element.id == id);
        if (entry.intersectionRatio > 0) {
          // make the variable true
          this_section.active = true;
        } else {
          // make that section inactive
          this_section.active = false;
        }
      })
    })

    // find all sections in this form and observer them
    document.querySelectorAll('section').forEach((section) => {
      // push it as well
      if (!section.id.startsWith('section')) {
        this.section_links.push({
          name: section.getElementsByTagName('h2')[0].innerHTML,
          active: false,
          id: section.id,
          icon: section.getAttribute('data-attr')
        });
        this.observer.observe(section);
      }
    })

    // add an event listener to make sure that observer is released
    window.addEventListener('beforeunload', (event) => {
      this.observer.disconnect();
      delete this.observer;
    });
  }

  saveSettings() {
    for (let key in this.attachmentName) {
      delete this.settings.value[key];
    }

    // join phrases and add
    this.settings.value['phrases'] = this.phrases.join(',');

    // set secondary color
    this.settings.value['secondary_color'] = this.settings.value['primary_color'];

    this.dataService.putURL(`user/company/`, {
      ...this.settings.value,
    }).subscribe((res: Company) => {
      location.reload();
    }, (err) => {
      this.handleErrors(err);
    });
  }

  loadCompanyDetails(company: Company) {
    this.companyDetails = company;

    if (this.companyDetails.is_external_sharing_disabled) {
      this.settings.get('is_external_views_shown').disable();
    }

    this.settings.patchValue({
      ...this.companyDetails,
      bg_image: '',
      company_logo: '',
      intro_video: '',
      outro_video: '',
      overlay_logo: ''
    });

    this.attachmentName.bg_image = this.companyDetails.bg_image.split("/").pop().split("?")[0];
    this.attachmentName.overlay_logo = this.companyDetails.overlay_logo.split("/").pop().split("?")[0];
    this.attachmentName.font_file = this.companyDetails.font_file.split("/").pop().split("?")[0];
    this.attachmentName.company_logo = this.companyDetails.company_logo.split("/").pop().split("?")[0];
    this.attachmentName.intro_video = this.companyDetails.intro_video.split("/").pop().split("?")[0];
    this.attachmentName.outro_video = this.companyDetails.outro_video.split("/").pop().split("?")[0];

    this.is_global_manager = this.authService.userDetails.is_global_manager;
    if (this.companyDetails.max_upload_video_height == '720') {
      // free users see this message
      this.disable_settings_message = this.translateService.instant('Upgrade to use this feature');
    } else if (!this.is_global_manager) {
      // company managers see this, low priority message
      this.disable_settings_message = this.translateService.instant('Please contact your Clypp administrator');
    }

    // load phrases
    this.phrases = this.companyDetails.phrases.split(',');
  }

  externalSharingToggled(event){
    this.settings.get('is_external_views_shown')[event.checked ? 'disable' : 'enable']();
  }

  attachvalue(event, uploadName) {
    if (!this.settings.get(uploadName).hasError('maxContentSize')) {
      this.attachmentUpload(uploadName);
    }
  }

  attachmentUpload(fileName) {
    let attachment = this.settings.value[fileName]._files[0];
    let formData = new FormData();
    formData.append(fileName, attachment, attachment.name);
    const fileInput = <HTMLInputElement>document.querySelector(`ngx-mat-file-input[formcontrolname=${fileName}] input[type="file"]`);
    fileInput.value = null;

    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });

    this.dataService.putURL(`user/company/`, formData, {responseType: 'json', observe: 'body'}).subscribe(
      (resp: Company) => {
        let uploaded = _('Hochgeladen');
        this.snackBar.open(uploaded, '', {duration: 2000});
        this.authService.company = resp;
        this.loadCompanyDetails(resp);
        this.settings.markAsPristine();
        dialogRef.close();
      },
      (error) => {
        this.handleErrors(error);
        this.settings.markAsPristine();
        dialogRef.close();
      }, () => {
        dialogRef.close();
      })
  }

  // user is changing colors
  primaryColorChangeStarted($event) {
    // update in real time
    let hex_color = $event.target.value;
    this.primaryColorSetupService.savePrimaryColor(hex_color);
  }

  // user exited the color picker
  primaryColorChangeEnded() {
    // reset to actual primary color
    this.primaryColorSetupService.savePrimaryColor(this.companyDetails.primary_color);
  }

  handleErrors(err) {
    if (err.error?.name) {
      alert(err.error.name[0]);
    } else {
      alert(this.translateService.instant('Ein Fehler ist aufgetreten'));
    }
  }

  removePhrase(phrase) {
    const index = this.phrases.indexOf(phrase);
    if (index >= 0) {
      this.phrases.splice(index, 1);
    }
    this.settings.markAsDirty();
  }

  addPhrase(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.phrases.push(value);
    }
    event.chipInput!.clear();
    this.settings.markAsDirty();
  }

  openIPAddressDialog(event) {
    event.preventDefault();
    this.dialog.open(AllowedIpAddressesComponent, {
      disableClose: false,
      autoFocus: false,
    });
  }

  openWatermarkDialog() {
    this.dialog.open(WatermarkPreviewComponent, {
      height: "90vh",
      width: "70vw",
      disableClose: false,
      data: {
        watermark: this.companyDetails.overlay_logo,
        bg_image: this.companyDetails.bg_image,
        position: this.settings.value['overlay_code']
      }
    });
  }

  scrollTo(section) {
    let element = document.querySelector('#' + section);
    element.scrollIntoView({block: "start", behavior: "smooth"});
  }

  discardChanges() {
    this.loadCompanyDetails(this.authService.company);
    this.settings.markAsPristine();
  }

  openFeedbackDialog(){
    this.dialog.open(FeedbackDialogComponent, {
      width: "40%",
      minWidth: "400px",
      autoFocus: false,
      disableClose: false,
      hasBackdrop: true
    });
  }

  openProfileDialog() {
    this.dialog.open(VideoProfileComponent, {
      width: '90vw',
      minWidth: '400px',
      height: '90vh',
      autoFocus: false,
      disableClose: false,
      hasBackdrop: true,
    });
  }
}
