import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from 'src/app/services/data.service';
import {MiniDetails} from "../../models/video/video.interface";
import {AuthService} from "../../services/auth.service";
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';


@Component({
  selector: 'app-combine-tag-dialog',
  templateUrl: './combine-tag-dialog.component.html',
  styleUrls: ['./combine-tag-dialog.component.scss']
})

export class CombineTagDialogComponent implements OnInit {

  destination_tag_id: number = 0;
  allTags: MiniDetails[] = [];
  sourceTag: MiniDetails = null;
  videos_in_this_tag: number = 0;
  tag_form_control = new FormControl();
  filteredOptions: Observable<MiniDetails[]>;

  constructor(
    private dataService: DataService,
    public dialogRef: MatDialogRef<CombineTagDialogComponent>,
    private translateService: TranslateService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.sourceTag = this.data.sourceTag;
  }

  ngOnInit(): void {
    // get videos_in_this_tag
    this.dataService.getURL(`manager/tags/${this.sourceTag.id}/`, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: any) => {
      this.videos_in_this_tag = res.n_videos;
      // only load all tags if needed
      this.allTags = JSON.parse(JSON.stringify(this.authService.tag_data));
      this.allTags = this.allTags.filter(e => e.id != this.sourceTag.id);
      // Push the default object at the 0th index
      this.allTags.unshift({id: 0, name: this.translateService.instant('Keine')});
      this.filteredOptions = this.tag_form_control.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || ''))
      );
    });
  }

  reassignTags() {
    const message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      const form_data = {
        source_tag_id: this.sourceTag.id,
        destination_tag_id: this.destination_tag_id
      };
      this.dataService.putURL(`user/tags/`, form_data).subscribe((res: any) => {
        this.dialogRef.close(true);
      }, (err) => {
        window.alert(err.error);
      });
    }
  }

  // this method either re-assigns videos or simply deletes the tag
  deleteOrReassignTag() {
    if (this.destination_tag_id) {
      this.reassignTags();
      // dialog is closed
    } else {
      // user does not want to re-assign, simply send delete call
      this.dataService.deleteURL(`manager/tags/${this.sourceTag.id}/`).subscribe((res: any) => {
        this.dialogRef.close(true);
      }, (err) => {
        window.alert(err.error);
      });
    }
  }

  onItemSelect(item: MiniDetails) {
    this.destination_tag_id = item.id;
  }

  displayFn(tag: number) {
    const selectedObject = this.allTags.find(obj => obj.id == tag);
    if (selectedObject) {
      // return the display value based on the object properties
      return selectedObject.name;
    }
  }

  private _filter(value: string) {
    if (typeof value == 'string') {
      const filterValue = value?.toLowerCase().trim();
      return this.allTags.filter(option =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }
}
