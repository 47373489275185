<div class="m-3 d-flex justify-content-between flex-wrap">
  <div class="d-flex justify-content-start align-items-center">
    <mat-icon class="mr-1 material-icons-outlined" color="primary" mat-icon>
      play_circle_outline
    </mat-icon>
    <mat-card-title class="fontStyle mt-0 mb-0 mr-4" translate>
      All Playlists
    </mat-card-title>
    <button mat-stroked-button class="border-radius-20" routerLink="/start">
      <mat-icon>chevron_left</mat-icon>
      <span translate>Back</span>
    </button>
  </div>

  <mat-slide-toggle (change)="featuredToggle($event)" [checked]="isFeatured" class="mr-4">
    <span class="text-nunito" translate>
        Featured only
    </span>
  </mat-slide-toggle>
</div>


<div (scrolled)="getAllPlaylists();" [fromRoot]="true" [infiniteScrollContainer]="'mat-sidenav-content'"
     [infiniteScrollDistance]="1" [infiniteScrollThrottle]="500" [scrollWindow]="false" class="container-fluid"
     infiniteScroll>

  <div class="d-flex justify-content-center" style="flex-flow: wrap;">
    <!--video card grid-->
    <h3 translate *ngIf="playlists.length == 0 && !showSpinner">
      No results. Potentially search for other terms.
    </h3>
    <app-video-card-playlist *ngFor="let playlist of playlists"
                             [playlist_object]="playlist" class="mx-2">
    </app-video-card-playlist>
  </div>

  <div *ngIf="showSpinner" class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>

  <mat-card-subtitle *ngIf="page>maxPages" class="text-center">
    Showing all results
  </mat-card-subtitle>
</div>
