import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DataService} from '../../services/data.service';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit {
  processedOptList: string[] = [];
  feedbackForm = this.formBuilder.group({
    subject: ['', [Validators.required]],
    message: ['', [Validators.required]],
    contact: [''],
    uas: [navigator.userAgent]
  });


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              public translateService: TranslateService,
              public snackBar: MatSnackBar,
              public dataService: DataService,
              private formBuilder: UntypedFormBuilder,
              public dialogRef: MatDialogRef<FeedbackDialogComponent>) {
    // feedback list options
    let optList: string[] = [
      "Issues with Video Recording",
      "Video Processing Failed",
      "Problems with Profile Settings",
      "Problems with Subscription",
      "Modify Domains",
      "Modify Number of Creators",
      "Modify Number of Viewers",
      "Modify Video Time Limit",
      "Modify Video Resolution",
      "Modify maximum upload size",
      "Feedback about User Interface",
      "Application suddenly logs out",
      "Delete my account",
      "Delete my workspace",
      "Buy Credits",
      "Other"
    ];

    // translating the feedback options according to language
    this.processedOptList = optList.map((elem) => {
      return this.translateService.instant(elem);
    })
  }

  ngOnInit(): void {
  }

  //close the mat-dialog
  closePopup(): void {
    this.dialogRef.close({});
  }

  // post call on click of submit
  formInputSubmit() {
    //passing the feedbackForm value which includes UAS value
    this.dataService.postURL('user/support/', this.feedbackForm.value, {observe: 'response'}).subscribe((res: any) => {
      if (res.status === 200) {
        this.snackBar.open(this.translateService.instant('Thank you for your message!'), '', {duration: 2500});
      } else {
        this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2500});
      }
      this.dialogRef.close({});
    }, (err) => {
      this.snackBar.open(err, '', {duration: 2500});
      this.dialogRef.close({});
    });
  }
}
