<div class="d-flex justify-content-between" mat-dialog-title>
  <h1 class="text-nunito" translate>Upload Clypps</h1>
  <button (click)="closeDialog()" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <!--todo: limit drop zone-->
  <!--drop zone-->
  <div (dragleave)="showHideDropDiv(true);" (dragover)="$event.preventDefault();"
       (drop)="mep.close(); filesSelected($event, true);" class="w-100 h-100 border-radius-10 position-absolute"
       hidden id="drop-div"
       style="top: 0; left: 0; z-index: 2; display: flex;background-color: rgba(0, 0, 0, 0.70);">
    <mat-card-title class="text-white d-flex align-items-center justify-content-center w-100 h-100">
      {{'Drag and drop files to upload'|translate}}
    </mat-card-title>
  </div>

  <!--settings block-->
  <mat-accordion>
    <!--expansion for existing profile-->
    <mat-expansion-panel #mep="matExpansionPanel" class="mat-elevation-z0 border-radius-10"
                         expanded style="border: 1px solid lightgray;">
      <mat-expansion-panel-header>
        <mat-panel-title class="text-nunito">
          <mat-icon class="material-icons-outlined mr-2">settings</mat-icon>
          {{'Einstellungen'|translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <!--setting options-->
      <div class="d-flex flex-wrap">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 d-inline-block">
          <!-- language field-->
          <mat-form-field appearance="outline">
            <mat-label translate>Language</mat-label>
            <mat-select [(ngModel)]="audio_language">
              <mat-option *ngFor="let option of languages" [value]="option[0]">
                {{option[1]}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label translate>Recording Quality</mat-label>
            <mat-select [(ngModel)]="project_settings" matTooltipPosition="left">
              <mat-option value="720">
                {{'Fast Processing (720p)'|translate}}
              </mat-option>
              <mat-option value="1080">
                {{'Optimal (1080p)'|translate}}
              </mat-option>
              <mat-option [disabled]="disable_high_quality" id="high-quality-option" value="1440">
                {{'High Resolution (1440p)'|translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!--only show this field if you are a global manager or there are more than 0 options-->
          <mat-form-field appearance="outline">
            <!--Translation is not needed as same in German-->
            <mat-label>Content Branding</mat-label>
            <mat-select [(ngModel)]="ci_id">
              <mat-option [value]="0">
                {{'Default'|translate}}
              </mat-option>
              <mat-option *ngFor="let option of videoProfileOptions" [value]="option.id">
                {{option.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 d-inline-block">
          <!--toggle for auto creating subtitles-->
          <div class="d-flex justify-content-between my-4">
            <span class="text-nunito d-flex align-items-center">
                <mat-icon class="material-icons-outlined pr-4 mr-2" color="primary">subtitles</mat-icon>
              {{'Create subtitles' | translate}}
            </span>
            <mat-slide-toggle [(ngModel)]="auto_create_subtitles"
                              [disabled]="!authService.company.is_transcription_service_enabled">
            </mat-slide-toggle>
          </div>
          <!--toggle for auto creating descriptions-->
          <!--todo: unhide and implement auto summary-->
          <div class="d-none justify-content-between my-4">
            <span class="text-nunito d-flex align-items-center">
                <mat-icon class="material-icons-outlined pr-4 mr-2"
                          color="primary">auto_fix_high</mat-icon>
              {{'Auto-create description' | translate}}
            </span>
            <mat-slide-toggle [(ngModel)]="auto_create_desc"
                              [disabled]="!(authService.company.is_transcription_service_enabled && auto_create_subtitles)">
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <!--file upload and drag and drop-->
  <div class="d-flex mt-4 text-nunito">
    <div (dragenter)="showHideDropDiv(false);" class="dropzone position-relative w-100 border-radius-10">
      <div class="d-flex align-items-center" style="flex-direction: column;">
        <div class="mb-3" style="color: #7A7A7D;">{{'Drag and drop files to upload'|translate}}</div>
        <button class="border-radius-20 mb-3" mat-stroked-button>
          <label class="h-100 cursor-pointer align-items-center d-flex justify-content-center m-0"
                 for="file-upload" style="width: 150px;">
            <mat-icon class="mr-2" mat-icon>upload_file</mat-icon>
            <span translate>Select Videos</span>
          </label>
        </button>
        <input (change)="mep.close(); filesSelected($event,false);" [multiple]="true"
               accept=".mp4,.mov,.m4v,.webm,.mkv,.mts"
               class="d-none" id="file-upload" type="file"/>
        <div style="color: #7A7A7D;">{{'up to'|translate}}&nbsp;{{MAX_LIMIT}}&nbsp;Clypps</div>
      </div>
    </div>
  </div>

  <!--uploaded video list-->
  <div *ngIf="all_videos.length" class="my-2 text-nunito">
    <div class="mt-2">
      {{getFinishCount()}} {{'von' | translate}} {{all_videos.length}} {{'Done' | translate | lowercase }}
    </div>

    <div *ngFor="let item of all_videos, let i = index" class="my-2">
      <hr>
      <div class=" d-flex justify-content-between">
        <span class="text-nunito d-flex align-items-center">
          <mat-spinner *ngIf="item.state=='PR'" [diameter]="30"></mat-spinner>
          <mat-icon *ngIf="item.state == 'UP'" class="material-icons-outlined">file_upload</mat-icon>
          <mat-icon *ngIf="item.state == 'CC'">closed_caption_off</mat-icon>
          <mat-icon *ngIf="item.state == 'SU'">auto_fix_high</mat-icon>
          <mat-icon *ngIf="item.state == 'FA'" class="material-icons-outlined" color="warn">warning</mat-icon>
          <mat-icon *ngIf="item.state == 'CO'" class="material-icons-outlined" color="primary">play_circle</mat-icon>
          <div class="d-flex flex-column ml-2">
            <span class="mb-1">{{item.title}}</span>
            <span *ngIf="item.state == 'UP'" class="text-gray" translate>Uploading</span>
            <span *ngIf="item.state == 'PR'" class="text-gray" translate>
              Your Clypp is being processed
            </span>
            <span *ngIf="item.state == 'FA'" class="text-danger" translate>Failed</span>
            <span *ngIf="item.state == 'CC'" class="text-gray" translate>Transcribing</span>
            <span *ngIf="item.state == 'SU'" class="text-gray" translate>Putting on the finishing touches…</span>
            <span *ngIf="item.state == 'CO'" class="text-success" translate>Done</span>
          </div>
        </span>
        <div class="d-flex align-items-center">
          <button (click)="editVideo(item)" aria-label="delete video" class="cursor-pointer ml-2"
                  mat-icon-button>
            <mat-icon>open_in_new</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr *ngIf="all_videos.length > 0">
<mat-dialog-actions *ngIf="all_videos.length > 0"
                    [matTooltip]="isEverythingComplete() ? '' : ('Bitte warten...'|translate)"
                    align="end"
                    matTooltipPosition="below">
  <!--publish toggle-->
  <mat-slide-toggle [(ngModel)]="is_published" [disabled]="!isEverythingComplete()">
      <span class="text-nunito" style="font-size: 14px;" translate>
          Publish all Clypps
      </span>
  </mat-slide-toggle>
  <!--done button-->
  <button (click)="publishVideos()" [disabled]="!isEverythingComplete()"
          class="ml-3" color="primary"
          mat-stroked-button>
    Done
  </button>
</mat-dialog-actions>
