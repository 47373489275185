import { Component, Input, OnInit } from '@angular/core';
import { ControlsConfigInterface } from '../../interfaces/controls-config.interface';


@Component({
  selector: 'app-screencast-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {


  @Input()
  startButtonConfig: ControlsConfigInterface;

  @Input()
  stopButtonConfig: ControlsConfigInterface;

  @Input()
  rerecordButtonConfig: ControlsConfigInterface;

  disabledReset = true;
  disabledStart = false;
  disabledResume = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
