import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../services/auth.service';
import {DataService} from '../../services/data.service';
import {PlaylistCard} from "../../models/playlist/playlist";
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from "@angular/material/sort";
import { UploaderChangeDialogComponent } from '../uploader-change-dialog/uploader-change-dialog.component';


@Component({
  selector: 'playlist-management',
  templateUrl: './playlist-management.component.html',
  styleUrls: ['./playlist-management.component.scss']
})
export class PlaylistManagementComponent implements OnInit {
  search_query: string = "";
  filter_option: string = "published";
  all_playlists: PlaylistCard[] = [];
  displayedColumns: string[] = ['title', 'added_on', 'creator', 'actions'];
  dataSource: MatTableDataSource<PlaylistCard>;

  @ViewChild(MatSort) sort: MatSort;

  authorised: boolean = false;
  showSpinner: boolean = false;

  constructor(private authService: AuthService,
              private dialog: MatDialog,
              private dataService: DataService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar) {
    this.authorised = this.authService.userDetails.is_quality_manager || this.authService.userDetails.is_team_manager;
  }

  ngOnInit(): void {
    this.loadPlaylists();
  }

  loadPlaylists() {
    if (this.authorised) {
      this.showSpinner = true;

      this.dataService.getURL<PlaylistCard[]>('manager/playlists/', {observe: 'body', responseType: 'json'})
        .subscribe((res: PlaylistCard[]) => {
            this.all_playlists = res;
            this.updateFilter();
          }, (err) => {
            window.alert(err.error);
          }, () => {
            this.showSpinner = false;
          }
        );
    }
  }

  // update a playlist, including uploader
  managerPlaylistAction(playlist: PlaylistCard, action: string = 'draft', uploader: number = 0) {
    // only quality manager can perform a playlist action
    let message = `${playlist.uploader.first_name} ${playlist.uploader.last_name} `;
    message += this.translateService.instant("will also be notified about this.");
    message += '\n\n';
    message += this.translateService.instant("Would you like to continue?");

    if (window.confirm(message)) {
      this.dataService.putURL(`manager/playlists/?pl_id=${playlist.id}`,
        {
          action: action,
          uploader: uploader  // change the uploader of playlist. This is optional except for changing uploader.
        },
        {observe: 'body', responseType: 'json'}).subscribe((res) => {

        // show snackbar
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2000});

        // reload this page data if drafting a video
        if (action == 'draft') {
          // go to page 1, otherwise invalid page error may arise
          playlist.is_draft = true;
          playlist.is_featured = false;
        }
        else if (action == 'uploader') {
          // go to page 1
          this.loadPlaylists();
        }
        // no else case yet
      }, (err) => {
        window.alert(err.error);
      });
    }
  }

  // filter function to search in video title
  customFilterPredicate() {
    const myFilterPredicate = (data: PlaylistCard, filter: string): boolean => {
      let searchString = filter.trim().toLowerCase();
      // search in title or email
      return data.title.toLowerCase().indexOf(searchString) != -1 ||
        data.uploader.email.toLowerCase().indexOf(searchString) != -1;
    }
    return myFilterPredicate;
  }

  applyFilter(){
    this.dataSource.filter = this.search_query.trim().toLowerCase();
  }

  updateFilter() {
    let filtered_playlists: PlaylistCard[] = [];
    if (this.filter_option === 'published') {
      filtered_playlists = this.all_playlists.filter(ele => !ele.is_draft);
    } else if (this.filter_option === 'drafts') {
      filtered_playlists = this.all_playlists.filter(ele => ele.is_draft);
    } else if (this.filter_option === 'featured') {
      filtered_playlists = this.all_playlists.filter(ele => ele.is_featured);
    } else {
      // empty
    }
    this.dataSource = new MatTableDataSource(filtered_playlists);
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.sort = this.sort;
    this.applyFilter();  // keep prev search
  }

  // go to playlist view page
  viewPlaylist(id: number){
    window.open(`playlistView/${id}`, '_blank');
  }

  // change the uploader of a playlist
  // opens a dialog where checklist data is fetched
  // user must be a quality admin
  changeUploader(playlist: PlaylistCard){
    if(!this.authService.userDetails.is_quality_manager){
      const message = this.translateService.instant("You need Video Management rights to edit this");
      this.snackBar.open(message, '', {duration: 3000});
      return;
    }
    const dialogRef = this.dialog.open(UploaderChangeDialogComponent, {
      minWidth: '400px',
      width: '50vw',
      maxWidth: '600px',
      data: playlist,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // confirm and save
        if (result != playlist.uploader.id) {
          this.managerPlaylistAction(playlist, 'uploader', result)
        }
      }
    });
  }
}

