<mat-nav-list>
  <button mat-list-item (click)="performAction('approve')">
    <mat-icon class="mr-2">done_all</mat-icon>
    <span translate>Freigeben</span>
  </button>
  <button mat-list-item (click)="performAction('revoke')">
    <mat-icon class="mr-2">remove_done</mat-icon>
    <span translate>Revoke approval</span>
  </button>
  <button mat-list-item (click)="performAction('feature')">
    <mat-icon class="mr-2">star</mat-icon>
    <span translate>Hervorheben</span>
  </button>
  <button mat-list-item (click)="performAction('unfeature')">
    <mat-icon class="mr-2">star_border</mat-icon>
    <span translate>Nicht hervorheben</span>
  </button>
  <button mat-list-item (click)="performAction('internal')">
    <mat-icon class="mr-2">public_off</mat-icon>
    <span translate>Deactivate external sharing</span>
  </button>
  <button mat-list-item (click)="performAction('external')">
    <mat-icon class="mr-2">public</mat-icon>
    <span translate>Enable external sharing</span>
  </button>
  <button mat-list-item (click)="performAction('unlisted')">
    <mat-icon class="mr-2">vpn_lock</mat-icon>
    <span>{{'Enable external sharing'|translate}}&nbsp;({{'Unlisted'|translate|lowercase}})</span>
  </button>
  <button mat-list-item (click)="performAction('draft')">
    <mat-icon class="mr-2">cloud_off</mat-icon>
    <span translate>Save as draft</span>
  </button>
</mat-nav-list>
