import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'tooltipList'
})
export class TooltipListPipe implements PipeTransform {

  constructor(public translateService: TranslateService){

  }

  transform(lines: string[]): string {

    let list: string = '';

    lines.forEach((line,index) => {
      let tranlastedLines = this.translateService.instant(line);
        list += ((index != 0) ? '• ' : '  ') + tranlastedLines + '\n';
    });

    return list;
  }
}