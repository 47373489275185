<div class="d-flex justify-content-between mb-1" mat-dialog-title>
  <div class="d-flex align-items-center">
    <mat-icon class="material-icons-outlined mr-2" color="primary">
      feed
    </mat-icon>
    <span class="fontStyle mr-3" translate>Chapters</span>
  </div>

  <div class="d-flex align-items-center">
    <button (click)="saveChapter()"
            *ngIf="is_anything_changed"
            [disabled]="chapterData.length <= 1"
            aria-label="save chapter" class="h-auto border-radius-20 mr-2" mat-stroked-button>
      {{ "Speichern" | translate }}
    </button>

    <!--delete all-->
    <button (click)="deleteChapters()"
            *ngIf="data.chapter_file"
            class="mr-2"
            mat-icon-button matTooltip="{{'Delete all'|translate}}">
      <mat-icon>delete_outline</mat-icon>
    </button>

    <button cdkFocusInitial mat-icon-button>
      <mat-icon (click)="closePopup();">close</mat-icon>
    </button>
  </div>
</div>

<mat-card-subtitle class="text-nunito">
  {{"Choose a time in the video player and click \"Add chapter\"" | translate}}
</mat-card-subtitle>

<div *ngIf="chapterData.length"
     class="d-flex flex-wrap justify-content-around overflow-auto mt-1"
     style="height: 460px;">
  <div class="overflow-hidden d-flex flex-column mt-3 w-50 align-items-center"
       style="max-width: 600px;">

    <!--https://github.com/vidstack/examples/blob/main/player/web-components/default-theme/video-player.html -->
    <media-player (duration-change)="onPlayerReady();"
                  *ngIf="is_player_ready"
                  crossorigin="anonymous"
                  playsinline
                  preload="metadata"
                  src="{{data.processed_file}}"
                  type="video/mp4">
      <media-provider>
        <!--Chapter-->
        <track *ngIf="data.chapter_file"
               [src]="data.chapter_file"
               srclang="en-US"
               default
               kind="chapters"
               label="chapter"/>
      </media-provider>

      <!-- Gestures -->
      <media-gesture action="toggle:paused" event="pointerup"></media-gesture>
      <media-gesture action="toggle:fullscreen" event="dblpointerup"></media-gesture>
      <media-gesture action="toggle:controls" event="pointerup"></media-gesture>
      <media-gesture action="seek:-10" event="dblpointerup"></media-gesture>
      <media-gesture action="seek:10" event="dblpointerup"></media-gesture>

      <!-- Controls -->
      <media-controls class="vds-controls">
        <div class="vds-controls-spacer"></div>
        <!-- Time Slider Controls Group -->
        <media-controls-group class="vds-controls-group">
          <!-- Time Slider -->
          <media-time-slider class="vds-time-slider vds-slider">
            <media-slider-chapters class="vds-slider-chapters">
              <template>
                <div class="vds-slider-chapter">
                  <div class="vds-slider-track"></div>
                  <div class="vds-slider-track-fill vds-slider-track"></div>
                  <div class="vds-slider-progress vds-slider-track"></div>
                </div>
              </template>
            </media-slider-chapters>

            <div class="vds-slider-thumb"></div>

            <!-- Slider Preview -->
            <media-slider-preview class="vds-slider-preview bg-dark">
              <div class="vds-slider-chapter-title" data-part="chapter-title"></div>
              <media-slider-value class="vds-slider-value"></media-slider-value>
            </media-slider-preview>
          </media-time-slider>
        </media-controls-group>
        <!-- Lower Controls Group -->
        <media-controls-group class="vds-controls-group">
          <!-- Play Button -->
          <media-tooltip>
            <media-tooltip-trigger>
              <media-play-button class="vds-button">
                <media-icon class="vds-play-icon" type="play"></media-icon>
                <media-icon class="vds-pause-icon" type="pause"></media-icon>
                <media-icon class="vds-replay-icon" type="replay"></media-icon>
              </media-play-button>
            </media-tooltip-trigger>
            <media-tooltip-content class="vds-tooltip-content" placement="top">
              <span class="vds-play-tooltip-text">Play</span>
              <span class="vds-pause-tooltip-text">Pause</span>
            </media-tooltip-content>
          </media-tooltip>

          <!-- Mute Button -->
          <media-tooltip>
            <media-tooltip-trigger>
              <media-mute-button class="vds-button">
                <media-icon class="vds-mute-icon" type="mute"></media-icon>
                <media-icon class="vds-volume-low-icon" type="volume-low"></media-icon>
                <media-icon class="vds-volume-high-icon" type="volume-high"></media-icon>
              </media-mute-button>
            </media-tooltip-trigger>
            <media-tooltip-content class="vds-tooltip-content" placement="top">
              <span class="vds-mute-tooltip-text">Unmute</span>
              <span class="vds-unmute-tooltip-text">Mute</span>
            </media-tooltip-content>
          </media-tooltip>

          <!-- Volume Slider -->
          <media-volume-slider class="vds-slider">
            <div class="vds-slider-track"></div>
            <div class="vds-slider-track-fill vds-slider-track"></div>
            <media-slider-preview class="vds-slider-preview" no-clamp>
              <media-slider-value class="vds-slider-value"></media-slider-value>
            </media-slider-preview>
            <div class="vds-slider-thumb"></div>
          </media-volume-slider>

          <!-- Time Group -->
          <div class="vds-time-group">
            <media-time class="vds-time" type="current"></media-time>
            <div class="vds-time-divider">/</div>
            <media-time class="vds-time" type="duration"></media-time>
          </div>

          <media-chapter-title class="vds-chapter-title"></media-chapter-title>

          <div class="vds-controls-spacer"></div>
        </media-controls-group>
      </media-controls>
    </media-player>

    <button (click)="addChapterRow();" class="h-auto w-100 mt-3 border-radius-20" mat-stroked-button>
      <mat-icon class="icon-for-standard-button">add</mat-icon>
      <span class="text-nunito" translate>
        Add chapter
      </span>
    </button>
  </div>

  <div class="d-flex flex-column overflow-auto h-fill-available pl-4 w-50"
       style="max-width: 600px; min-width: 400px;">
    <!--create tiles-->
    <div *ngFor="let chapter of chapterData; let index = index;"
         class="d-flex w-100 align-items-center justify-content-between">
      <span>{{index + 1}}</span>
      <mat-form-field appearance="outline" style="width: 15%;">
        <input (change)="chapterChanged($event, index);"
               [disabled]="index == 0"
               [value]="chapter.time"
               class="text-center"
               matInput
               maxlength="5" minlength="5" placeholder="00:00"
               required
               type="text">
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 65%;">
        <input (change)="is_anything_changed=true;" [(ngModel)]="chapter.title"
               matInput
               maxlength="50"
               minlength="1" placeholder="{{'Titel'|translate}}" required
               type="text">
      </mat-form-field>

      <button (click)="deleteChapter(index);" [disabled]="index==0" mat-icon-button>
        <mat-icon>delete_outline</mat-icon>
      </button>

    </div>
  </div>
</div>
