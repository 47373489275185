<div class="d-flex justify-content-center align-items-center">
  <div class="f-pswd">
    <!-- enter mail ID -->
    <div *ngIf="email.length == 0">
      <form (ngSubmit)="mailFormClicked()" [formGroup]="mailIDForm">
        <mat-card style="background-color:transparent !important;padding: 0px !important;">
          <mat-card-header class="justify-content-center">
            <mat-card-title class="fontStyle" style="font-weight: bold;margin-bottom: 2rem;" translate>
              Reset Password
            </mat-card-title>
          </mat-card-header>
          <mat-card-header class="justify-content-center">
            <mat-card-subtitle class="fontNormal" translate>Bitte gib hier deine geschäftliche Email-Adresse an
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content style="display: flex;justify-content: center;">
            <mat-form-field appearance="outline" class="fontNormal" hideRequiredMarker
                            style="height: 60px;font-size: 18px;width:75%">
              <mat-label translate>Email-Adresse</mat-label>
              <input email formControlName="email" matInput
                     autocomplete="email"
                     placeholder="abc@firma.de" required type="email"/>
            </mat-form-field>
          </mat-card-content>
          <mat-card-header style="justify-content: center;">
            <mat-card-subtitle>
              <p style="font-size:16px; text-align: center;">
                <span translate>Es werden lediglich Email-Adressen von Organisationen akzeptiert,</span><br>
                <span translate>die ein aktives Clypp-Abonnement besitzen.</span>
              </p>
            </mat-card-subtitle>
          </mat-card-header>
          <div class="mx-4 d-flex justify-content-between">
            <button (click)="mailFormBackClicked()" mat-stroked-button type="button">
              {{'Zurück'|translate}}
            </button>
            <button id="next" [disabled]="mailIDForm.get('email').invalid" mat-stroked-button
                   type="submit">
              {{'Weiter'|translate}}
            </button>
          </div>
        </mat-card>
      </form>
    </div>

    <!-- verify otp -->
    <div>
      <div *ngIf="(otp.toString().length == 0 && email.length > 0) || otpVerified ">
        <form (ngSubmit)="otpFormClicked()" [formGroup]="otpForm">
          <mat-card style="background-color:transparent !important;padding: 0px !important;">
            <mat-card-header class="justify-content-center">
              <mat-card-title class="fontStyle" style="font-weight: bold;" translate>Reset Password</mat-card-title>
            </mat-card-header>
            <mat-card-header style="justify-content: center;">
              <mat-card-subtitle [style.color]="otpVerified ? '#979797' : '#000000'" class="fontNormal"
                                 style="font-size:15px; text-align: center">
                {{'Bitte gib hier den 4-stelligen PIN-Code ein,'|translate}}
                <br>
                {{'der soeben an deine geschäftliche Email-Adresse gesendet wurde:' | translate}}
                {{email}}
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content style="display:flex;justify-content:center;">

              <div id="divOuter">
                <div id="divInner">
                  <input [readonly]="otpVerified"
                         [style.color]="otpVerified ? '#979797' : '#000000'" formControlName="otp"
                         id="partitioned" maxlength="4" onKeyPress="if(this.value.length==4) return false;"
                         oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                         type="text"/>
                </div>
              </div>
              <!-- </div> -->
            </mat-card-content>

            <mat-card-header style="justify-content: center;">
              <mat-card-subtitle [style.color]="otpVerified ? '#979797' : '#000000'" class="fontNormal"
                                 style="font-size:15px; text-align: center" translate>
                Noch keinen PIN-Code erhalten?<br>
                Bitte sieh im Spam-Ordner nach<br>
                oder
              </mat-card-subtitle>
            </mat-card-header>

            <div class="w-100 d-flex justify-content-center">
              <button (click)="sendOTP();"
                 [disabled]="otpVerified"
                 mat-button
                 translate>
                PIN erneut senden
              </button>
            </div>
            <mat-card-actions *ngIf="!otpVerified">
              <button (click)="otpFormBackClicked()" mat-stroked-button type="button">
                {{'Zurück'|translate}}
              </button>
            </mat-card-actions>
          </mat-card>
        </form>
      </div>
      <div *ngIf="otpVerified">
        <form [formGroup]="regForm">
          <mat-divider></mat-divider>
          <mat-card>
            <mat-card-content class="d-flex justify-content-center">
              <mat-form-field appearance="outline" class="font-medium text-nunito w-100 my-2" hideRequiredMarker>
                <mat-label translate>New password</mat-label>
                <input [type]="hidePassword ? 'password' : 'text'" #password matInput formControlName="password"
                       (keydown.enter)="registered();"
                       autocomplete="new-password">
                <button (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword" mat-icon-button
                        matSuffix>
                  <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="regForm.get('password').hasError('required')">
                  <span translate>Required</span>
                </mat-error>
                <mat-error *ngIf="regForm.get('password').hasError('minlength')">
                  <span translate>Das Passwort muss aus mindestens 10 Zeichen bestehen</span>
                </mat-error>
                <mat-error *ngIf="regForm.get('password').hasError('maxlength')">
                  <span translate>Das Passwort sollte weniger als 30 Zeichen lang sein</span>
                </mat-error>
                <mat-error *ngIf="regForm.get('password').hasError('pattern')">
                  <span translate>(at least one uppercase, one lowercase letter, one special character and a number)</span>
                </mat-error>
              </mat-form-field>
            </mat-card-content>

            <mat-card-actions class="d-flex justify-content-between">
              <button (click)="otpFormBackClicked()" mat-stroked-button type="button" style="float: left;">
                {{'Zurück'|translate}}
              </button>
              <button [disabled]="regForm.get('password').invalid"
                      (click)="registered();"
                      mat-stroked-button style="float:right">
                {{'Speichern'|translate}}
              </button>
            </mat-card-actions>
          </mat-card>
        </form>
      </div>
    </div>
  </div>
</div>

