<div class="d-flex justify-content-between">
  <h1 mat-dialog-title class="mb-0">{{"Change content creator"|translate}}</h1>
  <button mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>

<span mat-dialog-title class="text-nunito">{{data.title}}</span>

<div mat-dialog-content>
  <mat-form-field appearance="outline" class="w-100 mb-3">
    <mat-label translate>Creator</mat-label>
    <mat-hint class="text-nunito" translate>Content can only be reassigned to other creators</mat-hint>

    <input [formControl]="checklist_form_control" [matAutocomplete]="auto"
           matInput placeholder="{{'Name'|translate}}" type="text">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option [value]="option.name"
                  (onSelectionChange)="onItemSelect(option)"
                  (click)="onItemSelect(option)"
                  *ngFor="let option of filteredOptions | async">
        {{option.name}}&nbsp;({{option.string}})
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-card-subtitle *ngIf="currently_selected_item" class="text-nunito m-2">
    {{'Selected'|translate}}:&nbsp;{{currently_selected_item.name}}&nbsp;({{currently_selected_item.string}})
  </mat-card-subtitle>
</div>

<div align="end" mat-dialog-actions>
  <button (click)="save()" [disabled]="currently_selected_item == null" cdkFocusInitial mat-stroked-button>
    {{'Transfer'|translate}}
  </button>
</div>
