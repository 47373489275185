import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {NavbarService} from '../services/navbar.service';


export interface Path {
  path: string;
  name: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  routePath: Path[] = [];

  constructor(public authService: AuthService,
              private navbarService: NavbarService) {
    this.routePath = [
      {
        path: 'content',
        name: 'Manage content'
      },
      {
        path: 'user',
        name: 'Manage Users',
      },
      {
        path: 'global',
        name: 'Manage Workspace',
      },
      {
        path: 'company',
        name: 'Settings & Design',
      },
      {
        path: 'analytics',
        name: 'Analytics',
      }]
  }

  ngOnInit(): void {
    this.navbarService.getUserProfileDetails();
  }
}
