<div class="d-flex justify-content-start align-items-center mx-3 mt-3">
  <mat-icon class="mr-1 material-icons-outlined" color="primary" mat-icon>
    play_circle_outline
  </mat-icon>
  <mat-card-title class="fontStyle mr-4 my-0">
    {{userVideos.length}}&nbsp;{{userVideos.length == 1 ? 'Clypp' : 'Clypps'}}
    {{'by' | translate}}&nbsp;{{userName}}
  </mat-card-title>
  <button (click)="backTo()" class="border-radius-20" mat-stroked-button>
    <mat-icon>chevron_left</mat-icon>
    <span translate>Back</span>
  </button>
</div>

<div class="p-3 d-flex justify-content-center" style="flex-flow: wrap;">
  <app-video-card *ngFor="let video of userVideos"
                  [backendUrl]="backendUrl"
                  [videoObject]="video">
  </app-video-card>
</div>
