<div style="overflow-y:auto !important;position: relative;" class="w-100 quickwin-creation backgroundpng">

  <div *ngIf="progress > 0" class="spinner-overlay">
    <div style="position: absolute;top:40%;left :50%;z-index:999;opacity: 1;" class="mt-3 text-center text-white">
      <div class="spinner-background">{{progress}}%</div>
      <mat-progress-spinner class="spinner" #spinner color="primary" mode="determinate" [value]="progress">
      </mat-progress-spinner>
      <div *ngIf="progress == 100 || processFinish" class="processText"><span translate>Bitte warten...</span></div>
    </div>
  </div>

  <div class=" w-100 h-100 mx-auto" style="z-index:9;position: relative;">

    <div fxLayout="row wrap">
      <div fxFlex="20%" >
        <div fxLayout="column" style="height: 100%;">
          <!--logo-->
          <div fxFlex="10%">
            <img class="imgresize" [src]="authService.company?.company_logo" alt="company logo" (click)="goToHome()">
          </div>
        <!--timer-->
        <div fxFlex="15%">
          <app-timer #videoTimer style="color: black !important;" [timeLimit]="timeLimit">
          </app-timer>
        </div>
        <!--control btn-->
        <div fxFlex="70%">
          <ng-container>
            <app-screencast-controls #controls
              [stopButtonConfig]="{
                            action: stopWebcamRecording,
                            videotype: VideoTypeValue,
                            pause: pauseRecording,
                            resume: resumeRecording,
                            pauseStatus: paused,
                            display: webcamRecorderService.recording() || (!webcamRecorderService.recording() && paused && !videoRecordingFinished)}"
              [startButtonConfig]="{
                             action: startWebcamRecording,
                             videotype:VideoTypeValue,
                             display: !webcamRecorderService.recording() && !videoRecordingFinished && !paused}"
              [rerecordButtonConfig]="{
                             action: openConfirmOverride,
                             videotype:VideoTypeValue,
                             display: !webcamRecorderService.recording() && videoRecordingFinished}" disabled>
            </app-screencast-controls>
          </ng-container>

          <div style="position: relative;top:3rem;">
            <div style="display:flex;justify-content: space-around">
              <button mat-fab [ngClass]="disabledReset ?  'disablewebbigIcon' : 'bigWebButton'" [disabled]="disabledReset"
              style="box-shadow: none !important;" (click)="moveToReview()">
                <mat-icon class="restartBig">keyboard_tab</mat-icon>
              </button>
            </div>

            <div class="mt-4 textcolor"
              style="display: flex;color: #000000 !important;justify-content:center;margin-right:0.5rem !important;">
              <span>{{nextLabel|translate}}</span>
            </div>
          </div>
          </div>
      </div>
    </div>
        <div fxFlex="60%" style="height: 100%;">
          <div fxLayout="column" style="height: 100%;">
            <!--notes-->
          <div fxFlex="45%" class="w-100" style="margin-top:0.5rem">
            <app-notes [rows]="rowval" [notes]="notes"  (notesChange)="notesChange($event)"></app-notes>
          </div>

          <div fxFlex="40%">
            <div fxLayout="row">
              <div fxFlex="15%">
              </div>
              <div fxFlex="70%">
                <div style="height: 56.5% ;width: 100%;position: relative;">
                  <app-webcam-viewer #webcam (startButtonClickedChanged)="startTestWebcam(false)" [recording]="isRecording"
                  [pause]="paused" [countdown]="showCountdown" style="color:black;" [src]="webcamDispVideo" [type]="'WEBCAM'">
                </app-webcam-viewer>
                <div class="countdown" style="margin-left: 0%;margin-top:12%;position: absolute; color:white;font-size: 120px !important;" *ngIf="showCountdown">
                  {{timeLeft}}</div>
                </div>
              </div>
              <div fxFlex="15%">
              </div>
            </div>

          </div>
          </div>
        </div>
        <div fxFlex="20%" style="height: 100%;">
          <div fxLayout="column" style="height: 100%;">
            <div style="display: flex;justify-content: flex-end; float: right; margin-right:1rem;margin-top:0.5rem;height: 30px;">
            <a href="{{'TIPS_URL' | translate}}" target="_blank">
              <p class="tips" translate>Tipps ansehen
                <mat-icon style="vertical-align: bottom;">tips_and_updates</mat-icon></p>
            </a>
            </div>

          <div [hidden]="!showAudio || micoffOnRecreate" style="position: relative;clear: both;margin-top: 7rem;">
            <div class="fontNormal" style="margin-top: 0.8rem;display: flex;justify-content: center;cursor:pointer;"
              (click)="openAudioEnable()" translate>Einstellungen
              <span class="invert-triangle"></span>
            </div>

            <div class="rectOption" [hidden]="!showAudioOption">
              <div style="height: 100%;" class="row">
                <div class="col-2">
                  <button style="margin-left: 5px;margin-top: 1.1rem;margin-bottom:5px;outline-color: transparent;z-index: 99;
         position: relative;border:0px solid transparent;cursor: default;">
                    <mat-icon style="display: flex;justify-content: center;font-size: 27px;width: 27px;height: 27px;">
                      {{audioIcon}}</mat-icon>
                  </button>
                </div>
                <div class="col-10 fontNormal" style="margin-top: 4%;
        padding-left: 12%;">
                  <span class="fontNormal">
                    <mat-form-field style="width: 175px !important;margin-top: -0.9rem !important;" appearance="outline">
                      <mat-select placeholder="Audio Input" (selectionChange)="onAudioDropChange($event)"
                        [(ngModel)]="defaultValue">
                        <mat-option *ngFor="let audio of audioinputVal" [value]="audio">
                          {{audio.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </span>
                </div>
              </div>
              <div class="row" style="margin-top: -0.5rem;">
                <div class="col-2">

                </div>
                <div class="col-10">
                  <canvas #meter style="margin-left:1.3rem;margin-top:0.6rem;width: 80%;" [width]="WIDTH"
                    [height]="HEIGHT"></canvas>
                </div>
              </div>
              <div style="margin-top: -0.7rem;" class="row">
                <div class="col-2">
                  <button style="margin-left: 5px;margin-top: 1.1rem;margin-bottom:5px;outline-color: transparent;z-index: 99;
       position: relative;border:0px solid transparent;cursor: default;">
                    <mat-icon style="display: flex;justify-content: center;font-size: 27px;width: 27px;height: 27px;">
                      videocam</mat-icon>
                  </button>
                </div>
                <div class="col-10 fontNormal" style="margin-top: 4%;
      padding-left: 12%;">
                  <span class="fontNormal">
                    <mat-form-field style="width: 175px !important;margin-top: -0.9rem !important;" appearance="outline">
                      <mat-select placeholder="Video Input" (selectionChange)="onVideoDropChange($event)"
                        [(ngModel)]="defaultVideoValue">
                        <mat-option *ngFor="let video of videoInputValue" [value]="video">
                          {{video.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </span>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

</div>
