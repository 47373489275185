<div class="d-flex justify-content-between" mat-dialog-title>
  <span class="fontStyle">{{'Subtitles for' | translate}}:&nbsp;{{videoObj?.title}}</span>
  <button mat-icon-button style="margin-top: -10px;">
    <mat-icon [mat-dialog-close]="false">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="d-flex justify-content-around" style="flex-flow: wrap">
  <div class="w-50 p-2" style="min-width: 350px;">
    <form [formGroup]="transcript_form" class="w-100 h-100">
      <mat-form-field appearance="outline" style="width: 40%;" class="w-100 h-100">
        <mat-label>Transcript</mat-label>
        <textarea formControlName="transcriptInput" matInput
          style="min-height: 43vh; resize:none !important; font-family:'nunito';"></textarea>
        <mat-hint translate>Please only edit texts, not time stamps.</mat-hint>
      </mat-form-field>
    </form>
  </div>
  <div class="w-50 text-center p-2" style="min-width: 350px;">
    <video id="subtitle-player" [src]="videoObj.processed_file" controls
           controlsList="nodownload nofullscreen noremoteplayback" disablepictureinpicture playsinline
           class="mt-3"
           style="border: 1px solid lightgray; border-radius: 10px; width: 90%; aspect-ratio: 16/9;"
           (loadedmetadata)="metaLoaded()" crossorigin="anonymous" preload="metadata" type="video/mp4">
      <track [src]="videoObj.vtt_file" default kind="subtitles" [srclang]="videoObj.audio_language">
      Your browser does not support the video tag.
    </video>

    <!--seek buttons-->
    <div class="justify-content-center d-flex w-100 mt-2">
      <button (click)="seekBackward()" class="mr-4"
              mat-icon-button
              title="{{'Backward'|translate}}">
        <mat-icon>replay_5</mat-icon>
      </button>
      <button (click)="seekForward()" mat-icon-button
              title="{{'Forward'|translate}}">
        <mat-icon>forward_5</mat-icon>
      </button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button (click)="deleteSubtitlePopup()" class="mr-3" mat-stroked-button style="border-color: red !important;">
    <span translate>Löschen</span>
  </button>

  <button (click)='saveTranscript()' [disabled]="!transcript_form.dirty" color="primary" mat-stroked-button>
    <span translate>Speichern</span>
  </button>
</mat-dialog-actions>
