<div class="d-flex mb-2" style="justify-content: space-between; align-items: baseline;">
  <span class="fontStyle" translate>Nutzerimport mit CSV-Datei</span>
  <button (click)="closeDialog()" mat-icon-button>
    <mat-icon class="cancel-icon">close</mat-icon>
  </button>
</div>

<mat-stepper orientation="vertical" linear="false" #stepper *ngIf="!csvUploadResp">
  <mat-step>
    <ng-template matStepLabel>
      {{"Nutze die CSV Vorlage für das richtige Format (optional)" | translate}}
    </ng-template>
    <div class="d-flex">
      <button class="col-2" mat-stroked-button style="line-height: 38px;" matStepperNext>
        {{"Weiter" | translate}}
      </button>
      <button (click)="DownloadCSV()" class="ml-3" mat-stroked-button style="line-height: 38px;" matStepperNext>
        <span translate>Vorlage herunterladen</span>&nbsp;
      </button>
    </div>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>{{"Aktualisiere die Vorlage mit Nutzerdaten" | translate}}</ng-template>
    {{mattooltip1}}
    <button class="col-2" mat-stroked-button style="line-height: 38px;" matStepperNext>
      {{"Weiter" | translate}}
    </button>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>
      {{"If desired, check this box to test your file upload in a dry run" | translate}}
    </ng-template>
    <div class="d-flex" style="align-items: baseline;">
      {{"Dry run" | translate}}<mat-slide-toggle [(ngModel)]="dry_run" class="col-2 ml-2"></mat-slide-toggle>
      <button class="col-2 ml-3" mat-stroked-button style="line-height: 38px;" matStepperNext>
        {{"Weiter" | translate}}
      </button>
    </div>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>{{"Lade die aktualisierte Datei hier hoch" | translate}}</ng-template>
    {{"Jede Spalte, die nicht exakt einer Spalte der Vorlage entspricht, wird beim Hochladen ignoriert." | translate}}

    <form [formGroup]="csvForm" style="text-align-last: center; justify-content: space-around;">
      <mat-form-field appearance="outline"
                      class="profile-field-group-mid-item fontNormal justify-content-center justify-content-lg-end w-75">
        <ngx-mat-file-input (change)="attachCSV($event,'csv_file')" (click)="csvfieldSelected()" [accept]="'.csv'"
                            [multiple]="false" formControlName="csv_file"
                            style="padding-left:0.5rem;font-family: nunito;font-weight:500;"
                            valuePlaceholder="{{'Upload CSV File' | translate}}"></ngx-mat-file-input>
        <mat-icon matSuffix ngxMatFileInputIcon>upload</mat-icon>
      </mat-form-field>
    </form>
  </mat-step>
</mat-stepper>

<div class="load-csv" style="height: 90%;">
  <div *ngIf="csvUploadResp" class="row d-flex resp fontNormal tablesize">

    <span *ngIf="dry_run" translate>Dry run results</span>
    <span *ngIf="!dry_run" translate>
      Successfully imported. To revert any changes, you can delete a user using bulk operations.
    </span>

    <table [dataSource]="dataSource" class="example-container1 mat-elevation-z8 mt-3" mat-table>
      <ng-container matColumnDef="id">
        <th *matHeaderCellDef mat-header-cell>Row</th>
        <td *matCellDef="let element" [matTooltip]="element.id" class="email" mat-cell> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th *matHeaderCellDef mat-header-cell>Status</th>
        <td *matCellDef="let element" mat-cell style="width: 50%;"> {{element.value}} </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
  </div>
</div>
