import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from '../services/auth.service';
import {DataService} from '../services/data.service';
import {vidDurationFormat} from '../services/utility.service';
import {TranslateService} from '@ngx-translate/core';

interface PopupData {
  id: string,
  duration: number,
  chapter_file: string,
  processed_file: string,
}

interface ChapterBlock {
  time: string;
  title: string;
  seconds: number;
}

@Component({
  selector: 'app-chapter-creation-popup',
  templateUrl: './chapter-creation-popup.component.html',
  styleUrls: ['./chapter-creation-popup.component.scss'],
  providers: [vidDurationFormat],
})
export class ChapterCreationPopupComponent implements OnInit {
  chapterData: ChapterBlock[] = [];
  video_player: HTMLVideoElement = null;
  is_player_ready: boolean = false;
  is_anything_changed: boolean = false;

  constructor(
    private snackBar: MatSnackBar,
    public dataService: DataService,
    public translateService: TranslateService,
    public authService: AuthService,
    public vidDuration: vidDurationFormat,
    public dialogRef: MatDialogRef<ChapterCreationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopupData) {
  }

  ngOnInit(): void {
    if (this.data.chapter_file != null) {
      this.dataService.readVTTFile(this.data.chapter_file).subscribe(
        result => {
          this.parseVTTContent(result);
          this.is_player_ready = true;
        }
      );
    } else {
      this.chapterData = [{time: '00:00', title: 'Start', seconds: 0}];
      this.is_player_ready = true;
    }

    this.dialogRef.backdropClick().subscribe(() => {
      this.closePopup();
    });
  }

  onPlayerReady() {
    this.video_player = document.querySelector('media-player') as unknown as HTMLVideoElement;
    // this.video_player = document.querySelector('video') as HTMLVideoElement;
  }

  // reading the value from vtt to desired format
  parseVTTContent(vttContent: string) {
    const lines = vttContent.split('\n');
    let index = 0;
    for (let line of lines) {
      if (line.includes('-->')) {
        const time = line.split('-->').map(part => part.trim())[0];
        const seconds = stringToSeconds(time);
        const title = lines[index + 1].trim();
        this.chapterData.push({
          time, seconds, title
        });
      }
      index += 1;
    }
  }

  addChapterRow(): void {
    const seconds = this.video_player.currentTime;
    const time = this.vidDuration.transform(seconds);
    // check if exists:
    if (this.chapterData.findIndex(ele => ele.time == time) > -1) {
      this.snackBar.open(this.translateService.instant('Already exists'), '', {duration: 2000});
      return;
    }
    // else: add
    const title = "Chapter title";
    this.chapterData.push({time, seconds, title});
    this.is_anything_changed = true;
    // sort data
    this.sortChapters();
    // window.alert(this.translateService.instant('Invalid! Chapters should be at least 10 seconds apart and should not exceed the total video duration.'));
  }

  sortChapters() {
    this.chapterData.sort((a, b) => a.seconds - b.seconds);
  }

  // save chapter on click of done button
  saveChapter() {
    if (!this.is_anything_changed) {
      return;
    }

    // check if anything is empty
    const empties = this.chapterData.filter(ele => ele.title.trim() == "").length;
    if (empties) {
      this.snackBar.open(this.translateService.instant('Please provide a title'), '', {duration: 2000});
      return;
    }

    // add last one to the block
    this.chapterData.push({
      time: this.vidDuration.transform(this.data.duration),
      title: 'end',
      seconds: this.data.duration
    });

    let formattedOutput = "";
    for (let chapter of this.chapterData) {
      formattedOutput += `${chapter.time} ${chapter.title.trim()}\n`;
    }
    this.is_player_ready = false;
    this.dataService.patchURL(`user/videos/${this.data.id}/`, {'chapter_string': formattedOutput}, {
      observe: 'body',
      responseType: 'text'
    }).subscribe((res: any) => {
      this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
      this.data.chapter_file = res;
      this.chapterData.splice(-1, 1);
      this.is_player_ready = true;
      this.is_anything_changed = false;
    });
  }

  chapterChanged(event, index) {
    const time = event.target.value;
    const seconds = stringToSeconds(time);
    if (seconds) {
      // check if not greater
      if (seconds < this.data.duration - 1) {
        // check if not there
        if (this.chapterData.findIndex(ele => ele.seconds == seconds) == -1) {
          // update and sort
          this.chapterData[index].time = time;
          this.chapterData[index].seconds = seconds;
          this.is_anything_changed = true;
          this.sortChapters();
          return;
        }
      }
    }
    // else revert
    event.srcElement.value = this.chapterData[index].time;
    this.snackBar.open(this.translateService.instant('Invalid input'), '', {duration: 2000});
  }

  deleteChapter(index) {
    this.is_anything_changed = true;
    this.chapterData.splice(index, 1);
  }

  // deletes and closes
  deleteChapters() {
    const message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      this.dataService.patchURL(`user/videos/${this.data.id}/`, {'chapter_string': 'delete'}, {
        observe: 'body',
        responseType: 'text'
      }).subscribe((res: any) => {
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2000});
        this.dialogRef.close(null);
      });
    }
  }

  closePopup() {
    if (this.is_anything_changed) {
      const message = this.translateService.instant("Bist du sicher?");
      if (!window.confirm(message)) {
        // do not close popup
        return;
      }
    }
    this.dialogRef.close(this.data.chapter_file);
  }
}

function stringToSeconds(innerText: string) {
  // expects 00:00 like string
  // todo: add support for 00:00:00 like string
  // todo: check positive
  try {
    const time_strings = innerText.split(':')
    if (parseInt(time_strings[0]) <= 59) {
      if (parseInt(time_strings[1]) <= 59) {
        const time_seconds = parseInt(time_strings[0]) * 60 + parseInt(time_strings[1]);
        if (time_seconds > 0) {
          return time_seconds;
        }
      }
    }
  } catch {
    return null;
  }
  // will come here in case of wrong time
  return null;
}
