import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { NavbarService } from '../services/navbar.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-view-all-comments',
  templateUrl: './view-all-comments.component.html',
  styleUrls: ['./view-all-comments.component.scss']
})
export class ViewAllCommentsComponent implements OnInit {
  responsedata: any = [];
  backendUrl: string;
  constructor(public dataService: DataService,private translateService:TranslateService, private snackBar: MatSnackBar, private breakpointObserver: BreakpointObserver, private navbarService: NavbarService, public authService: AuthService) {

  }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;

    this.dataService.getURL(`user/comments/`).subscribe((data: any) => {
      this.responsedata = data;
    })
  }
  deleteComment(id) {
    this.dataService.deleteURL(`user/comments/${id}/`).subscribe((res) => {
      if (res == null) {
        this.snackBar.open('comment deleted successfully', '', { duration: 2500 });
        this.ngOnInit();
      }
      else {
        this.snackBar.open('Failed to delete the comment', '', { duration: 2500 })
      }
    });
  };

}
