import {AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnInit, ViewChild} from '@angular/core';
import {NavItem} from './interfaces/nav-item';
import {AuthService} from './services/auth.service';
import {NavbarComponent} from './shared/navbar/navbar.component'
import {NavbarService} from './services/navbar.service';
import {DataService} from "./services/data.service";
import {DomSanitizer, Title} from '@angular/platform-browser';
import {ActivatedRoute, ActivationStart, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {animateText, sideNavAnimation, sideNavContainerAnimation} from './services/animation';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatSidenav} from '@angular/material/sidenav';
import en from '../assets/i18n/en.json';
import de from '../assets/i18n/de.json';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';
import {SharedService} from "./services/shared.service";
import { PrimaryColorSetupService } from './services/primary-color-setup.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [sideNavAnimation, sideNavContainerAnimation, animateText]
})
export class AppComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(NavbarComponent) navBar: NavbarComponent;
  showTopBar = true;
  showSideBar = true;  // this value is needed to keep track in width change block
  isSmallScreen: boolean = false;  // <=700 px screens
  isExpanded = false;  // controls the display of text on sidebar, always true in small screen
  @ViewChild(MatSidenav, {static: false}) sidenav: MatSidenav;
  currentUser: any;
  navList: NavItem[] = [];
  isTeamsBrowser: boolean = false;  // in teams browser, we do not show side nav
  showFooter: boolean = false;
  constructor(private route: Router, private router: ActivatedRoute,
              private titleService: Title, private dialog: MatDialog,
              private primaryColorSetupService: PrimaryColorSetupService,
              private breakpointObserver: BreakpointObserver, private cdr: ChangeDetectorRef,
              public authService: AuthService, private sanitizer: DomSanitizer, public navbarService: NavbarService,
              public dataService: DataService, public translateService: TranslateService,
              public sharedService: SharedService) {
    this.primaryColorSetupService.savePrimaryColor(localStorage.getItem("primary_color") || '#00096F');

    let userLang = navigator.language;
    translateService.setTranslation('en', en);
    translateService.setTranslation('de', de);
    translateService.setDefaultLang('de');
    let langResolver = userLang.split("-")[0];

    let storedPreference = localStorage.getItem('language');
    if (storedPreference) {
      translateService.use(storedPreference);
    } else {
      let decidedLang;
      if (translateService.getLangs().includes(langResolver)) {
        decidedLang = langResolver;
      } else {
        decidedLang = "en";
      }
      translateService.use(decidedLang);
      localStorage.setItem('language', decidedLang);
      // document.documentElement.setAttribute("lang", decidedLang);
    }

    this.route.events.pipe(filter(event => event instanceof ActivationStart)).subscribe(event => {
      let title = event['snapshot'].data.title;
      try {
        title = this.translateService.instant(title);
      } catch (e) {
        title = event['snapshot'].data.title;
      }
      this.titleService.setTitle(`${title} - Clypp`);

      if(event['snapshot'].data.topBar==undefined){
        this.showTopBar = true;
      }
      else{
        this.showTopBar = event['snapshot'].data.topBar;
      }
      if(event['snapshot'].data?.footer==undefined){
        this.showFooter = false;
      }
      else{
        this.showFooter = true;
      }
      if(event['snapshot'].data.sideBar==undefined){
        this.navbarService.showSideNav = true;
      }
      else{
        this.navbarService.showSideNav = event['snapshot'].data.sideBar;
        this.showSideBar = event['snapshot'].data.sideBar;
      }
      if(event['snapshot']._routerState.url == '/dashboard'){
        this.route.navigate(['/dashboard/video']);
      }
    });

    this.sharedService.sideBarExpansion.subscribe((booleanValue) => {
      if(this.isSmallScreen){
        // small screen, navbar is always expanded, but hidden. Show/hide it
        this.isExpanded = true;
        if (booleanValue) {
          this.sidenav.open();
        } else {
          this.sidenav.close();
        }
      }
      else {
        // large screen, navbar is always shown, expand/collapse it
       this.isExpanded = booleanValue;
      }
    })

    this.navList = [
      {
        name: 'Start',
        link: '/start',
        icon: 'home'
      },
      // {
      //   name: this.translateService.instant('Verlauf'),
      //   link: '/history',
      //   icon: 'history'
      // },
      // {
      //   name: this.translateService.instant('Likes'),
      //   link: '/favVideos',
      //   icon: 'thumb_up'
      // },
      {
        name: 'My Library',
        link: '/library',
        icon: 'video_library'
      },
      {
        name: 'Gruppen',
        link: '/groups',
        icon: 'groups',
      },
      {
        name: 'Requests',
        link: '/requests',
        icon: 'maps_ugc',
      },
    ]

    this.translateService.onLangChange.subscribe((event) => {
      let title = this.getTitle(route.routerState, route.routerState.root).join('-');
      title = this.translateService.instant(title);
      this.titleService.setTitle(`${title} - Clypp`);
      // this.navList = this.navList.map(item => {
      //   return {...item, name: this.translateService.instant(item.name)};
      // })
    });

    this.dataService.dataCarrier.subscribe((data) => {
      if (data.loginSuccess) {
        if (window.innerWidth > 700) {
          // big screen
          this.isSmallScreen = false;
          this.navbarService.showSideNav = true;
          this.sidenav && this.sidenav.open();
        } else {
          // small screen
          this.isSmallScreen = true;
          this.navbarService.showSideNav = true;
          this.sidenav && this.sidenav.close();
        }
      }
    });
  }

  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  // TO HIDE NAVBAR OPEN ON MOBILE FIRST VIEW LOAD, WE NEED TO MAKE navbarService.showSideNav observable
  ngOnInit(): void {
    // load banners and languages when the app starts
    this.authService.loadBanners();
    this.authService.loadLanguages();

    this.checkIsManager();
    if (!navigator.cookieEnabled) {
      // alert user to enable cookies
    }

    if (navigator.userAgent.indexOf('Teams') > -1) {
      this.isTeamsBrowser = true;
    }
  }

  close() {
    this.isExpanded = !this.isExpanded;
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.breakpointObserver.observe(['(max-width: 699px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.isSmallScreen = true;
        this.sidenav.close();
      } else {
        this.isSmallScreen = false;
        this.sidenav.mode = 'side';
        this.navbarService.showSideNav = this.showSideBar;
        this.sidenav.open();
      }
    });
    this.router.queryParams.subscribe(params => {
      if (params['create-box']) {
        this.navBar.createBox();
      }
    });
  }

  ngOnChanges() {
    // this.showTopBar = true;
  }

  onActivate(event) {
    document.querySelector("mat-sidenav-content").scrollTo(0, 0);
  }

  private checkIsManager(): void {
    this.authService.getIsAuthenticated().subscribe((isAuthenticated: boolean) => {
      if (isAuthenticated) {
        this.dataService.getURL<any>('user/').subscribe((res) => {
          this.authService.userDetails = res;
          this.currentUser = res;
          let local_storage_language = localStorage.getItem('language');
          if (res.email_language == "") {
            // language is empty, use local storage
            if (local_storage_language) {
              // not null
              this.translateService.use(local_storage_language);
            } else {
              // there is no language in local storage, nor in response
              // use browser language
            }
          } else {
            // user has already set a language, use that one
            if (res.email_language.indexOf('de') == 0) {
              local_storage_language = 'de';
            } else {
              local_storage_language = 'en';
            }
          }
          // now, lang must be having some value, either from local storage or from response
          this.translateService.use(local_storage_language);
          // update it in local storage for future logins
          localStorage.setItem('language', local_storage_language);
          document.documentElement.setAttribute("lang", local_storage_language);
          if (!this.isSmallScreen) {
            // large screen, hide sidenav if profile is incomplete
            if (!this.currentUser?.is_profile_completed) {
              this.navbarService.showSideNav = false;
            }

            // check if dashboard is there
            const index = this.navList.findIndex(e => e.icon == 'dashboard');
            if (index > -1) {
              this.navList.pop();  // remove the last element
            }

            // user is a manager, append to nav list
            if (res.is_global_manager || res.is_company_manager || res.is_department_manager || res.is_team_manager || res.is_quality_manager) {
              this.navList.push({
                name: 'Dashboard',
                link: '/dashboard',
                icon: 'dashboard'
              },)
            }

            // if user is a creator, show my library?

          }
        });
      }
    })
  }

  backdropClick() {
    this.sharedService.sideBarExpansion.next(false);
  }
}
