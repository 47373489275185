import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as ysFixWebmDuration from '../scripts/fix-webm-duration.js';

@Injectable({
  providedIn: 'root'
})
export class WebcamRecorderService {

  private videoChunks: Array<any>;

  public mediaRecorder: MediaRecorder;
  private mediaRecorderTesting: MediaRecorder;

  private startTime: number;
  private duration = 0;
  private isPaused = false;
  videoSource = undefined;
  videoMode: any;
  audioInputDetected: any;
  videoInputDetect: any;
  currentVideoDevice : any;
  audioMuted: boolean = true;
  audio:any;
  private audioInputVal = new Subject<string>();
  audioVal = this.audioInputVal.asObservable();
  private videoInputVal = new Subject<string>();
  videoVal = this.videoInputVal.asObservable();
  private videoCamId = new Subject<string>();
  activeVideoId = this.videoCamId.asObservable(); 
  audioSource = undefined;
  videoConstraints = undefined;
  selectedVal: any;
  selval: any;
  streamWebcam: any;
  videoDeviceId = undefined;
  videodevice = undefined;

  constructor() {
  }

  async recordWebcam(): Promise<any> {
    return new Promise(async resolve => {

      if (this.videoSource === undefined) {
        if (this.audioSource === undefined) {
          this.videoConstraints = {
            video: {
                          width: { ideal: 1280 },
                          height: { ideal: 720 },
                          facingMode: "user",
                          frameRate: 30
                      },
            audio: true
          }
        } else {
          this.videoConstraints = {
            video: {
              width: { ideal: 1280 },
              height: { ideal: 720 },
              facingMode: "user",
              frameRate: 30
          },
            audio: {
              deviceId: { exact: this.audioSource },
              echoCancellation: true,
              noiseSuppression: true
            }
          }
        }
      }
      else {
        if (this.audioSource === undefined) {
          this.videoConstraints = {
            video: {
              deviceId: { exact:this.videoSource }
          },
            audio: true
          }
        } else {
          this.videoConstraints = {
            video: {
                          deviceId: { exact:this.videoSource }
                      },
            audio: {
              deviceId: { exact: this.audioSource },
              echoCancellation: true,
              noiseSuppression: true
            }
          }
        }
      }

      //   if(this.videoSource === undefined){
      //     this.videoConstraints = {
      //         video: {
      //             width: { ideal: 1280 },
      //             height: { ideal: 720 },
      //             facingMode: "user",
      //             frameRate: 30
      //         },
      //         audio: true
      //     }
      // }else{
      //     this.videoConstraints ={
      //         video: {
      //             deviceId: { exact:this.videoSource }
      //         },
      //         audio: true
      //     }
      // }
      const stream = await navigator.mediaDevices.getUserMedia(this.videoConstraints);

      const options = {
        // audioBitsPerSecond : 128000,
        // videoBitsPerSecond : 5000000,
      };
      const supportedMimeTypes = this.getSupportedMimeTypes();
      options['mimeType'] = supportedMimeTypes[0];
      if (!MediaRecorder.isTypeSupported(options['mimeType'])) {
        console.error(`${options['mimeType']} is not supported`);
        options['mimeType'] = '';
      }

      this.mediaRecorder = new MediaRecorder(stream, options);
      this.videoChunks = [];

      this.mediaRecorder.addEventListener('dataavailable', event => {
        this.videoChunks.push(event.data);
      });


      const start = () => {

        this.duration = 0;
        this.isPaused = false;
        this.mediaRecorder.start(500);
        this.startTime = Date.now();
        return stream;
      };
      const pause = () => {
        this.mediaRecorder.pause();
        this.duration = this.duration + Date.now() - this.startTime;
        this.isPaused = true;
      };
      const resume = () => {
        this.mediaRecorder.resume();
        this.startTime = Date.now();
        this.isPaused = false;
        return stream;
      };

      const stop = () =>
        new Promise(stopResolve => {

          this.mediaRecorder.addEventListener('stop', async () => {
            const videoBlob = new Blob(this.videoChunks, { type: 'video/webm' });
            const videoUrl = await this.getFixedDurationBlobURL(videoBlob)
            const vidChunksArray = this.videoChunks;
            const vDuration = this.duration;
            stopResolve({ videoBlob, videoUrl, vidChunksArray, vDuration });
          });

          this.mediaRecorder.stop();
          stream.getTracks().forEach(track => track.stop());
          if (this.isPaused) {
            this.duration = this.duration;
          }
          else {
            this.duration = this.duration + (Date.now() - this.startTime);
          }
        });
      resolve({ start, pause, resume, stop });
    });
  }

  async webcamTesting(): Promise<any> {   
    return new Promise(async resolve => {
      if(this.videoSource === undefined){
        this.videoMode = {
            video: {
                width: { ideal: 1280 },
                height: { ideal: 720 },
                facingMode: "user",
                frameRate: 30
            },
            audio: false
        }
      }else{
          this.videoMode ={
              video: {
                width: { ideal: 1280 },
                height: { ideal: 720 },
                deviceId: { exact:this.videoSource }
              },
              audio: false
          }

      }
      this.streamWebcam = await navigator.mediaDevices.getUserMedia(this.videoMode)
      .then((strem: any) => strem)
      navigator.mediaDevices.enumerateDevices().then(devices =>{})
      .catch((err) => {console.log('Access Denied', err); return 'denied';});
      this.videoCamId.next(this.streamWebcam.getVideoTracks()[0].label);
      this.currentVideoDevice= this.streamWebcam.getVideoTracks()[0].label;
      const options = {
      };
      const supportedMimeTypes = this.getSupportedMimeTypes();
      options['mimeType'] = supportedMimeTypes[0];
      if(this.streamWebcam != 'denied') {
        this.mediaRecorderTesting = new MediaRecorder(this.streamWebcam, options);
        this.videoChunks = [];

        this.mediaRecorderTesting.addEventListener('dataavailable', event => {
          this.videoChunks.push(event.data);
        });
      }

      const start = () => {
        if(this.mediaRecorderTesting.state != 'recording'){
          this.mediaRecorderTesting?.start(500);
        }
        if(this.mediaRecorderTesting == undefined) {
          return 'denied'
        } else{
          return this.streamWebcam;
        }
      };
      const stop = () => {
        if(this.mediaRecorderTesting?.state === 'recording')
          this.mediaRecorderTesting?.stop();
          this.streamWebcam.getTracks().forEach(track => track.stop());
        this.streamWebcam.getTracks().forEach(track => track.stop());
      };
      resolve({ start, stop });
    });
  }
  stopWebcamTesting () {
    if(this.mediaRecorderTesting?.state === 'recording')
      this.mediaRecorderTesting?.stop();
      this.streamWebcam.getTracks().forEach(track => track.stop());
  };

  recordingTesting(): boolean {
    return this.mediaRecorderTesting?.state === 'recording';
  }

  recording(): boolean {
    return this.mediaRecorder?.state === 'recording';
  }

  getStream(): any {
    return this.mediaRecorder.stream;
  }

  getStreamTesting(): any {
    return this.streamWebcam;
  }

  getFixedDurationBlobURL(blob: Blob): Promise<string> {
    return new Promise(((resolve, reject) => {
      ysFixWebmDuration(blob, this.duration, res => {
        resolve(window.URL.createObjectURL(res));
      });
    }));
  }

  getSupportedMimeTypes(): object {
    const VIDEO_TYPES = [
      "webm",
      "ogg",
      "mp4",
      "x-matroska"
    ];
    const VIDEO_CODECS = [
      "vp9",
      "vp9.0",
      "vp8",
      "vp8.0",
      "avc1",
      "av1",
      "h265",
      "h.265",
      "h264",
      "h.264",
      "opus",
    ];

    const supportedTypes = [];
    VIDEO_TYPES.forEach((videoType) => {
      const type = `video/${videoType}`;
      VIDEO_CODECS.forEach((codec) => {
        const variations = [
          `${type};codecs=${codec}`,
          `${type};codecs:${codec}`,
          `${type};codecs=${codec.toUpperCase()}`,
          `${type};codecs:${codec.toUpperCase()}`
        ];
        variations.forEach(variation => {
          if (MediaRecorder.isTypeSupported(variation)) {
            supportedTypes.push(variation);
          }
        })
      });
      if (MediaRecorder.isTypeSupported(type)) {
        supportedTypes.push(type);
      }
    });
    return supportedTypes;
  }

  getAudioVal() {
    var audioArr = [];
    var videoArr = [];
    this.selectedVal = navigator.mediaDevices.getUserMedia({ audio: true })
      .then(() => navigator.mediaDevices.enumerateDevices())
      .then(devices => {
        devices.forEach(function (d) {
          if (d.kind === 'audioinput') {
            audioArr.push({ label: d.label, deviceId: d.deviceId });
          } else if (d.kind === 'videoinput') {
            videoArr.push({ label: d.label, deviceId: d.deviceId });
          }
        }
        );
         this.getVideoValue(videoArr);
        this.getAudioValue(audioArr);
      })
      
      .catch(e => console.log(e));
}

getAudioValue(data) {
  this.audioInputDetected = data;
  this.audioInputVal.next(this.audioInputDetected);
}
getVideoValue(data) { 
  this.videoInputDetect = data;
  this.videoInputVal.next(this.videoInputDetect);
}
getSelectionVal(data) {
  this.audioSource = data;
}
getVideoSelectionVal(data) {
  this.videoSource = data;
}

}
