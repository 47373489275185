import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AuthService} from '../../services/auth.service';
import {DataService} from '../../services/data.service';
import {FormControl, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss']
})
export class PasswordDialogComponent implements OnInit {

  hide_old = true;
  hide_new = true;

  oldPasswordFormControl = new FormControl('', [Validators.required])

  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(30),
    Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).*$/)
  ])

  constructor(private dataService: DataService,
              private dialog: MatDialog,
              private translateService: TranslateService,
              private authService: AuthService,) {
  }

  ngOnInit(): void {
  }

  savePassword() {
    if (this.passwordFormControl.valid && this.oldPasswordFormControl.valid) {
      let new_pass: string = this.passwordFormControl.value;
      let old_pass: string = this.oldPasswordFormControl.value;

      this.dataService.patchURL('user/basic/',
        {new_pass: new_pass, old_pass: old_pass}, {observe: 'body', responseType: 'text'}).subscribe((res) => {
        // success, logout
        let message = this.translateService.instant("Password changed successfully");
        message += '\n\n';
        message += this.translateService.instant("You will now be logged out of all devices.")
        window.alert(message);
        this.dialog.closeAll();
        this.authService.logout();
      }, (err) => {
        // failure
        console.error(err);
        window.alert(err.error);
      });
    }
  }

}
