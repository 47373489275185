import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {FileValidator} from 'ngx-material-file-input';
import { NavbarService } from '../../services/navbar.service';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-create-playlist',
  templateUrl: './create-playlist.component.html',
  styleUrls: ['./create-playlist.component.scss']
})
export class CreatePlaylistComponent implements OnInit {

  readonly maxSize = 5970000;
  playlistForm = this.formBuilder.group({
    title: ['', Validators.required],
    desc: [ '', Validators.required],
    is_draft: [true, Validators.required],
    thumbnail: [undefined, FileValidator.maxContentSize(this.maxSize)]
  });
  is_Draft= true;
  param: any;
  headerTitleLabel = 'Neue Playlist erstellen';
  submitBtnLabel =  'Erstellen';
  constructor(private formBuilder: UntypedFormBuilder,private cdr: ChangeDetectorRef, private navbarService: NavbarService, private dataService: DataService,  private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.navbarService.disableCreationMode();
    this.navbarService.getUserProfileDetails();
    this.cdr.detectChanges();
    this.route.paramMap.subscribe(map => {
      this.param = map.get('param');
      if (this.param !== 'create') {
        this.getPlaylist();
        this.headerTitleLabel = 'Playlist bearbeiten';
        this.submitBtnLabel =  'Speichern';
      }
    });
  }
  getPlaylist(): void {
    this.dataService.getURL(`user/playlists/${this.param}/`).subscribe((res: any) => {
      this.playlistForm.get('title').setValue(res.title);
      this.playlistForm.get('desc').setValue(res.desc);
      this.playlistForm.get('is_draft').setValue(res.is_draft);
      this.is_Draft = res.is_draft;
    });
  }
  changedToggle(event): void {
    if (event.source.name == 'is_draft') {
      this.is_Draft = event.checked;
      this.playlistForm.get(event.source.name).setValue(event.checked);
    }

  }
  createPlaylist(): void {
    if (this.playlistForm.status == 'VALID' )
    {
      let thumbnail_img;
      let formData;
      if (this.playlistForm.get('thumbnail').value != null && this.playlistForm.get('thumbnail').value != undefined) {
        thumbnail_img = this.playlistForm.get('thumbnail').value._files[0];
      }
      delete this.playlistForm.value['thumbnail']
      this.dataService.postURL(`user/playlists/`, this.playlistForm.value).subscribe((res: any) => {
        if (res['reason']) {
          window.alert(res['reason']);
        } else {
          if (thumbnail_img != null && thumbnail_img != undefined) {
            let blob = new Blob([thumbnail_img]);
            let file = new File([blob], '');
            formData = new FormData();
            formData.append('thumbnail', file, `photo.jpg`);
            this.dataService.putURL(`user/playlists/${res.id}/`, formData).subscribe((resData: any) => {
              this.router.navigate(['library', 'playlists']);
            });
          } else {
            this.router.navigate(['library', 'playlists']);
          }
        }
      });
    }
  }

  updatePlaylist(): void {
    if (this.playlistForm.status == 'VALID' )
    {
      let thumbnail_img;
      let formData;
      if (this.playlistForm.get('thumbnail').value != null && this.playlistForm.get('thumbnail').value != undefined) {
        thumbnail_img = this.playlistForm.get('thumbnail').value._files[0];
      }
      delete this.playlistForm.value['thumbnail']
      this.dataService.putURL(`user/playlists/${this.param}/`, this.playlistForm.value).subscribe((res: any) => {
        if (res['reason']) {
          window.alert(res['reason']);
        } else {
          if (thumbnail_img != null && thumbnail_img != undefined) {
            let blob = new Blob([thumbnail_img]);
            let file = new File([blob], '');
            formData = new FormData();
            formData.append('thumbnail', file, `photo.jpg`);
            this.dataService.putURL(`user/playlists/${this.param}/`, formData).subscribe((resData: any) => {
              this.router.navigate(['library', 'playlists']);
            });
          } else {
            this.router.navigate(['library', 'playlists']);
          }
        }
      });
    }
  }

  submitForm(): void {
    if (this.param === 'create') {
      this.createPlaylist();
    } else {
      this.updatePlaylist();
    }
  }

}
