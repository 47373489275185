import { Injectable } from '@angular/core';
import { ResolveStart } from '@angular/router';
import { Subject } from 'rxjs';
import * as ysFixWebmDuration from '../scripts/fix-webm-duration';

@Injectable({
    providedIn: 'root'
})
export class AudioRecorderService {
    stoppedCapturing: Subject<void> = new Subject();
    selval :any;
    audio: any;
    selectedVal: any;
    audioSource = undefined;
    private mediaRecorder: MediaRecorder;
    startTime;
    audioMuted: boolean = false;
    audioInputDetected: any;
    private audioInputVal = new Subject<string>();
    noiseSuppress: boolean = true;
    echoCancellation:boolean = true;
    audioVal = this.audioInputVal.asObservable();

    constructor() {
    }

    async recordAudio(): Promise<any> {
        return new Promise(async resolve => {
            if(this.audioSource === undefined){
                this.selval = {
                    audio: true
                    // audio: {echoCancellation: this.echoCancellation, noiseSuppression: this.noiseSuppress}
                }
            }else{
               this.selval = {
                    audio: {
                      deviceId: {exact: this.audioSource},
                      echoCancellation: this.echoCancellation,
                      noiseSuppression: this.noiseSuppress
                    }
                }
            }

            const stream = await navigator.mediaDevices.getUserMedia(this.selval)

            this.mediaRecorder = new MediaRecorder(stream);
            const audioChunks = [];

            this.mediaRecorder.addEventListener('dataavailable', event => {
                audioChunks.push(event.data);
            });

            const start = () => {
                this.mediaRecorder.start();
                this.startTime = Date.now();
                return stream;
            };

            const stop = () =>
                new Promise(stopResolve => {
                    this.mediaRecorder.addEventListener('stop', () => {
                        const audioBlob = new Blob(audioChunks, {type: 'audio/wav'});
                        const audioUrl = URL.createObjectURL(audioBlob);
                        const audio = new Audio(audioUrl);
                        const play = () => audio.play();
                        const duration = Date.now() - this.startTime;
                        stopResolve({audioBlob, audioUrl, play, duration});
                    });

                    this.mediaRecorder.stop();
                    stream.getTracks().forEach(track => {console.log('tracks', track);track.stop()});
                });
            resolve({start, stop});
        });
    }

    recording(): boolean {
        return this.mediaRecorder?.state === 'recording';
    }
    getSelectionVal(data){
        this.audioSource = data;
    }
    getStream(): any {
        return this.mediaRecorder.stream;
    }
    getAudioToggle(value): any {
        if (this.audio == undefined) {
            this.audioMuted = value;
        }
        else if (!value) {
            this.audioMuted = false;
            this.audio.getTracks().forEach(function (track) {
                track.enabled = false;
            });
        } else {
            this.audioMuted = true;
            this.audio.getTracks().forEach(function (track) {
                track.enabled = true;
            });

        }
    }

    getNoiseToggle(value): any {
        this.noiseSuppress = value;
        this.echoCancellation = value;
    }

    getAudioVal() {
        var newArr = [];
        this.selectedVal = navigator.mediaDevices.getUserMedia({ audio: true })
            .then(() => navigator.mediaDevices.enumerateDevices())
            .then(devices => {
                devices.forEach(function (d) {
                    if (d.kind === 'audioinput') {
                        newArr.push({ label: d.label, deviceId: d.deviceId });
                    }
                }
                );
                this.getValue(newArr);
            })
            .catch(e => console.log(e));
    }

    getValue(data) {
        this.audioInputDetected = data;
        this.audioInputVal.next(this.audioInputDetected);
    }

}
