import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-webcam-viewer',
  templateUrl: './webcam-viewer.component.html',
  styleUrls: ['./webcam-viewer.component.scss']
})
export class WebcamViewerComponent implements OnInit {

  @Input()
  recording: boolean;
  @Input()
  pause: boolean;

  @Input()
  src: MediaStream;

  @Input()
  type: string;

  @Input()
  videoType: string;

  @Input()
  countdown: boolean;

  @Output()
  startButtonClickedChanged = new EventEmitter<void>();

  display: string;
  permissionDenied = false;

  constructor() {
  }

  ngOnInit(): void {
  }
  ngOnChanges(event){
  }

  startButtonClicked(): void {
    this.startButtonClickedChanged.emit();
  }
}
