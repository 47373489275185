<div class="m-3 d-flex align-items-center" style="height: 36px;">
  <mat-icon class="mr-1 material-icons-outlined" color="primary">
    dashboard
  </mat-icon>
  <span class="fontStyle" style="font-size: x-large;" translate>Dashboard</span>
</div>

<!--links-->
<nav *ngIf="authService.userDetails" mat-tab-nav-bar>
  <a #rla="routerLinkActive" *ngFor="let route of routePath;
     let i=index"
     [active]="rla.isActive"
     [routerLink]="route.path"
     class="text-nunito font-weight-normal"
     mat-tab-link
     routerLinkActive="active">
    {{route.name|translate}}
  </a>
</nav>

<router-outlet *ngIf="authService.company && authService.userDetails"></router-outlet>
