import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  @Input() notes = ['', '', ''];
  @Input() rows:any;
  @Output() notesChange = new EventEmitter<Array<string>>();

  constructor() {
  }

  ngOnInit(): void {
  }

  setValue(index: number, $event: any): void {
    this.notes[index] = $event.target.value;
    this.notesChange.emit(this.notes);
  }
}
