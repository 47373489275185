<div *ngIf="inProgress" class="spinner-overlay d-flex justify-content-center align-items-center">
  <div class="mt-3 text-center text-white d-flex align-items-center flex-column">
    <mat-spinner [diameter]="60"></mat-spinner>
    <div class="processText"><span translate>Hold on while we set up your workspace</span></div>
  </div>
</div>

<div class="backgroundpng">
  <div class="d-flex justify-content-center align-items-center"
       style="flex-direction: column;line-height: 5rem;">
    <form  [formGroup]="companyregForm">
      <h2 class="mb-1 fontStyle" translate>Neue Organisation registrieren</h2>
      <div class="mt-2">
        <div class="row">
          <div class="col-md-9">
            <div class="d-flex">
              <mat-form-field appearance="outline">
                <mat-label translate>Email-Adresse</mat-label>
                <input email formControlName="email" matInput required style="font-size: 17px;padding-top: 5px;"
                       autocomplete="email" type="email"/>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-3" style="margin-top: -0.3em;">
            <button id="sendOtp" (click)="sendotp()" mat-stroked-button style="height: 2.4rem;" type="button"
                    [disabled]="!(companyregForm.get('email').value && companyregForm.get('is_privacy_accepted').value)">
              <span *ngIf="!resendOTP" translate>PIN senden</span>
              <span *ngIf="resendOTP" translate>PIN erneut senden</span>
            </button>
          </div>
        </div>
        <div>
          <mat-checkbox [checked]="isPrivacy" class="acptCheckbox fontNormal" formControlName="is_privacy_accepted">
            <a href="{{'POLICY_URL' | translate}}" target="_blank" class="text-dark" translate>
              {{'I agree to the' | translate}}&nbsp;{{'Clypp privacy policy' | translate}}
            </a>
          </mat-checkbox>
        </div>
        <div>
          <mat-error *ngIf="companyregForm.get('is_privacy_accepted').hasError('required') && otpclick"
                     class="fontNormal privacyerror" translate>
            Required
          </mat-error>
        </div>
        <div *ngIf="resendOTP">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <mat-form-field appearance="outline"
                              class="checkButton profile-text-input fontNormal profile-field-group-item">
                <mat-label translate>Vorname</mat-label>
                <input class="fontNormal" formControlName="first_name" matInput
                       style="font-size: 16px;padding-top: 5px;"
                       autocomplete="given-name" type="text"/>
              </mat-form-field>
            </div>

            <div class="col-md-6 col-sm-12">
              <mat-form-field appearance="outline"
                              class=" checkButton profile-text-input fontNormal profile-field-group-item">
                <mat-label translate>Nachname</mat-label>
                <input class="fontNormal" formControlName="last_name" matInput style="font-size: 16px;padding-top: 5px;"
                       autocomplete="family-name" type="text"/>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <mat-form-field appearance="outline"
                              class=" checkButton profile-text-input fontNormal profile-field-group-item">
                <mat-label translate>Name der neuen Organisation</mat-label>
                <input class="fontNormal" formControlName="company_name" matInput
                       style="font-size: 16px;padding-top: 5px;"
                       autocomplete="organization" type="text" maxlength="99"/>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="checkButton profile-text-input fontNormal profile-field-group-item"
                hideRequiredMarker>
                <mat-label translate>New password</mat-label>
                <input [type]="hide ? 'password' : 'text'" #password matInput formControlName="password" autocomplete="new-password">
                <button (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" mat-icon-button
                        matSuffix>
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="companyregForm.get('password').hasError('required')">
                  <span translate>Required</span>
                </mat-error>
                <mat-error *ngIf="companyregForm.get('password').hasError('minlength')">
                  <span translate>Das Passwort muss aus mindestens 10 Zeichen bestehen</span>
                </mat-error>
                <mat-error *ngIf="companyregForm.get('password').hasError('maxlength')">
                  <span translate>Das Passwort sollte weniger als 30 Zeichen lang sein</span>
                </mat-error>
                <mat-error *ngIf="companyregForm.get('password').hasError('pattern')">
                  <span translate>(at least one uppercase, one lowercase letter, one special character and a number)</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline"
                              class=" checkButton profile-text-input fontNormal profile-field-group-item">
                <mat-label translate>4-digit OTP<span> (via Email)</span></mat-label>
                <input class="fontNormal" formControlName="otp" matInput
                       style="font-size: 16px;padding-top: 5px;"
                       autocomplete="one-time-code"
                       type="number" min="1000" max="9999"/>
              </mat-form-field>
            </div>
          </div>

          <button [disabled]="companyregForm.invalid"
                  color="primary"
                  mat-flat-button
                  class="w-100"
                  (click)="updateNewCompany()">
            <span translate>Organisation registrieren</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
