import { Injectable } from '@angular/core';
import tinycolor from "tinycolor2";

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PrimaryColorSetupService {
  primaryColor : any;
  secondaryColor : any;
  primaryColorPalette: Color[] = [];
  rgbcolor : any;
  secondaryColorPalette: Color[] = [];
  rgbpalette: Color[] =[];

  constructor() { }


  savePrimaryColor(primaryColor?) {
    (primaryColor) ? (this.primaryColor = primaryColor) : (this.primaryColor = localStorage.getItem("primary_color"));
    this.primaryColorPalette = this.computeColors(this.primaryColor);

    for (const color of this.primaryColorPalette) {
      const key1 = `--theme-primary-${color.name}`;
      const value1 = color.hex;
      const key2 = `--theme-primary-contrast-${color.name}`;
      const value2 = 'white';
      document.documentElement.style.setProperty(key1, value1);
      document.documentElement.style.setProperty(key2, value2);
    }
  }

  saveSecondaryColor() {
    this.secondaryColor = localStorage.getItem("secondary_color");
    this.secondaryColorPalette = this.computeColors(this.secondaryColor);

    for (const color of this.secondaryColorPalette) {
      const key1 = `--theme-secondary-${color.name}`;
      const value1 = color.hex;
      const key2 = `--theme-secondary-contrast-${color.name}`;
      const value2 = 'white';
      document.documentElement.style.setProperty(key1, value1);
      document.documentElement.style.setProperty(key2, value2);
    }
  }

  savergbColor() {
    if(this.primaryColor != undefined){
    this.rgbcolor = this.hex2rgba(this.primaryColor);
    }
    this.rgbpalette = this.colourchanges(this.rgbcolor);

    for (const color of this.rgbpalette) {
      const key1 = `--theme-rgb-${color.name}`;
      const value1 = color.hex;
      const key2 = `--theme-rgb-contrast-${color.name}`;
      const value2 = 'white';
      document.documentElement.style.setProperty(key1, value1);
      document.documentElement.style.setProperty(key2, value2);
    }
  }

  hex2rgba(hex){
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${0.05})`;
  };

  computeColors(hex: string): Color[] {
    return [
      getColorObject(tinycolor(hex).lighten(52), '50'),
      getColorObject(tinycolor(hex).lighten(37), '100'),
      getColorObject(tinycolor(hex).lighten(26), '200'),
      getColorObject(tinycolor(hex).lighten(12), '300'),
      getColorObject(tinycolor(hex).lighten(6), '400'),
      getColorObject(tinycolor(hex), '500'),
      getColorObject(tinycolor(hex).darken(6), '600'),
      getColorObject(tinycolor(hex).darken(12), '700'),
      getColorObject(tinycolor(hex).darken(18), '800'),
      getColorObject(tinycolor(hex).darken(24), '900'),
      getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
      getColorObject(tinycolor(hex).lighten(30).toString(), 'A200'),
      getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
      getColorObject(tinycolor(hex).lighten(30).saturate(2), 'A700'),
      getColorObject(tinycolor(hex).setAlpha(.50).toString(), 'A800'),
    ];
  }

colourchanges(hex: string): Color[] {
  return [
    color(tinycolor(hex), '555'),
  ];
}
}

function getColorObject(value, name): Color {
  const c = tinycolor(value);
  const isRgbaFormat = String(value).startsWith('rgba');
  return {
    name: name,
    hex: isRgbaFormat ? value : c.toHexString(),
    darkContrast: c.isLight()
  };
}

function color(value,name): Color{
  var color = tinycolor(value);
  return {
    name: name,
    hex: value._originalInput,
    darkContrast: color.isLight()
  }
}


