<!-- image carousel-->
<div (keypress)="move_img($event)" *ngIf="closeGallery"
     class="spinner-overlay justify-content-center align-items-center">
  <div class="row" tabindex="-1">
    <span class="col-12" style="text-align:end;margin-left: -1rem;margin-top: 1rem;">
      <mat-icon (click)="close_gallery()" class="cursor-pointer" mat-list-icon style="color:white;">cancel</mat-icon>
     </span>
  </div>
  <div class="row alignment">
      <div class="col-1 arrow" style="text-align: end;">
        <mat-icon (click)="move_left()" class="cursor-pointer" mat-list-icon *ngIf="index">
          chevron_left
        </mat-icon>
      </div>
    <div class="col-10 parent-img">
        <img id="img-child" [src]="src" alt="description image" class="image" (click)="close_gallery()">
      </div>
    <div class="col-1 arrow" style="text-align: start;">
        <mat-icon (click)="move_right()" class="cursor-pointer" mat-list-icon *ngIf="index != srcs.length-1">
          chevron_right
        </mat-icon>
      </div>
  </div>
</div>

<div #scroll *ngIf="currentVideo" class="public-view-container">
  <!--searching video bar -->
  <mat-form-field appearance="outline" class="w-100 mb-2" style="line-height: 2rem;">
    <mat-label>{{'Search public content of' | translate}}&nbsp;{{companyName}}…</mat-label>
    <input (keyup.enter)="searchVideo()" [(ngModel)]="search_text" autocomplete="off" matInput
           placeholder="{{'Search for…' | translate}}">
    <button (click)="searchVideo()" mat-icon-button matSuffix style="color: grey">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <!---video title container--->
  <div class="mr-0 ml-0 mb-3 text-nunito">
    <span (click)="backToPublic()" class="font-weight-bolder cursor-pointer" translate>Public</span>
    <mat-icon class="gray-color align-middle">navigate_next</mat-icon>
    <span>{{currentVideo.title}}</span>
  </div>

  <!---video container-->
  <div class="view-video-container" oncontextmenu="return false;">
    <media-player (duration-change)="player_ready_and_register_view();"
                  [title]="currentVideo.title"
                  crossorigin="anonymous"
                  playsinline
                  preload="auto"
                  src="{{processed_file_path}}">
      <media-provider>
        <!--Poster-->
        <media-poster alt="{{currentVideo.title}}"
                      class="vds-poster"
                      src="{{currentVideo.thumbnail}}">
        </media-poster>

        <!--Subtitles or captions-->
        <ng-container *ngFor="let translation of translations">
          <track *ngIf="translation.vtt_file"
                 [label]="translation.language"
                 [src]="translation.vtt_file"
                 [srclang]="translation.language"
                 kind="subtitles"
          />
        </ng-container>

        <!--Chapter-->
        <track *ngIf="currentVideo.chapter_file"
               [src]="currentVideo.chapter_file"
               [srclang]="currentVideo.audio_language"
               default
               kind="chapters"
               label="chapter"/>
      </media-provider>

      <!-- Gestures -->
      <media-gesture action="toggle:paused" event="pointerup"></media-gesture>
      <media-gesture action="toggle:fullscreen" event="dblpointerup"></media-gesture>
      <media-gesture action="toggle:controls" event="pointerup"></media-gesture>
      <media-gesture action="seek:-10" event="dblpointerup"></media-gesture>
      <media-gesture action="seek:10" event="dblpointerup"></media-gesture>

      <!-- Captions -->
      <media-captions class="vds-captions"></media-captions>

      <!-- Controls -->
      <media-controls class="vds-controls">
        <div class="vds-controls-spacer"></div>
        <!-- Time Slider Controls Group -->
        <media-controls-group class="vds-controls-group">
          <!-- Time Slider -->
          <media-time-slider class="vds-time-slider vds-slider">
            <media-slider-chapters class="vds-slider-chapters">
              <template>
                <div class="vds-slider-chapter">
                  <div class="vds-slider-track"></div>
                  <div class="vds-slider-track-fill vds-slider-track"></div>
                  <div class="vds-slider-progress vds-slider-track"></div>
                </div>
              </template>
            </media-slider-chapters>

            <div class="vds-slider-thumb"></div>

            <!-- Slider Preview -->
            <media-slider-preview class="vds-slider-preview bg-dark">
              <div class="vds-slider-chapter-title" data-part="chapter-title"></div>
              <media-slider-value class="vds-slider-value"></media-slider-value>
            </media-slider-preview>
          </media-time-slider>
        </media-controls-group>
        <!-- Lower Controls Group -->
        <media-controls-group class="vds-controls-group">
          <!-- Play Button -->
          <media-tooltip>
            <media-tooltip-trigger>
              <media-play-button class="vds-button">
                <media-icon class="vds-play-icon" type="play"></media-icon>
                <media-icon class="vds-pause-icon" type="pause"></media-icon>
                <media-icon class="vds-replay-icon" type="replay"></media-icon>
              </media-play-button>
            </media-tooltip-trigger>
            <media-tooltip-content class="vds-tooltip-content" placement="top">
              <span class="vds-play-tooltip-text">Play</span>
              <span class="vds-pause-tooltip-text">Pause</span>
            </media-tooltip-content>
          </media-tooltip>

          <!-- Mute Button -->
          <media-tooltip>
            <media-tooltip-trigger>
              <media-mute-button class="vds-button">
                <media-icon class="vds-mute-icon" type="mute"></media-icon>
                <media-icon class="vds-volume-low-icon" type="volume-low"></media-icon>
                <media-icon class="vds-volume-high-icon" type="volume-high"></media-icon>
              </media-mute-button>
            </media-tooltip-trigger>
            <media-tooltip-content class="vds-tooltip-content" placement="top">
              <span class="vds-mute-tooltip-text">Unmute</span>
              <span class="vds-unmute-tooltip-text">Mute</span>
            </media-tooltip-content>
          </media-tooltip>

          <!-- Volume Slider -->
          <media-volume-slider class="vds-slider">
            <div class="vds-slider-track"></div>
            <div class="vds-slider-track-fill vds-slider-track"></div>
            <media-slider-preview class="vds-slider-preview" no-clamp>
              <media-slider-value class="vds-slider-value"></media-slider-value>
            </media-slider-preview>
            <div class="vds-slider-thumb"></div>
          </media-volume-slider>

          <!-- Time Group -->
          <div class="vds-time-group">
            <media-time class="vds-time" type="current"></media-time>
            <div class="vds-time-divider">/</div>
            <media-time class="vds-time" type="duration"></media-time>
          </div>

          <media-chapter-title class="vds-chapter-title"></media-chapter-title>

          <div class="vds-controls-spacer"></div>

          <!-- Caption Button -->
          <media-tooltip>
            <media-tooltip-trigger>
              <media-caption-button class="vds-button">
                <media-icon class="vds-cc-on-icon" type="closed-captions-on"></media-icon>
                <media-icon class="vds-cc-off-icon" type="closed-captions"></media-icon>
              </media-caption-button>
            </media-tooltip-trigger>
            <media-tooltip-content class="vds-tooltip-content" placement="top">
              <span class="vds-cc-on-tooltip-text">Closed-Captions Off</span>
              <span class="vds-cc-off-tooltip-text">Closed-Captions On</span>
            </media-tooltip-content>
          </media-tooltip>

          <!-- Playback speed menu -->
          <media-menu class="vds-menu">
            <!-- Settings Menu Button -->
            <media-tooltip>
              <media-tooltip-trigger>
                <media-menu-button class="vds-menu-button vds-button">
                  <media-icon type="playback-speed-circle"></media-icon>
                </media-menu-button>
              </media-tooltip-trigger>
              <media-tooltip-content class="vds-tooltip-content" placement="top">
                {{ 'Wiedergabegeschwindigkeit'|translate }}
              </media-tooltip-content>
            </media-tooltip>

            <!-- Settings Menu Items -->
            <media-menu-items class="vds-menu-items" placement="top end">
              <!--https://www.vidstack.io/docs/wc/player/components/menus/speed-radio-group?styling=default-theme-->
              <media-speed-radio-group class="vds-radio-group" normal-label="Normal">
                <template>
                  <media-radio class="vds-radio">
                    <media-icon class="vds-icon" type="check"></media-icon>
                    <span class="vds-radio-label" data-part="label"></span>
                  </media-radio>
                </template>
              </media-speed-radio-group>
            </media-menu-items>
          </media-menu>

          <!-- Fullscreen Button -->
          <media-tooltip>
            <media-tooltip-trigger>
              <media-fullscreen-button class="vds-button">
                <media-icon class="vds-fs-enter-icon" type="fullscreen"></media-icon>
                <media-icon class="vds-fs-exit-icon" type="fullscreen-exit"></media-icon>
              </media-fullscreen-button>
            </media-tooltip-trigger>
            <media-tooltip-content class="vds-tooltip-content" placement="top end">
              <span class="vds-fs-enter-tooltip-text">Enter Fullscreen</span>
              <span class="vds-fs-exit-tooltip-text">Exit Fullscreen</span>
            </media-tooltip-content>
          </media-tooltip>
        </media-controls-group>
      </media-controls>
    </media-player>

    <!--<video #media (loadedmetadata)="player_ready_and_register_view()" [poster]="currentVideo.thumbnail"
           [src]="processed_file_path"
           autofocus autoplay
           class="video-view"
           controls controlsList="nodownload" crossorigin="anonymous" preload="auto"
           type="video/mp4">
      <ng-container *ngFor="let translation of translations">
        <track *ngIf="translation.vtt_file"
               [label]="translation.language"
               [src]="translation.vtt_file"
               [srclang]="translation.language"
               kind="subtitles"
        />
      </ng-container>
      Your browser does not support the video tag.
    </video>-->
  </div>

  <!---video title below video--->
  <h2 class="mt-3 fontStyle">{{currentVideo.title}}</h2>

  <div class="d-flex justify-content-between align-items-center mb-2" style="flex-flow: wrap;">
    <div class="d-flex text-nunito gray-color align-items-center">
      <!--video view count-->
      <mat-icon *ngIf="show_view_count" class="material-icons-outlined mr-1">visibility</mat-icon>
      <span *ngIf="show_view_count" class="mr-2">
          {{ viewCount | number }}
          {{viewCount == 1 ? ('Aufruf'|translate) : ('Aufrufe'|translate)}}
        &nbsp;●
      </span>

      <!--video published on -->
      <span matTooltip="{{'Veröffentlicht am' | translate}}" matTooltipPosition="right">
        {{currentVideo.added_on | date: "dd MMMM yyyy" : undefined : date_locale }}
      </span>
    </div>

    <div class="d-flex align-items-start text-nunito">
      <!-- Share button -->
      <button (click)="openSharePopup();" mat-button>
        <mat-icon class="mr-2">
          share
        </mat-icon>
        <span translate>Teilen</span>
      </button>

      <!--language selection-->
      <button *ngIf="translations.length > 1" [matMenuTriggerFor]="menu_trans"
              aria-label="{{ 'Change language' | translate }}"
              matTooltipPosition="above"
              mat-button matTooltip="{{ 'Change language' | translate }}">
        <mat-icon>translate</mat-icon>
        {{current_language}}
      </button>
      <mat-menu #menu_trans="matMenu">
        <button (click)="changeLanguage(translation);" *ngFor="let translation of translations" mat-menu-item>
          <span>{{translation.language}}</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <mat-divider></mat-divider>

  <!--video author section-->
  <div class="d-flex mt-3">
    <img [src]="currentVideo.uploader.userprofile.profile_pic" alt="user image"
         style="aspect-ratio: 1/1; height: 50px; border-radius: 50%;">
    <div class="ml-2 d-flex flex-column">
      <b class="text-nunito">
        {{currentVideo.uploader.first_name}}&nbsp;{{currentVideo.uploader.last_name}}
      </b>
      <span class="text-nunito gray-color">{{companyName}}</span>
    </div>
  </div>

  <!--attachment details-->
  <div *ngIf="currentVideo.attachment" class="text-nunito gray-color mt-3">
    <span translate>Anhang</span>:&nbsp;
    <a (click)="downloadAttachment();" matTooltip="{{'Anhang'|translate}}" class="cursor-pointer">
      {{ attachFileName?.split("?")[0] }}
    </a>
  </div>

  <!--desc-->
  <div (click)="getClickEvent($event);" [innerHTML]="formatted_video_desc"
       class="video-desc mt-3 w-100">
  </div>

  <!--disabled comment field for view-->
  <mat-divider class="mt-3 mb-3"></mat-divider>
  <mat-form-field appearance="outline" class="w-100 text-nunito" disabled>
    <mat-label translate>Kommentar hinzufügen</mat-label>
    <textarea class="text-nunito" disabled matInput readonly rows="5"
              style="resize: none; height: auto;" type="text"></textarea>
  </mat-form-field>

  <div class="gray-color text-nunito">
    <mat-icon class="mr-2" style="vertical-align: bottom;">info_outlined</mat-icon>
    <span translate>If want to share your thoughts and write anything, please</span>&nbsp;
    <a [routerLink]="['/login']" translate>Anmelden</a>&nbsp;
    <span translate>oder</span>&nbsp;
    <a [routerLink]="['/login']" translate>Register</a>
  </div>

  <!-- footer section -->
  <footer class="mt-5 mb-3 gray-color d-flex justify-content-center font-medium"
          style="gap: 10px;">
    <a class="mr-1" href="{{ 'WEB_URL' | translate }}" target="_blank" translate>Website</a>
    <a class="mr-1" href="{{ 'IMPRESSUM_URL' | translate }}" target="_blank" translate>Imprint</a>
    <a class="mr-1" href="{{ 'POLICY_URL' | translate }}" target="_blank" translate>Privacy</a>
    <span>&copy; Zesavi 2024</span>
  </footer>

  <!--scroll top button-->
  <button (click)="scrollTop()" class="scroll-btn shadow" mat-fab>
    <mat-icon>vertical_align_top</mat-icon>
  </button>
</div>

<!--display content if the video is password protected-->
<div *ngIf="password_protected || displayText" class="ml-4 mt-4 text-center">
  <div>
    <mat-icon style="vertical-align: middle;" class="material-icons-outlined">info</mat-icon>&nbsp;
    <span class="text-nunito">{{displayText}}</span>
  </div>
  <div *ngIf="password_protected">
    <mat-form-field class="text-nunito" style="min-width: 300px">
      <mat-label translate>Zugangscode eingeben</mat-label>
      <input (keydown.enter)='access_key_given()' [(ngModel)]="access_key" matInput type="text"
             placeholder="{{'Code (max. 32 Zeichen)'|translate}}">
      <button (click)="access_key_given()" *ngIf="access_key" aria-label="enter" mat-button mat-icon-button matSuffix>
        <mat-icon>done</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>

