<div class="d-flex justify-content-between mb-2" mat-dialog-title>
  <h1 class="text-nunito font-weight-bold w-100 text-center" translate>
    New Request
  </h1>
  <button [mat-dialog-close]="false" mat-icon-button style="margin-top: -6px;">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label translate>Titel</mat-label>
    <input autocomplete="off" class="fontNormal"
           [(ngModel)]="title"
           matInput
           maxlength="100"
           placeholder="{{'Please write your question clearly' | translate}}" style="height: 25px;"
           type="text"/>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label translate>Beschreibung</mat-label>
    <textarea [rows]="4"
              [(ngModel)]="desc"
              class="fontNormal"
              matInput maxlength="500">
     </textarea>
  </mat-form-field>

  <mat-form-field appearance="outline" class="fontNormal w-100">
    <mat-label translate>Who should reply?</mat-label>
    <input [formControl]="checklist_form_control" [matAutocomplete]="auto"
           matInput placeholder="{{'Name'|translate}}" type="text">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option [value]="option.name"
                  (onSelectionChange)="onItemSelect(option)"
                  (click)="onItemSelect(option)"
                  *ngFor="let option of filteredOptions | async">
        {{option.name}}&nbsp;({{option.string}})
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-card-subtitle *ngIf="currently_selected_item" class="text-nunito">
    {{'Selected'|translate}}:&nbsp;{{currently_selected_item.name}}&nbsp;({{currently_selected_item.string}})
  </mat-card-subtitle>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button (click)="sendVideoRequest();"
          [disabled]="(title == '') || (currently_selected_item == null)"
          class="border-radius-20"
          mat-stroked-button>
    {{'Senden'|translate}}
  </button>
</mat-dialog-actions>
