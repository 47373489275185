<div (keypress)="move_img($event)" *ngIf="closeGallery"
  class="spinner-overlay justify-content-center align-items-center">
  <div class="row" tabindex="-1">
    <span class="col-12" style="text-align:end;margin-left: -1rem;margin-top: 1rem;">
      <mat-icon (click)="close_gallery()" mat-list-icon style="color:white;">cancel</mat-icon>
    </span>
  </div>
  <div class="row alignment">
    <div class="col-1 arrow" style="text-align: end;">
      <mat-icon (click)="move_left()" mat-list-icon *ngIf="index">
        chevron_left
      </mat-icon>
    </div>
    <div class="col-10 parent-img">
      <img id="img-child" [src]="src" alt="description image" class="image" (click)="close_gallery()">
    </div>
    <div class="col-1 arrow" style="text-align: start;">
      <mat-icon (click)="move_right()" mat-list-icon *ngIf="index != srcs.length-1">
        chevron_right
      </mat-icon>
    </div>
  </div>
</div>