<button class="close-button" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>
<h2 mat-dialog-title style="font-family: 'poppins-medium';">
  Bitte klicken Sie auf die knopf unten, um das Video herunterzuladen.
<!--  Es ist soweit: Dein Video ist fertig aufgenommen und kategorisiert. Wie willst Du es-->
<!--  veröffentlichen?-->
</h2>
<div mat-dialog-content>
  <div *ngFor="let option of exportOptions" class="d-inline-block text-center px-3">
    <button cdkFocusInitial mat-icon-button (click)="option.action()" class="export-icon" [ngClass]="option.cssClasses">
      <mat-icon>{{option.icon}}</mat-icon>
    </button>
    <div style="font-family: 'nunito';">{{option.text}}</div>
  </div>
</div>
