<div class="justify-content-between d-flex align-items-baseline">
  <h2 class="fontStyle">{{ "Permanently delete user" | translate }}</h2>
  <button mat-icon-button [mat-dialog-close]="false">
    <mat-icon>
      close
    </mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <mat-card-subtitle class="text-nunito">
    {{deletion_message}}
    <br><br>
    {{this.first_name}}&nbsp;{{this.last_name}}&nbsp;{{'will also be notified about this.'|translate}}
  </mat-card-subtitle>
</div>
<div mat-dialog-actions class="justify-content-between">
  <button (click)="manageContent()"
          [disabled]="in_progress"
          class="disablebtn mr-3" mat-stroked-button>
    {{ "Manage content" | translate }}
  </button>

  <button (click)="callDeleteAPI()"
          [disabled]="in_progress"
          cdkFocusInitial class="blocked" mat-stroked-button>
    {{ "Delete Permanently" | translate }}
  </button>

  <mat-progress-bar *ngIf="in_progress" mode="indeterminate"></mat-progress-bar>
</div>
