import { Component, Inject, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../../../services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VideoProfile } from "../../../../models/video/video.interface";
import {
  ProgressSpinnerDialogComponent
} from "../../../../shared/progress-spinner-dialog/progress-spinner-dialog.component";
import {AuthService} from "../../../../services/auth.service";


@Component({
  selector: 'app-video-profile',
  templateUrl: './video-profile.component.html',
  styleUrls: ['./video-profile.component.scss']
})
export class VideoProfileComponent implements OnInit {

  maxSize5MB: number = 5242880;
  creditDropdown: {key: string, value: string}[] = [];  // intro outro etc
  all_profiles: VideoProfile[] = [];
  is_authorised: boolean = false;
  is_pro_user: boolean = false;
  is_profile_added_deleted: boolean = false;  // flag to keep track of changes

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private dataService: DataService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<VideoProfileComponent>) {
    this.creditDropdown = [
      {key: 'none', value: this.translateService.instant('Keine')},
      {key: 'intro', value: this.translateService.instant('Nur Intro')},
      {key: 'outro', value: this.translateService.instant('Nur Outro')},
      {key: 'both', value: this.translateService.instant('Beides')}
    ];
    // only manager can add/remove profile
    this.is_authorised = this.authService.userDetails.is_global_manager || this.authService.userDetails.is_quality_manager;
    this.is_pro_user = this.authService.company.max_upload_video_height != '720';
  }

  ngOnInit(): void {
    // load the profiles
    this.dataService.getURL<any>('video/profiles/', {observe: 'body', responseType: 'json'})
      .subscribe((resData: VideoProfile[]) => {
        this.all_profiles = resData;
    });
  }

  // edit a profile name
  editProfile(profile: VideoProfile, index: number){
    let message = this.translateService.instant("Please enter the profile name:")
    let name = window.prompt(message, profile.name);
    if(name){
      let formData = new FormData();
      formData.append('name', name);
      this.saveProfile(profile.id, index, formData, 'name');
    }
  }

  // delete a profile
  deleteProfile(id, index) {
    let message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      this.dataService.deleteURL<any>(`video/profiles/?id=${id}`, {observe: 'body'})
        .subscribe((res) => {
          // success
          this.all_profiles.splice(index, 1);
          this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2000});
          this.is_profile_added_deleted = true;
      }, (err) => {
          // failed to delete, show alert
          console.error(err);
          window.alert(err.error.text);
        });
    }
  }

  // create a form
  attachValue(event, id, index, uploadName) {
    let formData = new FormData();

    if (uploadName === 'overlay_code' || uploadName === 'ci_video_code') {
      formData.append(uploadName, event);
      this.saveProfile(id, index, formData, uploadName)
    } else {
      let attachment = event._files[0];
      if(attachment.size > this.maxSize5MB){
        let message = this.translateService.instant("Please select a file under X MB", {
          'X': 5
        });
        window.alert(message);
        return;
      }
      formData.append(uploadName, attachment, attachment.name);
      this.saveProfile(id, index, formData, uploadName)
    }
  }

  saveProfile(id, index, formData, uploadName){
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });

    this.dataService.patchURL<any>(`video/profiles/?id=${id}`, formData, {responseType: 'json', observe: 'body'}).subscribe((resp) => {
      this.all_profiles[index][uploadName] = resp[uploadName];
      this.snackBar.open(this.translateService.instant('Erfolgreich'), '', { duration: 2000 });
      this.is_profile_added_deleted = true;
      dialogRef.close();
    }, (err) => {
      console.error(err);
      this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
      dialogRef.close();
      // reset all profiles
      this.ngOnInit();
    });
  }

  addNewProfile() {
    let message = this.translateService.instant("Please enter the profile name:")
    let name = window.prompt(message, "Profile 1");
    if (name) {
      const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
        this.dialog.open(ProgressSpinnerDialogComponent, {
          panelClass: 'transparent',
          disableClose: true,
        });

      this.dataService.postURL(`video/profiles/`, {'name': name}).subscribe((res: VideoProfile) => {
        this.all_profiles.push(res);
        this.is_profile_added_deleted = true;
        dialogRef.close();
      }, (err) => {
        dialogRef.close();
        console.error(err);
        window.alert(err.error);
      });
    }
  }
}
