<div class="m-3 d-flex justify-content-between flex-wrap">
  <div class="d-flex justify-content-start align-items-center">
    <mat-icon class="mr-1 material-icons-outlined" color="primary" mat-icon>
      sticky_note_2
    </mat-icon>
    <mat-card-title class="fontStyle mt-0 mb-0 mr-4" translate>
      All Pages
    </mat-card-title>
    <button class="border-radius-20" mat-stroked-button routerLink="/start">
      <mat-icon>chevron_left</mat-icon>
      <span translate>Back</span>
    </button>
  </div>

  <div class="d-flex justify-content-end align-items-baseline">
    <mat-slide-toggle (change)="featuredToggle($event)" [checked]="isFeatured" class="mr-4">
      <span class="text-nunito" translate>
          Featured only
      </span>
    </mat-slide-toggle>

    <mat-form-field appearance="outline">
      <mat-label translate>Sort</mat-label>
      <mat-select (selectionChange)="sortedBy($event)" [value]="sortValue">
        <mat-option value="added_on"><span translate>Newest</span></mat-option>
        <mat-option value="last_edited_on"><span translate>Last edited</span></mat-option>
        <mat-option value="title"><span translate>Titel</span></mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>


<div (scrolled)="getAllTopics();" [fromRoot]="true" [infiniteScrollContainer]="'mat-sidenav-content'"
     [infiniteScrollDistance]="1" [infiniteScrollThrottle]="500" [scrollWindow]="false" class="container-fluid"
     infiniteScroll>

  <div class="d-flex justify-content-center" style="flex-flow: wrap;">
    <!--topic card grid-->
    <app-topic-card *ngFor="let topic of allTopics" [topic_object]="topic" class="mx-2"
                    routeUrl="pages/view/" style="max-width: 320px; margin-right: 20px;">
    </app-topic-card>
  </div>

  <div *ngIf="showSpinner" class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>

  <mat-card-subtitle *ngIf="page>maxPages" class="text-center">
    Showing all results
  </mat-card-subtitle>
</div>
