import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { VideoCard } from '../models/video/video.interface';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {
  userVideos: VideoCard[] = [];
  backendUrl: string = '';
  id: number = 0;
  userName: string;

  constructor(public dataService: DataService,
    private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.getuserVideos();
    });
  }

  getuserVideos(): void {
    let userUrl = `user/${this.id}/videos/`;
    // todo: use new interface
    this.dataService.getURL(userUrl, { observe: 'body', responseType: 'json' }).subscribe((resp: any) => {
      this.userName = resp.name;
      this.userVideos = resp.data;
    }, (err) => {
      window.alert(err.error);
    });
  }

  backTo() {
    window.history.back();
  }
}

