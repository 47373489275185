<div class="container-fluid mt-2 loc_pos">
  <div class="d-flex align-items-baseline">
    <h2 class="fontstyle m-0" translate>Locations</h2>
    <button (click)="addLocation();"
            [disabled]="!authorised"
            class="ml-4 border-radius-20" mat-stroked-button>
      <mat-icon mat-icon>add</mat-icon>
      <span translate>Add Location</span>
    </button>
  </div>
  <hr>

  <div class="location-list" fluidHeight="auto">
    <table #locTableSort="matSort" [dataSource]="locationDataSource" mat-table matSort matSortActive="users"
           matSortDirection="desc">

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'Name' | translate}}</th>
        <td (click)="renameLocation(element)" *matCellDef="let element" class="pointer" mat-cell
            matTooltip="{{'Bearbeiten'|translate}}" matTooltipPosition="left">
          {{element.name ? element.name : '-'}}
        </td>
      </ng-container>


      <ng-container matColumnDef="users">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'User count' | translate}}</th>
        <td (click)="userCountLocations(element)" *matCellDef="let element" class="cursor-pointer"
            mat-cell matTooltip="{{'View members'|translate}}" matTooltipPosition="left">
          {{element.users ? element.users : '-'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th *matHeaderCellDef mat-header-cell mat-sort-header style="width: 160px;">{{''}}</th>
        <td *matCellDef="let element" mat-cell>
          <button (click)="deleteLocation(element)"
                  [disabled]="!authorised"
                  class="px-1 blocked" mat-icon-button>
            <mat-icon mat-list-icon>delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
  </div>
  <!-- </mat-expansion-panel> -->
</div>

