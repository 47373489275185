import {ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslationDetails, VideoView} from '../models/video/video.interface';
import {DataService} from '../services/data.service';
import {environment} from '../../environments/environment';
import {Meta, Title} from '@angular/platform-browser';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../services/auth.service';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {StillThereComponent} from "../shared/still-there/still-there.component";
import {PopupData, SharePopupComponent} from "../shared/share-popup/share-popup.component";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-public-video-view',
  templateUrl: './public-video-view.component.html',
  styleUrls: ['./public-video-view.component.scss']
})
export class PublicVideoViewComponent implements OnInit, OnDestroy {
  @ViewChild('scroll') scroll: ElementRef;

  videoId: string;
  currentVideo: VideoView = null;
  access_key: string = '';
  password_protected: boolean = false;
  formatted_video_desc: string = "";
  companyName: string = "";
  search_text: string = "";
  match_two_step_timeStamp = /([0-5][0-9](:[0-5][0-9])) /g;                      // valid pattern : 59:59<space>
  match_three_step_timeStamp = /([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9]) /g; // 00:59:59<space>
  make_two_step_Url = /([0-5][0-9](:[0-5][0-9]))/g;                                // 00:00
  make_three_step_Url = /([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])/g;           // 00:00:00
  attachmentPath: string;
  attachFileName: string;
  processed_file_path: string;
  viewCount: number | string;
  translations: TranslationDetails[] = [];
  current_language: string = "";
  closeGallery: boolean = false;
  src: string = "";
  srcs: any = [];
  index: number;
  browser_language: string = "";
  date_locale: string = 'en';  // to show dates in different languages
  show_view_count: boolean = true;
  video_player: HTMLVideoElement = null;
  displayText: string = '';

  timeoutHandler: any = null;  // timeout handler to show still-there popup, when the url has expired

  constructor(private router: Router, private route: ActivatedRoute,
              private titleService: Title, private snackBar: MatSnackBar, private translateService: TranslateService,
              private dataService: DataService,
              private cdr: ChangeDetectorRef,
              public authService: AuthService,
              private dialog: MatDialog,
              private metaService: Meta) {
    if (this.authService.company) {
      this.companyName = this.authService.company.name;
      this.show_view_count = this.authService.company.is_external_views_shown;
    }
    if (this.translateService.currentLang == 'de') {
      this.date_locale = 'de';
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeGallery == true && this.move_img(event);
  }

  ngOnInit(): void {
    // api call to get the video details
    this.load_data();
  }

  ngOnDestroy() {
    clearTimeout(this.timeoutHandler);
  }

  // enabling the video preview while sharing the link
  metaUpdate(video) {
    this.metaService.updateTag({name: 'twitter:card', content: 'summary'});
    this.metaService.updateTag({name: 'twitter:site', content: '@clypp'});
    this.metaService.updateTag({name: 'twitter:title', content: video.title});
    this.metaService.updateTag({name: 'twitter:description', content: video.desc});
    this.metaService.updateTag({name: 'twitter:image', content: video.thumbnail});

    this.metaService.updateTag({property: 'og:title', content: video.title}, "property='og:title'");
    this.metaService.updateTag({
      property: 'og:url',
      content: `${environment.baseURL}publicVideoView/${video.id}`
    }, "property='og:url'");
    this.metaService.updateTag({property: 'og:description', content: video.desc}, "property='og:description'");
    this.metaService.updateTag({property: 'og:image', content: video.thumbnail}, "property='og:image'");
    this.metaService.updateTag({property: 'og:video', content: video.processed_file}, "property='og:video'");
    this.metaService.updateTag({
      property: 'og:video:secure_url',
      content: video.processed_file
    }, "property='og:video:secure_url'");
  }

  // api call to get the video response
  load_data(access_key = "") {
    this.cdr.detectChanges();
    this.route.paramMap.subscribe(map => {
      //detect the video id and embedtype from the route url
      this.videoId = map.get('id');

      let url = `public/videos/${this.videoId}/`;
      if (access_key != "") {
        url = `public/videos/${this.videoId}/?access_key=${encodeURIComponent(access_key)}`;
      }

      // load the video
      this.dataService.getURL<VideoView>(url, {
        observe: 'body',
        responseType: 'json'
      }).subscribe((res: VideoView) => {
        // data is loaded
        this.titleService.setTitle(`${res.title} - Clypp`);
        this.password_protected = false;
        this.displayText = '';
        this.currentVideo = res;

        // set company info
        // this.authService.company = this.currentVideo.company;
        this.companyName = this.currentVideo.company['name'];
        this.show_view_count = res['company']['is_external_views_shown'];

        this.viewCount = res.views;
        this.processed_file_path = res.processed_file;

        this.metaUpdate(res);

        this.attachmentPath = res.attachment;
        if (this.attachmentPath) {
          this.attachFileName = this.attachmentPath?.split('/').pop();
        }

        // add all translations
        this.addOriginalLang();
        this.translations.push(...res.translations);
        // update the labels of translations
        this.updateTranslationLabels();
      }, (err: HttpErrorResponse) => {
        this.displayText = err.error;
        if (err.status == 406) {
          // not public or external sharing disabled
          if (this.authService.isAuthenticated()) {
            // if user is logged in, go to internal page
            window.alert(err.error);
            this.router.navigate(['view', this.videoId]);
          }
        } else if (err.status == 423) {
          // no key provided, dont alert
          this.password_protected = true;
        } else if (err.status == 409) {
          // wrong access key
          this.password_protected = true;
        } else if (err.status == 429) {
          // too many requests
          this.password_protected = false;
          this.displayText = err.statusText;
        } else {
          // 404, 400, 500
          // display message is already shown
        }
      });  // end of data service
    });  // end of params route
  }

  // function triggered on entering the access_key
  access_key_given() {
    this.load_data(this.access_key);
  }

  player_ready_and_register_view() {
    // load the player
    this.video_player = document.querySelector('media-player') as unknown as HTMLVideoElement;
    // this.video_player = document.querySelector('video') as HTMLVideoElement;
    this.video_player.focus();

    // now set the first language
    this.changeLanguage(this.translations[0]);
    // now check for other languages
    this.browser_language = this.authService.languages.get(navigator.language);
    if (this.browser_language != this.current_language) {
      let translation_object = this.translations.find(ele => ele.language == this.browser_language);
      if (translation_object) {
        this.changeLanguage(translation_object);
        this.snackBar.open(
          this.translateService.instant('Clypp translated'),
          this.translateService.instant('Undo'),
          {duration: 3500, horizontalPosition: 'left'}
        ).onAction().subscribe(() => {
          this.changeLanguage(this.translations[0]);
        });
      }
    }

    let xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", `${environment.backendURL}/embed/external/${this.videoId}/viewed/`, true);
    xmlHttp.send(null);

    // now the player is loaded, start the timer to let user know refresh the page
    this.timeoutHandler = setTimeout(() => {
      // open the still-there component
      clearTimeout(this.timeoutHandler);
      // pause the video
      document.querySelector('video').pause();
      const stillThereDialogRef: MatDialogRef<StillThereComponent> =
        this.dialog.open(StillThereComponent, {
          panelClass: 'transparent',
          disableClose: true,
        });
      stillThereDialogRef.afterClosed().subscribe(() => {
        // reload the page
        location.reload();
      });
    }, environment.timeoutDuration * 1000); // *1000 into milliseconds
  }

  // function triggered on search video
  searchVideo(): void {
    if (this.search_text) {
      this.router.navigate([`public/${this.currentVideo.company['id']}`], {
        queryParams: {
          search_text: this.search_text,
          sort_option: 'added_on'
        }
      });
    } else {
      this.router.navigate([`public/${this.currentVideo.company['id']}`]);
    }
  }

  //triggered to move back to the public page
  backToPublic() {
    this.router.navigate([`public/${this.currentVideo.company['id']}`]);
  }

  // description click event triggered which changes the video duration according to the desc timestamp
  getClickEvent(event) {
    if (event.target.localName == 'img') {
      let index = this.srcs.findIndex(x => x == event.srcElement.attributes[0].nodeValue);
      this.openLink(index);
    } else if (event.target.localName == 'a') {
      this.changeCurrentVidTime(event)
    }
  }

  // changing the video duration
  changeCurrentVidTime(event) {
    let pattern = event.target;
    let cond1 = pattern.innerText.match(this.make_two_step_Url) && pattern.innerText.match(this.make_two_step_Url).length > 0;
    let cond2 = pattern.innerText.match(this.make_three_step_Url) && pattern.innerText.match(this.make_three_step_Url).length > 0
    if (pattern.localName == 'a' && (cond1 || cond2)) {
      this.scroll.nativeElement.scrollIntoView({behavior: 'smooth'});
      const time_strings = event.srcElement.innerText.split(':');
      let time_seconds: number = 0;
      try {
        // find seconds
        if (cond2) {
          // check this one first, as cond1 is always true
          time_seconds = parseInt(time_strings[0]) * 60 + parseInt(time_strings[1]) * 60 + parseInt(time_strings[2]);
        } else {
          // condition 1 applies
          time_seconds = parseInt(time_strings[0]) * 60 + parseInt(time_strings[1]);
        }
      } catch {
        // do nothing in case of error
      }
      this.video_player.currentTime = time_seconds;
      // do not play pause the video, previous state stays
    }
  }

  urlifyDescriptionTimestamps() {
    this.formatted_video_desc = this.replaceURLs(this.formatted_video_desc);
  }

  replaceURLs(message) {
    if (!message) return;
    let compared_with_first_reg = this.makeUrl(message, this.match_three_step_timeStamp, this.make_three_step_Url);
    return this.makeUrl(compared_with_first_reg, this.match_two_step_timeStamp, this.make_two_step_Url)
  }

  // formatting the video description to show the timestamp
  makeUrl(text, pattern1, pattern2) {
    return text.replace(pattern1, function (url) {
      let new_url = url.substring(0, url.indexOf(' '));
      if (new_url.match(pattern2) && new_url.match(pattern2).length > 0)
        return '<a>' + new_url + '</a> ' + url.substring(url.indexOf(' ') + 1)
      else
        return url
    });
  }

  // function triggered on click of attachment download
  async downloadAttachment(): Promise<void> {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.attachmentPath);
    link.setAttribute('download', this.attachFileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  //scrolled to the top of the view
  scrollTop() {
    this.scroll.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  // triggered on selection of the language in the translate button
  changeLanguage(translation) {
    this.currentVideo.title = translation.title;
    this.formatted_video_desc = translation.desc;
    this.current_language = translation.language;
    this.getImageUrls();
    this.urlifyDescriptionTimestamps();

    // update track of player
    if (this.video_player != null) {
      this.video_player.textTracks['A'].forEach((track: any) => {
        if (track.kind == 'subtitles') {
          if (track.label == this.current_language) {
            track.mode = "showing";
          } else {
            track.mode = "hidden";
          }
        }
      });
    }
  }

  // mapping the translation label from the video response to the overall language response from authservice
  updateTranslationLabels() {
    this.translations.forEach((element, index) => {
      this.translations[index].language = this.authService.languages.get(this.translations[index].language);
    })
  }

  // adding a video current language array to the translation video response
  addOriginalLang() {
    this.translations = []; // to clean the data after changing video
    this.translations.push({
      audio_file: null,
      created_on: null,
      desc: this.currentVideo.desc,
      id: null,
      language: this.currentVideo.audio_language,
      title: this.currentVideo.title,
      video: this.currentVideo.id,
      vtt_file: this.currentVideo.vtt_file
    })
  }

  close_gallery() {
    this.closeGallery = false;
    document.getElementsByClassName('mat-sidenav')[0]['style'].visibility = 'visible';
  }

  move_img(event) {
    if (event.keyCode == 37)
      this.move_left();
    if (event.keyCode == 39)
      this.move_right();
  }

  move_left() {
    if (this.index > 0) {
      this.index -= 1;
      this.src = this.srcs[this.index]
    }
  }

  move_right() {
    if (this.index < this.srcs.length - 1) {
      this.index += 1;
      this.src = this.srcs[this.index]
    }
  }

  openLink(i) {
    this.closeGallery = true;
    this.index = i;
    this.src = this.srcs[i];
    document.getElementsByClassName('mat-sidenav')[0]['style'].visibility = 'hidden';
  }

  getImageUrls() {
    var m,
      rex = /<img.*?src="([^">]*\/([^">]*?))".*?>/g;
    this.srcs = [];
    while (m = rex.exec(this.formatted_video_desc)) {
      this.srcs.push(m[1]);
    }
  }

  openSharePopup() {
    this.video_player.pause();

    const data: PopupData = {
      ...this.currentVideo,
      type: "video",
    }

    this.dialog.open(SharePopupComponent, {
      disableClose: true,
      data: data,
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
    if (this.closeGallery) {
      switch (e.key) {
        case 'ArrowRight':
          this.move_right();
          break;
        case 'ArrowLeft':
          this.move_left();
          break;
      }
    }
  }

}
