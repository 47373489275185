import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';


export const sideNavAnimation = trigger('openCloseSidenav', [

    state('open', style({
      'min-width': '150px'
    })),
    state('closed', style({
      'min-width': '62px'
    })),
    transition('open <=> closed', [
      animate('0.15s')
    ]),
  ]);


export const sideNavContainerAnimation = trigger('openCloseSidenavContent', [
  state('closed',
      style({
        'margin-left': '62px'
      })
    ),
    state('open',
      style({
        'margin-left': '62px'
      })
    ),
    transition('open <=> closed', [
      animate('0.15s')
    ]),
  ]);

  export const animateText = trigger('animateText', [
    state('closed',
      style({
        'display': 'none',
        opacity: 0,
      })
    ),
    state('open',
      style({
        'display': 'block',
        opacity: 1,
      })
    ),
    // transition('open <=> closed', [
    //   animate('0.15s')
    // ]),
  ]);
