<form class="w-50" style="left: 25%; position: relative;" [formGroup]="playlistForm" (ngSubmit)="submitForm()">

  <mat-card class="mat-elevation-z6">

      <mat-card-header class="justify-content-center">
          <mat-card-title class="fontStyle">{{headerTitleLabel}}</mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
          <mat-form-field  appearance="fill" class="w-100 fontNormal" hideRequiredMarker>
               <mat-label translate>Titel</mat-label>
              <input matInput type="text" formControlName="title" required />
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100 fontNormal" hideRequiredMarker>
               <mat-label translate>Beschreibung</mat-label>
              <textarea matInput formControlName="desc" required></textarea>
          </mat-form-field>

          <mat-slide-toggle name="is_draft" class="fontNormal" [checked]="is_Draft" (change)='changedToggle($event)'>Nicht sofort veröffentlichen</mat-slide-toggle>
          <br>
          <mat-form-field class="mt-4 w-100 fontNormal" >
            <ngx-mat-file-input formControlName="thumbnail" [accept]="'.jpg, .png'" [multiple]="true"
                                valuePlaceholder="Vorschaubild hochladen">
            </ngx-mat-file-input>
            <mat-label class="fontNormal" style="color:black !important">Vorschaubild</mat-label>
          </mat-form-field>

      </mat-card-content>

      <mat-card-actions class="d-flex justify-content-between">
          <button [routerLink]="['/playlists']" class="btn-zesavi" color="primary" mat-stroked-button translate>
            Cancel
          </button>
        <button class="btn-zesavi" color="primary" mat-flat-button type="submit">
          {{submitBtnLabel}}
        </button>
      </mat-card-actions>

  </mat-card>


</form>
