<h2 mat-dialog-title translate class="text-nunito">Shared within the workspace with</h2>

<mat-form-field class="w-100" appearance="outline" style="line-height: inherit;">
    <mat-label translate>Choose...</mat-label>
    <input type="text"
           [autofocus]="false"
           placeholder="{{'Search for…'|translate}}"
           aria-label="choose"
           matInput
           [formControl]="myControl"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (onSelectionChange)="itemSelected(option)">
        <span>{{option.name}}</span>&nbsp;
        <span style="color: gray">{{option.string}}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

<!--this data is the selected restrictions-->
<mat-dialog-content style="min-height: 200px;">
  <mat-chip-list class="mat-chip-list-stacked">
    <mat-chip *ngFor="let chip of selected_items" (removed)="itemRemoved(chip)">
      <button matChipRemove matPrefix>
        <mat-icon>cancel</mat-icon>
      </button>
      <span class="mx-1">{{chip.name}}</span>
      <span style="color: gray">{{chip.string}}</span>
    </mat-chip>
  </mat-chip-list>
</mat-dialog-content>

<!--on save, return video object-->
<mat-dialog-actions class="justify-content-between">
  <button mat-stroked-button (click)="cancel()">{{'Cancel'|translate}}</button>
  <button mat-stroked-button (click)="save()">{{'Speichern'|translate}}</button>
</mat-dialog-actions>
