import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-wc-sc-both-viewer',
  templateUrl: './wc-sc-both-viewer.component.html',
  styleUrls: ['./wc-sc-both-viewer.component.scss']
})



export class WcScBothViewerComponent implements OnInit {
  

  @Input()
  recording: boolean;

  @Input()
  srcTestVid: MediaStream;

  @Input()
  type: string;

  @Output()
  startButtonClickedChanged = new EventEmitter<void>();

  display: string;
  permissionDenied = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  startButtonClicked(): void {
    this.startButtonClickedChanged.emit();
  }

  startButtonClicked1():void{
    // navigator.mediaDevices.getUserMedia({audio: true, video: false}).then(console.log).catch(console.log)
    //   navigator.mediaDevices.getUserMedia({video: false, audio: true}).then( stream => {
    //       window.localStream = stream;
    //       window.localAudio.srcObject = stream;
    //       window.localAudio.autoplay = true;
    //   }).catch( err => {
    //       console.log("u got an error:" + err)
    //   });
    // }
   }
}
