<div class="d-flex justify-content-between align-items-baseline m-3 flex-wrap">
  <div class="d-flex align-items-baseline mt-2">
    <mat-card-title class="fontStyle" translate>All users</mat-card-title>&nbsp;&nbsp;
    <mat-card-subtitle class="text-nunito font-weight-normal" translate>
      under your supervision
    </mat-card-subtitle>
  </div>

  <div class="d-flex w-100 align-items-baseline">
    <h3 class="font-weight-normal">
      {{rights_display.length == 1 ? ("Your right"|translate) : ("Your rights"|translate)}}:&nbsp;
    </h3>
    <h2 *ngIf="current_user">
      <span *ngFor="let i of rights_display" class="badge mr-2 text-nunito text-white"
            [style.background-color]="i.color_string"
            [matTooltip]="i.tooltip">
        {{i.right_string}}
      </span>
      <button (click)="openRightsNotionLink();"
              mat-icon-button matTooltip="{{'Need help?'|translate}}"
              matTooltipPosition="right">
        <mat-icon>help_outline</mat-icon>
      </button>
    </h2>
  </div>

  <!--search for users and bulk actions-->
  <div class="d-flex flex-wrap align-items-baseline">
    <mat-form-field appearance="outline">
      <mat-label translate>Search for…</mat-label>
      <input (keyup)="applyFilter();" [(ngModel)]="search_text" autocomplete="off" matInput
             placeholder="{{'Name or email' | translate}}">
    </mat-form-field>

    <!--  bulk actions-->
    <button [disabled]="selection.isEmpty()" [matMenuTriggerFor]="bulkActionsMenu" mat-button>
      <mat-icon class="material-icons-outlined mr-1">check</mat-icon>
      <span translate>Admin Actions</span>
    </button>

    <mat-menu #bulkActionsMenu="matMenu">
      <button (click)="performBulkAction(item.key);" *ngFor="let item of bulkOperationDropdown" [title]="item.value"
              mat-menu-item>
        {{ item.value }}
      </button>
    </mat-menu>
  </div>

  <!--buttons-->
  <div *ngIf="current_user" class="d-flex align-items-center">
    <button [matMenuTriggerFor]="menu" class="zesavi-mini-fab mr-2"
            mat-mini-fab matTooltip="{{'Filter users' | translate}}">
      <mat-icon class="material-icons-outlined" style="color: black;">filter_alt</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button (click)="filter()" mat-menu-item>
        <span translate>Alle</span>
      </button>
      <button (click)="filter('user.is_active')" mat-menu-item>
        <span translate>Deaktiviert</span></button>
      <button (click)="filter('is_profile_completed')" mat-menu-item>
        <span translate>Unvollständig</span></button>
    </mat-menu>

    <button (click)="importFromAzure()" *ngIf="current_user.is_global_manager" class="zesavi-mini-fab mr-2"
            mat-mini-fab
            matTooltip="{{'Import users from Microsoft Entra ID' | translate}}">
      <img class="azureIconImage"
           src="https://code.benco.io/icon-collection/azure-icons/Azure-Active-Directory.svg"/>
    </button>
    <button (click)="loadCSV()" *ngIf="current_user.is_global_manager" class="zesavi-mini-fab mr-2"
            mat-mini-fab matTooltip="{{'Aus CSV Datei importieren' | translate}}">
      <mat-icon class="material-icons-outlined text-dark">publish</mat-icon>
    </button>
    <button (click)="addNewUser()" *ngIf="current_user.is_global_manager || current_user.is_company_manager"
            class="zesavi-mini-fab mr-2" mat-mini-fab matTooltip="{{'Add new user to this workspace' | translate}}">
      <mat-icon class="material-icons-outlined text-dark">group_add</mat-icon>
    </button>
    <a [href]="download_users_uri" class="zesavi-mini-fab mr-2"
       download="clypp_users.csv" mat-mini-fab matTooltip="{{'Download list as CSV'|translate}}">
      <mat-icon class="material-icons-outlined text-dark">file_download</mat-icon>
    </a>
  </div>
</div>

<div class="user-list mx-3 mb-5">
  <table [dataSource]="dataSource" mat-table matSort>
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th *matHeaderCellDef mat-header-cell>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td *matCellDef="let row" mat-cell>
        <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                      (click)="$event.stopPropagation()"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="profile_pic">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let element" mat-cell>
        <div class="user-avatar" mat-card-avatar
             [style.background-image]="'url(' + element.profile_pic + ')'"></div>
      </td>
    </ng-container>

    <ng-container matColumnDef="name_email">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'Email-Adresse' | translate}}</th>
      <td (click)="userDetailPopup(element)"
          *matCellDef="let element"
          [matTooltip]="element.user.is_active ? (element.is_profile_completed ? '' : ('Profile is incomplete'|translate)) : ('User is blocked'|translate)"
          [ngClass]="{ 'strikethrough': !element.user.is_active }"
          class="cursor-pointer"
          mat-cell matTooltipPosition="right">
        <!-- the tool-tip above is:
              if user is active:
                if user has complete profile:
                  no tooltip -->
        <span class="primary-color-text">{{element.user.first_name}}&nbsp;{{element.user.last_name}}</span>
        <br>
        <span>{{element.user.email}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="dept_team">
      <th *matHeaderCellDef mat-header-cell>{{'Organizational Levels' | translate}}</th>
      <td *matCellDef="let element" mat-cell>
        <span>{{getTeamName(element.team)}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="is_approved">
      <th *matHeaderCellDef mat-header-cell mat-sort-header
          matTooltip="{{'_AUTHORIZATION_TOOLTIP'| translate:{val1:authService.company?.dept_alias,val2:authService.company?.team_alias} }}"
          matTooltipPosition="above">
        {{'Autorisiert' | translate}}
      </th>
      <td *matCellDef="let element" mat-cell>
        <mat-icon *ngIf="element.is_approved" color="primary" class="material-icons-round" mat-list-icon>check
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="is_creator">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        Creator
      </th>
      <td *matCellDef="let element" mat-cell>
        <mat-icon *ngIf="element.is_creator" color="primary" class="material-icons-round" mat-list-icon>
          check
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="is_global_manager">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        {{'Globale Administration' | translate}}
      </th>
      <td *matCellDef="let element" mat-cell>
        <mat-icon *ngIf="element.is_global_manager" color="primary" class="material-icons-round" mat-list-icon>
          check
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="is_quality_manager">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        {{'Videoverwaltung' | translate}}
      </th>
      <td *matCellDef="let element" mat-cell>
        <mat-icon *ngIf="element.is_quality_manager" color="primary" class="material-icons-round" mat-list-icon>
          check
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="user.last_login">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'Letzte Aktivität' | translate}}</th>
      <td *matCellDef="let element" [title]="element.user.last_login" mat-cell>
        {{element.user.last_login | date:'dd.MM.y'}}
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;"
        [style.background-color]="row.is_profile_completed ? '' : 'lightgray'"
        mat-row>
    </tr>
  </table>
  <div *ngIf="showSpinner" class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</div>

<!--if user is not authorised, let them know-->
<h2 *ngIf="!authorised" class="text-nunito m-4" translate>
  You need User Management rights to edit this
</h2>

