<div class="container-fluid mt-3 mb-3 groups">
  <div class="d-flex justify-content-between align-items-baseline">
    <div class="d-flex align-items-baseline">
      <h2 class="fontStyle m-0" translate>Gruppen</h2>
      <button (click)="navbarService.addGroup();" class="ml-4 border-radius-20" mat-stroked-button>
        <mat-icon mat-icon>add</mat-icon>
        <span translate>Neue Gruppe</span>
      </button>
    </div>
    <mat-form-field appearance="outline">
      <mat-label translate>Search for…</mat-label>
      <input (keyup)="applyFilter($event);"
             autocomplete="off" matInput
             placeholder="{{'Name or email' | translate}}">
    </mat-form-field>
  </div>
  <hr>

  <div class="groups-table">
    <table [dataSource]="dataSource" mat-table matSort>

      <!-- title Column -->
      <ng-container matColumnDef="title">
        <th *matHeaderCellDef mat-sort-header mat-header-cell>{{'Name'|translate}}</th>
        <td *matCellDef="let element" class="pr-2" mat-cell>
          <button mat-menu-item title="{{'View'|translate}}" (click)="viewGroup(element.id);">
            {{element.title}}
          </button>
        </td>
      </ng-container>

      <!-- user count Column -->
      <ng-container matColumnDef="users">
        <th *matHeaderCellDef mat-header-cell>{{'Mitwirkende' | translate}}</th>
        <td *matCellDef="let element" mat-cell>
          <button mat-menu-item title="{{'View members'|translate}}" (click)=viewMembers(element)>
            {{element.members.length}}
          </button>
        </td>
      </ng-container>

      <!--actions columns: view or delete a group-->
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell style="width: 160px;"></th>
        <td *matCellDef="let element" mat-cell>
          <!--join-->
          <button (click)="joinGroup(element.id);"
                  [disabled]="checkMyPresenceInThisGroup(element)"
                  class="px-1" mat-icon-button
                  title="{{'Join group'|translate}}">
            <mat-icon class="material-icons-outlined">group_add</mat-icon>
          </button>
          <!-- Delete -->
          <button (click)="deleteGroup(element.id);"
                  class="px-1" mat-icon-button
                  title="{{'Löschen'|translate}}">
            <mat-icon>
              delete_outline
            </mat-icon>
          </button>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
  </div>

  <h2 *ngIf="!authorised" class="text-nunito m-4" translate>
    You need video, global or company management rights
  </h2>
</div>
