<mat-progress-bar mode="indeterminate" *ngIf="show_spinner"></mat-progress-bar>
<div class="d-flex justify-content-between ml-3 mt-1">
  <div class="title-block row m-0" style="white-space: normal;">
    <div style="margin-top: 10px; word-break: break-all;">
      <h2 class="mb-1 fontStyle">
        <mat-icon class="mr-1 material-icons-outlined" style="vertical-align: middle;">group
        </mat-icon>&nbsp; {{group_object?.title}}
      </h2>
      {{'Creator' | translate}}: {{group_object?.uploader.first_name}} {{group_object?.uploader.last_name}}
      <div *ngIf="group_object?.company && (group_object?.company.id != authService.userDetails.company)">
        {{'Organisation'}}: {{group_object?.company.name}}
      </div>
    </div>

    <div>
      <button (click)="backToGroups()" class="button-div border-radius-20" mat-stroked-button translate>
        <mat-icon>chevron_left</mat-icon>
        <span translate>Back</span></button>
    </div>

    <div class="d-flex" style="margin-top: 8px;">
      <button (click)="addMember()" *ngIf="userIsAdmin" class="zesavi-mini-fab mr-2" mat-mini-fab
              matTooltip="{{'Mitwirkende verwalten' | translate}}">
        <mat-icon class="material-icons-outlined" style="color: black;">group_add</mat-icon>
      </button>

      <button [matMenuTriggerFor]="menu" class="zesavi-mini-fab" mat-mini-fab>
        <mat-icon style="color: black;">more_horiz</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button (click)="sendEmail();" mat-menu-item>
          <mat-icon>mail_outline</mat-icon>
          <span translate>Email senden</span></button>
        <button (click)="editGroup();" [disabled]="!userIsAdmin" mat-menu-item>
          <mat-icon>edit</mat-icon>
          <span translate>Gruppenname ändern</span></button>
        <button (click)="leaveGroup();" [disabled]="!isUserMember" mat-menu-item>
          <!--only group members should see leave group button-->
          <mat-icon>logout</mat-icon>
          <span translate>Gruppe verlassen</span></button>
        <button (click)="deleteGroup();" [disabled]="!userIsAdmin" mat-menu-item>
          <!--only group admins can delete a group-->
          <mat-icon>delete_outline</mat-icon>
          <span translate>Delete group</span></button>
      </mat-menu>
    </div>
  </div>
</div>

<div class="group-tabs mb-1">
  <mat-tab-group [(selectedIndex)]="selectedIndex" class="container-fluid">
    <mat-tab label="Clypps">
      <mat-grid-list #grid (window:resize)="onResize($event)" *ngIf="!isGroupEmpty" [cols]="cols" [gutterSize]="'1rem'"
                     class="mt-2" rowHeight="320px">
        <mat-grid-tile *ngFor="let video of videos">
          <app-video-card [backendUrl]="backendUrl" [videoObject]="video" class="VideoCardStyle"></app-video-card>
        </mat-grid-tile>
      </mat-grid-list>

      <div class="row" style="width: 90%; display:flex;justify-content: center;margin-top:2rem">
        <div class="col-10">
          <h3 translate>So fügen Sie Clypps zu einer Gruppe hinzu</h3>
          <ol>
            <li translate>Klicke auf "Clypps" in der linken Menüleiste, um deine Videos anzuzeigen
            </li>
            <li translate>Klicke auf "Bearbeiten"</li>
            <li translate>
              Under "Visibility" in the first dropdown, select "Advanced" and search for a group name
            </li>
            <li translate>Zuletzt klicke auf "Speichern" und dann auf "Veröffentlichen"
            </li>
          </ol>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="{{'Mitwirkende'|translate}}">

      <h3 translate>Mitwirkende</h3>
      <mat-grid-list #grid (window:resize)="onResize($event)" [cols]="member_cols" [gutterSize]="'1rem'"
                     class="mt-2 mx-2" rowHeight="120px" style="display: block;">
        <mat-grid-tile *ngFor="let m of group_object?.members" class="group-tile">
          <div class="group-member">
            <div class="avatar-block">
              <img [matTooltip]="m.email" [src]="m.userprofile.profile_pic" class="avatar">
              <div *ngIf="checkIfManager(m.id)" style="position: absolute; left: 50px; top:0px; color: grey;">
                <!--user is a group manager-->
                <mat-icon class="material-icons-outlined"
                          matTooltip="{{'Group administrator' | translate}}"
                          matTooltipPosition="above">
                  admin_panel_settings
                </mat-icon>
              </div>
            </div>
            <h3>{{m.first_name}} {{m.last_name}}</h3>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <h3 translate>External viewers</h3>
      <span *ngIf="authService.company?.is_external_group_disabled" style="color: darkred;" translate>
        The administrator has disabled the communication outside of your organisation.
      </span>
      <mat-grid-list #grid (window:resize)="onResize($event)" [cols]="member_cols" [gutterSize]="'1rem'"
                     class="mt-2 mx-2" rowHeight="120px" style="display: block;">
        <mat-grid-tile *ngFor="let m of group_object?.external_viewers" class="group-tile">
          <div class="group-member">
            <mat-icon (click)="removeMember(m.id, true)" *ngIf="userIsAdmin" class="only-show-on-hover"
                      matTooltip="{{'Aus Gruppe entfernen'|translate}}">person_remove
            </mat-icon>
            <div class="avatar-block">
              <img [matTooltip]="m.email" [src]="m.userprofile.profile_pic" class="avatar">
            </div>
            <h3>{{m.first_name}} {{m.last_name}}</h3>
          </div>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="userIsAdmin" class="group-tile">
          <div (click)="invite()" *ngIf="!authService.company?.is_external_group_disabled"
               matTooltip="{{'Invite someone outside of this organisation' | translate}}"
               style="text-align-last: center;">
            <mat-icon class="avatar mat-elevation-z4 zesavi-mini-fab" style="padding-top: 10px;cursor: pointer;">
              person_add
            </mat-icon>
            <h3 translate>Einladen</h3>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <div *ngIf="userIsAdmin && (invitees?.data.length != 0)" class="mt-2 mx-2" style="display: block;">
        <h3 translate>Pending Invites</h3>
        <div class="invitees-list">
          <table [dataSource]="invitees" mat-table>
            <ng-container matColumnDef="recipient_email">
              <th *matHeaderCellDef mat-header-cell>{{'Email' | translate}}</th>
              <td *matCellDef="let element" [title]="element.recipient_email" mat-cell>
                {{element.recipient_email}}
              </td>
            </ng-container>

            <ng-container matColumnDef="invited_by">
              <th *matHeaderCellDef mat-header-cell>{{'Invited by' | translate}}</th>
              <td *matCellDef="let element" mat-cell [title]="element.invited_by.email">
                {{element.invited_by?.first_name}} {{element.invited_by?.last_name}}
              </td>
            </ng-container>
            <ng-container matColumnDef="invited_on">
              <th *matHeaderCellDef mat-header-cell>{{'Invited on' | translate}}</th>
              <td *matCellDef="let element"
                  [title]="element.invited_on" mat-cell>
                {{element.invited_on | date:'dd.MM.y HH:mm'}}
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let element" mat-cell>
                <div>
                  <button (click)="sendExternalEmail(element);" class="mr-2" mat-stroked-button
                          matTooltip="{{'Email senden' | translate }}">
                    <mat-icon mat-list-icon class="material-icons-outlined mr-1">mail</mat-icon>
                    <span class="d-none d-md-inline" translate>Email senden</span>
                  </button>

                  <button (click)="deleteExternalInvitation(element)" mat-stroked-button
                          matTooltip="{{'Cancel invitation' | translate }}">
                    <mat-icon mat-list-icon class="material-icons-outlined mr-1">cancel</mat-icon>
                    <span class="d-none d-md-inline" translate>Cancel</span>
                  </button>
                </div>
              </td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

          </table>
        </div>
      </div>

    </mat-tab>
  </mat-tab-group>
</div>
