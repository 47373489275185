<!--header-->
<div class="d-flex justify-content-between">
  <div style="width: 40px;">
    <!--gap behind to adjust for X icon-->
  </div>
  <h1 class="text-nunito font-weight-bold w-100 text-center" translate>
    {{data.type == 'topic' ? 'Share this Page' : 'Share this Clypp'}}
  </h1>
  <button (click)="saveAndClose();" mat-icon-button style="margin-top: -6px;">
    <mat-icon>close</mat-icon>
  </button>
</div>

<!--tabs-->
<mat-tab-group class="pl-4 pr-4"
               style="min-width: 30vw;"
               mat-align-tabs="center" [selectedIndex]="selected_tab_index">

  <!-- Within the work space: visible for all -->
  <mat-tab label="{{ 'Inside workspace' | translate }}" [disabled]="disable_internal_tab">
    <mat-form-field appearance="outline"
                    *ngIf="is_video_creator"
                    class="w-100"
                    style="line-height: inherit; height: 50px;">
      <input [autofocus]="false"
             [formControl]="myControl"
             [matAutocomplete]="auto"
             aria-label="choose"
             class="text-nunito"
             matInput
             [placeholder]="search_placeholder"
             type="text">
      <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
        <mat-option *ngFor="let option of filteredOptions | async"
                    (onSelectionChange)="itemSelected(option)"
                    [value]="option"
                    class="text-nunito">
          <span>{{option.name}}</span>&nbsp;
          <span class="text-gray">{{option.string}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <!-- visible to all: this data is the selected restrictions -->
    <mat-card-subtitle class="text-nunito text-dark font-weight-bold my-3" translate>
      Restricted to
    </mat-card-subtitle>
    <div class="w-100 overflow-auto mb-2 px-1" style="height:150px;">
      <!--padding x is needed to remove x-axis scroll-->
      <mat-chip-list class="mat-chip-list-stacked">
        <!--If no one is selected, then is_restricted is false -->
        <mat-chip class="text-nunito w-fill-available" *ngIf="selected_items.length == 0">
          <mat-icon class="mx-1 material-icons-outlined" style="color: grey;">visibility</mat-icon>
          <span translate>Everyone can see this content</span>
        </mat-chip>
        <!-- remaining items -->
        <mat-chip (removed)="itemRemoved(chip)"
                  class="text-nunito w-fill-available"
                  *ngFor="let chip of selected_items">
          <button matChipRemove matPrefix [hidden]="!is_video_creator">
            <mat-icon>cancel</mat-icon>
          </button>
          <span class="mx-1">{{chip.name}}</span>
          <span class="text-gray">{{chip.string}}</span>
          <!-- there is a schedule, click to delete-->
          <button (click)="deleteSchedule(chip);" *ngIf="findSchedule(chip);"
                  class="mat-chip-remove remove-event-button"
                  matTooltip="{{'Access expires on'|translate}}&nbsp;{{findSchedule(chip) | date: 'dd.MM.yyyy'}}"
                  matTooltipPosition="right">
            <mat-icon>free_cancellation</mat-icon>
          </button>
          <!-- there is no schedule, click to add-->
          <ng-container *ngIf="!findSchedule(chip) && is_video_creator">
            <!-- there should no existing schedule, user must be creator and paid user -->
            <button (click)="picker.open()" class="mat-chip-remove add-event-button" [disabled]="is_free_user"
                    matSuffix matTooltip="{{'Set expiry date'|translate}}" matTooltipPosition="right">
              <mat-icon>insert_invitation</mat-icon>
            </button>
            <!--create a form field for date piker-->
            <mat-form-field class="d-none">
              <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" (dateInput)="addSchedule(chip, $event)">
              <mat-datepicker #picker touchUi></mat-datepicker>
            </mat-form-field>
          </ng-container>
        </mat-chip>
      </mat-chip-list>
    </div>

    <mat-divider class="my-4"></mat-divider>

    <mat-card-subtitle *ngIf="is_anything_changed" class="my-1 text-nunito" translate>
      Close popup to save changes
    </mat-card-subtitle>

    <!-- Copy URL link -->
    <div class="w-100 d-flex justify-content-between mt-2">
      <!-- Copy URL link: full width in case of topic, half width in case of video -->
      <button (click)="copyToClipboard(internal_view_link, true)"
              [ngClass]="data.type == 'video' ? 'w-50 mr-2' : 'w-100'"
              class="border-radius-20"
              color="primary" mat-stroked-button>
        <mat-icon class="mr-2">link</mat-icon>
        <span translate>Copy link</span>
      </button>

      <!-- Copy embed URL: only visible in case of video -->
      <button (click)="copyToClipboard(embed_video_link, false)"
              *ngIf="data.type == 'video'"
              class="border-radius-20 ml-2 w-50"
              color="primary" mat-stroked-button>
        <mat-icon class="mr-2">link</mat-icon>
        <span translate>Copy embed link</span>
      </button>
    </div>

    <div class="w-100 d-flex justify-content-between mt-3">
      <!-- Download QR Code -->
      <button (click)="downloadQR(internalQR, internal_view_link);"
              color="primary"
              class="border-radius-20 mr-2 w-50" mat-stroked-button>
        <mat-icon class="mr-2">qr_code</mat-icon>
        <span translate class="d-none d-sm-inline">Download QR Code</span>
      </button>
      <!-- Copy iFrame embed code -->
      <button (click)="copyToClipboard(iframe_code)"
              color="primary"
              class="border-radius-20 ml-2 w-50" mat-stroked-button>
        <mat-icon class="mr-2">code</mat-icon>
        <span translate class="d-none d-sm-inline">Copy iFrame code</span>
      </button>
    </div>

    <div class="qrcodeImage" hidden>
      <qrcode #internalQR [qrdata]="internal_view_link" allowEmptyString=false colorDark="#000000" elementType="canvas"
              errorCorrectionLevel="M" imageHeight="75" imageSrc="assets/images/default_overlay.png" imageWidth="75"
              margin="2" scale="1" width="500"></qrcode>
    </div>
  </mat-tab>

  <!-- Outside the work space -->
  <mat-tab label="{{ 'Outside workspace' | translate }}">
    <div *ngIf="!is_external_sharing_disabled">
      <div *ngIf="is_video_creator" class="mt-3">
        <!-- dropdown and schedule calendar -->
        <div class="d-flex w-100 align-items-baseline">
          <!--dropdown-->
          <mat-form-field appearance="outline" class="fontNormal w-fill-available"
                          floatLabel="never"
                          style="min-height: 100px;">
            <mat-label translate>Sichtbarkeit</mat-label>
            <!--do not allow user to change it, if it is disabled company wide-->
            <mat-select (selectionChange)="externalChange($event)" [(value)]="selected_external_option">
              <mat-select-trigger class="fontNormal">
                <mat-icon style="vertical-align: middle;">{{ selected_external_option.icon }}</mat-icon>
                {{ selected_external_option.name }}
              </mat-select-trigger>
              <mat-option *ngFor="let externalVal of external_restriction_options" [value]="externalVal"
                          class="fontNormal">
                <mat-icon style="vertical-align: middle;">{{ externalVal.icon }}</mat-icon>
                {{ externalVal.name }}
              </mat-option>
            </mat-select>
            <mat-hint>{{ selected_external_option.hint }}</mat-hint>
          </mat-form-field>

          <!--schedule calendar-->
          <ng-container *ngIf="selected_external_option.value != 'private'">
            <!-- only show schedule selection in case of public video-->
            <!-- there is a schedule, click to delete-->
            <button (click)="deleteSchedule(publicSchedule);" *ngIf="findSchedule(publicSchedule);"
                    matTooltip="{{ 'Access expires on'|translate }}&nbsp;{{ findSchedule(publicSchedule) | date: 'dd.MM.yyyy' }}"
                    matTooltipPosition="right" class="border-radius-20" mat-icon-button>
              <mat-icon class="mr-1">free_cancellation</mat-icon>
            </button>
            <!-- there is no schedule, click to add-->
            <ng-container *ngIf="!findSchedule(publicSchedule)">
              <!-- there should no existing schedule, user must be creator and paid user -->
              <button (click)="picker.open()" [disabled]="is_free_user"
                      class="border-radius-20" mat-icon-button
                      matTooltip="{{'Set expiry date'|translate}}" matTooltipPosition="right">
                <mat-icon class="mr-1">insert_invitation</mat-icon>
              </button>
              <!--create a form field for date piker-->
              <mat-form-field class="d-none">
                <input (dateInput)="addSchedule(publicSchedule, $event)" [matDatepicker]="picker" [max]="maxDate"
                       [min]="minDate"
                       matInput>
                <mat-datepicker #picker touchUi></mat-datepicker>
              </mat-form-field>
            </ng-container>
          </ng-container>
        </div>

        <!--access code -->
        <div *ngIf="selected_external_option.value == 'accessCode'" class="d-flex align-items-center mt-3">
          <mat-form-field appearance="standard" class="w-75">
            <mat-label>
              {{"Access Code" | translate}}
            </mat-label>
            <mat-hint translate>
              Protect with access code (optional)
            </mat-hint>
            <input (keyup.enter)="saveAccessKey();" [(ngModel)]="data.access_key"
                   autocomplete="off"
                   matInput
                   maxlength="32"
                   minlength="0"
                   placeholder="{{'Code (max. 32 Zeichen)'|translate}}"
                   type="text"/>
            <button (click)="copyToClipboard(data.access_key)" mat-button mat-icon-button matSuffix>
              <mat-icon>content_copy</mat-icon>
            </button>
          </mat-form-field>

          <!--[disabled]="access_key.length == 0"-->
          <button (click)="saveAccessKey();"
                  color="primary"
                  class="text-nunito border-radius-20 ml-3 w-25" mat-stroked-button
                  style="height: max-content;">
            <!--show save button only in less than large screen-->
            <mat-icon class="d-lg-none">save</mat-icon>
            <!--show save text only in large screen-->
            <span class="d-none d-lg-inline" translate>Speichern</span>
          </button>
        </div>
      </div>

      <!--The video is internal and I am not creator-->
      <div *ngIf="!(is_video_creator || data.is_external)" class="mt-3 align-items-center d-flex">
        <mat-icon class="material-icons-outlined" style="width: 30px;">info</mat-icon>
        <span translate>This content is not public.</span>
      </div>

      <!--The video is external, password protected and I am not creator-->
      <div *ngIf="!is_video_creator && data.is_access_key_needed && data.is_external"
           class="mt-3 align-items-center d-flex">
        <mat-icon class="material-icons-outlined" style="width: 30px;">info</mat-icon>
        <span translate>
          You may need an access code.
        </span>
      </div>

      <mat-divider class="my-4"></mat-divider>

      <!-- this container is visible for both uploader/viewer when video is external-->
      <div *ngIf="data.is_external" class="mt-2">
        <div class="w-100 d-flex justify-content-between mt-2">
          <!-- Copy URL link: full width in case of topic, half width in case of video -->
          <button (click)="copyToClipboard(external_view_link, true)"
                  [ngClass]="data.type == 'video' ? 'w-50 mr-2' : 'w-100'"
                  class="border-radius-20"
                  color="primary" mat-stroked-button>
            <mat-icon class="mr-2">link</mat-icon>
            <span translate>Copy link</span>
          </button>

          <!-- Copy embed URL: only visible in case of video -->
          <button (click)="copyToClipboard(embed_video_link, false)"
                  *ngIf="data.type == 'video'"
                  class="border-radius-20 ml-2 w-50"
                  color="primary" mat-stroked-button>
            <mat-icon class="mr-2">link</mat-icon>
            <span translate>Copy embed link</span>
          </button>
        </div>

        <div class="w-100 d-flex justify-content-between mt-3">
          <!-- Download QR Code -->
          <button (click)="downloadQR(externalQR, external_view_link);"
                  color="primary"
                  class="border-radius-20 mr-2 w-50" mat-stroked-button>
            <mat-icon class="mr-2">qr_code</mat-icon>
            <span translate class="d-none d-sm-inline">Download QR Code</span>
          </button>

          <!-- Copy iFrame embed code -->
          <button (click)="copyToClipboard(iframe_code)"
                  color="primary"
                  class="border-radius-20 ml-2 w-50" mat-stroked-button>
            <mat-icon class="mr-2">code</mat-icon>
            <span translate class="d-none d-sm-inline">Copy iFrame code</span>
          </button>
        </div>

        <div class="qrcodeImage" hidden>
          <qrcode #externalQR [qrdata]="external_view_link" allowEmptyString=false colorDark="#000000"
                  elementType="canvas"
                  errorCorrectionLevel="M" imageHeight="75" imageSrc="assets/images/default_overlay.png" imageWidth="75"
                  margin="2" scale="1" width="500">
          </qrcode>
        </div>
      </div>
    </div>

    <!--When Company external sharing is disabled, then we do not show external sharing options-->
    <div *ngIf="is_external_sharing_disabled" class="mt-3 align-items-center d-flex">
      <mat-icon class="material-icons-outlined" style="width: 30px;">info</mat-icon>
      <span translate>Content can no longer be shared with people outside the organization through external links</span>
    </div>
  </mat-tab>
</mat-tab-group>

<mat-progress-bar class="mt-2" color="primary" *ngIf="in_progress" mode="indeterminate"></mat-progress-bar>
