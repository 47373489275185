import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Subscription, forkJoin} from 'rxjs';
import {environment} from '../../environments/environment';
import {MiniDetails, TagWiseContent, VideoCard, VideoHistoryCard, VideoView} from '../models/video/video.interface';
import {AuthService} from '../services/auth.service';
import {DataService} from '../services/data.service';
import {TranslateService} from '@ngx-translate/core';
import {MyVideosVideoPopupComponent} from "../my-videos-video-popup/my-videos-video-popup.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Friend} from "../dashboard/global-administration/partner-organizations/partner-organizations.component";
import {UserActivitiesOverview} from "../models/activity.interface";
import {VideoRequestDialogComponent} from '../shared/video-request-dialog/video-request-dialog.component';
import {ProgressSpinnerDialogComponent} from "../shared/progress-spinner-dialog/progress-spinner-dialog.component";
import {NavbarService} from "../services/navbar.service";

@Component({
  selector: 'internal-video',
  templateUrl: './internal-video.component.html',
  styleUrls: ['./internal-video.component.scss']
})
export class InternalVideoComponent implements OnInit {
  cols: number;
  videos: VideoCard[] = [];
  gutterSize: number;
  subscription: Subscription;
  backendUrl: string;
  pageSize: number = 10;
  searchQuery: string = "";
  sortValue: string = 'added_on';  // added_on
  page_title: string = "";
  query_construct: string = 'user/videos/';
  options = {observe: 'body', responseType: 'json'};

  all_videos_case: boolean = true;  // to keep track of tag-case or all-videos-case

  tags: MiniDetails[] = [];
  page: number = 1;
  maxPages: number = 1;
  showSpinner: boolean = false;
  partner_companies: Friend[] = [];
  greetings_text: string = "";
  active_days_label: string[] = [];  // ['Mo', 'Tu'…] last seven days, including today
  seven_days_label: string[] = [];  // labels when user was active
  userActivityResp: UserActivitiesOverview = null;
  increasePercentage: number = 0;
  history_videos: VideoHistoryCard[] = [];
  featured_videos: VideoCard[] = [];
  @ViewChild('scroll') scroll: ElementRef;
  searchCase: boolean = false;
  searchLength: number = 0;

  constructor(private dataService: DataService,
              private translateService: TranslateService,
              private navbarService: NavbarService,
              public authService: AuthService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    this.navbarService.getUserProfileDetails();

    // get main page results
    this.getAllClypps();

    // get features videos and history
    this.getAllBlockData();

    // load tags and partnerships
    this.getFeaturedTags();
    const interval = setInterval(() => {
      // load after some time, as auth service may not be ready
      this.getPartnerships();
      // load after some time, as json file may not be ready
      this.getUserActivityDetails();
      clearInterval(interval);
    }, 400);

    // find hour of the day to show greetings
    const d = new Date();
    const hour = d.getHours();
    if (hour <= 11) {
      this.greetings_text = this.translateService.instant("Good morning");
    } else if (hour <= 16) {
      this.greetings_text = this.translateService.instant("Good afternoon");
    } else {
      this.greetings_text = this.translateService.instant("Good evening");
    }
  }

  clearOldVideoRecord() {
    this.all_videos_case = true;
    this.page = 1;
    this.maxPages = 1;
    this.videos = [];
  }

  applyFilter(): void {
    if (this.searchQuery !== '') {
      this.clearOldVideoRecord();
      this.searchCase = true;
      this.query_construct = 'user/videos/';
      this.getInternalVideos();
    }
    else {
      if (this.searchCase){
        this.back();
      }
    }
  }

  // todo: hide buttons if no scroll is there

  getAllClypps() {
    this.clearOldVideoRecord();
    this.searchQuery = '';
    this.page_title = this.translateService.instant("All Clypps");
    this.query_construct = 'user/videos/';
    this.getInternalVideos();
  }

  partnerSelected(partner: Friend) {
    this.clearOldVideoRecord();
    this.searchQuery = '';
    this.page_title = this.translateService.instant("All Clypps");
    this.page_title += " ";
    this.page_title += this.translateService.instant("von");
    this.page_title += " ";
    this.page_title += partner.sharing_company.name;
    this.query_construct = `user/friends/${partner.id}/`;
    this.getInternalVideos();
  }

  getInternalVideos(): void {
    if (this.page <= this.maxPages && !this.showSpinner && this.all_videos_case) {
      this.all_videos_case = true;
      this.showSpinner = true;
      let queryConstruct = `${this.query_construct}?sort_by=${this.sortValue}&page=${this.page}&search_query=${this.searchQuery}`;
      this.dataService.getURL(queryConstruct, this.options).subscribe((resp: any) => {
        this.maxPages = Math.ceil(resp.length / resp.pageSize);
        this.videos.push(...resp['data']);
        this.showSpinner = false;
        this.page = resp.pageIndex + 1;
        if (this.searchCase) {
          this.searchLength = resp.length;
        }
        // load more videos if we are on page 1
        if (this.page == 2) {
          this.getInternalVideos();
        }
      }), (err) => {
        console.error(err);
        this.showSpinner = false;
      }
    }
  }

  playVideo(video_id) {
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });
    this.dataService.getURL<VideoView>(`user/videos/${video_id}/`, this.options).subscribe((res: VideoView) => {
      dialogRef.close();
      this.dialog.open(MyVideosVideoPopupComponent, {
        // width: '1120px',
        height: '90%',
        disableClose: false,
        data: res,
        panelClass: 'my-panel',
      });
    }, (err) => {
      dialogRef.close();
      window.alert(err.error);
    });
  }

  getFeaturedTags(): void {
    this.dataService.getURL('user/featured-tags/').subscribe((data: any) => {
      this.tags = data;
    });
  }

  getPartnerships(): void {
    // todo: load partner companies only if enabled
    if (this.authService.company?.is_partner_content_shown) {
      this.dataService.getURL('user/friends/', this.options)
        .subscribe((res: Friend[]) => {
            this.partner_companies = res;
          }
        );
    }
  }

  tagSelected(tag: MiniDetails) {
    this.page_title = tag.name;
    this.all_videos_case = false;
    // load the videos:
    let tagUrl = `user/tags/${tag.id}/`;
    this.videos = [];
    this.showSpinner = true;
    this.dataService.getURL(tagUrl, this.options).subscribe((resp: TagWiseContent) => {
      this.videos = resp.videos;
      this.showSpinner = false;
    }, (err) => {
      console.error(err);
      // window.alert(err.error);
      this.showSpinner = false;
    });
  }

  getUserActivityDetails() {
    this.dataService.getURL('user/activities/overview/', this.options)
      .subscribe((res: UserActivitiesOverview) => {
        this.userActivityResp = res;
        const initial = res.n_14_days - res.n_7_days;
        const final = res.n_7_days;
        // formula: (final-initial)/initial
        if (initial != 0) {
          this.increasePercentage = Math.round(((final - initial) / initial) * 100);
        } // else, do not show

        // prepare last 7 active days
        for (let i of this.userActivityResp.active_date_7_days) {
          this.active_days_label.push(new Date(i).toDateString().slice(0, 2));
        }
        let i = 6;
        let seven_days: Date[] = [];
        while (i >= 0) {
          const today = new Date();
          seven_days.push(new Date(today.setDate(today.getDate() - i)));
          i--;
        }
        for (let i of seven_days) {
          this.seven_days_label.push(i.toDateString().slice(0, 2));
        }
      }, (err) => {
        window.alert(err.error.detail);
      });
  }

  getAllBlockData() {
    const featuredVideos = this.dataService.getURL('user/videos/?is_featured=true&sort_by=added_on', this.options);
    const continueWatching = this.dataService.getURL('user/videos/history/?page=1', this.options);

    forkJoin([featuredVideos, continueWatching]).subscribe(
      (responses) => {
        this.featured_videos = responses[0]['data'] as VideoCard[];
        this.history_videos = responses[1]['data'] as VideoHistoryCard[];
      },
      (error) => {
        console.error(error);
      }
    );
  }

  scrollToLeftRight(id: string, left: boolean = false) {
    const element = document.getElementById(id);
    // element has 2+x children, first and last children are buttons
    // scroll distance = width of each child * number of children visible
    const child_width = element.firstElementChild.clientWidth;
    const viewport_width = element.clientWidth;
    const number_of_visible_children = Math.max(Math.floor(viewport_width / child_width), 1);
    // above number may become 0 for very large zoom levels, hence using max

    let scroll_distance = (child_width + 20) * number_of_visible_children;
    // margin-right is added
    if (left) {
      scroll_distance = -scroll_distance;
    }

    element.scrollBy({
      behavior: "smooth",
      left: scroll_distance,
      top: 0
    });
  }

  //scrolled to the top of the view
  scrollTop() {
    this.scroll.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  back() {
    this.searchCase = false;
    this.getAllClypps();
  }

  clearInput() {
    // clear search query if any,
    // else go back if in search case
    if (this.searchQuery){
      this.searchQuery = '';
    }
    else {
      if (this.searchCase){
        this.back();
      }
    }
  }
}
