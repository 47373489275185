import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  dataCarrier: BehaviorSubject<any> = new BehaviorSubject<any>({});
  searchParamChanged: BehaviorSubject<any> = new BehaviorSubject<any>({});
  constructor(private httpClient: HttpClient) {}

  /* GET */
  getURL<T>(path: string, options = {}): Observable<T> {
    return this.httpClient.get<T>(`${environment.backendURL}/${path}`, options);
  }

  getSearchURL<T>(title: string, page: string, options = {}): Observable<T> {
    return this.getURL<T>(`user/videos/?search_query=${title}&page=${page}`);
  }

  getManagerDeleteUser<T>(user_id: number): Observable<T> {
    return this.getURL<T>(`manager/users/user/${user_id}/`, {observe: 'body', responseType: 'json'});
  }

  // delete user
  deleteUser(user_id: number): Observable<string> {
    return this.deleteURL(`manager/users/user/${user_id}/`);
  }

  /* PUT */
  putURL<T>(path: string, body: any, options = {}): Observable<T> {
    return this.httpClient.put<T>(
      `${environment.backendURL}/${path}`,
      body,
      options
    );
  }

  /* PATCH */
  patchURL<T>(path: string, body = {}, options = {}): Observable<T> {
    return this.httpClient.patch<T>(`${environment.backendURL}/${path}`, body, options);
  }

  /* POST */
  postURL<T>(path: string, body: any, options = {}): Observable<T> {
    return this.httpClient.post<T>(
      `${environment.backendURL}/${path}`,
      body,
      options
    );
  }

  deleteURL<T>(path: string, options = {}): Observable<T> {
    return this.httpClient.delete<T>(
      `${environment.backendURL}/${path}`,
      options
    );
  }

  deleteVideo(video: any): Observable<string> {
    return this.deleteURL(`user/videos/${video['id']}/`);
  }

  // update user profile
  updateUser(formDetails: any): Observable<string> {
    return this.putURL<string>(`user/`, formDetails);
  }

  // activity json mapping
  getActivityJson<T>(): Observable<T> {
    return this.httpClient.get<T>('../../assets/files/activity_mapping.json');
  }

  readVTTFile(vttFilePath: string): Observable<string> {
    console.log("vttfile", vttFilePath)
    return this.httpClient.get(vttFilePath, { responseType: 'text' });
  }
}
