import {AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType} from '@angular/common/http';
import {WebcamRecorderService} from '../../services/webcam-recorder.service';
import {VideoType} from '../../models/video/video-type.enum';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../services/auth.service';
import {AudioRecorderService} from '../../services/audio-recorder.service';
import * as audioMeter from 'src/app/scripts/volume-meter.js';
import {DomSanitizer} from '@angular/platform-browser';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {NavbarService} from '../../services/navbar.service';
import {Observable} from 'rxjs';
import {DataService} from '../../services/data.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

@Component({
  selector: 'app-webcam-creation',
  templateUrl: './webcam-creation.component.html',
  styleUrls: ['./webcam-creation.component.scss'],
  host: {
    "(window:resize)": "onWindowResize($event)"
  },
})
export class WebcamCreationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('controls') controls;
  @ViewChild('webcam') webcam;
  @ViewChild('webcamSCViewer') webcamSCViewer;
  @ViewChild('meter') meter: ElementRef;
  @ViewChild('videoTimer') videoTimer;
  @Input() vidValue: any;

  @ViewChild('grid') grid;
  backfromTrim: boolean = false;
  cols: any;
  notescol: any;
  vidcols: any;
  notesValue: any;

  gridByBreakpoint = {
    wL: '1:0.8',
    xl: '1.3:0.8',
    lg: '1:0.9',
    md: '1:1.5',
    sm: '1:2',
    xs: '1:5.2',
  };
  gridRow = {
    wL: 28,
    xl: 32,
    lg: 18,
    md: 14,
    sm: 10,
    xs: 6,
  };
  gridvid = {
    wL: '15',
    xl: '17',
    lg: '14',
    md: '12',
    sm: '8',
    xs: '6',
  };
  rowval: any;
  environment = environment;
  formData: FormData;
  showRecordingWindow = false;
  timeLimit: number;
  VideoTypeValue: any;
  paused = false;
  showCountdown = false;
  timeLeft: string = '3';
  isRecording: boolean = false;
  webcamVideo: any;
  recorderTest;
  webcamDispVideo: any;
  showVideodropdown = false;
  videoTypes = VideoType;
  rowValue: any;
  notes = [];
  alreadyEnteredTrimmingStep = false;

  showAudioOption: boolean = false;
  public audioIcon = 'mic';
  showAudio: boolean = true;
  showGreenRect: boolean = false;
  micVal: boolean = true;
  showMicEditBack: boolean = false;
  micoffOnRecreate: boolean = false;
  arecorder;
  responseVal: any;
  recorder;
  readonly WIDTH = 35;
  readonly HEIGHT = 2;
  defaultValue: any;
  defaultVideoValue: any;
  audioinputVal: any;
  videoProcessingComplete = false;
  videoRecordingFinished = false;
  videoURL: any;
  disabledReset: boolean;
  nextLabel = 'Aufnahme beenden';
  videoInputValue: any;
  width: number = window.innerWidth;
  videoId: string;
  height: number = window.innerWidth;
  backfromReview: any;
  Video_ID: any;
  videoId_Resp: any;
  date: any;
  videoFormControl;
  videoBOTHURL: string;
  restart_Start: boolean = false;
  progress: number = 0;
  processFinish = false;
  audioHigh = new Audio();
  audioLow = new Audio();
  webcamVideoBlob: any;
  videoSize: any;
  maxSize: any;
  blobSize: any;
  private audioMeter: any;
  testvalue:any;
  videovalue :any;
  selectedVal: any;
  videoArray: any;

  constructor(
    public webcamRecorderService: WebcamRecorderService,
    public audioRecorderService: AudioRecorderService,
    public authService: AuthService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private breakpointObserver: BreakpointObserver,
    private navbarService: NavbarService,
    private router: Router,
    private translateService: TranslateService,
    private dataService: DataService
  ) {
    let numberCheck = /\d+/g;
    if (this.environment.videoSizeLimit.includes('GB')) {
      this.videoSize = this.environment.videoSizeLimit.match(numberCheck);
      this.maxSize = ((this.videoSize * 1000) * 2 ** 20);
    } else {
      this.videoSize = this.environment.videoSizeLimit.match(numberCheck);
      this.maxSize = (this.videoSize * 2 ** 20);
    }
    this.navbarService.showSideNav = false;
    window.onresize = () => {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 699px)');
      this.navbarService.showSideNav = false;
      if (this.width >= 2029) {
        this.rowval = 6;
      } else if (this.width >= 1850) {
        this.rowval = 8;
      } else if (this.width <= 1420) {
        this.rowval = 7;
      } else {
        this.rowval = 8;
      }
    }
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
        Breakpoints.Handset,
        Breakpoints.HandsetPortrait,
        Breakpoints.WebLandscape,
        Breakpoints.WebPortrait,
      ])
      .subscribe((result) => {
        if (result.breakpoints[Breakpoints.WebLandscape]) {
          this.cols = this.gridByBreakpoint.wL;
          this.notescol = this.gridRow.wL;
          this.vidcols = this.gridvid.wL;
        }

        if (result.matches) {
          if (result.breakpoints[Breakpoints.XSmall]) {
            this.cols = this.gridByBreakpoint.xs;
            this.notescol = this.gridRow.xs;
            this.vidcols = this.gridvid.xs;
          }
          if (result.breakpoints[Breakpoints.Small]) {
            this.cols = this.gridByBreakpoint.sm;
            this.notescol = this.gridRow.sm;
            this.vidcols = this.gridvid.sm;
          }
          if (result.breakpoints[Breakpoints.Medium]) {
            this.cols = this.gridByBreakpoint.md;
            this.notescol = this.gridRow.md;
            this.vidcols = this.gridvid.md;
          }
          if (result.breakpoints[Breakpoints.Large]) {
            this.cols = this.gridByBreakpoint.lg;
            this.notescol = this.gridRow.lg;
            this.vidcols = this.gridvid.lg;
          }
          if (result.breakpoints[Breakpoints.XLarge]) {
            this.cols = this.gridByBreakpoint.xl;
            this.notescol = this.gridRow.xl;
            this.vidcols = this.gridvid.xl;
          }
        }
      });

      this.router.events.subscribe(event => {
        // Handle route change
        this.stopTestWebcam();
    });
  }

  playDelay = async (start: boolean) => {
    this.showCountdown = true;
    this.timeLeft = '3';
    await delay(900);
    this.timeLeft = '2';
    await delay(900);
    this.timeLeft = '1';
    await delay(300);
    this.audioHigh.play();
    await delay(700);
    this.showCountdown = false;
  }

  deleteBeforeClosing() {
    return  this.dataService.deleteVideo(this.vidValue).toPromise();
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    // console.log(event);
    this.goBackRemovePermission();
  }

  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    let value = window.location.href.split("/").pop();
    if (value == 'record' && this.vidValue['video_file'] == null) {
      let httpData = await this.deleteBeforeClosing();
    }
  }


  ngOnInit(): void {
    // load audio on init
    this.audioHigh.src = '../../assets/Mini_Button_01.wav';
    this.audioHigh.load();
    this.audioLow.src = '../../assets/Mini_Button_02.wav';
    this.audioLow.load();


    let timerValue = localStorage.getItem('max_video_duration_in_seconds');
    let timeval = Number(timerValue);
    if (timeval == 0) {
      this.timeLimit = 180;
    } else {
      this.timeLimit = timeval;
    }

    if (this.width >= 2029) {
      this.rowval = 6;
    } else if (this.width >= 1850) {
      this.rowval = 8;
    } else if (this.width <= 1420) {
      this.rowval = 7;
    } else {
      this.rowval = 8;
    }

    this.VideoTypeValue = 'WC';
    this.navbarService.showSideNav = false;
    this.navbarService.enableCreationMode();
    this.navbarService.hide();
    this.notes = [
      'Hinweis: Beginne dein Video mit dem Satz: "In diesem Video zeige ich ' +
      this.date +
      ' wie…',
      '',
    ];

    //audio dropdown
    this.webcamRecorderService.getAudioVal();
    this.webcamRecorderService.audioVal.subscribe((data) => {
      this.audioinputVal = data;
      this.defaultValue = this.audioinputVal[0];
    });
    //video dropdown
    // this.webcamRecorderService.getVideoVal();
    // this.webcamRecorderService.videoVal.subscribe((data) => {
    //   this.videoInputValue = data;
    //   this.defaultVideoValue = this.videoInputValue[0];
    // });
    this.startAudioRecording();

  }


  notesChange(event) {
    this.notesValue = event[1];
  }

  ngAfterViewInit(): void {

    if (this.vidValue['state'] == 'NO') {
      this.restart_Start = true;
    }
    // this.webcamRecorderService.videoVal.subscribe((data) => {
    //   if(data['deviceId'] === ''){
    //     this.videovalue = undefined;
    //   }else{
    //     this.videovalue = data[0];
    //   }

      // this.onVideoDropChange(data[0],false);
    if (this.vidValue['video_file'] != null || this.vidValue['processed_file'] != null) {
      this.nextLabel = 'Aufnahme beibehalten';
      this.controls.disabledStart = true;
      this.controls.disabledReset = false;
      this.controls.disabledResume = true;
      this.alreadyEnteredTrimmingStep = true;
      this.startTestWebcam(true);
    } else {
      this.paused = false;
      this.disabledReset = true;
      this.startTestWebcam(false);

    }
  // });

    if (this.vidValue['script'] !== null) {
      this.notes = [
        'Hinweis: Beginne dein Video mit dem Satz: "In diesem Video zeige ich ' +
        this.date +
        ' wie…',
        this.vidValue['script'],
      ];
    }
  }

  startTestWebcam = async (disabledStart) => {
    let videoInputArr = [];
    //this.ngOnInit();
    this.isRecording = true;
    this.recorderTest = await this.webcamRecorderService
      .webcamTesting()
      .then((strm: any) => strm)
      .catch((err: any) => err);
    navigator.mediaDevices.enumerateDevices().then(devices =>
      devices.forEach(device => {
        if (device.kind === 'audioinput') {
          if (device.label != "") {
            this.decideForWebcam(disabledStart);
          } else {
            navigator.mediaDevices.getUserMedia({audio: true})
              .then((stream) => {
                this.decideForWebcam(disabledStart);
              })
              .catch((err) => {
                this.controls.disabledStart = true;
                this.webcam.permissionDenied = true;
              });

          }
        }else if(device.kind === 'videoinput'){
          videoInputArr.push({ label: device.label, deviceId: device.deviceId })
          if(this.videoInputValue == null){
          this.videoInputValue = videoInputArr;
          this.defaultVideoValue = this.videoInputValue[0];
          }
        }
      }));
  };
  decideForWebcam = (disabledStart) => {
    this.webcamVideo = this.recorderTest.start();
    if (this.webcamVideo != 'denied') {
      this.webcamDispVideo = this.webcamRecorderService.getStreamTesting();
      this.controls.disabledStart = disabledStart;
      this.webcam.permissionDenied = false;
    } else {
      this.controls.disabledStart = true;
      this.webcam.permissionDenied = true;
    }
  }

  stopTestWebcam = async () => {
    this.recorderTest.stop();
  };

  startWebcamRecording = async () => {
    this.stopTestWebcam();
    this.stopAudioRecording();
    this.controls.disabledStart = true;
    this.isRecording = true;
    this.recorder = await this.webcamRecorderService.recordWebcam();
    this.webcamDispVideo = this.webcamRecorderService.getStream();
    this.showAudio = false;
    await this.playDelay(true);
    this.webcamVideo = this.recorder.start();
    await this.initRecording();
    this.disabledReset = false;
    this.controls.disabledStart = false;
    this.showGreenRect = true;
    if (
      this.vidValue['video_file'] != null) {
      this.nextLabel = 'Aufnahme beenden';
    }
  };

  stopWebcamRecording = async () => {
    this.audioLow.play();
    this.videoRecordingFinished = true;
    this.isRecording = false;
    this.showCountdown = false;

    this.videoTimer.pauseTimer();
    this.webcamVideoBlob = await this.recorder.stop();
    let file = new File([this.webcamVideoBlob.videoBlob], 'recording', {
      type: 'video/webm',
    });
    this.blobSize = this.webcamVideoBlob.videoBlob.size;
    this.formData = new FormData();
    this.formData.append('video_file', file, `${new Date().getTime()}.webm`);
    this.videoURL = this.sanitizer.bypassSecurityTrustUrl(this.webcamVideoBlob.videoUrl);
    if (this.blobSize < this.maxSize) {
      this.startConverting();
    } else {
      // this.snackBar.open(this.translateService.instant('_VideoSize_Exceed', {val: environment.videoSizeLimit}), '', { duration: 3000 });
      this.controls.disabledReset = false;
      this.disabledReset = true;
      window.alert(this.translateService.instant('_VideoSize_Exceed', {val: environment.videoSizeLimit}));
      if (window.confirm(this.translateService.instant("Would you like to download the video?"))) {
        this.downloadVideo();
      }
    }
  };

  pauseRecording = async () => {
    this.audioLow.play();
    this.getRecordingPaused();
  };

  getRecordingPaused = async () => {
    this.paused = true;
    this.controls.disabledReset = false;
    this.showCountdown = true;
    this.timeLeft = 'Pause';
    this.videoTimer.pauseTimer();
    await this.recorder.pause();
    this.controls.disabledResume = true;
    await delay(1000);
    this.controls.disabledResume = false;
  };

  resumeRecording = async () => {
    this.controls.disabledResume = true;
    this.disabledReset = true;
    this.controls.disabledReset = true;
    await this.playDelay(false);
    this.controls.disabledResume = false;
    this.disabledReset = false;
    this.paused = false;
    this.showCountdown = false;
    this.videoTimer.resumeTimer();
    this.disabledReset = false;
    this.webcamVideo = await this.recorder.resume();
  };

  startConverting(): void {
    if (typeof this.vidValue != 'object') {
      this.vidValue = JSON.parse(this.vidValue);
    }

    if (this.notesValue != undefined) {
      this.formData.append('script', this.notesValue);
    }
    if (this.backfromTrim) {
      this.formData.append('edit_parameters', null);
    }

    let observable;
    observable = this.httpClient.put(
      `${environment.backendURL}/user/videos/${this.vidValue['id']}/`,
      this.formData,
      {
        responseType: 'text',
        reportProgress: true,
        observe: 'events'
      }
    );
    // this.stopAudioRecording();
    this.showProgressSpinnerUntilExecuted(observable);
  }

  openConfirmOverride = () => {
    let message = this.translateService.instant("Attention!\nUnsaved changes will be lost.\nClear recording?");
    if(window.confirm(message)){
      this.videoURL = '';
      this.videoTimer?.resetTimer();
      if (this.videoTimer) {
        this.videoTimer.display = this.videoTimer?.transform(0);
      }

      this.backfromTrim = true;
      this.paused = false;
      this.videoRecordingFinished = false;
      this.controls.disabledReset = true;
      this.controls.disabledStart = false;
      this.disabledReset = true;

      this.showCountdown = false;
      if (this.restart_Start) {
        if (this.webcamRecorderService.mediaRecorder.state != 'inactive') {
          this.recorder.stop();
        }
      }
      // if (this.webcamRecorderService.mediaRecorder.state != 'inactive') {
      //   this.recorder.stop();
      // }
      this.timeLeft = '3';
      if (!this.alreadyEnteredTrimmingStep) {
        this.startTestWebcam(false);
      }
      this.showAudio = true;
      this.showGreenRect = false;
      this.showMicEditBack = false;
      this.alreadyEnteredTrimmingStep = false;
    }
    // this.overrideDialog()
    //   .afterClosed()
    //   .subscribe((res) => {

    //   });
  };

  showProgressSpinnerUntilExecuted(observable: Observable<any>): void {
    // const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
    //   this.dialog.open(ProgressSpinnerDialogComponent, {
    //     panelClass: 'transparent',
    //     disableClose: true,
    //   });
    const subscription = observable.subscribe((response: HttpEvent<any>) => {

        switch (response.type) {
          // case HttpEventType.Sent:
          //   break;
          case HttpEventType.ResponseHeader:
            this.processFinish = true;
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(response.loaded / response.total * 100);
            break;
          case HttpEventType.Response:
            this.processFinish = true;
            setTimeout(() => {
              // this.responseVal = JSON.parse(response);
              this.router.navigate(['create-video/' + this.vidValue['id'] + '/edit']);
              subscription.unsubscribe();
              // dialogRef.close();
            }, 50);

        }

      },
      (error) => {
        subscription.unsubscribe();
        // handle error
        window.alert(this.translateService.instant('Verbindung unterbrochen'));
        if (window.confirm(this.translateService.instant("Would you like to download the video?"))) {
          this.downloadVideo();
        }
        this.videoProcessingComplete = true;
        // dialogRef.close();
      }
    );
  }

  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;

    if (this.width >= 2029) {
      this.rowval = 6;
    } else if (this.width >= 1850) {
      this.rowval = 8;
    } else if (this.width <= 1420) {
      this.rowval = 7;
    } else {
      this.rowval = 8;
    }
  }

  openAudioEnable() {
    for(let i = 0; i < this.videoInputValue.length; i++){
          if(this.videoInputValue[i].label === this.webcamRecorderService.currentVideoDevice){
            this.defaultVideoValue = this.videoInputValue[i];
          };
    }
    this.showAudioOption = !this.showAudioOption;
  }

  onAudioDropChange(event) {
    let DeviceId = event.value.deviceId;
    this.stopAudioRecording();
    this.audioRecorderService.getSelectionVal(DeviceId);
    this.webcamRecorderService.getSelectionVal(DeviceId);
    this.startAudioRecording();

  }

  onVideoDropChange(event) {
    this.stopTestWebcam();
    let DeviceId = event.value.deviceId;
    this.webcamRecorderService.getVideoSelectionVal(DeviceId);
    this.videovalue = event.value;
    if(this.alreadyEnteredTrimmingStep){
      this.startTestWebcam(true);
    }else{
    this.startTestWebcam(false);
    }
  }

  async startAudioRecording(): Promise<void> {
    this.arecorder = await this.audioRecorderService.recordAudio();
    const mediaStream = this.arecorder.start();
    const audioContext = new AudioContext();
    const mediaStreamSource = audioContext.createMediaStreamSource(mediaStream);
    this.audioMeter = audioMeter.createAudioMeter(audioContext);
    mediaStreamSource.connect(this.audioMeter);
    this.onLevelChange();
  }

  async stopAudioRecording(): Promise<void> {
    if (this.arecorder) {
      await this.arecorder.stop();
      this.arecorder = null;
      this.meter.nativeElement
        .getContext('2d')
        .clearRect(0, 0, this.WIDTH, this.HEIGHT);
      this.audioMeter.shutdown();
    }
  }

  onLevelChange = (time = 0) => {
    if (this.audioRecorderService.recording()) {
      const context = this.meter.nativeElement.getContext('2d');
      context.clearRect(0, 0, this.WIDTH, this.HEIGHT);
      if (this.audioMeter.checkClipping()) {
        // context.fillStyle = 'red';
      } else {
        context.fillStyle = localStorage.getItem("primary_color");
      }
      context.fillRect(
        0,
        0,
        this.audioMeter.volume * this.WIDTH * 1.4,
        this.HEIGHT
      );
      window.requestAnimationFrame(this.onLevelChange);
    }
  };

  moveToReview() {
    window.moveTo(0, 0);
    window.resizeTo(screen.width, screen.height);
    this.controls.disabledStart = true;
    this.stopTestWebcam();
    if (this.vidValue['video_file'] === undefined || this.vidValue['video_file'] === null) {
      this.stopWebcamRecording();
    } else if (this.vidValue['video_file'] != undefined && this.backfromTrim) {
      this.stopWebcamRecording();
    } else {
      this.stopAudioRecording();
      this.router.navigate(['create-video/' + this.vidValue['id'] + '/edit']);
    }
  }

  downloadVideo() {
    const blob = new Blob([this.webcamVideoBlob.videoBlob], {type: 'video/webm'});
    // const blob = this.formData['video_file'];
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'video.webm';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100);
  }

  private async initRecording(): Promise<void> {
    this.videoProcessingComplete = false;
    this.videoRecordingFinished = false;
    await this.videoTimer.startTimer();
  }

  goToHome(){
    this.ngOnDestroy();
    this.router.navigate(['start']);
  }

  async goBackRemovePermission() {
    this.stopTestWebcam();
    this.stopAudioRecording();
    await this.recorder.stop();
    // we can not call stopWebcamRecording as it takes us to edit page
  }
}
