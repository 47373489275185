<span style="display: inline">
  <h1 mat-dialog-title style="font-family: 'poppins-medium', sans-serif; display: inline-block">
    {{ data.user.first_name }}&nbsp;{{ data.user.last_name }}
    <span class="danger-color" *ngIf="!data.user.is_active">
      {{"(blocked)" | translate}}
    </span>
  </h1>

  <mat-icon (click)="closePopup()" style="font-size: 25px; position: relative; cursor: pointer; float: right">
    close
  </mat-icon>
</span>

<mat-dialog-content style="max-height: none;">
  <form [formGroup]="userDetailForm" class="user-detail-form">
    <div class="row mb-3">
      <div class="d-flex flex-column align-items-start col-lg-8 col-md-8 col-sm-8 col-8">
        <h2 translate>Profil</h2>
        <mat-form-field appearance="outline" dense hideRequiredMarker>
          <mat-label translate>Email-Adresse</mat-label>
          <input #password [type]="'email'" autocomplete="none" formControlName="email" matInput/>
        </mat-form-field>
      </div>
      <div class="d-flex justify-content-center col-lg-4 col-md-4 col-sm-4 col-4">
        <span class="avatar-box justify-content-sm-center justify-content-lg-end">
          <img [src]="userDetailForm.get('profile_pic').value" alt="profile" class="profile-pic-preview"/>
        </span>
      </div>
    </div>
    <div class="row formField">

      <div class="col-sm-6">
        <mat-form-field appearance="outline" dense hideRequiredMarker>
          <mat-label translate>Vorname</mat-label>
          <input #password [type]="'text'" autocomplete="none" formControlName="first_name" matInput/>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field appearance="outline" dense hideRequiredMarker>
          <mat-label translate>Nachname</mat-label>
          <input #password [type]="'text'" autocomplete="none" formControlName="last_name" matInput/>
        </mat-form-field>
      </div>

      <div class="col-sm-6 ">
        <mat-form-field appearance="outline" dense hideRequiredMarker>
          <mat-label>{{ authService.company?.dept_alias }}</mat-label>
          <mat-select (selectionChange)="departChange()" formControlName="department">
            <mat-option class="auto-width" *ngFor="let dpt of departList" [value]="dpt.id">{{ dpt.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field appearance="outline" dense hideRequiredMarker>
          <mat-label>{{ authService.company?.team_alias }}</mat-label>
          <mat-select formControlName="team">
            <mat-option class="auto-width" *ngFor="let team of teamsList" [value]="team.id">{{ team.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-6">
        <mat-form-field appearance="outline" dense hideRequiredMarker>
          <mat-label translate>Standort</mat-label>
          <mat-select formControlName="location">
            <mat-option class="auto-width" *ngFor="let item of locationList " [value]="item.id">{{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-6">
        <mat-form-field appearance="outline" dense hideRequiredMarker>
          <mat-label translate>Position</mat-label>
          <mat-select formControlName="user_role">
            <mat-option class="auto-width" *ngFor="let item of rolesList " [value]="item.id">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-6" style="display: flex;" hidden>
        <mat-form-field appearance="outline" dense hideRequiredMarker class="w-100">
          <mat-label translate>IP Network</mat-label>
          <mat-icon class="material-icons-outlined" matSuffix
                    style="display: flex;height: 100%;align-items: center;padding-left: 5px;"
                    matTooltip="{{'Leave empty for no IP address related restrictions' | translate}}" matTooltipPosition="right">
            info
          </mat-icon>
          <input #ip type="text" autocomplete="none" placeholder="192.168.1.0/24" formControlName="ip_network" matInput
            maxlength="50" />
        </mat-form-field>
      </div>

    </div>

    <div class="row"></div>
    <h2 translate>Rechte</h2>
    <div class="row rightsSection">
      <div class="col-lg-4 col-md-4 col-sm-6">
        <mat-checkbox class="checkval"
                      matTooltipPosition="right"
                      color="primary" formControlName="is_approved"
                      matTooltip="{{'Bestätigt, dass ein Nutzer Teil der im Profil angegebenen Abteilung und des Teams ist'| translate}}">
          {{ "Autorisiert" | translate }}
        </mat-checkbox>
        <br>
        <mat-checkbox class="checkval"
                      matTooltipPosition="right"
                      color="primary" formControlName="is_creator"
                      matTooltip="{{ 'To create your own content, you need Creator rights.' | translate }}">
          Creator
        </mat-checkbox>
      </div>

      <div class="row d-flex flex-wrap justify-content-between col-lg-4 col-md-4 col-sm-6">
        <div class="col-sm-12">
          <mat-checkbox (change)="changeCM($event)" class="splitcheck checkval" color="primary"
                        formControlName="is_company_manager"
                        matTooltipPosition="left"
                        matTooltip="{{'Nutzer kann Personen in seiner/ihrer Organisation verwalten'| translate}}">
            {{ "Organisationsverwaltung" | translate }}
          </mat-checkbox>
        </div>

        <div class="col-sm-12">
          <mat-checkbox (change)="changeDM($event)" class="splitcheck checkval" color="primary"
                        formControlName="is_department_manager"
                        matTooltipPosition="left"
                        matTooltip="{{'Nutzer kann Personen in seiner/ihrer Abteilung verwalten'| translate}}">
            {{ deptManagementText }}
          </mat-checkbox>
        </div>

        <div class="col-sm-12">
          <mat-checkbox class="splitcheck checkval" color="primary" formControlName="is_team_manager"
                        matTooltipPosition="left"
                        matTooltip="{{'Nutzer kann Personen in seinem/ihrem Team verwalten' | translate}}">
            {{ teamsManagementText }}
          </mat-checkbox>
        </div>

      </div>

      <div class="col-lg-4 col-md-4 col-sm-6">
        <mat-checkbox (change)="changeGM($event)" class="checkval" color="primary"
                      formControlName="is_global_manager"
                      matTooltipPosition="left"
                      matTooltip="{{'Nutzer hat organisationsweite Einstellungs- & Verwaltungsrechte'| translate}}">
          {{ "Globale Administration" | translate }}
        </mat-checkbox>
        <br>
        <mat-checkbox class="checkval" color="primary"
                      formControlName="is_quality_manager"
                      matTooltipPosition="left"
                      matTooltip="{{'Nutzer kann Videos anderer Personen freigeben und blockieren'| translate}}">
          {{ "Videoverwaltung" | translate }}
        </mat-checkbox>
      </div>
    </div>
  </form>
</mat-dialog-content>

<footer>
  <mat-toolbar style="
      background-color: white;
      width: 100%;
      height: 2.5rem;
      padding-bottom: 5px;
    ">
    <!-- <div class="row" style="width: 100%;height: 2.5rem;padding-bottom: 5px;"> -->
    <!--hide delete and block option for myself-->
    <div class="w-100" style="display: flex; justify-content: flex-start" [hidden]="data.id == authService.userDetails.id">
      <button (click)="deleteUser()" class="blocked" mat-stroked-button
              style="font-family: nunito; margin-right: 0.5rem">
        {{ "Löschen" | translate }}
      </button>

      <button (click)="blockUnblockUser()" [ngClass]="{ blocked: data.user.is_active }" mat-stroked-button
              style="font-family: nunito; margin-right: 0.5rem">
        {{(data.user.is_active ? "Block" : "Unblock") | translate}}
      </button>
    </div>
    <div style="display: flex; justify-content: flex-end">
      <button (click)="manageContent()" mat-stroked-button
        style="font-family: nunito; line-height: 34px;margin-right: 0.5rem;">
        <span translate>Manage content</span>
      </button>
      <button (click)="assertPopup()" [disabled]="!userDetailForm.valid" mat-stroked-button
        style="font-family: nunito; line-height: 34px">
        <span translate>Speichern</span>
      </button>
    </div>
  </mat-toolbar>
</footer>
