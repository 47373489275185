<div (window:resize)="onResize()" class="mt-4 mx-4" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center"
     fxLayoutGap="1%" fxLayoutWrap style="margin-top: 1.2rem !important;padding-bottom: 40px;overflow-x:hidden">
  <div [hidden]="selectRecordAudio" fxFlex="50%" fxFlexOrder.xs="2">
    <mat-card
      style="width: 100%; color: white; background-color:#F5F5F5;border-radius: 8px;min-height: 100%;height: 95%;">

      <div class="w-100" fxLayout="row" style="background-color:#D4D4D4;height: 80px;">
        <button (click)="tabClicked('edit_tab')" [ngClass]="edit_tab ? 'whiteBackground' : 'greyBackground' "
                mat-flat-button
                style="width:142px;display:flex;align-items:center; position: relative;flex-direction: column;">
          <mat-icon style="font-size:40px;top:5px; position: relative;width: 40px;height: 40px;">video_settings
          </mat-icon>
          <span class="fontStyle" style="display:flex;width: 100%;height:50%;justify-content:center;font-size: 14px;"
                translate>Bearbeiten</span>
        </button>
<!--        <button (click)="tabClicked('thumbnail_tab')" [ngClass]="thumbnail_tab ? 'whiteBackground' : 'greyBackground' "-->
<!--                class="d-none d-sm-block" mat-flat-button-->
<!--                style="width:142px;display:flex;align-items:center;position: relative;flex-direction: column;">-->
<!--          <mat-icon style="font-size:40px;top:5px;position: relative;width: 40px;height: 40px;">photo_library</mat-icon>-->
<!--          <span class="fontStyle" style="display:flex;height:50%;justify-content:center;font-size: 14px;" translate>Vorschaubilder</span>-->
<!--        </button>-->
        <button (click)="tabClicked('textOverlay_tab')"
                [ngClass]="textOverlay_tab ? 'whiteBackground' : 'greyBackground' " class="d-none d-sm-block"
                mat-flat-button
                style="width:142px;display:flex;align-items:center;position: relative;flex-direction: column;">
          <mat-icon style="font-size:40px;top:5px;position: relative;width: 40px;height: 40px;">text_fields</mat-icon>
          <span class="fontStyle" style="display:flex;height:50%;justify-content:center;font-size: 14px;" translate>Overlay</span>
        </button>
        <button (click)="tabClicked('blur_tab')" [ngClass]="blur_tab ? 'whiteBackground' : 'greyBackground' "
                class="d-none d-sm-block" mat-flat-button
                style="width:142px;display:flex;align-items:center;position: relative;flex-direction: column;">
          <mat-icon style="font-size:40px;top:5px;position: relative;width: 40px;height: 40px;">blur_on</mat-icon>
          <span class="fontStyle" style="display:flex;height:50%;justify-content:center;font-size: 14px;"
                translate>Blur</span>
        </button>
      </div>

      <!-- first tab starts -->
      <span *ngIf="edit_tab">

        <!-- audio div -->
        <div class="mt-2">
          <h2 class="fontStyle" translate>
            Sound
          </h2>

          <div class="w-100" fxLayout="row" style="color:white;background-color: #F5F5F5;margin-bottom:0.5rem">
            <button (click)="audioToggle()" [disabled]="permissionDenied"
                    [ngClass]="icon=='volume_off' ? 'vidMuted' : ''" class="muteButton" mat-raised-button>
              <mat-icon>
                {{icon}}
              </mat-icon>
            </button>
            <span class="fontNormal" style="padding: 5px 5px 15px 10px; color:black; font-size: 18px;">
              {{iconText | translate}}
            </span>
          </div>

          <div *ngIf="icon=='volume_up'">
            <h2 class="fontStyle" translate>
            Noise Suppression
            </h2>
            <div class="w-100" fxLayout="row" style="color:white;background-color: #F5F5F5;margin-bottom:0.5rem">
              <button (click)="noiseToggle()" [ngClass]="noiseIcon=='noise_aware' ? 'noiseSuppressed' : ''"
                      class="muteButton" mat-raised-button>
                <mat-icon>
                  {{noiseIcon}}
                </mat-icon>
              </button>
              <span class="fontNormal" style="padding:5px 5px 15px 10px; color:black; font-size: 18px;">
                {{noiseIconText | translate}}
              </span>
            </div>
          </div>
        </div>

        <!--  Webcam position and webcam background div  -->
        <div>
          <h2 class="fontStyle" translate *ngIf="videoType == 'BO'">
            Webcam Ausrichtung
          </h2>

          <mat-grid-list #grid cols="3" gutterSize="50px" rowHeight="190px">
            <div *ngIf="videoType == 'BO'" class="w-100" fxLayout="row"
                 style="margin-bottom:1rem;padding:0px 10px 0px 10px;justify-content: space-evenly;">
                  <div class="w-100" fxFlex="20%" style="margin: 5px;">
                      <button (click)="webcamPosition('bl')" [ngClass]="webcamObj.bl ? 'selectedbuttonBorder' : ''"
                              mat-raised-button matTooltip="{{'Links unten'|translate}}"
                              style="background-color: white;width:100%;height: 120px;">
                          <svg height="100" viewBox="0 0 1500 1080" width="100">
                              <defs>
                                  <style>
                                      .cls-1 {
                                        fill: #fff;
                                      }
                                  </style>
                              </defs>
                              <g data-name="Ebene 2" id="Ebene_2">
                                  <g data-name="Ebene 1" id="Ebene_1-2">
                                      <rect class="cls-1" height="1080" rx="5" width="1500"/>
                                      <rect height="360.7" rx="20" width="500.5" x="39.05" y="688.4"/>
                                  </g>
                              </g>
                          </svg>
                      </button>
                      <p class="fontNormal"
                         style="color:black;padding-top: 0.5rem; font-size: 18px;position: relative;text-align: center;"
                         translate>
                          Links unten</p>
                  </div>
                  <div class="w-100" fxFlex="20%" style="margin: 5px;">
                      <button (click)="webcamPosition('tr')" [ngClass]="webcamObj.tr ? 'selectedbuttonBorder' : ''"
                              mat-raised-button matTooltip="{{'Rechts oben'|translate}}"
                              style="background-color: white;width:100%;height: 120px;">
                          <svg height="100" viewBox="0 0 1500 1080" width="100">
                              <defs>
                                  <style>
                                      .cls-1 {
                                        fill: #fff;
                                      }
                                  </style>
                              </defs>
                              <g data-name="Ebene 2" id="Ebene_2">
                                  <g data-name="Ebene 1" id="Ebene_1-2">
                                      <rect class="cls-1" height="1080" rx="5"
                                            transform="translate(1350 1080) rotate(180)" width="1500"/>
                                      <rect height="360.7" rx="20" width="500.5" x="900.45" y="30.9"/>
                                  </g>
                              </g>
                          </svg>
                        <!-- <svg width="100" height="100" viewBox="0 0 1500 1080"><defs><style>.cls-1{fill:#fff;}</style></defs><g id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect class="cls-1" width="1500" height="1080" rx="5"/><rect x="39.05" y="688.4" width="500.5" height="360.7" rx="20"/></g></g></svg> -->
                      </button>
                      <p class="fontNormal"
                         style="color:black;padding-top: 0.5rem; font-size: 18px;position: relative;text-align: center;"
                         translate>Rechts oben</p>
                  </div>
                  <div class="w-100" fxFlex="20%" style="margin: 5px;">
                      <button (click)="webcamPosition('br')" [ngClass]="webcamObj.br ? 'selectedbuttonBorder' : ''"
                              mat-raised-button matTooltip="{{'Rechts unten'|translate}}"
                              style="background-color: white;width:100%;height:120px;">
                          <svg height="100" viewBox="0 0 1500 1080" width="100">
                              <defs>
                                  <style>
                                      .cls-1 {
                                        fill: #fff;
                                      }
                                  </style>
                              </defs>
                              <g data-name="Ebene 2" id="Ebene_2">
                                  <g data-name="Ebene 1" id="Ebene_1-2">
                                      <rect class="cls-1" height="1080" rx="5"
                                            transform="translate(1500 1080) rotate(180)" width="1500"/>
                                      <rect height="360.7" rx="20" width="500.5" x="960.45" y="688.4"/>
                                  </g>
                              </g>
                          </svg>
                      </button>
                      <p class="fontNormal"
                         style="color:black;padding-top: 0.5rem; font-size: 18px;position: relative;text-align: center;"
                         translate>
                          Rechts unten</p>
                  </div>
                  <div class="w-100" fxFlex="20%" style="margin: 5px;">
                      <button (click)="webcamPosition('ai-left')"
                              [ngClass]="webcamObj['ai-left'] ? 'selectedbuttonBorder' : ''" mat-raised-button
                              matTooltip="{{'Links unten ohne Hintergrund'|translate}}"
                              style="background-color: white;width:100%;height: 120px;">
                          <svg height="100" viewBox="0 0 1500 1080" width="100">
                              <defs>
                                  <style>
                                      .cls-1 {
                                        fill: #fff;
                                      }
                                  </style>
                              </defs>
                              <g data-name="Ebene 2" id="Ebene_2">
                                  <g data-name="Ebene 1" id="Ebene_1-2">
                                      <rect class="cls-1" height="1080" rx="5" width="1500"/>
                                      <path
                                        d="M317.76,899.69a76,76,0,1,0-76-76A76,76,0,0,0,317.76,899.69Zm0,38c-50.73,0-152,25.46-152,76v38h304v-38C469.76,963.15,368.49,937.69,317.76,937.69Z"/>
                                  </g>
                              </g>
                          </svg>
                      </button>
                      <p class="fontNormal"
                         style="color:black;padding-top: 0.5rem; font-size: 18px;position: relative;text-align: center;"
                         translate>
                          Cut-out left</p>
                  </div>
                  <div class="w-100" fxFlex="20%" style="margin: 5px;">
                      <button (click)="webcamPosition('ai-right')"
                              [ngClass]="webcamObj['ai-right'] ? 'selectedbuttonBorder' : ''" mat-raised-button
                              matTooltip="{{'Rechts unten ohne Hintergrund'|translate}}"
                              style="background-color: white;width:100%;height:120px;">
                          <svg height="100" viewBox="0 0 1500 1080" width="100">
                              <defs>
                                  <style>
                                      .cls-1 {
                                        fill: #fff;
                                      }
                                  </style>
                              </defs>
                              <g data-name="Ebene 2" id="Ebene_2">
                                  <g data-name="Ebene 1" id="Ebene_1-2">
                                      <rect class="cls-1" height="1080" rx="5"
                                            transform="translate(1500 1080) rotate(180)" width="1500"/>
                                      <path
                                        d="M1182.24,899.69a76,76,0,1,1,76-76A76,76,0,0,1,1182.24,899.69Zm0,38c50.73,0,152,25.46,152,76v38h-304v-38C1030.24,963.15,1131.51,937.69,1182.24,937.69Z"/>
                                  </g>
                              </g>
                          </svg>
                      </button>
                      <p class="fontNormal"
                         style="color:black;padding-top: 0.5rem; font-size: 18px;position: relative;text-align: center;"
                         translate>
                          Cut-out right</p>
                  </div>
            </div>
          </mat-grid-list>
        </div>
      </span>
      <!-- first tab ends -->
      <!-- second tab starts -->


      <span *ngIf="textOverlay_tab">
        <div class="overlay_Scroll">
          <span>
            <div class="fontStyle d-flex" style="color:black;font-size: 24px;line-height: 55px;">
              <span translate class="mr-2">Text Overlay</span>
              <button (click)="accordion.closeAll(); setTextOverlay();" mat-stroked-button
                      style="height: 36px; margin-top: 10px;">
                <mat-icon>add</mat-icon>
                {{'Add'|translate}}
              </button>
            </div>
            <!-- <div class="example-action-buttons" *ngIf="text_overlay_Array.length > 0">
              <button (click)="accordion.openAll()" mat-button>
                {{'Expand All'|translate}}
              </button>
              <button (click)="accordion.closeAll()" mat-button>
                {{'Collapse All'|translate}}
              </button>
            </div> -->
            <mat-accordion class="ml-2">
              <mat-expansion-panel #textExpansionPanel *ngFor="let item of text_overlay_Array; let i = index;" [expanded]="i === activeTextOverlay"
              (afterExpand)="textexpansionselected(item)">
                <mat-expansion-panel-header style="height: 65px;">
                  <mat-panel-title style="overflow: hidden;">
                    {{item['title']}}
                  </mat-panel-title>
                  <mat-panel-description>
                    <mat-icon class="material-icons-outlined" (click)="deleteExpansionPanel(i, item['id'], item['title'])"
                              matTooltip="{{'Löschen'|translate}}">
                      delete
                    </mat-icon>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <form  [formGroup]="text_overlay_formObj[item.id]" style="color: black;">
                  <div class="row" rowHeight="66px">
                    <div class="col-md-6 col-sm-12">
                      <mat-checkbox (change)="overlayBoxSelected(item)" checked=true class="acptCheckbox fontNormal"
                                    color="primary" formControlName="overlayBox"
                                    style="position: relative;top: 14px;left: 5px;">
                        {{'Colored Background'|translate}}
                      </mat-checkbox>
                    </div>
                    <div *ngIf="text_overlay_formObj[item.id].get('overlayBox').value" class="col-md-3 col-sm-12"
                         style="margin-bottom: 1rem;">
                      <mat-form-field style="width: 95%;">
                        <mat-label style="color:rgba(0, 0, 0, 0.87)" translate>Box Color</mat-label>
                        <input (change)="overlayTextChanged(item)" type="color"
                               formControlName="boxColor" id="boxColor" matInput style="color:rgba(0, 0, 0, 0.87)">
                        <mat-error class="fontNormal" style="margin-top: 1rem;font-size: 12px;" translate>
                            Invalid color
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <mat-form-field appearance="outline"
                                      class="profile-text-input fontNormal profile-field-group-mid-item2"
                                      style="width:100%">

                        <mat-label translate>Text</mat-label>
                        <textarea (input)="overlayTextChanged(item)" appearance="fill" class="fontNormal"
                                  formControlName="overlayText" matInput placeholder="Enter text here" rows="6"
                                  style="resize:none !important">
                                          </textarea>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="row">
                        <div class="col-md-6 col-sm-12">
                          <mat-form-field appearance="outline"
                                          class="checkButton profile-text-input profile-field-group-mid-item1"
                                          style="width:100%">
                            <mat-label translate>Font Size</mat-label>
                            <mat-select (selectionChange)="changeOlTxtDrpDwnSelection(item)"
                                        formControlName="overlayFontSize"
                                        style="font-size: 16px;padding-top: 5px;height: 33px;">
                              <mat-option [value]="'small'">Small</mat-option>
                              <mat-option [value]="'medium'">Medium</mat-option>
                              <mat-option [value]="'large'">Large</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <mat-form-field style="width: 95%;top: 20px;">
                            <mat-label style="color:rgba(0, 0, 0, 0.87)" translate>Font Color</mat-label>
                            <input (change)="overlayTextChanged(item)" type="color"
                                   formControlName="fontColor" id="fontColor" matInput
                                   style="color:rgba(0, 0, 0, 0.87)">
                            <mat-error class="fontNormal" style="margin-top: 1rem;font-size: 12px;" translate>
                              Invalid color
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row" style="margin-top: 1.5rem;">
                        <div class="col-md-6 col-sm-12">
                          <mat-form-field appearance="outline" style="width:100%">
                            <mat-label translate>Start Time</mat-label>
                            <input (change)="onStartTimeChange($event.target.value, item.id, 'startT')"
                                   [max]="player.duration" class="fontNormal" formControlName="textOverStartTime" autocomplete="off"
                                   matInput min="0" style="font-size: 16px;padding-top: 5px;" type="number" step="0.1"/>
                          </mat-form-field>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <mat-form-field appearance="outline" style="width:100%">
                            <mat-label translate>End Time</mat-label>
                            <input  (change)="onStartTimeChange($event.target.value, item.id, 'endT')"
                                   [max]="player.duration"
                                   [min]="text_overlay_formObj[item.id].get('textOverStartTime').value"
                                   class="fontNormal" formControlName="textOverEndTime" matInput autocomplete="off"
                                   placeholder="Enter text here" style="font-size: 16px;padding-top: 5px;"
                                   type="number" step="0.1" />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>

                </form>
              </mat-expansion-panel>
            </mat-accordion>
          </span>
        </div>
      </span>
      <!-- second tab ends -->

      <!-- third tab starts -->
      <span *ngIf="blur_tab">
        <div class="overlay_Scroll">
          <!--shape Overlay-->
          <div class="fontStyle d-flex" style="color:black;font-size: 24px;line-height: 55px;">
            <span translate class="mr-2">Blur section</span>
            <button (click)="shapeAccordion.closeAll(); addShapeExpansionPanel();" mat-stroked-button
                    style="height: 36px; margin-top: 10px;">
              <mat-icon>add</mat-icon>
              {{'Add'|translate}}
            </button>
          </div>

          <mat-accordion #shapeAccordion="matAccordion" class="ml-2">
            <mat-expansion-panel  *ngFor="let item of shape_overlay_Array; let i = index;" [expanded]="i === activeIndex" (afterExpand)="expansionselected(i,item)" (closed)="closeExpansion(i,item)">
              <mat-expansion-panel-header style="height: 65px;">
                <mat-panel-title style="overflow: hidden;">
                  <span translate>Section</span>&nbsp;{{i + 1}}
                </mat-panel-title>
                <mat-panel-description>
                  <mat-icon class="material-icons-outlined" (click)="deleteShapeExpansionPanel(i)" matTooltip="{{'Löschen'|translate}}">
                    delete
                  </mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <form [formGroup]="shape_overlay_formObj[i]" style="color: black;">
                <div class="row" hidden>
                  <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" style="width:100%">
                      <mat-label translate>Width</mat-label>
                      <input (change)="shapeResizeChanged(i, 'shapeWidth')" [(ngModel)]="item.shapeWidth"
                             [max]="videoClientWidth" class="fontNormal" formControlName="shapeWidth" matInput
                             min="10" required type="number"/>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" style="width:100%">
                      <mat-label translate>Height</mat-label>
                      <input (change)="shapeResizeChanged(i, 'shapeHeight')" [(ngModel)]="item.shapeHeight"
                             [max]="videoClientHeight" class="fontNormal" formControlName="shapeHeight" matInput
                             min="10" required type="number"/>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row" hidden>
                  <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" style="width:100%">
                      <mat-label translate>X</mat-label>
                      <input (change)="shapeResizeChanged(i, 'overlayXPosition')" [(ngModel)]="item.overlayXPosition"
                             [max]="videoClientWidth" class="fontNormal" formControlName="overlayXPosition" matInput
                             min="0" required type="number"/>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" style="width:100%">
                      <mat-label translate>Y</mat-label>
                      <input (change)="shapeResizeChanged(i, 'overlayYPosition')" [(ngModel)]="item.overlayYPosition"
                             [max]="videoClientHeight" class="fontNormal" formControlName="overlayYPosition" matInput
                             min="0" required type="number"/>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" style="width:100%">
                      <mat-label translate>Start Time</mat-label>
                      <input (change)="onShapeTimeChange($event.target.value, i, 'shapeOverStartTime')"
                             [max]="player.duration" class="fontNormal" autocomplete="off"
                             formControlName="shapeOverStartTime" matInput min="0" required type="number" step="0.1"/>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" style="width:100%" >
                      <mat-label translate>End Time</mat-label>
                      <input (change)="onShapeTimeChange($event.target.value, i, 'shapeOverEndTime')"
                             [max]="player.duration" autocomplete="off"
                             [min]="shape_overlay_formObj[i].get('shapeOverStartTime').value" class="fontNormal"
                             formControlName="shapeOverEndTime" matInput required type="number" step="0.1" />
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </span>
      <!--third tab ends -->
    </mat-card>
  </div>
  <div *ngIf="selectRecordAudio" fxFlex="50%" fxFlexOrder.xs="2"
       style="height: inherit !important;margin-bottom: 1.5rem !important">
    <app-notes (notesChange)="notesChange($event)" [notes]="notes" [rows]="'18'"></app-notes>
  </div>
  <div fxFlex="50%" fxFlexOrder.xs="1">
    <mat-card
      style="width: 100%; border-radius: 8px;color: black; background-color: white;min-height: 100%;margin-top: -1.3rem;margin-bottom: 1.5rem;">
      <mat-card-content>
        <div class="row ">
          <div class="jumbotron text-center w-100 mb-0 video-trim-window" style="padding: .5rem 1rem;">
            <!--                        single video case height: 25.5vw; -->
            <!--                      *ngIf="videoBOTHURL == undefined || videoBOTHURL == null"-->
            <div #videoDynamic [ngStyle]="videoCont" style="margin: 0 auto;">
              <div [ngStyle]="videoContainerHeight" class="video-view-container"
                   style="display: flex;height: 25.5vw;justify-content:left;align-content: center;background-color: whitesmoke;">
                <video #videoPlayer (loadeddata)="dataLoaded($event)" (loadedmetadata)="onPlayerReady($event)"
                       (timeupdate)="timeUpdated()" [ngStyle]="videoPlayerClass" [src]="videoURL"
                       [vgMedia]="videoPlayer" class="video-view" crossorigin id='videoTrim' type="video/mp4"></video>

                <div *ngIf="showCountdown" class="spinner_overlay"></div>
                <div *ngIf="showCountdown" class="countdownval"
                     style="height: 100%;display: flex;justify-content: center;align-items: center;">{{timeLeft}}</div>

                <div #dragDiv></div>
                <div #resizeDiv></div>
                <div class="text_overlay_Div" id="canvasDiv" style="display: none;"></div>
                <!-- <div style="width: 100%;height: 100%;position: absolute;bottom: 0px;"> -->
                  <div *ngIf="videoBOTHURL != undefined || videoBOTHURL != null" [ngClass]="{
                                                 'top-right-overlay':overlay_code === 'tr',
                                                 'bottom-left-overlayAI':overlay_code === 'ai-left',
                                                 'bottom-left-overlay':overlay_code === 'bl',
                                                 'bottom-right-overlay':overlay_code === 'br',
                                                 'bottom-right-overlayAI':overlay_code === 'ai-right'
                                             }" class="videoOverlayContainer"
                       style="z-index: 999;position: relative;display: flex;justify-content: center;align-content: center;align-items: center;">
                    <video #videoPlayerBOTH [src]="videoBOTHURL" [vgMedia]="videoPlayerBOTH" class="video-view"
                           crossorigin id='videoTrimBoth' style="border-radius: 6%;"></video>

                  </div>
                <!-- </div> -->
              </div>
            </div>


            <!--                          (play)="playedVideo()"-->
            <audio #audioPlayer [autoplay]="false" [loop]="false" [src]="audioURL" crossorigin
                   id="audioOverlay"></audio>

            <!-- both video case -->
            <div class="d-flex" style="position: relative; margin-top: 1vw;">
                            <span #currentTime class="fontNormal1"
                                  style="display:flex;justify-content: flex-start;width:80%">
                            </span>

              <!-- audio record button -->
              <div style="width: 20%;position: relative;display:flex;justify-content: flex-end;">
                <mat-icon (click)="selectRecordAudioOpt()" *ngIf="!selectRecordAudio && !this.audio_end_time"
                          matTooltip="{{'Audio überschreiben' | translate}}"
                          style="display: flex;justify-content: center;font-size: 27px;width: 20px;height: 20px;color: black; cursor: pointer;">
                  mic_none
                </mat-icon>
                <mat-icon (click)="selectRecordAudioOpt()" *ngIf="selectRecordAudio && !this.audio_end_time"
                          matTooltip="{{'Cancel' | translate}}"
                          style="display: flex;justify-content: center;font-size: 27px;width: 20px;height: 20px;color: black; cursor: pointer;">
                  mic
                </mat-icon>
                <mat-icon (click)="resetAudio()" *ngIf="this.audio_end_time>0"
                          matTooltip="{{'Überschriebenes Audio löschen' | translate}}"
                          style="display: flex;justify-content: center;font-size: 27px;width: 20px;height: 20px;color: black; cursor: pointer;">
                  delete_forever
                </mat-icon>
              </div>
            </div>
            <div class="mt-3"
                 style="width: 100%;position: relative;display: flex;justify-content: center; height: 45px;">
              <!-- audio overlay control -->
              <div *ngIf="selectRecordAudio" class="d-flex justify-content-around">
                <div>
                  <!-- start audio recording -->
                  <button (click)="startAudioRecording()" *ngIf="!audioRecorderService.recording()"
                          [disabled]="showCountdown" class="bigButton" mat-fab style="position:relative;top:8px;">
                    <mat-icon class="startIcon" matTooltip="{{'Audioaufnahme starten' | translate}}"
                              style="display: flex;cursor: pointer;">circle
                    </mat-icon>
                  </button>
                  <!-- stop button -->
                  <button (click)="stopAudioRecording()" *ngIf="audioRecorderService.recording()" class="bigButton"
                          mat-fab style="position:relative;top:8px;">
                    <mat-icon class="stopIcon" matTooltip="{{'Audioaufnahme beenden' | translate}}"
                              style="display: flex;cursor: pointer;">stop_circle
                    </mat-icon>
                    <svg fill="#FFFFFF" height="18"
                         style=" display: flex; position: relative; bottom: 30px; left: 11px; " viewBox="0 0 35 35"
                         width="18">
                      <rect fill="white" height="35" width="35"/>
                    </svg>
                  </button>
                </div>

                <div class="rectOption">
                  <div class="row" style="width: 200px !important;display: flex;">
                    <div class="col-12 fontNormal">
                                            <span class="fontNormal">
                                                <mat-form-field appearance="outline"
                                                                style="position: relative;bottom: .8rem;">
                                                    <mat-select (selectionChange)="onAudioDropChange($event)"
                                                                [(ngModel)]="defaultValue" [disabled]="disableSeeker"
                                                                placeholder="Audio Input">
                                                        <mat-option *ngFor="let audio of audioinputVal" [value]="audio">
                                                            {{audio.label}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </span>
                    </div>
                  </div>
                  <div class="row" style="width: 200px !important;position: relative;bottom: 2rem;left:1.2rem">
                    <div class="col-12" style="height: 20px;">
                      <canvas #meter *ngIf="!disableAudio" [height]="HEIGHT" [width]="WIDTH"></canvas>
                    </div>
                  </div>
                </div>
              </div>

              <!-- video player control -->
              <div *ngIf="!selectRecordAudio" class="d-flex justify-content-around">
                <div>
                  <button (click)="backwardVideo()" class="bg-white" mat-raised-button
                          style="border: 0px solid transparent;box-shadow:none !important">
                    <mat-icon>fast_rewind</mat-icon>
                  </button>
                </div>
                <div *ngIf="player?.paused">
                  <button (click)="playVideo()" class="bg-white" mat-raised-button
                          style="border: 0px solid transparent;box-shadow:none !important">
                    <mat-icon style="transform: scale(1.7);">
                      play_arrow
                    </mat-icon>
                  </button>
                </div>
                <div *ngIf="!player?.paused">
                  <button (click)="pauseVideo()" class="bg-white" mat-raised-button
                          style="border: 0px solid transparent;box-shadow:none !important">
                    <mat-icon style="transform: scale(1.7);">
                      pause
                    </mat-icon>
                  </button>
                </div>
                <div>
                  <button (click)="forwardVideo()" class="bg-white" mat-raised-button
                          style="border: 0px solid transparent;box-shadow:none !important">
                    <mat-icon>fast_forward</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <input (change)="onChangeSeekerTime(seekerValue)" (input)="onChangeSeekerTime(seekerValue)"
                   *ngIf="player?.duration" [(ngModel)]="seekerValue" [disabled]='disableSeeker'
                   [max]="(player.duration*10).toFixed(0)-1" class="player-seeker" min="0" step="0.1"
                   style="position: relative;width: 100%;cursor: pointer;z-index: 999;top: 2px;" type="range">
            <!-- <ng-waveform *ngIf="selectRecordAudio" #waveform class="waveform" [src]="videoURL" [height]="40"
                backgroundColor="#d3d3d3" regionBackgroundColor="rgba(255, 255, 255, 0.7)"
                regionStartStickColor="#21f032" regionEndStickColor="red" waveColor="green">
            </ng-waveform> -->

            <!--                        Showing the trimmer always-->
            <mat-grid-list *ngIf="player?.duration" [cols]="player.duration*10" class="w-100" rowHeight="25px"
              style="top:10px;position:relative;">
              <mat-grid-tile (click)="trimBarSelected(tile, i)" *ngFor="let tile of tiles; let i = index;"
                [colspan]="tile.cols" [rowspan]="tile.rows" [style.background]="tile.color" [style.border]="tile.bordercolor"
                class="trimSection" style="cursor: pointer; margin-left: 2px;margin-right: 2px;border-radius: 3px;">
                <span class="w-100"></span>
              </mat-grid-tile>
            </mat-grid-list>
            <div *ngIf="opened" (clickOutside)="clickOutside()"></div>
            <!--for replaced audio-->
            <mat-grid-list *ngIf="player?.duration && audio_end_time" [cols]="player.duration*10" class="w-100"
                           matTooltip="{{'Überschriebenes Audio' | translate}}" rowHeight="5px"
                           style="top:11px; position:relative;">
              <mat-grid-tile [colspan]="audio_start_time*10" rowspan="1"></mat-grid-tile>
              <mat-grid-tile [colspan]="(audio_end_time-audio_start_time)*10" rowspan="1"
                             style="background-color: red"></mat-grid-tile>
            </mat-grid-list>
            <span *ngIf="player?.duration" [ngClass]="{'disableDiv': selectRecordAudio}">
              <!--text overlay bar-->
              <mat-grid-list *ngFor="let obj of text_overlay_Array;let i = index;" (click)="textOverlayBar(i,obj)" [cols]="player.duration*10" class="w-100"
                             rowHeight="5px" style="top:11px; position:relative; padding-top: 6px;">
                <mat-grid-tile [colspan]="obj['textOverStartTime']*10" rowspan="1"></mat-grid-tile>
                <mat-grid-tile [colspan]="(obj['textOverEndTime']-obj['textOverStartTime'])*10"
                               [matTooltip]="obj['title']" rowspan="1" style="background-color: cornflowerblue">
                </mat-grid-tile>
              </mat-grid-list>
              <!--resize overlay bar-->
              <mat-grid-list *ngFor="let obj of shape_overlay_Array; let i = index;" (click)="resizeBar(i,obj)" [cols]="player.duration*10"
                             class="w-100" rowHeight="5px" style="top:11px; position:relative; padding-top: 6px;">
                <mat-grid-tile [colspan]="obj['shapeOverStartTime']*10" rowspan="1"></mat-grid-tile>
                <mat-grid-tile [colspan]="(obj['shapeOverEndTime']-obj['shapeOverStartTime'])*10"
                               [matTooltip]="'Blur '+(i+1)" rowspan="1" style="background-color: gray">
                </mat-grid-tile>
              </mat-grid-list>
            </span>

            <button (click)="splitVideo()" *ngIf='!selectRecordAudio' class="borderbutton" mat-flat-button
                    matTooltip="(c)" style="float:right;">
              <mat-icon>
                content_cut
              </mat-icon>
              <span class="fontNormal d-none d-md-inline"
                    style="display:inline-block;position:relative;left:5px;font-size: 14px;font-weight: bold;"
                    translate>Clip schneiden</span>
            </button>
            <button (click)="deleteSelectedSection()" *ngIf='showDeleteSelectedBtn && !selectRecordAudio'
                    class="borderbutton" mat-flat-button style="float:right;">
              <mat-icon>
                delete
              </mat-icon>
              <span class="fontNormal" translate>
                Löschen
              </span>
            </button>
            <button (click)="discardSelectedSection()" *ngIf='showDiscardButton && !selectRecordAudio'
                    class="borderbutton" mat-flat-button style="float:right;">
              <mat-icon>
                replay
              </mat-icon>
              <span class="fontNormal" translate>
                Wiederherstellen
              </span>
            </button>

            <button (click)="resetTrimmer()" class="borderbutton" mat-flat-button
                    matTooltip="{{'Trimming zurücksetzen (r)' | translate}}">
              <mat-icon>
                cached
              </mat-icon>
            </button>
          </div>
        </div> <!--  endRow -->
        <!-- <div class="row" *ngIf="shapeClicked" (clickOutside)="clickOutsideShapeOverlay();shapeAccordion.closeAll();"></div> -->
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div fxLayout="row" id="footer">
  <footer>
    <mat-toolbar style="background-color: white;">
      <div class="w-100" style="display:flex;justify-content:flex-start">
        <button (click)="backRecording()" class="bgtransparent mr-sm-2" mat-button mat-raised-button
                type="submit">
          <mat-icon class="d-none d-sm-inline">autorenew</mat-icon>
          {{'Neu aufnehmen' | translate}}
        </button>
      </div>
      <div style="display:flex;justify-content:flex-end">
        <button *ngIf="processedFileExists" (click)="backToMetaPage()" class="bgtransparent mr-2" mat-raised-button>
          <mat-icon class="d-none d-sm-inline material-icons-outlined">cancel</mat-icon>
          {{'Discard changes' | translate}}
        </button>

        <button [disabled]="!playerLoaded" (click)="processVideo()"
                [ngClass]="playerLoaded ? 'bgEnabled':'btnDisable'" class="bgColor" mat-button mat-raised-button
                style="font-family: 'nunito';box-shadow: none;font-size: 18px;">
                <mat-icon class="d-none d-sm-inline">done_all</mat-icon>
          {{'Process Clypp' | translate}}
        </button>

      </div>
    </mat-toolbar>
  </footer>
</div>
