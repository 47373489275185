import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {VideoHistoryCard} from "../models/video/video.interface";

@Component({
  selector: 'app-video-card-history',
  templateUrl: './video-card-history.component.html',
  styleUrls: ['./video-card-history.component.scss']
})
export class VideoCardHistoryComponent implements OnInit {
  @Input('videoObject') videoObject: VideoHistoryCard;
  @Input ('backendUrl') backendUrl:any;
  @Input('disableClick') disableClick: boolean = false;
  thumbnail: string;
  percent_viewed: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.thumbnail = this.videoObject.video.thumbnail;
    if(this.videoObject.video.duration > 0){
      this.percent_viewed = this.videoObject.viewed_till / this.videoObject.video.duration * 100;
    }
  }

  mouseovered(){
    if(this.videoObject.video.gif){
      this.thumbnail = this.videoObject.video.gif;
    }
  }

  mouseouted(){
    this.thumbnail = this.videoObject.video.thumbnail;
  }
}
