<div class="row m-0 p-xl-4 p-2" style="overflow-x: hidden">

  <div class="col-lg-6 col-md-12 col-sm-12 col-12 d-inline-block" style="min-width: 300px">
    <div class="d-flex justify-content-between flex-wrap mb-3 mt-2">
      <h2 class="fontStyle d-flex align-items-center" translate>Workspace statistics</h2>
    </div>
    <div class="d-flex justify-content-around" style="flex-wrap: wrap;">
      <div class="simple-card-analytics">
        <h4 class="text-nunito m-0" translate>
          <mat-icon class="material-icons-outlined vertical-align-text-bottom">
            play_circle
          </mat-icon>
          Total Clypps
        </h4>
        <h3 class="text-nunito m-0 font-weight-bolder text-color">
          {{n_videos}}
        </h3>
      </div>

      <div class="simple-card-analytics">
        <h4 class="text-nunito m-0" translate>
          <mat-icon class="material-icons-outlined vertical-align-text-bottom">
            person
          </mat-icon>
          Total users
        </h4>
        <h3 class="text-nunito m-0 font-weight-bolder text-color">
          {{n_users}}
        </h3>
      </div>

      <div class="simple-card-analytics">
        <h4 class="text-nunito m-0" translate>
          <mat-icon class="material-icons-outlined vertical-align-text-bottom">
            group
          </mat-icon>
          Total Creators
        </h4>
        <h3 class="text-nunito m-0 font-weight-bolder text-color">
          {{n_creators}}
        </h3>
      </div>
    </div>

    <!--activity chart-->
    <div class="mt-4">
      <div class="d-flex justify-content-between mx-2">
        <!--select parameter-->
        <mat-form-field appearance="outline">
          <mat-select (selectionChange)="activitySelect($event)" [(ngModel)]="parameter">
            <mat-option value="activities">{{'Activities'|translate}}</mat-option>
            <mat-option value="users">{{'Active users'|translate}}</mat-option>
            <mat-option value="views">{{'Video views'|translate}}</mat-option>
            <mat-option value="videos">{{'Videos created'|translate}}</mat-option>
            <mat-option value="duration">{{'Video duration sum'|translate}}</mat-option>
          </mat-select>
        </mat-form-field>

        <!--duration window select-->
        <mat-form-field appearance="outline">
          <mat-select (selectionChange)="durationSelect($event)" [(ngModel)]="window">
            <mat-option value="7D">{{'7 days' | translate}}</mat-option>
            <mat-option value="30D">{{'30 days' | translate}}</mat-option>
            <mat-option value="90D">{{'90 days' | translate}}</mat-option>
            <mat-option value="YTD">{{'Year to date' | translate}}</mat-option>
            <mat-option value="1Y">{{'1 year' | translate}}</mat-option>
            <mat-option value="MAX">{{'Max' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!--chart-->
      <span *ngIf="!is_authorised" translate>
        You need global or company management rights
      </span>
      <div *ngIf="is_authorised" class="d-flex">
        <canvas class="p-2" id="canvas" style="width: 100%; height: 375px;"></canvas>
      </div>
    </div>
  </div>

  <!--  Recent activities-->
  <div class="col-lg-6 col-md-12 col-sm-12 col-12 d-inline-block">
    <!--title and filter-->
    <div class="d-flex justify-content-between flex-wrap">
      <h2 class="fontStyle d-flex align-items-center" translate>Recent activities</h2>
      <!--filter options-->

      <mat-form-field *ngIf="is_authorised" appearance="outline">
        <mat-label translate>Activity</mat-label>
        <input [formControl]="activityFormControl" [matAutocomplete]="auto3" matInput type="text">
        <mat-autocomplete #auto3="matAutocomplete" (optionSelected)="onActivitySelected($event)"
                          [displayWith]="displayFn.bind(this)">
          <mat-option value="">
            {{ 'Alle'|translate }}
          </mat-option>
          <mat-option *ngFor="let act of filteredActivities | async" [title]="act.activity_code"
                      [value]="act.activity_code">
            <span *ngIf="german_labels">
              {{ act.prefix_de }}&nbsp;{{ act.link_de }}&nbsp;{{ act.suffix_de }}
            </span>
            <span *ngIf="!german_labels">
              {{ act.prefix_en }}&nbsp;{{ act.link_en }}&nbsp;{{ act.suffix_en }}
            </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field *ngIf="is_authorised" appearance="outline">
        <mat-label translate>Filter users</mat-label>
        <input [formControl]="user_name_form_control" [matAutocomplete]="auto2"
               matInput
               placeholder="{{'Name' | translate}}" type="text">
        <mat-autocomplete #auto2="matAutocomplete">
          <mat-option (onSelectionChange)="handleSelectionChange($event, user.id)"
                      *ngFor="let user of filteredOptions | async"
                      [title]="user.string" [value]="user.name">
            {{user.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <span *ngIf="!is_authorised" translate>
            You need global or company management rights
        </span>
    <div *ngIf="is_authorised">
      <table [dataSource]="dataSource" fixedLayout="true" mat-table style="width: 100%;">
        <!-- user Column -->
        <ng-container matColumnDef="user">
          <td *matCellDef="let element" class="pr-2" mat-cell style="width:80%">
            <b [title]="element.email">{{element.first_name}}&nbsp;{{element.last_name}}</b>
            {{element.prefix}}
            <a *ngIf="element.href" [href]="element.href"
               [matTooltip]="element.supporting_text" matTooltipPosition="right"
               target="_blank">
              {{element.link_text}}
            </a>
            <span *ngIf="element.href.length == 0"
                  [matTooltip]="element.supporting_text" matTooltipPosition="right">
                {{element.link_text}}
              </span>
            {{element.suffix}}
          </td>
        </ng-container>

        <!-- time Column -->
        <ng-container matColumnDef="time">
          <td *matCellDef="let element" [title]="element.created_on" mat-cell style="text-align: end;"
              style="width:20%">
            {{utilityService.timeSince(element.created_on, "ago") | async}}
          </td>
        </ng-container>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
      </table>
      <mat-paginator (page)="updatePage($event)" [hidePageSize]="true" [length]="resultsLength"
                     [pageIndex]="currentPage"
                     class="pagination"
                     pageSize="10"></mat-paginator>
    </div>
  </div>
</div>
