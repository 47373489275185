<!--links-->
<nav class="pt-2" mat-tab-nav-bar>
  <a #rla="routerLinkActive" *ngFor="let route of routePath;
     let i=index"
     [active]="rla.isActive"
     [routerLink]="route.link"
     class="text-nunito font-weight-normal" mat-tab-link
     routerLinkActive="active">
    <mat-icon class="material-icons-outlined mr-2" mat-list-icon>
      {{route.icon}}
    </mat-icon>
    {{route.name|translate}}
  </a>
</nav>

<router-outlet></router-outlet>
