import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DataService} from "../../services/data.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-topic-bottom-sheet',
  templateUrl: './topic-bottom-sheet.component.html',
  styleUrls: ['./topic-bottom-sheet.component.scss']
})
export class TopicBottomSheetComponent implements OnInit {

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<TopicBottomSheetComponent>,
    private snackBar: MatSnackBar,
    private dataService: DataService,
    private translateService: TranslateService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {ids: string[]}
  ) {

  }

  ngOnInit(): void {
  }

  performAction(action: string) {
    // confirm first
    let message: string = "";
    if (this.data.ids.length == 1) {
      message = this.translateService.instant("Creator");
      message += " "
      message += this.translateService.instant("will also be notified about this.");
    } else {
      message = this.translateService.instant("Creators");
      message += " "
      message += this.translateService.instant("will be informed about this.");
    }
    message += "\n\n";
    message += this.translateService.instant("Would you like to continue?");
    if (window.confirm(message)) {
      const body = {
        action: action,
        ids: this.data.ids
      }
      this.dataService.putURL(`manager/topics/`, body, {
        observe: 'body',
        responseType: 'text'
      }).subscribe((res: string) => {
        this.snackBar.open(res, '', {duration: 2500});
        this._bottomSheetRef.dismiss(true);
      }, (err) => {
        console.error(err);
        this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
        this._bottomSheetRef.dismiss(false);
      });
    }
  }

}
