<div class="m-3 d-flex align-items-center">
  <mat-card-title class="fontStyle mr-3" translate>
    Playlist bearbeiten
  </mat-card-title>

  <button (click)="backToMyVideos()" mat-stroked-button class="mr-3 border-radius-20">
    <mat-icon class="mr-1">chevron_left</mat-icon>
    {{'Back'|translate}}
  </button>

  <button [matMenuTriggerFor]="menu" aria-label="more options" class="zesavi-mini-fab" mat-mini-fab>
    <mat-icon style="color: black;">more_horiz</mat-icon>
  </button>
  <span >
    <div class="d-flex align-items-center">
      <mat-menu #menu="matMenu">
        <button (click)='setPrivate(true)' *ngIf="!is_Draft" class="fontNormal" mat-menu-item>
          <mat-icon class="mr-1" mat-list-icon>cloud_off</mat-icon>
          <span class="fontNormal" translate>Save as private</span>
        </button>
        <button (click)='setPrivate(false)' *ngIf="is_Draft"
                [disabled]="videosInPlaylist.length == 0"
                class="fontNormal" mat-menu-item>
          <mat-icon class="mr-1 material-icons-outlined" mat-list-icon>cloud_done</mat-icon>
          <span class="fontNormal" translate>Veröffentlichen</span>
        </button>
        <button (click)="savePlaylistURL()" class="fontNormal"
                [disabled]="is_Draft" mat-menu-item>
          <mat-icon class="mr-1" mat-list-icon>link</mat-icon>
          <span class="fontNormal" translate>Copy link</span>
        </button>
        <button (click)='deletePlaylist()' class="fontNormal" mat-menu-item>
          <mat-icon class="mr-1 material-icons-outlined" mat-list-icon>delete</mat-icon>
          <span class="fontNormal" translate>Playlist löschen</span>
        </button>
      </mat-menu>
    </div>
  </span>
</div>

<mat-divider></mat-divider>

<div style="flex-flow: wrap;" class="w-100 d-flex justify-content-around">
  <div class="w-75 p-3" style="min-width: 500px; max-width: 850px;">
    <form [formGroup]="playlistForm" class="w-100" style="position: relative;">
      <mat-card>
        <mat-card-content>
          <mat-form-field class="w-100 fontNormal" appearance="outline" hideRequiredMarker>
            <mat-label translate>Titel dieser Playlist</mat-label>
            <input (input)="title_changed=true;"
                   (keyup.enter)="saveTitle();"
                   (clickOutside)="saveTitle();"
                   autocomplete="off"
                   autofocus="false"
                   class="fontNormal"
                   formControlName="title"
                   matInput
                   id="title-input"
                   maxlength="100"
                   placeholder="{{'Wähle einen aussagekräftigen Titel'|translate}}" required type="text"/>
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-100" style="line-height: inherit;">
            <mat-label translate>Add Clypps</mat-label>
            <mat-icon (click)="getSearchResult()" class="pointer" matSuffix>search</mat-icon>
            <input (keydown.enter)="getSearchResult()" autocomplete="off" formControlName="searchVal"
                   matInput placeholder="{{'Search for…' | translate}}" type="text">
          </mat-form-field>

          <div class="d-flex justify-content-around" style="flex-flow: wrap">
            <div *ngFor="let video of search_videos">
              <div *ngIf="video.uploader.userprofile.company == companyID">
                <!--Only show videos which belong to this company-->
                <app-video-card [backendUrl]="backendUrl" [videoObject]="video"></app-video-card>
                <button (click)="addVideo(video)"
                        *ngIf="!findVideoInList(video.id);"
                        class="addVideoButton" color="primary"
                        mat-mini-fab
                        matTooltip="{{'Add' | translate}}" type="button">
                  <mat-icon style="color: white;">add</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </div>

  <div class="w-25 p-3" style="min-width: 350px; max-width: 500px;">
    <mat-card-header>
      <mat-card-title class="fontStyle" translate>
        Clypps order
      </mat-card-title>
      <mat-card-subtitle translate style="font-family: 'nunito';">
        Klicke und ziehe Clypps in die gewünschte Reihenfolge
      </mat-card-subtitle>
    </mat-card-header>

    <div class="relatedVid">
      <mat-grid-list #grid (cdkDropListDropped)="drop($event)" [gutterSize]="'10px'" cdkDropList cols="1"
                     rowHeight="100px">

        <mat-grid-tile *ngFor="let video_index of videosInPlaylist" cdkDrag>
          <mat-card class="card-mv-style-s1 cursor-pointer">
            <mat-card-header style="position: relative;" (click)="recentVideoClick(video_index.video.id)">
              <!--video thumbnail-->
              <img [src]="video_index.video.thumbnail" class="w-25 mt-2"
                   style="object-fit: contain; object-position: top;">

              <!--video icons-->
              <div class="position-absolute" style="top: 16px; left: 10px;">
                <mat-icon *ngIf="video_index.video.is_restricted"
                          title="{{'Restricted visibility inside the workspace'|translate}}">
                  network_locked
                </mat-icon>&nbsp;
                <mat-icon *ngIf="video_index.video.is_external && !video_index.video.is_access_key_needed"
                          title="{{'Public'|translate}}">
                  public
                </mat-icon>
                <mat-icon *ngIf="video_index.video.is_external && video_index.video.is_access_key_needed"
                          title="{{'Unlisted'|translate}}">
                  vpn_lock
                </mat-icon>
              </div>
              <!--video title-->
              <div class="w-75 ml-2">
                <b class="text-nunito mb-2 overflow-hidden"
                   [title]="video_index.video.title"
                   style="display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;">
                  <!--show draft if video is draft-->
                  <span style="color: red" *ngIf="video_index.video.is_draft">
                    ({{'Draft'|translate}})
                  </span>
                  <!--show video title-->
                  {{video_index.video.title}}
                </b>
                <mat-card-subtitle class="text-nunito">
                  {{video_index.video.uploader.first_name}} {{video_index.video.uploader.last_name}}
                  <br>
                  <span *ngIf="video_index.video.views == 1">
                    {{video_index.video.views | number}}&nbsp;{{'Aufruf' | translate}}&nbsp;{{utilityService.timeSince(video_index.video.added_on) | async }}
                  </span>
                  <span *ngIf="video_index.video.views > 1 || video_index.video.views == 0">
                    {{video_index.video.views | number}}&nbsp;{{'Aufrufe' | translate}}&nbsp;{{utilityService.timeSince(video_index.video.added_on) | async }}
                  </span>
                </mat-card-subtitle>
              </div>
            </mat-card-header>
          </mat-card>
          <button mat-icon-button (click)="deleteVideo(video_index.video.id)"
                  matTooltip="{{'Entfernen' | translate}}" matTooltipPosition="below"
                  style="position: absolute; top: 30%; left: 85%; z-index: 10;">
            <mat-icon>close</mat-icon>
          </button>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</div>
