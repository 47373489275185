<div style="overflow-y:auto !important;position: relative;" class="w-100 quickwin-creation backgroundpng">

  <div *ngIf="progress > 0" class="spinner-overlay">
    <div style="position: absolute;top:40%;left :50%;z-index:999;opacity: 1;" class="mt-3 text-center text-white">
      <div class="spinner-background">{{progress}}%</div>
      <mat-progress-spinner class="spinner" #spinner color="primary" mode="determinate" [value]="progress">
      </mat-progress-spinner>
      <div *ngIf="progress == 100 || processFinish" class="processText"><span translate>Bitte warten...</span></div>
    </div>
  </div>


  <div class="w-100 mx-auto" style="height:100%;overflow-x: hidden;z-index:9;position: relative;">
    <div style="height: 100%;">
      <div fxLayout="row wrap" class="recordingfullscreen" style="position: relative;">
        <div fxFlex="15%" fxFlex.md="20%" fxFlex.sm="30%" fxFlex.xs="100%" style="height: 100%;position: relative;">
          <div fxLayout="column" style="height: 100%;">
            <div>
              <div style="margin-left: 2rem;">
                <span style="display: flex;justify-content: flex-start;float:left;margin-top:1rem;">
                  <app-timer #videoTimer style="color: black !important;" [timeLimit]="timeLimit">
                  </app-timer>
                </span>
                <span style="margin-top:0.5rem">
                  <ng-container>
                    <app-screencast-controls #controls
                      [stopButtonConfig]="{
                                    action: stopScrCstWebCam,
                                    videotype:VideoTypeValue,
                                    pause: pauseRecording,
                                    resume: resumeRecording,
                                    pauseStatus: paused,
                                    display: screencastWebcamService.recording() || (!screencastWebcamService.recording() && paused && !videoRecordingFinished)}"
                      [startButtonConfig]="{
                                     action: startCaptureScrCstWebCam,
                                     videotype:VideoTypeValue,
                                     display: !screencastWebcamService.recording() && !videoRecordingFinished && !paused}"
                      [rerecordButtonConfig]="{
                                     action: openConfirmOverride,
                                     videotype:VideoTypeValue,
                                     display: !screencastWebcamService.recording() && videoRecordingFinished}">
                    </app-screencast-controls>
                  </ng-container>
                </span>
                <span style="position: relative; left: 2rem;">
                  <div style="display:flex;
                    justify-content: center;
                  margin-top: 0rem;position: relative;left: 1.2rem;">
                    <button mat-fab [ngClass]="disabledReset ?  'disablebigIcon' : 'bigButton'"
                      style="box-shadow: none !important;" (click)="metaReviewButton()" [disabled]="disabledReset">
                      <mat-icon class="restartBig">keyboard_tab</mat-icon>
                    </button>
                  </div>
                  <span style="max-width: min-content; margin-left: 35%;" class="textcolor">{{nextLabel | translate}}</span>
                </span>
              </div>
            </div>
            <div [hidden]="!showAudio || micoffOnRecreate" style="position: relative;">
              <div class="fontNormal" style="margin-top: 0.5rem;display: flex;justify-content: center; cursor: pointer;"
                (click)="openAudioEnable()" translate>Einstellungen
                <span class="invert-triangle"></span>
              </div>

              <div class="rectOption" [hidden]="!showAudioOption">
                <div style="height: 100%;" class="row">
                  <div class="col-2">
                    <button style="margin-left: 5px;margin-top: 1.1rem;margin-bottom:5px;outline-color: transparent;z-index: 99;
             position: relative;" [ngClass]="!micVal ?'disablemic' : 'enablemic'" (click)="toggleAudio()">
                      <mat-icon style="display: flex;justify-content: center;font-size: 27px;width: 27px;height: 27px;"
                        [matTooltip]="audioSelectionTooltip">
                        {{audioIcon}}</mat-icon>
                    </button>
                  </div>
                  <div class="col-2">
                    <button style="margin-left: 5px;margin-top: 1.1rem;margin-bottom:5px;outline-color: transparent;z-index: 99;
           position: relative;" [ngClass]="noiseSuppression ? 'enablemic' : 'disablemic'" (click)="toggleNoise()">
                      <mat-icon
                        style="display: flex;justify-content: center;font-size: 27px;width: 27px;height: 27px;color: black;"
                        [matTooltip]="noiseSuppressionTooltip">
                        {{noiseIcon}}</mat-icon>
                    </button>
                  </div>
                  <div class="col-8 fontNormal" style="margin-top: 3%;padding-left: 7%;">
                    <span class="fontNormal">
                      <mat-form-field style="width:95% !important;margin-top: -0.7rem !important;" appearance="outline">
                        <mat-select placeholder="Audio Input" (selectionChange)="onAudioDropChange($event)"
                          [(ngModel)]="defaultValue">
                          <mat-option *ngFor="let audio of audioinputVal" [value]="audio">
                            {{audio.label}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </span>
                  </div>
                </div>
                <div class="row" style="margin-top: -0.5rem;">
                  <div class="col-2">
                  </div>
                  <div class="col-10">
                    <canvas #meter style="margin-left:0.45rem;width: 80%;" [width]="WIDTH" [height]="HEIGHT"></canvas>
                  </div>
                </div>
                <div style="margin-top: -0.7rem;" class="row">
                  <div class="col-2">
                    <button style="margin-left: 5px;margin-top: 1.1rem;margin-bottom:5px;outline-color: transparent;z-index: 99;
           position: relative;border:0px solid transparent;cursor: default;">
                      <mat-icon style="display: flex;justify-content: center;font-size: 27px;width: 27px;height: 27px;">
                        videocam</mat-icon>
                    </button>
                  </div>
                  <div class="col-10 fontNormal" style="margin-top: 3%;padding-left: 9%;">
                    <span class="fontNormal">
                      <mat-form-field style="width:95% !important;margin-top: -0.7rem !important;" appearance="outline">
                        <mat-select placeholder="Video Input" (selectionChange)="onVideoDropChange($event)"
                          [(ngModel)]="defaultVideoValue">
                          <mat-option *ngFor="let video of videoInputValue" [value]="video">
                            {{video.label}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div fxFlex="70%"  style="height: 100%;">
              <div class="w-100" style="position: relative;left: 2%;height: 100%;">
                <app-notes [rows]="notescol" style="position: relative;height: 100%;width: 100%;" [notes]="notes"
                  (notesChange)="notesChange($event)"></app-notes>
                <div class="countdown1" style="color:white;font-size: 80px !important;z-index:100;left: 40%; top: 50%;"
                  *ngIf="showCountdown">
                  {{timeLeft}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="position: relative;left:0px;max-width: 85%;padding: 0px 20px 0px 20px;" fxFlex="85%" fxFlex.md="80%"
        fxFlex.sm="70%"
        fxFlex.xs="100%">
          <div fxLayout="column" style="height: 100%;">
            <div style="height: 5% !important;">
              <div fxLayout="row" style="justify-content: space-around;">
                <div fxFlex="80%" style="display: flex;justify-content: center;">
                  <img class="imgresize" style="float:right;margin-top:0rem; height: 30px; cursor: pointer;"
                    [src]="authService.company.company_logo" alt="company logo" (click)="goToHome()">
                </div>
                <div style="display: flex;justify-content: flex-end; float: right; height: 30px;">
                  <a href="{{'TIPS_URL' | translate}}" target="_blank">
                    <p class="tips" translate>Tipps ansehen
                      <mat-icon style="vertical-align: bottom;">tips_and_updates</mat-icon>
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <div style="height: 95%;margin-bottom: 1rem;">
              <div class="container">
                <div class="text">
                  <div style="height: 100%;">
                    <mat-grid-list cols="4" class="myGridList" rowHeight="fit">
                      <mat-grid-tile colspan="4" rowspan="3">
                        <div class="video-div">
                          <video *ngIf="!screencastPreview && screenValue" [srcObject]="screenValue" autoplay
                            playsinline muted style="width: 100%; height: 100%"></video>
                             <!-- if entire screen is shared, then a blue overlay comes on video -->
                             <div *ngIf="is_entire_screen_shared" class="entire-screen-shared">
                              <span translate style="right: auto; left: 40%;">
                                Your whole screen is being shared
                              </span>
                            </div>
                          <div style="padding-top: 15%;height: 100%;width: 100%;" (click)="onSelectScreen()"
                            *ngIf="screencastPreview">
                            <div class="iconCenter">
                              <mat-icon class="iconStyle">screen_share</mat-icon>
                            </div>
                            <div class="fontNormal iconCenter" translate>Click here to preview the screen,</div>
                            <div class="fontNormal iconCenter" translate>then click "start" on the left</div>
                          </div>
                        </div>
                      </mat-grid-tile>
                    </mat-grid-list>
                  </div>
                  <app-wc-sc-both-viewer *ngIf="showCam" #webcamSCViewer
                                         (startButtonClickedChanged)="startTestWebcam(false)"
                                         [recording]="screencastWebcamService.recording()" [srcTestVid]="webcamDispVideo" [type]="'BOTH'">
                  </app-wc-sc-both-viewer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
