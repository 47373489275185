import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {NavbarService} from '../../services/navbar.service';
import {NavigationEnd, Router} from '@angular/router';
import {VideoCreationModalPopupComponent} from "../../video-creation-modal-popup/video-creation-modal-popup.component"
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from '@ngx-translate/core';
import {DataService} from "../../services/data.service";
import {FormControl} from '@angular/forms';
import {SharedService} from 'src/app/services/shared.service';
import { FeedbackDialogComponent } from '../../shared/feedback-dialog/feedback-dialog.component';
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {ChecklistItem, MiniDetails} from "../../models/video/video.interface";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  is_public_page: boolean = false;  // used to show login button and go to correct url on clicking logo

  transLang: any[] = [{code: "en", name: "English"}, {code: "de", name: "Deutsch"}];
  smallSc: boolean = false;
  showSearch: boolean = false;  // valid for small screen only
  lang_label = 'English';

  myControl = new FormControl('');
  filteredOptions: Observable<ChecklistItem[]>;  // show filter based on user search
  selectedOption : boolean = false;  // this becomes true if user selects an autocomplete item

  constructor(public translateService: TranslateService,
              public sharedService: SharedService,
              public dataService: DataService,
              public authService: AuthService,
              public navbarService: NavbarService,
              private router: Router, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.lang_label = this.transLang.find(x=>localStorage.getItem('language') == x.code)['name'];
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/public')) {
          this.is_public_page = true;
        }
        else {
          this.is_public_page = false;
        }
      }
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter((value || ''))),
    );
  }

  private _filter(value: string): ChecklistItem[] {
    const filterValue = value.toLowerCase().trim();
    if(filterValue == '') {
      // return nothing if user does not type anything
      return [];
    }

    // search in users, groups and tags only
    const return_data: ChecklistItem[] = this.authService.checklist_data
      .filter(item => item.name.toLowerCase().includes(filterValue)
        && (item.type == 'group' || item.type == 'user'));

    const filter_tags: MiniDetails[] = this.authService.tag_data
      .filter(e => e.name.toLowerCase().includes(filterValue));

    for (const item of filter_tags) {
      return_data.push({
        id: item.id,
        name: item.name,
        string: item.name,
        type: 'tag'
      });
    }

    return return_data;
  }

  setTransLanguage(changedLang) {
    this.translateService.use(changedLang.code);
    // setting it in local storage will help to keep it even after login
    localStorage.setItem('language', changedLang.code);
    this.lang_label = changedLang.name;
    // document.documentElement.setAttribute("lang", changedLang);
  }

  getSearchResult() {
    // user is searching for something
    if (!this.selectedOption) {
      if (this.myControl.value) {
        this.router.navigate(['search'], {
          queryParams: {
            search_type: 'all',
            search_text: this.myControl.value
          }
        });
      }
      else {
        this.router.navigate(['start']);
      }
    } else {
      // reset click/enter on the autocomplete item?
      this.selectedOption = false;
    }
  }

  goToGroupsOrUser(item: ChecklistItem) {
    this.selectedOption = true;  // made true, so that ENTER would not call search query
    // user is searching for an object
    if (item.type == 'group') {
      // go to groups
      this.router.navigate(['groups', item.id]);
    }
    else if (item.type == 'user') {
      // go to users
      this.router.navigate(['user', item.id]);
    } else if (item.type == 'tag') {
      // go to tags
      this.router.navigate(['tag', item.id]);
    }
    this.myControl.setValue(item.name);
  }

  ngAfterViewInit(): void {
    if(window.innerWidth <= 900){
      this.smallSc = true;
    }
    else{
      this.smallSc = false;
    }
  }

  logout(): void {
    // when logging with Microsoft SSO or ADFS, the logout_url key will be set by backend
    let logout_url: string = localStorage.getItem('logout_url');
    this.lang_label = this.transLang.find(x=>localStorage.getItem('language') == x.code)['name'];
    if (logout_url) {
      localStorage.clear();  // access token is stored in local storage by backend when login using sso
      // sessionStorage.removeItem('access_token');  // as access token is set in session storage too by FE
      // clear cookie set by backend by setting an old expire date
      document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
      window.location.href = logout_url;
    } else {
      this.authService.logout();
    }
  }


  // opened when user comes via http://localhost:4200/start?create-box=true link
  createBox() {
    if (!this.authService.currentUser.is_creator) {
      this.navbarService.setCreatorPopup();
      return;
    }

    // open modal popup
    this.dialog.open(VideoCreationModalPopupComponent, {
      width: '500px',
      disableClose: false,
      panelClass: 'my-panel'
    }).afterClosed().subscribe(res => {
    });
  }

  sideBarToggle() {
    // find the current state
    let toggleVal = this.sharedService.sideBarExpansion.value;
    // set the new state to inverse of current state
    this.sharedService.sideBarExpansion.next(!toggleVal);
    // above is subscribed in app.component.ts
  }

  openFeedbackDialog(){
    this.dialog.open(FeedbackDialogComponent, {
      width: "40%",
      minWidth: "400px",
      autoFocus: false,
      disableClose: false,
      hasBackdrop: true
    });
  }

  clyppPublicPage(){
    // take user to public page of Clypp
    window.open("https://clypp.app/public/537", "_blank");
  }

  openHelpPage() {
    window.open("https://clypp.app/pages/view/8d1130a3-0186-4e03-ac75-8649359666a6", '_blank');
  }

  openNewsPage() {
    window.open("https://clypp.app/pages/view/4bfbe70d-c5b6-4cf6-a738-e51f94fd7cad", '_blank');
  }

  clearSearch() {
    if(this.myControl.value){
      // user is pressing X once
      this.myControl.setValue('');
    }
    else {
      // user is pressing x twice, hide search
      this.showSearch = false;
    }
  }

  clearSearchAndGoBack() {
    this.showSearch = false;
    this.myControl.setValue('');
    this.getSearchResult();
  }
}
