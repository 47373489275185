import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(public auth: AuthService, public router: Router) {
  }

  canActivate(state: RouterStateSnapshot, route : ActivatedRoute): boolean {
    if (!this.auth.isAuthenticated()) {
      let qpString = '';
     // console.log(route.root.queryParams);
      let stateObj = route.root.queryParams;
      for (const [key, value] of Object.entries(stateObj)) {
        qpString = qpString + `${key}:${value},`;
      }
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url, qParams: qpString }});
      return false;
    } else {
      return true;
    }
    
  }
}
