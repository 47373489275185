<!-- header -->
<mat-toolbar class="bg-white justify-content-between mat-elevation-z2 fixed-toolbar">
  <!--back to edit or record page-->
  <div class="d-flex justify-content-start">
    <!-- always visible-->
    <button (click)="saveVideo('record');"
            class="my-3 mx-1 border-radius-20" color="primary" mat-stroked-button>
      <mat-icon class="icon-for-standard-button material-icons-outlined" matPrefix>fast_rewind</mat-icon>
      <span class="d-none d-md-inline" translate>Record</span>
    </button>

    <!-- visible if video file is there-->
    <button (click)="saveVideo('edit');"
            *ngIf="video_object?.video_file"
            class="my-3 mx-1 border-radius-20" color="primary" mat-stroked-button>
      <mat-icon class="icon-for-standard-button" matPrefix>arrow_back_ios</mat-icon>
      <span class="d-none d-md-inline" translate>Bearbeiten</span>
    </button>
  </div>

  <!--company logo-->
  <img class="d-none d-lg-inline cursor-pointer" height="50px"
       routerLink="/start"
       style="position: absolute; margin: auto; right: 0; left: 0;"
       id="company_logo"
       title="{{'To homepage'|translate}}" alt="Company Logo"
       src="{{authService.company.company_logo}}">

  <!--other buttons-->
  <div class="d-flex justify-content-end" *ngIf="video_object">

    <!--set draft/publish-->
    <mat-slide-toggle [(ngModel)]="is_published" class="mt-4" style="width: 150px;">
        <span *ngIf="is_published" class="text-nunito" style="font-size: 14px;" translate>
          Veröffentlicht
        </span>
      <span *ngIf="!is_published" class="text-nunito" style="font-size: 14px;" translate>
          Draft
      </span>
    </mat-slide-toggle>

    <!--done button-->
    <!--if video is getting drafted, then there is just one case-->
    <div *ngIf="!is_published">
      <button (click)="saveVideo('done')" class="m-3 border-radius-20"
              color="primary"
              mat-flat-button>
        <span class="d-none d-md-inline" translate>
          Done
        </span>
        <mat-icon class="icon-for-standard-button" matSuffix>done</mat-icon>
      </button>
    </div>

    <!--if video is getting published, then there are three cases-->
    <div *ngIf="is_published">
      <button (click)="saveVideo('done')" class="m-3 border-radius-20"
              color="primary"
              mat-flat-button>
        <!--case 1: neither Trello nor fulfill request-->
        <span *ngIf="!(is_fulfill_request_case || is_trello_case)"
              class="d-none d-md-inline" translate>
          Done
        </span>
        <!--case 2: show trello button if there is card_id and jira_token and video is getting published-->
        <span *ngIf="is_trello_case"
              class="d-none d-md-inline" translate>
          Attach on Trello
        </span>
        <!--show fulfill-request button if there is video_request_id and video is getting published-->
        <span *ngIf="is_fulfill_request_case"
              class="d-none d-md-inline" translate>
          Fulfill request
        </span>
        <mat-icon class="icon-for-standard-button" matSuffix>done</mat-icon>
      </button>
    </div>

  </div>
</mat-toolbar>

<div class="d-flex justify-content-center" style="margin-top: 70px;">
  <div class="d-flex mt-3 flex-column"
       style="min-width: 55vw; max-width: 97vw; width: 400px;"
       *ngIf="video_object">

    <!--show hide title based on click action-->
    <mat-card-title class="fontStyle cursor-pointer line-height-initial"
                    *ngIf="!is_title_editor_visible"
                    (click)="is_title_editor_visible=true; is_text_area_active=true;">
      {{title ? title : empty_title_text}}
      <mat-icon class="material-icons-outlined" matSuffix>edit</mat-icon>
    </mat-card-title>

    <!--title editor-->
    <mat-form-field appearance="outline" class="w-100 line-height-initial"
                    *ngIf="is_title_editor_visible"
                    (clickOutside)="hideTitleEditor();">
      <textarea (keydown.enter)="hideTitleEditor();"
                (keydown.escape)="hideTitleEditor();"
                [(ngModel)]="title"
                autocapitalize="on"
                autocomplete="off"
                id="title-editor"
                class="fontStyle line-height-initial" matInput
                maxlength="100"
                required
                rows="2"
                style="font-size: 24px;"
                type="text">
      </textarea>
      <mat-icon class="material-icons-outlined" matSuffix>edit</mat-icon>
    </mat-form-field>

    <!--video content-->
    <div class="w-100 border-radius-10 mt-3 d-flex align-items-center"
         style="background-color: gainsboro; aspect-ratio: 16/9;">

      <mat-card-subtitle class="text-nunito w-100 text-center"
                         *ngIf="video_object.state == 'NO'" translate>
        Please click on "Edit" and record your video.
      </mat-card-subtitle>

      <mat-card-subtitle class="text-nunito w-100 text-center"
                         *ngIf="video_object.state == 'FA'" translate>
        Failed to process video. Please click on "Edit" and try again.
      </mat-card-subtitle>

      <mat-card-subtitle class="text-nunito w-100 text-center"
                         *ngIf="video_object.state == 'CO' && video_object.processed_file == null" translate>
        Failed to process video. Please click on "Edit" and try again.
      </mat-card-subtitle>

      <mat-card-subtitle class="text-nunito w-100 text-center"
                         *ngIf="video_object.state == 'UP'" translate>
        Please click on "Edit" and process your video.
      </mat-card-subtitle>

      <mat-card-subtitle class="text-nunito w-100 text-center d-flex align-items-center flex-column"
                         *ngIf="video_object.state == 'PR'">
        {{'Your Clypp is being processed'|translate}}
        <mat-spinner class="mt-4 mb-4"></mat-spinner>
        <!--todo: show approx time-->
        {{time_estimate_to_process_video}}
      </mat-card-subtitle>

      <video *ngIf="video_object.processed_file && video_object.state == 'CO'" width="100%" height="100%" #videoPlayer
             (loadedmetadata)="playerReady()"
             (focusin)="is_text_area_active=true;"
             (focusout)="is_text_area_active=false;"
             [poster]="video_object.thumbnail"
             controls controlsList="nodownload" crossorigin="anonymous" preload="metadata" playsinline
             class="border-radius-10">
        <source [src]="video_object.processed_file"
                type="video/mp4">
        <track *ngIf="video_object.vtt_file"
               [src]="video_object.vtt_file"
               [srclang]="video_object.audio_language"
               [label]="video_object.audio_language"
               default
               kind="subtitles">
      </video>

      <!-- <media-player *ngIf="video_object.processed_file && video_object.state == 'CO'"  width="100%" height="100%"
        (duration-change)="playerReady();" playsinline [src]="video_object.processed_file"
        [title]="video_object.title" aspect-ratio="16/9" class="border-radius-10" crossorigin="anonymous" #videoPlayer
        preload="metadata">
        <media-poster src="{{video_object.thumbnail}}"></media-poster>
        <media-provider></media-provider>
        <media-outlet id="media-outlet-block">
          <ng-container *ngFor="let subtitle of translations">
            <track kind="subtitles" [src]="video_object.vtt_file" [srclang]="video_object.audio_language" [label]="video_object.audio_language"
              [default]="video_object.audio_language == current_language" />
          </ng-container>
          <track *ngIf="video_object.chapter_file" [src]="video_object.chapter_file" [srclang]="video_object.audio_language"
            default kind="chapters" />
        </media-outlet>
        <media-community-skin></media-community-skin>
      </media-player> -->
    </div>

    <!--language, transcribe, attachment-->
    <div class="w-100 mt-3 justify-content-between align-items-center d-flex mb-1" style="flex-flow: wrap">

      <!--language, transcribe are together-->
      <div>
        <mat-form-field appearance="outline" class="mr-2">
          <mat-label translate>Language</mat-label>
          <mat-select [(ngModel)]="audio_language">
            <mat-option *ngFor="let option of languages" [value]="option[0]">
              {{option[1]}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--create subs if none there-->
        <button (click)="transcribeNow();"
                *ngIf="authService.company.is_transcription_service_enabled && !video_object.vtt_file"
                [disabled]="video_object.state!='CO' || is_transcribing"
                mat-button
                color="primary"
                matTooltip="{{'Transcribe your recording'|translate}}">
          <mat-icon class="material-icons-outlined mr-1">subtitles</mat-icon>
          <span *ngIf="!is_transcribing" translate>Create subtitles</span>
          <span *ngIf="is_transcribing" translate>Bitte warten...</span>
          <mat-progress-bar *ngIf="is_transcribing"
                            class="position-absolute" mode="buffer" style="bottom: 0; left: 0;">
          </mat-progress-bar>
        </button>

        <!--subtitle menu if there-->
        <button mat-button
                [matMenuTriggerFor]="subsMenu"
                *ngIf="video_object.vtt_file">
          <mat-icon class="material-icons-outlined mr-1">subtitles</mat-icon>
          <span translate>Subtitles</span>
        </button>

        <mat-menu #subsMenu="matMenu">
          <button mat-menu-item (click)="editSubtitles();">
            <mat-icon class="material-icons-outlined">edit</mat-icon>
            <span translate>Bearbeiten</span>
          </button>
          <button mat-menu-item (click)="updateSubtitles();"
                  [disabled]="!authService.company.is_transcription_service_enabled">
            <mat-icon>update</mat-icon>
            <span translate>Recreate subtitles</span>
          </button>
          <button mat-menu-item (click)="deleteSubtitles();">
            <mat-icon class="material-icons-outlined">delete_outline</mat-icon>
            <span translate>Löschen</span>
          </button>
        </mat-menu>
      </div>

      <div>
        <!--translate-->
        <button (click)="addTranslation();"
                [disabled]="!authService.company.is_transcription_service_enabled"
                [matBadgeHidden]="video_object.translations?.length==0"
                [matBadge]="video_object.translations?.length" mat-button
                matTooltip="{{'Translate title, description & subtitles'|translate}}"
                matTooltipPosition="above">
          <mat-icon class="material-icons-outlined mr-1">translate</mat-icon>
          <span translate>Translations</span>
        </button>

        <!--open share popup-->
        <button (click)="sharePopup();" mat-button aria-label="share">
          <mat-icon matPrefix *ngIf="!video_object.is_restricted">lock_open</mat-icon>
          <mat-icon matPrefix *ngIf="video_object.is_restricted">lock_outline</mat-icon>
          <span translate>Manage access</span>
        </button>

        <!--more options-->
        <button [matMenuTriggerFor]="moreMenu" aria-label="more options" mat-icon-button>
          <mat-icon>more_horiz</mat-icon>
        </button>

        <!--duplicate-->
        <mat-menu #moreMenu="matMenu">
          <button (click)="duplicateVideo()"
                  [disabled]="video_object.state == 'NO'"
                  class="fontNormal" mat-menu-item>
            <mat-icon class="mr-1">content_copy
            </mat-icon>
            <span class="fontNormal" translate>Duplicate</span>
          </button>

          <!--chapters-->
          <button (click)="openChapterPopup()"
                  [disabled]="video_object.state != 'CO'"
                  class="fontNormal" mat-menu-item>
            <!--State must be completed for user to see correct duration and processed_file-->
            <mat-icon class="mr-1 material-icons-outlined">feed</mat-icon>
            <span *ngIf="!video_object.chapter_file" class="fontNormal" translate>Add chapters</span>
            <span *ngIf="video_object.chapter_file" class="fontNormal" translate>Edit chapters</span>
          </button>

          <!--thumbnail-->
          <button (click)="updateThumbnail()" [disabled]="video_object.state != 'CO'" class="fontNormal" mat-menu-item>
            <mat-icon class="mr-1 material-icons-outlined">image</mat-icon>
            <span translate>Update thumbnail</span>
          </button>

          <!--delete clypp-->
          <button (click)="deleteVideo()" [disabled]="video_object.state == 'PR'" class="fontNormal text-danger"
                  mat-menu-item>
            <mat-icon class="mr-1 material-icons-outlined text-danger">delete</mat-icon>
            <span translate>Delete Clypp</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!--auto create desc-->
    <div class="w-100 mt-3"
         *ngIf="authService.company.is_transcription_service_enabled">
      <button mat-button
              [matMenuTriggerFor]="menu"
              matTooltip="{{'Automatically generate a description from your subtitles'|translate}}"
              matTooltipPosition="above"
              aria-label="auto create description"
              color="primary"
              class="pl-0"
              style="height: fit-content;">
        <mat-icon class="material-icons-outlined mr-1">auto_fix_high</mat-icon>
        <span translate>Auto-create description</span>
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="autoCreateDesc('guide');">
          <mat-icon>format_list_numbered</mat-icon>
          <span translate>Step-by-Step Guide</span>
        </button>
        <button mat-menu-item (click)="autoCreateDesc('summary');">
          <mat-icon>close_fullscreen</mat-icon>
          <span translate>Quick Summary</span>
        </button>
        <button mat-menu-item (click)="autoCreateDesc('compact');" [disabled]="desc?.length <= 100">
          <mat-icon>short_text</mat-icon>
          <span translate>Make it shorter</span>
        </button>
        <button mat-menu-item (click)="insertScript();" [disabled]="video_object.script == ''">
          <mat-icon>notes</mat-icon>
          <span translate>Insert script</span>
        </button>
      </mat-menu>
    </div>

    <!--desc-->
    <!--quill-editor-->
    <!--    (onContentChanged)="onContentChanged($event)" -->

    <!--the desc body should not be over 1MB big-->
    <mat-error class="mt-2 text-nunito" translate
               *ngIf="desc?.length > environment.bodySize">
      The provided input is too large. Try deleting some content.
    </mat-error>
    <quill-editor #customQuill
                  (onContentChanged)="quillContentChanged($event);"
                  [styles]="{'border-bottom-left-radius': '5px','border-bottom-right-radius': '5px',
                  'min-height': '100px', 'font-size': 'medium'}"
                  class="custom-quill mt-1 mb-5"
                  [(ngModel)]="desc"
                  (focusin)="is_text_area_active=true;"
                  (focusout)="is_text_area_active=false;"
                  placeholder="{{'Diese Beschreibung wird unter deinem Video gezeigt' | translate}}">
      <div quill-editor-toolbar>
        <span class="ql-formats">
          <button title="Bold" class="ql-bold"></button>
          <button title="Italic" class="ql-italic"></button>
          <button title="Underline" class="ql-underline"></button>
          <button title="Strike" class="ql-strike"></button>
          <button title="Code" class="ql-code-block"></button>
          <button class="ql-header" value="1"></button>
          <button class="ql-header" value="2"></button>
          <button class="ql-clean"></button>
          <button class="ql-link"></button>
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
        </span>
        <span class="ql-formats d-flex flex-wrap">
          <button (click)="insertScreenshot()" *ngIf="is_screenshot_button_enabled" [disabled]="!player" class="custom-sc-btn screenshotBorder d-flex mr-2"
                  matTooltipPosition="above"
                  matTooltip="{{'Choose a specific timestamp in the video player and click here to insert a screenshot'| translate}}"
                  style="width:inherit">
            <mat-icon class="sc-icon material-icons-outlined mr-1"
                      matSuffix style="color: black !important; line-height: unset;">
                photo_camera
            </mat-icon>
            <span class="text-nunito pr-1">Screenshot</span>
          </button>
          <button *ngIf="video_object.attachment == null" class="d-flex align-items-center custom-sc-btn"
                  matTooltipPosition="above"
                  matTooltip="{{'Attach file' | translate}}">
            <label for="file-upload" class="mb-0">
              <div class="d-flex justify-content-center w-100 align-items-center">
                <mat-icon class="material-icons-outlined mr-1">attach_file</mat-icon>
              </div>
            </label>
            <input (change)="attachFile($event)"
              accept=".pdf,.jpg,.png,.ppt,.pptx,.docx,.csv,.doc,.jpeg,.xlsx,.xls,.txt" hidden id="file-upload"
              type="file" />
          </button>
          <a [href]="video_object.attachment" class="text-nunito" *ngIf="video_object.attachment" target="_blank">{{attached_file_name}}
          </a>
          <button mat-icon-button matSuffix  *ngIf="video_object.attachment" (click)="deleteAttachment();"
          matTooltip="{{'Delete attachment'|translate}}" class="d-flex align-items-center pt-0">
             <mat-icon>delete_outline</mat-icon>
           </button>

        </span>
      </div>
    </quill-editor>


    <!--auto create tags-->
    <div class="w-100 mt-4"
         *ngIf="authService.company.is_transcription_service_enabled">
      <button (click)="autoCreateKeywords();"
              matTooltip="{{'Automatically create keywords from description'|translate}}"
              matTooltipPosition="above"
              class="pl-0"
              color="primary"
              mat-button
              matSuffix>
        <mat-icon class="material-icons-outlined mr-1">auto_fix_high</mat-icon>
        <span translate>Auto-create keywords</span>
      </button>
    </div>

    <!--tags-->
    <div class="w-100 mb-4" style="margin-top: -12px;">
      <mat-form-field class="w-100 line-height-initial" appearance="standard">
        <mat-chip-list #chipList aria-label="keyword selection">
          <mat-chip *ngFor="let tag of selected_tags" (removed)="removeTag(tag)">
            {{tag.name}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <input type="text"
                 [autofocus]="false"
                 placeholder="{{'New keyword…'|translate}}"
                 aria-label="new keyword"
                 matInput
                 #tagInput
                 (focusin)="is_text_area_active=true;"
                 (focusout)="is_text_area_active=false;"
                 [formControl]="tagCtrl"
                 [matAutocomplete]="auto"
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 (matChipInputTokenEnd)="addTagByName($event.value)">
        </mat-chip-list>
        <mat-hint class="text-nunito" translate>Separate keywords with a comma</mat-hint>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let tag of filtered_tags | async" [value]="tag" (onSelectionChange)="addTagByObject(tag);">
            {{tag.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
</div> <!--end of main content block-->
