import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DataService} from '../services/data.service';
import {HttpErrorResponse} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../services/auth.service";

interface PopupData {
  system: string,
  user: string,
  assistant: string,
  file_search: File,
  auto_close: boolean,
}

@Component({
  selector: 'app-completion-popup',
  templateUrl: './completion-popup.component.html',
  styleUrls: ['./completion-popup.component.scss']
})
export class CompletionPopupComponent implements OnInit {

  show_spinner: boolean = false;
  include_assistant_message: boolean = false;  // user can disable it
  languages: string[] = [];
  limits: string[] = [];
  audience: string[] = [];

  current_language: string = "";
  current_limit: string = "";
  current_audience: string = "";

  response_text: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: PopupData,
              private dataService: DataService,
              private authService: AuthService,
              private translateService: TranslateService,
              public dialogRef: MatDialogRef<CompletionPopupComponent>) {
    for (let i of this.authService.languages.entries()) {
      this.languages.push(i[1]);
    }

    this.limits.push(this.translateService.instant('in upto 10 sentences'));
    this.limits.push(this.translateService.instant('in upto 100 words'));
    this.limits.push(this.translateService.instant('in upto 1000 words'));

    this.audience.push(this.translateService.instant('in simple language'));
    this.audience.push(this.translateService.instant('for consumers'));
    this.audience.push(this.translateService.instant('for students'));
    this.audience.push(this.translateService.instant('for research scientists'));
    this.audience.push(this.translateService.instant('for corporate employees'));

    if (this.data.assistant) {
      this.include_assistant_message = true;
    }
  }

  ngOnInit(): void {
    if (this.data.auto_close) {
      this.getResult();
    }

    // if backdrop click is disabled, then confirm before closing
    if (this.dialogRef.disableClose) {
      this.dialogRef.backdropClick().subscribe(() => {
        this.closeDialog();
      });
    }
  }

  getResult() {
    if (this.data.system.length == 0) {
      this.data.system = this.translateService.instant("Generate with AI");
    }

    if (this.data.user.length == 0) {
      document.getElementById('user-input').focus();
      return;
    }

    if (this.current_language) {
      this.data.system += ` in ${this.current_language}`;
    }

    if (this.current_limit) {
      this.data.system += ` ${this.current_limit}`;
    }

    if (this.current_audience) {
      this.data.system += ` ${this.current_audience}`;
    }

    const formData = new FormData();
    formData.append('user', this.data.user);  // user message is needed
    // assistant message is optional, it does not apply for file search, but applies for image search
    if (this.include_assistant_message) {
      formData.append('assistant', this.data.assistant);
    } else {
      // BE expects assistant message, even if it is empty
      formData.append('assistant', '');
    }
    // system message is needed
    formData.append('system', this.data.system);
    if (this.data.file_search) {
      // check if image or something else
      if (this.data.file_search.type.includes('image')) {
        // image: attach to another key
        formData.append('image_search', this.data.file_search, this.data.file_search.name);
      } else {
        // pdf or presentation
        formData.append('file_search', this.data.file_search, this.data.file_search.name);
      }
    }

    this.show_spinner = true;
    this.dataService.postURL('user/clyppai/', formData,
      {observe: 'body', responseType: 'text'}).subscribe((res: string) => {
      this.show_spinner = false;
      this.response_text = res.replace(/[\\*]{2,}/g,'');  // remove all two or more stars
      this.closeDialog();
    }, (err: HttpErrorResponse) => {
      this.show_spinner = false;
      window.alert(err.error);
    })
  }

  closeDialog() {
    if (this.show_spinner) {
      // ask user before closing
      const message = this.translateService.instant('Bist du sicher?');
      const confirmed = window.confirm(message);
      if (confirmed) {
        // ok
        this.dialogRef.close(undefined);
      } else {
        // keep the spinner running, continue the process
      }
    } else {
      // no process running
      this.dialogRef.close(this.response_text);
    }
  }

  attachFile(event) {
    const file: File = (event.target as HTMLInputElement).files[0];
    const maxSize: number = 10000000; // 10 MB
    if (file.size > maxSize) {
      // check file
      let message = this.translateService.instant('Limit Exceeded: ');
      message += `${Math.floor(file.size / 1000000)} MB / ${maxSize / 1000000} MB`
      window.alert(message);
      return;
    }

    if (file.type.includes('pdf') || file.type.includes('presentation') || file.type.includes('msword') ||
      file.type.includes('officedocument')) {
      // officedocument: docx, msword: doc
      this.data.system = this.translateService.instant("Complete the tasks based on the file provided to you in the original language");
      // this.data.user = this.translateService.instant("Briefly explain each page in this file");
    } else if (file.type.includes('image')) {
      this.data.system = this.translateService.instant("Complete the tasks based on the image provided to you");
      // this.data.user = this.translateService.instant("Briefly explain this image");
    } else {
      console.log(file.type);
      let message = this.translateService.instant('File not supported');
      window.alert(message);
      return;
    }
    this.data.file_search = file;
    // disable assistant
    this.include_assistant_message = false;
  }
}
