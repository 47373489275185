import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-fe-banners',
  templateUrl: './fe-banners.component.html',
  styleUrls: ['./fe-banners.component.scss']
})
export class FeBannersComponent {

  constructor(public authService: AuthService, public translateService: TranslateService) {
  }

}
