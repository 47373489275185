<div *ngIf="disable_settings_message" class="hide-settings-overlay">
    <h2 class="text-nunito">
      {{disable_settings_message}}
    </h2>
</div>

<div class="w-100 d-flex justify-content-end">
  <!--navigation-->
  <div class="navigation-content">
    <nav class="h-auto">
      <a (click)="scrollTo(link.id);"
         *ngFor="let link of section_links"
         [style.color]="link.active ? 'black' : 'gray'"
         class="navigation-link p-3 text-nunito d-flex align-items-center">
         <mat-icon class="material-icons-outlined mr-2">{{link.icon}}</mat-icon>
        {{link.name}}
      </a>
    </nav>
  </div>

  <!--main content-->
  <div class="company-form-content p-3">
    <form [formGroup]="settings">
      <section class="p-3" id="workspace" data-attr="app_registration">
        <h2 class="fontstyle" translate>Workspace Design</h2>
        <hr>

        <!--organization name-->
        <div class="mt-2">
          <span class="labelText" translate>
            Workspace name
          </span>

          <mat-form-field class="labelValue">
            <input autocomplete="off" autofocus="false" formControlName="name" matInput maxlength="100"
                   placeholder="My workspace" required type="text"/>
            <mat-hint class="text-nunito font-size-14">
              {{'This also appears on your'|translate}}
              <a target="_blank" [href]="'/public/'+companyDetails?.id">{{'public page'|translate}}</a>
            </mat-hint>
          </mat-form-field>
        </div>

        <!--organization logo-->
        <div class="mt-3">
          <span class="labelText" translate>
            Workspace logo
          </span>

          <mat-form-field class="labelValue">
            <ngx-mat-file-input (change)="attachvalue($event,'company_logo')" [accept]="'.jpg, .png, .gif'"
                                [multiple]="false" formControlName="company_logo"
                                valuePlaceholder="{{'Hier hochladen' | translate}} (png, jpg, gif)">
            </ngx-mat-file-input>
            <mat-error *ngIf="settings.get('company_logo').hasError('maxContentSize')">
                <span
                  [translateParams]="{val: settings.get('company_logo')?.getError('maxContentSize').maxSize | byteFormat }"
                  [translate]="'max_file_Size'"></span>
            </mat-error>
            <mat-hint class="text-nunito font-size-14">
              <b>{{'Currently'|translate}}:&nbsp;</b>
              <a [href]="companyDetails?.company_logo" target="_blank">{{attachmentName.company_logo}}</a>
              <br>
              {{'This appears on the top left.'|translate}}
            </mat-hint>
          </mat-form-field>
        </div>

        <!--chip-->
        <div class="mt-4">
          <span class="labelText" translate>
            Custom phrases
          </span>
          <mat-form-field appearance="standard" class="labelValue">
            <mat-chip-list #chipList>
              <mat-chip (removed)="removePhrase(phrase)" *ngFor="let phrase of phrases">
                {{phrase}}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
              <input (matChipInputTokenEnd)="addPhrase($event)" [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     placeholder="{{'New phrase…' | translate}}">
            </mat-chip-list>
            <mat-hint class="text-nunito font-size-14" translate>
              Add specific words and phrases to improve transcriptions. Separate by comma or Enter.
            </mat-hint>
          </mat-form-field>
        </div>


        <!--site notice-->
        <div class="mt-3">
          <span class="labelText" translate>
            Site notice
          </span>

          <mat-form-field class="labelValue">
            <input autocomplete="off" autofocus="false" formControlName="site_notice" matInput maxlength="200"
                   placeholder="Announcement" type="text"/>
            <mat-hint class="text-nunito font-size-14" translate>
              This appears on your start page. You can share new features here.
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="mt-3">
          <span class="labelText" translate>
            Additional privacy policy
          </span>

          <mat-form-field class="labelValue">
            <input autocomplete="off" autofocus="false" formControlName="custom_privacy_url" matInput
                     maxlength="200"
                     placeholder="https://yourcompany.de/privacy/" type="text"/>
            <mat-hint class="text-nunito font-size-14" translate>
              (Optional) Users need to accept this additional policy to access the Clypp platform (provide a URL, e.g., to a PDF)
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="mt-5 d-flex" style="flex-flow: wrap">
          <span class="labelText" translate>
            Verfügbare Domain-Namen
          </span>

          <div class="labelValue d-flex" style="flex-flow: wrap;">
            <span class="text-nunito w-75">{{companyDetails?.domain_names}}</span>
            <button (click)="openFeedbackDialog();"
                    class="border-radius-20"
                    mat-stroked-button>
              {{'Contact Support'|translate}}
            </button>
            <mat-hint class="text-nunito font-size-14 w-100" translate>
              Users with this email-address can sign-up in your company
            </mat-hint>
          </div>
        </div>

        <div class="mt-2">
          <span class="labelText" translate>
            Primary color
          </span>
          <mat-form-field class="w-25 labelValue" style="min-width: 300px;">
              <input (change)="primaryColorChangeEnded();" (input)="primaryColorChangeStarted($event);"
                     formControlName="primary_color" id="boxColor" matInput placeholder="#000000"
                     type="color">
            <mat-hint class="text-nunito font-size-14" translate>
              Color customisation for buttons, etc.
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="mt-2">
          <span class="labelText" translate>
            First level
          </span>
          <mat-form-field class="w-25 labelValue" style="min-width: 300px;">
              <input autocomplete="off" autofocus="false" formControlName="dept_alias" matInput maxlength="100"
                     placeholder="Department" required type="text"/>
            <mat-hint class="text-nunito font-size-14" translate>
              Label for first organization level (e.g. Department, Class, Block)
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="mt-3">
          <span class="labelText" translate>
            Second level
          </span>
          <mat-form-field class="w-25 labelValue" style="min-width: 300px;">
              <input autocomplete="off" autofocus="false" formControlName="team_alias" matInput maxlength="100"
                     placeholder="Team" required type="text"/>
            <mat-hint class="text-nunito font-size-14" translate>
              Label for second organization level (e.g. Team, Section, Row)
            </mat-hint>
          </mat-form-field>
        </div>
      </section>


      <section class="p-3 mt-5" id="clypps" data-attr="video_settings">
        <h2 class="fontstyle w-25 d-inline-block" translate>Content Design</h2>
        <button (click)="openProfileDialog();" color="primary" class="border-radius-20 text-color" mat-stroked-button>
          <mat-icon class="material-icons-outlined mr-1">settings_suggest</mat-icon>
          {{'Manage'|translate}}
        </button>
        <hr>

        <!--intro outro-->
        <div class="mt-3">
          <span class="labelText" translate>
            Intro und Outro
          </span>
          <mat-form-field class="labelValue w-25" style="min-width: 300px;">
            <mat-select formControlName="ci_video_code">
              <mat-option *ngFor="let creditOption of creditDropdown" [value]="creditOption.key">
                {{creditOption.value}}
              </mat-option>
            </mat-select>
            <mat-hint class="text-nunito font-size-14" translate>
            Kann automatisch vor bzw. hinter jedem erstellten Video erzeugt werden
          </mat-hint>
          </mat-form-field>
        </div>

        <!--watermark-->
        <div class="mt-5 d-flex" style="flex-flow: wrap;">
          <span class="labelText mt-2" translate>
            Watermark position
          </span>
          <div class="labelValue">
            <mat-button-toggle-group formControlName="overlay_code">
              <mat-button-toggle value="no">
                <mat-icon>block</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="tl">
                <mat-icon
                  style="transform: rotate(180deg);">picture_in_picture_alt
                </mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="tr">
                <mat-icon>picture_in_picture</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="bl">
                <mat-icon
                  style="transform: rotate(180deg);">picture_in_picture
                </mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="br">
                <mat-icon>picture_in_picture_alt</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
            <span class="ml-4" style="vertical-align: middle;">
              <mat-icon (click)="openWatermarkDialog()"
                        matSuffix
                        matTooltipPosition="right"
                        matTooltip="{{'Preview'| translate}}">
                preview
              </mat-icon>
            </span>
          </div>
        </div>

        <!--overlay logo-->
        <div class="mt-3">
          <span class="labelText" translate>
            Watermark on Clypps
          </span>

          <mat-form-field class="labelValue">
            <ngx-mat-file-input (change)="attachvalue($event,'overlay_logo')" [accept]="'.jpg, .png'"
                                [multiple]="false" formControlName="overlay_logo"
                                valuePlaceholder="{{'Hier hochladen' | translate}} (png, jpg)">
            </ngx-mat-file-input>
            <mat-error *ngIf="settings.get('overlay_logo').hasError('maxContentSize')">
                <span
                  [translateParams]="{val: settings.get('overlay_logo')?.getError('maxContentSize').maxSize | byteFormat }"
                  [translate]="'max_file_Size'"></span>
            </mat-error>
            <mat-hint class="text-nunito font-size-14">
              <b>{{'Currently'|translate}}:&nbsp;</b>
              <a [href]="companyDetails?.overlay_logo" target="_blank">{{attachmentName.overlay_logo}}</a>
              <br>
              {{'This appears on the Clypps after processing.'|translate}}
            </mat-hint>
          </mat-form-field>
        </div>

        <!--background image-->
        <div class="mt-4">
          <span class="labelText" translate>
            Kundenspezifischer Hintergrund
          </span>

          <mat-form-field class="labelValue">
            <ngx-mat-file-input (change)="attachvalue($event,'bg_image')" [accept]="'.jpg, .png'"
                                [multiple]="false" formControlName="bg_image"
                                valuePlaceholder="{{'Hier hochladen' | translate}} (png, jpg)">
            </ngx-mat-file-input>
            <mat-error *ngIf="settings.get('bg_image').hasError('maxContentSize')">
                <span
                  [translateParams]="{val: settings.get('bg_image')?.getError('maxContentSize').maxSize | byteFormat }"
                  [translate]="'max_file_Size'"></span>
            </mat-error>
            <mat-hint class="text-nunito font-size-14">
              <b>{{'Currently'|translate}}:&nbsp;</b>
              <a [href]="companyDetails?.bg_image" target="_blank">{{attachmentName.bg_image}}</a>
              <br>
              {{'The image should have an aspect ratio of 16:9 (at least 1920p x 1080p). It is displayed in the background of a new Clypp to ensure a 16:9 layout of video recordings'|translate}}
            </mat-hint>
          </mat-form-field>
        </div>

        <!--intro video-->
        <div class="mt-5">
          <span class="labelText" translate>
            Intro-Video
          </span>

          <mat-form-field class="labelValue">
            <ngx-mat-file-input (change)="attachvalue($event,'intro_video')" [accept]="'.mp4, .mov'"
                                [multiple]="false" formControlName="intro_video"
                                valuePlaceholder="{{'Hier hochladen' | translate}} (mp4, mov)">
            </ngx-mat-file-input>
            <mat-error *ngIf="settings.get('intro_video').hasError('maxContentSize')">
                <span
                  [translateParams]="{val: settings.get('intro_video')?.getError('maxContentSize').maxSize | byteFormat }"
                  [translate]="'max_file_Size'"></span>
            </mat-error>
            <mat-hint class="text-nunito font-size-14">
              <b>{{'Currently'|translate}}:&nbsp;</b>
              <a [href]="companyDetails?.intro_video" target="_blank">{{attachmentName.intro_video}}</a>
              <br>
              {{'Appended to the beginning after video processing'|translate}}
            </mat-hint>
          </mat-form-field>
        </div>

        <!--outro video-->
        <div class="mt-4">
          <span class="labelText" translate>
            Outro-Video
          </span>

          <mat-form-field class="labelValue">
            <ngx-mat-file-input (change)="attachvalue($event,'outro_video')" [accept]="'.mp4, .mov'"
                                [multiple]="false" formControlName="outro_video"
                                valuePlaceholder="{{'Hier hochladen' | translate}} (mp4, mov)">
            </ngx-mat-file-input>
            <mat-error *ngIf="settings.get('outro_video').hasError('maxContentSize')">
                <span
                  [translateParams]="{val: settings.get('outro_video')?.getError('maxContentSize').maxSize | byteFormat }"
                  [translate]="'max_file_Size'"></span>
            </mat-error>
            <mat-hint class="text-nunito font-size-14">
              <b>{{'Currently'|translate}}:&nbsp;</b>
              <a [href]="companyDetails?.outro_video" target="_blank">{{attachmentName.outro_video}}</a>
              <br>
              {{'Appended to the end after video processing'|translate}}
            </mat-hint>
          </mat-form-field>
        </div>


        <div class="mt-4">
          <span class="labelText" translate>
            Font for Text Overlays
          </span>

          <mat-form-field class="labelValue">
            <ngx-mat-file-input (change)="attachvalue($event,'font_file')" accept=".ttf, .otf"
                                [multiple]="false" formControlName="font_file"
                                valuePlaceholder="{{'Hier hochladen' | translate}} (ttf, otf)">
            </ngx-mat-file-input>
            <mat-error *ngIf="settings.get('font_file').hasError('maxContentSize')">
                <span
                  [translateParams]="{val: settings.get('font_file')?.getError('maxContentSize').maxSize | byteFormat }"
                  [translate]="'max_file_Size'"></span>
            </mat-error>
            <mat-hint class="text-nunito font-size-14">
              <b>{{'Currently'|translate}}:&nbsp;</b>
              <a [href]="companyDetails?.font_file" target="_blank">{{attachmentName.font_file}}</a>
              <br>
              {{'Upload a font style, which shall be used for text overlays in the video creation process'|translate}}
            </mat-hint>
          </mat-form-field>
        </div>
      </section>


      <section class="p-3 mt-5" id="partners" data-attr="workspaces">
        <h2 class="fontstyle" translate>Partner workspaces</h2>
        <hr>
        <app-partner-organizations></app-partner-organizations>
      </section>

      <!--section 4-->
      <section id="security" data-attr="security" class="p-3 mt-5" style="padding-bottom: 6rem !important;">
        <h2 class="fontstyle" translate>Security</h2>
        <hr>

        <div class="d-flex" style="flex-flow: wrap;">
          <div class="workspace-security-block">
            <mat-slide-toggle matTooltip="{{'Neue Nutzer/innen können sofort Inhalte sehen, die den ausgewählten Abteilungen/Teams zugeordnet sind ' | translate}}"
                              matTooltipPosition="above"
                              class="m-2"
                              formControlName="auto_approve_user">
              <span class="text-nunito" translate>
                Automatische Zugehörigkeitsprüfung
              </span>
            </mat-slide-toggle>

            <mat-slide-toggle matTooltip="{{'If active, any user in your organization can upgrade their own profile to become a Creator (as long as creator rights are available)' | translate}}"
                              matTooltipPosition="above"
                              class="m-2"
                              formControlName="auto_creator_user">
              <span class="text-nunito" translate>
                Allow anyone to become a Creator
              </span>
            </mat-slide-toggle>

            <mat-slide-toggle matTooltip="{{'If unchecked, existing comments will be hidden and new comments will be blocked' | translate}}"
                              matTooltipPosition="above"
                              class="m-2"
                              formControlName="is_comment_feature_enabled">
              <span class="text-nunito" translate>
                Allow users to comment on Clypps
              </span>
            </mat-slide-toggle>

            <mat-slide-toggle matTooltip="{{'We may use third-party applications to provide AI services. You can find the complete list in our privacy policy.' | translate}}"
                              matTooltipPosition="above"
                              class="m-2"
                              formControlName="is_transcription_service_enabled">
              <span class="text-nunito" translate>
                Allow AI services with servers in EU
              </span>
            </mat-slide-toggle>

            <mat-slide-toggle matTooltip="{{'If disabled, Clypps from partner workspaces would only appear in search results and groups tab.' | translate}}"
                              matTooltipPosition="above"
                              class="m-2"
                              formControlName="is_partner_content_shown">
              <span class="text-nunito" translate>
                Show partner content on homepage
              </span>
            </mat-slide-toggle>
          </div>

          <div class="workspace-security-block">
            <mat-slide-toggle matTooltip="{{'Content is not approved automatically (Exception: Content of users with management rights)' | translate}}"
                              matTooltipPosition="above"
                              class="m-2"
                              formControlName="is_strict_quality_control">
              <span class="text-nunito" translate>
                Do not auto-approve content
              </span>
            </mat-slide-toggle>

            <mat-slide-toggle matTooltip="{{'Content can no longer be shared with people outside the organization through external links' | translate}}"
                              matTooltipPosition="above"
                              class="m-2"
                              (change)="externalSharingToggled($event)"
                              formControlName="is_external_sharing_disabled">
              <span class="text-nunito" translate>
                Disallow sharing content externally
              </span>
            </mat-slide-toggle>

            <mat-slide-toggle matTooltip="{{'If deactivated, the number of views on public content is hidden' | translate}}"
                              matTooltipPosition="above"
                              class="m-2"
                              formControlName="is_external_views_shown">
              <span class="text-nunito" translate>
                Show number of views on public pages
              </span>
            </mat-slide-toggle>

            <mat-slide-toggle matTooltip="{{'Personen außerhalb der Organisation können nicht mehr zu Gruppen hinzugefügt werden und nicht mehr deren Inhalte ansehen' | translate}}"
                              matTooltipPosition="above"
                              class="m-2"
                              formControlName="is_external_group_disabled">
              <span class="text-nunito" translate>
                Disallow external group members
              </span>
            </mat-slide-toggle>

            <mat-slide-toggle matTooltip="{{'Attention! Do not activate this option without adding your own IP in the table below.' |translate}}"
                              matTooltipPosition="above"
                              class="m-2"
                              formControlName="is_ip_restriction_enabled">
              <span class="text-nunito" (click)="openIPAddressDialog($event);" translate>
                Restrict login to Clypp to specific&nbsp;
                <span class="ip_addr" translate>IP Addresses</span>
              </span>
              <mat-icon class="ml-1"
                        mat-icon
                        style="vertical-align: bottom;">
                warning_amber
              </mat-icon>
            </mat-slide-toggle>
          </div>
        </div>

        <!--show and translate; number input: n_token_valid_days => min = 7, max = 365-->
        <div class="m-2">
          <mat-form-field class="w-50 labelValue" style="min-width: 300px;" appearance="outline">
            <mat-label translate>User token validity</mat-label>
            <mat-select formControlName="n_token_valid_days">
              <mat-option [value]="1">
                <span translate>1 day</span>
              </mat-option>
              <mat-option [value]="7">
                <span translate>7 days</span>
              </mat-option>
              <mat-option [value]="30">
                <span translate>30 days</span>
              </mat-option>
              <mat-option [value]="180">
                <span translate>180 days</span>
              </mat-option>
              <mat-option [value]="365">
                <span translate>1 year</span>
              </mat-option>
            </mat-select>
            <mat-hint class="text-nunito font-size-14" translate>
              Clypps issues an authentication token for each user.
              Select the duration it is valid for members in your workspace.
            </mat-hint>
          </mat-form-field>
        </div>
      </section>
    </form>
  </div>
</div>

<!--footer-->
<mat-toolbar *ngIf="settings.dirty" class="toolbar-footer justify-content-center">
  <div class="fontStyle mr-3 pl-4 d-none d-sm-inline" translate>
    Unsaved changes
  </div>
  <button (click)="discardChanges()" class="blocked" mat-stroked-button>
    {{ "Discard" | translate }}
  </button>
  <button (click)="saveSettings()"
          [disabled]="settings.invalid"
          class="ml-3" color="primary"
          mat-flat-button>
    {{'Speichern' | translate}}
  </button>
</mat-toolbar>
