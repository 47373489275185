import {
  Component, Injectable, Input, OnInit, AfterViewInit, Output, ElementRef, ViewChild,
  ViewEncapsulation,
  OnChanges,
  Inject
} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material/dialog";
import {AuthService} from '../../services/auth.service';
import {DataService} from '../../services/data.service';
import {UntypedFormBuilder, ReactiveFormsModule, UntypedFormControl, FormGroup, Validators, UntypedFormArray} from '@angular/forms';
import {MatSnackBar} from "@angular/material/snack-bar";
import {EventEmitter} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatTooltip} from '@angular/material/tooltip';
import {MatChipInputEvent} from '@angular/material/chips';
import {TranslateService} from '@ngx-translate/core';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';


@Component({
  selector: 'app-restricted-selection-dialog',
  templateUrl: './restricted-selection-dialog.component.html',
  styleUrls: ['./restricted-selection-dialog.component.scss']
})


export class RestrictedSelectionDialogComponent implements OnInit {


  @ViewChild('usersInput') usersInput: ElementRef<HTMLInputElement>;
  // @ViewChild('auto') matAutocomplete: MatAutocomplete;

  restrictValue: any = [];
  userChecklist: any;

  val = 'testing';
  closeButton: boolean = false;
  closeTooltip: string;
  clearBtn: boolean = false;

  @Input('userId') userId: any;

  filteredUsers: Observable<any[]>;
  filteredOptions: Observable<any>
  selectedUser: any = [];
  usersCtrl = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  users_array: any = [];
  users_id = [];
  user_valueId = [];
  users_list: any = [];
  checkValue: any = [];
  overallArrayValue: any = [];
  chipvalue: any;
  usersResponse: any;
  index: number;
  tag: any;
  depts_id = [];
  teams_id = [];
  locations_id = [];
  defaultMessage: string;
  groups_id = [];


  redirectForm = this.formBuilder.group({
    users: [[]],
    teams: [[]],
    departments: [[]],
    locations: [[]],
    groups: [[]]
  })
  overallForm = this.formBuilder.group({
    overall: [[]]
  })
  overallValueFormat: any = [];
  userslistMeta: any = [];
  overall_id: any = [];
  userList: any = [];
  userlistArray: any = [];
  restrictionValue: any = [];
  groupId: any;
  showSaveBtn: boolean = false;
  deletedHimself = false;
  title: any;

  constructor(private dialog: MatDialog, private translateService: TranslateService, private formBuilder: UntypedFormBuilder, private snackBar: MatSnackBar, public dataService: DataService, public authService: AuthService, private fb: UntypedFormBuilder, public dialogRef: MatDialogRef<RestrictedSelectionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.showSaveBtn = data.showBtn;
    this.restrictValue = data.checklist;
    this.userslistMeta = data.userlist;
    this.groupId = data.group;
    this.userlistArray = this.userslistMeta;
    this.userChecklist = this.restrictValue;
    if (data.message != undefined) {
      this.defaultMessage = data.message;
      this.closeTooltip = this.translateService.instant('Aus Gruppe entfernen');

    }
    this.translateService.onLangChange.subscribe((event) => {
      this.defaultMessage = this.translateService.instant('Achtung:<br>Wenn du hier nichts auswählst, kannst nur du dieses Video innerhalb deiner Organisation sehen');
    })
    this.defaultMessage = this.translateService.instant('Achtung:<br>Wenn du hier nichts auswählst, kannst nur du dieses Video innerhalb deiner Organisation sehen');
  }

  get users(): UntypedFormArray {
    return this.redirectForm.get('users') as UntypedFormArray;
  }

  set users(formControl: UntypedFormArray) {
    this.redirectForm.setControl('users', formControl);
  }

  ngOnInit(): void {

    this.val = "testing";
    let usersFinalList = [];

    this.userValue(this.groupId);
    this.filteredUsers = this.usersCtrl.valueChanges.pipe(
      startWith(''),
      map(value => value ? this._filter(value) : this.userChecklist.slice())
    );

    for (let i in this.userChecklist) {
      this.users_array.push(this.userChecklist);
    }
    if (this.userId != null) {
      this.users_id = JSON.parse(JSON.stringify(this.userId));

      for (let i in this.userChecklist) {

        if (this.userId.includes(this.userChecklist[i]['id'])) {
          this.users_list.push(this.userChecklist);

        }
      }
    }

  }

  userValue(init = true) {
    this.userlistArray.map((obj) => {
      if (Object.keys(this.userslistMeta).length > 0) {
        this.selectedusers(obj, init);
        // return obj;
      }
    })
  }

  onKey(value: string) {
    this.selectedUser = this.search(value);
  }

  search(value: string) {
    let filter = this.userChecklist.filter(item =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    return [...filter];
  }

  removeUserTag(user: any): void {
    this.showSaveBtn = true;
    const index = this.users_list.indexOf(user);
    if (index >= 0) {
      for (let i in this.userChecklist) {
        if (this.userChecklist[i]['name'] == user.name) {
          const overallArray = this.overall_id.indexOf(user.id);
          this.overall_id.splice(overallArray, 1);
          if (this.userChecklist[i]['type'] == 'team') {
            const indexArray = this.teams_id.indexOf(user.id);
            this.users_list.splice(index, 1);
            this.teams_id.splice(indexArray, 1);
            break;
          }
          if (this.userChecklist[i]['type'] == 'dept') {
            const deptArray = this.depts_id.indexOf(user.id);
            this.users_list.splice(index, 1);
            this.depts_id.splice(deptArray, 1);
            break;
          }
          if (this.userChecklist[i]['type'] == 'user') {
            const userArray = this.users_list.indexOf(user.id);
            this.users_list.splice(index, 1);
            this.users_id.splice(userArray, 1);
            break;
          }
          if (this.userChecklist[i]['type'] == 'group') {
            const groupArray = this.groups_id.indexOf(user.id);
            this.users_list.splice(index, 1);
            this.groups_id.splice(groupArray, 1);
            break;
          } else {
            const locationArray = this.locations_id.indexOf(user.id);
            this.users_list.splice(index, 1);
            this.locations_id.splice(locationArray, 1);
            break;
          }

        }
      }
      if (this.groupId) {
        this.dataService.putURL(`user/groups/${this.groupId}/`, {
          id: user.id,
          action: 'remove'
        }).subscribe((data: any) => {
          // Already spliced in the block of type == user
          //this.users_list.splice(index, 1);
          if (data == null) {
            //Code: 204 group has been deleted
            this.snackBar.open(this.translateService.instant('Die Gruppe wurde gelöscht'), '', {duration: 2500});
          } else {
            this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
          }
          let currentUser = parseInt(this.authService.currentUserId);
          if (user.id == currentUser) {
            this.deletedHimself = true;
          }
        });
      }
    }

    this.overallValueFormat = this.users_list;
    this.overallForm.get('overall').setValue(this.users_list);

    this.redirectForm.get('users').setValue(this.users_id);
    this.redirectForm.get('departments').setValue(this.depts_id);
    this.redirectForm.get('teams').setValue(this.teams_id);
    this.redirectForm.get('locations').setValue(this.locations_id);
    this.redirectForm.get('groups').setValue(this.groups_id);
  }

  _filter(value: string): string[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.userChecklist.filter(option => option.name.toLowerCase().includes(filterValue)
        || option.string.toLowerCase().includes(filterValue)
      );
    }

  }

  callOnSelect(group, action = "add") {
    if (action == "make_admin") {
      group.is_admin = true;
    } else if (action == "remove_admin") {
      if (group.id == this.authService.currentUserId) {
        let confirmation = _('Are you sure you want to remove yourself as a group administrator?');
        if (confirm(this.translateService.instant(confirmation))) {
          group.is_admin = false;
        } else {
          return;
        }
      } else {
        group.is_admin = false;
      }
    }
    this.dataService.putURL(`user/groups/${this.groupId}/`, {id: group.id, action}, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((data: any) => {
        this.usersInput.nativeElement.value = '';
        if (data['response'] == 'failure') {
          // this.snackBar.open(data['reason'], '', { duration: 2500 });
          this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2500}
        } else {
          this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
          if (group.id == this.authService.currentUserId && action == "remove_admin") {
            this.closeDialog();
          }
        }
      },
      (error) => {
        // handle error
        this.snackBar.open(
          this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2500}
        );
      });
  }

  selectedusers(tag, init) {
    this.showSaveBtn = true;
    let currentUser = parseInt(this.authService.currentUserId);
    if (tag.id == currentUser) {
      this.deletedHimself = false;
    }
    if (this.groupId && init == false) {
      this.callOnSelect(tag);
      tag = {...tag, is_admin: false};
    }
    if (this.users_list.length == 0) {
      this.users_list.push(tag);
    } else {
      let result = this.users_list.filter(f =>
        f.id === tag.id && f.type === tag.type);

      if (result.length == 0) {
        this.users_list.push(tag);
      }
    }
    // if (!this.users_list.includes(tag)) {
    // this.users_list.push(tag);

    for (let i in this.userChecklist) {

      if (tag.name == this.userChecklist[i]['name']) {
        this.overall_id.push(this.userChecklist[i]);
        if (this.userChecklist[i]['type'] == 'team') {
          this.teams_id.push(this.userChecklist[i]['id']);
          break;
        }
        if (this.userChecklist[i]['type'] == 'dept') {
          this.depts_id.push(this.userChecklist[i]['id']);
          break;
        }
        if (this.userChecklist[i]['type'] == 'user') {
          this.users_id.push(this.userChecklist[i]['id']);
          break;
        }
        if (this.userChecklist[i]['type'] == 'group') {
          this.groups_id.push(this.userChecklist[i]['id']);
          break;
        } else {
          this.locations_id.push(this.userChecklist[i]['id']);
          break;
        }
      }
    }
    // }

    this.overallValueFormat = this.overall_id;

    this.overallForm.get('overall').setValue(this.overall_id);
    this.redirectForm.get('teams').setValue(this.teams_id);
    this.redirectForm.get('departments').setValue(this.depts_id);
    this.redirectForm.get('users').setValue(this.users_id);
    this.redirectForm.get('locations').setValue(this.locations_id);
    this.redirectForm.get('groups').setValue(this.groups_id);

    this.usersCtrl.setValue(null);

  }

  closeDialog() {
    this.closeButton = true;
    this.dialogRef.close({
      close: false,
      // formvalue: this.redirectForm.value,
      // overall: this.overallForm.value,
      // deletedHimself: this.deletedHimself
    });
  }

  submitRestrict() {
    this.closeButton = false;
    this.dialogRef.close({
      close: true,
      formvalue: this.redirectForm.value,
      overall: this.overallForm.value
    });
  }

  clearRestrict(){
    this.users_list = [];
    this.teams_id = [];
    this.depts_id = [];
    this.locations_id = [];
    this.overall_id = [];
    this.groups_id = [];
    this.users_id = [];
    this.dialogRef.close({
      close: false
    });
  }


}

export class users {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}
