import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import {DataService} from "../../services/data.service";
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';
import {UtilityService} from '../../services/utility.service';
import {environment} from '../../../environments/environment';
import {Activity, ActivityMap, ActivityDisplayData} from "../../models/activity.interface";
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {ChecklistItem} from "../../models/video/video.interface";
import { Chart } from 'chart.js';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-analytics-dashboard',
  templateUrl: './analytics-dashboard.component.html',
  styleUrls: ['./analytics-dashboard.component.scss']
})
export class AnalyticsDashboardComponent implements OnInit {
  n_creators: number = 0;
  n_users: number = 0;
  n_videos: number = 0;
  is_authorised: boolean = false;  // only company and global managers can see the data here
  german_labels: boolean = false;  // used for activities

  parameter: string = 'activities';
  window: string = '30D';

  displayedColumns: string[] = ['user', 'time'];
  currentPage: number = 0;
  resultsLength: number = 0;
  dataSource: MatTableDataSource<ActivityDisplayData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  activityJSONResp: ActivityMap[] = [];
  baseUrl = environment.baseURL;
  user_list: ChecklistItem[] = [];  // to store all users
  filter_user_id: number = 0;  // user filter
  filter_activity_code: string = "";  // activity filter
  activityFormControl = new FormControl();
  user_name_form_control = new FormControl();
  filteredOptions: Observable<ChecklistItem[]>;
  filteredActivities: Observable<ActivityMap[]>;
  lineCanvas: HTMLCanvasElement;

  constructor(private dataService: DataService,
              public authService: AuthService,
              private translateService: TranslateService,
              public utilityService: UtilityService) {
    if (this.authService.userDetails.is_company_manager || this.authService.userDetails.is_global_manager) {
      this.is_authorised = true;
    }
    if (this.translateService.currentLang == 'de') {
      this.german_labels = true;
    }
  }

  ngOnInit(): void {
    this.dataService.getURL<any>(`company/num-creators/`, {observe: 'body', responseType: 'json'}).subscribe((res) => {
      this.n_creators = res['creators'];
      this.n_users = res['users'];
      this.n_videos = res['videos'];
    });

    // call activity table and chart data only if the user is company or global manager
    if (this.is_authorised) {
      // to get the activity code JSON from assets
      this.dataService.getActivityJson().subscribe((res: ActivityMap[]) => {
        this.activityJSONResp = res;
        this.filteredActivities = this.activityFormControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterActivity(value || ''))
        );
      });

      this.activitiesChartAPI();
      this.recentActivities();
      this.loadChecklistUserData();
    }
  }

  //activity table API call
  recentActivities(page: number = 1) {
    let query: string = `manager/activities/?page=${page}`;
    // append user filter
    if (this.filter_user_id != 0) {
      query += `&user_id=${this.filter_user_id}`;
    }
    // append activity filter
    if (this.filter_activity_code != '') {
      query += `&activity_code=${this.filter_activity_code}`;
    }

    this.dataService.getURL<any>(query).subscribe((response) => {
      // mapping the recent activities response with the activity code JSON
      const data: Activity[] = response.data;
      this.currentPage = response.pageIndex - 1;
      const mapResult: ActivityDisplayData[] = data.map(el => {
        // create a basic structure
        let temp: ActivityDisplayData = {
          first_name: el.user.first_name,
          last_name: el.user.last_name,
          email: el.user.email,
          prefix: '',
          link_text: el.activity_code,
          suffix: '',
          supporting_text: '',
          href: '',
          created_on: el.created_on
        };
        // find the correct map
        const map = this.activityJSONResp.find(act => act.activity_code == el.activity_code);
        // if map is found, use data as per language
        if(map){
          if(this.german_labels){
            temp.prefix = map.prefix_de;
            temp.link_text = map.link_de;
            temp.suffix = map.suffix_de;
          }
          else{
            temp.prefix = map.prefix_en;
            temp.link_text = map.link_en;
            temp.suffix = map.suffix_en;
          }
          temp.supporting_text = map.hover ? el.supporting_text : "";
          if(map.link){
            // not empty
            temp.href = `${this.baseUrl}${map.link_to}`;
            temp.href += map.link == 'id' ?  `${el.id_link}` : el.uuid_link;
          }
        }
        return temp;
      });
      this.dataSource = new MatTableDataSource(mapResult);
      // this.dataSource.connect().next(mapResult);
      this.resultsLength = response.length;
    }, (err) => {
      window.alert(err.error.detail);
    });
  }

  //chart API call
  activitiesChartAPI() {
    this.dataService.getURL<any>(`manager/activities/date-wise/?window=${this.window}&parameter=${this.parameter}`)
      .subscribe((res) => {
        // remove existing canvas on dropdown change
        if (this.lineCanvas) {
          const existingChart = Chart.getChart(this.lineCanvas);
          existingChart.destroy();
        }
        const interval = setInterval(() => {
          // load after 1/2 a second, as html may not be ready
          this.loadCharts(res);
          clearInterval(interval);
        }, 500);
      }, (err) => {
        window.alert(err.error);
      }
    );
  }

  loadCharts(res) {
    Chart.defaults.font.family = "nunito";
    Chart.defaults.font.size = 14;

    this.lineCanvas = document.getElementById('canvas') as HTMLCanvasElement;
    const formattedLabels = res.map(label => {
      const date = new Date(label.date);
      return date.toLocaleDateString(navigator.language, { day: '2-digit', month: 'short', year: 'numeric' });
    });
    const chart = new Chart(this.lineCanvas, {
      type: "line",
      data: {
        labels: formattedLabels,
        datasets: [
          {
            data: res.map(x => x.value),
            fill: false,
            borderColor: "#f84848",
            borderWidth: 1,
            pointRadius: 4
          },
        ]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          y: {
            grid: {
              display: true
            },
          },
          x: {
            grid: {
              display: true
            },
            ticks: {
              maxTicksLimit: res.length > 300 ? 12 : 30,
              autoSkip: true
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
          }
        },
        elements: {
          line: {
            tension: 0
          }
        },
      }
    });
  }

  // activity selection change event
  activitySelect(event) {
    this.parameter = event.value;
    this.activitiesChartAPI();
  }

  //duration selection change event
  durationSelect(event) {
    this.window = event.value;
    this.activitiesChartAPI();
  }

  //pagination event
  updatePage(event): void {
    const new_page = event.pageIndex + 1;  // because it starts from 0
    this.recentActivities(new_page);
  }

  loadChecklistUserData() {
    // first, add default value
    this.user_list.push({
      id: 0,
      name: this.translateService.instant('All users'),
      string: this.translateService.instant('All users'),
      type: 'user'
    });
    // filter users from loaded data
    if (this.authService.checklist_data.length) {
      // data is already loaded
      this.user_list.push(...this.authService.checklist_data.filter((item) => item.type == 'user'));
    }
    else {
      // the API call is not yet complete, load local data and initiate the list
      // this may occur when user refresh the analytics page
      this.dataService.getURL<any>('user/company/checklist/').subscribe((resData: ChecklistItem[]) => {
        this.user_list.push(...resData.filter((item) => item.type == 'user'));
      });
    }

    // it might be possible that some users do not have FN LN
    // replace the name with email
    this.user_list.map(element => {
      if(element.name==""){
        element.name = element.string;  // this performs in-place editing
      }
    });

    // todo: this does not load all names if user refreshes on the activities page,
    //  because checklist above may not be ready
    this.filteredOptions = this.user_name_form_control.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || ''))
      )
  }

  private _filter(name: string): ChecklistItem[]{
    const filterValue = name.toLowerCase().trim();
    return this.user_list.filter(
      option => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  onActivitySelected(event: MatAutocompleteSelectedEvent) {
    this.filter_activity_code = event.option.value;
    this.recentActivities();
  }

  // displayFn method to return the value to display
  displayFn(activityCode: string): string {
    const selectedObject = this.activityJSONResp.find(obj => obj.activity_code === activityCode);
    if (selectedObject) {
      // return the display value based on the object properties
      return this.german_labels ? `${selectedObject.prefix_de} ${selectedObject.link_de} ${selectedObject.suffix_de}` :
        `${selectedObject.prefix_en} ${selectedObject.link_en} ${selectedObject.suffix_en}`;
    }
  }

  private _filterActivity(name: string): ActivityMap[] {
    const filterValue = name.toLowerCase().trim();
    return this.activityJSONResp.filter(option => {
      const spanValue = this.getSpanValue(option); // Define a method to get the span value
      return spanValue.toLowerCase().includes(filterValue);
    });
  }

  private getSpanValue(option: ActivityMap): string {
    if (this.german_labels) {
      return `${option.prefix_de} ${option.link_de} ${option.suffix_de}`;
    } else {
      return `${option.prefix_en} ${option.link_en} ${option.suffix_en}`;
    }
  }

  handleSelectionChange(event, userId){
    if (event.isUserInput) {
      this.filter_user_id = userId;
      this.recentActivities();
    }
  }
}


