<div class="text-center">

    <div *ngIf="rerecordButtonConfig.videotype =='WC'">
        <div *ngIf="startButtonConfig.display">
            <button mat-fab class="bigButton1" [ngClass]="startButtonConfig.videotype == 'WC' ? 'lPadding' : 'mPadding'"
                (click)="startButtonConfig.action()" [disabled]="disabledStart">
                <mat-icon [ngClass]="disabledStart ? 'disableStartIcon' : 'startIcon'">fiber_manual_record</mat-icon>
            </button>
            <div class="mt-4 textcolor" translate>Starten</div>
        </div>
        <div *ngIf="stopButtonConfig.display">
            <button [ngClass]="stopButtonConfig.videotype == 'WC' ? 'lPadding' : 'mPadding'" class="bigButton1"
                *ngIf="!stopButtonConfig.pauseStatus" mat-fab (click)="stopButtonConfig.pause()" >
                <div
                    style="height:75px;width:75px;border-radius: 50px;margin-top:-0.7rem; background-color:rgb(201, 56, 3);">
                    <mat-icon class="bigIcon1" style="color:white !important">pause</mat-icon>
                    <div class="mt-2 textcolor" translate>Pausieren</div>
                </div>

            </button>
            <button [ngClass]="stopButtonConfig.videotype == 'WC' ? 'lPadding' : 'mPadding'" class="bigButton1"
                *ngIf="stopButtonConfig.pauseStatus " mat-fab (click)="stopButtonConfig.resume()"
                [disabled]="disabledResume">
                <div
                    style="height:75px;width:75px;border-radius: 50px; margin-top:-0.7rem; background-color:rgb(201, 56, 3);">
                    <mat-icon class="bigIcon1" style="color:white !important;">play_arrow</mat-icon>
                    <div class="mt-3 textcolor" translate>Fortsetzen</div>
                </div>

            </button>
        </div>


        <div style="position: relative;top:2rem">
            <button mat-fab style="height: 50px;width:50px;box-shadow: none !important;" [ngClass]="!disabledReset ? 'enablereniewIcon' : 'disablereniewIcon'"
            (click)="rerecordButtonConfig.action()" [disabled]="disabledReset">
            <mat-icon class="renewBigWC">autorenew</mat-icon>
        </button>
            <div class="mt-4 textcolor" translate>Neu aufnehmen</div>
        </div>

        <!-- <div style="position: relative;top:3rem;">
            <div style="display:flex;justify-content: space-around">
              <button mat-fab [ngClass]="disabledReset ?  'disablewebbigIcon' : 'bigWebButton'" [disabled]="disabledReset"
              style="box-shadow: none !important;" >
                <mat-icon class="restartBig">keyboard_tab</mat-icon>
              </button>
            </div>

            <div class="mt-4 textcolor"
              style="display: flex;color: #000000 !important;justify-content:center;margin-right:0.5rem !important;">
              Aufnahme {{nextLabel}}
            </div>
          </div> -->
    </div>
</div>
    <div *ngIf="rerecordButtonConfig.videotype !=='WC'">
        <div style="display:flex;justify-content: center;position: relative;top: 1rem;left: 2rem;">
        <div *ngIf="startButtonConfig.display">
            <button mat-fab class="bigButton" [ngClass]="startButtonConfig.videotype != 'WC' ? 'lPadding' : 'mPadding'"
                (click)="startButtonConfig.action()" [disabled]="disabledStart">
                <mat-icon [ngClass]="disabledStart ? 'disablebigIcon' : 'bigIcon'">fiber_manual_record</mat-icon>
            </button>
            <div class="textcolor" translate>Starten</div>
        </div>
        <div *ngIf="stopButtonConfig.display">
            <button [ngClass]="stopButtonConfig.videotype != 'WC' ? 'lPadding' : 'mPadding'" class="bigButton"
                *ngIf="!stopButtonConfig.pauseStatus" title='Pausieren' mat-fab (click)="stopButtonConfig.pause()">
                <div
                style="height:70px;width:70px;border-radius: 50px;margin-top:-0.7rem; background-color:rgb(201, 56, 3);">
                    <mat-icon class="bigIcon1" style="color:white !important">pause</mat-icon>
                    <div class="textcolor" translate>Pausieren</div>
                </div>

            </button>
            <button [ngClass]="stopButtonConfig.videotype != 'WC' ? 'lPadding' : 'mPadding'" class="bigButton"
                *ngIf="stopButtonConfig.pauseStatus " title='Fortsetzen' mat-fab (click)="stopButtonConfig.resume()" [disabled]="disabledResume">
                <div
                style="height:70px;width:70px;border-radius: 50px; margin-top:-0.7rem; background-color:rgb(201, 56, 3);">
                    <mat-icon class="bigIcon1" style="color:white !important;">play_arrow</mat-icon>
                    <div class=" textcolor" translate>Fortsetzen</div>
                </div>

            </button>
        </div>
    </div>
<div style="display:flex;justify-content: flex-start;">
        <div style="margin-left:0.2rem;position: relative;top:2.9rem">
            <div style="display: flex;">
                <button mat-fab style="z-index:99" [ngClass]="!disabledReset ? 'disablebigResetIcon' : 'bigResetIcon'"
                  (click)="rerecordButtonConfig.action()" [disabled]="disabledReset">
                    <mat-icon class="renewBig">autorenew</mat-icon>
                </button>
                <div class="mt-2 textcolor" style="display: flex;width: 20%;margin-left: -2.8rem;position: relative;top:1.2rem" translate>Aufnahme wiederholen</div>
            </div>
        </div>
    </div>
    <!-- <span style="position: relative; left: 2rem;">
        <div style="display:flex;
        justify-content: center;
      margin-top: 0rem;position: relative;left: 1.2rem;">
          <button mat-fab   style="box-shadow: none !important;" [ngClass]="disabledReset ?  'disablebigResetIcon' : 'bigResetIcon'"
      [disabled]="disabledReset">
            <mat-icon class="restartBig">keyboard_tab</mat-icon>
          </button>
        </div>

        <div class="textcolor"
          style="display: flex;color:black;justify-content:center;margin-left:5rem !important;position: relative; width: 40%;top: 2px;">
          Aufnahme
          <br />
          {{nextLabel}}
        </div>
      </span> -->
    </div>

