<!--show after video url is expired-->
<div class="dark-gray-bg bg-transparent">
  <div class="long-video-message">
    <p class="text-nunito text-white mb-4" style="font-size: 50px" translate>
      Are you still there?
    </p>
    <button class="mt-2 border-radius-20" mat-dialog-close mat-stroked-button
            style="border-color: white !important;">
      <span class="text-nunito text-white" translate>Ja</span>
    </button>
  </div>
</div>
