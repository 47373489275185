import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialogComponent implements OnInit {
  exportOptions = [
    {
      text: 'Download',
      icon: 'get_app',
      cssClasses: 'bg-blue',
      action: () => this.download(this.data.zipUrl)
    }
    // {
    //   text: 'SCORM',
    //   icon: 'get_app',
    //   cssClasses: 'bg-green',
    //   action: () => this.download(this.data.scormUrl)
    // },
    // {
    //   text: 'Export auf zesavi.com',
    //   icon: 'publish',
    //   cssClasses: 'bg-green',
    //   action: ''
    // },
    // {
    //   text: 'Einbinden in ...',
    //   icon: 'code',
    //   action: ''
    // }
  ];

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
  }

  ngOnInit(): void {
  }

  download(url: string): void {
    const fileLink = document.createElement('a');
    fileLink.href = url;
    fileLink.download = `${this.data.fileName}.zip`;
    fileLink.click();
  }

}
