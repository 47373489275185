import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatGridList } from '@angular/material/grid-list';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { VideoHistoryCard } from '../models/video/video.interface';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { NavbarService } from '../services/navbar.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-history-page',
  templateUrl: './history-page.component.html',
  styleUrls: ['./history-page.component.scss']
})
export class HistoryPageComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid: MatGridList;
  cols: number;

  videos: VideoHistoryCard[] = [];
  subscription: Subscription;
  backendUrl: string;
  videoCurrentPage: number = 100;
  pageSize: number = 10;
  pageSizeOptions: number[] = [this.pageSize];
  // tslint:disable-next-line:max-line-length
  constructor(public dataService: DataService,
              private translateService:TranslateService,
              private snackBar: MatSnackBar,
              private navbarService: NavbarService,
              public authService: AuthService) {

  }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    this.navbarService.getUserProfileDetails();
    this.navbarService.disableCreationMode();
    this.getVideos('1');
    this.resize(window.innerWidth);
  }

  getVideos(page): void {
    this.dataService.getURL(`user/videos/history/?page=${page}`).subscribe((data: any) => {
      this.videos = data.data;
      this.pageSize = data.pageSize;
      this.videoCurrentPage = data.length;
    });
  }

  deleteHistory() {
    let message = this.translateService.instant("Der gesamte angezeigte Videoverlauf wird gelöscht.");
    message += '\n\n';
    message += this.translateService.instant("Would you like to continue?");
    if (window.confirm(message)) {
      this.dataService.deleteURL(`user/videos/history/`).subscribe((res) => {
        if (res == null) {
          this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2500});
          this.ngOnInit();
        } else {
          this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2500});
          console.error(res);
        }
      }, (err) => {
        console.error(err);
      });
    }
  }

  ngOnDestroy(): void {
  }

  updateVideos(event) {
    this.getVideos(event.pageIndex + 1);
  }

  resize(innerWidth) {
    let cw = 320; // card width
    let gs = 20;  // gutter size
    let sn = 50; // small side nav width = 50 approx
    this.cols = Math.floor((innerWidth - sn) / (cw + gs));
  }

  onResize(event) {
    this.resize(event.target.innerWidth);
  }
}
