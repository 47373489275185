<div #outerBox [style.height.px]="videoHeight" [style.width.px]="videoWidth"
     style="position: absolute; margin:0px !important;">
  <div #resizeBox (click)="selectDiv(id)" (rzResizing)="onResizeStop($event,id)"
       (started)="onDragBegin($event,id)" (stopped)="onDragEnded($event, id)" [bounds]="outerBox" [id]="id"
       [inBounds]="true" [style.border]="borderColor" [style.height.px]="height" [style.left.px]="left"
       [style.top.px]="top" [style.visibility]="visibility" [style.width.px]="width" [style]="style" class="container1"
       ngDraggable ngResizable rzContainment="parent" rzHandles="all" style="z-index: 995;position: absolute;">

  </div>
</div>
