import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-watermark-preview',
  templateUrl: './watermark-preview.component.html',
  styleUrls: ['./watermark-preview.component.scss']
})
export class WatermarkPreviewComponent implements OnInit {
  @ViewChild('meter') meter: ElementRef;
  height;
  width;
  inProgress = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<WatermarkPreviewComponent>) {

  }

  async ngOnInit() {
    this.inProgress = true;
    var dialogDim = document.getElementsByClassName('mat-dialog-container')[0]
    this.width = dialogDim.clientWidth -50;
    this.height = this.width * (9 / 16) ;
    var can = document.getElementById('myCanvas');
    can['height'] = this.height;
    can['width'] = this.width;
    const canvas = document.getElementById('myCanvas') as HTMLCanvasElement | null;
    const ctx = canvas?.getContext('2d');
    await this.loadBackground(ctx);
    await this.loadWatermark(ctx, this.width, this.height);
    this.inProgress = false;
  }

  async loadBackground(ctx) {
    return new Promise(resolve => {
      var bg_image = new Image();
      bg_image.src = this.data.bg_image;

      bg_image.onload = () => {
        ctx.drawImage(bg_image, 0, 0, this.width, this.height);
        resolve(true)
      }
    });


  }

  async loadWatermark(ctx, window_width, window_height) {
    return new Promise(resolve => {
      var watermark = new Image();
      watermark.src = this.data.watermark;
      let height = this.height/12;
      switch (this.data.position) {
        case 'no':
          resolve(true)
          break;
        case 'tl':
          watermark.onload = function () {
            let aspectRatio = watermark.width / watermark.height;
            let width = parseFloat((height * aspectRatio).toFixed(2));
            ctx.drawImage(watermark, 10, 10, width, height);
          };
          resolve(true);
          break;
        case 'bl':
          watermark.onload = function () {
            let aspectRatio = watermark.width / watermark.height;
            let width = parseFloat((height * aspectRatio).toFixed(2));
            ctx.drawImage(watermark, 10, window_height - height - 10, width, height);
          };
          resolve(true);
          break;
        case 'tr':
          watermark.onload = function () {
            let aspectRatio = watermark.width / watermark.height;
            let width = parseFloat((height * aspectRatio).toFixed(2));
            ctx.drawImage(watermark, window_width - width - 10, 10, width, height);
          };
          resolve(true);
          break;
        case 'br':
          watermark.onload = function () {
            let aspectRatio = watermark.width / watermark.height;
            let width = parseFloat((height * aspectRatio).toFixed(2));
            ctx.drawImage(watermark, window_width - width - 10, window_height - height - 10, width, height);
          };
          resolve(true);
          break;
      }
    })
  }

  closePopup(): void {
    this.dialogRef.close();
  }
}
