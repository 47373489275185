import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import {map, take, tap} from 'rxjs/operators';  // window removed from import
import {AuthResponse, Company} from '../models/interfaces/auth-response';
import { Credentials } from '../models/interfaces/credentials';
import { User } from '../models/user/user';
import { UserProfile } from '../models/user/userprofile';
import { Banner } from "../models/banner";
import { DataService } from './data.service';
import { PrimaryColorSetupService } from './primary-color-setup.service';
import {ChecklistItem, MiniDetails} from "../models/video/video.interface";

@Injectable({
    providedIn: 'root'
})
export class AuthService implements CanLoad {

    private _isAuthenticated = new BehaviorSubject<boolean>(false);
    public checklist_data: ChecklistItem[] = [];
    public tag_data: MiniDetails[] = [];

    constructor(private primaryColorSetupService: PrimaryColorSetupService, private dataService: DataService, private router: Router) {
    }

    get currentUserId(): string {
        return this._currentUser.id;
    }

    private _currentUser: User;

    get currentUser(): User {
        return this._currentUser;
    }

    set currentUser(value: User) {
        this._currentUser = value;
    }

    private _UserDetails: UserProfile;

    get userDetails(): UserProfile {
        return this._UserDetails;
    }

    set userDetails(value:UserProfile){
        this._UserDetails = value;
    }

    private _company: Company;

    get company(): Company {
        return this._company;
    }

    set company(value: Company) {
        this._company = value;
    }

    private _banners: Banner[] = [];
    get banners() {
      return this._banners;
    }
    set banners(value) {
      this._banners = value;
    }

    private _languages = new Map<string, string>([]);
    get languages() {
      return this._languages;
    }
    set languages(value) {
      this._languages = value;
    }

    private static setAccessToken(token: string, expiration_date: string): void {
      document.cookie = `access_token=${token}; expires=${expiration_date}; secure; samesite=None; path=/;`;
      localStorage.setItem('access_token', token);  // todo: remove. Currently stored for Android app, teams tab
      return;
    }

    static getAccessToken(): string {
      // let token = sessionStorage.getItem('access_token');
      let token = undefined;
      if (document.cookie.includes('access_token')) {
        const value = `; ${document.cookie}`;
        const parts = value.split("; access_token=");
        if (parts.length == 2) {
          token = parts.pop().split(';').shift();
        }
      } else {
        // try local storage, as it may be set by the backend
        token = localStorage.getItem('access_token');
      }
      return token;
    }


    authenticate(credentials: Credentials): Promise<AuthResponse> {
      return this.dataService.postURL<AuthResponse>(`login/`, {
        username: credentials.email,
        password: credentials.password
      }).pipe(tap(res => {
          if (res && res.access_token) {
            return this.setData(res);
          }
        })).toPromise();
    }

    isAuthenticated(): boolean {
        return this._isAuthenticated.value;
    }

    async getNewJwt(): Promise<AuthResponse> {
        // Get a new JWT from Auth0 using the refresh token saved
        // in local storage
        try{
        const token = AuthService.getAccessToken();
          if (token) {
            return this.dataService.postURL<any >('refresh-token/', {'token' : token},
              {observe: 'response'})
              .pipe(tap(res => {
                let resp = res.body;
                if(resp['response'] == 'failure'){
                  window.alert(resp['reason']);
                  this.logout();
                }
                if (resp && resp.access_token && resp.user) {
                  localStorage.setItem('primary_color', resp.company.primary_color);
                  localStorage.setItem('secondary_color', resp.company.secondary_color);
                  localStorage.setItem('max_video_duration_in_seconds', resp.company.max_video_duration_in_seconds);
                  this.primaryColorSetupService.savePrimaryColor();
                  this.primaryColorSetupService.saveSecondaryColor();
                  this.primaryColorSetupService.savergbColor();
                  return this.setData(resp);
                }})).toPromise();
          }
        }
        catch (e){
          if(e instanceof DOMException){
            console.error('Clypp: Please allow third party cookies and refresh the page');
          }
          // throw e;
          // else if(e instanceof ReferenceError){
          //  // token not found
          // }
        }
    }

    async setData(res: AuthResponse): Promise<void> {
        // this method is always called when refresh token api is successful or upon login
        this.currentUser = res.user;
        this.company = res.company;
        AuthService.setAccessToken(res.access_token, res.expiration_date);
        this._isAuthenticated.next(true);

        // set the company data now
        this.updateCompanyData();

        // load and save the checklist data
        this.loadChecklistData();
        // load tags data to use across the app
        this.loadTagsData().then();
    }

    // universal call to refresh company data
    updateCompanyData() {
      this.dataService.getURL<Company>('user/company/', {
        observe: 'body',
        responseType: 'json'
      }).subscribe((res) => {
        this.company = res;
      }, (err) => {
      });
    }

    loadBanners() {
      // load the banners now
      this.dataService.getURL<Banner[]>('banners/',
        {observe: 'body', responseType: 'json'}).subscribe((res: Banner[])=>{
        this.banners = res;
        // now, urlify the banners as well
        for(let i in this.banners){
          this.banners[i].site_notice = urlify(this.banners[i].site_notice);
        }
      }, (err)=>{
          console.error(err);
      });
    }

    loadChecklistData() {
      this.dataService.getURL<ChecklistItem[]>('user/company/checklist/').subscribe((resData: ChecklistItem[]) => {
        this.checklist_data = resData;
      });
    }

    // the method loads tags for the entire company
    async loadTagsData() {
      const local_subscription = this.dataService.getURL<MiniDetails[]>("user/tags/", {
        observe: 'body', responseType: 'json'
      });
      this.tag_data = await local_subscription.toPromise();
    }


    async logout(): Promise<boolean> {
      localStorage.clear();
      // sessionStorage.removeItem('access_token');
      this._isAuthenticated.next(false);
      this.currentUser = null;
      // clear cookie set by backend by setting an old expire date
      document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
      return this.router.navigate(['/login']);
    }

    canLoad(): Observable<boolean> {
        return this._isAuthenticated.pipe(map(auth => !!auth), take(1));
    }

    getIsAuthenticated(): BehaviorSubject<boolean> {
      return this._isAuthenticated;
    }

    loadLanguages() {
      // load the languages now
      this.dataService.getURL<any[]>('user/videos/languages/',
        {observe: 'body', responseType: 'json'}).subscribe((res)=>{
          for(let i of res){
            this.languages.set(i[0], i[1]);
          }
      }, (err)=>{});
    }

}

// this method surrounds https links with anchor tag
export function urlify(text) {
  let urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  });
}
