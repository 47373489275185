import {Component, Inject, OnInit, HostListener, ViewChild} from '@angular/core';
import {UntypedFormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {DataService} from '../../services/data.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material/dialog";
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {FileValidator} from 'ngx-material-file-input';
import {MatTableDataSource} from '@angular/material/table';


@Component({
  selector: 'app-load-user-csv',
  templateUrl: './load-user-csv.component.html',
  styleUrls: ['./load-user-csv.component.scss']
})
export class LoadUserCsvComponent implements OnInit {
  readonly maxSize = 20970000;
  csvUploaded: boolean = false;
  csvUploadResp: any;
  mattooltip1: string = "";
  dataSource: any;
  resparray: any = [];
  csvForm = this.formBuilder.group({
    csv_file: [undefined, FileValidator.maxContentSize(this.maxSize)],
  });
  object: any;
  displayedColumns: string[] = ['id', 'description'];
  dry_run: boolean = false;

  constructor(private snackBar: MatSnackBar, private dataService: DataService, private httpClient: HttpClient, public dialogRef: MatDialogRef<LoadUserCsvComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public formBuilder: UntypedFormBuilder, public sanitizer: DomSanitizer, private translateService: TranslateService) {
    let publsh = "Ersetze die Spaltennamen in deiner Datei mit den jeweils entsprechenden Spaltennamen aus der Vorlage, z.B. sollte deine Spalte \"Email\" mit \"userPrincipalName\" ersetzt werden, etc.";
    this.mattooltip1 = this.translateService.instant(publsh);
  }

  ngOnInit(): void {
  }

  csvfieldSelected() {
    if (this.csvUploadResp != undefined) {
      this.csvForm.get('csv_file').setValue(undefined);
      const fileInput = <HTMLInputElement>document.querySelector('ngx-mat-file-input[formcontrolname="csv_file"] input[type="file"]');
      fileInput.value = null;
      this.csvUploadResp = undefined;
      this.dataSource = [];
    }
  }

  DownloadCSV() {
    let link = document.createElement("a");
    link.download = "clypp_user_import_template.csv";
    link.href = "assets/files/zesavi_user_import_template.csv";
    link.click();
  }

  attachCSV(event, file) {
    let attachment = this.csvForm.get('csv_file').value._files[0];
    let formData = new FormData();
    formData.append('csv_file', attachment, attachment.name);
    this.dataService
      .postURL(`manager/import/?dry_run=${this.dry_run}`, formData, {observe: 'body', responseType: 'json'})
      .subscribe((resp: any) => {
        if (resp.response == 'success') {
          this.resparray = [];
          this.csvUploadResp = resp['data'];
          for (var i in this.csvUploadResp) {
            this.object = {id: i, value: this.csvUploadResp[i]};
            this.resparray.push(this.object);
          }
          this.dataSource = new MatTableDataSource(this.resparray);
          this.csvUploaded = true;
          this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
        }
        else {
          this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'),
            '', {duration: 2500});
        }
      }, (error) => {
        window.alert(error.error);
      });
  }

  closeDialog() {
    this.dialogRef.close({'csvUploaded': this.csvUploaded});
  }
}
