import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatGridList} from '@angular/material/grid-list';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-carousel-slider',
  templateUrl: './carousel-slider.component.html',
  styleUrls: ['./carousel-slider.component.scss']
})
export class CarouselSliderComponent implements OnInit {
  @Input() cardData: any[];
  // @Input() cardType: 'video' | 'history' | 'playlist' | 'member' | 'group' = 'video';
  @Input() cardType: string = 'video';
  @ViewChild('grid') grid: MatGridList;
  currentIndex = 0;
  visibleCards: any[] = [];
  cols: number;
  backendUrl: string;
  rowHeight: number = 300;
  cardWidth: number = 320;


  constructor(private router: Router) {
    this.backendUrl = environment.backendURL;
  }

  ngOnInit(): void {
    switch (this.cardType) {
      case 'latestUser':
        this.cardWidth = 180;
        this.rowHeight = 180;
        break;
      case 'groups':
        this.cardWidth = 280;
        this.rowHeight = 220;
        break;
    }
    console.log("data", this.cardData)
    this.resize(window.innerWidth);
  }

  previousSlide() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.updateVisibleCards();
    }
  }

  nextSlide() {
    if (this.currentIndex < this.cardData.length - this.cols) {
      this.currentIndex++;
      this.updateVisibleCards();
    }
  }

  updateVisibleCards() {
    this.visibleCards = this.cardData.slice(this.currentIndex, this.currentIndex + this.cols);
  }

  resize(innerWidth) {
    let gs = 20;  // gutter size
    let sn = 35; // small side nav width = 50 approx (CHANGED FROM 50 to 35 as it was causing this.col to get 0 on small devices)
    this.cols = Math.floor((innerWidth - sn) / (this.cardWidth + gs));
    this.updateVisibleCards();
  }

  onResize(event) {
    this.resize(event.target.innerWidth);
  }

  navigateUserPage(user_id) {
    this.router.navigate(['user', user_id]);
  }

  viewGroups(value) {
    if (value.last_edited_on) {
      this.router.navigate(['groups', value.id]);
    } else if (value.type == 'team') {
      this.router.navigate(['group', value.type]);
    } else if (value.type == 'department') {
      this.router.navigate(['group', value.type]);
    }
  }
}
