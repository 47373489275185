<div class="m-3 d-flex justify-content-between" style="flex-flow: wrap;">
  <div>
    <span class="fontStyle mr-4" style="font-size: x-large;" translate>
      <mat-icon class="mr-1 material-icons-outlined" color="primary" style="vertical-align: middle;">
        maps_ugc
      </mat-icon>
      Requests
    </span>
    <button (click)="videoReq()" class="border-radius-20" mat-stroked-button matTooltip="{{ 'Request Clypp' | translate }}">
      <mat-icon class="mr-1">
        add
      </mat-icon>
      {{ "New Request" | translate }}
    </button>
  </div>

  <div>
    <mat-form-field appearance="outline" class="mr-4" style="width:inherit;">
      <mat-label translate>Search for…</mat-label>
      <input (keyup)="applyFilter();" [(ngModel)]="searchQuery" autocomplete="off" matInput
             placeholder="{{'Titel' | translate}}">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Filter</mat-label>
      <mat-select (selectionChange)="applyFilter();" [(ngModel)]="filterValue" class="sort-input">
        <mat-option value="all"><span translate>Alle</span></mat-option>
        <mat-option value="open"><span translate>Open</span></mat-option>
        <mat-option value="fulfilled"><span translate>Assigned to me</span></mat-option>
        <mat-option value="requested_by"><span translate>Requested by me</span></mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="mt-3 d-flex justify-content-start" style="flex-flow: wrap;">
  <mat-card *ngFor="let req of filteredRequest"
            class="m-2"
            [style.border-color]="(req.fulfilled_video && req.grabbed_by) ? authService.company.primary_color : 'lightgray'"
            style="width: 400px; border: 1px solid; border-radius: 10px;">

    <mat-card-title class="d-flex justify-content-between" style="min-height: 90px;">
      <mat-card-header class="w-50">
        <img [src]="req.requested_by.userprofile.profile_pic" class="mr-1" mat-card-avatar>
        <mat-card-subtitle class="mb-1 text-nunito">
          {{ 'Requested by' | translate }}
        </mat-card-subtitle>
        <mat-card-title style="overflow-wrap: break-word; font-size: 15px;" class="text-nunito"
                        [title]="req.requested_by.email">
          {{ req.requested_by.first_name }}&nbsp;{{ req.requested_by.last_name }}
        </mat-card-title>
      </mat-card-header>

      <!--if req is already grabbed-->
      <mat-card-title-group *ngIf="req.grabbed_by" class="w-50">
        <mat-card-subtitle class="mb-1 text-nunito" *ngIf="req.fulfilled_video">
          {{ 'Fulfilled by' | translate }}
        </mat-card-subtitle>
        <mat-card-subtitle class="mb-1 text-nunito" *ngIf="!req.fulfilled_video">
          {{ 'Assigned to' | translate }}
        </mat-card-subtitle>
        <mat-card-title style="word-break: break-word; font-size: 15px;" class="text-nunito"
                        [title]="req.grabbed_by.email">
          {{ req.grabbed_by.first_name }}&nbsp;{{ req.grabbed_by.last_name }}
        </mat-card-title>
        <img [src]="req.grabbed_by.userprofile.profile_pic" class="ml-2" mat-card-avatar matSuffix>
      </mat-card-title-group>
    </mat-card-title>

    <mat-card-subtitle matTooltip="{{'Requested on'|translate}}" matTooltipPosition="above"
                       [title]="req.requested_on"
                       class="text-nunito">
      {{req.requested_on | date: "dd.MM.yyyy"}}
    </mat-card-subtitle>

    <mat-card-content style="min-height: 120px;">
      <mat-card-title class="text-nunito"
                      style="word-break: break-word; font-size: 17px;"
                      [title]="req.title">
        {{req.title}}
      </mat-card-title>
      <mat-card-subtitle class="text-nunito"
                         style="word-break: break-word;"
                         [title]="req.desc">
        {{req.desc}}
      </mat-card-subtitle>

      <mat-card-subtitle *ngIf="req.requested_department" class="text-nunito">
        {{ authService.company?.dept_alias }}:
        {{ req.requested_department.name }}
      </mat-card-subtitle>
      <mat-card-subtitle *ngIf="req.requested_team" class="text-nunito">
        {{ authService.company?.team_alias }}:
        {{ req.requested_team.name }}
      </mat-card-subtitle>

    </mat-card-content>

    <div class="mb-0 d-flex justify-content-between">
      <div class="w-50">
        <!--View video-->
        <button (click)="viewVideoCall(req.fulfilled_video)" *ngIf="req.fulfilled_video" mat-button color="primary">
          <!--Clicking this button will redirect to req.fulfilled_video view video page-->
          <mat-icon class="mr-1 material-icons-outlined">visibility</mat-icon>
          <span class="fontNormal1" translate>View Clypp</span>
        </button>

        <!--Fulfill-->
        <button [matMenuTriggerFor]="menu" mat-button>
          <mat-icon class="mr-1 material-icons-outlined" *ngIf="!req.fulfilled_video">task_alt</mat-icon>
          <span class="fontNormal1" translate *ngIf="!req.fulfilled_video">Fulfill</span>

          <mat-icon class="mr-1 material-icons-outlined" *ngIf="req.fulfilled_video">update</mat-icon>
          <span class="fontNormal1" translate *ngIf="req.fulfilled_video">Update Clypp</span>
        </button>

        <mat-menu #menu="matMenu">
          <button (click)="selectClypp(req.id);" mat-menu-item>
            {{'Select Clypp'|translate}}
          </button>
          <button (click)="createClypp(req);" mat-menu-item>
            {{'New Clypp'|translate}}
          </button>
          <!--todo: add button-->
          <button (click)="searchClypp(req.id);" mat-menu-item hidden>
            {{'Search for…'|translate}}
          </button>
        </mat-menu>
      </div>

      <div class="w-50">
        <!--email to requested_by/Grabbed_by-->
        <button (click)="sendEmail(req)" *ngIf="req.grabbed_by" mat-button>
          <mat-icon class="mr-1" mat-list-icon>mail_outline</mat-icon>
          <span class="fontNormal1" translate>Email senden</span>
        </button>

        <!--Delete Request-->
        <button (click)="deleteRequestCall(req.id)" *ngIf="req.requested_by.id == userId" mat-button>
          <mat-icon class="mr-1" mat-list-icon>delete_outline</mat-icon>
          <span class="fontNormal1" translate>Löschen</span>
        </button>
      </div>
    </div>

    <!--Someone is working on the request-->
<!--    <mat-card-footer *ngIf="req.is_grabbed && !req.is_fulfilled">-->
<!--      <mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
<!--    </mat-card-footer>-->
  </mat-card>
</div>
