<div class="d-flex justify-content-between">
  <h1 mat-dialog-title style="font-family: 'poppins-medium';" translate>
    Contact Support
  </h1>
  <button (click)="closePopup()" mat-icon-button style="margin-top: -5px;">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form [formGroup]="feedbackForm">
    <mat-form-field class="w-100">
      <mat-label translate>What is the subject of the request?</mat-label>
      <mat-select formControlName="subject" placeholder="{{'Please select' | translate}}">
        <mat-option *ngFor="let option of processedOptList" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-label translate>Message</mat-label>
      <textarea autocomplete="off" formControlName="message" matInput
                placeholder="{{'We are always grateful for suggestions, questions or feedback.' | translate}}">
      </textarea>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-label translate>Phone number for further queries</mat-label>
      <input matInput formControlName="contact" placeholder="+49 999 9999 9999" type="tel">
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions style="float: right;">
  <button (click)="formInputSubmit()" [disabled]="!feedbackForm.valid" [mat-dialog-close]="true" cdkFocusInitial
          color="primary" mat-stroked-button>
    <span translate>Submit</span>
  </button>
</mat-dialog-actions>
