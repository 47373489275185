import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-pdf-viewer-popup',
  templateUrl: './pdf-viewer-popup.component.html',
  styleUrls: ['./pdf-viewer-popup.component.scss'],
})
export class PdfViewerPopupComponent implements OnInit {
  pdfSrc: string = '';
  pdfTitle: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.pdfSrc = data.src;
    this.pdfTitle = data.title;
  }

  ngOnInit(): void {
  }
}
