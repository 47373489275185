import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../../services/auth.service";
import {HttpErrorResponse} from '@angular/common/http';
import {Media, VideoProfile, VideoView} from 'src/app/models/video/video.interface';
import {range} from 'rxjs';

@Component({
  selector: 'app-upload-clypps-popup',
  templateUrl: './upload-clypps-popup.component.html',
  styleUrls: ['./upload-clypps-popup.component.scss']
})
export class UploadClyppsPopupComponent implements OnInit {
  max_video_size_bytes: number = 0;
  languages: [string, string][] = [];
  audio_language: string = "en-US";
  project_settings: string = "1080";
  MAX_LIMIT: number = 10;
  videoProfileOptions: VideoProfile[] = [];
  ci_id: number | null = 0;
  is_published: boolean = false;
  disable_high_quality: boolean = true;
  auto_create_subtitles: boolean = false;
  auto_create_desc: boolean = false;

  all_videos: VideoView[] = [];

  constructor(private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private dataService: DataService,
              protected authService: AuthService,
              public dialogRef: MatDialogRef<UploadClyppsPopupComponent>) {
    this.max_video_size_bytes = this.authService.company.max_video_size_bytes;
    // initiate languages from auth service
    for (let i of this.authService.languages.entries()) {
      this.languages.push(i);
    }
    this.audio_language = this.authService.userDetails.email_language;
    if(this.authService.company.max_upload_video_height == '1440') {
      // disable pro option
      this.disable_high_quality = false;
    }
  }

  ngOnInit(): void {
    this.loadVideoProfiles();

    this.dialogRef.backdropClick().subscribe(() => {
      this.closeDialog();
    });
  }

  loadVideoProfiles() {
    this.dataService.getURL('video/profiles/').subscribe((res: VideoProfile[]) => {
      this.videoProfileOptions = res;
    }, (err) => {
      this.snackBar.open("Failed to load video profiles", '', {duration: 2500});
    });
  }

  filesSelected(event, dragUpload: boolean): void {
    let fileArr: FileList = null;
    if (dragUpload) {
      event.preventDefault();
      this.showHideDropDiv(true);
      fileArr = event.dataTransfer.files;
    } else {
      fileArr = event.target.files;
    }

    let n_files = fileArr.length;
    if (this.all_videos.length + n_files <= this.MAX_LIMIT) {
      // spaces left

    } else {
      let message = this.translateService.instant("Limit Exceeded: ");
      message += `${this.MAX_LIMIT} Clypps`;
      window.alert(message);
      return;
    }
    range(0, n_files).forEach(i => {
      this.createPRVideo(fileArr.item(i));
    });
  }

  // make the drag drop div visible or hidden
  showHideDropDiv(hidden: boolean = false) {
    document.getElementById('drop-div').hidden = hidden;
  }

  createPRVideo(file: File) {
    // check for max file size limit
    if (file.size > this.max_video_size_bytes) {
      const message = this.translateService.instant("Limit Exceeded: ") +
        `${Math.floor(file.size / 1000000)} MB / ${this.max_video_size_bytes / 1000000} MB`;
      window.alert(message);
      return;
    }

    // check for file ext
    if (file.type.includes('video')) {
      // allow upload of all known types of videos
      // ok
    } else if (file.name.toLowerCase().endsWith('.mkv') || file.name.toLowerCase().endsWith('.mts')) {
      // check mts and mkv
      // ok
    } else {
      this.snackBar.open(this.translateService.instant('File not supported'), '', {duration: 2500});
      return;
    }

    const postData = {
      title: file.name.trim().slice(-99),
      type: 'PR',
      desc: "",
      script: "",
      audio_language: this.audio_language,
      ci_profile: this.ci_id,
      project_settings: this.project_settings
    }

    if (this.ci_id == 0) {
      postData.ci_profile = null;
    }

    // file matches all criteria so processing starts and state has been set
    // this.files[index].processing = true;
    // this.files[index].state = 'AY';

    this.dataService.postURL<VideoView>(`user/videos/`, postData, {observe: 'body', responseType: 'json'})
      .subscribe((res: VideoView) => {
        if (res['reason']) {
          window.alert(res['reason']);
        } else {
          res.state = 'UP';
          this.all_videos.push(res);
          this.uploadMedia(file, res.id).then((media: Media) => {
            // will only come here when the media is uploaded
            // now, join all medias
            this.joinAllMedias(res.id).then((res) => {
              // find and replace
              const i = this.all_videos.findIndex(e => e.id == res.id);
              res.state = 'PR';
              this.all_videos[i] = res;
              this.postProcessVideo(res);
            }).catch(e => {
              throw e;
            });
          }).catch(e => {
            console.error(e);
            // change this one's state to failed
            this.all_videos.find(e => e.id == res.id).state = 'FA';
          });
        }
      });
  }

  postProcessVideo(video: VideoView) {
    this.dataService.postURL(`user/videos/${video.id}/medias/`, video.edit_parameters)
      .subscribe((res) => {
        // processing will only start if edit parameters are changed or video is not in CO state
        // start the polling call
        this.startPollingCall(video.id);
      }, (err) => {
        // failed to send post call, possible options could be 401, 404 or 500. All comes with a message.
        console.error(err);
        this.all_videos.find(e => e.id == video.id).state = 'FA';
      });
  }

  // called when file is selected from the <input>
  async uploadMedia(file: File, videoId: string): Promise<Media> {
    let formData = new FormData();
    // take the last 99 characters of file name, because backend limit is 100 characters
    // add the data to the form
    formData.append("video_file", file);
    formData.append("name", file.name.slice(-49));  // send the file name for improved experience

    const local_subscription = this.dataService.postURL<Media>(`user/videos/${videoId}/`,
      formData, {observe: 'body', responseType: 'json'});
    return local_subscription.toPromise();
  }

  // to check state
  startPollingCall(id: string) {
    // every 20 seconds, check if the video is in CO state or not.
    // stop if state is not PR
    setTimeout(() => {
      this.dataService.getURL<any>(`user/videos/${id}/state/`, {observe: 'body', responseType: 'json'})
        .subscribe((res) => {
        if (res.state == "PR") {
          // check again
          this.startPollingCall(id);
        } else {
          // update video obj
          const temp_video = this.all_videos.find(e => e.id == id);
          temp_video.state = res.state;
          // if state is CO, and if auto subtitles are on => transcribe it
          if (res.state == 'CO' && this.auto_create_subtitles) {
            // start transcription
            this.createSubtitles(temp_video);
          }
        }
      });
    }, 20000);
  }

  // this method changes the state from CO to CC and then back to CO
  createSubtitles(video: VideoView) {
    // button is disabled if AI services are not allowed
    video.state = 'CC';
    if (this.authService.userDetails.video_transcribed) {
      const message = this.translateService.instant('You will be notified after the subtitles are ready');
      this.snackBar.open(message, '', {duration: 2000});
    }
    this.dataService.patchURL<any>(`user/videos/${video.id}/state/`,
      {audio_language: this.audio_language},
      {observe: 'body', responseType: 'text'}).subscribe(
      (res: string) => {
        if (res.length) {
          // sometimes, there won't be any url due to no audio
          // now auto create desc
          if (this.auto_create_desc) {
            this.createSummary(video);
          } else {
            video.state = 'CO';
          }
        }
      }, (err: HttpErrorResponse) => {
        video.state = 'CO';
        console.error(err);
        this.snackBar.open(err.error, '', {duration: 2500});
      });
  }

  // create a short summary of the video
  createSummary(video: VideoView) {
    // todo: use new openai services to create desc
    video.state = 'CO';
    return;
  }

  async joinAllMedias(videoId: string): Promise<VideoView> {
    // send the finish call
    const local_subscription = this.dataService.postURL<VideoView>(`user/videos/${videoId}/`,
      {'action': 'end'}, {observe: 'body', responseType: 'json'});
    return local_subscription.toPromise();
  }

  publishVideos() {
    // close dialog if publish toggle is not enabled else publish all videos
    if (this.is_published) {
      this.all_videos.forEach(e => {
        this.publishVideo(e.id).then();
      });
    }
    // todo: wait for above calls to finish
    this.closeDialog();
  }

  async publishVideo(videoId: string): Promise<VideoView> {
    const local_subscription = this.dataService.putURL<VideoView>(
      `user/videos/${videoId}/?done=true`,
      {is_draft: false},
      {observe: 'body', responseType: 'json'});
    return local_subscription.toPromise();
  }

  // delete clypp
  editVideo(video: VideoView) {
    switch (video.state) {
      case 'PR':
        window.open(`create-video/${video.id}/trim`, "_blank");
        break;
      case 'CO':
      case 'CC':
        window.open(`create-video/${video.id}/review`, "_blank");
        break;
      default:
        window.open(`create-video/${video.id}/record`, "_blank");
        break;
    }
  }

  closeDialog() {
    // if any file is pending, then confirm first, else close with true
    if (this.all_videos.length == 0) {
      this.dialogRef.close(false);
      return;
    }
    if (this.isEverythingComplete()) {
      this.dialogRef.close(true);
      return;
    } else {
      // pending files
      const message = this.translateService.instant("Upload may continue in the background");

      if (window.confirm(message)) {
        this.dialogRef.close(true);
      }
    }
  }

  getFinishCount(): number {
    return this.all_videos.filter(e => e.state == 'CO').length;
  }

  isEverythingComplete(): boolean {
    return this.getFinishCount() == this.all_videos.length;
  }

}

