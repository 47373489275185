import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  ChangeDetectionStrategy,
  AfterContentChecked,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {ScreencastWebcamService} from '../../services/screencast-webcam.service';
import {HttpClient, HttpEvent, HttpEventType} from '@angular/common/http';
import {VideoType} from '../../models/video/video-type.enum';
import {WebcamRecorderService} from '../../services/webcam-recorder.service';
import {Observable, Subscription} from 'rxjs';
import {AudioRecorderService} from '../../services/audio-recorder.service';
import * as audioMeter from 'src/app/scripts/volume-meter.js';
import {DomSanitizer} from '@angular/platform-browser';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {NavbarService} from '../../services/navbar.service';
import {environment} from '../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from '../../services/auth.service';
import {DataService} from '../../services/data.service';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-screencast-webcam-creation',
  templateUrl: './screencast-webcam-creation.component.html',
  styleUrls: ['./screencast-webcam-creation.component.scss'],
})
export class ScreencastWebcamCreationComponent implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy {
  @ViewChild('controls') controls;
  @ViewChild('webcam') webcam;
  @ViewChild('webcamSCViewer') webcamSCViewer;
  @ViewChild('videoTimer') videoTimer;
  @ViewChild('meter') meter: ElementRef;
  @ViewChild('grid') grid;
  @Input() vidValue: any;


  gridByBreakpoint = {
    wL: '1:0.8',
    xl: '1.3:0.8',
    lg: '1:0.9',
    md: '1:1.5',
    sm: '1:2',
    xs: '1:5.2',
  };

  cols: any;
  notescol: any;
  showRecordingWindow = false;
  timeLimit: number;
  VideoTypeValue: any;
  paused = false;
  showCountdown = false;
  showCam = true;
  timeLeft: string = '3';
  environment = environment;
  alreadyEnteredTrimmingStep = false;
  backfromTrim: boolean = false;

  showAudioOption: boolean = false;
  public audioIcon = 'mic';
  showAudio: boolean = true;
  showGreenRect: boolean = false;
  micVal: boolean = true;
  showMicEditBack: boolean = false;
  micoffOnRecreate: boolean = false;
  screencastWebcam;
  isRecording: boolean = false;
  webcamVideo: any;
  recorderTest;
  responseVal: any;
  webcamDispVideo: any;
  videoTypes = VideoType;
  disabledReset: boolean;
  subscription: Subscription;
  subscriptionVideo: Subscription;
  backfromReview: any;
  videoProcessingComplete = false;
  videoRecordingFinished = false;
  rerecord: boolean = false;
  videoFormControl;
  videoURL: any;
  formData: FormData;
  formDataWebCam: FormData;
  videoBOTHURL: any;
  stopCpaturingBO: any;
  readonly WIDTH = 35;
  readonly HEIGHT = 2;
  arecorder;
  audioinputVal: any;
  notes = [];
  defaultValue: any;
  width: number = window.innerWidth;
  height: number = window.innerWidth;
  rowval: any;
  videoInputValue: any;
  videoId: string;
  defaultVideoValue: any;
  restart_Start: boolean = false;
  progress: number = 0;
  processFinish = false;
  germanLang: boolean = false;
  public noiseIcon = 'blur_on';
  noiseValue = true;
  // noiseSuppression : boolean = false;
  noiseSuppression: boolean = true;
  noiseSuppressionTooltip: string = 'Disable noise suppression';
  disableAudio: boolean = false;
  showPublishBtn = true;
  nextLabel: string = 'Aufnahme beenden';
  time: string;
  date: any;
  audioSelectionTooltip = 'Mikrofon stummschalten';
  notesValue: any;
  reload: boolean = true;
  videoSize: any;
  maxSize: any;
  blobSize: any;
  blobWebcamSize: any;
  audioHigh = new Audio();
  audioLow = new Audio();
  private currentWindow;
  private audioMeter: any;
  selectScreen: MediaStream;
  screencastPreview: boolean = true;
  screenValue: MediaStream;
  startrecord:boolean = false;
  videovalue :any;
  is_entire_screen_shared: boolean = false;

  constructor(
    public webcamRecorderService: WebcamRecorderService,
    public screencastWebcamService: ScreencastWebcamService,
    public audioRecorderService: AudioRecorderService,
    public authService: AuthService,
    private sanitizer: DomSanitizer,
    private breakpointObserver: BreakpointObserver,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private router: Router,
    private navbarService: NavbarService,
    private snackBar: MatSnackBar,
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private dataService: DataService
  ) {
    let numberCheck = /\d+/g;
    if (this.environment.videoSizeLimit.includes('GB')) {
      this.videoSize = this.environment.videoSizeLimit.match(numberCheck);
      this.maxSize = ((this.videoSize * 1000) * 2 ** 20);
    } else {
      this.videoSize = this.environment.videoSizeLimit.match(numberCheck);
      this.maxSize = (this.videoSize * 2 ** 20);
    }
    this.navbarService.showSideNav = false;
    window.onresize = () => {
      this.width = window.innerWidth;
      this.height = window.innerHeight;

      const isSmallScreen =
        this.breakpointObserver.isMatched('(max-width: 699px)');
      this.navbarService.showSideNav = false;

      if (this.width >= 1850) {
        this.rowval = 35;
      } else {
        this.rowval = 18;
      }
    };
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
        Breakpoints.Handset,
        Breakpoints.HandsetPortrait,
        Breakpoints.WebLandscape,
        Breakpoints.WebPortrait,
      ]).subscribe(result => {
        if (result.matches) {

          if (result.breakpoints[Breakpoints.WebLandscape]) {
            this.notescol = 8;
            }
            if (result.breakpoints[Breakpoints.XSmall]) {
              this.notescol = 6;
            }
            if (result.breakpoints[Breakpoints.Small]) {
              this.notescol = 8;
            }
            if (result.breakpoints[Breakpoints.Medium]) {
              this.notescol = 10;
            }
            if (result.breakpoints[Breakpoints.Large]) {
              this.notescol = 12;
            }
            if (result.breakpoints[Breakpoints.XLarge]) {
              this.notescol = 14;
            }
        }
    })

    this.router.events.subscribe(event => {
      // Handle route change
      this.stopTestWebcam();
  });
  }

  playDelay = async (start: boolean) => {
    this.showCountdown = true;
    this.timeLeft = '3';
    await delay(900);
    this.timeLeft = '2';
    await delay(900);
    this.timeLeft = '1';
    await delay(300);
    this.audioHigh.play();
    await delay(700);
    this.showCountdown = false;
  }

  deleteBeforeClosing() {
    return  this.dataService.deleteVideo(this.vidValue).toPromise();
  }

  //browser back button event
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.goBackRemovePermission();
  }

  // window close delete call
  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    let value = window.location.href.split("/").pop();
    if (value == 'record' && this.vidValue['video_file'] == null) {
      let httpCall = await this.deleteBeforeClosing();
    }
  }

  ngOnInit(): void {
    if (this.translateService.currentLang == 'de') {
      this.germanLang = true;
    }

    // load audio on init
    this.audioHigh.src = '../../assets/Mini_Button_01.wav';
    this.audioHigh.load();
    this.audioLow.src = '../../assets/Mini_Button_02.wav';
    this.audioLow.load();
    let timerValue = localStorage.getItem('max_video_duration_in_seconds');
    let timeval = Number(timerValue);
    if (timeval == 0) {
      this.timeLimit = 180;
    } else {
      this.timeLimit = timeval;
    }

    this.VideoTypeValue = 'BO';
    this.navbarService.showSideNav = false;
    this.navbarService.enableCreationMode();
    this.navbarService.hide();

    this.videoFormControl = this.formBuilder.group({
      videoControl: [true, Validators.requiredTrue],
    });

    this.notes = [
      'Hinweis: Beginne dein Video mit dem Satz: "In diesem Video zeige ich ' +
      this.date +
      ' wie…',
      '',
    ];

    // testing audio
    this.screencastWebcamService.getAudioVal();
    this.screencastWebcamService.audioVal.subscribe((data) => {
      this.audioinputVal = data;
      this.defaultValue = this.audioinputVal[0];
    });
    //testing video
    // this.screencastWebcamService.getVideoVal();
    // this.screencastWebcamService.videoVal.subscribe((data) => {
    //   this.videoInputValue = data;
    //   this.defaultVideoValue = this.videoInputValue[0];
    // });
    this.startAudioRecording();

    // window.moveTo(0, 0);
    // window.resizeTo(screen.width/2, screen.height/1);
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
}

  notesChange(event) {
    this.notesValue = event[1];
  }

  ngAfterViewInit(): void {
    if (this.vidValue['state'] == 'NO') {
      this.restart_Start = true;
    }
    // this.screencastWebcamService.videoVal.subscribe((data) => {
    //   if(data['deviceId'] === ''){
    //     this.videovalue = undefined;
    //   }else{
    //     this.videovalue = data[0];
    //   }
    if (
      this.vidValue['video_file'] != null ||
      this.vidValue['processed_file'] != null
    ) {
      this.nextLabel = 'Aufnahme beibehalten';
      this.controls.disabledStart = true;
      this.controls.disabledReset = false;
      this.controls.disabledResume = true;
      this.alreadyEnteredTrimmingStep = true;
      this.startTestWebcam(true);
    } else {
      this.paused = false;
      this.disabledReset = true;
      this.startTestWebcam(false);
    }
  // });
    if (this.vidValue['integration_parameters'] !== null) {
      this.toggleAudio();
      this.showAudioOption = true;
    } else {
      // translate the tooltip
      this.audioSelectionTooltip = this.translateService.instant('Mute');
    }
    this.noiseSuppressionTooltip = this.translateService.instant('Disable noise suppression');
    if (this.vidValue['script'] !== null) {
      this.notes = [
        'Hinweis: Beginne dein Video mit dem Satz: "In diesem Video zeige ich ' +
        this.date +
        ' wie…',
        this.vidValue['script'],
      ];
    }

  }

  startTestWebcam = async (disabledStart) => {
    var videoInputArr = [];
    this.isRecording = true;
    this.recorderTest = await this.webcamRecorderService
      .webcamTesting()
      .then((strm: any) => strm)
      .catch((err: any) => err);
    navigator.mediaDevices.enumerateDevices().then(devices =>
      devices.forEach(device => {
        if (device.kind === 'audioinput') {
          if (device.label != "") {
            this.decideForWebcam(disabledStart);
          } else {
            navigator.mediaDevices.getUserMedia({audio: true})
              .then((stream) => {
                this.decideForWebcam(disabledStart);
              })
              .catch((err) => {
                this.controls.disabledStart = true;
                this.webcamSCViewer.permissionDenied = true;
              });

          }
        }else if(device.kind === 'videoinput'){
          videoInputArr.push({ label: device.label, deviceId: device.deviceId });
          if(this.videoInputValue == null){
          this.videoInputValue = videoInputArr;
          }
        }
      }));
  };
  decideForWebcam = (disabledStart) => {
    this.webcamVideo = this.recorderTest.start();
    if (this.webcamVideo != 'denied') {
      this.webcamDispVideo = this.webcamRecorderService.getStreamTesting();
      this.controls.disabledStart = disabledStart;
      this.webcamSCViewer.permissionDenied = false;
    } else {
      this.controls.disabledStart = true;
      this.webcamSCViewer.permissionDenied = true;
    }
  }
  stopTestWebcam = async () => {
    this.webcamRecorderService.stopWebcamTesting();
  };

  startCaptureScrCstWebCam = async () => {
    //this.controls.disabledStart = true;
    this.startrecord = true;
    if(this.screenValue == undefined){
      this.selectScreen = await this.screencastWebcamService.onSelectScreen();
    }
    this.reload = true;
    this.screencastWebcam = await this.screencastWebcamService.startCapture();

    if (this.screencastWebcam == null) {
      this.webcamDispVideo =  this.webcamRecorderService.getStreamTesting();
      this.controls.disabledStart = false;
      this.startrecord = false;
    } else {
      this.webcamDispVideo = this.screencastWebcam[1];
      this.screencastPreview = false;
      this.screenValue = this.screencastWebcam[0];
      if(this.screenValue.getVideoTracks()[0].getSettings()['displaySurface']=='monitor'){
        this.is_entire_screen_shared = true;
      }
      this.controls.disabledStart = true;
      this.showRecordingWindow = true;
      this.showAudio = false;
      // window.moveTo(0, 0);
      // window.resizeTo(screen.width / 6.05, screen.height);
      await this.playDelay(true);

      this.stopTestWebcam();
      this.stopAudioRecording();


      this.currentWindow = window;
      await this.initRecording();
      this.disabledReset = false;
      this.controls.disabledStart = false;
      this.showGreenRect = true;
      this.subscription = this.screencastWebcamService.stoppedCapturing.subscribe(() => {
        this.getCaptureScrCstWebCam();
      });
      this.subscriptionVideo = this.screencastWebcamService.stoppedCapturingVideo.subscribe(() => {
        this.videoTimer.pauseTimer();
        this.showRecordingWindow = false;
        this.getCaptureScrCstWebCamVideo();
      });
      this.screencastWebcam[0].getVideoTracks()[0].addEventListener('ended', () => {
        this.stopScrCstWebCam();
      });
      if (this.vidValue['video_file'] != null) {
        this.nextLabel = 'Aufnahme beenden';
      }
    }
  };


  onSelectScreen = async () => {
    this.screencastPreview = true;
    if (!this.startrecord) {
      this.selectScreen = await this.screencastWebcamService.onSelectScreen();
      if (this.selectScreen == null) {
        this.screencastPreview = true;
      } else {
        this.screencastPreview = false;
        this.screenValue = this.selectScreen;
        if(this.selectScreen.getVideoTracks()[0].getSettings()['displaySurface']=='monitor'){
          this.is_entire_screen_shared = true;
        }
        this.selectScreen.getVideoTracks()[0].addEventListener('ended', () => {
          this.screencastPreview = true;
          this.is_entire_screen_shared = false;
          this.screenValue = undefined;
          this.screencastWebcamService.stopScreen();
        });
      }
    } else {
      this.screencastPreview = false;
      this.screenValue = this.selectScreen;
    }
  }

  stopScrCstWebCam() {
    this.screencastPreview = true;
    this.screencastWebcamService.stopCapture();
  }

  getCaptureScrCstWebCam = async () => {
    this.is_entire_screen_shared = false;
    this.videoFormControl.get('videoControl').setValue(true);

    this.formData = new FormData();
    let blob = new Blob([this.screencastWebcamService.chunks[0]]);
    this.blobSize = blob.size;
    this.formData.append('video_file', blob, `${new Date().getTime()}.webm`);
    this.videoURL = this.sanitizer.bypassSecurityTrustUrl(
      await this.screencastWebcamService.getFixedDurationBlobURL()
    );
  };

  getCaptureScrCstWebCamVideo = async () => {
    this.videoFormControl.get('videoControl').setValue(true);
    this.formDataWebCam = new FormData();
    let blobWebcam = new Blob([this.screencastWebcamService.chunksMedia[0]]);
    this.blobWebcamSize = blobWebcam.size;
    this.formDataWebCam.append(
      'overlay_video',
      blobWebcam,
      `${new Date().getTime()}.webm`
    );
    this.videoBOTHURL = this.sanitizer.bypassSecurityTrustUrl(
      await this.screencastWebcamService.getFixedDurationVideoBlobURL()
    );
    if (this.reload) {
      this.startConverting();
    }

    this.currentWindow.focus();
  };

  pauseRecording = async () => {
    // let audio = new Audio();
    // audio.src = '../../assets/Pause.m4a';
    // audio.load();
    // audio.play();
    this.getRecordingPaused();
    this.audioLow.play();
  };

  getRecordingPaused = async () => {
    this.paused = true;
    this.controls.disabledReset = false;
    this.showCountdown = true;
    this.timeLeft = '⏸';
    this.videoTimer.pauseTimer();
    this.screencastWebcamService.pauseScreencast();
    this.controls.disabledResume = true;
    await delay(1000);
    this.controls.disabledResume = false;
  };

  resumeRecording = async () => {
    this.controls.disabledResume = true;
    this.disabledReset = true;
    this.controls.disabledReset = true;
    await this.playDelay(false);
    this.controls.disabledResume = false;
    this.disabledReset = false;
    this.paused = false;
    this.showCountdown = false;
    this.videoTimer.resumeTimer();
    this.disabledReset = false;
    this.screencastWebcamService.resumeScreencast();
  };

  startConverting(): void {
    this.audioLow.play();
    // window.moveTo(0, 0);
    // window.resizeTo(screen.width, screen.height);
    if (typeof (this.vidValue) != 'object') {
      this.vidValue = JSON.parse(this.vidValue);
    }
    if (this.blobSize < this.maxSize && this.blobWebcamSize < this.maxSize) {
      let observable;
      observable = this.httpClient.put(
        `${environment.backendURL}/user/videos/${this.vidValue['id']}/`,
        this.formDataWebCam,
        {
          responseType: 'json', reportProgress: true,
          observe: 'events'
        });
      this.showProgressSpinnerUntilExecuted(observable);
    } else {
      // this.snackBar.open(this.translateService.instant('_VideoSize_Exceed', {val: environment.videoSizeLimit}), '', { duration: 3000 });
      this.controls.disabledReset = false;
      this.disabledReset = true;
      window.alert(this.translateService.instant('_VideoSize_Exceed', {val: environment.videoSizeLimit}));
      if (window.confirm(this.translateService.instant("Would you like to download the video?"))) {
        this.downloadVideo();
      }
    }

  }

  showProgressSpinnerUntilExecuted(observable: Observable<any>): void {

    observable.subscribe((response: HttpEvent<any>) => {

        switch (response.type) {
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(response.loaded / response.total * 50);
            break;
          case HttpEventType.Response:
            setTimeout(() => {
              if (this.notesValue != undefined) {
                this.formData.append('script', this.notesValue);
              }
              if (this.backfromTrim) {
                this.formData.append('edit_parameters', null);
              }
              let subscribeVideo;
              subscribeVideo = this.httpClient.put(
                `${environment.backendURL}/user/videos/${this.vidValue['id']}/`,
                this.formData,
                {
                  reportProgress: true,
                  observe: 'events', responseType: 'json'
                });
              subscribeVideo.subscribe((response: HttpEvent<any>) => {
                switch (response.type) {
                  case HttpEventType.ResponseHeader:
                    this.processFinish = true;
                    break;
                  case HttpEventType.UploadProgress:
                    this.progress = Math.round((response.loaded / response.total * 50) + 50);
                    break;
                  case HttpEventType.Response:
                    this.processFinish = true;
                    this.router.navigate(['create-video/' + this.vidValue['id'] + '/edit']);
                }

              });
            }, 50);

        }
      },
      (error) => {
        // handle error
        window.alert(this.translateService.instant('Verbindung unterbrochen'));
        if (window.confirm(this.translateService.instant("Would you like to download the video?"))) {
          this.downloadVideo();
        }
        this.videoProcessingComplete = true;
      });

  }

  openConfirmOverride = () => {
    let message = this.translateService.instant("Attention!\nUnsaved changes will be lost.\nClear recording?");
    if(window.confirm(message)){
          this.screencastWebcamService.destroySubject();
          this.is_entire_screen_shared = false;
          this.reload = false;
          this.rerecord = true;
          this.videoURL = '';
          this.videoBOTHURL = '';
          this.videoTimer?.resetTimer();
          this.timeLeft = '';
          if (this.videoTimer) {
            this.videoTimer.display = this.videoTimer?.transform(0);
          }
          this.backfromTrim = true;
          this.paused = false;
          this.videoRecordingFinished = false;
          this.controls.disabledReset = true;
          this.controls.disabledStart = false;
          this.disabledReset = true;
          if (!this.alreadyEnteredTrimmingStep) {
            this.startTestWebcam(false);
          }
          this.showRecordingWindow = false;
          this.alreadyEnteredTrimmingStep = false;
          if (this.restart_Start) {
            this.stopScrCstWebCam();
          }
          if(this.screencastWebcam.length != 0){
            // this.screencastWebcamService.stopCapture();
            this.startrecord = false;
            this.screenValue = undefined;
            this.screencastPreview=true;
            // this.startTestWebcam(false);
           }
          this.showAudio = true;
          this.showGreenRect = false;
          this.showMicEditBack = false;
        }
  };

  openAudioEnable() {
      for(let i = 0; i < this.videoInputValue.length; i++){
            if(this.videoInputValue[i].label === this.webcamRecorderService.currentVideoDevice){
              this.defaultVideoValue = this.videoInputValue[i];
            };
      }
    this.showAudioOption = !this.showAudioOption;
  }

  toggleAudio() {
    if (this.audioIcon === 'mic') {
      this.micVal = false;
      this.audioIcon = 'mic_off';
      this.disableAudio = true;
      this.audioSelectionTooltip = this.translateService.instant('Unmute');
      this.screencastWebcamService.getAudioToggle(false);
      this.stopAudioRecording();
      this.snackBar.open(this.translateService.instant('Muted'), '', {duration: 1000});
    } else {
      this.micVal = true;
      this.audioIcon = 'mic';
      this.disableAudio = false;
      this.audioSelectionTooltip = this.translateService.instant('Mute');
      this.screencastWebcamService.getAudioToggle(true);
      this.startAudioRecording();
      this.snackBar.open(this.translateService.instant('Unmuted'), '', {duration: 1000});
    }
  }

  toggleNoise() {
    if (this.noiseIcon === 'blur_on') {
      this.noiseSuppression = false;
      this.noiseIcon = 'blur_off';
      this.audioRecorderService.getNoiseToggle(false);
      this.screencastWebcamService.getNoiseToggle(false);
      this.stopAudioRecording();
      if (!this.disableAudio) {
        this.startAudioRecording();
      }
      this.noiseSuppressionTooltip = this.translateService.instant("Enable noise suppression");
      this.snackBar.open(this.translateService.instant('Noise suppression disabled'), '', {duration: 1000});
    } else {
      this.noiseSuppression = true;
      this.noiseValue = true;
      this.noiseIcon = 'blur_on';
      this.audioRecorderService.getNoiseToggle(true);
      this.screencastWebcamService.getNoiseToggle(true);
      if (!this.disableAudio) {
        this.startAudioRecording();
      }
      this.noiseSuppressionTooltip = this.translateService.instant("Disable noise suppression");
      this.snackBar.open(this.translateService.instant('Noise suppression enabled'), '', {duration: 1000});
    }
  }

  onAudioDropChange(event) {
    let DeviceId = event.value.deviceId;
    this.stopAudioRecording();
    this.audioRecorderService.getSelectionVal(DeviceId);
    this.screencastWebcamService.getSelectionVal(DeviceId);
    this.startAudioRecording();
  }

  onVideoDropChange(event) {
    this.stopTestWebcam();
    let DeviceId = event.value.deviceId;
    this.webcamRecorderService.getVideoSelectionVal(DeviceId);
    this.screencastWebcamService.getVideoSelectionVal(DeviceId);
    if(this.alreadyEnteredTrimmingStep){
      this.startTestWebcam(true);
    }else{
    this.startTestWebcam(false);
    }
  }

  async startAudioRecording(): Promise<void> {
    this.arecorder = await this.audioRecorderService.recordAudio();
    const mediaStream = this.arecorder.start();
    const audioContext = new AudioContext();
    const mediaStreamSource = audioContext.createMediaStreamSource(mediaStream);
    this.audioMeter = audioMeter.createAudioMeter(audioContext);
    mediaStreamSource.connect(this.audioMeter);
    this.onLevelChange();
  }

  async stopAudioRecording(): Promise<void> {
    if (this.arecorder) {
      await this.arecorder.stop();
      this.arecorder = null;
      this.meter.nativeElement
        .getContext('2d')
        .clearRect(0, 0, this.WIDTH, this.HEIGHT);
      this.audioMeter.shutdown();
    }
  }

  onLevelChange = (time = 0) => {
    if (this.audioRecorderService.recording()) {
      const context = this.meter.nativeElement.getContext('2d');
      context.clearRect(0, 0, this.WIDTH, this.HEIGHT);
      if (this.audioMeter.checkClipping()) {
        context.fillStyle = 'red';
      } else {
        context.fillStyle = localStorage.getItem("primary_color");
      }
      context.fillRect(
        0,
        0,
        this.audioMeter.volume * this.WIDTH * 1.4,
        this.HEIGHT
      );
      window.requestAnimationFrame(this.onLevelChange);
    }
  };

  metaReviewButton() {
    // window.moveTo(0, 0);
    // window.resizeTo(screen.width, screen.height);

    this.rerecord = false;
    this.controls.disabledStart = true;
    this.controls.disabledStart = true;
    // window.moveTo(0, 0);
    // window.resizeTo(screen.width, screen.height);


    if (
      this.vidValue['video_file'] === undefined ||
      this.vidValue['video_file'] === null
    ) {
      this.stopScrCstWebCam();
    } else if (this.vidValue['video_file'] != undefined && this.backfromTrim) {
      this.stopScrCstWebCam();
    } else {
      this.router.navigate(['create-video/' + this.vidValue['id'] + '/edit']);
      this.stopTestWebcam();
    }
  }

  downloadVideo() {
    const blob = new Blob([this.screencastWebcamService.chunks[0]], {type: 'video/webm'});
    // const blob = this.formData['video_file'];
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'screencast.webm';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100);

    // code below downloads video file, but the same screencast was downloaded
    // const blob2 = new Blob([this.screencastWebcamService.chunksMedia[0]], {type: 'video/webm'});
    // // const blob = this.formData['video_file'];
    // const url2 = window.URL.createObjectURL(blob2);
    // const a2 = document.createElement('a');
    // a2.style.display = 'none';
    // a2.href = url;
    // a2.download = 'webcam.webm';
    // document.body.appendChild(a2);
    // a2.click();
    // setTimeout(() => {
    //   document.body.removeChild(a2);
    //   window.URL.revokeObjectURL(url2);
    // }, 100);
  }

  private async initRecording(): Promise<void> {
    this.videoProcessingComplete = false;
    this.videoRecordingFinished = false;
    await this.videoTimer.startTimer();
  }

  goToHome(){
    this.ngOnDestroy();
    this.router.navigate(['start']);
  }

  goBackRemovePermission() {
    this.stopAudioRecording();
    this.stopTestWebcam();
  }
}
