import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

export interface IPAddress {
  id: number;
  ip: string;
  added_on: string;
}

@Component({
  selector: 'app-allowed-ip-addresses',
  templateUrl: './allowed-ip-addresses.component.html',
  styleUrls: ['./allowed-ip-addresses.component.scss']
})
export class AllowedIpAddressesComponent implements OnInit {

  ip_addresses: IPAddress[] = [];
  ip_address_input: string = "";

  @ViewChild('ip_table') ip_table: MatTable<IPAddress[]>;

  language: string = 'en';

  constructor(
    public dataService: DataService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<AllowedIpAddressesComponent>,
    ) { }

  ngOnInit(): void {
    this.loadIPAddresses();
    this.language = this.translateService.currentLang;
  }
  loadIPAddresses() {
    this.dataService.getURL<IPAddress[]>('manager/ips/', {observe: 'body', responseType: 'json'})
      .subscribe((res) => {
          this.ip_addresses = res;
        }, (err) => {
          window.alert(err.error);
        }
      );
  }

  addIPAddress() {
    if (this.ip_address_input) {
      // todo: enforce the ip address format
      this.dataService.postURL<IPAddress>('manager/ips/', {ip_address: this.ip_address_input},
        {observe: 'body', responseType: 'json', headers: {'Accept-Language': this.language}})
        .subscribe((res) => {
          // then clear
          this.ip_address_input = "";
          // success snackbar
          this.snackBar.open(this.translateService.instant('Erfolgreich'), '', { duration: 2500 });
          this.ip_addresses.push(res);
          this.ip_table.renderRows();
        }, (err) => {
          window.alert(err.error);
        });
    }
  }

  deleteIPAddress(element) {
    // delete
    let message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      this.dataService.deleteURL(`manager/ips/?id=${element.id}`).subscribe(() => {
        const index = this.ip_addresses.indexOf(element, 0);
        if (index > -1) {
          this.ip_addresses.splice(index, 1);
          this.ip_table.renderRows();
        }
        else {
          this.loadIPAddresses();
          this.ip_table.renderRows();
        }
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', { duration: 2500 });
      }, () => {});
    }
  }

  closePopup() {
    this.dialogRef.close();
  }
}
