import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DataService} from '../services/data.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {NavbarService} from "../services/navbar.service";
import {TranslateService} from "@ngx-translate/core";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  email: any = '';
  otp: any = '';
  fontcolor: string;
  otpVerified = false;
  hidePassword = true;

  otpForm = this.formBuilder.group({
    otp: ['', Validators.required]
  });

  regForm = this.formBuilder.group({
    password: ['', [Validators.required,
      Validators.minLength(10),
      Validators.maxLength(30),
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).*$/)]],
  });

  mailIDForm = this.formBuilder.group({
    email: ['', [Validators.required]],
  });

  constructor(private formBuilder: UntypedFormBuilder,
              private snackBar: MatSnackBar,
              private router: Router,
              public dataService: DataService,
              public translateService: TranslateService) {
    this.otpForm.valueChanges.subscribe(() => {
      if (this.otpForm.value.otp.length >= 4) {
        this.otpFormClicked();
      }
    })
  }

  ngOnInit(): void {
  }

  // send otp
  mailFormClicked(): void {
    if (this.mailIDForm.status != "INVALID") {
      this.email = this.mailIDForm.value.email;
      this.sendOTP();
    }
  }

  sendOTP() {
    this.dataService.postURL('forgot-password-request/', {email: this.email}, {
      observe: 'body', responseType: 'json', headers: {
        'Accept-Language': this.translateService.currentLang
      }
    }).subscribe((res) => {
      this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
    }, (error: HttpErrorResponse) => {
      window.alert(error.error);
    });
  }

  // verify otp
  otpFormClicked(): void {
    if (this.otpForm.status == 'VALID') {
      // tslint:disable-next-line:radix
      this.otpForm.value.otp = parseInt(this.otpForm.value.otp);
      this.dataService.postURL('verify-otp/', {
        ...this.mailIDForm.value,
        ...this.otpForm.value
      }).subscribe(() => {
          this.otp = this.otpForm.value.otp;
          this.otpVerified = true;
      }, (err: HttpErrorResponse) => {
        window.alert(err.error);
      });
    }
  }

  // save new password
  registered(): void {
    if (this.regForm.valid) {
      // tslint:disable-next-line:radix
      this.otpForm.value.otp = parseInt(this.otpForm.value.otp);
      this.dataService.postURL('forgot-password-complete/', {
        ...this.mailIDForm.value,
        ...this.otpForm.value,
        ...this.regForm.value
      }, {
        observe: 'body', responseType: 'json', headers: {
          'Accept-Language': this.translateService.currentLang
        }
      }).subscribe((res) => {
        if (res['reason']) {
          window.alert(res['reason']);
        } else {
          this.mailIDForm.value.email = '';
          this.email = '';
          this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
          this.router.navigate(['login']);
        }
      });
    }
  }

  mailFormBackClicked(): void {
    this.mailIDForm.value.email = '';
    this.email = '';
    this.router.navigate(['login']);
  }

  otpFormBackClicked(): void {
    this.otpForm.get('otp').setValue('');
    this.regForm.get('password').setValue('');
    this.otp = '';
    this.email = '';
    this.otpVerified = false;
  }
}
