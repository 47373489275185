import {Component, OnInit, ViewChild,} from '@angular/core'
import {DataService} from '../services/data.service'
import {AuthService} from '../services/auth.service'
import {NavbarService} from '../services/navbar.service'
import {MatSnackBar} from '@angular/material/snack-bar'
import {Router} from '@angular/router'
import {TranslateService} from '@ngx-translate/core'
import {Topic, TopicCard} from '../models/video/video.interface';
import {UtilityService} from "../services/utility.service";
import {MatDialog} from "@angular/material/dialog";
import {UploaderChangeDialogComponent} from "../dashboard/uploader-change-dialog/uploader-change-dialog.component";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-topics-overview',
  templateUrl: './topics-overview.component.html',
  styleUrls: ['./topics-overview.component.scss']
})
export class TopicsOverviewComponent implements OnInit {
  @ViewChild('input') public search: any;

  searchQuery: string = "";
  filterValue: string = 'all';
  showSpinner: boolean = false;
  sort_option: string = 'last_edited_on';

  topics: TopicCard[] = [];
  filteredTopics: TopicCard[] = [];
  options = {
    observe: 'body', responseType: 'json'
  }
  snackbarConfig = {duration: 2500};
  is_external_sharing_disabled: boolean = true;
  is_creator: boolean = false;  // to check if user is a creator or not

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private dialog: MatDialog,
    protected navbarService: NavbarService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    protected utilityService: UtilityService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    // todo: wait for it to load
    this.navbarService.getUserProfileDetails();
    this.navbarService.disableCreationMode();
    this.is_external_sharing_disabled = this.authService.company.is_external_sharing_disabled;
    this.getTopics();
  }

  getTopics() {
    this.showSpinner = true;
    this.dataService.getURL('user/topics/?mine', this.options).subscribe((res: TopicCard[]) => {
        this.topics = res;
        this.showSpinner = false;
        this.applyFilter();
        this.is_creator = this.authService.userDetails.is_creator;
      },
      (err) => {
        this.handleError(err);
      }
    )
  }

  applyFilter() {
    // performs both search and filter

    // first search
    this.filteredTopics = this.topics.filter(r => r.title.toLowerCase().includes(this.searchQuery.toLowerCase()));

    // then filter
    switch (this.filterValue) {
      case 'drafts':
        this.filteredTopics = this.filteredTopics.filter(r => r.is_draft);
        break;
      case 'published':
        this.filteredTopics = this.filteredTopics.filter(r => !r.is_draft);
        break;
      case 'external':
        this.filteredTopics = this.filteredTopics.filter(r => r.is_external);
        break;
      default:
        // all case is already taken care of
        break;
    }

    // then sort
    switch (this.sort_option) {
      case 'added_on':
        this.filteredTopics = this.filteredTopics.sort((a, b) => new Date(b.added_on).getTime() - new Date(a.added_on).getTime());
        break;
      case 'last_edited_on':
        this.filteredTopics = this.filteredTopics.sort((a, b) => new Date(b.last_edited_on).getTime() - new Date(a.last_edited_on).getTime());
        break;
      case 'title':
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#sorting_array_of_objects
        this.filteredTopics = this.filteredTopics.sort((a, b) => {
          const nameA = a.title.toUpperCase();
          const nameB = b.title.toUpperCase();
          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          } else {
            return 0;
          }
        });
        break;
      default:
        break;
    }
  }

  topicAction(id: string, action: string) {
    let formBody = {};
    switch (action) {
      case 'edit':
        this.router.navigate(['pages', 'edit', id]);
        return;
      case 'draft':
        formBody = {
          is_draft: true
        };
        break;
      case 'publish':
        formBody = {
          is_draft: false
        };
        break;
      case 'external':
        formBody = {
          is_draft: false,
          is_external: true
        };
        break;
      case 'internal':
        formBody = {
          is_external: false
        };
        break;
      default:
        return;
    }

    this.showSpinner = true;
    this.dataService.putURL(`user/topics/${id}/`, formBody, this.options).subscribe((res: Topic) => {
      this.showSpinner = false;
      const index = this.topics.findIndex(e => e.id == id);
      this.topics[index].is_draft = res.is_draft;
      this.topics[index].is_external = res.is_external;
      this.applyFilter();
      this.snackBar.open(this.translateService.instant('Erfolgreich'), '', this.snackbarConfig);
    }, (err) => {
      this.handleError(err);
    });
  }

  deleteTopic(id: string, title: string) {
    const message = this.translateService.instant('Delete_topic', {val: title});
    if (window.confirm(message)) {
      this.showSpinner = true;
      this.dataService.deleteURL(`user/topics/${id}`).subscribe(() => {
        const index = this.topics.findIndex(e => e.id == id);
        this.topics.splice(index, 1);
        this.applyFilter();
        this.showSpinner = false;
        this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', this.snackbarConfig);
      }, (err) => {
        this.handleError(err);
      })
    }
  }

  duplicateTopic(id: string) {
    this.showSpinner = true;
    this.dataService.putURL(`user/topics/`, { id: id }, {
      observe: 'body', responseType: 'text'
    }).subscribe((res: string) => {
      this.topicAction(res, 'edit');
    }, (err: HttpErrorResponse) => {
      this.handleError(err);
      window.alert(err.error);
    })
  }

  changeUploader(topic: TopicCard): void {
    const dialogRef = this.dialog.open(UploaderChangeDialogComponent, {
      minWidth: '400px',
      width: '50vw',
      maxWidth: '600px',
      data: topic,
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // confirm and save
          if (result != topic.uploader.id) {
            const user_object = this.authService.checklist_data.find(e => e.id == result && e.type == 'user');
            let message = user_object.name;
            message += " "
            message += this.translateService.instant("will also be notified about this.");
            message += '\n\n';
            message += this.translateService.instant("Would you like to continue?");
            if (window.confirm(message)) {
              this.showSpinner = true;
              this.dataService.putURL(`user/topics/${topic.id}/`, {uploader: result}).subscribe(() => {
                this.snackBar.open(this.translateService.instant('Erfolgreich'), '', this.snackbarConfig);
                const index = this.topics.findIndex(e => e.id == topic.id);
                if (index > -1) {
                  this.topics.splice(index, 1);
                  this.applyFilter();
                }
                this.showSpinner = false;
              }, (err: HttpErrorResponse) => {
                this.handleError(err);
                window.alert(err.error);  // above method only shows generic snackbar
              });
            }
          }
        }
      }
    );
  }

  handleError(err) {
    this.showSpinner = false;
    console.error(err);
    this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', this.snackbarConfig);
  }

  viewTopic(topic: TopicCard) {
    if (topic.is_draft) {
      // if draft, go to edit
      this.topicAction(topic.id, 'edit');
    } else {
      // go to view page
      // todo: send snapshot data: sideBar: true, topBar: true
      this.router.navigate(['pages', 'view', topic.id]);
    }
  }
}
