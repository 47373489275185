<!-- recording step starts-->
<!--webcam recording-->
<ng-template [ngIf]="videoTypeVal === videoTypes.WEBCAM">
  <app-webcam-creation [vidValue]="vidValue">
  </app-webcam-creation>
</ng-template>

<!--screencast recording-->
<ng-template [ngIf]="videoTypeVal === videoTypes.SCREENCAST">
  <app-screencast-creation [vidValue]="vidValue">
  </app-screencast-creation>
</ng-template>

<!--screencast - webcam recording-->
<ng-template [ngIf]="videoTypeVal === videoTypes.BOTH">
  <app-screencast-webcam-creation [vidValue]="vidValue">
  </app-screencast-webcam-creation>
</ng-template>

<!--upload video-->
<ng-template [ngIf]="videoTypeVal === videoTypes.UPLOAD">
  <app-upload-creation [vidValue]="vidValue"></app-upload-creation>
</ng-template>

<!--testing: new approach, can be deleted later-->
<ng-template [ngIf]="videoTypeVal === videoTypes.PRESENTATION">
  <app-sc-record-dialog [videoObj]="vidValue"></app-sc-record-dialog>
</ng-template>

<!--recording step over-->
