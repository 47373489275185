import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from "../services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../services/auth.service";
import {UtilityService} from "../services/utility.service";
import {TopicView, Section, Topic, MiniDetails} from '../models/video/video.interface';
import {SafeHtml, DomSanitizer} from '@angular/platform-browser';
import {inIframe} from "../login/login.component";
import {HttpErrorResponse} from "@angular/common/http";
import * as mime from 'mime';
import {NavbarService} from "../services/navbar.service";
import {PopupData, SharePopupComponent} from "../shared/share-popup/share-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {Location} from "@angular/common";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {TopicBottomSheetComponent} from "../shared/topic-bottom-sheet/topic-bottom-sheet.component";
import { PdfViewerPopupComponent } from '../pdf-viewer-popup/pdf-viewer-popup.component';
import {SectionTranslation, TopicTranslation} from "../models/topic.interface";
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../environments/environment";

interface SectionLink {
  name: string;
  title: string;
  id: string;
}

@Component({
  selector: 'app-view-topics',
  templateUrl: './view-topics.component.html',
  styleUrls: ['./view-topics.component.scss']
})

export class ViewTopicsComponent implements OnInit, OnDestroy {
  isIframe: boolean = false;
  // to show a topic without top and sidebar
  // hide back button

  isAuthenticated: boolean = false;
  // public: show a topic without edit/review button. Back button would perform differently
  // iframe + private: show login button
  // not iframe + private: go to login page

  isTopicManager: boolean = false;
  isTopicCreator: boolean = false;

  topic_id: string = "";
  desc: string = '';
  topic: TopicView = null;
  section_links: SectionLink[] = [];
  sections_to_display: Section[] = [];  // using a separate array after storing sorted data
  section_translations: SectionTranslation[] = [];
  display_message: string = "";
  is_login_needed: boolean = false;  // if topic is not public and status is 406, then show login button
  is_access_key_needed: boolean = false;
  is_access_key_hidden: boolean = true;
  access_key: string = "";
  currently_displayed_image_src: string = "";
  tagsData: MiniDetails[] = [];
  translations: TopicTranslation[] = [];
  currentSectionIndex: number = 0;
  currentSection: Section = null;
  current_url: string = '';

  // company specific params
  is_external_views_shown: boolean = false;
  company_logo: string = "";
  overlay_logo: string = "";
  company_id: number = 0;

  constructor(
    private dataService: DataService,
    private bottomSheet: MatBottomSheet,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    public utilityService: UtilityService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    protected navbarService: NavbarService,
    private location: Location,
    private snackBar: MatSnackBar,
  ) {
    // capturing route param for topics id
    this.route.paramMap.subscribe(async (map) => {
      this.topic_id = map.get('id');
    });

    // load the access key
    this.route.queryParams.subscribe(params => {
      this.access_key = params['access_key'] || "";  // either undefined or an empty string
    });
  }

  ngOnDestroy() {
    this.closeGallery();
  }

  ngOnInit(): void {
    this.current_url = this.location.path();

    if (inIframe()) {
      this.isIframe = true;
    }

    this.isAuthenticated = this.authService.isAuthenticated();
    if (this.isAuthenticated) {
      // if user is logged in, check if profile is complete
      this.navbarService.getUserProfileDetails();
      if (!this.isIframe) {
        // to see sideBar, user must be logged in outside iframe
        this.navbarService.disableCreationMode();
      }
    }
    this.loadTopic();
  }

  // get all tags
  loadAllTags() {
    // find the tag details
    this.tagsData = this.authService.tag_data.filter(obj => this.topic.tags.includes(obj.id));
  }

  loadTopic(): void {
    this.sections_to_display = [];  // needs to be cleared as manager may make a topic draft
    this.translations = [];
    this.section_links = [];
    this.section_translations = [];
    this.topic = null;

    // load the topic, based on logged in or logged out state
    let url = `public/topics/${this.topic_id}/`;
    if (this.isAuthenticated) {
      url = `user/topics/${this.topic_id}/`
    }

    if (this.access_key) {
      url += "?access_key=" + encodeURIComponent(this.access_key);
      this.location.replaceState(`/pages/view/${this.topic_id}?access_key=${encodeURIComponent(this.access_key)}`);
      this.current_url = this.location.path();
    }

    this.dataService.getURL(url, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: TopicView) => {
      this.topic = res;
      // only load tags if there are any
      if (this.topic.tags.length && this.isAuthenticated) {
        this.loadAllTags();
      }

      // initiate a timeout so that when links expire, user can refresh
      this.initiateTimeoutForIframe();

      if (this.isAuthenticated) {
        // user is watching topic of own company
        this.is_external_views_shown = this.authService.company.is_external_views_shown;
        this.company_id = this.topic.company;
        this.overlay_logo = this.authService.company.overlay_logo;
        this.company_logo = this.authService.company.company_logo;
      } else {
        // user is watching topic of another company
        this.is_external_views_shown = this.topic.company['is_external_views_shown'];
        this.company_id = this.topic.company['id'];
        this.overlay_logo = this.topic.company['overlay_logo'];
        this.company_logo = this.topic.company['company_logo'];
      }

      this.initiateTranslations();

      this.display_message = "";  // to hide the access key input

      // find my role
      if (this.isAuthenticated) {
        if (res.uploader.id == this.authService.userDetails.user) {
          this.isTopicCreator = true;
        }

        if (res.company == this.authService.company.id) {
          // now check if I am a manager or not
          if (this.authService.userDetails.is_quality_manager || this.authService.userDetails.is_company_manager) {
            this.isTopicManager = true;
          } else if (this.authService.userDetails.is_department_manager) {
            if (this.topic.uploader.userprofile.department == this.authService.userDetails.department) {
              this.isTopicManager = true;
            }
          } else if (this.authService.userDetails.is_team_manager) {
            if (this.topic.uploader.userprofile.team == this.authService.userDetails.team) {
              this.isTopicManager = true;
            }
          }
        }
      }

      // sort sections
      let currentId: number | null = null;
      if (this.topic.sections.length) {
        while (true) {
          const prev_section = res.sections.find((item) => item.next_section === currentId);
          if (!prev_section) {
            // no prev section found, this is the first one
            break;
          }
          this.sections_to_display.unshift(prev_section);
          currentId = prev_section.id;
          // res.sections = res.sections.filter((item) => item !== prev_section);
        }
      }

      // first load the sections from original language
      this.changeLanguage(this.translations[0]);

      // now change language to user language, if any
      let user_profile_language = navigator.language;
      if (this.isAuthenticated) {
        // ignore browser language in case if user is authenticated
        user_profile_language = this.authService.userDetails.email_language;
      }
      // find based on 5 characters
      let tl_index = this.topic.translations.findIndex(e => e.language == user_profile_language);
      if (tl_index == -1) {
        // find based on first two characters
        tl_index = this.topic.translations.findIndex(e => e.language.slice(0, 2) == user_profile_language.slice(0, 2));
      }
      if (tl_index > -1) {
        // language found
        this.changeLanguage(this.translations[tl_index + 1]);  // +1 as original language is there
        this.snackBar.open(
          this.translateService.instant('Page translated'),
          this.translateService.instant('Undo'),
          {duration: 3500, horizontalPosition: 'left'}
        ).onAction().subscribe(() => {
          this.changeLanguage(this.translations[0]);
        });
      }
    }, (err: HttpErrorResponse) => {
      console.error(err);
      // avoid showing alerts in iframes
      this.display_message = err.error;
      if (err.status == 406) {
        // topic is published but not public
        this.is_login_needed = true;
      } else if (err.status == 409) {
        // Incorrect current password
        this.is_access_key_needed = true;
      } else if (err.status == 423) {
        // This video is protected by an access code
        this.is_access_key_needed = true;
      } else if (err.status == 429) {
        this.is_access_key_needed = false;
        this.display_message = err.statusText;
      } else if (err.status == 404) {
        // try again with the public api
        if (this.isAuthenticated) {
          this.isAuthenticated = false;
          this.loadTopic();
        }
      }
    });
  }

  initiateTimeoutForIframe() {
    // if user is in an iframe, then open snackbar after links expire
    if (this.isIframe) {
      const timeoutHandler = setTimeout(() => {
        // open the still-there component
        clearTimeout(timeoutHandler);
        // show snackbar with action
        const message = this.translateService.instant("Are you still there?");
        const action = this.translateService.instant("Ja");
        this.snackBar.open(message, action, {horizontalPosition: 'center'}).onAction().subscribe(() => {
          location.reload();
        });
      }, environment.timeoutDuration * 1000); // *1000 into milliseconds
    }
  }

  initiateTranslations() {
    // add original language to translation
    this.translations.push({
      id: 0,
      title: this.topic.title,
      language: this.authService.languages.get(this.topic.language),
      emoji: this.topic.emoji,
      last_edited_on: this.topic.last_edited_on
    });

    // add remaining language
    for (let i of this.topic.translations) {
      this.translations.push({
        id: i.id,
        title: i.title,
        language: this.authService.languages.get(i.language),
        emoji: i.emoji,
        last_edited_on: i.last_edited_on
      });
    }

    // add original section content to the array
    for (let i of this.topic.sections) {
      this.section_translations.push({
        section: i.id,
        translated_topic: 0,
        content: i.content as string,
        id: 0,  // we don't care about it
      })
    }
  }

  downloadAndLoadTranslation(translation: TopicTranslation) {
    let url = `public/topic-translations/${translation.id}/`;
    if (this.isAuthenticated) {
      url = `user/topic-translations/?translation_id=${translation.id}`;
    }
    this.dataService.getURL(url, {observe: 'body',
      responseType: 'json'
    }).subscribe((res: SectionTranslation[]) => {
      if (res.length == this.sections_to_display.length) {
        this.section_translations.push(...res);
        this.changeLanguage(translation, true);  // recursive ensures that the loop is not infinite
      }
    }, (err: HttpErrorResponse) => {
      window.alert(err.error);
    });
  }

  changeLanguage(translation: TopicTranslation, recursive: boolean = false){
    // update basic data first
    this.topic.language = translation.language;
    this.topic.emoji = translation.emoji;
    this.topic.title = translation.title;

    // now load section, only if there are any
    if (this.sections_to_display.length) {
      for (let i of this.sections_to_display) {
        // find the translated block
        const translated_block = this.section_translations.find(e => e.translated_topic == translation.id && e.section == i.id);
        if (translated_block) {
          i.content = this.getTrustedHtml(translated_block.content);
        } else {
          // download data and try again
          if (!recursive) {
            this.downloadAndLoadTranslation(translation);
          }
          return;
        }
      }
    }

    // initiate observer after a second
    const timeout = setTimeout(() => {
      this.initiateTableOfContent();
      this.initiateMediaPlaybackObserver();
      clearTimeout(timeout);
    }, 500);
  }

  // in iframe, copy link, else open share popup
  shareTopic() {
    // disable close if user is uploader
    let disableClose: boolean = false;
    if (this.isTopicCreator) {
      disableClose = true;
    }

    // prepare popup data
    const data: PopupData = {
      ...this.topic,
      type: "topic",
    }
    // open popup
    const dialogRef = this.dialog.open(SharePopupComponent, {
      disableClose: disableClose,
      data: data
    });
    // update existing topic if it has changed
    dialogRef.afterClosed().subscribe((res: Topic) => {
      if (res) {
        // find topic and update it
        this.topic = {
          ...res,
          sections: [],  // we don't care about sections as they are already initialised
          translations: [],  // we don't care about translations as they are already initialised
        };
        // this is needed, otherwise the language and title are reset to en-US form
        this.changeLanguage(this.translations[0]);
      }
    });
    return;
  }

  getTrustedHtml(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }


  scrollTo(section_id: string) {
    let element = document.querySelector('#' + section_id);
    element.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  initiateTableOfContent() {
    this.section_links = [];
    // find all sections in this form and observer them
    document.querySelectorAll('section').forEach((section, index) => {
      // push it as well
      if (section.id.startsWith(`section-${this.topic_id}-`)) {
        let name = section.innerText;
        name = name.trim();

        const n_characters: number = 100;

        if (name.length == 0) {
          // check if it has title from an iframe
          if (section.innerHTML.includes('title=')) {
            name = section.innerHTML.split('title="')[1]?.split('"')[0];
          } else {
            // no innerText, no title, probably empty
            name = this.translateService.instant('Empty section');
            name += ` (${index + 1})`;
          }
        }
        const title = name.substring(0, 100);
        if (name.length > n_characters) {
          name = name.substring(0, n_characters) + '…';
        }

        this.section_links.push({
          name: name,
          id: section.id,
          title: title
        });
      }
    })
  }

  // subscribe to an event: if any video/audio is played, then pause all remaining ones
  initiateMediaPlaybackObserver() {
    let all_video_elements: HTMLVideoElement[] = [];

    // append outer elements of parent document
    const outer_video_elements = document.querySelectorAll('video');
    outer_video_elements.forEach(ele => {
      all_video_elements.push(ele);
    });

    // wait for three seconds when the iframes are probably ready
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      const all_iframe_elements = document.querySelectorAll('iframe');
      all_iframe_elements.forEach(ele => {
        if (ele.contentDocument) {
          // null in case of different origins
          const inner_video_elements = ele.contentDocument.querySelectorAll('video');
          inner_video_elements.forEach(ele => {
            all_video_elements.push(ele);
          })
        }
      });

      // now, assign a callback to them
      all_video_elements.forEach(outsideElement => {
        // find all videos and when any video is played, pause remaining ones
        outsideElement.onplay = function (e) {
          // pause remaining ones
          all_video_elements.forEach(insideElement => {
            if (outsideElement != insideElement) {
              // if this is a different element
              if (!insideElement.paused) {
                // if this element is playing, pause it.
                insideElement.pause();
              }
            }
          })
        };
      });
    }, 3000);
  }

  getAttachedFilename(url) {
    let attachFileName = url.split('/').pop();
    return attachFileName.split("?")[0];
  }

  getMimeType(url: string): string {
    let attachmentUrl = this.getAttachedFilename(url);
    const mimeType = mime.getType(attachmentUrl);

    if (mimeType == null){
      // can be null if not found anything
      return 'other';
    }

    if (mimeType.includes('video')) {
      return 'video';
    } else if (mimeType.includes('image')) {
      return 'image';
    } else if (mimeType.includes('audio')) {
      return 'audio';
    } else if (mimeType.includes('pdf')) {
      return 'pdf';
    } else {
      return 'other';
    }
  }

  goBack() {
    if (this.isAuthenticated) {
      // go one page back
      window.history.back();
    } else {
      if (this.topic) {
        // go to company's public page
        this.router.navigate(['public', this.company_id]);
      } else {
        // go to login page
        this.router.navigate(['login']);
      }
    }
  }

  closeGallery() {
    document.getElementsByClassName('mat-sidenav')[0]['style'].visibility = 'visible';
    this.currently_displayed_image_src = "";
  }

  openImageOverlay(src: string) {
    this.currently_displayed_image_src = src;
    document.getElementsByClassName('mat-sidenav')[0]['style'].visibility = 'hidden';
  }

  tagSelected(tag_id: number){
    if (this.isIframe) {
      // stay on page and open tag in new tab
      window.open(`tag/${tag_id}`, '_blank');
    } else {
      this.router.navigate(['tag', tag_id]);
    }
  }

  applyBulkAction() {
    this.bottomSheet.open(TopicBottomSheetComponent, {
      data: {
        ids: [this.topic_id]
      }
    }).afterDismissed().subscribe(res => {
      if (res) {
        this.loadTopic();
      }
    });
  }

  openPdfPopup(src: string) {
    const _dialogRef = this.dialog.open(PdfViewerPopupComponent, {
      minWidth: "80%",
      height: "98%",
      disableClose: false,
      autoFocus: false,
      data: {
        src: src,
        title: this.getAttachedFilename(src)
      }
    });
  }

  updateCurrentSection(index: number) {
    this.currentSectionIndex = index;
    if (this.currentSectionIndex > 0 && this.currentSectionIndex <= this.sections_to_display.length) {
      this.currentSection = this.sections_to_display[this.currentSectionIndex - 1];
    } else {
      this.currentSection = null;
    }
  }

  protected readonly top = top;
}

