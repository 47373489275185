<ng-container [ngSwitch]="timerMode">
    <div class="d-flex pb-5 justify-content-center" *ngSwitchCase="timerModes.SPINNER">
        <mat-progress-spinner mode="determinate" color="primary" [ngClass]="color" diameter="80"
                              class="spinner-foreground"
                              [value]="(currentTime / timeLimit) * 100"></mat-progress-spinner>
        <mat-progress-spinner mode="determinate" class="gray spinner-background" diameter="80"
                              value="100"></mat-progress-spinner>
        <div class="position-within-spinner">
            <span style="font-family: 'nunito';font-size: 16px;">{{display}}</span>
        </div>
    </div>
    <div *ngSwitchCase="timerModes.TIME">
        <span class="timer-value"  style="font-family: 'nunito';" [ngClass]="color">{{display}}</span>
    </div>
</ng-container>
