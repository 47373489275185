import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsSignedInGuardService {
  constructor(public auth: AuthService, public router: Router) {
  }

  canActivate(state: RouterStateSnapshot, route : ActivatedRoute): boolean {
    if (this.auth.isAuthenticated()) {
      this.router.navigate(["/"]); // or home
      return false;
    }
    return true;
  }
}
