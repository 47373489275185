<div fxLayout="row" class="profile-page">

  <form [formGroup]="regForm" (ngSubmit)="updateUserProfile()" style="width: 100%">

    <div fxLayout="row" style="min-height: 100%;background-color: white">
      <mat-card class="w-100" style="height:100%;background-color: white;">
        <mat-card-content class="profile-content">
          <div class="row d-flex justify-content-between">
            <div class="col-8 d-flex flex-column">
              <mat-card-title class="font-poppins ml-2 my-3" translate>
                Mein Profil
              </mat-card-title>
              <div class="row d-flex">
                <div class="col-6">
                  <mat-form-field appearance="outline" class="profile-field-group-item w-100">
                    <mat-label translate>Email-Adresse</mat-label>
                    <input style="font-size: 17px;padding-top: 5px;" matInput type="text"
                           autocomplete="email" formControlName="email" />
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <span class="fontNormal profile-text-input w-100">
                    <mat-form-field appearance="outline" class="profile-text-input fontNormal"
                      style="width: 100% !important;">
                      <mat-label>Organisation</mat-label>
                      <input style="font-size: 17px;padding-top: 5px;" matInput type="text"
                             autocomplete="organization" formControlName="company" />
                    </mat-form-field>
                  </span>
                </div>
              </div>

              <div class="profile-field-group flex-wrap">
                <div class="flex-column profile-field-group d-flex fontNormal"
                     *ngIf="authService.currentUser.is_profile_completed"
                     style="margin-top: 0rem;">
                  <span class="text-nunito">
                    <span class="license-label mb-3"
                          *ngIf="authService.currentUser.is_creator"
                          translate>
                      Creator - you can create, see and share content
                    </span>
                    <span class="license-label mb-3"
                          *ngIf="!authService.currentUser.is_creator"
                          translate>
                      Viewer - you can see and share content
                    </span>
                  </span>
                  <div class="mb-3" *ngIf="!authService.currentUser.is_creator">
                    <span class="mr-1 font-medium" translate>
                      To create your own content, you need Creator rights.
                    </span>
                    <span class="fontNormal line font-medium"
                          [ngStyle]="regForm.get('is_profile_completed').value ? {'cursor': 'pointer'} : {}"
                          (click)="openCreatorDialog()" translate>
                      (JETZT BEANTRAGEN).
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 d-flex flex-fill flex-column align-items-end">
              <img class="profile-pic-img" [src]="profile_pic_url" alt="profile picture"
                   onerror="this.src='https://avatar-service-platform.personio.de/'" />
              <label class="h-100 mt-3 cursor-pointer text-center"
                     style="width: 150px;"
                     for="file-upload">
                <!--width 150px to match above image-->
                <mat-icon class="material-icons-outlined mr-2"
                          style="vertical-align: bottom;"
                          matPrefix>edit</mat-icon>
                <span translate>Hochladen</span>
              </label>
              <input (change)="fileSelected($event)" [multiple]="false"
                     class="d-none" accept=".jpg,.png," id="file-upload"
                     type="file"/>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col-sm-12">
              <mat-form-field appearance="outline"
                class="checkButton profile-text-input fontNormal profile-field-group-item">
                <mat-label translate>Vorname</mat-label>
                <input class="fontNormal" style="font-size: 16px;padding-top: 5px;" matInput type="text"
                       autocomplete="given-name" formControlName="first_name" />
              </mat-form-field>
            </div>
            <div class="col-md-4 col-sm-12">
              <mat-form-field appearance="outline" class=" checkButton profile-text-input profile-field-group-item">
                <mat-label translate>Nachname</mat-label>
                <input class="fontNormal" style="font-size: 16px;padding-top: 5px;" matInput type="text"
                       autocomplete="family-name" formControlName="last_name" />
              </mat-form-field>
            </div>

            <div class="col-md-4 col-sm-12">
              <mat-form-field appearance="outline" class="profile-text-input profile-field-group-item">
                <mat-label translate>Standort</mat-label>
                <mat-select formControlName="location" panelClass="{{loc_dd_width > 30 ? 'dynamic_width' : 'null'}}"
                            style="font-size: 16px;padding-top: 5px;">
                  <mat-option *ngFor="let item of locationResp " [value]="item.id">{{item.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>
          <div class="row">
            <div class="d-flex col-md-4 col-sm-12">
              <mat-form-field appearance="outline" style="width:100%" class="checkButton profile-text-input fontNormal">
                <mat-label translate>{{dept_alias}}</mat-label>
                <mat-select style="font-size: 16px;padding-top: 5px;" formControlName="department" panelClass="{{dept_dd_width > 30 ? 'dynamic_width' : 'null'}}"
                  (selectionChange)="onDepartmentSelect();">
                  <mat-option *ngFor="let department of departments" [value]="department.id"  >{{department.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span style="margin-top: 1.85rem" class="side-checkbox">
                <mat-checkbox class="checkval" [checked]="authService.userDetails?.is_approved" color="primary"
                  matTooltip="{{'Accepted/Approved by a manager' | translate}}" disabled></mat-checkbox>
              </span>
            </div>
            <div class="d-flex col-md-4 col-sm-12">
              <mat-form-field appearance="outline" style="width:100%"
                class="checkButton profile-text-input profile-field-group-mid-item1">
                <mat-label translate>{{team_alias}}</mat-label>
                <mat-select style="font-size: 16px;padding-top: 5px;" formControlName="team" panelClass="{{team_dd_width > 30 ? 'dynamic_width' : 'null'}}">
                  <mat-option *ngFor="let team of teams" [value]="team.id">{{team.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span style="margin-top: 1.85rem" class="side-checkbox">
                <mat-checkbox class="checkval" [checked]="authService.userDetails?.is_approved" color="primary"
                  matTooltip="{{'Accepted/Approved by a manager' | translate}}" disabled>
                </mat-checkbox>
              </span>
            </div>
            <!-- <div class="col-md-4"></div> -->
            <div class="col-md-4 col-sm-12">
              <mat-form-field appearance="outline"
                              class="profile-text-input profile-field-group-item">
                <mat-label translate>Position</mat-label>
                <mat-select formControlName="user_role" style="font-size: 16px;padding-top: 5px;">
                  <mat-option *ngFor="let item of rolesResp " [value]="item.id">{{item.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="d-flex flex-column">
            <div class="d-flex mb-3">
              <mat-checkbox class="acptCheckbox mr-2" color="primary" formControlName="is_privacy_accepted">
              </mat-checkbox>
              <span class="fontNormal" translate>I agree to the</span>&nbsp;
              <a [href]="clypp_privacy_policy_url" target="'_blank'"
                 aria-label="click to view clypp privacy policy">
                <span class="fontNormal" translate>Clypp privacy policy</span>
              </a>
              <ng-container *ngIf="custom_privacy_policy_url">
                &nbsp;<span class="fontNormal" translate>and</span>&nbsp;
                <a [href]="custom_privacy_policy_url" target="'_blank'"
                 aria-label="click to view custom privacy policy">
                <span class="fontNormal">{{ custom_privacy_policy_text }}</span>
              </a>
              </ng-container>
            </div>
            <mat-accordion style="margin-bottom: 20px;">
              <mat-expansion-panel class="selected mat-elevation-z0" expanded="true">
                <mat-expansion-panel-header>
                  <mat-panel-title class="fontBold">
                    <mat-icon mat-list-icon class="mr-2">language</mat-icon>
                    {{'Accounteinstellungen'|translate}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <span class="greyText" translate>Hier findest du Grundeinstellungen zu deinem Account</span>
                <div>
                  <span translate>Language</span>
                  <mat-form-field class="fontNormal" appearance="outline" style="margin-left:12px">
                    <mat-select (selectionChange)="setTransLanguage($event.value)" class="fontNormal"
                                [value]="authService.userDetails?.email_language"
                                placeholder="{{'Please select' | translate}}...">
                      <mat-option *ngFor="let lang of languages" class="fontNormal" [value]="lang[0]">
                        {{lang[1]}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-icon class="ml-2" style="vertical-align: middle;"
                            matTooltip="{{'The default language for Clypps & their translations. Supported platform languages are English & German.' | translate}}">
                    info_outline
                  </mat-icon>
                </div>
                <div>
                  <span (click)="openPasswordDialog();"
                        [hidden]="disable_vanilla_registration"
                        class="fontNormal"
                        style="text-decoration: underline; cursor: pointer;" translate>
                    Change password
                  </span>
                </div>
                <div class="mt-2">
                  <span (click)="logOutAllDevices();"
                        class="fontNormal"
                        style="text-decoration: underline; cursor: pointer;" translate>
                    Log out from all devices
                  </span>
                </div>
                <div class="mt-2">
                  <span (click)="deleteUserAccount();" class="fontNormal"
                        style="text-decoration: underline; cursor: pointer;" translate>
                    Delete account
                  </span>
                </div>
              </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion multi="true">
              <mat-expansion-panel class="selected mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title class="fontBold">
                    <mat-icon mat-list-icon class="mr-2 material-icons-outlined">notifications</mat-icon>
                    {{"E-Mail Benachrichtigungen" | translate}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p class="greyText" translate>
                  Bestimme selbst, zu welchen Aktivitäten du per E-Mail informiert werden möchtest
                </p>

                <mat-expansion-panel class="mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="fontMedium" translate>Video related</mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="video_liked">
                    {{'when someone likes my Clypp' | translate}}</mat-checkbox> <br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="video_unliked">
                    {{'when someone unlikes my Clypp' | translate}}</mat-checkbox> <br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="video_commented">
                    {{'when someone comments on my Clypp' | translate}}</mat-checkbox> <br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="video_shared">
                    {{'when someone shares a Clypp exclusively with me' | translate}}</mat-checkbox> <br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="video_public_playlist">
                    {{'when someone adds my Clypp to a public playlist' | translate}}
                  </mat-checkbox> <br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="video_approved">
                    {{'when a manager approves my Clypp' | translate}}</mat-checkbox> <br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="video_featured">
                    {{'when a manager features my Clypp' | translate}}</mat-checkbox> <br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="video_un_featured">
                    {{'when a manager un-features my Clypp' | translate}}</mat-checkbox><br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="video_processed">
                    {{'when my video is processed' | translate}}</mat-checkbox><br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="video_transcribed">
                    {{'when my video is transcribed' | translate}}</mat-checkbox><br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="dept_video_requested">
                    <span [translate]="'video_dept_team'" [translateParams]="{val: dept_alias}"></span>
                    <!-- {{'when someone requests a video from my' | translate}}&nbsp;{{authService.company?.dept_alias}} -->
                    </mat-checkbox><br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="team_video_requested">
                    <span [translate]="'video_dept_team'" [translateParams]="{val: team_alias}"></span>
                    <!-- {{'when someone requests a video from my' | translate}}&nbsp;{{authService.company?.team_alias}} -->
                  </mat-checkbox><br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="video_request_fulfilled">
                    {{'when someone fulfills my request' | translate}}
                  </mat-checkbox><br>
                </mat-expansion-panel>

                <mat-expansion-panel class="mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="fontMedium" translate>Group related</mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="group_added">
                    {{'when someone adds me to a group' | translate}}</mat-checkbox> <br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="group_removed">
                    {{'when someone removes me from group' | translate}}</mat-checkbox> <br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="group_video_added">
                    {{'when someone adds a video to a group I am part of' | translate}}
                  </mat-checkbox>
                  <br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="group_invitation_accepted">
                    {{'when someone outside the organisation accepts my group invitation' | translate}}
                  </mat-checkbox> <br>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="group_invitation_rejected">
                    {{'when someone outside the organisation rejects my group invitation' | translate}}
                  </mat-checkbox> <br>
                </mat-expansion-panel>

                <mat-expansion-panel class="mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="fontMedium" translate>Profile related</mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="profile_edited"
                    [checked]="profile_edited">{{'when a manager edits my profile' | translate}}</mat-checkbox>
                </mat-expansion-panel>

                <mat-expansion-panel class="mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="fontMedium" translate>Marketing</mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-checkbox class="acptCheckbox fontNormal" formControlName="is_marketing_accepted">
                    {{'Ich möchte durch einen Newsletter über neue Produkte und Features informiert werden (optional)' |translate}}
                  </mat-checkbox>
                </mat-expansion-panel>

              </mat-expansion-panel>
            </mat-accordion>

            <div class="d-flex mt-4 mb-5 justify-content-between">
              <div class="d-flex align-items-end">
                <span class="mr-2 external-links">
                  <a href="{{ 'POLICY_URL' | translate }}" target="'_blank'">
                    <p class="fontNormal" style="color: black" translate>
                      Datenschutz
                    </p>
                    <mat-icon mat-list-icon style="font-size: 22px">open_in_new</mat-icon>
                  </a>
                </span>
                <span class="external-links">
                  <a href="{{ 'IMPRESSUM_URL' | translate }}" target="'_blank'">
                    <p class="fontNormal" matLine style="color: black" translate>
                      Impressum
                    </p>
                    <mat-icon mat-list-icon style="font-size: 22px">open_in_new</mat-icon>
                  </a>
                </span>
              </div>
              <div>
                <button (click)='gotoHome();' *ngIf="authService.currentUser.is_profile_completed" class="fontNormal"
                        mat-stroked-button>
                  <span translate>To homepage</span>
                </button>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</div>

<!--footer-->
<mat-toolbar *ngIf="regForm.dirty" class="toolbar-footer justify-content-center">
  <div class="fontStyle mr-3 pl-4 d-none d-sm-inline">
    <ng-container [ngSwitch]="regForm.get('is_profile_completed').value">
      <ng-container *ngSwitchCase="false">
        <span translate>
          Update your profile to access Clypp
        </span>
      </ng-container>
      <ng-container *ngSwitchCase="true">
        <span translate>
          Unsaved changes
        </span>
        <button (click)="discardChanges()" class="blocked ml-3" mat-stroked-button>
          {{ "Discard" | translate }}
        </button>
      </ng-container>
    </ng-container>
  </div>
  <button (click)="updateUserProfile()" mat-stroked-button>
    {{'Update'|translate}}
  </button>
</mat-toolbar>
