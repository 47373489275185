import {ChangeDetectorRef, Component, OnChanges, OnInit} from '@angular/core';
import {VideoType} from '../models/video/video-type.enum';
import {DataService} from '../services/data.service';
import {NavbarService} from '../services/navbar.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-video-creation-overall',
  templateUrl: './video-creation-overall.component.html',
  styleUrls: ['./video-creation-overall.component.scss'],
})
export class VideoCreationOverallComponent implements OnInit, OnChanges {
  videoTypes = VideoType;

  width: number = window.innerWidth;
  height: number = window.innerWidth;

  videoId: string;
  vidValue: any = [];
  VideoID: string;
  title: string = "";
  description: string = "";
  script: string = "";
  videoTypeVal: string = "";
  trello_card_id: string = "";  // used to track if user is creating a clypp for a trello card
  video_request_id: number = 0;  // used to track if user is fulfilling a request

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private navbarService: NavbarService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public translateService: TranslateService
  ) {
    this.navbarService.showSideNav = false;
    window.onresize = () => {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      // this.navbarService.showSideNav = false;
    };

  }

  ngOnInit(): void {

    this.navbarService.getUserProfileDetails();
    this.navbarService.enableCreationMode();
    this.navbarService.hide();
    this.navbarService.showSideNav = false;

    this.cdr.detectChanges();

    // here, we are checking if there is any parameters in the address bar or not
    this.route.queryParams.subscribe((params) => {
      this.title = params['title'];
      this.description = params['desc'];
      this.script = params['script'];
      this.trello_card_id = params['card_id'];
      this.video_request_id = params['video_request_id'];
    });

    // if there is any parameter, then no post call was made before
    // make a post call, get the video id, then redirect to that link
    this.route.paramMap.subscribe(async (map) => {
      if (this.title) {
        // case of redirect from Teams or similar app
        // send a post call first
        let video_type = this.route.params['_value']['param'];
        let postData = {
          'title': this.title.trim().slice(0, 99),
          'type': video_type,
          'script': this.script ?? "",
          'desc': this.description ?? ""
        };

        if(this.trello_card_id){
          postData['integration_parameters'] = {'card_id': this.trello_card_id};
          postData['is_external'] = true;
          postData['is_access_key_needed'] = true;
          postData['access_key'] = this.trello_card_id.slice(0, 32);  // BE has a limit of 32 characters
        }
        else if(this.video_request_id){
          postData['integration_parameters'] = {'video_request_id': this.video_request_id};
        }

        // now post the data, get the id, open the stuff in new window and redirect back to calling url
        this.dataService.postURL<string>(`user/videos/`, postData, {observe: 'body', responseType: 'json'})
          .subscribe((res) => {
            // Todo: handle non 201 status codes
            if (res['response'] == 'failure') {
              window.alert(res['reason']);
              this.router.navigate(['user_profile']);
            } else {
              // now we do not open a new popup, but just redirect
              this.router.navigate(['create-video', res['id'], 'record']);
            }
          }, (err) => {
            window.alert(err.error);
          });
      }

      // if there were no parameters, then deal with the url as before
      else {
        // ID must be there, coming here from a navbar or some other redirect
        this.VideoID = map.get('id');
        this.dataService.getURL(`user/videos/${this.VideoID}/`).subscribe((res) => {
          this.vidValue = res;
          this.videoId = JSON.stringify(this.vidValue);
          this.videoTypeVal = this.vidValue['type'];
        }, (err) => {
          console.log(err);
          let message = _("Please try again");
          window.alert(this.translateService.instant(message));
          this.router.navigate(['/']);
        });
      }
    });
  }

  ngOnChanges(): void {
    this.cdr.detectChanges();
  }

}

