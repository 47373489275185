import {Component, Input, OnInit} from '@angular/core';
import {UtilityService} from '../services/utility.service';
import { AuthService } from '../services/auth.service';
import {VideoCard} from "../models/video/video.interface";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss']
})
export class VideoCardComponent implements OnInit {
  @Input('videoObject') videoObject: VideoCard;
  @Input('backendUrl') backendUrl: any;
  @Input('routeUrl') routeUrl: string = '/view';
  @Input('disableClick') disableClick: boolean = false;
  @Input('is_external_views_shown') is_external_views_shown: boolean = true;
  thumbnail: string;
  show_external_label: boolean = false;  // to show External text if video is from another workspace
  show_language_label: boolean = false;

  constructor(public utilityService: UtilityService,
              private translateService: TranslateService,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    // show external label. This will never be true for public videos
    if (this.authService.company.id != this.videoObject.uploader.userprofile.company) {
      this.show_external_label = true;
    }

    // show language label or not
    if (this.authService.userDetails) {
      // logged in case
      if (this.authService.userDetails.email_language.slice(0, 2) != this.videoObject.audio_language.slice(0, 2)) {
        this.show_language_label = true;
      }
    }
    else {
      // public case
      if(this.translateService.currentLang != this.videoObject.audio_language.slice(0, 2)) {
        this.show_language_label = true;
      }
    }

    this.thumbnail = this.videoObject.thumbnail;
  }

  mouseovered() {
    if(this.videoObject.gif){
      this.thumbnail = this.videoObject.gif;
    }
  }

  mouseouted(){
    this.thumbnail = this.videoObject.thumbnail;
  }
}
