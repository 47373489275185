import {Component, OnInit} from '@angular/core';
import {DataService} from '../services/data.service';
import {NavbarService} from '../services/navbar.service';
import {environment} from '../../environments/environment'
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PlaylistCard} from "../models/playlist/playlist";
import {UtilityService} from '../services/utility.service';
import {HttpErrorResponse} from "@angular/common/http";
import {UploaderChangeDialogComponent} from "../dashboard/uploader-change-dialog/uploader-change-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss']
})
export class PlaylistsComponent implements OnInit {
  backendUrl: string;
  message: string;
  environment = environment
  all_playlists: PlaylistCard[] = [];
  filtered_playlist: PlaylistCard[] = [];

  sort_option: string = 'last_edited_on';
  filter_option: string = 'all';
  showSpinner: boolean = false;
  searchQuery: string = "";

  is_creator: boolean = false;

  constructor(private dataService: DataService,
              private dialog: MatDialog,
              private authService: AuthService,
              public snackBar: MatSnackBar,
              public translateService: TranslateService,
              public router: Router,
              public utilityService: UtilityService,
              public navbarService: NavbarService) {
  }

  ngOnInit(): void {
    this.navbarService.disableCreationMode();
    this.navbarService.getUserProfileDetails();
    this.backendUrl = environment.backendURL;
    this.getPlaylists();
  }

  setPrivate(playlistvideo: PlaylistCard, value: boolean): void {
    this.showSpinner = true;
    const body: any = {is_draft: value};
    this.dataService
      .putURL(`user/playlists/${playlistvideo.id}/`, body, {
        observe: 'body',
        responseType: 'json',
      })
      .subscribe((res: any) => {
        this.showSpinner = false;
        if (res['reason']) {
          window.alert(res['reason']);
        } else {
          const index = this.all_playlists.indexOf(playlistvideo);
          if (index >= 0) {
            this.all_playlists[index].is_draft = value;
            this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2000});
            this.applyFilter();
          }
        }
      }, (err) => {
        this.showSpinner = false;
        console.error(err);
      })
  }

  viewPlaylist(playlist: PlaylistCard) {
    if (playlist.is_draft) {
      this.router.navigate(['createPlaylistMyVideos', playlist.id]);
    } else {
      this.router.navigate(['playlistView', playlist.id]);
    }
  }

  deletePlaylist(playListObj: PlaylistCard): void {
    let message = this.translateService.instant("Your playlist will be deleted forever.");
    message += '\n\n';
    message += this.translateService.instant("Would you like to continue?");
    if (window.confirm(message)) {
      this.showSpinner = true;
      this.dataService.deleteURL(`user/playlists/${playListObj.id}/`).subscribe((res: any) => {
        this.showSpinner = false;
        this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2500});
        const index = this.all_playlists.indexOf(playListObj);
        if (index >= 0) {
          this.all_playlists.splice(index, 1);
          this.applyFilter();
        }
      }, (err) => {
        this.showSpinner = false;
        console.error(err);
      });
    }

  }

  changeUploader(playListObj: PlaylistCard): void {
    const dialogRef = this.dialog.open(UploaderChangeDialogComponent, {
      minWidth: '400px',
      width: '50vw',
      maxWidth: '600px',
      data: playListObj,
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // confirm and save
          if (result != playListObj.uploader.id) {
            const user_object = this.authService.checklist_data.find(e => e.id == result && e.type == 'user');
            let message = user_object.name;
            message += " "
            message += this.translateService.instant("will also be notified about this.");
            message += '\n\n';
            message += this.translateService.instant("Would you like to continue?");
            if (window.confirm(message)) {
              this.showSpinner = true;
              this.dataService.putURL(`user/playlists/${playListObj.id}/`, {uploader: result}).subscribe((res: PlaylistCard) => {
                this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
                const index = this.all_playlists.findIndex(e => e.id == playListObj.id);
                if (index > -1) {
                  this.all_playlists.splice(index, 1);
                  this.applyFilter();
                }
                this.showSpinner = false;
              }, (err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.error(err);
                window.alert(err.error);
              });
            }
          }
        }
      }
    );
  }

  getPlaylists() {
    this.showSpinner = true;
    this.dataService.getURL('user/playlists/my-playlists/', {
      observe: 'body',
      responseType: 'json'
    }).subscribe((data: any) => {
      this.all_playlists = data;
      this.showSpinner = false;
      this.is_creator = this.authService.userDetails.is_creator;
      this.applyFilter();
    }), (err) => {
      console.error(err);
      this.showSpinner = false;
    };
  }

  copyLink(id: number) {
    let copyText = this.environment.baseURL + 'playlistView/' + id
    navigator.clipboard.writeText(copyText)
    this.snackBar.open(this.translateService.instant('Link kopiert'), '', {
      duration: 1000,
    })
  }


  applyFilter(): void {
    // performs both search and filter
    // first search
    this.filtered_playlist = this.all_playlists.filter(r => r.title.toLowerCase().includes(this.searchQuery.toLowerCase()));

    // then filter
    switch (this.filter_option) {
      case 'drafts':
        this.filtered_playlist = this.filtered_playlist.filter(r => r.is_draft);
        break;
      case 'published':
        this.filtered_playlist = this.filtered_playlist.filter(r => !r.is_draft);
        break;
      case 'empty':
        this.filtered_playlist = this.filtered_playlist.filter(r => r.video_count == 0);
        break;
      default:
        break;
    }

    // then sort
    switch (this.sort_option) {
      case 'added_on':
        this.filtered_playlist = this.filtered_playlist.sort((a, b) =>
          new Date(b.added_on).getTime() - new Date(a.added_on).getTime());
        break;
      case 'last_edited_on':
        this.filtered_playlist = this.filtered_playlist.sort((a, b) =>
          new Date(b.last_edited_on).getTime() - new Date(a.last_edited_on).getTime());
        break;
      case 'videos':
        this.filtered_playlist = this.filtered_playlist.sort((a, b) =>
          b.video_count - a.video_count);
        break;
      case 'title':
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#sorting_array_of_objects
        this.filtered_playlist = this.filtered_playlist.sort((a, b) => {
          const nameA = a.title.toUpperCase();
          const nameB = b.title.toUpperCase();
          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          } else {
            return 0;
          }
        });
        break;
      default:
        break;
    }
  }

}
