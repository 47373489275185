<form [formGroup]="videoUploadFormGroup" >
    <mat-form-field class="d-none" (change)="onFileSelected()"  hideRequiredMarker>
        <ngx-mat-file-input #videoInput [accept]="'.mp4,.mov,.3gp,.avi,.mkv'" formControlName="requiredVideo"
        valuePlaceholder="Upload Video Here"
                            required></ngx-mat-file-input>
    </mat-form-field>
</form>

<div class="d-flex flex-column" style="margin-left:1.5rem;margin-right:1.5rem;margin-top: 1.5rem;" [ngClass]="'h-70'" >

    <div appDnd (fileDropped)="onDropped($event)"
         class="jumbotron jumbotron-fluid mb-0 cursor-pointer text-center flex-grow-1 upload-window" (click)="videoInput.open()">
        <button mat-icon-button style="height: 150px !important;width: 150px !important;"  fxHide fxShow.lt-sm>
            <mat-icon class="upload-button" style="font-size: 100px;
            margin-right: 50%;">cloud_upload</mat-icon>
        </button>
        <button mat-icon-button size="large" class="large-icon"  fxHide.lt-sm>
            <mat-icon class="upload-button">cloud_upload</mat-icon>
        </button>
        <p class="button-color fontNormal" style="text-align: center;" translate>Hier klicken </p>
        <p class="button-color fontNormal" style="text-align: center;" translate>oder</p>
        <p class="button-color fontNormal" style="text-align: center;" translate>Datei hineinziehen</p>

        <!-- <button mat-raised-button class="upload-button">Video</button> -->
    </div>
</div>
