import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import * as ysFixWebmDuration from '../scripts/fix-webm-duration.js';

@Injectable({
  providedIn: 'root'
})
export class ScreencastService {

    stoppedCapturing: Subject<void> = new Subject();

    private mediaRecorder: MediaRecorder;
    private screenCast: any;

    private startTime: number;
    private duration: number;
    audio: any;
    selectedVal: any;
    audioSource = undefined;
    private isPaused = false;
    audioMuted: boolean = true;
    noiseSuppress: boolean = true;
    echoCancellation:boolean = true;
    audioInputDetected: any;
    private audioInputVal = new Subject<string>();
    audioVal = this.audioInputVal.asObservable();
    // private preview = new Subject<MediaStream>();
    // previewValue = this.preview.asObservable();
    selval: any;

    constructor() {
    }

    // tslint:disable-next-line:variable-name
    private _chunks: Array<Blob>;

    get chunks(): Array<Blob> {
        return this._chunks;
    }

    async onSelectScreen() : Promise<MediaStream> {
        const mediaDevices = navigator.mediaDevices as any;
        if (this.audioSource === undefined) {
            this.selval = {
              audio: true
              // audio: {echoCancellation: this.echoCancellation, noiseSuppression: this.noiseSuppress}
            }
        } else {
            this.selval = {
                audio: {
                    deviceId: { exact: this.audioSource},
                    echoCancellation: this.echoCancellation,
                    noiseSuppression: this.noiseSuppress
                }
            }
        }
        this.audio = await navigator.mediaDevices.getUserMedia(this.selval).then(
            (strem: any) => strem).catch((err) => { console.log('Access Denied', err); return 'deniedMic'; });

        // if (this.audio == 'deniedMic') {
        //     return null;
        // }

        this.screenCast = await mediaDevices.getDisplayMedia({
            video: {
                cursor: 'always',
                height: 1440
            }
        }).then((strem: any) => strem)
            .catch((err) => { console.log('Access Denied', err); return 'deniedDisplay'; });

      // this.preview.next(this.screenCast);
      if (this.screenCast == 'deniedDisplay') {
        return null;
    }
      return this.screenCast;
    }


    async startCapture(): Promise<MediaStream> {
        if (this.screenCast == 'deniedDisplay') {
          return null;
      }
        this._chunks = [];
        this.screenCast.addEventListener('inactive', () => {
            this.screenCast.getTracks().forEach(track => track.stop());
            if(this.audio != 'deniedMic'){
            this.audio.getTracks().forEach(track => track.stop());
            }
            if (this.mediaRecorder.state != 'inactive') {
                this.mediaRecorder.stop();
            }
        });
        const options = {
            audioBitsPerSecond: 128000,
            videoBitsPerSecond: 5000000
        };
        if(this.audio == 'deniedMic'){
            this.mediaRecorder = new MediaRecorder(new MediaStream([...this.screenCast.getTracks()]), options);
        }else{
        this.mediaRecorder = new MediaRecorder(new MediaStream([...this.screenCast.getTracks(), ...this.audio.getTracks()]), options);
        }
        this.mediaRecorder.addEventListener('dataavailable', event => {
            if (event.data && event.data.size > 0) {
                this._chunks.push(event.data);
                this.stoppedCapturing.next();
            }
        });
        setTimeout(() => {
            if (!this.audioMuted) {
                this.audio.getTracks().forEach(function (track) {
                    track.enabled = false;
                });
            }
            this.mediaRecorder.start();
            this.startTime = Date.now();
            this.duration = 0;
            this.isPaused = false;
        }, 2800);

        return this.screenCast;
  }

  stopCapture(): void {
    if (!this.isPaused) {
      this.duration = this.duration + (Date.now() - this.startTime);
    }
    if (this.mediaRecorder.state != 'inactive') {
      this.mediaRecorder.stop();
    }
    this.screenCast.getTracks().forEach(track => track.stop());
    if (this.audio != 'deniedMic') {
      this.audio.getTracks().forEach(track => track.stop());
    }
  }

  stopScreen(): void {
      this.screenCast.getTracks().forEach(track => track.stop());
  }

  pauseScreencast(): void {
    this.mediaRecorder.pause();
    this.duration = this.duration + Date.now() - this.startTime;
    this.isPaused = true;
  }

  resumeScreencast(): any {
    this.mediaRecorder.resume();
    this.startTime = Date.now();
    this.isPaused = false;
    return this.screenCast;
  }

  recording(): boolean {
    return this.mediaRecorder?.state === 'recording';
  }

  getFixedDurationBlobURL(): Promise<string> {
    return new Promise(((resolve, reject) => {
      ysFixWebmDuration(new Blob([this.chunks[0]]), this.duration, res => {
        resolve(window.URL.createObjectURL(res));
      });
    }));
  }

  getAudioToggle(value): any {
    if (this.audio == undefined) {
      this.audioMuted = value;
    } else if (!value) {
      this.audioMuted = false;
      this.audio.getTracks().forEach(function (track) {
        track.enabled = false;
      });
    } else {
      this.audioMuted = true;
      this.audio.getTracks().forEach(function (track) {
        track.enabled = true;
      });

    }
  }

  getNoiseToggle(value): any {
    this.noiseSuppress = value;
    this.echoCancellation = value;
}

  getAudioVal() {
    var newArr = [];
    this.selectedVal = navigator.mediaDevices.getUserMedia({audio: true})
      .then(() => navigator.mediaDevices.enumerateDevices())
      .then(devices => {
        devices.forEach(function (d) {
            if (d.kind === 'audioinput') {
              newArr.push({label: d.label, deviceId: d.deviceId});
            }
          }
        );
        this.getValue(newArr);
      })
      .catch(e => console.log(e));
  }

  getValue(data) {
    this.audioInputDetected = data;
    this.audioInputVal.next(this.audioInputDetected);
  }

  getSelectionVal(data) {
    this.audioSource = data;
  }
}
