<div class="container-fluid dept_team">
  <div class="d-flex align-items-baseline">
    <h2 class="fontstyle m-0" translate>Organizational Levels</h2>
    <button (click)="openDialog(null)" [disabled]="!authorised"
            class="ml-4 border-radius-20" mat-stroked-button>
      <mat-icon mat-icon>add</mat-icon>
      <span [translateParams]="{val: authService.company.dept_alias}" [translate]="'_ADD_DEPT'"></span>
    </button>
  </div>
  <hr>

  <h2 *ngIf="!authorised" class="text-nunito m-4" translate>
    You need global or company management rights
  </h2>

  <div class="d-flex justify-content-between align-items-baseline">
    <mat-tree [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl"
              class="example-tree mat-tree-position">
      <!-- Without Children -->
      <mat-tree-node *matTreeNodeDef="let node">
        <li class="mat-tree-node">
          <button disabled mat-icon-button></button>
          <span class="d-flex align-items-center">{{node.name}}</span>
          <div class="d-flex ml-auto">
            <span (click)="openTeamMembersPopup(node)"
                  class="d-flex align-items-center ml-2 py-0 badge badge-pill badge-secondary cursor-pointer"
                  matTooltip="{{'View members' | translate}}">
            <mat-icon class="badge-icon">people</mat-icon>
              {{node.users}}
          </span>
            <button [matMenuTriggerFor]="menu" class="ml-2 zesavi-mini-fab customized"
                    mat-mini-fab>
              <mat-icon class="compressed">more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="openDialog(node)" *ngIf="node?.parent" mat-menu-item>
                <mat-icon>add</mat-icon>
                <span [translateParams]="{val: this.authService.company.team_alias}" [translate]="'_Add_Team'"></span>
              </button>
              <button (click)="editDialog(node)" mat-menu-item>
                <mat-icon class="material-icons-outlined">edit</mat-icon>
                <span translate>Rename</span></button>
              <button (click)="deleteNode(node)" mat-menu-item>
                <mat-icon class="material-icons-outlined">delete</mat-icon>
                <span translate>Löschen</span></button>
            </mat-menu>
          </div>
          <!-- <button *ngIf="!node?.users" mat-icon-button (click)="deleteNode(node)">
            <mat-icon>delete</mat-icon>
          </button>
          <button *ngIf="node?.parent" mat-icon-button (click)="openDialog(node)">
            <mat-icon>add</mat-icon>
          </button>
          <button mat-icon-button (click)="editDialog(node)">
            <mat-icon>edit</mat-icon>
          </button> -->
        </li>
      </mat-tree-node>

      <!-- With Children -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
        <li>
          <div class="mat-tree-node">
            <button [attr.aria-label]="'toggle ' + node.Name" mat-icon-button matTreeNodeToggle>
              <mat-icon class="mat-icon-rtl-mirror">
                {{nestedTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <span class="d-flex align-items-center">{{node.name}}</span>
            <div class="d-flex ml-auto">
              <div class="d-flex">
                <span (click)="openDeptMembersPopup(node)"
                      class="d-flex align-items-center ml-2 py-0 badge badge-pill badge-secondary cursor-pointer"
                      matTooltip="{{'View members'|translate}}">
                  <mat-icon class="badge-icon">people</mat-icon>
                    {{node.users}}
                </span>
                <button [matMenuTriggerFor]="menu" class="ml-2 zesavi-mini-fab customized"
                        mat-mini-fab>
                  <mat-icon class="compressed">more_horiz</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                  <button (click)="openDialog(node)" *ngIf="node?.parent" mat-menu-item>
                    <mat-icon>add</mat-icon>
                    <span [translateParams]="{val: authService.company.team_alias}" [translate]="'_Add_Team'"></span>
                  </button>
                  <button (click)="editDialog(node)" mat-menu-item>
                    <mat-icon class="material-icons-outlined">edit</mat-icon>
                    <span translate>Rename</span></button>
                  <button (click)="deleteNode(node)" mat-menu-item>
                    <mat-icon class="material-icons-outlined">delete</mat-icon>
                    <span translate>Löschen</span></button>
                </mat-menu>
              </div>
            </div>
          </div>
          <ul [class.example-tree-invisible]="!nestedTreeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</div>
