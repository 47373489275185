import { Component, Inject, OnInit, ViewChild, ElementRef, Input, OnChanges, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { environment } from '../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-videos-add-videos-playlist',
  templateUrl: './my-videos-add-videos-playlist.component.html',
  styleUrls: ['./my-videos-add-videos-playlist.component.scss']
})
export class MyVideosAddVideosPlaylistComponent implements OnInit, OnChanges {

  @Input('videoObject') videoObject: any;
  @Input('playlistId') playlistId: any;
  @Input('videosInPlaylist') videosInPlaylist :any;
  @Output()
  videoAdded = new EventEmitter<{ IsVideoAdded: boolean }>();
   listVideos: any;
   colsVideo: number;
   backendUrl: string;
   playlistID: any;
   idVal:any = [];
   hideAddBtn : boolean;
   constructor(private formBuilder: UntypedFormBuilder, private cdr: ChangeDetectorRef, private translateService:TranslateService, private dataService: DataService, private breakpointObserver: BreakpointObserver, private snackBar: MatSnackBar) {
     this.breakpointObserver.observe([
       Breakpoints.XSmall,
       Breakpoints.Small,
       Breakpoints.Medium,
       Breakpoints.Large,
       Breakpoints.XLarge,
     ]).subscribe(result => {
       if (result.matches){
         if (result.breakpoints[Breakpoints.XSmall]){
           this.colsVideo = 1;
         }
         if (result.breakpoints[Breakpoints.Small]){
           this.colsVideo = 2;
         }
         if (result.breakpoints[Breakpoints.Medium]){
           this.colsVideo = 3;
         }
         if (result.breakpoints[Breakpoints.Large]){
           this.colsVideo = 3;
         }
         if (result.breakpoints[Breakpoints.XLarge]){
           this.colsVideo = 3;
         }
       }
     });
   }
 
   ngOnInit(): void {
     // this.backendUrl = environment.backendURL;
     // this.dataService.getURL(`user/videos/`).subscribe((res: any) => {
     //   this.listVideos = res.data;
     // });
 
 
   
     this.listVideos = this.videoObject;
   
    this.videoObject.map((data1, index1) => {
      this.videosInPlaylist.map((data2, index2) => {
        if (data1.id == data2.id) {
           data1['status'] = true;
           return data1;
        }
       })
 
   });
   
   }
 
   ngOnChanges(){
     this.listVideos = this.videoObject;
   }
 
   addVideo(videoObj): void{
     this.dataService.getURL(`user/playlists/${this.playlistId}/${videoObj.id}/add/`).subscribe((res: any) => {
       if (res.response == 'success') {
        this.videoAdded.emit({IsVideoAdded: true});
         this.snackBar.open(this.translateService.instant('Video erfolgreich hinzugefügt'), '', {
           duration: 1000,
         });
       } else {
 
       }
     });
   }
 
 }
 