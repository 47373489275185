import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DataService} from "../services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../services/auth.service";
import {VideoView, MiniDetails} from "../models/video/video.interface";
import {COMMA} from '@angular/cdk/keycodes';
import {FormControl} from "@angular/forms";
import {interval, Observable, Subscription} from "rxjs";
import {map, startWith, switchMap, takeWhile} from "rxjs/operators";
import {TranscriptPopupComponent} from "../shared/transcript-popup/transcript-popup.component";
import {ThumbnailPopupComponent} from "../shared/thumbnail-popup/thumbnail-popup.component";
import {AddTranslationsComponent} from "../add-translations/add-translations.component";
import {StillThereComponent} from "../shared/still-there/still-there.component";
import {environment} from '../../environments/environment';
import {ProgressSpinnerDialogComponent} from "../shared/progress-spinner-dialog/progress-spinner-dialog.component";
import {inIframe} from "../login/login.component";
import {copyImageToClipboard} from "copy-image-clipboard";
import {ChapterCreationPopupComponent} from "../chapter-creation-popup/chapter-creation-popup.component";
import {HttpErrorResponse} from "@angular/common/http";
import {NavbarService} from "../services/navbar.service";
import {PopupData, SharePopupComponent} from "../shared/share-popup/share-popup.component";
import {CompletionPopupComponent} from "../completion-popup/completion-popup.component";
import {ContentChange} from "ngx-quill";


@Component({
  selector: 'app-video-meta-page',
  templateUrl: './video-meta-page.component.html',
  styleUrls: ['./video-meta-page.component.scss']
})
export class VideoMetaPageComponent implements OnInit, OnDestroy {

  // fetched variables
  video_id: string = "";
  video_object: VideoView = null;
  all_tags: MiniDetails[] = [];
  languages: [string, string][] = [];

  // calculated variables
  attached_file_name: string = "";
  is_title_editor_visible: boolean = false;
  // is_desc_editor_visible: boolean = false;
  is_transcribing: boolean = false;
  is_trello_case: boolean = false;
  is_fulfill_request_case: boolean = false;
  is_screenshot_button_enabled: boolean = false;
  time_estimate_to_process_video: string = '';

  // variables to send in a form
  title: string = "";
  is_published: boolean = false;
  desc: string = "";
  audio_language: string = "en-US";

  // html elements
  @ViewChild('videoPlayer') video_player;
  @ViewChild('tagInput') tag_input_element: ElementRef<HTMLInputElement>;
  @ViewChild('customQuill', { static: false }) customQuill: any;
  player: HTMLVideoElement = null;
  empty_title_text: string = "";
  separatorKeysCodes: number[] = [COMMA];  // ENTER is removed as it takes both the input-text and auto select item
  tagCtrl = new FormControl('');
  selected_tags: MiniDetails[] = [];
  filtered_tags: Observable<MiniDetails[]> = null;

  timeoutHandler: any = null;  // timeout handler to show are you still there popup, when the url has expired
  transcriptionSubscription: Subscription = null;  // on click of create subtitles, we open edit subtitles popup
  current_language: string;

  is_text_area_active: boolean = false;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private navbarService: NavbarService,
    public dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    public authService: AuthService
  ) {
    // capturing route param for video id
    this.route.paramMap.subscribe(async (map) => {
      this.video_id = map.get('id');
    });

    this.empty_title_text = this.translateService.instant("Please provide a title");

    // show button in non-chrome browsers
    if (navigator.userAgent.indexOf('Chrome') != -1) {
      this.is_screenshot_button_enabled = true;
    }

    // hide button in iframe
    if(inIframe()){
      this.is_screenshot_button_enabled = false;
    }
  }

  ngOnInit(): void {
    // do stuff one by one
    this.loadVideo();

    // initiate languages from auth service
    for (let i of this.authService.languages.entries()) {
      this.languages.push(i);
    }

    // to ensure that the languages are there, cross verify
    if (this.languages.length == 0) {
      this.loadLanguages().then();
    }
  }

  quillContentChanged(event: ContentChange) {
    if(event.source == 'api') {
      // console.log(event)
      const quill = this.customQuill.quillEditor;
      quill.setSelection(quill.getLength());
    }
  }

  ngOnDestroy() {
    // clear any subscription from before
    if (this.transcriptionSubscription) {
      this.transcriptionSubscription.unsubscribe();
    }
    // clear the handler
    clearTimeout(this.timeoutHandler);
  }

  // load the video and set tags
  loadVideo(){
    this.dataService.getURL<VideoView>(`user/videos/${this.video_id}/`, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: VideoView) => {
      this.video_object = res;
      this.title = res.title;
      this.audio_language = res.audio_language;
      this.desc = res.desc;
      this.is_published = !res.is_draft;
      this.current_language = this.authService.languages.get(res.audio_language);
      // attached file name
      if(res.attachment){
        try{
          this.attached_file_name = res.attachment.split("/").pop().split("?")[0];
        }
        catch (e) {
          this.attached_file_name = res.attachment;
          console.error(e);
        }
      }
      // load tags
      this.initiateTags();

      // check for integration cases
      if (this.video_object.integration_parameters) {
        // Trello case
        if (this.video_object.integration_parameters.card_id) {
          // todo: also check for userprofile.jira_token
          this.is_trello_case = true;
        }
        // fulfill request case, generally both can not be true together
        if (this.video_object.integration_parameters.video_request_id) {
          this.is_fulfill_request_case = true;
        }
      }

      // initiate an interval call to update state
      if (this.video_object.state == 'PR') {
        interval(10000)
          .pipe(
            takeWhile((val) => this.video_object.state == "PR"),
            switchMap(() => this.pollingCall())
          )
          .subscribe((res: any) => {
            if (res.state == "CO") {
              this.video_object.processed_file = res.processed_file;
              this.video_object.thumbnail = environment.bucketURL + this.video_id + ".jpg"
            }
            this.video_object.state = res.state;
          })

        // also estimate the time needed to process the video
        this.estimateTimeNeeded();

        // also check, if the video is a draft, mark it as published, otherwise user may forget to publish it
        if (!this.is_published) {
          // video is draft
          if (!this.video_object.processed_file) {
            // there is no processed file, publish the video
            this.is_published = true;
          }
        }
      }
    }, (err) => {
      window.alert(err.error);
      console.error(err);
      this.router.navigate(['start'])
    });
  }


  // initialise tags when video and tag data is ready
  initiateTags(){
    this.all_tags = this.authService.tag_data;
    this.filtered_tags = this.tagCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );
    this.selected_tags = this.all_tags.filter(e => this.video_object.tags.includes(e.id));
    // now remove those tags from all tags
    this.all_tags = this.all_tags.filter(e => !this.selected_tags.includes(e));
  }

  // to check state
  pollingCall() {
    return this.dataService.getURL<any>(`user/videos/${this.video_id}/state/`);
  }

  // get languages
  async loadLanguages(){
    this.dataService.getURL<any>('user/videos/languages/', {observe: 'body', responseType: 'json'})
      .subscribe((resp) => {
      this.languages = resp;
    });
  }

  pausePlayer() {
    if (this.player) {
      this.player.pause();
    }
  }

  // save the video and go to proper link
  saveVideo(redirect_link: string = ""){
    // redirect_link: take the user to this route after saving the video or stay on this page
    this.pausePlayer();

    // check if title is empty else return
    if(!this.title.length){
      window.alert(this.empty_title_text);
      this.is_title_editor_visible = true;
      document.getElementById('title-editor').focus();
      return;
    }

    if (this.desc == null) {
      this.desc = "";
    }

    // check if desc is over 1 MB
    if(this.desc.length > environment.bodySize){
      // the overall form should be < 1MB as per WAF settings
      const message = this.translateService.instant("The provided input is too large. Try deleting some content.");
      window.alert(message);
      return;
    }

    // save video
    const tags: Number[] = this.selected_tags.map(element => element.id);

    // video should only be published if the state is either CO or PR
    if (!(this.video_object.state == 'CO' || this.video_object.state == 'PR')) {
      // video is being published but the state is neither CO nor PR
      if (this.is_published) {
        // show snackbar
        let message = this.translateService.instant("Veröffentlichen nicht möglich");
        this.snackBar.open(message, "", {duration: 3000});
      }
      // make the video draft
      this.is_published = false;
    }

    let form_data = {
      title: this.title,
      is_draft: !this.is_published,
      desc: this.desc,
      audio_language: this.audio_language,
      tags: tags
    }

    let url = `user/videos/${this.video_id}/`;
    if (redirect_link == 'done') {
      // user is clicking "Done" button
      // add extra data to notify manager if video is held for review
      url += "?done=true"
    }

    // show spinner
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });
    this.dataService.putURL(url, form_data, {
      responseType: 'json',
      observe: 'body'
    }).subscribe((res: VideoView) => {
      let message = this.translateService.instant("Gespeichert");
      this.video_object = res;

      // redirect as needed
      if (redirect_link == 'edit') {
        // go to trim page
        this.router.navigate(['create-video', this.video_id, 'trim']);
      } else if (redirect_link == 'record') {
        // user wants to go to record page
        this.router.navigate(['create-video', this.video_id, 'record']);
      } else if (redirect_link == 'done') {
        // go to either internal page or view page or my videos
        if(!this.is_published){
          // video is drafted
          if (navigator.userAgent.indexOf('Teams') > -1 || inIframe()) {
            // if we are in Teams or an iframe, do not redirect to my video, but show an alert
            message = this.translateService.instant("Your video is now saved as draft. You can edit it again on");
            window.alert(message + " " + environment.baseURL);
            if(this.video_object.processed_file){
              this.router.navigate(['embed', 'video', this.video_id]);
            }
            else {
              this.router.navigate(['internal']);
            }
          }
          else {
            // my videos
            // in case of trello, also go to my videos
            this.router.navigate(['library', 'clypps']);
          }
        }
        else {
          // video is published
          // switch based on browser and iframe
          if (navigator.userAgent.indexOf('Teams') > -1 || inIframe()) {
            // teams case
            let message = this.translateService.instant("Your video is now published. " +
              "You can now close this window and share it using the Clypp application.");
            window.alert(message);
            if (this.video_object.state == 'CO') {
              this.router.navigate(['embed', 'video', this.video_id]);
            }
            else {
              this.router.navigate(['internal']);
            }
          }
          else if(this.is_trello_case){
            // go to view video
            // todo: check case when video is being processed
            let message = this.translateService.instant("Your video is now published " +
              "and the link is attached to the card. You may now close this window.");
            window.alert(message);
            this.router.navigate(['view', this.video_id]);
          }
          else if(this.is_fulfill_request_case){
            // go to request page
            this.router.navigate(['requests']);
          }
          else {
            // else, redirect view video page
            message = this.translateService.instant('Erfolgreich');
            this.snackBar.open(message, '', { duration: 1000 });
            if (this.video_object.state == 'CO') {
              this.router.navigate(['view', this.video_id], {
                state: { open_share_popup: true }
              });
            }
            else{
              this.router.navigate(['library', 'clypps']);
            }
          }
        }
      }
      // else no redirect link
      else {
        // do nothing and stay here
        this.snackBar.open(message, '', { duration: 1000 });
        // this is needed as response of save is not serialised as GET VIDEO needed
        this.loadVideo();
      }

    }, (err) => {
      this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'),
        '', { duration: 2500 })
      console.error(err);
      dialogRef.close();
    }, () => {
      // always executed
      dialogRef.close();
    });
  }

  transcribeNow() {
    if (this.is_transcribing || this.video_object.vtt_file || this.video_object.state != 'CO') {
      // already transcribing OR there is already a VTT file OR the state is not complete
      return;
    }
    // send the transcript call, disable the transcript button, enable after completion
    this.is_transcribing = true;
    // show snackbar that you will be notified
    if (this.authService.userDetails.video_transcribed) {
      const message = this.translateService.instant('You will be notified after the subtitles are ready');
      this.snackBar.open(message, '', {duration: 3000});
    }
    // clear any subscription from before
    this.transcriptionSubscription = this.dataService.patchURL<any>(`user/videos/${this.video_id}/state/`,
      {audio_language: this.audio_language},
      {observe: 'body', responseType: 'text'}).subscribe(
        (res: string) => {
          if (res.length) {
            // sometimes, there won't be any url due to no audio
            this.video_object.vtt_file = res;
            const message = this.translateService.instant('Transcription completed');
            this.snackBar.open(message, '', { duration: 3000 });
            // open the edit popup immediately
            this.editSubtitles();
          }
          this.is_transcribing = false;
        }, (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 504) {
            // timed out
            // do not clear is_transcribing
            const message = this.translateService.instant("Please refresh the page after some time");
            window.alert(message);
          } else {
            window.alert(err.error);
            this.is_transcribing = false;
          }
        }, () => {
          // this line is always executed
      });
  }

  editSubtitles(){
    this.pausePlayer();

    // open the edit popup
    this.is_text_area_active = true;
    let transcriptDialog = this.dialog.open(TranscriptPopupComponent, {
      width: "80vw",
      minWidth: '400px',
      disableClose: false,
      data: this.video_object,
    });
    transcriptDialog.afterClosed().subscribe((res) => {
      this.is_text_area_active = false;
      if (res) {
        if (res == 'subtitles_deleted') {
          this.video_object.vtt_file = null;
        }
        else {
          this.video_object.vtt_file = res.vtt_file;
        }
      }
    });
  }

  // same approach as in Transcript Popup
  deleteSubtitles() {
    let message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      this.dataService.deleteURL(`user/videos/${this.video_id}/state/`)
        .subscribe((res) => {
            this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
            this.video_object.vtt_file = null;
          }, (err) => {
            window.alert(err.error);
          }
        );
    }
  }

  // update the subs only after they are deleted
  updateSubtitles(){
    this.dataService.deleteURL(`user/videos/${this.video_id}/state/`)
      .subscribe((res) => {
        this.video_object.vtt_file = null;
        this.transcribeNow();
      });
  }

  sharePopup() {
    // todo: save video first? as there may be changed title
    //  also, if video is published but not saved, users may not get a notification

    // pause video, otherwise video may play in the background
    this.pausePlayer();

    const data: PopupData = {
      ...this.video_object,
      type: "video",
    }


    this.is_text_area_active = true;
    const dialogRef = this.dialog.open(SharePopupComponent, {
      disableClose: true,
      data: data
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.is_text_area_active = false;
      // update current video's data if I am uploader;
      if (res) {
        // only update limited items as video may have a separate title and desc
        this.video_object.access_key = res.access_key;
        this.video_object.is_external = res.is_external;
        this.video_object.is_access_key_needed = res.is_access_key_needed;
        this.video_object.is_restricted = res.is_restricted;
        this.video_object.departments = res.departments;
        this.video_object.locations = res.locations;
        this.video_object.teams = res.teams;
        this.video_object.users = res.users;
        this.video_object.groups = res.groups;
      }
    });
  }

  playerReady() {
    this.player = this.video_player.nativeElement;

    // start the timer when player is ready
    this.timeoutHandler = setTimeout(() => {
      // open the still-there component
      // no need to save data, unless user clicks on yes.
      this.saveVideo();
      const stillThereDialogRef: MatDialogRef<StillThereComponent> =
        this.dialog.open(StillThereComponent, {
          panelClass: 'transparent',
          disableClose: true,
        });
      stillThereDialogRef.afterClosed().subscribe(() => {
        // reload processed
        // do not reload the meta page as there might be other popups opened
        // location.reload();
      });
      clearTimeout(this.timeoutHandler);
    }, environment.timeoutDuration * 1000);
  }

  // when user inputs tag name and presses comma or enter
  addTagByName(name: string){
    // empty the field
    this.tag_input_element.nativeElement.value = "";

    // trim the name
    const trimmed_name: string = name.trim().slice(0, 49); // BE limit is 50
    if (trimmed_name.length == 0) {
      return;
    }

    // find tag by name
    let temp_tag = this.selected_tags.find(e => e.name.toLowerCase() == trimmed_name.toLowerCase());
    if (temp_tag) {
      // already there, return
      return;
    }

    // find tag by name in remaining tags
    temp_tag = this.all_tags.find(e => e.name.toLowerCase() == trimmed_name.toLowerCase());
    if (temp_tag) {
      // tag found, add it
      this.addTagByObject(temp_tag);
    } else {
      // push it to BE
      this.navbarService.createTag(trimmed_name).then(res => {
        this.addTagByObject(res);
        // reload global checklist
        this.authService.loadTagsData().then();
      }).catch(e => {
        console.error(e);
        this.snackBar.open(this.translateService.instant('Already exists'), '', {duration: 2500});
      });
    }
  }

  addTagByObject(tag: MiniDetails){
    // empty the field
    this.tag_input_element.nativeElement.value = "";
    this.selected_tags.push(tag);

    // remove from all tags
    const index = this.all_tags.findIndex(e => e.id == tag.id);
    if (index > -1) {
      this.all_tags.splice(index, 1);
    }
  }

  removeTag(tag: MiniDetails) {
    const index = this.selected_tags.findIndex(e => e.id == tag.id);
    this.selected_tags.splice(index, 1);
    // add back to the main list
    this.all_tags.push(tag);
  }

  private _filter(value: string): MiniDetails[] {
    return this.all_tags.filter(element => element.name.toLowerCase().includes(value));
  }

  updateThumbnail(): void {
    this.pausePlayer();

    this.is_text_area_active = true;
    this.dialog.open(ThumbnailPopupComponent, {
      minWidth: '50vw',
      height: '420px',
      disableClose: false,
      data: this.video_object,
    }).afterClosed()
      .subscribe((res) => {
        this.is_text_area_active = false;
        if (res == true || res == undefined) {
          // dialog is closed without any upload
        }
        else{
          this.video_object.thumbnail = res['thumbnail'];
        }
      })
  }

  deleteVideo() {
    let message = this.translateService.instant('Dein Video wird unwiederbringlich gelöscht. Fortfahren?');
    if (window.confirm(message)) {
      this.dataService.deleteURL(`user/videos/${this.video_id}/`).subscribe((res) => {
        // navigate to home if not in teams or iframe
        if (navigator.userAgent.indexOf('Teams') > -1 || inIframe()) {
            this.router.navigate(['internal']);
        }
        // navigate to internal if in teams or iframe
        else {
          this.router.navigate(['library', 'clypps']);
        }
      });
    }
  }

  // update translations
  addTranslation() {
    // send a save video call before, otherwise translations may be incorrect
    this.saveVideo();
    clearTimeout(this.timeoutHandler);

    let dialogData =
    {
      title: this.title,
      video_id: this.video_id,
      languages: this.languages,
      originalLanguage: this.audio_language,
    };

    this.is_text_area_active = true;
    const dialogRef = this.dialog.open(AddTranslationsComponent, {
      autoFocus: false,
      data: dialogData,
      width: "70vw",
      height: "90vh",
      disableClose: false,
      panelClass: 'my-panel'
    });

    dialogRef.afterClosed().subscribe((dialogResponse: any[]) => {
      this.is_text_area_active = false;
      // todo: fix type to Translation[]
      this.video_object.translations = dialogResponse
    });
  }

  // this method adds script to the end in the video desc
  insertScript() {
    const quill = this.customQuill.quillEditor;
    // quill.insertText(quill.getLength(), '\n', 'silent');
    if (quill.getLength() > 1) {
      // desc is not empty
      quill.insertText(quill.getLength(), '---', 'silent');
      quill.insertText(quill.getLength(), this.video_object.script, 'silent');
      this.desc = quill.root.innerHTML;
    } else {
      // desc is empty
      const res_html = this.video_object.script.replace(/\n/g, "<br>");
      this.desc = `<p>${res_html}</p>`;
    }

  }

  // this method auto creates desc using clypp AI services
  autoCreateDesc(type: 'guide' | 'summary' | 'compact'){
    // https://quilljs.com/docs/api#gettext
    const quill = this.customQuill.quillEditor;
    const desc_plain_text: string = quill.getText();  // returns plain text

    if (type == 'compact') {
      // button is only enabled if desc is > 100 characters
    } else if (this.video_object.vtt_file == null) {
      // there is no subtitle file, ask user to create them first
      const message = this.translateService.instant("Please transcribe your video first");
      const action = this.translateService.instant("Create subtitles");
      this.snackBar.open(
        message,
        action,
        {duration: 4000}).onAction().subscribe(() => {
          // on click of action, create subtitles
          this.transcribeNow();
      });
      return;
    }

    // now download the video cc, as they're not provided in the video response
    this.pollingCall().subscribe((response: any) => {
      if (response.cc || type == 'compact') {
        // open the popup
        let system_prompt: string = this.translateService.instant("You will be provided with a text. ");
        let user_prompt: string = response.cc;
        let assistant_prompt: string = desc_plain_text;
        switch (type) {
          case 'summary':
            assistant_prompt = response.cc;
            user_prompt = this.translateService.instant("Summarize it");
            break;
          case 'guide':
            assistant_prompt = response.cc;
            user_prompt = this.translateService.instant("Using your own words, create a very short step-by-step guide line by line from this text without using bullets or numbers in the text language. Add each sentence in a new line.")
            break;
          case 'compact':
            user_prompt = this.translateService.instant("Make this input more concise");
            assistant_prompt = desc_plain_text;
            break;
          default:
            return;
        }

        // open AI popup
        this.is_text_area_active = true;
        this.dialog.open(CompletionPopupComponent, {
          width: '70vw',
          minWidth: '400px',
          maxWidth: '700px',
          maxHeight: '90vh',
          autoFocus: false,
          disableClose: false,
          hasBackdrop: true,
          data: {
            system: system_prompt,
            user: user_prompt,
            assistant: assistant_prompt,
            auto_close: true,
            file_search: null,
          }
        }).afterClosed().subscribe((res: string) => {
          this.is_text_area_active = true;
          // this boolean will tell if we need to reload the profiles
          if (res) {
            // append to desc
            // const res_html = res.replace(/\n/g, "<br>");
            if (quill.getLength() > 1) {
              // only add a new line if there is some text
              quill.insertText(quill.getLength(), '---', 'silent');
              quill.insertText(quill.getLength(), res, 'silent');
              this.desc = quill.root.innerHTML;
            } else {
              // quill.insertText(quill.getLength(), res, 'silent');
              // above method was inserting a line break
              const res_html = res.replace(/\n/g, "<br>");
              this.desc = `<p>${res_html}</p>`;
            }
          }
        });
      } else {
        this.snackBar.open(this.translateService.instant('Subtitles are empty'),'', {duration: 2000});
      }
    })
  }

  autoCreateKeywords() {
    if (!this.desc) {
      // there is no subtitle file, ask user to type something first
      const message = this.translateService.instant("Please create a description first");
      this.snackBar.open(
        message,
        '',
        {duration: 2000});
      // auto focus on desc
      this.customQuill.quillEditor.focus();
      return;
    }
    // show spinner
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });

    // in body, we need to send existing tags and desc
    const tags: Number[] = this.selected_tags.map(element => element.id);
    const body = {
      desc: this.desc,
      tags: tags
    };

    this.dataService.putURL(`user/videos/${this.video_id}/state/?type=tags`, body,
      {observe: 'body', responseType: 'json'}).subscribe((res: any) => {
      if (res['response'] == 'failure') {
        window.alert(res['reason']);
      } else {
        // load all tags
        this.video_object.tags = res['reason'] as number[];
        this.authService.loadTagsData().then(() => {
          this.initiateTags();
        });
      }
    }, (error: HttpErrorResponse) => {
      window.alert(error.error);
      dialogRef.close();
    }, () => {
      // close spinner
      dialogRef.close();
    });
  }

  attachFile(event) {
    const maxSize: number = 10000000; // 10 MB
    const file = (event.target as HTMLInputElement).files[0];
    if (file.size > maxSize) {
      // check file
      let message = this.translateService.instant('Limit Exceeded: ');
      message += `${Math.floor(file.size / 1000000)} MB / ${maxSize / 1000000} MB`
      window.alert(message);
      return;
    } else {
      // upload file
      // show progress
      const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });

      let formData = new FormData();
      formData.append('attachment', file, file.name);
      this.dataService.putURL(`user/videos/${this.video_id}/`, formData,
        {observe: 'body', responseType: 'json'}).subscribe((res: VideoView) => {
          this.video_object.attachment = res.attachment;
          // update name
          try {
            this.attached_file_name = res.attachment.split("/").pop().split("?")[0];
          }
          catch (e) {
            this.attached_file_name = res.attachment;
            console.error(e);
          }
      }, (err) => {
          this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'),
            '', { duration: 2500 })
          console.error(err);
          dialogRef.close();
      }, () => {
          // close dialog
          dialogRef.close();
      });
    }
  }

  deleteAttachment() {
    let message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      let formData = new FormData();
      formData.append('attachment', '');
      this.dataService.putURL(`user/videos/${this.video_id}/`, formData, {
        responseType: 'text',
      }).subscribe(
        (resp) => {
          this.video_object.attachment = null;
          this.attached_file_name = "";
        });
    }
  }

  // for quill editor
  insertScreenshot(){
    // draw the image
    const canvas = document.createElement('canvas');
    // Get a handle on the 2d context of the canvas element
    const context = canvas.getContext('2d');
    // Takes a snapshot of the video
    const width = this.player.videoWidth;
    const height = this.player.videoHeight;
    canvas.width = width;
    canvas.height = height;
    context.fillRect(0, 0, width, height);
    // Grab the image from the video
    context.drawImage(this.player, 0, 0, width, height);

    let newData = canvas.toDataURL('image/jpeg', 0.3);
    copyImageToClipboard(
      newData,
    ).then((res) => {
      let quill = this.customQuill.quillEditor;
      let cursor = quill.getSelection();
      let index = 0;
      if (cursor) {
        // when user is not active in desc, then cursor is set to NONE
        // hence we add image in this case to the 0th index
        index = cursor.index;
      }
      quill.insertText(index, '\n', 'silent');
      quill.insertEmbed(index, 'image', newData, 'silent');
      quill.insertText(index, '\n', 'silent');
      // quill.scrollSelectionIntoView();  // error: this is not a function
      // quill.clipboard.dangerouslyPasteHTML(index, `<br><img src="${newData}"/><br><br><br>`);
      this.desc = quill.root.innerHTML;
    }).catch((e) => { console.log('Error: ', e.message); })
  }

  onContentChanged(event) {
    let quill = this.customQuill.quillEditor;
    var regex = /https?:\/\/[^\s]+/;
    // AutoLink URL when pasting 
    quill.clipboard.addMatcher(Node.TEXT_NODE, function (node, delta) {
      if (typeof (node.data) !== 'string') return;
      var matches = node.data.match(regex);
      if (matches && matches.length > 0) {
        var ops = [];
        var str = node.data;
        matches.forEach(function (match) {
          var split = str.split(match);
          var beforeLink = split.shift();
          ops.push({ insert: beforeLink });
          ops.push({ insert: match, attributes: { link: match } });
          str = split.join(match);
        });
        ops.push({ insert: str });
        delta.ops = ops;
      }
      return delta;
    });
    // Autolink URLs when typing   
    quill.on('text-change', function (node, delta, oldDelta, source) {
      const isWhitespace = (char) => {
        const whiteSpaceRegex = /\s/;
        return typeof char === 'string' && whiteSpaceRegex.test(char);
      };
      if (delta.ops.length === 2 && delta.ops[0].retain && isWhitespace(delta.ops[1].insert)) {
        var endRetain = delta.ops[0].retain;
        var text = quill.getText().substr(0, endRetain);
        var match = text.match(regex);
        if (match !== null) {
          var url = match[0];
          var ops = [];
          if (endRetain > url.length) {
            ops.push({ retain: endRetain - url.length });
          }
          ops = ops.concat([
            { delete: url.length },
            { insert: url, attributes: { link: url } }
          ]);
          quill.updateContents({
            ops: ops
          });
        }
      }
    });
  }

  // estimate the time it would take to process the video
  estimateTimeNeeded() {
    // 720p => 1s -> 0.7s
    // 1080p => 1s -> 1s
    // 1440p => 1s -> 1.5s
    let estimated_seconds = 0;
    switch (this.video_object.project_settings) {
      case '720':
        estimated_seconds = this.video_object.duration * 0.7;
        break;
      case '1080':
        estimated_seconds = this.video_object.duration * 1;
        break;
      default:
        estimated_seconds = this.video_object.duration * 1.5;
        break;
    }
    // find the time in string
    let estimated_minutes = (estimated_seconds/60 + 1).toFixed(0);  // + 1 minute margin
    this.time_estimate_to_process_video = this.translateService.instant("May take: ");
    this.time_estimate_to_process_video += estimated_minutes;  // it also rounds off
    this.time_estimate_to_process_video += " "
    if(parseInt(estimated_minutes) < 2){
      this.time_estimate_to_process_video += this.translateService.instant('Minute');
    }
    else {
      this.time_estimate_to_process_video += this.translateService.instant('Minuten');
    }
  }

  duplicateVideo() {
    // show spinner
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });

    this.dataService.getURL(`user/videos/${this.video_id}/duplicate/`, {observe: 'body', responseType: 'text'})
      .subscribe((res) => {
        dialogRef.close();
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
        this.router.navigate(['create-video', res, 'record']);
      }, (err) => {
        dialogRef.close();
        window.alert(this.translateService.instant('Ein Fehler ist aufgetreten'));
        console.error(err);
      });
  }

  hideTitleEditor() {
    this.is_title_editor_visible = false;
    this.is_text_area_active = false;
    if (this.title.length) {
      this.video_object.title = this.title;
    }
  }

  openChapterPopup(){
    // saving existing data is not needed, as user would come back to this page
    // do not clear timeout, as video would also expire in the chapter popup too
    // upon timeout, data would be anyway saved

    const dialogData = {
      id: this.video_object.id,
      duration: this.video_object.duration,
      chapter_file: this.video_object.chapter_file,
      processed_file: this.video_object.processed_file,
    };
    this.pausePlayer();
    this.is_text_area_active = true;
    const dialogRef = this.dialog.open(ChapterCreationPopupComponent, {
      maxWidth: '99vw',  // default is 80vw
      disableClose: true,
      // autoFocus: false,
      data: dialogData,
      // panelClass: 'chapter-container'
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.is_text_area_active = false;
      this.video_object.chapter_file = res;
    });
  }

  @HostListener('document:keydown.space', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // if text area or player is active, then do not prevent default
    if (this.is_text_area_active) {
      return;
    }

    if (this.player) {
      event.preventDefault();
      if (this.player.paused) {
        this.player.play();
      } else {
        this.pausePlayer();
      }
    }
  }


  protected readonly environment = environment;
}
