import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from '../../services/auth.service';
import {DataService} from '../../services/data.service';
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {NavbarService} from "../../services/navbar.service";

@Component({
  selector: 'app-set-creator-dialog',
  templateUrl: './set-creator-dialog.component.html',
  styleUrls: ['./set-creator-dialog.component.scss']
})
export class SetCreatorDialogComponent implements OnInit {
  maxCreators: number = 0;
  numAvailable: number = 0;
  creatorButtonTooltip: string = "";
  auto_creator_user: boolean = false;

  constructor(private dataService: DataService,
              protected navbarService: NavbarService,
              private router: Router,
              private authService: AuthService,
              public dialogRef: MatDialogRef<SetCreatorDialogComponent>,
              private translateService: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.auto_creator_user = this.authService.company.auto_creator_user;
    this.maxCreators = this.authService.company.max_creators;

    // this.maxCreators = localStorage.getItem('max_creators');
    this.dataService.getURL<any>(`company/num-creators/`, {observe: 'body', responseType: 'json'}).subscribe((res) => {
      this.numAvailable = this.maxCreators - res['creators'];
      // button should only be enabled if auto_creator_user is true and there are creator licenses available
      if (this.auto_creator_user) {
        if (this.numAvailable) {
          // licenses are available
          this.creatorButtonTooltip = this.translateService.instant("To create your own content, you need Creator rights.");
        } else {
          // no licenses left
          if (this.authService.userDetails.is_company_manager) {
            // tell user to buy more licenses
            this.creatorButtonTooltip = "Dashboard -> ";
            this.creatorButtonTooltip += this.translateService.instant("Manage Workspace");
          } else {
            // tell user to ask admin
            this.creatorButtonTooltip = this.translateService.instant("Please contact your Clypp administrator");
          }
        }
      } else {
        // check if I am a manager
        if (this.authService.userDetails.is_team_manager) {
          // user should be at least a team manager
          // tell user to visit dashboard
          this.creatorButtonTooltip = "Dashboard -> ";
          this.creatorButtonTooltip += this.translateService.instant("Manage Users");
        } else {
          // tell user to contact manager
          this.creatorButtonTooltip = this.translateService.instant("Please contact your Clypp administrator");
        }
      }
    });
  }

  submitCreator() {
    // if licenses are available and auto user is enabled, make user a creator
    if (this.auto_creator_user) {
      if (this.numAvailable) {
        // send patch request
        this.dataService.patchURL('user/', {'is_creator': true},
          {observe: 'body', responseType: 'json'}).subscribe((res: any) => {
          if (res.is_creator) {
            window.alert(this.translateService.instant("Erfolgreich"));
            location.reload();
          } else {
            // did not create them a creator
            window.alert(this.translateService.instant('Ein Fehler ist aufgetreten'));
          }
        }, (err) => {
          window.alert(err.error);
          window.alert(this.translateService.instant('Ein Fehler ist aufgetreten'));
        });
      } else {
        // no licenses are left
        if (this.authService.userDetails.is_company_manager) {
          // email Clypp support
          this.mailManager('support');
        } else {
          // mail to admin
          this.mailManager();
        }
      }
    } else {
      // auto creator is disabled
      if (this.authService.userDetails.is_team_manager) {
        // go to manage users
        this.router.navigate(['dashboard', 'user']);
      } else {
        // mail to admin
        this.mailManager();
      }
    }
    this.dialogRef.close();
  }

  mailManager(scope: 'team' | 'dept' | 'glob' | 'support' = 'team') {
    this.dataService.getURL('user/managers/', {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: ManagerEmails) => {
      let emails: string[] = [];
      switch (scope) {
        case "team":
          emails = res.team_managers_emails;
          if (emails.length) {
            break;
          } // else go to next scope
        case "dept":
          emails = res.dept_managers_emails;
          if (emails.length) {
            break;
          } // else go to next scope
        case "glob":
          emails = res.glob_managers_emails;
          break;
        case "support":
          emails = ["support@clypp.app"];
          break;
      }

      const href: string = `mailto:${emails.join(';')}?subject=Creator license request&body=`;
      window.open(href, "_blank");
    });
  }
}

interface ManagerEmails {
  glob_managers_emails: string[],
  dept_managers_emails: string[],
  team_managers_emails: string[]
}
