import { CdkDragEnd } from '@angular/cdk/drag-drop';
import {
  Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy, OnChanges, ViewChild, ElementRef, EventEmitter,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {AuthService} from "../services/auth.service";
// import 'css-font-loading-module'

@Component({
  selector: 'app-text-overlay-drag-drop',
  templateUrl: './text-overlay-drag-drop.component.html',
  styleUrls: ['./text-overlay-drag-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,


})
export class TextOverlayDragDropComponent implements OnInit, OnChanges {
  @Input('id') id: any;
  @Input('fontSize') fontSize: any;
  @Input('overlayObj') overlayObj: any;
  @Output() onDrag = new EventEmitter<{ id: any, x: number, y: number }>();
  @Output() selectTextBox = new EventEmitter<{ id: any}>();
  styleObject: any;
  @Input('textBoxId') textBoxId : any;
  @ViewChild('block') block: ElementRef;
  @Input('videoSize') videoSize: any;
  @Input('noBackground') noBackground: any;
  @Input('visibility') visibility: any;
  dragEvent: any;
  backgroundColor:any;
  visibilityValue:any;
  fontUrl = '';
  styles;
  positionX: any;
  positionY:any;

  constructor(public cd: ChangeDetectorRef, public authService: AuthService) {
    this.loadCompanyDetails();

  }

  loadCompanyDetails() {
    this.fontUrl = this.authService.company.font_file;
    this.styles = `
          @font-face {
              font-family: 'font1';
              src: url("${this.fontUrl}") format("truetype");
          }
          div#block div{
              font-family: 'font1', sans-serif !important;
          }`
    const node = document.createElement('style');
    node.innerHTML = this.styles;
    document.body.appendChild(node);
  }

  ngOnInit(): void {
    if(this.overlayObj['overlayBox'] == 0){
      this.backgroundColor ='transparent';
  }else{
      this.backgroundColor = this.overlayObj.boxColor.rgba;
  }
  if(this.visibility == false){
    this.visibilityValue = 'hidden';
  }else{
    this.visibilityValue = 'visible';
  }

    this.styleObject = {
      'background-color': this.backgroundColor,
      'color': this.overlayObj.fontColor.rgba,
      'font-size': this.fontSize,
      'height': 'max-content',
      'width': 'max-content',
      'text-align': 'left',
      'left': `${this.overlayObj.overlayEditX}px`,
      'top': `${this.overlayObj.overlayEditY}px`,
      'max-width': `${this.videoSize.width - this.overlayObj.overlayEditX}px`,
      // 'max-height': `${this.videoSize.height - this.overlayObj.overlayEditY}px`,
      'visibility': this.visibilityValue,
      'padding': '2px 4px 4px 4px',
      // 'overflow':'auto'
    }
    // console.log("maxheight",this.videoSize.width - this.block.nativeElement.getBoundingClientRect().height)
    // console.log("x and y",this.block.nativeElement.getBoundingClientRect().height)
    this.cd.detectChanges();
  }

  ngOnChanges() {
    this.cd.detectChanges();
  }

  onDragEnded(event:CdkDragEnd, id) {
    this.onDrag.emit({
      'id': id,
      'x': this.block.nativeElement.getBoundingClientRect().x,
      'y': this.block.nativeElement.getBoundingClientRect().y
    });
    this.styleObject = {
      'background-color': this.backgroundColor,
      'color': this.overlayObj.fontColor.rgba,
      'font-size': this.fontSize,
      'height': 'max-content',
      'width': 'max-content',
      'text-align': 'left',
      'left': `${this.overlayObj.overlayEditX}px`,
      'top': `${this.overlayObj.overlayEditY}px`,
      'max-width': `${this.videoSize.width - event.source.getFreeDragPosition().x}px`,
      'padding': '2px 4px 4px 4px',
      // 'max-height': `${this.videoSize.height - event.source.getFreeDragPosition().y - this.block.nativeElement.clientHeight}px`,
      // 'overflow':'auto'
    }
    this.cd.detectChanges();
  }

  selectDiv(id){
    this.selectTextBox.emit({
      'id':id
    })
  }

  deleteDiv(id) {
    var deleteDiv = document.getElementById(id);
    deleteDiv.parentNode.removeChild(deleteDiv);
  }
}


