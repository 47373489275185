import {Component, Injectable, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {BehaviorSubject, of as observableOf} from 'rxjs';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslateService} from '@ngx-translate/core';
import {AlertDialogComponent} from 'src/app/shared/alert-dialog/alert-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DataService} from 'src/app/services/data.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {MembersListPopupComponent} from '../../../shared/members-list-popup/members-list-popup.component';

export interface TreeData {
  id: number;
  classId?: number;
  name: string;
  desc: string;
  added_on?: string;
  users?: number;
  parent?: boolean;
  teams?: TreeData[];
}

class TreeClass implements TreeData {
  id: number;
  classId: number;
  name: string;
  desc: string;
  added_on?: string;
  users?: number;
  parent?: boolean;
  teams?: TreeClass[];

  constructor(values: TreeData) {
    this.id = values.id;
    this.name = values.name;
    this.users = values.users;
    this.parent = values.parent;
    this.teams = values.teams !== undefined ? toTreeClassList(values.teams) : undefined;
    this.classId = idcounter++;
  }
}

function toTreeClassList(values: TreeData[]): TreeClass[] {
  return values.map(tree => {
    //Since only teams has no teams attribute present
    if (tree.teams != undefined) {
      tree.parent = true;
    } else {
      tree.parent = false;
    }
    return new TreeClass(tree)
  });
}

var idcounter = 1;


@Injectable({
  providedIn: 'root'
})
export class TreeFunctionService {

  flatJsonArray(flattenedAray: Array<TreeData>, node: TreeData[]) {
    const array: Array<TreeData> = flattenedAray;
    node.forEach(element => {
      if (element.teams) {
        array.push(element);
        this.flatJsonArray(array, element.teams);
      }
    });
    return array;
  }

  findNodeMaxid(node: TreeData[]) {
    const flatArray = this.flatJsonArray([], node);
    const flatArrayWithoutChildren = [];
    flatArray.forEach(element => {
      flatArrayWithoutChildren.push(element.classId);
    });
    return Math.max(...flatArrayWithoutChildren);
  }

  findPosition(id: number, data: TreeData[]) {
    for (let i = 0; i < data.length; i += 1) {
      if (id === data[i].classId) {
        return i;
      }
    }
  }

  findFatherNode(id: number, data: TreeData[]) {
    for (let i = 0; i < data.length; i += 1) {
      const currentFather = data[i];
      for (let z = 0; z < currentFather.teams?.length; z += 1) {
        if (id === currentFather.teams[z]['classId']) {
          return [currentFather, z];
        }
      }
      for (let z = 0; z < currentFather.teams?.length; z += 1) {
        if (id !== currentFather.teams[z]['classId']) {
          const result = this.findFatherNode(id, currentFather.teams);
          if (result !== false) {
            return result;
          }
        }
      }
    }
    return false;
  }

}

@Injectable()
export class TreeDataService {
  _dataChange = new BehaviorSubject<TreeData[]>([]);
}


@Component({
  selector: 'dept-team',
  templateUrl: './dept-team.component.html',
  styleUrls: ['./dept-team.component.scss']
})
export class DeptTeamComponent implements OnInit {
  nestedTreeControl: NestedTreeControl<TreeData>;
  nestedDataSource: MatTreeNestedDataSource<TreeData>;
  titleDept: any;
  subtitleDept: string;
  titleTeam: string;
  subtitleTeam: string;
  editTitleDept: string;
  editSubtitleDept: string;
  editTitleTeams: string;
  lang: any;
  editSubtitleTeams: string;
  authorised: boolean = false;

  constructor(
    private treeDataService: TreeDataService,
    private dataService: DataService,
    private service: TreeFunctionService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    public authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.authorised = this.authService.userDetails.is_company_manager || this.authService.userDetails.is_global_manager;
    this.nestedTreeControl = new NestedTreeControl<TreeData>(this._getChildren);
    this.nestedDataSource = new MatTreeNestedDataSource();
    if(this.authorised){
      this.getDeptTeams();
    }
    this.treeDataService._dataChange.subscribe(
      data => (this.nestedDataSource.data = data)
    );
  }

  getDeptTeams(){
    this.dataService.getURL<any>('manager/departments/').subscribe((res) => {
      let treeData = toTreeClassList(res);
      this.treeDataService._dataChange.next(treeData);
    });
  }

  hasNestedChild = (_: number, nodeData: TreeData) => nodeData.teams?.length > 0;

  refreshTreeData() {
    const data = this.nestedDataSource.data;
    this.nestedDataSource.data = null;
    this.nestedDataSource.data = data;
    // update checklist
    this.authService.loadChecklistData();
  }

  addChildNode(childrenNodeData) {
    childrenNodeData.node.classId = this.service.findNodeMaxid(this.nestedDataSource.data) + 1;
    childrenNodeData.currentNode.teams.push(childrenNodeData.node);
    this.refreshTreeData();
  }

  openDialog(currentNode) {
    let title;
    let subtitle;
    let placeholder;
    let btnText = this.translateService.instant(`Erstellen`);
    if (currentNode == null) {
      this.translateService.get('_Neue_Abteilung', {
        val_1: this.authService.company.dept_alias,
      }).subscribe((res: string) => {
        this.titleDept = res;
      });
      this.translateService.get('_SubTitle_Dept', {
        val_1: this.authService.company.dept_alias
      }).subscribe((res: string) => {
        this.subtitleDept = res;
      });
      title = this.titleDept,
        subtitle = this.subtitleDept,
        placeholder = this.authService.company.dept_alias + " Name"
    } else {
      this.translateService.get('_Title_Teams', {
        val_1: this.authService.company.team_alias,
        val_2: currentNode.name
      }).subscribe((res: string) => {
        this.titleTeam = res;
      });
      this.translateService.get('_SubTitle_Teams', {
        val_1: this.authService.company.team_alias,
        val_2: currentNode.name
      }).subscribe((res: string) => {
        this.subtitleTeam = res;
      });
      title = this.translateService.instant('_Add_Team', {val: this.authService.company.team_alias});
      subtitle = this.subtitleTeam;
      placeholder = this.authService.company.team_alias + " Name"

    }
    // https://github.com/biesbjerg/ngx-translate-extract/issues/206 Not Extracting if _(title)
    const dialogData = {
      title,
      subtitle,
      placeholder,
      fieldText:'',
      actionText: btnText,
    };

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      autoFocus: false,
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResponse: any) => {
      if (dialogResponse?.clickedSubmit) {
        let node: TreeData = {
          id: null,
          name: dialogResponse.fieldText,
          desc: "result.nodeDescription",
          teams: []
        };
        if (currentNode != null) {
          this.addAPI(node, currentNode.id).subscribe((res: any) => {
              node.id = res.id;
              let addEntry = {currentNode, node: node};
              this.addChildNode(addEntry);
              let message = this.translateService.instant('Erfolgreich');
              this.snackBar.open(message, '', {duration: 2500});
              this.getDeptTeams();
            },
            (err) => this.handleErrors(err));
        } else {
          this.addAPI(node, null).subscribe((res: any) => {
              node.id = res.id;
              node.parent = true;
              let data = [...this.nestedDataSource.data, node]
              this.treeDataService._dataChange.next(data);
              let message = this.translateService.instant('Erfolgreich')
              this.snackBar.open(message, '', {duration: 2500});
              this.refreshTreeData();
              this.getDeptTeams();
            },
            (err) => this.handleErrors(err));
        }
      }
    });
  }

  editDialog(currentNode) {
    let title;
    let subtitle;
    let placeholder;
    if (currentNode.parent) {
      this.translateService.get('_Edit_Title_Dept', {
        val_1: this.authService.company.dept_alias
      }).subscribe((res: string) => {
        this.editTitleDept = res;
      });
      this.translateService.get('_Edit_Subtitle_Dept', {
        val_1: this.authService.company.dept_alias
      }).subscribe((res: string) => {
        this.editSubtitleDept = res;
      });

      // title = _(`Abteilung bearbeiten`);
      // subtitle = _(`Wie lautet der Name deiner Abteilung?`);
      title = this.editTitleDept;
      subtitle = this.editSubtitleDept;
      placeholder = this.authService.company.dept_alias + " Name"
      // placeholder = this.translateService.instant(`z.B. Abteilung 1`);
    } else {

      this.translateService.get('_Edit_Title_Teams', {
        val_1: this.authService.company.team_alias
      }).subscribe((res: string) => {
        this.editTitleTeams = res;
      });
      this.translateService.get('_Edit_Subtitle_Teams', {
        val_1: this.authService.company.team_alias
      }).subscribe((res: string) => {
        this.editSubtitleTeams = res;
      });

      title = this.editTitleTeams;
      subtitle = this.editSubtitleTeams;
      placeholder = this.authService.company.team_alias + " Name"
    }
    // https://github.com/biesbjerg/ngx-translate-extract/issues/206 Not Extracting if _(title)
    const dialogData = {
      title: title,
      subtitle: subtitle,
      placeholder,
      fieldText: currentNode.name,
      actionText: this.translateService.instant('Speichern')
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      autoFocus: false,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe((dialogResponse: any) => {
      if (dialogResponse?.clickedSubmit) {
        const node: TreeData = {
          ...currentNode, name: dialogResponse.fieldText
        };
        let message = this.translateService.instant("Erfolgreich");

        if (currentNode.parent) {
          this.editAPI(node, true).subscribe((res: any) => {
              this.editNode({currentNode: currentNode, node: node});
              this.snackBar.open(message, '', {duration: 2500});
            },
            (err) => this.handleErrors(err));
        } else {
          this.editAPI(node, false).subscribe((res: any) => {
              this.editNode({currentNode: currentNode, node: node});
              this.snackBar.open(message, '', {duration: 2500});
            },
            (err) => this.handleErrors(err),
          );

        }
      }
    });
  }

  handleErrors(err) {
    if (err.error?.non_field_errors) {
      alert(err.error.non_field_errors[0])
    } else {
      alert(err.message)
    }
  }

  editNode(nodeToBeEdited) {
    const fatherElement: TreeData = this.service.findFatherNode(nodeToBeEdited.currentNode.classId, this.nestedDataSource.data);
    let elementPosition: number;
    nodeToBeEdited.node.classId = this.service.findNodeMaxid(this.nestedDataSource.data) + 1;
    if (fatherElement[0]) {
      fatherElement[0].teams[fatherElement[1]] = nodeToBeEdited.node;
    } else {
      elementPosition = this.service.findPosition(nodeToBeEdited.currentNode.classId, this.nestedDataSource.data);
      this.nestedDataSource.data[elementPosition] = nodeToBeEdited.node;
    }
    this.refreshTreeData();
    return fatherElement;
  }

  hasSomeUsers(nodeToBeDeleted): void {
    let message = this.translateService.instant("Du musst die Benutzer/innen zuerst übertragen oder löschen, bevor du die organisatorische Ebene löschen kannst.");
    message += '\n\n';
    message += this.translateService.instant("Redirect to the user management?");
    if (window.confirm(message)) {
      this.router.navigate(['dashboard', 'user']);
    }
  }

  openDeptMembersPopup(node: TreeData): void {
    this.dataService.getURL<any>(`user/company/department/${node.id}/?members=true`).subscribe((res) => {
      if (res.length == 0) {
        this.snackBar.open(_('No members here'), '', {duration: 2500});
      } else {
        const dialogRef = this.dialog.open(MembersListPopupComponent, {
          width: "40vw",
          minWidth: "350px",
          height: "80vh",
          data: {
            res,
            adminBadgeTooltip: this.translateService.instant('Dept_Management', {val_1: this.authService.company.dept_alias,}),
            title: this.translateService.instant("Mitwirkende"),
          }  // 1 = visible for dept , 0 = visible for team , -1 = invisible
        });
      }
    });

  }

  openTeamMembersPopup(node: TreeData): void {
    this.dataService.getURL<any>(`user/teams/${node.id}/?members=true`).subscribe((res) => {
      if (res.length == 0) {
        this.snackBar.open(_('No members here'), '', {duration: 2000});
      } else {
        const dialogRef = this.dialog.open(MembersListPopupComponent, {
          width: "40vw",
          minWidth: "350px",
          height: "80vh",
          data: {
            res,
            adminBadgeTooltip: this.translateService.instant('Teams_Management', {val_1: this.authService.company.team_alias,}),
            title: this.translateService.instant("Mitwirkende"),
          }
        });
      }
    });
  }

  deleteNode(nodeToBeDeleted: TreeData) {
    const deletedElement: TreeData = this.service.findFatherNode(nodeToBeDeleted.classId, this.nestedDataSource.data);
    let elementPosition: number;
    if (nodeToBeDeleted.users > 0) {
      this.hasSomeUsers(nodeToBeDeleted);
    } else {
      if (window.confirm(this.translateService.instant('Löschen') + " " + nodeToBeDeleted.name + '?')) {
        if (deletedElement[0]) {
          this.deleteAPI(nodeToBeDeleted.id, false).subscribe((res: any) => {
          }, err => {
          }, () => {
            this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2000});
            deletedElement[0].teams.splice(deletedElement[1], 1);
            this.refreshTreeData();

          });
        } else {
          elementPosition = this.service.findPosition(nodeToBeDeleted.classId, this.nestedDataSource.data);
          this.deleteAPI(nodeToBeDeleted.id, true).subscribe((res: any) => {
          }, err => {
          }, () => {
            this.snackBar.open(this.translateService.instant("Erfolgreich gelöscht"), '', {duration: 2000});
            this.nestedDataSource.data.splice(elementPosition, 1);
            this.refreshTreeData();
          });
        }
      }
    }
  }


  deleteAPI(id, intentionForDept) {
    let intention = (intentionForDept) ? `user/company/department/${id}/` : `user/teams/${id}/`;
    return this.dataService.deleteURL(intention);
  }

  addAPI(postObject, id = null) {
    let intention = (id) ? `user/company/department/${id}/teams/` : `user/company/department/`;
    return this.dataService.postURL(intention, postObject);
  }

  editAPI(putObject, intentionForDept) {
    let intention = (intentionForDept) ? `user/company/department/${putObject.id}/` : `user/teams/${putObject.id}/`;
    return this.dataService.putURL(intention, putObject);
  }

  private _getChildren = (node: TreeData) => observableOf(node.teams);
}
