<div class="d-flex justify-content-between">
  <h1 class="py-2" mat-dialog-title>
    {{ 'Text to speech'|translate }}
    <mat-icon class="material-icons-outlined"
              matTooltip="{{'You can create AI-generated audio overlays by using text-to-speech'|translate}}"
              matTooltipPosition="right"
              style="vertical-align: sub;">info
    </mat-icon>
  </h1>
  <button [mat-dialog-close]="null" mat-icon-button>
    <mat-icon style="scale: 1.2">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div *ngIf="script_lines.length"
       style="overflow-y: auto; max-height: 200px;">
    <p (click)="appendLine(script_line);" *ngFor="let script_line of script_lines"
       class="cursor-pointer"
       mat-card-subtitle
       title="{{'Insert below'|translate}}">
      {{ script_line }}
    </p>
  </div>
  <!--input text-->
  <mat-form-field appearance="outline" class="w-100" [hidden]="!show_text_input">
    <mat-label>Text</mat-label>
    <textarea [(ngModel)]="text" [maxlength]="MAX_LENGTH" id="text-area" matInput
              cdkFocusInitial
              placeholder="{{'Type or paste the text you want to convert to speech.'|translate}}"
              required
              rows="5">
    </textarea>
    <!-- hide the hint as the number is random -->
    <!--    <mat-hint matSuffix>{{text.length}}/{{MAX_LENGTH}}</mat-hint> -->
    <mat-error *ngIf="text.length > MAX_LENGTH" class="my-2 text-nunito" translate>
      The provided input is too large. Try deleting some content.
    </mat-error>
    <!--make it shorter button, use clypp ai-->
    <button (click)="shortenText();" *ngIf="text.length > 100"
            mat-icon-button matTooltip="{{'Make it shorter'|translate}}"
            matTooltipPosition="right" matSuffix>
      <mat-icon class="material-icons-outlined">auto_fix_high</mat-icon>
    </button>
  </mat-form-field>

  <mat-slide-toggle [(ngModel)]="split_sentences" [hidden]="!show_text_input" class="mb-3">
    {{ 'Create separate audio tracks for each sentence' | translate }}
  </mat-slide-toggle>

  <!--input locale and short name-->
  <div class="d-flex justify-content-between flex-column">
    <!--voice selection with sample-->
    <div class="d-flex align-items-baseline" class="mt-1">
      <!-- <mat-form-field appearance="outline" class="w-50">
        <mat-label translate>Voice</mat-label>
        <mat-select [(ngModel)]="voice_name">
          <mat-optgroup *ngFor="let group of all_languages" [label]="group.LocaleName">
            <mat-option *ngFor="let voice of group.voices" [value]="voice.ShortName">
              {{ voice.DisplayName }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field> -->
      <mat-form-field appearance="outline" class="w-50 mr-2">
        <mat-label translate>Voice</mat-label>
        <input (input)="filterLocales($event);" [matAutocomplete]="auto"
               [value]="selectedVoice?.DisplayName || ''" matInput
               type="text">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectVoice($event.option.value)">
          <ng-container *ngFor="let group of filteredLanguages">
            <mat-optgroup [label]="group.LocaleName">
              <mat-option *ngFor="let voice of group.voices" [value]="voice">
                {{ voice.DisplayName }}
              </mat-option>
            </mat-optgroup>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <mat-label translate>Speech rate</mat-label>
      <mat-slider
        [min]="0.9"
        [max]="1.1"
        [step]="0.05"
        [(ngModel)]="speed"
        [disabled]="voice_name.includes('Neural')">
      </mat-slider>

      <mat-form-field [hidden]="!show_text_input" appearance="outline" class="ml-2" hideRequiredMarker
                      style="width: 110px;">
        <mat-label>Start: {{toHHMMSS()}}</mat-label>
        <input type="number" min="0" [max]="duration" [(ngModel)]="start_time" required matInput step="1">
        <mat-error *ngIf="start_time > duration">Max: {{duration}}</mat-error>
      </mat-form-field>
    </div>

    <!-- language field-->
    <div class="d-flex align-items-center" style="height: 71px;">
      <mat-slide-toggle [(ngModel)]="translate">
        <!-- upon change of toggle, if translate is enabled, disable the split_lines -->
        <span translate>Translate</span>
      </mat-slide-toggle>
      <mat-icon class="ml-2"
                matTooltip="{{'Your text will be translated into the language you select in the dropdown'|translate}}"
                matTooltipPosition="below">
        info_outline
      </mat-icon>

      <!--language selector, if translation is enabled-->
      <mat-form-field *ngIf="translate" appearance="outline" class="ml-3">
        <mat-label translate>Language</mat-label>
        <mat-select [(ngModel)]="to_lang">
          <mat-option *ngFor="let option of languages" [value]="option[0]">
            {{ option[1] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

<div mat-dialog-actions style="float: right">
  <button (click)="onSubmit();" [disabled]="text.length==0" color="primary"
          class="border-radius-20"
          mat-stroked-button>
    <mat-icon class="material-icons-outlined mr-2">auto_awesome</mat-icon>
    <span translate>Erstellen</span>
  </button>
</div>
