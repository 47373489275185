import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from 'src/app/services/data.service';
import {MembersListPopupComponent} from '../../../shared/members-list-popup/members-list-popup.component';
import {AuthService} from "../../../services/auth.service";
import {LocationPosition} from "../../../models/interfaces/auth-response";

@Component({
  selector: 'app-position-management',
  templateUrl: './position-management.component.html',
  styleUrls: ['./position-management.component.scss']
})
export class PositionManagementComponent implements OnInit {
  posDataSource: MatTableDataSource<LocationPosition>;
  all_positions: LocationPosition[] = [];
  displayedColumns: string[] = ['name', 'users', 'delete'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('posTableSort') public posTableSort: MatSort;

  // only global and company admin can view members, edit/delete positions.
  authorised: boolean = false;

  constructor(private dialog: MatDialog,
              private dataService: DataService,
              private translateService: TranslateService,
              private authService: AuthService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.authorised = this.authService.userDetails.is_company_manager;
    // every company manager is also a global manager
    this.loadPositions();
  }

  loadPositions() {
    // anyone can fetch positions
    this.dataService.getURL<LocationPosition[]>('user/company/roles/').subscribe((res) => {
      this.all_positions = res;
      this.initiateTable();
    });
  }

  initiateTable(){
    delete this.posDataSource;
    this.posDataSource = new MatTableDataSource(this.all_positions);
    this.posDataSource.sort = this.posTableSort;
  }

  addPosition(){
    // top 2 managers can add a location
    const message = this.translateService.instant("Please provide a name:");
    let name = window.prompt(message, '');
    if(name){
      name = name.trim().slice(0, 99);
      if(name){
        this.dataService.postURL<LocationPosition>('user/company/roles/', {name: name},
          {observe: 'body', responseType: 'json'}).subscribe((res) => {
            this.all_positions.push(res);
            this.initiateTable();
            this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
        }, (err) => {
          if (err.status == 400) {
            // invalid body or duplicate
            this.snackBar.open(this.translateService.instant('Already exists'), '', {duration: 2500});
          } else {
            // 500 status code
            console.error(err);
            this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
          }
        });
      }
    }
  }

  renamePosition(position: LocationPosition){
    if (!this.authorised) {
      return;
    }
    const message = this.translateService.instant('Please provide a new name:');
    let name = window.prompt(message, position.name);
    if(name){
      // not undefined
      name = name.trim().slice(0, 99);
      if (name) {
        // not empty, send call, it will be trimmed and shortened to 50 characters in the BE
        this.dataService.putURL(`user/company/roles/${position.id}/`, {name: name}, {
          observe: 'body',
          responseType: 'json'
        }).subscribe((res: LocationPosition) => {
          position.name = res.name;  // update table
          this.all_positions.find(el => el.id == res.id).name = res.name;  // update array
          this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
        }, (err) => {
          if (err.status == 400) {
            // invalid body or location exists
            this.snackBar.open(this.translateService.instant('Already exists'), '', {duration: 2500});
          } else {
            // 500 status code
            console.error(err);
            this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
          }
        });
      }
    }
  }

  deletePosition(position: LocationPosition) {
    if (position.users) {
      const errorMsg = this.translateService.instant("Error: There are users in this object");
      this.snackBar.open(errorMsg, '', {duration: 2500});
    } else {
      if (window.confirm(this.translateService.instant("Bist du sicher?"))) {
        this.dataService.deleteURL(`user/company/roles/${position.id}/`).subscribe(res => {
          this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2500});
          const deleted_loc_index = this.all_positions.findIndex(el => el.id == position.id);
          this.all_positions.splice(deleted_loc_index, 1);
          this.initiateTable();
        }, (err) => {
          console.error(err);
          this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
        });
      }
    }
  }

  userCountPosition(value): void {
    // assert request.user.userprofile.is_company_manager or request.user.userprofile.is_global_manager
    if (!this.authorised) {
      let message = this.translateService.instant("You need global or company management rights");
      this.snackBar.open(message, '', {duration: 2000});
      return;
    }

    if (value.users != 0) {
      this.dataService.getURL<any>(`user/company/roles/${value.id}/?members=true`).subscribe((res) => {
        const dialogRef = this.dialog.open(MembersListPopupComponent, {
          width: "40vw",
          minWidth: "350px",
          height: "80vh",
          data: {
            res,
            adminBadgeTooltip: '',
            title: this.translateService.instant("Mitwirkende"),
          } // 1 = visible for dept , 0 = visible for team , -1 = invisible
        });
      });
    }
  }
}
