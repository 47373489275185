<div (scrolled)="onScrollDown()" [fromRoot]="true" [infiniteScrollContainer]="'mat-sidenav-content'"
     [infiniteScrollDistance]="1" [infiniteScrollThrottle]="500"
     [scrollWindow]="false" class="container-fluid"
     (window:resize)="resize($event.target.innerWidth)"
     infiniteScroll>

  <div class="d-flex mt-3 ml-2">
    <mat-icon class="mr-1 material-icons-outlined searchIcon" color="primary" mat-icon>
      search
    </mat-icon>
    <span class="h4 fontStyle">
      {{n_results}}
      <span *ngIf="n_results==1">{{'Search result'|translate}}&nbsp;{{'for'|translate}}:&nbsp;</span>
      <span *ngIf="n_results!=1">{{'Search results'|translate}}&nbsp;{{'for'|translate}}:&nbsp;</span>
      <i>{{search_text}}</i>
    </span>
  </div>

  <div class="d-flex m-2">
    <mat-button-toggle-group (change)="searchTypeChanged()" [(ngModel)]="search_type" aria-label="search type"
                             style="border-radius: 10px; height: 50px;">
      <mat-button-toggle value="all" style="border-radius: 10px 0 0 10px">
        <mat-icon class="iconSize">grid_view</mat-icon>
        {{'Alle'|translate}}
        <span class="text-gray">{{n_video_results + n_topic_results + n_playlist_results}}</span>
      </mat-button-toggle>
      <mat-button-toggle value="videos">
        <mat-icon class="material-icons-outlined iconSize">play_circle</mat-icon>
        Clypps
        <span class="text-gray">{{n_video_results}}</span>
      </mat-button-toggle>
      <mat-button-toggle value="pages">
        <mat-icon class="material-icons-outlined iconSize">sticky_note_2</mat-icon>
        {{'Pages'|translate}}
        <span class="text-gray">{{n_topic_results}}</span>
      </mat-button-toggle>
      <mat-button-toggle value="playlists" style="border-radius: 0 10px 10px 0">
        <mat-icon class="material-icons-outlined iconSize">playlist_play</mat-icon>
        {{"Playlists" | translate}}
        <span class="text-gray">{{n_playlist_results}}</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <!--View all clypps header-->
  <div *ngIf="search_type=='all' && n_video_results!=0" class="d-flex mx-2 mt-3 justify-content-between align-items-end">
    <div class="d-flex align-items-center">
      <mat-icon class="material-icons-outlined mr-2" color="primary">play_circle</mat-icon>
      <h2 class="text-nunito m-0">Clypps</h2>
    </div>
    <button mat-stroked-button class="border-radius-20"
            (click)="search_type='videos'; searchTypeChanged();"
            *ngIf="n_video_results > 10">
      {{'_view_all'|translate:{'value': n_video_results} }}
    </button>
  </div>

  <!-- show videos -->
  <mat-grid-list *ngIf="video_search_results.length"
                 [cols]="cols"
                 [gutterSize]="'1rem'"
                 class="mt-2"
                 rowHeight="320px">
    <mat-grid-tile *ngFor="let video of video_search_results" style="box-shadow: none;">
      <app-video-card [backendUrl]="backendUrl" [videoObject]="video" class="VideoCardStyle"></app-video-card>
    </mat-grid-tile>
  </mat-grid-list>

  <!--View all pages header-->
  <div *ngIf="search_type=='all' && n_topic_results!=0" class="d-flex mx-2 justify-content-between align-items-end">
    <div class="d-flex align-items-center">
      <mat-icon class="material-icons-outlined mr-2" color="primary">sticky_note_2</mat-icon>
      <h2 class="text-nunito m-0">{{'Pages'|translate}}</h2>
    </div>
    <button mat-stroked-button class="border-radius-20"
            (click)="search_type='pages'; searchTypeChanged();"
            *ngIf="n_topic_results > 10">
      {{'_view_all'|translate:{'value': n_topic_results} }}
    </button>
  </div>

  <!--show pages-->
  <mat-grid-list *ngIf="topics_search_results.length"
                 [cols]="cols"
                 [gutterSize]="'1rem'"
                 class="mt-2"
                 rowHeight="320px">
    <mat-grid-tile *ngFor="let topic of topics_search_results" style="box-shadow: none;">

      <app-topic-card [topic_object]="topic" class="VideoCardStyle"></app-topic-card>

      <mat-card class="w-100 bg-transparent cursor-pointer d-none">
        <mat-card-header style="height: 50px;"
                         aria-label="view page">
          <div class="emoji-box" mat-card-avatar>
            <span class="emoji-icon">{{topic.emoji}}</span>
          </div>
          <mat-card-title [title]="topic.title" class="text-nunito" style="max-width: 70vw;">
            {{topic.title}}
          </mat-card-title>
          <mat-card-subtitle class="text-nunito">
            {{'Updated by' | translate}}
            <b [title]="topic.uploader.email">{{topic.uploader.first_name}}&nbsp;{{topic.uploader.last_name}}</b>
            <span title="{{'Erstellt am'|translate}}&nbsp;{{topic.added_on | date:'dd.MM.y'}}">
              {{utilityService.timeSince(topic.last_edited_on, "ago", false) |async}}
            </span>
          </mat-card-subtitle>
        </mat-card-header>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>

  <!--View all playlist header-->
  <div *ngIf="search_type=='all' && n_playlist_results!=0" class="d-flex mx-2 justify-content-between align-items-end">
    <div class="d-flex align-items-center">
      <mat-icon class="material-icons-outlined mr-2" color="primary">playlist_play</mat-icon>
      <h2 class="text-nunito m-0">{{'Playlists'|translate}}</h2>
    </div>
    <button mat-stroked-button class="border-radius-20"
            (click)="search_type='playlists'; searchTypeChanged();"
            *ngIf="n_playlist_results > 10">
      {{'_view_all'|translate:{'value': n_playlist_results} }}
    </button>
  </div>

  <!-- show playlists -->
  <mat-grid-list *ngIf="playlist_search_results.length"
                 [cols]="cols"
                 [gutterSize]="'1rem'"
                 class="mt-2"
                 rowHeight="320px">
    <mat-grid-tile *ngFor="let playlist of playlist_search_results" style="box-shadow: none;">
      <app-video-card-playlist [playlist_object]="playlist" class="VideoCardStyle"></app-video-card-playlist>
    </mat-grid-tile>
  </mat-grid-list>

  <div *ngIf="n_results == 0 && !showSpinner" style="display: flex;
    align-items: center;
    height: 200px;
    position: relative;
    justify-content: center;">
    <h3>
      {{'No results. Search in other categories or'|translate}}
      <u (click)="navbarService.openVideoRequestDialog();" class="pointer">
        {{'ask your colleagues'|translate}}
      </u>
    </h3>
  </div>

  <div *ngIf="showSpinner" class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</div>
