import {AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../services/auth.service';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient, HttpEvent, HttpEventType} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {NavbarService} from '../../services/navbar.service';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-upload-creation',
  templateUrl: './upload-creation.component.html',
  styleUrls: ['./upload-creation.component.scss'],
  host: {'(window:pageshow)': 'eventFunction($event)'}
})
export class UploadCreationComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() vidValue: any;
  color = 'primary';
  mode = 'indeterminate';
  formData: FormData;
  videoFormControl;
  videoURL: any;
  progress: number = 0;
  videoRecordingFinished = false;
  videoProcessingComplete = false;
  environment = environment;
  alreadyEnteredTrimmingStep = false;
  VideoTypeValue: any;
  responseVal: any;
  date: any;
  videoId: any;
  processFinish = false;
  isOnIOS: boolean;
  eventName: any;
  max_video_Size: number = 0;
  blobSize: any;

  constructor(
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private navbarService: NavbarService,
    private router: Router,
    private translateService: TranslateService,
    private dataService: DataService
  ) {
    this.max_video_Size = this.authService.company.max_video_size_bytes;

    // if(navigator.userAgent.match(/iPad/i)|| navigator.userAgent.match(/iPhone/i)){
    //   this.isOnIOS = true;
    // }
    // this.eventName = this.isOnIOS ? "pagehide" : "beforeunload";

  }

  deleteBeforeClosing() {
    return  this.dataService.deleteVideo(this.vidValue).toPromise();
  }
// window close delete call

  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    let value = window.location.href.split("/").pop();
    if (value == 'record' && this.vidValue['video_file'] == null) {
      let httpData = await this.deleteBeforeClosing();
    }
  }

  // @HostListener('document:visibilitychange', ['$event'])
  // visibilitychange($event) {
  // //   async ngOnDestroy()
  // // {
  //   let value = window.location.href.split("/").pop();
  //   if(value == 'record' && this.vidValue['video_file'] == null){
  //      this.dataService.deleteVideo(this.vidValue).subscribe(res=>{
  //     },(error) => {
  //     }
  //     );
  //   }
  // }


  ngOnInit(): void {
    this.navbarService.showSideNav = false;
    this.navbarService.enableCreationMode();
    this.navbarService.hide();
    this.VideoTypeValue = 'UP';
    this.videoFormControl = this.formBuilder.group({
      videoControl: [true, Validators.requiredTrue],
    });
//     window.addEventListener("pagehide", (e)=>{
//       this.myFunction();
//       setInterval(() => {
//       window.alert("page shown123");
//       },3000);
//   });
//   window.addEventListener("visibilitychange", (e)=>{
//     this.myFunction();
//     setInterval(() => {
//     window.alert("page shown123");
//     },3000);
// });
// window.addEventListener("pageshow", (e)=>{
//   if (e.persisted) {
//   this.myFunction();
//   setInterval(() => {
//   window.alert("page shown123");
//   },3000);
// }
// });
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload()
        this.myFunction();
        setInterval(() => {
          window.alert("tab closed");
        }, 3000);
      }
    };
  }


  ngAfterViewInit(): void {
    if (this.vidValue['video_file'] != null || this.vidValue['processed_file'] != null) {
      this.alreadyEnteredTrimmingStep = true;
    }

  }

  uploadedVideo = async (uploadVidObj) => {
    this.videoFormControl.get('videoControl').setValue(true);
    let file = new File([uploadVidObj.uploadedVidBlob], 'upload');
    this.blobSize = file.size;
    this.formData = new FormData();
    this.formData.append('video_file', file, `${new Date().getTime()}.mp4`);
    this.videoURL = uploadVidObj.uploadedVidSrc;
    this.videoRecordingFinished = true;
    if (this.blobSize < this.max_video_Size) {
      this.startConverting();
    } else {
      let message = this.translateService.instant("Limit Exceeded: ") +
        `${Math.floor(file.size / 1000000)} MB / ${this.max_video_Size / 1000000} MB`;
      this.snackBar.open(message, '', {duration: 3000});
    }
  };

  eventFunction(event) {
    window.location.reload()
    if (event.persisted) {
      window.location.reload()
    }
    let value = window.location.href.split("/").pop();
    if (value == 'record' && this.vidValue['video_file'] == null) {
      this.dataService.deleteVideo(this.vidValue).subscribe(res => {
        }, (error) => {
        }
      );
    }

  }

  metaReviewButton() {
    window.moveTo(0, 0);
    window.resizeTo(screen.width, screen.height);
    if (this.vidValue['video_file'] === undefined || this.vidValue['video_file'] === null) {
    } else {
      this.router.navigate(['create-video/' + this.vidValue['id'] + '/edit']);
    }
  }

  startConverting(): void {
    if (typeof this.vidValue != 'object') {
      this.vidValue = JSON.parse(this.vidValue);
    }
    let observable;
    if (this.vidValue['video_file'] != undefined) {
      this.formData.append('edit_parameters', null);
    }
    observable = this.httpClient.put(
      `${environment.backendURL}/user/videos/${this.vidValue['id']}/`,
      this.formData,
      {
        responseType: 'text',
        reportProgress: true,
        observe: 'events'
      }
    );
    this.showProgressSpinnerUntilExecuted(observable);
  }

  showProgressSpinnerUntilExecuted(observable: Observable<any>): void {

    const subscription = observable.subscribe(
      (response: HttpEvent<any>) => {

        switch (response.type) {
          // case HttpEventType.Sent:
          //   break;
          case HttpEventType.ResponseHeader:
            this.processFinish = true;
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(response.loaded / response.total * 100);
            break;
          case HttpEventType.Response:
            this.processFinish = true;
            setTimeout(() => {
              this.responseVal = '';
              this.router.navigate(['create-video/' + this.vidValue['id'] + '/edit']);
              subscription.unsubscribe();
              // handle response
              this.videoProcessingComplete = true;
              // dialogRef.close();
            }, 50);
        }
      },
      (error) => {
        // console.log(error);
        subscription.unsubscribe();
        // handle error
        this.snackBar.open(this.translateService.instant('Verbindung unterbrochen'), '', {duration: 2500});
        this.videoProcessingComplete = true;
        // dialogRef.close();
      }
    );
  }
}
