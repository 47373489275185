import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { environment } from '../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-videos',
  templateUrl: './add-videos.component.html',
  styleUrls: ['./add-videos.component.scss']
})
export class AddVideosComponent implements OnInit {

  listVideos: any;
  colsVideo: number;
  backendUrl: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private translateService:TranslateService, private formBuilder: UntypedFormBuilder, private dataService: DataService, private breakpointObserver: BreakpointObserver, private snackBar: MatSnackBar) {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).subscribe(result => {
      if (result.matches){
        if (result.breakpoints[Breakpoints.XSmall]){
          this.colsVideo = 1;
        }
        if (result.breakpoints[Breakpoints.Small]){
          this.colsVideo = 2;
        }
        if (result.breakpoints[Breakpoints.Medium]){
          this.colsVideo = 3;
        }
        if (result.breakpoints[Breakpoints.Large]){
          this.colsVideo = 4;
        }
        if (result.breakpoints[Breakpoints.XLarge]){
          this.colsVideo = 5;
        }
      }
    });
  }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    this.dataService.getURL(`user/videos/`).subscribe((res: any) => {
      this.listVideos = res.data;
    });
  }

  addVideo(videoObj): void{
    this.dataService.getURL(`user/playlists/${this.data.playlistID}/${videoObj.id}/add/`).subscribe((res: any) => {
      if (res.response == 'success') {
        this.snackBar.open(this.translateService.instant('Video erfolgreich hinzugefügt'), '', {
          duration: 1000,
        });
      } else {

      }
    });
  }

}
