import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import {DataService} from "../../services/data.service";
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SectionLink} from "./company-management/company-management.component";
import {Router} from "@angular/router";
import {inIframe} from "../../login/login.component";
import {ProgressSpinnerDialogComponent} from "../../shared/progress-spinner-dialog/progress-spinner-dialog.component";
import {FeedbackDialogComponent} from "../../shared/feedback-dialog/feedback-dialog.component";
import { CombineTagDialogComponent } from 'src/app/shared/combine-tag-dialog/combine-tag-dialog.component';
import {FeaturedTag} from "../../models/video/video.interface";
import {NavbarService} from "../../services/navbar.service";


@Component({
  selector: 'global-administration',
  templateUrl: './global-administration.component.html',
  styleUrls: ['./global-administration.component.scss']
})
export class GlobalAdministrationComponent implements OnInit {
  @ViewChild('tagTableSort') public tagTableSort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  section_links: SectionLink[] = [];
  observer: IntersectionObserver = null;  // needed to observe when video element is in sight

  allTags: FeaturedTag[] = [];  // this does not make use of "videos" count
  tagsTable: MatTableDataSource<FeaturedTag>;
  featuredTags: FeaturedTag[];
  showSpinner: boolean = false;

  authorised: boolean = false;

  n_creators: number = 0;
  n_users: number = 0;
  n_videos: number = 0;

  constructor(
    public authService: AuthService,
    private router: Router,
    private dataService: DataService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private navbarService: NavbarService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    let u = this.authService.userDetails;
    this.authorised = u.is_global_manager || u.is_company_manager || u.is_quality_manager;
    if(this.authorised){
      this.loadFeaturedTags();
    }

    this.dataService.getURL<any>(`company/num-creators/`, {observe: 'body', responseType: 'json'}).subscribe((res) => {
      this.n_creators = res['creators'];
      this.n_users = res['users'];
      this.n_videos = res['videos'];
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // initiate it after a second otherwise elements are not ready
      this.initiateObserver();
    }, 100);
  }

  loadFeaturedTags() {
    this.dataService.getURL<FeaturedTag[]>('user/featured-tags/', {observe: 'body', responseType: 'json'})
      .subscribe((res) => {
        this.featuredTags = res;
        this.loadTags();
        }, (err) => {
          window.alert(err.error);
        }
      );
  }

  loadTags() {
    // if user.is_global_manager or user.is_company_manager or user.is_quality_manager:
    this.showSpinner = true;
    this.dataService.getURL<FeaturedTag[]>('user/tags/', {observe: 'body', responseType: 'json'})
      .subscribe((res) => {
          res.map(ele => ele.featured = this.featuredTags.findIndex(x => x.id == ele.id) > -1);
          this.allTags = res;
          this.initTagsTable();
          this.showSpinner = false;
        }, (err) => {
          this.showSpinner = false;
          window.alert(err.error);
        }
      );
  }

  initTagsTable(){
    delete this.tagsTable;  // frees memory
    this.tagsTable = new MatTableDataSource(this.allTags);
    this.tagsTable.sort = this.tagTableSort;
    this.tagsTable.paginator = this.paginator;
    this.tagsTable.filterPredicate = this.customFilterPredicate();
  }

  deleteTag(element: FeaturedTag) {
    const deleteDialog = this.dialog.open(CombineTagDialogComponent, {
      minWidth: '400px',
      data: {
        sourceTag: element
      }
    });
    deleteDialog.afterClosed().subscribe((dialogResponse: any) => {
      if (dialogResponse) {
        // just splice
        this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2500});
        const removed_tag_id = this.allTags.indexOf(element);
        this.allTags.splice(removed_tag_id, 1);
        this.initTagsTable();

        // update global list
        const index = this.authService.tag_data.findIndex(e => e.id == element.id);
        this.authService.tag_data.splice(index, 1);
      }
      else {
        // undefined
        // do nothing
      }
    });
  }

  addTag(){
    // anyone can add a tag
    const message = this.translateService.instant("Please provide a name:");
    let name = window.prompt(message, '');
    if (name) {
      const trimmed_name = name.trim().slice(0, 49); // BE limit is 50
      this.navbarService.createTag(trimmed_name).then(res => {
        const new_tag: FeaturedTag = {
          videos: 0,
          featured: false,
          id: res.id,
          name: res.name
        }
        this.allTags.unshift(new_tag); // add to beginning
        this.initTagsTable();
      }).catch(e => {
        console.error(e);
        this.snackBar.open(this.translateService.instant('Already exists'), '', {duration: 2500});
      });
    }
  }

  applyFilter(event: Event): void {
    event.stopPropagation();
    if(event != undefined){
      const filterValue = (event instanceof Event) ? (event.target as HTMLInputElement).value : event;
      filterValue ? (this.tagsTable.filter = filterValue.trim().toLowerCase()) : this.tagsTable.filter = '';
      this.tagsTable.paginator = this.paginator;
    }
  }

  customFilterPredicate() {
    const myFilterPredicate = (data: any, filter: string): boolean => {
      let searchString = filter;
      return data.name.toString().trim().toLowerCase().indexOf(searchString.toLowerCase()) !== -1;
    }
    return myFilterPredicate;
  }

  starTags(tag: FeaturedTag){
    // if profile.is_global_manager or profile.is_company_manager or profile.is_quality_manager:
    let message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      const action = tag.featured ? 'unfeature' : 'feature'
      this.dataService.putURL(`manager/tags/${tag.id}/?action=${action}`, {}, {observe: 'body', responseType: 'json'} ).subscribe((res: any) => {
        tag.featured = !tag.featured;
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
      }, (err) => {
        window.alert(err.error);
      });
    }
  }

  // top 3 admins can rename a tag
  renameTag(tag: FeaturedTag){
    const message = this.translateService.instant('Please provide a new name:');
    let name = window.prompt(message, tag.name);
    if(name){
      // not undefined
      name = name.trim().slice(0, 49);
      if (name) {
        // not empty, send call, it will be trimmed and shortened to 50 characters in the BE
        this.dataService.putURL(`manager/tags/${tag.id}/?action=rename`, {name: name}, {
          observe: 'body',
          responseType: 'json'
        }).subscribe((res: any) => {
          tag.name = name;
          // update authservice
          const global_tag = this.authService.tag_data.find(e => e.id = tag.id);
          global_tag.name = name;
          this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
        }, (err) => {
          window.alert(err.error);
        });
      }
    }
  }

  stopToggle(event){
    event.stopPropagation()
  }

  scrollTo(section) {
    let element = document.querySelector('#' + section);
    element.scrollIntoView({block: "start", behavior: "smooth"});
  }

  initiateObserver() {
    this.section_links = [];

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('id');
        let this_section = this.section_links.find(element => element.id == id);
        if (entry.intersectionRatio > 0) {
          // make the variable true
          this_section.active = true;
        } else {
          // make that section inactive
          this_section.active = false;
        }
      })
    })

    // find all sections with an id and observer them
    document.querySelectorAll('section[id]').forEach((section) => {
      // push it if id starts with section
      if (section.id.startsWith('section')) {
        this.section_links.push({
          name: section.getElementsByTagName('h2')[0].innerHTML,
          active: false,
          id: section.id,
          icon: section.getAttribute('data-attr')
        });
        this.observer.observe(section);
      }
    })

    // add an event listener to make sure that observer is released
    window.addEventListener('beforeunload', (event) => {
      this.observer.disconnect();
      delete this.observer;
    });
  }

  viewTag(element: FeaturedTag){
    if(element.videos){
     this.router.navigate(['tag', element.id]);
    }
  }

  redirectToBilling() {
    this.dataService.getURL('manager/stripe-customer-portal/', {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res) => {
      if (res['response'] == 'success') {
        let url = res['url'];
        if (inIframe()) {
          // if we are in iframe, then open a new tab
          // this is needed for cases like trello, where 3rd party urls are not allowed
          window.open(url, '_blank');
        } else {
          window.location.href = url;
        }
      } else {
        window.alert(res['reason']);
      }
    });
  }

  buyCredits() {
    if (!this.authService.company.stripe_customer_id) {
      // an enterprise user does not have a customer id
      this.openFeedbackDialog();
    } else if (!this.authService.userDetails.is_global_manager) {
      // a company manager can access this page but not buy credits
      let message = this.translateService.instant("Please contact your Clypp administrator");
      window.alert(message);
    } else {
      // only accessible by global manager
      let message = this.translateService.instant('Each package costs 5€ and tops up your account with 5000 credits.');
      message += '\n' + this.translateService.instant('An invoice will be sent to your registered email address with the payment link.');
      message += '\n\n' + this.translateService.instant('Please enter the quantity of packages') + ':';
      let error_message = this.translateService.instant('Please try again');
      let input = window.prompt(message, '1');
      try {
        let quantity = parseInt(input);
        if (quantity > 0 && quantity <= 100) {

          const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
            this.dialog.open(ProgressSpinnerDialogComponent, {
              panelClass: 'transparent',
              disableClose: true,
            });

          this.dataService.postURL('manager/stripe-customer-portal/',
            {
              quantity: quantity
            },
            {
              observe: 'body',
              responseType: 'json',
              headers: {'Accept-Language': this.translateService.currentLang}
            }).subscribe((res) => {
            // success or failure, we show the reason
            dialogRef.close();
            window.alert(res['reason']);
          }, () => {
            // failed to send invoice
            dialogRef.close();
            window.alert(this.translateService.instant('Ein Fehler ist aufgetreten'));
          });
        } else {
          // invalid quantity
          window.alert(error_message);
        }
      } catch (e) {
        // non integer input
        window.alert(error_message);
      }
    }
  }

  openFeedbackDialog(){
    this.dialog.open(FeedbackDialogComponent, {
      width: "40%",
      minWidth: "400px",
      autoFocus: false,
      disableClose: false,
      hasBackdrop: true
    });
  }

}
