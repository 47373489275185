import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DataService} from '../services/data.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {LoginService} from '../login/login.service';

@Component({
  selector: 'app-company-registration',
  templateUrl: './company-registration.component.html',
  styleUrls: ['./company-registration.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CompanyRegistrationComponent implements OnInit {
  inProgress = false;
  companyregForm = this.formBuilder.group({
    email: ['', Validators.required],
    company_name: ['', Validators.required],
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    otp: ['', Validators.required],
    password: ['', [Validators.required,
      Validators.minLength(10),
      Validators.maxLength(30),
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).*$/)]],
    is_privacy_accepted: [false, Validators.requiredTrue],
  });
  isPrivacy: boolean = false;
  resendOTP: boolean = false;
  hide = true;
  otpclick: boolean = false;


  constructor(private snackBar: MatSnackBar,
              private loginService: LoginService,
              private router: Router,
              private dataService: DataService,
              public formBuilder: UntypedFormBuilder,
              public sanitizer: DomSanitizer,
              private translateService: TranslateService) {

    const navigation = this.router.getCurrentNavigation();
    let stateValue = navigation.extras.state as {
      emailvalue: string
    };
    if (stateValue) {
      this.companyregForm.get('is_privacy_accepted').setValue(stateValue['privacy']);
      this.isPrivacy = stateValue['privacy'];
      this.companyregForm.get('email').setValue(stateValue['email']);
    }
  }

  ngOnInit(): void {
  }

  sendotp() {
    let otp = {
      is_privacy_accepted: this.companyregForm.get('is_privacy_accepted').value,
      email: this.companyregForm.get('email').value,
    };

    if (this.companyregForm.get('is_privacy_accepted').invalid || this.companyregForm.get('email').invalid) {
      this.companyregForm.get('email').markAsTouched();
    } else {
      this.otpclick = true;
      var otp_button = document.getElementById('sendOtp');
      otp_button.setAttribute('disabled', 'true');
      this.dataService
        .postURL<string>(`company/request-otp/`, otp, {
          observe: 'body', responseType: 'json', headers: {
            'Accept-Language': this.translateService.currentLang
          }})
        .subscribe((resp: any) => {
          otp_button.removeAttribute('disabled');
          if (resp['response'] == 'failure') {
            this.resendOTP = false;
            if (resp['reason'] == 'company already exists') {
              // this.snackBar.open(this.translateService.instant('Firma existiert bereits'), '', { duration: 2500 });
              window.alert(this.translateService.instant('Firma existiert bereits'));
              if (window.confirm(this.translateService.instant("Would you like to sign up in that company?"))) {
                this.router.navigate(['signUp']);
              }
            } else if (resp['reason'] == 'privacy not accepted') {
              this.snackBar.open(this.translateService.instant('Please accept the privacy policy'), '', {duration: 2500});
            } else if (resp['reason'] == 'not personal email') {
              window.alert(this.translateService.instant("Please use the business email address or contact support@clypp.app"));
            } else {
              this.snackBar.open(resp['reason'], '', {duration: 2500});
            }
          } else {
            this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
            this.resendOTP = true;
          }
        }, (error) => {
          this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
          console.error(error);
          otp_button.removeAttribute('disabled');
        });
    }
  }

  callbackSnackBar = () => {
    this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500})
  }

  updateNewCompany() {
    this.inProgress = true;
    // update header if in German
    this.dataService
      .postURL<string>(`company/sign-up/`, this.companyregForm.value,
        {
          observe: 'body',
          responseType: 'json',
          headers: {
            'Accept-Language': this.translateService.currentLang
          }
        }).subscribe((resp: any) => {
      if (resp['response'] == 'failure') {
        if (resp['reason'] == 'incorrect pin') {
          this.companyregForm.get('otp').setValue('');
          this.companyregForm.get('otp').markAsTouched();
          this.snackBar.open(this.translateService.instant('Falscher OTP-Pin'), '', {duration: 2500});
        } else if (resp['reason'] == 'company already exists') {
          this.snackBar.open(this.translateService.instant('Firma existiert bereits'), '', {duration: 2500});
        } else if (resp['reason'] == 'captcha failure') {
          this.snackBar.open(this.translateService.instant('reCaptcha failed. Please refresh the window.'), '', {duration: 2500});
        } else if (resp['reason'] == 'privacy not accepted') {
          this.snackBar.open(this.translateService.instant('Please accept the privacy policy'), '', {duration: 2500});
        } else {
          this.snackBar.open(resp['reason'], '', {duration: 2500});
        }
      } else {
        this.loginService.login('user', this.companyregForm.get('email').value,
          this.companyregForm.get('password').value,
          this.callbackSnackBar
        );
      }
      this.inProgress = false;
    });
  }
}
