import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {MatDialogRef} from "@angular/material/dialog";
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from "../../services/auth.service";
import {ChecklistItem} from "../../models/video/video.interface";
import {RequestOverview} from "../../models/videoRequest";
import {HttpErrorResponse} from "@angular/common/http";


@Component({
  selector: 'app-video-request-dialog',
  templateUrl: './video-request-dialog.component.html',
  styleUrls: ['./video-request-dialog.component.scss']
})
export class VideoRequestDialogComponent implements OnInit {
  all_items: ChecklistItem[] = [];
  title: string = "";
  desc: string = "";
  checklist_form_control = new FormControl();
  currently_selected_item: ChecklistItem = null;
  filteredOptions: Observable<ChecklistItem[]>;

  constructor(private dataService: DataService,
              private snackBar: MatSnackBar,
              private dialogRef: MatDialogRef<VideoRequestDialogComponent>,
              private translateService: TranslateService,
              private authService: AuthService,) {
    this.all_items = this.authService.checklist_data.filter(e => ['dept', 'team', 'user'].includes(e.type));
    this.filteredOptions = this.checklist_form_control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );
  }

  ngOnInit(): void {
    this.dialogRef.backdropClick().subscribe(() => {
      if (this.title || this.desc || this.currently_selected_item) {
        const message = this.translateService.instant("You have unsaved changes. Do you want to delete the request?");
        if (window.confirm(message)) {
          this.dialogRef.close(false);
        } else {
          this.dialogRef.disableClose = true;
        }
      } else {
        this.dialogRef.close(false);
      }
    });
  }

  onItemSelect(item: ChecklistItem) {
    // console.log(this.checklist_form_control);
    this.currently_selected_item = item;
  }

  sendVideoRequest() {
    const body = {
      title: this.title,
      desc: this.desc,
      scope: this.currently_selected_item.type,
      id: this.currently_selected_item.id
    };
    this.currently_selected_item = null; // this will disable the button
    this.dataService.postURL(`user/requests/`, body, {observe: 'body', responseType: 'json'})
      .subscribe((resp: RequestOverview) => {
        this.snackBar.open(this.translateService.instant('Deine Anfrage wurde gesendet'), "", {duration: 2500});
        this.dialogRef.close(true);
      }, (error: HttpErrorResponse) => {
        console.error(error);
        this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
      });
  }

  private _filter(value: string): ChecklistItem[] {
    const filterValue = value.toLowerCase().trim();
    return this.all_items.filter(option =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  protected readonly parseInt = parseInt;
}
