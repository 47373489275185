import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '../services/data.service';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {NavbarService} from '../services/navbar.service';

@Component({
  selector: 'app-my-activities',
  templateUrl: './my-activities.component.html',
  styleUrls: ['./my-activities.component.scss']
})
export class MyActivitiesComponent implements OnInit {
  inProgress = false;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['activity_code',"supporting_text","created_on"];//,'department.name','videos','delete'];
  activityCurrentPage: number = 100;
  pageSize: number = 10;
  pageSizeOptions: number[] = [this.pageSize];
  constructor(public dataService: DataService, private navbarService: NavbarService, public authService: AuthService, private translateService: TranslateService, public dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.navbarService.getUserProfileDetails();
    this.loadActivities(1)
  }
  loadActivities(page) {
    this.inProgress = true;
    this.dataService.getURL(`user/activities/?page=${page}`).subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res.data);
      this.pageSize = res.pageSize;
      this.activityCurrentPage = res.length;
      this.inProgress = false;
    },
    (err:any)=> {
      this.handleErrors(err);
      this.inProgress = false;
    });
  }

  updateActivites(event) {
    this.loadActivities(event.pageIndex + 1);
  }

  deleteActivity() {
    let message = this.translateService.instant("Your entire activity history will be cleared.");
    message += '\n\n';
    message += this.translateService.instant("Would you like to continue?");
    if(window.confirm(message)){
      this.dataService.deleteURL(`user/activities/`).subscribe((res) => {
          if (res == null) {
            this.snackBar.open(this.translateService.instant('Erfolgreich'), '', { duration: 2500 });
            this.ngOnInit();
          }
          else {
            this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', { duration: 2500 })
            console.error(res);
          }
        }, (err) => {
        this.handleErrors(err);
      });
    }
  }

  handleErrors(err) {
    if (err.error?.non_field_errors) {
      alert(err.error.non_field_errors[0])
    } else {
      alert(err.message)
    }
  }
}
