import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorIntlService extends MatPaginatorIntl {
  translate: TranslateService;

  getRangeLabel = function (page, pageSize, length) {
    // const of = this.translate ? this.translate.instant('paginator.of') : 'of';
   this.translate.instant('Objekte pro Seite:');
    if (length == 0) {
            return ``;
          } else if (length <= (pageSize * (page + 1))) {
            return `${(page * pageSize) + 1} ${this.translate.instant('bis')} ${length}`;
          } else {
            return `${(page * pageSize) + 1} ${this.translate.instant('bis')}  ${pageSize * (page + 1)} ${this.translate.instant('von')}  ${length}`;
          }

        };


  injectTranslateService(translate: TranslateService) {
    this.translate = translate;

    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }
  translateLabels() {
    const customPaginatorIntl = new MatPaginatorIntl();
    customPaginatorIntl.itemsPerPageLabel = this.translate.instant('Objekte pro Seite:');
    customPaginatorIntl.firstPageLabel = this.translate.instant('Zuerst');
    customPaginatorIntl.lastPageLabel = this.translate.instant('Zuletzt');
    customPaginatorIntl.nextPageLabel = this.translate.instant('Nächster');
    customPaginatorIntl.previousPageLabel = this.translate.instant('Vorherige');
    this.changes.next();
  }
}

