import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DataService} from "../../services/data.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../services/auth.service";
import {SectionTranslation, TopicTranslation} from "../../models/topic.interface";
import {TopicView} from "../../models/video/video.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {MatAccordion} from "@angular/material/expansion";
import {QuillModules} from "ngx-quill/lib/quill-editor.interfaces";
import {ContentChange} from "ngx-quill";

export interface TopicTranslationsPopupData {
  readonly id: string;
  language: string;
  readonly title: string;
  readonly emoji: string;
  readonly section_ids: number[];
  readonly last_edited_on: string;
  translations: TopicTranslation[];
}

// this interface manages if a translation section is edited or not and maintains the order of display
interface EditableSectionTranslation extends SectionTranslation {
  changed: boolean;
  // this will only become true when user types anything in the quill editor
  // this will only become false when the save call is success
}

@Component({
  selector: 'app-topic-translation-popup',
  templateUrl: './topic-translation-popup.component.html',
  styleUrls: ['./topic-translation-popup.component.scss']
})
export class TopicTranslationPopupComponent implements OnInit {

  language_map: [string, string][] = [];
  new_language_value: string = null;
  is_ai_service_disabled: boolean = false;  // disable button
  currently_opened_sections: EditableSectionTranslation[] = [];
  showEmojiPicker: boolean = false;
  showSpinner: boolean = false;
  primary_color: string = "black";
  quillConfig: QuillModules = {};

  @ViewChild(MatAccordion) accordion: MatAccordion;

  readonly options = {observe: 'body', responseType: 'json'};

  constructor(@Inject(MAT_DIALOG_DATA) public data: TopicTranslationsPopupData,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<TopicTranslationPopupComponent>,
              private dataService: DataService,
              private snackBar: MatSnackBar,
              public authService: AuthService,
              private translateService: TranslateService) {
    // in the data, we need translations, topic id, authService languages, topic's original language
    // we need to ensure in auth services that AI services are enabled
    for (let i of this.authService.languages.entries()) {
      this.language_map.push(i);
    }
    // this will disable some buttons like auto translate
    this.is_ai_service_disabled = !this.authService.company.is_transcription_service_enabled;
    // for quill border
    this.primary_color = localStorage.getItem('primary_color');
    // keeping it light
    this.quillConfig = {
      toolbar: [['bold', 'italic', 'link', 'clean']],
    };
  }

  ngOnInit(): void {
    // to keep track of language changed
    this.dialogRef.backdropClick().subscribe(() => {
      this.closeDialog();
    });
  }

  // this method translates existing translations: their title and sections
  updateAllTranslations(): void {
    const message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      for (let t of this.data.translations) {
        this.autoTranslateEachSection(t.id, false);
      }
    }
  }

  saveTopicLanguage(event) {
    const language = event.value;
    // this is only used to save topic language
    // 1. check if the new language is in translation
    const index = this.data.translations.findIndex(e => e.language == language);
    if (index > -1) {
      this.snackBar.open(this.translateService.instant('Already exists'), '', {duration: 2500});
      // update mat select
      event.source.value = this.data.language;
      // todo: open that accordion
      return;
    }

    this.showSpinner = true;
    // language does not exist in translations, save form and upon success, update data as well
    this.dataService.putURL(`user/topics/${this.data.id}/`, {language: language},
      this.options).subscribe((res: TopicView) => {
      this.data.language = language;
      this.showSpinner = false;
      this.snackBar.open(this.translateService.instant('Gespeichert'), '', {duration: 2000});
    }, (err: HttpErrorResponse) => {
      this.handleErr(err);
      // revert changes
      event.source.value = this.data.language;
    });
  }

  addLanguage() {
    // check for existing data
    const index = this.data.translations.findIndex(e => e.language == this.new_language_value);
    if (this.data.language == this.new_language_value || index > -1) {
      this.snackBar.open(this.translateService.instant('Already exists'), '', {duration: 2500});
      this.new_language_value = null;
      return;
    }
    // add the language first
    this.showSpinner = true;
    this.dataService.postURL('user/topic-translations/', {
      topic_id: this.data.id,
      language: this.new_language_value
    }, this.options).subscribe((res: TopicTranslation) => {
      this.new_language_value = null;
      this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2000});
      this.data.translations.push(res);
      // now translate each section of this, only if AI services are enabled
      if (!this.is_ai_service_disabled) {
        this.autoTranslateEachSection(res.id);
      } else {
        this.showSpinner = false;
      }
    }, (err: HttpErrorResponse) => {
      this.handleErr(err);
      this.new_language_value = null;
    });
  }

  // this method loads each section of a translation and translates them
  autoTranslateEachSection(id: number, confirm: boolean=false) {
    if (confirm) {
      // only come here when button is clicked
      const message = this.translateService.instant("Bist du sicher?");
      confirm = window.confirm(message);
    } else {
      // only come here by an internal call like creating a new translation
      confirm = true;
    }
    if (confirm) {
      this.autoTranslateTitle(id);
      this.showSpinner = true;
      // first, load all sections
      this.dataService.getURL(`user/topic-translations/?translation_id=${id}`, this.options).subscribe(
        (res: SectionTranslation[]) => {
          // for each res, translate it
          for (let r of res) {
            this.autoTranslateOneSection(r, false);
          }
          this.showSpinner = false;
        }, (err: HttpErrorResponse) => {
          this.handleErr(err);
        });
    }
  }

  // sends put call to auto translate title
  autoTranslateTitle(id: number) {
    this.showSpinner = true;
    this.dataService.putURL(`user/topic-translations/?translation_id=${id}`, {}, this.options)
      .subscribe((res: TopicTranslation) => {
        // find and update translation object
        this.showSpinner = false;
        const temp_translation_reference = this.data.translations.find(e => e.id == res.id);
        if (temp_translation_reference) {
          // found, not undefined
          temp_translation_reference.title = res.title;
          temp_translation_reference.last_edited_on = res.last_edited_on;
        }
      }, (err: HttpErrorResponse) => {
        this.handleErr(err);
      });
  }

  handleErr(err: HttpErrorResponse) {
    console.error(err);
    this.showSpinner = false;
    this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
  }

  // this method auto translates a section
  autoTranslateOneSection(section: SectionTranslation, show_snackbar: boolean = true) {
    this.showSpinner = true;
    this.dataService.putURL(`user/topic-translations/?section_translation_id=${section.id}`, {},
      this.options).subscribe((res: SectionTranslation) => {
      section = res;
      this.showSpinner = false;
      // if this section is in currently opened ones, then update it!
      const temp_section_reference = this.currently_opened_sections.find(e => e.id == res.id);
      if (temp_section_reference) {
        // found, not undefined
        temp_section_reference.content = res.content;
        temp_section_reference.changed = false;
      }
      if (show_snackbar) {
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2000});
      }
    }, (err: HttpErrorResponse) => {
      this.handleErr(err);
    });
  }

  // user can delete a translation
  deleteTranslation(id: number) {
    const message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      this.showSpinner = true;
      this.dataService.deleteURL(`user/topic-translations/?translation_id=${id}`).subscribe(data => {
        // ok
        const index = this.data.translations.findIndex(e => e.id == id);
        if (index >= 0) {
          this.data.translations.splice(index, 1);
        }
        this.showSpinner = false;
        this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2000});
      }, (err: HttpErrorResponse) => {
        this.handleErr(err);
      });
    }
  }

  // load the current translation
  async loadTranslation(id: number) {
    await this.savePendingSections();  // save sections first, then do anything
    this.showSpinner = true;
    this.dataService.getURL(`user/topic-translations/?translation_id=${id}`, this.options).subscribe(
      (res: SectionTranslation[]) => {
        // push data in correct order
        for (let sec_id of this.data.section_ids) {
          const translated_section = res.find(e => e.section == sec_id);
          // this must exist
          this.currently_opened_sections.push({
            ...translated_section,
            changed: false
          });
        }
        this.showSpinner = false;
      }, (err: HttpErrorResponse) => {
        this.handleErr(err);
      }
    );
  }

  // this method manually updates a section
  async saveOneSection(section: EditableSectionTranslation) {
    this.showSpinner = true;
    const encoded_content = encodeURIComponent(section.content);  // BE will now decode content before saving
    const local_subscription = this.dataService.patchURL<SectionTranslation>(`user/topic-translations/?section_translation_id=${section.id}`,
      {content: encoded_content}, this.options);
    const res = await local_subscription.toPromise();
    section.content = res.content;
    section.changed = false;
    this.showSpinner = false;
    this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2000});
    document.getElementById(`save-section-button-${section.id}`).hidden = true;
  }

  // save a translation's title and emoji
  saveOneTranslation(translation: TopicTranslation) {
    this.showSpinner = true;
    this.dataService.patchURL(`user/topic-translations/?translation_id=${translation.id}`,
      {title: translation.title, emoji: translation.emoji},
      this.options).subscribe((res: TopicTranslation) => {
      translation = res;
      this.showSpinner = false;
      this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2000});
    }, (err: HttpErrorResponse) => {
      this.handleErr(err);
    });
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(translation: TopicTranslation, emoji: string) {
    if (emoji.length > 4) {
      const message = this.translateService.instant("Please keep it short");
      window.alert(message);
      return;
    }
    translation.emoji = emoji;
    this.showEmojiPicker = false;
    this.saveOneTranslation(translation);
  }

  editTitle(translation: TopicTranslation){
    const prompt_message = this.translateService.instant('Please provide a title');
    let input = window.prompt(prompt_message, translation.title);
    if (input) {
      input = input.trim();
      if (input) {
        translation.title = input.slice(0, 99);
        this.saveOneTranslation(translation);
      }
    }
  }

  closeDialog() {
    // since language is passed as value and not as reference, there is no way on the prev popup to track it.
    this.savePendingSections().then(() => {
      this.dialogRef.close(this.data.language);
    });
  }

  // this method adds/removes a border to quill editor
  showHideSectionBorder(section_id: number, focus: boolean){
    if (focus) {
      // selected
      document.getElementById(`section-div-${section_id}`).style.borderColor = this.primary_color;
    } else {
      // blur
      document.getElementById(`section-div-${section_id}`).style.borderColor = 'transparent';
    }
  }

  // this method tracks changes when quill content is changed
  contentChanged(section: EditableSectionTranslation, event: ContentChange){
    try {
      if(event.source == 'api') {
        // problem: on loading, quill editor always fire this event
        // do not show save button
      } else {
        // show save button
        section.changed = true;
      }
    } catch {
      // show save button
      section.changed = true;
    }
  }

  async savePendingSections() {
    // save existing sections and clear the array
    for (let pending_section of this.currently_opened_sections) {
      if (pending_section.changed) {
        // save it
        await this.saveOneSection(pending_section);
      }
    }
    this.currently_opened_sections = [];
    return;
  }

  async sectionClosed() {
    this.showEmojiPicker=false;
    // await this.savePendingSections();
  }
}
