<div class="d-flex justify-content-between mb-2" mat-dialog-title>
  <div style="width: 40px;">

  </div>
  <h1 class="text-nunito font-weight-bold w-100 text-center">
    {{title}}
  </h1>
  <button mat-icon-button style="margin-top: -6px;">
    <mat-icon [mat-dialog-close]="true">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="d-flex justify-content-start" id="group-members" style="flex-flow: wrap;">
  <mat-card *ngFor="let m of members" class="p-1" style="width: 120px;">
    <mat-card-header>
      <img [matTooltip]="m.user.email" [src]="m.user.userprofile.profile_pic"
           class="avatar" mat-card-avatar style="margin-left: 32px;">

      <!--if (dept-popup is open and user is a dept manager) or (team popup is open and user is a team manager)-->
      <div *ngIf="data.adminBadgeTooltip" style="position: absolute; left: 85px; color: gray;">
        <!--user is a dept or group manager-->
        <mat-icon *ngIf="m.is_department_manager"
                  matTooltip="{{data.adminBadgeTooltip}}"
                  matTooltipPosition="above"
                  class="material-icons-outlined">
          admin_panel_settings
        </mat-icon>

        <!--user is a team manager but not dept manager-->
        <!--we can not re-use tooltip here-->
        <!--todo: remove Teamverwaltung-->
        <mat-icon *ngIf="m.is_team_manager && !m.is_department_manager"
                  matTooltip="{{'Teamverwaltung'|translate}}"
                  matTooltipPosition="above"
                  class="material-icons-outlined">
          admin_panel_settings
        </mat-icon>
      </div>
    </mat-card-header>

    <mat-card-content>
      <mat-card-subtitle style="word-break: break-word; text-align: center;">
        {{m.user.first_name}} {{m.user.last_name}}
      </mat-card-subtitle>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
