import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../services/data.service';
import { NavbarService } from '../services/navbar.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Playlist } from '../models/playlist/playlist';
import {AuthService} from "../services/auth.service";
import {PlaylistVideo} from "../quickwin-view/quickwin-view.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-playlist-view',
  templateUrl: './playlist-view.component.html',
  styleUrls: ['./playlist-view.component.scss']
})
export class PlaylistViewComponent  implements OnInit {
  playlist: Playlist;
  playlist_id: any;
  playlistVideos: PlaylistVideo[] = [];
  is_quality_manager: boolean = false;  // used for manager level actions like draft, feature, unfeature

  constructor(private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              private dataService: DataService,
              private translateService: TranslateService,
              private navbarService: NavbarService,
              private router: Router,
              private authService: AuthService) {
    // a manager can modify playlist visibility
    if(this.authService.userDetails){
      // this is to prevent null values if refreshed
      this.is_quality_manager = this.authService.userDetails.is_quality_manager;
    }
   }

  ngOnInit(): void {
    this.navbarService.disableCreationMode();
    this.navbarService.getUserProfileDetails();
    this.cdr.detectChanges();
    this.route.paramMap.subscribe(map => {
      this.playlist_id = map.get('id');
      this.getPlaylist();
    });
  }

  getPlaylist(): void {
    this.dataService.getURL(`user/playlists/${this.playlist_id}/`,
      {observe: 'body', responseType: 'json'}).subscribe((res: Playlist) => {
      this.playlist = res;
      this.getPlaylistVideos();
    }, (err) => {
      window.alert(err.error);
      // go to all playlists page
      this.router.navigate(['allPlaylists']);
    });
  }

  getPlaylistVideos(): void {
    this.dataService.getURL(`user/playlists/${this.playlist_id}/videos/`,
      {observe: 'body', responseType: 'json'}).subscribe((res: PlaylistVideo[]) => {
      this.playlistVideos = res;
      if(this.playlistVideos.length == 0){
        // go back
        let message = this.translateService.instant("This playlist is empty");
        window.alert(message);
        this.backButton();
      }
    }, (err) => {
        console.error(err);
        window.alert(err.error);
    });
  }

  backButton(){
    history.back();
  }
}
