<form [formGroup]="redirectForm">
  <div>
    <div class="fontStyle" style="margin-top:0.5rem;font-size: 20px;color:black;font-weight: bold;">
      {{ 'Internal Access' | translate }}
    </div>
    <div class="w-100 fontNormal titleStyle" >
      {{title}}
    </div>
    <div class="fontNormal mt-2 fontNormal w-100">
      {{ 'Shared within the workspace with' | translate }}
    </div>
    <div style="width:100%">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label [translateParams]="{val1: this.authService.company.dept_alias, val2: this.authService.company.team_alias}" [translate]="'restrict_label'"></mat-label>
        <mat-chip-list #chipList formControlName="users" style="color:black !important">

          <input #usersInput [formControl]="usersCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes" hideRequiredMarker>
        </mat-chip-list>

        <mat-autocomplete #auto="matAutocomplete">
          <mat-option (onSelectionChange)="selectedusers(tag,false)" *ngFor="let tag of filteredUsers | async"
                      [value]="tag" style="color:black !important">
            {{tag.name}}&nbsp;<span class="fontNormal"
                                    style="margin-left:0.2rem;color:grey">{{ tag.string | translate}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div style="width: 100%;height:300px;border:1px solid black;overflow-y: auto;margin-bottom: 1rem;">
      <div *ngIf="!users_list.length" class="retriction-warning"><span *ngIf="!data.title"
                                                                       [innerHTML]="defaultMessage"></span></div>
      <mat-chip
        (removed)="removeUserTag(user)"
        *ngFor="let user of users_list" [removable]="true" [selectable]="true" color="white" disableRipple
        style="color:black !important;width: 100%;margin-bottom: 0.5rem;margin-top:0.3rem;padding-top:10px;padding-bottom:5px;background-color: transparent !important;">
        <div class="fontNormal mt-1 mb-1" fxLayout="row" style="width:100%;justify-content: space-between;">
          <div class="fontNormal" style="white-space:pre-wrap;text-overflow: ellipsis"> {{user.name}}&nbsp;
            <span class="fontNormal" style="margin-left:0.2rem;color:grey">{{user.string | translate}}</span>
          </div>
          <div>
            <mat-icon (click)="callOnSelect(user,'make_admin')" *ngIf="user.is_admin != undefined && !user.is_admin"
                      matSuffix matTooltip="{{'Make group admin' | translate}}" style="color: black !important;"
            >add_moderator
            </mat-icon>
            <mat-icon (click)="callOnSelect(user,'remove_admin')" *ngIf="user.is_admin != undefined && user.is_admin"
                      matSuffix matTooltip="{{'Dismiss as admin' | translate}}" style="color: black !important;"
            >remove_moderator
            </mat-icon>
            <mat-icon [matTooltip]="closeTooltip" matChipRemove matSuffix
                      style="color: black !important;transform: scale(1.4);">clear
            </mat-icon>
          </div>
        </div>
      </mat-chip>
    </div>
  </div>

  <!-- <div *ngIf="!showSaveBtn">
    <button (click)="submitRestrict()" color="primary" mat-raised-button style="float:right;display:flex;justify-content:flex-end"
            type="submit" type="submit">
      <span translate>Speichern</span>
    </button>
  </div> -->

  <div style="display: flex; justify-content: space-between">
    <button (click)="clearRestrict()" cdkFocusInitial mat-stroked-button type="button">
      <span translate>Cancel</span>
    </button>
    <button (click)="submitRestrict()" mat-stroked-button type="submit">
      <span translate>Speichern</span>
    </button>

  </div>
</form>
