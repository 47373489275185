<div class="d-flex flex-flow-column h-100">

  <app-navbar *ngIf="showTopBar" style="flex: none !important"></app-navbar>

  <mat-sidenav-container (backdropClick)="backdropClick()" [hasBackdrop]="isSmallScreen ? true : isExpanded ? true : false" autosize
                         class="flex-grow-1"
                         style="overflow: auto !important; background: white">

    <mat-sidenav #sidenav="matSidenav" [@openCloseSidenav]="isExpanded ? 'open' : 'closed'"
                 [disableClose]="isSmallScreen ? false : true"
                 [style.display]="navbarService.showSideNav ? 'block' : 'none'"
                 style="overflow: hidden !important; overflow-y: auto !important;">

      <div *ngIf="!isTeamsBrowser">
        <mat-nav-list>
          <button *ngFor="let item of navList" [routerLink]="item.link" mat-list-item routerLinkActive="active">
            <mat-icon [matTooltipDisabled]="isExpanded == true" class="material-icons-outlined" mat-list-icon
                      matTooltip="{{ item.name | translate }}" matTooltipPosition="right">
              {{ item.icon }}
            </mat-icon>
            <span [@animateText]="isExpanded ? 'open' : 'closed'" class="fontNormal" style="padding-left: 8px">
              {{ item.name | translate}}
            </span>
          </button>
        </mat-nav-list>
      </div>
    </mat-sidenav>

    <mat-sidenav-content [@openCloseSidenavContent]="isExpanded ? 'open' : 'closed'"
                         [ngClass]="{'m-0': !authService.isAuthenticated() || isSmallScreen || !navbarService.showSideNav}">
      <!-- set margin to zero, if I am not authenticated or small screen or sidenav is hidden -->
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<footer *ngIf="showFooter" class="text-nunito" style="text-align: -webkit-center; text-align: -moz-center;">
  <div style="display: flex; justify-content: space-evenly; max-width: 400px; color: darkgray">
    <a class="mr-1 text-dark font-medium" href="{{ 'WEB_URL' | translate }}" target="_blank" translate>Website</a>
    <a class="mr-1 text-dark font-medium" href="{{ 'IMPRESSUM_URL' | translate }}" target="_blank" translate>Imprint</a>
    <a class="mr-1 text-dark font-medium" href="{{ 'POLICY_URL' | translate }}" target="_blank" translate>Privacy</a>
  </div>
  <p class="font-medium"> © Zesavi GmbH 2024</p>
</footer>
