<div (window:resize)="onResize($event)" class="carousel-controls">
  <div *ngIf="currentIndex > 0" [ngClass]="{
        'carouselHeight': cardType === 'clypp' || cardType === 'playlist' || cardType === 'history',
        'h-100': cardType === 'latestUser' || cardType === 'groups'
      }" class="top-left carousel-arrow">
    <mat-icon (click)="previousSlide()" class="material-icons-outlined cursor-pointer">arrow_back_ios</mat-icon>
  </div>

  <!-- playlist and clypp videos-->
  <mat-grid-list [cols]="cols" [gutterSize]="'1rem'" [rowHeight]="rowHeight">
    <div *ngIf="visibleCards.length > 0">
      <mat-grid-tile *ngFor="let card of visibleCards">
        <!-- video mat card -->
        <app-video-card *ngIf="cardType === 'clypp'" [backendUrl]="backendUrl" [videoObject]="card"
                        class="VideoCardStyle"></app-video-card>

        <!-- playlist mat card -->
        <app-video-card-playlist *ngIf="cardType === 'playlist'" [playlist_object]="card"
                                 class="VideoCardStyle"></app-video-card-playlist>

        <!-- history mat card -->
        <app-video-card-history *ngIf="cardType === 'history'" [backendUrl]="backendUrl" [videoObject]="card">
        </app-video-card-history>

        <!--latest clypp user-->
        <div *ngIf="cardType === 'latestUser'" class="align-items-center">
          <img (click)="navigateUserPage(card.id);" [src]="card.userprofile.profile_pic" alt="user image"
               aria-label="view clypps by this user" class="cursor-pointer user-avatar mb-2">
          <div class="d-flex fontStyle justify-content-center mb-1">
            <b>{{ card.first_name }}&nbsp;{{ card.last_name }}</b>
          </div>
          <div class="d-flex fontNormal fontcolor justify-content-center" translate>
            {{card.n_videos_30_days}}&nbsp;Clypps
          </div>
        </div>

        <!--groups by user -->
        <mat-card (click)="viewGroups(card)" *ngIf="cardType === 'groups'" class="group-card">
          <h2 style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{card.title}}</h2>
          <mat-card-content class="group-card-content">
            <div *ngFor="let m of card.members; let i = index;" class="member-avatar">
              <div *ngIf="card.last_edited_on && i<9;" [matTooltip]="m.first_name + ' ' + m.last_name"
                   mat-card-avatar
                   style="background-size: cover; background-image: url({{m.userprofile.profile_pic}})">
              </div>
              <div *ngIf="!card.last_edited_on && i<9;" mat-card-avatar
                   style="background-size: cover; background-image: url({{m.profile_pic}})">
              </div>
              <div *ngIf="i==9;" style="text-align-last: center;">
                <mat-icon [matTooltip]="card.members.length - 9 + ' weitere'" class="avatar"
                          style="padding-top: 10px;">
                  more_horiz
                </mat-icon>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions *ngIf="card.last_edited_on">
                        <span class="d-flex align-items-center"><mat-icon
                          matTooltip="{{'Last modified at' | translate}}">published_with_changes</mat-icon>&nbsp;
                          {{card.last_edited_on | date: 'dd.MM.yyyy HH:mm'}}</span>
          </mat-card-actions>
        </mat-card>
      </mat-grid-tile>
    </div>

    <!-- no data-->
    <div *ngIf="!cardData.length" [ngStyle]="{ 'height.px': rowHeight }"
         class="d-flex justify-content-center align-items-center">
      <span translate>No items found</span>
    </div>
  </mat-grid-list>

  <div *ngIf="currentIndex < cardData.length - cols" [ngClass]="{
        'carouselHeight': cardType === 'clypp' || cardType === 'playlist' || cardType === 'history',
        'h-100': cardType === 'latestUser' || cardType === 'groups'
      }" class="top-right carousel-arrow">
    <mat-icon (click)="nextSlide()" class="material-icons-outlined cursor-pointer">arrow_forward_ios</mat-icon>
  </div>
</div>
