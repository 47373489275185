import { Component,ElementRef,EventEmitter,AfterViewInit,Input,OnInit,Output,ViewChild,OnChanges,OnDestroy, HostListener} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { ScreencastService } from '../../services/screencast.service';
import { Observable, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoType } from '../../models/video/video-type.enum';
import { AudioRecorderService } from '../../services/audio-recorder.service';
import * as audioMeter from 'src/app/scripts/volume-meter.js';
import { NavbarService } from '../../services/navbar.service';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimerComponent } from '../../shared/timer/timer.component';
import { ControlsComponent } from '../controls/controls.component';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
@Component({
  selector: 'app-screencast-creation',
  templateUrl: './screencast-creation.component.html',
  styleUrls: ['./screencast-creation.component.scss'],
})
export class ScreencastCreationComponent implements OnInit, AfterViewInit,OnDestroy {

  @ViewChild('controls') controls: ControlsComponent;
  @ViewChild('videoTimer') videoTimer: TimerComponent;
  @ViewChild('meter') meter: ElementRef;
  @Input() vidValue : any;


  showRecordingWindow = false;

  // vidValue:any=[];
  videoTypes = VideoType;
  timeLimit:number;
  VideoTypeValue: any;
  paused = false;
  showCountdown = false;
  timeLeft: string = '3';
  screencast: MediaStream;
  selectScreen: MediaStream;
  audioinputVal: any;
  private currentWindow;
  disabledReset: boolean;
  subscription: Subscription;
  rerecord:boolean = false;
  notesValue:any;

  readonly WIDTH = 35;
  readonly HEIGHT = 2;
  private audioMeter: any;
  arecorder;
  environment = environment;
  showAudioOption: boolean = false;
  public audioIcon = 'mic';
  public noiseIcon = 'blur_on';
  noiseSuppression : boolean = true;
  noiseSuppressionTooltip: string = 'Disable noise suppression';
  showAudio: boolean = true;
  showGreenRect: boolean = false;
  micVal: boolean = true;
  showMicEditBack: boolean = false;
  micoffOnRecreate: boolean = false;

  videoRecordingFinished = false;
  alreadyEnteredTrimmingStep = false;
  formData: FormData;
  videoURL: any;
  nextLabel : string = 'Aufnahme beenden';
  defaultValue: any;
  notes = [];
  time: string;
  date: any;
  videoId: string;
  germanLang:boolean = false;
  backfromTrim:boolean = false;
  restart_Start:boolean = false;
  disableAudio:boolean = false;
  audioSelectionTooltip = 'Mikrofon stummschalten';
  reload : boolean = true;
  progress: number = 0;
  processFinish = false;
  permissionDenied: boolean;
  notescol:number = 0;
  audioHigh = new Audio();
  audioLow = new Audio();
  screencastPreview: boolean = true;
  screenValue: MediaStream;
  startrecord:boolean = false;
  is_entire_screen_shared: boolean = false;

  playDelay = async () => {
    this.showCountdown = true;
    this.timeLeft = '3';
    await delay(900);
    this.timeLeft = '2';
    await delay(900);
    this.timeLeft = '1';
    await delay(300);
    this.audioHigh.play();
    await delay(700);
    this.showCountdown = false;
  }

  constructor(
    public screencastService: ScreencastService,
    private sanitizer: DomSanitizer,
    public audioRecorderService: AudioRecorderService,
    private navbarService: NavbarService,
    private httpClient: HttpClient,
    private snackBar: MatSnackBar,
    public authService: AuthService,
    private translateService:TranslateService,
    private dataService: DataService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private router:Router
  ) {

    this.navbarService.showSideNav = false;
    window.onresize = () => {
    }
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
      Breakpoints.Handset,
      Breakpoints.HandsetPortrait,
      Breakpoints.WebLandscape,
      Breakpoints.WebPortrait
  ]).subscribe(result => {
      if (result.matches) {

        if (result.breakpoints[Breakpoints.WebLandscape]) {
          this.notescol = 10;
          }
          if (result.breakpoints[Breakpoints.XSmall]) {
            this.notescol = 6;
          }
          if (result.breakpoints[Breakpoints.Small]) {
            this.notescol = 8;
          }
          if (result.breakpoints[Breakpoints.Medium]) {
            this.notescol = 10;
          }
          if (result.breakpoints[Breakpoints.Large]) {
            this.notescol = 12;
          }
          if (result.breakpoints[Breakpoints.XLarge]) {
            this.notescol = 14;
          }
      }
  })
  }
  deleteBeforeClosing() {
    return  this.dataService.deleteVideo(this.vidValue).toPromise();
  }

  //browser back button detected
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.goBackremovePermission();
   }

  // window close delete call
  @HostListener('window:unload')
  async ngOnDestroy()
  {
    let value = window.location.href.split("/").pop();
    if(value == 'record' && (this.vidValue['video_file'] == null || this.vidValue['video_file'] == undefined)){
      let httpData = await this.deleteBeforeClosing();
    }
  }

  ngOnInit(): void {
    if(this.translateService.currentLang == 'de'){
      this.germanLang = true;
    }

    // load audio on init
    this.audioHigh.src = '../../assets/Mini_Button_01.wav';
    this.audioHigh.load();
    this.audioLow.src = '../../assets/Mini_Button_02.wav';
    this.audioLow.load();
    //audio dropdown
        let timerValue = localStorage.getItem('max_video_duration_in_seconds');
        let timeval = Number(timerValue) ;
        if(timeval == 0){
          this.timeLimit = 180;
        }else{
          this.timeLimit = timeval;
        }

        this.screencastService.getAudioVal();
        this.screencastService.audioVal.subscribe((data) => {
          this.audioinputVal = data;
          this.defaultValue = this.audioinputVal[0];
        });
        this.startAudioRecording();

    this.VideoTypeValue = 'SC';
    this.notes = [
      'Hinweis: Beginne dein Video mit dem Satz: "In diesem Video zeige ich ' +
        this.date +
        ' wie…',
        '' ,
    ];


    this.navbarService.showSideNav = false;
    this.navbarService.enableCreationMode();
    this.navbarService.hide();

    window.moveTo(0, 0);
    // window.resizeTo(screen.width/2, screen.height/1);
  }

  notesChange(event){
    this.notesValue = event[1];
  }

  ngAfterViewInit():void{
    if(this.vidValue['state'] === 'NO'){
      this.restart_Start = true;
    }

    if(this.vidValue['video_file'] != null || this.vidValue['processed_file'] != null ){
          this.nextLabel = 'Aufnahme beibehalten';
          this.controls.disabledStart = true;
          this.controls.disabledReset = false;
          this.controls.disabledResume = true;
    }else{
      this.paused = false;
      this.disabledReset = true;
      this.checkPermissions();
    }
    if(this.vidValue['integration_parameters'] !== null){
      this.toggleAudio();
      this.showAudioOption = true;
      // this.stopAudioRecording();
    }
    else{
      // translate the tooltip
      this.audioSelectionTooltip=this.translateService.instant('Mute');
    }
    this.noiseSuppressionTooltip = this.translateService.instant('Disable noise suppression');
    if(this.vidValue['script'] !== null){
      this.notes = [
        'Hinweis: Beginne dein Video mit dem Satz: "In diesem Video zeige ich ' +
          this.date +
          ' wie…',
          this.vidValue['script'] ,
      ];
    }
  }

  startCapture = async () => {
    this.startrecord = true;
    if(this.screenValue == undefined){
      this.selectScreen = await this.screencastService.onSelectScreen();
    }
    this.controls.disabledStart = true;
    this.reload = true;
    this.screencast = await this.screencastService.startCapture();
    if (this.screencast == null) {
      this.controls.disabledStart = false;
      this.startrecord = false;
    } else {
      this.screencastPreview = false;
      this.screenValue = this.screencast;
      if(this.screencast.getVideoTracks()[0].getSettings()['displaySurface']=='monitor'){
        this.is_entire_screen_shared = true;
      }
      this.showRecordingWindow = true;
      this.stopAudioRecording();
      // window.moveTo(0, 0);
      // window.resizeTo(screen.width / 6.05, screen.height);
      this.showAudio = false;
      await this.playDelay();
      this.currentWindow = window;
      await this.initRecording();
      this.disabledReset = false;
      // this.screencast.getVideoTracks()[0].addEventListener('ended', () => {
      //   window.alert("hello");
      //   this.stopCapture2();
      // });
      this.subscription = this.screencastService.stoppedCapturing.subscribe(
        () => this.stopCapture()
      );
      this.showGreenRect = true;
      this.controls.disabledStart = false;
      if (this.vidValue['video_file'] != null){
          this.nextLabel = 'Aufnahme beenden';
      }

      // demonstrates how to detect that the user has stopped
      // sharing the screen via the browser UI.
      // this is important for firefox, chrome works fine without it
      this.screencast.getVideoTracks()[0].addEventListener('ended', () => {
        // console.log("ended");
        this.stopCapture2();
      });
    }
  };


  stopCapture2 = async () => {
    this.screencastService.stopCapture();
}


  onSelectScreen = async () => {
    this.screencastPreview = true;
    if (!this.startrecord) {
      this.screencast = await this.screencastService.onSelectScreen();
      if (this.screencast == null) {
        this.screencastPreview = true;
      } else {
        this.screencastPreview = false;
        this.screenValue = this.screencast;
        if(this.screencast.getVideoTracks()[0].getSettings()['displaySurface']=='monitor'){
          this.is_entire_screen_shared = true;
        }
        this.screencast.getVideoTracks()[0].addEventListener('ended', () => {
          this.screencastPreview = true;
          this.is_entire_screen_shared = false;
          this.screenValue = undefined;
          this.screencastService.stopScreen();
        });
      }
    } else {
      this.screencastPreview = false;
      this.screenValue = this.screencast;
    }
  }

  stopCapture = async () => {
    this.audioLow.play();
    this.is_entire_screen_shared = false;
    // this.screencastService.stopCapture();
    this.subscription.unsubscribe();
    this.videoTimer.pauseTimer();
    this.showRecordingWindow = false;
    this.screencastPreview = true;
      this.formData = new FormData();
      let blob = new Blob([this.screencastService.chunks[0]]);
      this.formData.append('video_file', blob, `${new Date().getTime()}.webm`);
      this.videoURL = this.sanitizer.bypassSecurityTrustUrl(
        await this.screencastService.getFixedDurationBlobURL()
      );

     if(this.reload){
      this.startConverting();
     }
    this.currentWindow.focus();

  };
  pauseRecording = async () => {
    this.audioLow.play();
    this.getRecordingPaused();
  };
  getRecordingPaused = async () => {
    this.paused = true;
    this.controls.disabledReset = false;
    this.showCountdown = true;
    this.timeLeft = '⏸';
    this.videoTimer.pauseTimer();
    this.screencastService.pauseScreencast();
    this.controls.disabledResume = true;
    await delay(1000);
    this.controls.disabledResume = false;
  };

  resumeRecording = async () => {
    this.controls.disabledResume = true;
    this.disabledReset = true;
    this.controls.disabledReset = true;
    await this.playDelay();
    this.controls.disabledResume = false;
    this.disabledReset = false;
    this.paused = false;
    this.showCountdown = false;
    this.videoTimer.resumeTimer();
    // this.stepperFooter.disableNextButton = false;
    this.disabledReset = false;
    this.screencastService.resumeScreencast();
  };

  private async initRecording(): Promise<void> {
    this.videoRecordingFinished = false;
    await this.videoTimer.startTimer();
  }

  openAudioEnable() {
    this.showAudioOption = !this.showAudioOption;
  }

  toggleAudio() {
    if (this.audioIcon === 'mic') {
      this.micVal = false;
      this.audioIcon = 'mic_off';
      this.disableAudio = true;
      this.audioSelectionTooltip=this.translateService.instant('Unmute');
      this.screencastService.getAudioToggle(false);
      this.stopAudioRecording();
      this.snackBar.open(this.translateService.instant('Muted'), '', { duration: 1000 });
    } else {
      this.micVal = true;
      this.audioIcon = 'mic';
      this.audioSelectionTooltip=this.translateService.instant('Mute');
      this.disableAudio = false;
      this.screencastService.getAudioToggle(true);
      this.startAudioRecording();
      this.snackBar.open(this.translateService.instant('Unmuted'), '', { duration: 1000 });
    }
  }

  toggleNoise(){
    if (this.noiseIcon === 'blur_on') {
      this.noiseSuppression = false;
      this.noiseIcon = 'blur_off';
      this.audioRecorderService.getNoiseToggle(false);
      this.screencastService.getNoiseToggle(false);
      this.stopAudioRecording();
      if(!this.disableAudio){
        this.startAudioRecording();
      }
      this.noiseSuppressionTooltip = this.translateService.instant("Enable noise suppression");
      this.snackBar.open(this.translateService.instant('Noise suppression disabled'), '', { duration: 1000 });
    }else{
      this.noiseSuppression = true;
      this.noiseIcon = 'blur_on';
      this.audioRecorderService.getNoiseToggle(true);
      this.screencastService.getNoiseToggle(true);
      if(!this.disableAudio){
        this.startAudioRecording();
      }
      this.noiseSuppressionTooltip = this.translateService.instant("Disable noise suppression");
      this.snackBar.open(this.translateService.instant('Noise suppression enabled'), '', { duration: 1000 });
    }
  }

  checkPermissions(){
    navigator.mediaDevices.enumerateDevices().then(devices =>
      devices.forEach(device => {
        if (device.kind === 'audioinput') {
          if (device.label != "") {
            this.controls.disabledStart = false;
            this.permissionDenied = false;
          } else {
            navigator.mediaDevices.getUserMedia({ audio: true })
              .then( (stream) => {
                this.controls.disabledStart = false;
                this.permissionDenied = false;
              })
              .catch((err) => {
                // this.controls.disabledStart = true;
                this.permissionDenied = true;
              });

          }
        }
      }));
  }
  onAudioDropChange(event) {
    let DeviceId = event.value.deviceId;
    this.stopAudioRecording();
    this.audioRecorderService.getSelectionVal(DeviceId);
    this.screencastService.getSelectionVal(DeviceId);
    this.startAudioRecording();
  }

  async startAudioRecording(): Promise<void> {
    this.arecorder = await this.audioRecorderService.recordAudio();
    const mediaStream = this.arecorder.start();
    const audioContext = new AudioContext();
    const mediaStreamSource = audioContext.createMediaStreamSource(mediaStream);
    this.audioMeter = audioMeter.createAudioMeter(audioContext);
    mediaStreamSource.connect(this.audioMeter);
    this.onLevelChange();
  }

  async stopAudioRecording(): Promise<void> {
    if (this.arecorder) {
      await this.arecorder.stop();
      this.arecorder = null;
      this.meter.nativeElement
        .getContext('2d')
        .clearRect(0, 0, this.WIDTH, this.HEIGHT);
      this.audioMeter.shutdown();
    }
  }

  openConfirmOverride = () => {
    let message = this.translateService.instant("Attention!\nUnsaved changes will be lost.\nClear recording?");
    if(window.confirm(message)){
        this.reload = false;
        this.rerecord = true;
        this.videoURL = '';
        this.videoTimer?.resetTimer();
        this.timeLeft = '';
        if (this.videoTimer) {
          this.videoTimer.display = this.videoTimer?.transform(0);
        }
        this.backfromTrim = true;
        this.paused = false;
        this.videoRecordingFinished = false;
        this.controls.disabledReset = true;
        this.controls.disabledStart = false;
        this.disabledReset = true;
        this.showRecordingWindow = false;

         if(this.restart_Start){
          this.screencastService.stopCapture();
         }
         if(this.screencast){
          this.screencastService.stopCapture();
          this.startrecord = false;
          this.screenValue = undefined;
         }

        this.showAudio = true;
        this.showGreenRect = false;
        this.showMicEditBack = false;
        this.alreadyEnteredTrimmingStep = false;
      }
    // this.overrideDialog().afterClosed().subscribe((res) => {

    //   });
  };

  startConverting(): void {
    // window.moveTo(0, 0);
    // window.resizeTo(screen.width, screen.height);

    if (typeof this.vidValue != 'object') {
      this.vidValue = JSON.parse(this.vidValue);
    }
    if(this.notesValue != undefined){
      this.formData.append('script',this.notesValue);
    }
    if(this.backfromTrim){
      this.formData.append('edit_parameters', null);
    }

    let observable;
    observable = this.httpClient.put(
      `${environment.backendURL}/user/videos/${this.vidValue['id']}/`,
      this.formData,
      { responseType: 'text',
      reportProgress: true,
      observe: 'events' }
    );
    this.showProgressSpinnerUntilExecuted(observable);
  }

  showProgressSpinnerUntilExecuted(observable: Observable<any>): void {
    // this.stopAudioRecording();
    // const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
    //   this.dialog.open(ProgressSpinnerDialogComponent, {
    //     panelClass: 'transparent',
    //     disableClose: true,
    //   });

    const subscription = observable.subscribe((response: HttpEvent<any>) => {

      switch (response.type) {
        // case HttpEventType.Sent:
        //   break;
        case HttpEventType.ResponseHeader:
          this.processFinish = true;
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(response.loaded / response.total * 100);
          break;
        case HttpEventType.Response:
          this.processFinish = true;
          setTimeout(() => {
            // this.responseVal = JSON.parse(response);
            subscription.unsubscribe();
            this.router.navigate(['create-video/' + this.vidValue['id'] + '/edit']);
            // dialogRef.close();
          }, 50);
      }
    },
      (error) => {
        subscription.unsubscribe();
        // handle error
        this.downloadVideo();
        window.alert(this.translateService.instant('Verbindung unterbrochen'));
        this.router.navigate(['create-video/' + this.vidValue['id'] + '/review']);
        // if (window.confirm(this.translateService.instant("Would you like to download the video?"))){
        //   this.downloadVideo();
        // }
        // dialogRef.close();
      }
    );
  }


  onLevelChange = (time = 0) => {
    if (this.audioRecorderService.recording()) {
      const context = this.meter.nativeElement.getContext('2d');

      context.clearRect(0, 0, this.WIDTH, this.HEIGHT);
      if (this.audioMeter.checkClipping()) {
        context.fillStyle = 'red';
      } else {
        context.fillStyle = localStorage.getItem("primary_color");
      }
      context.fillRect(
        0,
        0,
        this.audioMeter.volume * this.WIDTH * 1.4,
        this.HEIGHT
      );
      window.requestAnimationFrame(this.onLevelChange);
    }
  }

  metaReviewButton() {
    window.moveTo(0, 0);
    window.resizeTo(screen.width, screen.height);
    this.rerecord = false;
    this.controls.disabledStart = true;
    if(this.vidValue['video_file'] === undefined || this.vidValue['video_file'] === null){
      this.stopCapture2();
    }else if( this.vidValue['video_file'] != undefined && this.backfromTrim){
      this.stopCapture2();
    }else {
      this.stopAudioRecording();
      this.router.navigate(['create-video/'+ this.vidValue['id'] +'/edit']);
   }
  }

  downloadVideo(){
    const blob = new Blob([this.screencastService.chunks[0]], {type: 'video/webm'});
    // const blob = this.formData['video_file'];
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'video.webm';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100);
  }

  goToHome(){
    // this method takes user back to homepage
    // instead of using <a href>, we are using router
    this.ngOnDestroy();
    this.router.navigate(['start']);
  }

  goBackremovePermission(){
    // console.log("val",this.vidValue['video_file'])
    // if(this.vidValue['video_file'] === undefined || this.vidValue['video_file'] === null){
    //   this.stopCapture2();
    // }else{
      this.stopAudioRecording();
   // }
  }
}
