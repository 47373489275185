import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {Subtitle, VideoView} from "../models/video/video.interface";
import {environment} from '../../environments/environment';
import {ActivatedRoute} from "@angular/router";
import {DataService} from "../services/data.service";
import {AuthService} from "../services/auth.service";
import {Meta, Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {Location} from "@angular/common";
import {MatSnackBar} from "@angular/material/snack-bar";
import {loadScript} from "../shared/share-popup/share-popup.component";
import {range} from "rxjs";


// there are 3 cases
//  1. user is logged in and viewing embed video of his own company => works
//  2. user is not logged in and viewing public embed video => works
//  3. user is logged in and viewing public embed video of another company => works
@Component({
  selector: 'app-video-embed',
  templateUrl: './video-embed.component.html',
  styleUrls: ['./video-embed.component.scss']
})
export class VideoEmbedComponent implements OnInit, OnDestroy {

  @Input('inline_video_object') inline_video_object: VideoView;
  video_id: string = null;
  video_object: VideoView = null;
  is_access_key_needed: boolean = false;
  is_login_needed: boolean = false;
  access_key: string = "";
  display_message: string = "";
  is_access_key_hidden: boolean = true;
  poster_url: string = "";
  subtitles: Subtitle[] = [];
  timeoutHandler: any = null;  // timeout handler to show still-there popup, when the url has expired

  constructor(private route: ActivatedRoute,
              private titleService: Title,
              private snackBar: MatSnackBar,
              private dataService: DataService,
              private authService: AuthService,
              private translateService: TranslateService,
              private location: Location,
              private metaService: Meta) {
    // load the video id
    this.route.paramMap.subscribe(map => {
      this.video_id = map.get('id');
    })

    this.poster_url = environment.bucketURL + this.video_id + ".jpg";

    // load the access key
    this.route.queryParams.subscribe(params => {
      this.access_key = params['access_key'] || "";  // either undefined or an empty string
    });
  }

  ngOnDestroy() {
    clearTimeout(this.timeoutHandler);
  }

  ngOnInit(): void {
    // now, this component can be re-used as embed player
    if (this.inline_video_object) {
      this.video_id = this.inline_video_object.id;  // in case of media play, this will be null
      this.poster_url = this.inline_video_object.thumbnail;
      this.video_object = this.inline_video_object;
      this.loadSubtitles();
    } else {
      // if user is authenticated, load the internal page
      if (this.authService.isAuthenticated()) {
        this.loadInternalVideo();
      } else {
        this.loadExternalVideo();
      }
    }
    // load player js script
    // https://github.com/embedly/player.js?tab=readme-ov-file#install
    loadScript("https://cdn.embed.ly/player-0.1.0.min.js");
  }

  loadInternalVideo() {
    // case 1
    this.dataService.getURL<VideoView>(`user/videos/${this.video_id}/`,
      {observe: 'body', responseType: 'json'}).subscribe((res: VideoView) => {
      this.video_object = res;
      this.loadSubtitles();
    }, (err) => {
      this.display_message = err.error;
      // case 3
      // failed to load internal video, it might be possible that video is external from another company
      this.loadExternalVideo();
    });
  }

  loadExternalVideo() {
    // case 2
    let url = `public/videos/${this.video_id}/`;
    if (this.access_key) {
      url += `?access_key=${this.access_key}`;
      this.location.replaceState(`/embed/video/${this.video_id}?access_key=${encodeURIComponent(this.access_key)}`);
    }

    this.dataService.getURL<VideoView>(url,
      {observe: 'body', responseType: 'json'}).subscribe((res: any) => {
      // data loaded successfully
      this.is_access_key_needed = false;
      this.is_login_needed = false;
      this.display_message = "";
      this.video_object = res;
      this.loadSubtitles();
    }, (err) => {
      this.display_message = err.error;
      // switch status codes
        if (err.status == 406) {
          // not public or external sharing disabled
          this.is_login_needed = true;
          if (this.authService.isAuthenticated()) {
            // user is logged in but the video belongs to another company
            this.display_message = this.translateService.instant('Please log into the correct workspace to view this video.');
          }
        } else if (err.status == 423) {
          // no key provided, dont alert
          this.is_access_key_needed = true;
        } else if (err.status == 409) {
          // wrong access key
          this.is_access_key_needed = true;
        } else if (err.status == 429) {
          // too many requests
          this.is_access_key_needed = false;
          this.display_message = err.statusText;
        } else {
          // 404, 400, 500
          // display message is already shown
        }
    });
  }

  // this method is needed to connect player-js to our html5 video player
  loadPlayerJS() {
    // http://playerjs.io/test.html
    // we add custom script here
    const body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.text = "" +
      "const player = document.querySelector('video');" +
      "player.muted = true;" +
      "const adapter = playerjs.HTML5Adapter(player);" +
      "adapter.ready();";
    body.appendChild(script);
  }

  // check if everything is ready
  // load the transcripts
  loadSubtitles() {
    // sometimes, there could be no processed file
    if (!this.video_object.processed_file) {
      this.display_message = this.translateService.instant("This Clypp is still processing");
      this.video_object = null;
      return;  // do not load the subtitles
    }

    this.updateMetaDeta();

    if (this.video_object.vtt_file) {
      this.subtitles.push({
        src: this.video_object.vtt_file,
        srclang: this.video_object.audio_language,
        label: this.authService.languages.get(this.video_object.audio_language)
      })
    }
    for (let translation of this.video_object.translations) {
      if (translation.vtt_file) {
        this.subtitles.push({
          src: translation.vtt_file,
          srclang: translation.language,
          label: this.authService.languages.get(translation.language)
        })
      }
    }

    // In any case, after the video is ready, start the timer.
    clearTimeout(this.timeoutHandler);  // clear interval, just in case if there is any pending
    this.timeoutHandler = setTimeout(() => {
      // open the still-there component
      clearTimeout(this.timeoutHandler);
      // show snackbar with action
      const message = this.translateService.instant("Are you still there?");
      const action = this.translateService.instant("Ja");
      this.snackBar.open(message, action, {horizontalPosition: 'center'}).onAction().subscribe(() => {
        location.reload();
      });
    }, environment.timeoutDuration * 1000); // *1000 into milliseconds
  }

  // for link sharing, we update meta data
  updateMetaDeta() {
    if (this.video_id == null) {
      return;
    }

    this.titleService.setTitle(`Clypp: ${this.video_object.title}`);

    this.metaService.updateTag({name: 'twitter:card', content: 'summary'});
    this.metaService.updateTag({name: 'twitter:site', content: '@clypp'});
    this.metaService.updateTag({name: 'twitter:title', content: this.video_object.title});
    this.metaService.updateTag({name: 'twitter:description', content: this.video_object.desc});
    this.metaService.updateTag({name: 'twitter:image', content: this.poster_url});

    this.metaService.updateTag({property: 'og:title', content: this.video_object.title}, "property='og:title'");
    this.metaService.updateTag({
      property: 'og:url',
      content: `${environment.baseURL}view/${this.video_id}`
    }, "property='og:url'");
    this.metaService.updateTag({
      property: 'og:description',
      content: this.video_object.desc
    }, "property='og:description'");
    this.metaService.updateTag({property: 'og:image', content: this.poster_url}, "property='og:image'");
    this.metaService.updateTag({
      property: 'og:video',
      content: this.video_object.processed_file
    }, "property='og:video'");
    this.metaService.updateTag({
      property: 'og:video:secure_url',
      content: this.video_object.processed_file
    }, "property='og:video:secure_url'");
  }

  // public videos should only register a view when played, not when loaded
  // hence, on metadata loaded, we register a view
  register_view() {
    // if video id is null, return
    if (this.video_id == null) {
      return;
    }
    // get the browser language and set that subtitle to default
    let browser_language = navigator.language;

    // create a request, but do not send it
    let xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", `${environment.backendURL}/embed/external/${this.video_id}/viewed/`, true);

    if (this.authService.isAuthenticated()) {
      // user is authenticated, use local language
      browser_language = this.authService.userDetails.email_language;
      // check if user belongs to the same company
      if (this.authService.company.id != this.video_object.company) {
        // register view in this case too
        xmlHttp.send(null);
      }
    } else {
      // register a view
      xmlHttp.send(null);
    }

    const video = document.querySelector("video");
    range(0, video.textTracks.length).forEach(i => {
      if (video.textTracks[i].language == browser_language) {
        video.textTracks[i].mode = "showing";
      } else {
        video.textTracks[i].mode = "hidden";
      }
    });
  }

  // keep track of video history if the user is logged in
  registerViewedTill() {
    if (this.video_id == null) {
      return;
    }

    if (this.authService.isAuthenticated()) {
      // no need to check for a company
      // if friend company or my own company, then request will be accepted,
      // if another company, then BE will return 404
      let player: HTMLVideoElement = document.getElementsByTagName('video')[0];
      let patch_body = {
        viewed_till: player.currentTime
      }
      this.dataService.patchURL(`user/videos/${this.video_id}/download/`, patch_body).subscribe();
    }
  }

  openLogin() {
    const redirect_url: string = `/view/${this.video_id}`;
    document.requestStorageAccess().then(() => {
      // granted, open new tab (not opening new window as popups are blocked by default)
      const new_window = window.open(redirect_url, '_blank');
      // check after every one second if user has closed the window
      const timer = setInterval(function () {
        if (new_window.closed) {
          clearInterval(timer);
          location.reload();
        }
      }, 1000);
      // update the message, as user may not close the new window
      this.display_message = this.translateService.instant('Please refresh the page after login.');
    }, () => {
      // denied
      this.display_message = this.translateService.instant('Please refresh the page after login.');
      // open login page anyway
      window.open(redirect_url, '_blank');
    });
  }

  protected readonly self = self;
}
