<div class="d-flex justify-content-center">
  <mat-card class="card-mv-style-s1 cursor-pointer"
            style="width: 320px; height: 300px;margin-top: 15px;">
    <a (click)="videoSelect(playlist_object.id)" class="a-text-simple">
      <div class="image-container">
        <div class="position-absolute border-radius-10 pl-tilted-background">
        </div>
        <img [src]="playlist_object.thumbnail"
             alt="playlist"
             class="div-card-thumbnail"
             mat-card-image
             onerror="this.onerror=null; this.src='assets/images/no_videos.png'"
        />
        <div [style.background-image]="'url(' + playlist_object.uploader.userprofile.profile_pic + ')'"
             [title]="playlist_object.uploader.email"
             class="playlist-avatar"
             mat-card-avatar
             matTooltip="{{ playlist_object.uploader.first_name }}&nbsp;{{ playlist_object.uploader.last_name }}"
             matTooltipPosition="right"
             style="background-size: cover;">
        </div>
        <div
          (click)="videoSelect(playlist_object.id)"
          class="after video-counter"
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          "
        >
          <div style="font-size: 35px; font-weight: bolder; color: white">
            {{ playlist_object.video_count }}
          </div>
          <div>
            <mat-icon
              style="
                font-size: 50px;
                width: 50px;
                height: 50px;
                color: white;
                margin-top: 1.5rem;
                margin-left: 0.5rem;
              "
            >
              playlist_play
            </mat-icon>
          </div>
        </div>
      </div>
      <mat-card-footer>
        <mat-card-subtitle [title]="playlist_object.title" class="title fontStyle">
          <mat-icon *ngIf="playlist_object.is_featured" class="material-icons-outlined"
                    style="color: gold; vertical-align: middle;">star
          </mat-icon>
          {{ playlist_object.title}}
        </mat-card-subtitle>
        <mat-card-subtitle class="text-nunito"
                           style="font-size: 16px;"
                           title="{{'Erstellt am'|translate}} {{playlist_object.added_on | date: 'dd.MM.yyyy'}}">
          {{ 'Updated' | translate }}
          {{ utilityService.timeSince(playlist_object.last_edited_on, "ago", false) |async }}
        </mat-card-subtitle>
      </mat-card-footer>
    </a>
  </mat-card>
</div>
