import {DataService} from 'src/app/services/data.service';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef,} from '@angular/material/dialog';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {UserContentDialogComponent} from "../shared/user-content-dialog/user-content-dialog.component";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './manage-user-dialog.component.html',
  styleUrls: ['./manage-user-dialog.component.scss'],
})
export class ManageUserDialogComponent implements OnInit {

  deletion_message: string = "";
  confirmation_message: string = "";

  first_name: string = "";
  last_name: string = "";

  in_progress: boolean = false;

  constructor(
    private dataService: DataService,
    public dialogRef: MatDialogRef<ManageUserDialogComponent>,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.first_name = this.data['first_name'];
    this.last_name = this.data['last_name'];

    // if the values are empty, then write "User"
    if (this.first_name == '') {
      this.first_name = 'User';
      this.last_name = this.data['id'];
    }
  }

  ngOnInit(): void {
    this.translateService.get('_USER_DELETION_MESSAGE', {
      first_name: this.first_name,
      last_name: this.last_name
    }).subscribe((res: string) => {
      this.deletion_message = res;
    });

    this.translateService.get('_USER_CONFIRMATION_MESSAGE', {
      first_name: this.first_name,
      last_name: this.last_name
    }).subscribe((res: string) => {
      this.confirmation_message = res;
    });
  }

  callDeleteAPI() {
    if (window.confirm(this.confirmation_message)) {
      this.in_progress = true;
      const action = 'delete';
      const profiles = [this.data.id];
      this.dataService.putURL<any>(`manager/users/`, {profiles, action},
        {observe: 'body', responseType: 'text'})
        .subscribe(
          (res) => {
            this.snackBar.open(res, '', {duration: 2500});
            this.dialogRef.close(true);
          },
          (err: HttpErrorResponse) => {
            window.alert(err.error);
            console.error(err);
          });
    }
  }

  manageContent() {
    this.dialog.open(UserContentDialogComponent, {
      data: this.data
    });
    // no need to handle close case
  }
}
