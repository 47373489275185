<div *ngIf="display_message"
     style="flex-flow: wrap;"
     class="w-100 h-100 d-flex align-content-center">

  <mat-card-title style="line-height: initial;" *ngIf="display_message" class="text-center w-100">
    {{display_message}}
  </mat-card-title>

  <!--  this button is visible when user is not logged in, display message is always there -->
  <div class="w-100 text-center mt-1" *ngIf="is_login_needed">
    <button mat-stroked-button (click)="openLogin();">
      {{'Anmelden'|translate}}
    </button>
  </div>

  <!--  this input + button is visible when video needs an access key, display message is always there -->
  <div *ngIf="is_access_key_needed" class="w-100 text-center">
    <mat-form-field appearance="outline">
      <mat-label translate>Zugangscode eingeben</mat-label>
      <input matInput
             autofocus
             onfocus="this.removeAttribute('readonly');"
             (keydown.enter)="loadExternalVideo();"
             [(ngModel)]="access_key"
             [type]="is_access_key_hidden ? 'password' : 'text'">
      <button mat-icon-button matSuffix (click)="is_access_key_hidden = !is_access_key_hidden">
        <mat-icon>{{is_access_key_hidden ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <br>
    <button mat-stroked-button (click)="loadExternalVideo()">
      {{'Check'|translate}}
    </button>
  </div>
</div>

<div *ngIf="video_object"
     class="h-100 w-100 overflow-hidden d-flex justify-content-center align-items-center">

  <!--Vidstack player-->
  <!-- https://github.com/vidstack/examples/blob/main/player/web-components/default-theme/video-player.html -->

  <media-player #videoPlayer
                class="h-100 w-100 overflow-hidden"
                [src]="video_object.processed_file"
                [title]="video_object.title"
                (load-start)="loadPlayerJS();"
                (ended)="registerViewedTill();"
                (started)="register_view();"
                crossorigin="anonymous"
                muted
                disableRemotePlayback
                oncontextmenu="return false;"
                playsinline
                preload="none">
    <!-- preload none is not respected by this player -->
    <media-provider>
      <!--poster-->
      <media-poster [alt]="video_object.title"
                    [src]="video_object.thumbnail"
                    class="vds-poster">
      </media-poster>

      <!--captions-->
      <ng-container *ngFor="let subtitle of subtitles">
        <track [label]="subtitle.label"
               [src]="subtitle.src"
               [srclang]="subtitle.srclang"
               kind="subtitles"
               default
        />
      </ng-container>

      <!--chapter-->
      <track *ngIf="video_object.chapter_file"
             [src]="video_object.chapter_file"
             default
             kind="chapters"
             label="chapter"
             [srclang]="video_object.audio_language"/>
    </media-provider>

    <!-- Gestures -->
    <media-gesture action="toggle:paused" event="pointerup"></media-gesture>
    <media-gesture action="toggle:fullscreen" event="dblpointerup"></media-gesture>
    <media-gesture action="toggle:controls" event="pointerup"></media-gesture>
    <media-gesture action="seek:-10" event="dblpointerup"></media-gesture>
    <media-gesture action="seek:10" event="dblpointerup"></media-gesture>

    <!-- Captions -->
    <media-captions class="vds-captions"></media-captions>

    <!-- Controls -->
    <media-controls class="vds-controls">
      <div class="vds-controls-spacer"></div>
      <!-- Time Slider Controls Group -->
      <media-controls-group class="vds-controls-group">
        <!-- Time Slider -->
        <media-time-slider class="vds-time-slider vds-slider">
          <media-slider-chapters class="vds-slider-chapters">
            <template>
              <div class="vds-slider-chapter">
                <div class="vds-slider-track"></div>
                <div class="vds-slider-track-fill vds-slider-track"></div>
                <div class="vds-slider-progress vds-slider-track"></div>
              </div>
            </template>
          </media-slider-chapters>

          <div class="vds-slider-thumb"></div>

          <!-- Slider Preview -->
          <media-slider-preview class="vds-slider-preview bg-dark">
            <div class="vds-slider-chapter-title" data-part="chapter-title"></div>
            <media-slider-value class="vds-slider-value"></media-slider-value>
          </media-slider-preview>
        </media-time-slider>
      </media-controls-group>

      <!-- Lower Controls Group -->
      <media-controls-group class="vds-controls-group">
        <!-- Play Button -->
        <media-tooltip>
          <media-tooltip-trigger>
            <media-play-button class="vds-button">
              <media-icon class="vds-play-icon" type="play"></media-icon>
              <media-icon class="vds-pause-icon" type="pause"></media-icon>
              <media-icon class="vds-replay-icon" type="replay"></media-icon>
            </media-play-button>
          </media-tooltip-trigger>
          <media-tooltip-content class="vds-tooltip-content" placement="top">
            <span class="vds-play-tooltip-text">Play</span>
            <span class="vds-pause-tooltip-text">Pause</span>
          </media-tooltip-content>
        </media-tooltip>

        <!-- Mute Button -->
        <media-tooltip>
          <media-tooltip-trigger>
            <media-mute-button class="vds-button">
              <media-icon class="vds-mute-icon" type="mute"></media-icon>
              <media-icon class="vds-volume-low-icon" type="volume-low"></media-icon>
              <media-icon class="vds-volume-high-icon" type="volume-high"></media-icon>
            </media-mute-button>
          </media-tooltip-trigger>
          <media-tooltip-content class="vds-tooltip-content" placement="top">
            <span class="vds-mute-tooltip-text">Unmute</span>
            <span class="vds-unmute-tooltip-text">Mute</span>
          </media-tooltip-content>
        </media-tooltip>

        <!-- Volume Slider -->
        <media-volume-slider class="vds-slider">
          <div class="vds-slider-track"></div>
          <div class="vds-slider-track-fill vds-slider-track"></div>
          <media-slider-preview class="vds-slider-preview" no-clamp>
            <media-slider-value class="vds-slider-value"></media-slider-value>
          </media-slider-preview>
          <div class="vds-slider-thumb"></div>
        </media-volume-slider>

        <!-- Time Group -->
        <div class="vds-time-group">
          <media-time class="vds-time" type="current"></media-time>
          <div class="vds-time-divider">/</div>
          <media-time class="vds-time" type="duration"></media-time>
        </div>

        <media-chapter-title class="vds-chapter-title"></media-chapter-title>

        <div class="vds-controls-spacer"></div>

        <!--Captions menu-->
        <media-menu clas="vds-menu">
          <!--button with tooltip-->
          <media-tooltip>
            <media-tooltip-trigger>
              <media-menu-button aria-label="captions menu" class="vds-menu-button vds-button">
                <media-icon type="closed-captions"></media-icon>
              </media-menu-button>
            </media-tooltip-trigger>
            <media-tooltip-content class="vds-tooltip-content" placement="top">
              {{ 'Subtitles'|translate }}
            </media-tooltip-content>
          </media-tooltip>

          <!--subtitles items-->
          <media-menu-items class="vds-menu-items" placement="top end">
            <!-- https://www.vidstack.io/docs/wc/player/components/menus/menu?styling=default-theme -->
            <media-captions-radio-group class="vds-radio-group" off-label="Off">
              <template>
                <media-radio class="vds-radio">
                  <media-icon class="vds-icon" type="check"></media-icon>
                  <span class="vds-radio-label" data-part="label"></span>
                </media-radio>
              </template>
            </media-captions-radio-group>
          </media-menu-items>
        </media-menu>

        <!-- Playback speed menu -->
        <media-menu class="vds-menu">
          <!-- Settings Menu Button -->
          <media-tooltip>
            <media-tooltip-trigger>
              <media-menu-button class="vds-menu-button vds-button">
                <media-icon type="playback-speed-circle"></media-icon>
              </media-menu-button>
            </media-tooltip-trigger>
            <media-tooltip-content class="vds-tooltip-content" placement="top">
              {{ 'Wiedergabegeschwindigkeit'|translate }}
            </media-tooltip-content>
          </media-tooltip>

          <!-- Settings Menu Items -->
          <media-menu-items class="vds-menu-items" placement="top end">
            <!--https://www.vidstack.io/docs/wc/player/components/menus/speed-radio-group?styling=default-theme-->
            <media-speed-radio-group class="vds-radio-group" normal-label="Normal">
              <template>
                <media-radio class="vds-radio">
                  <media-icon class="vds-icon" type="check"></media-icon>
                  <span class="vds-radio-label" data-part="label"></span>
                </media-radio>
              </template>
            </media-speed-radio-group>
          </media-menu-items>
        </media-menu>

        <!-- Fullscreen Button -->
        <media-tooltip>
          <media-tooltip-trigger>
            <media-fullscreen-button class="vds-button">
              <media-icon class="vds-fs-enter-icon" type="fullscreen"></media-icon>
              <media-icon class="vds-fs-exit-icon" type="fullscreen-exit"></media-icon>
            </media-fullscreen-button>
          </media-tooltip-trigger>
          <media-tooltip-content class="vds-tooltip-content" placement="top end">
            <span class="vds-fs-enter-tooltip-text">Enter Fullscreen</span>
            <span class="vds-fs-exit-tooltip-text">Exit Fullscreen</span>
          </media-tooltip-content>
        </media-tooltip>
      </media-controls-group>
    </media-controls>
  </media-player>

  <!--
  <video [poster]="poster_url"
         #videoPlayer
         (loadstart)="loadPlayerJS();"
         (loadedmetadata)="register_view();"
         (ended)="registerViewedTill();"
         controls
         preload="none"
         style="overflow: hidden;"
         oncontextmenu="return false;"
         controlsList="nodownload"
         crossorigin="anonymous"
         disableRemotePlayback
         playsinline
         height="100%"
         width="100%">
    <source src="{{video_object.processed_file}}" type="video/mp4">
    <track *ngFor="let subtitle of subtitles" kind="subtitles"
           [src]="subtitle.src" [label]="subtitle.label" [srclang]="subtitle.srclang">
    Your browser does not support the video tag.
  </video>
  -->

  <button mat-fab (click)="videoPlayer.play(); videoPlayer.muted=false;" *ngIf="videoPlayer.paused"
          color="primary" class="position-absolute">
    <mat-icon>
      play_arrow
    </mat-icon>
  </button>
</div>
