import { Component, ElementRef, EventEmitter, Input, OnInit,OnChanges, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileInput, FileValidator } from 'ngx-material-file-input';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-video-upload-viewer',
  templateUrl: './video-upload-viewer.component.html',
  styleUrls: ['./video-upload-viewer.component.scss']
})
export class VideoUploadViewerComponent implements OnInit {
  @Output()
  videoUploaded = new EventEmitter<object>();
  environment = environment;

  vidSrc: any = '';
  fileName: any;
  fileSelected:boolean = false;
  showUploadBtn:boolean = false;
  videoUploadFormGroup: UntypedFormGroup;
  max_video_Size: number = 0;



  constructor(private formBuilder: UntypedFormBuilder, private elRef: ElementRef, public authService: AuthService, private dialog: MatDialog, public sanitizer: DomSanitizer) {
    this.max_video_Size = this.authService.company.max_video_size_bytes;
  }

  ngOnInit(): void {
    this.videoUploadFormGroup = this.formBuilder.group({
      requiredVideo: [
        undefined,
        [Validators.required, FileValidator.maxContentSize(this.max_video_Size)]
      ]
    });
  }

  readFile(file: File): void {
    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        let buffer = e.target.result;
        // We have to convert the buffer to a blob:
        let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });
        // The blob gives us a URL to the video file:
        let url = window.URL.createObjectURL(videoBlob);

        this.vidSrc = this.sanitizer.bypassSecurityTrustUrl(url);
        this.videoUploaded.emit({showUploadBtn:this.showUploadBtn, uploadedVidSrc: this.vidSrc, uploadedVidBlob: videoBlob, name: this.fileName });
        
      };
      reader.readAsArrayBuffer(file);
    }
   
  }

  onDropped(file: File): void {
    if (file.type === 'video/mp4') {
      this.fileName = file.name;
      this.videoUploadFormGroup.setValue({ requiredVideo: new FileInput([file]) });
      this.readFile(file);
    }
  }

  onFileSelected(): void {
    const vidObj = this.videoUploadFormGroup.get('requiredVideo').value;
    this.fileName = vidObj['_fileName'];
    this.readFile(vidObj.files[0]);
  }

}
