<div style="padding:1.5rem 3.5rem 1.5rem 3.5rem">
    <h2 translate>Meine Kommentare</h2>
<div class="row"  *ngFor="let videoResponse of responsedata" >
        <div class="col-9">{{videoResponse.content}}</div>
        <div class="col-3"> 
            <button
       mat-icon-button
       (click)="deleteComment(videoResponse.id)">
            <span><mat-icon>delete</mat-icon></span>
            </button>
        </div>
</div>
</div>