import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatGridList } from '@angular/material/grid-list';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { VideoCard} from '../models/video/video.interface';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { NavbarService } from '../services/navbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-favorite-video',
  templateUrl: './favorite-video.component.html',
  styleUrls: ['./favorite-video.component.scss']
})
export class FavoriteVideoComponent implements OnInit {
@ViewChild('grid') grid: MatGridList;
@ViewChild(MatPaginator) paginator: MatPaginator;
cols:number;

  gridByBreakpoint = {
    xl: 5,
    lg: 4,
    md: 3,
    sm: 2,
    xs: 1
  }

  videos: VideoCard[];
  gutterSize: number;
  subscription: Subscription;
  backendUrl: string;
  videoCurrentPage: number = 100;
  pageSize: number = 10;
  pageSizeOptions: number[] = [this.pageSize];
  // tslint:disable-next-line:max-line-length

  constructor(public dataService: DataService,private translateService :TranslateService,
    private breakpointObserver: BreakpointObserver,
    public route: ActivatedRoute,
    private navbarService: NavbarService,
    public authService: AuthService) {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).subscribe(result =>{
      if(result.matches){
        if(result.breakpoints[Breakpoints.XSmall]){
          this.cols = this.gridByBreakpoint.xs;
        }
        if(result.breakpoints[Breakpoints.Small]){
          this.cols = this.gridByBreakpoint.sm;
        }
        if(result.breakpoints[Breakpoints.Medium]){
          this.cols = this.gridByBreakpoint.md;
        }
        if(result.breakpoints[Breakpoints.Large]){
          this.cols = this.gridByBreakpoint.lg;
        }
        if(result.breakpoints[Breakpoints.XLarge]){
          this.cols = this.gridByBreakpoint.xl;
        }

      }
    })
  }
  ngOnInit(): void {
    this.getVideos('1');
    this.backendUrl = environment.backendURL;
    this.navbarService.disableCreationMode();
    this.resize(window.innerWidth);


  }

  getVideos(page): void {
    this.dataService.getURL(`user/videos/myfavs/?page=${page}`).subscribe((data: any) => {
      this.videos = data.data;
      this.pageSize = data.pageSize;
      this.videoCurrentPage = data.length;
      this.paginator._intl.itemsPerPageLabel = this.translateService.instant('Objekte pro Seite:');
    });
  }

  updateVideos(event) {
    this.getVideos(event.pageIndex+1);
  }

  resize(innerWidth) {
    let cw = 320; // card width
    let gs = 20;  // gutter size
    let sn = 50; // small side nav width = 50 approx
    this.cols = Math.floor((innerWidth - sn) / (cw + gs));
  }

  onResize(event) {
    this.resize(event.target.innerWidth);
  }
}
