<div class="d-flex m-3 align-items-center" style="height: 36px;">
  <!--manual height 36px as buttons in other components are like this too -->
  <mat-icon class="mr-1 material-icons-outlined" color="primary">
    video_library
  </mat-icon>
  <span class="fontStyle" style="font-size: x-large;" translate>
    My Library
  </span>
</div>

<nav mat-tab-nav-bar class="mt-3" style="position: sticky;
  top: 0;background-color: white;z-index: 9999;">
  <a #rla="routerLinkActive"
     *ngFor="let route of routePath"
     [active]="rla.isActive"
     [routerLink]="route.link"
     class="text-nunito font-weight-normal"
     mat-tab-link routerLinkActive="active">
    <mat-icon class="material-icons-outlined mr-2">
      {{route.icon}}
    </mat-icon>
    {{route.name|translate}}
  </a>
</nav>

<router-outlet></router-outlet>
