<app-fe-banners></app-fe-banners>
<div class="Site-content d-flex justify-content-center align-items-center">

    <div *ngIf="!isIframe">
    <!-- Only show this if we are neither in Teams nor in iFrame -->
    <form (ngSubmit)="submitLogin()" [formGroup]="credentials">
      <mat-card style="background-color:transparent !important">
        <mat-card-header class="justify-content-center" style="margin-bottom:2rem">
          <mat-card-title class="fontStyle" translate>Jetzt anmelden</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <mat-form-field appearance="outline" class="w-100 fontNormal" hideRequiredMarker
                          style="height: 60px;font-size: 18px;">
            <mat-label translate>Email-Adresse</mat-label>
            <input autocomplete="email" email formControlName="email" matInput required type="text"/>
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-100 fontNormal" hideRequiredMarker
                          style="height: 60px;font-size: 18px;">
            <mat-label translate>Passwort</mat-label>
            <input autocomplete="current-password" formControlName="password" matInput required type="password"/>
          </mat-form-field>

        </mat-card-content>
        <!--        <mat-checkbox class="w-100" style="justify-content: center;">Anmeldeinformationen speichern</mat-checkbox>-->
        <mat-card-actions class="d-flex flex-column align-items-center">
          <button class="btn-zesavi btn-color" mat-raised-button style="margin-bottom: 20px;" type="submit">
            {{'Anmelden'|translate}}
          </button>
          <p class="oderStyle" translate>oder</p>
          <button class="btn-zesavi btn-color" mat-raised-button routerLink="/signUp"
                  style="margin-bottom: 20px;margin:0px !important" type="submit">
            {{'Neu registrieren'|translate}}
          </button>
        </mat-card-actions>
        <hr>
        <div class="d-flex flex-column align-items-center">
          <div>
            <a class="w-100 forgotPassword" routerLink="/forgotPassword" translate>
              Reset Password
            </a>
          </div>

          <div class="mt-3">
            <a href="/api/microsoft/sign_in">
              <img [src]=microsoftImageSource alt="Login with Microsoft" width="250px">
            </a>
          </div>

          <!--https://developers.google.com/identity/gsi/web/reference/html-reference#element_with_class_g_id_signin-->
          <div class="g_id_signin mt-3"
               data-logo_alignment="left"
               data-shape="rectangular"
               data-size="large"
               data-text="sign_in_with"
               data-theme="outline"
               data-type="standard"
               data-width=250>
          </div>

        </div>
      </mat-card>
    </form>
  </div>

  <div *ngIf="isIframe" style="position: absolute; top: 15%;">
    <!--Only show this in iframe, but not in Teams browser-->
    <!--  Todo: this is the iframe case, tell users how to unblock cookies in Safari and Chrome  -->
    <mat-card>
      <mat-card-title translate>Dear Clypp user</mat-card-title>
      <mat-card-header class="justify-content-center">
        <img alt="Microsoft Teams" mat-card-image
             src="../../assets/logo/favicon.png" style="height: 50px; margin-left: -40px;">
      </mat-card-header>
      <mat-card-content>
        <mat-card-title>
          <span translate>Please</span>
          <span (click)="openLoginLink('/login');" class="btn-link" translate>
            log in here
          </span>
          <span translate>and reload this page.</span>
        </mat-card-title>
        <mat-card-subtitle translate>
          You may also need to enable third party cookies.
        </mat-card-subtitle>
      </mat-card-content>
    </mat-card>

    <mat-divider class="my-2"></mat-divider>

    <!-- Teams case (and most probably in iframe)-->
    <mat-card>
      <mat-card-title translate>On Microsoft Teams</mat-card-title>
      <mat-card-header class="justify-content-center">
        <img alt="Microsoft Teams" mat-card-image
             src="../../assets/microsoft-teams.png" style="height: 50px; margin-left: -40px;">
      </mat-card-header>
      <mat-card-content>
        <mat-card-title>
          <span translate>Please</span>
          <span class="btn-link" translate
                (click)="openLoginLink('https://teams.microsoft.com/l/entity/34ed2c1e-bccf-463d-8fbc-0403e72e89fc/internalVideosTab');">
            log into the Clypp Personal Tab
          </span>
          <span translate>and try again.</span>
        </mat-card-title>
      </mat-card-content>
    </mat-card>
  </div>
</div>

