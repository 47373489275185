<div class="m-3 head-text">
    <p translate>You can share your organisation's unrestricted videos with other organisation and decide if other organisation's videos are visible in your organisation.</p>
    <!-- <p translate>You can also decide if other organisation's videos are visible in your organisation.</p> -->
  </div>
  <div class="friend-table ml-3 mb-3">
    <table #friend_table [dataSource]="friend_organisations" mat-table>
      <!-- sharing company --> <!-- shared company --> <!-- accepted --> <!-- action -->
      <ng-container matColumnDef="sharing_company">
        <th *matHeaderCellDef mat-header-cell translate>Sharing Organisation</th>
        <td *matCellDef="let element" mat-cell>
          {{element.sharing_company.name}}
          <a *ngIf="element.sharing_company.id != authService.company.id" href="/public/{{element.sharing_company.id}}"
             target="_blank">
            <mat-icon style="color: black; vertical-align: bottom;" mat-list-icon>open_in_new</mat-icon>
          </a>
        </td>
        <td *matFooterCellDef mat-footer-cell>
          {{authService.company.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="shared_company">
        <th *matHeaderCellDef mat-header-cell translate>Shares Clypps with</th>
        <td *matCellDef="let element" mat-cell>
          {{element.shared_company.name}}
          <a *ngIf="element.shared_company.id != authService.company.id" href="/public/{{element.shared_company.id}}"
             target="_blank">
            <mat-icon style="color: black; vertical-align: bottom;" mat-list-icon>open_in_new</mat-icon>
          </a>
        </td>
        <td *matFooterCellDef mat-footer-cell>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label translate>Email-Adresse</mat-label>
            <input [(ngModel)]="global_admin_email" matInput placeholder="max.mustermann@gmail.com" email>
            <mat-hint translate>
              Provide the email of an admin in the desired partner organization
            </mat-hint>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="accepted">
        <th *matHeaderCellDef mat-header-cell translate>Accepted</th>
        <td *matCellDef="let element" mat-cell>
          <mat-icon *ngIf="element.is_accepted" class="ml-4" mat-list-icon matTooltip="{{'Accepted' | translate}}">
            done
          </mat-icon>
          <mat-icon *ngIf="!element.is_accepted && element.sharing_company.id == authService.company.id" class="ml-4"
                    mat-list-icon matTooltip="{{'Pending' | translate}}">
            error_outline
          </mat-icon>
          <button (click)="acceptInvite(element.id)" *ngIf="!element.is_accepted && element.sharing_company.id != authService.company.id" class="px-1 ml-3"
                  mat-stroked-button>
            <mat-icon mat-list-icon>done</mat-icon>
            <span translate class="d-none d-sm-inline">Accept</span>
          </button>
        </td>
        <td *matFooterCellDef mat-footer-cell>
          <button (click)="inviteCompany()" class="ml-3" mat-stroked-button>
            <mat-icon class="material-icons-outlined">add</mat-icon>
            <span translate class="d-none d-sm-inline">Teilen</span>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" mat-cell>
          <button (click)="deleteInvite(element.id)" class="px-1 blocked ml-3" mat-stroked-button>
            <mat-icon mat-list-icon>delete_outline</mat-icon>
            <span translate class="d-none d-sm-inline">Löschen</span>
          </button>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <tr *matHeaderRowDef="['sharing_company', 'shared_company', 'accepted', 'action']" mat-header-row></tr>
      <tr *matRowDef="let row; columns: ['sharing_company', 'shared_company', 'accepted', 'action'];" mat-row></tr>
      <tr *matFooterRowDef="['sharing_company', 'shared_company', 'accepted', 'action']"
          style="height: 100px;"
          mat-footer-row></tr>
    </table>
  </div>
