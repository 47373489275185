import {Component, OnInit} from '@angular/core';
import {DataService} from "../services/data.service";
import {AuthService} from '../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ProgressSpinnerDialogComponent} from "../shared/progress-spinner-dialog/progress-spinner-dialog.component";
import {RequestOverview} from "../models/videoRequest";
import {SelectVideoPopupComponent} from "../select-video-popup/select-video-popup.component";
import {VideoRequestDialogComponent} from "../shared/video-request-dialog/video-request-dialog.component";

@Component({
  selector: 'app-video-request-dashboard',
  templateUrl: './video-request-dashboard.component.html',
  styleUrls: ['./video-request-dashboard.component.scss']
})
export class VideoRequestDashboardComponent implements OnInit {
  requests: RequestOverview[] = [];
  userId: number = 0;
  filterValue: string = 'open';  // on first page, show open requests
  searchQuery: string = '';
  filteredRequest: RequestOverview[] = [];

  constructor(public dataService: DataService,
              private dialog: MatDialog,
              public authService: AuthService,
              private snackBar: MatSnackBar,
              private router: Router,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.userId = parseInt(this.authService.currentUserId);
    this.getVideoRequest();
  }

  getVideoRequest() {
    this.dataService.getURL<any>(`user/requests/`, {observe: 'body', responseType: 'json'}).subscribe((res) => {
      this.requests = res;
      this.filteredRequest = [...this.requests];
      // apply filter to load existing results
      this.applyFilter();
      let message: string = `${this.filteredRequest.length} `;
      if (this.filteredRequest.length == 1){
        message += this.translateService.instant("open request");
      } else {
        message += this.translateService.instant("open requests");
      }
      this.snackBar.open(this.translateService.instant(message), '', {duration: 2500});
    });
  }

  // open the video search popup and fulfill the request
  searchClypp(request_id: number){
    // todo
  }

  // this method sends the put call to fulfill a request
  _fulfillRequest(request_id: number, video_id: string) {
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });

    this.dataService.putURL(`user/requests/${request_id}/`, {
      fulfilled_video: video_id
    }, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res) => {
      this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
      // grabbed_by is also needed
      this.getVideoRequest();
      dialogRef.close();
    }, (e) => {
      console.error(e);
      if (e.status == 500) {
        this.snackBar.open(this.translateService.instant('This is not a valid Clypp link'), '', {duration: 2500});
      } else {
        this.snackBar.open(e.error, '', {duration: 2500});
      }
      dialogRef.close();
    });
  }

  // ask user to select a clypp from the popup
  selectClypp(request_id: number){
    this.dialog.open(SelectVideoPopupComponent, {
      maxWidth: "700px",
      width: "50%",
      minWidth: "350px",
      // minHeight: "400px",
      // height: "80vh",
      disableClose: false,
    }).afterClosed().subscribe(res => {
      if (res) {
        let message = this.translateService.instant("Fulfill request");
        message += "?";
        if (window.confirm(message)) {
          this._fulfillRequest(request_id, res.id);
        }
      }
    });
  }

  // take user to create page, with integration parameters
  createClypp(req: RequestOverview) {
    this.router.navigate(['create-video', 'PR'],{
      queryParams: {
        title: req.title,
        desc: req.desc,
        video_request_id: req.id
      }
    });
  }

  deleteRequestCall(request_id: number) {
    if (window.confirm(this.translateService.instant("Bist du sicher?"))) {
      this.dataService.deleteURL(`user/requests/${request_id}/`).subscribe((res) => {
        this.snackBar.open(
          this.translateService.instant('Erfolgreich gelöscht'), '',
          {duration: 2500}
        );
        const deleted_loc_index: number = this.requests.findIndex(r => r.id == request_id);
        this.requests.splice(deleted_loc_index, 1);
        this.applyFilter();
      }, (e) => {
        console.error(e);
        this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
      });
    }
  }

  sendEmail(req) {
    // if a req is grabbed, then this method sends an email to
    // either requested_by
    // or grabbed_by
    if(req.grabbed_by){
      let subject = this.translateService.instant("Inquiry on your Clypp request");
      subject += `: ${req.title}`;

      if(this.userId == req.requested_by.id){
        window.open(`mailto:${req.grabbed_by.email}?subject=${subject}`, "_blank");
      }
      else{
        window.open(`mailto:${req.requested_by.email}?subject=${subject}`, "_blank");
      }
    }
  }

  viewVideoCall(video_id: string) {
    window.open(`/view/${video_id}`, '_blank');
  }

  videoReq() {
    const dialogRef = this.dialog.open(VideoRequestDialogComponent, {
      width: "600px",
      autoFocus: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((dialogResponse) => {
      if (dialogResponse) {
        // reset search and filter by my requests
        this.filterValue = 'requested_by';
        this.searchQuery = '';
        this.getVideoRequest();
      }
    });
  }

  applyFilter() {
    // performs both search and filter

    // first search
    this.filteredRequest = this.requests.filter(r => r.title.toLowerCase().includes(this.searchQuery.toLowerCase()));

    // then filter
    switch (this.filterValue) {
      case 'fulfilled':
        this.filteredRequest = this.filteredRequest.filter(r => r.grabbed_by?.id == this.userId);
        break;
      case 'open':
        this.filteredRequest   = this.filteredRequest.filter(r => {
          if (r.fulfilled_video == null) {
            // only show the open ones
            return true;
          }
          return false;
        });
        break;
      case 'requested_by':
        this.filteredRequest = this.filteredRequest.filter(r => r.requested_by.id == this.userId);
        break;
      default:
        // all case is already taken care of
        break;
    }
  }
}
