<h1 mat-card-title translate>Change password</h1>
<mat-card-subtitle translate>
  You will be logged out from all devices
</mat-card-subtitle>
<div mat-dialog-content style="min-height: 170px">
  <form autocomplete="off">

    <mat-form-field appearance="outline" class="w-100">
      <mat-label translate>Current password</mat-label>
      <input [type]="hide_old ? 'password' : 'text'" matInput [formControl]="oldPasswordFormControl" autocomplete="current-password">
      <button (click)="hide_old = !hide_old" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide_old" mat-icon-button
              matSuffix>
        <mat-icon>{{hide_old ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-error *ngIf="oldPasswordFormControl.hasError('required')">
        <span translate>Required</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label translate>New password</mat-label>
      <input [type]="hide_new ? 'password' : 'text'" matInput [formControl]="passwordFormControl" autocomplete="new-password">
      <button (click)="hide_new = !hide_new" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide_new" mat-icon-button
              matSuffix>
        <mat-icon>{{hide_new ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-error *ngIf="passwordFormControl.hasError('required')">
        <span translate>Required</span>
      </mat-error>
      <mat-error *ngIf="passwordFormControl.hasError('minlength')">
        <span translate>Das Passwort muss aus mindestens 10 Zeichen bestehen</span>
      </mat-error>
      <mat-error *ngIf="passwordFormControl.hasError('maxlength')">
        <span translate>Das Passwort sollte weniger als 30 Zeichen lang sein</span>
      </mat-error>
      <mat-error *ngIf="passwordFormControl.hasError('pattern')">
        <span translate>(at least one uppercase, one lowercase letter, one special character and a number)</span>
      </mat-error>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions class="justify-content-between">
  <button mat-stroked-button mat-dialog-close>
    {{'Cancel'|translate}}
  </button>
  <button mat-stroked-button color="primary" [disabled]="passwordFormControl.invalid || oldPasswordFormControl.invalid"
          (click)="savePassword();">
    {{'Speichern'|translate}}
  </button>
</div>
