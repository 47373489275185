import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from "@angular/material/snack-bar";
import {Observable} from 'rxjs';
import {DataService} from '../../services/data.service';
import {NavbarService} from '../../services/navbar.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProgressSpinnerDialogComponent} from '../../shared/progress-spinner-dialog/progress-spinner-dialog.component';
import {DomSanitizer} from '@angular/platform-browser';
import {AudioRecorderService} from '../../services/audio-recorder.service';
import * as audioMeter from 'src/app/scripts/volume-meter.js';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MatAccordion, MatExpansionPanel} from '@angular/material/expansion';
// import {Color, NgxMatColorPickerInput} from '@angular-material-components/color-picker';
import {TextOverlayDragDropComponent} from '../../text-overlay-drag-drop/text-overlay-drag-drop.component';
import {ShapeOverlayDivComponent} from '../shape-overlay-div/shape-overlay-div.component';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from "../../services/auth.service";
import {VideoView} from "../../models/video/video.interface";

export interface Bar {
  color: string;
  cols: number;
  rows: number;
  start: number;
  end: number;
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

@Component({
  selector: 'app-video-trimmer',
  templateUrl: './video-trimmer.component.html',
  styleUrls: ['./video-trimmer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class VideoTrimmerComponent implements OnInit, OnChanges {

  readonly WIDTH = 35;
  readonly HEIGHT = 2;
  video_Resp: string;

  @Input()
  videoTitle: string;

  @Input()
  videoURL: string;

  @Input()
  videoBOTHURL: string;

  @Input()
  videoType: string;

  @Input()
  showAudioFromCreation: any;

  @Output()
  videoTrimmed = new EventEmitter<{ trimmed: boolean, finalLink: string }>();
  @ViewChild('slider') slider;
  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>;
  @ViewChild('videoPlayerBOTH') videoPlayerBOTH;
  @ViewChild('valueInput') valueInput;
  @ViewChild('valueSpan') valueSpan;
  @ViewChild('valueBetweenStart') valueBetweenStart;
  @ViewChild('valueBetweenEnd') valueBetweenEnd;
  @ViewChild('currentTime') currentTime;
  @ViewChild('audioPlayer') audioPlayer;
  @ViewChild('meter') meter: ElementRef;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('shapeAccordion') shapeAccordion: MatAccordion;
  @ViewChildren(MatExpansionPanel) matExpansionPanelQueryList: QueryList<MatExpansionPanel>;
  @ViewChildren('textExpansionPanel') textExpansionPanelQueryList: QueryList<MatExpansionPanel>;
  @ViewChild('dragDiv', {read: ViewContainerRef}) dragDiv: ViewContainerRef;
  @ViewChild('resizeDiv', {read: ViewContainerRef}) resizeDiv: ViewContainerRef;
  @ViewChild('dragComponent') dragComponent: TextOverlayDragDropComponent;
  @ViewChild('resizeComponent') resizeComponent: ShapeOverlayDivComponent;
  @ViewChild('videoDynamic') videoDynamic;

  opened: boolean;
  shapeClicked: boolean;
  fullscreenBl = 'false';
  fullscreenBr = 'false';
  fullscreenTr = 'false';
  fullscreen_ai_left = 'false';
  fullscreen_ai_right = 'false';
  speed: number = 1.0;
  video_bg = 'orig';
  audio_vol: number = 1.0;
  overlay_code = 'br';
  ci_video_code = 'both';
  mic_on_text = "";
  mic_off_text = "";
  noise_on_text = "";
  noise_off_text = "";
  iconText: string;
  noiseIconText: string;
  edit_tab = true;
  textOverlay_tab = false;
  blur_tab = false;
  arecorder;
  formData: FormData;
  audioURL;
  deleteArr: any = [];
  noise_sup: boolean = false;
  webcamObj = {
    'ai-left': false,
    tr: false,
    bl: false,
    br: true,
    'ai-right': false
  }
  video_bgObj = {
    orig: true,
    blur: false,
    cust: false,
  };
  public icon = 'volume_up';
  public noiseIcon = 'noise_control_off';
  player;
  playerBOTH;
  urlVideo: any = [];
  videoDuration = 0;
  notesChangeValue: any;
  currentTempTime = 0;
  activeIndex: number;
  activeTextOverlay: number;
  // video trim
  tiles: Bar[] = [];
  timeStampArray = [];
  new_child: any;
  seekerValue = 0;
  showDeleteSelectedBtn = false;
  showDiscardButton = false;
  selectedSectionIndex = 0;
  pri_color = localStorage.getItem("primary_color");
  greyColor: string = this.pri_color;
  redColor = '#DADADA';
  blackColor = '#000000 2.5px solid';
  greenColor: string = this.pri_color;
  overlayXvalue: any;
  overlayYvalue: any;
  videoPlayerClass: any;
  videoContainerHeight: any;
  bgColorChanged: any;
  alphaChanged: Number;
  transparentColor = 'transparent 0px solid';
  date: any;
  videoCont: any;
  vidValue: any = [];
  VideoID: any;
  disableAudio: boolean = false;
  trimfromEdit: any;
  trimValue_Edit: any;
  trimCurrentTime: any;
  deletedValueArray: any = [];
  selectRecordAudio = false;
  showCountdown = false;
  timeLeft: string = '3';
  audioinputVal: any;
  defaultValue: any;
  audioIcon = 'mic';
  micVal: boolean = true;
  permissionDenied: boolean;
  disableSeeker = false;
  audio_start_time = 0;
  audio_end_time = 0;
  file;
  thumbnail_time = 1;  // this will store at what time the thumbnail was set
  text_overlay_Array = [];
  text_overlay_formObj = {};
  overlay_child_Obj = {};
  resize_child_Obj = [];
  shape_overlay_Array = [];
  shape_overlay_formObj = [];
  shapeOverlayCounter = 0;
  shapeOverlayDeleteCounter = 0;
  textOverlayCounter = 0;
  textOverlayDeleteCounter = 0;
  base_video: any;
  deletedShape: boolean = false;
  notes = [];
  fontColorChanged: any;
  audioHigh = new Audio();
  audioLow = new Audio();
  styles;
  fontUrl = '';
  videoUpscaleWidthFactor = 1;
  videoUpscaleHeightFactor = 1;
  videoDownscaleWidthFactor = 1;
  videoDownscaleHeightFactor = 1;
  videoClientHeight: 1080;
  videoClientWidth: 1920;
  trimbarIndex: any;
  vidMaxWidth: any;
  ShapetabIndex: number;
  textTabIndex: number;
  processedFileExists: boolean = false;
  playerLoaded: boolean = false;

  @Output()
  private finishedEditing = new EventEmitter<void>();
  private audioMeter: any;

  constructor(private httpClient: HttpClient,
              public componentFactoryResolver: ComponentFactoryResolver,
              private formBuilder: UntypedFormBuilder,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private navbarService: NavbarService,
              private route: ActivatedRoute,
              private translateService: TranslateService,
              public cd: ChangeDetectorRef,
              private dataService: DataService,
              private sanitizer: DomSanitizer,
              private canvasRenderer: Renderer2,
              private router: Router,
              public audioRecorderService: AudioRecorderService,
              public authService: AuthService) {
    this.loadCompanyDetails();

    // update the text
    this.mic_on_text = this.translateService.instant('Audio ist im fertigen Video aktiviert');
    this.mic_off_text = this.translateService.instant('Audio ist im fertigen Video deaktiviert');
    this.noise_on_text = this.translateService.instant('Die Rauschunterdrückung ist aktiviert');
    this.noise_off_text = this.translateService.instant('Die Rauschunterdrückung ist deaktiviert');
  }

  playDelay = async () => {
    this.showCountdown = true;
    this.timeLeft = '3';
    await delay(900);
    this.timeLeft = '2';
    await delay(900);
    this.timeLeft = '1';
    await delay(300);
    this.audioHigh.play();
    await delay(700);
    this.showCountdown = false;
  };

  loadCompanyDetails() {
    // this.dataService.getURL<any>('user/company/').subscribe((companyDetails) => {
    //   this.fontUrl = companyDetails.font_file;
    //     });
    this.fontUrl = this.authService.company.font_file;
    this.styles = `
              @font-face {
                  font-family: 'font1';
                  src: url("${this.fontUrl}") format("truetype");
              }
              div#canvasDiv div{
                  font-family: 'font1', sans-serif !important;
              }`
    const node = document.createElement('style');
    node.innerHTML = this.styles;
    document.body.appendChild(node);
  }

  ngOnInit(): void {
    // load audio on init
    this.audioHigh.src = '../../assets/Mini_Button_01.wav';
    this.audioHigh.load();
    this.audioLow.src = '../../assets/Mini_Button_02.wav';
    this.audioLow.load();

    this.iconText = this.mic_on_text;
    this.noiseIconText = this.noise_off_text;

    // this.navbarService.showSideNav = false;
    this.navbarService.enableCreationMode();
    this.navbarService.hide();

    // load data for this video
    this.route.paramMap.subscribe(async (map) => {
      this.VideoID = map.get('id');
    });
    this.dataService
      .getURL(`user/videos/${this.VideoID}/`)
      .subscribe((res) => {
        this.vidValue = res;
        this.video_Resp = JSON.stringify(this.vidValue);
        this.videoURL = this.vidValue['video_file'];
        this.videoBOTHURL = this.vidValue['overlay_video'];
        this.audioURL = null;
        this.videoType = this.vidValue['type'];
        if (this.vidValue['script'] !== null) {
          this.notes = [
            'Hinweis: Beginne dein Video mit dem Satz: "In diesem Video zeige ich ' +
            this.date +
            ' wie…',
            this.vidValue['script'],
          ];
        }

        // maintain state from review
        if (this.vidValue['edit_parameters'] != null) {
          //noise suppression
          if (this.vidValue['edit_parameters'].noise_sup) {
            this.noise_sup = true;
            this.noiseIcon = 'noise_aware';
            this.noiseIconText = this.noise_on_text;
          }
          if (this.vidValue['edit_parameters'].base_video) {
            this.base_video = this.vidValue['edit_parameters'].base_video;
          }

          //webcam overlay position
          this.webcamObj['ai-left'] = false;
          this.webcamObj.tr = false;
          this.webcamObj.bl = false;
          this.webcamObj.br = false;
          this.webcamObj['ai-right'] = false;

          if (this.vidValue['edit_parameters'].overlay_code == 'ai-left') {
            this.overlay_code = 'ai-left';
            this.webcamObj['ai-left'] = true;
          } else if (this.vidValue['edit_parameters'].overlay_code == 'tr') {
            this.overlay_code = 'tr';
            this.webcamObj['tr'] = true;
          } else if (this.vidValue['edit_parameters'].overlay_code == 'bl') {
            this.overlay_code = 'bl';
            this.webcamObj['bl'] = true;
          } else if (this.vidValue['edit_parameters'].overlay_code == 'ai-right') {
            this.overlay_code = 'ai-right';
            this.webcamObj['ai-right'] = true;
          } else {
            this.overlay_code = 'br';
            this.webcamObj['br'] = true;
          }

          //webcam bg

          this.video_bgObj.orig = false;
          this.video_bgObj.blur = false;
          this.video_bgObj.cust = false;
          this.video_bgObj[this.vidValue['edit_parameters'].video_bg] = true;
          this.video_bg = this.vidValue['edit_parameters'].video_bg;

          // audio overlay start & end
          if (this.vidValue['edit_parameters'].audio_end_time) {
            this.audio_start_time = this.vidValue['edit_parameters'].audio_start_time;
            this.audio_end_time = this.vidValue['edit_parameters'].audio_end_time;
            this.audioURL = this.vidValue['overlay_audio'];
          }

          if (this.vidValue['processed_file']) {
            this.processedFileExists = true;
          }

        }
      });

    document.onfullscreenchange = () => {
      if (document.fullscreenElement) {
        if (this.overlay_code == 'bl') {
          this.fullscreenBl = 'true';
        } else if (this.overlay_code == 'br') {
          this.fullscreenBr = 'true';
        } else if (this.overlay_code == 'tr') {
          this.fullscreenTr = 'true';
        } else if (this.overlay_code == 'ai-right') {
          this.fullscreen_ai_right = 'true';
        } else {
          this.fullscreen_ai_left = 'true';
        }
      } else {
        this.fullscreenBl = 'false';
        this.fullscreenBr = 'false';
        this.fullscreen_ai_left = 'false';
        this.fullscreen_ai_right = 'false';
        this.fullscreenTr = 'false';
      }
    }
    // this.audioRecorderService.getAudioVal();
    // this.audioRecorderService.audioVal.subscribe((data) => {
    //     this.audioinputVal = data;
    //     this.defaultValue = this.audioinputVal[0];
    // });

  }

  ngOnChanges(): void {
    this.onResize();
    this.cd.detectChanges();
  }

  hexToRgb(hex) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }


  onPlayerReady(event): void {
    this.player = this.videoPlayer.nativeElement;
    if (this.videoPlayerBOTH == undefined) {
      this.playerBOTH = this.videoPlayer.nativeElement;
    } else {
      this.playerBOTH = this.videoPlayerBOTH.nativeElement;
    }
    this.vidMaxWidth = this.player.clientWidth;
    if (window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      if (this.player.videoWidth <= 1280) {
        this.vidMaxWidth = 496;
      }
    }
    this.videoCont = {
      'max-width': `${this.vidMaxWidth}px`,
      'max-height': `${this.player.clientHeight}px`,
    }

    if (this.player.clientWidth > this.player.clientHeight) {
      this.videoPlayerClass = {
        'width': '100%',
        'height': 'auto'
      }
    } else {
      this.videoPlayerClass = {
        'width': 'inherit',
        'height': '100%'
      }
    }
    // setting text overlay after player is ready
    // if (this.vidValue['edit_parameters'] != null) {
    //     if (this.vidValue['edit_parameters']['draw_texts'] && this.vidValue['edit_parameters']['draw_texts'].length > 0) {
    //         for (let i in this.vidValue['edit_parameters']['draw_texts']) {
    //             this.setTextOverlayfromAPIresp(this.vidValue['edit_parameters']['draw_texts'][i]);
    //         }
    //     }
    // }
    this.checkDomainAndStop();
    this.videoDuration = (this.player.duration).toFixed(1);
    this.player.currentTime = 0;
    this.playerBOTH.currentTime = 0;

    if (this.player.duration != Infinity) {
      this.tiles = [{
        cols: this.player.duration * 10,
        rows: 1,
        color: this.greyColor,
        start: 0,
        end: (this.player.duration).toFixed(1)
      }];
      this.timeStampArray.push((this.player.duration).toFixed(1));
    }

    this.currentTime.nativeElement.innerHTML =
      this.toHHMMSS(Math.round(this.currentTempTime * 10) / 10) + ' | ' + this.toHHMMSS((this.player.duration).toFixed(1));


    if (this.vidValue['edit_parameters'] != null) {
      // trim bar
      if (this.vidValue['edit_parameters'].start != undefined) {
        this.VideoTrimBarState();
      }

      //audio toggle
      if (this.vidValue['edit_parameters'].audio_vol != null) {
        if (this.vidValue['edit_parameters'].audio_vol == 0) {
          this.showAudioFromCreation = false;
          this.icon = 'volume_up';
          this.audioToggle();
        }
      }

      // if there was thumbnail time from before, it will be set to that value
      // it will be only changed if user selects a new thumbnail
      this.thumbnail_time = this.vidValue['edit_parameters'].thumbnail_time;

    }

    // calculate the factors
    let videoNativeHeight = this.player.videoHeight;
    let videoNativeWidth = this.player.videoWidth;
    this.videoClientHeight = this.player.clientHeight;
    this.videoClientWidth = this.player.clientWidth;
    this.videoDownscaleWidthFactor = parseFloat((this.videoClientWidth / videoNativeWidth).toFixed(2));
    this.videoDownscaleHeightFactor = parseFloat((this.videoClientHeight / videoNativeHeight).toFixed(2));
    this.videoUpscaleWidthFactor = parseFloat((videoNativeWidth / this.videoClientWidth).toFixed(2));
    this.videoUpscaleHeightFactor = parseFloat((videoNativeHeight / this.videoClientHeight).toFixed(2));
  }

  dataLoaded(event) {
    this.playerLoaded = true;
    if (this.vidValue['edit_parameters'] != null) {
      // setting text overlay after player is ready
      if (this.vidValue['edit_parameters']['draw_texts'] && this.vidValue['edit_parameters']['draw_texts'].length > 0) {
        for (let i in this.vidValue['edit_parameters']['draw_texts']) {
          this.setTextOverlayfromAPIresp(this.vidValue['edit_parameters']['draw_texts'][i]);
        }
      }
      if (this.vidValue['edit_parameters']['blur_boxes'] && this.vidValue['edit_parameters']['blur_boxes'].length > 0) {
        for (var box of this.vidValue['edit_parameters']['blur_boxes']) {
          this.setBlurBoxesfromAPIresp(box);
        }
      }
    }
  }

  setTextOverlayfromAPIresp(textOverObj): void {
    // this.inputEnd.focus();
    this.textOverlayCounter++;
    this.textOverlayDeleteCounter++;
    let overlay_title = `Text ${this.text_overlay_Array.length + 1}`;
    this.text_overlay_formObj[this.textOverlayCounter] = this.formBuilder.group({
      textOverStartTime: [''],
      textOverEndTime: [''],
      boxColor: ['', [Validators.pattern(/^#[0-9A-F]{6}$/i), Validators.required]],
      fontColor: ['', [Validators.pattern(/^#[0-9A-F]{6}$/i), Validators.required]],
      overlayBox: [true],
      overlayFontSize: [''],
      overlayXPosition: [''],
      overlayYPosition: [''],
      overlayEditX: [''],
      overlayEditY: [''],
      alpha: [''],
      overlayText: ['', [Validators.required]]
    });

    // calculating actual x and y value of the text overlay
    // this.overlayXvalue = (((textOverObj['x'] * this.player.clientWidth) + ((this.player.getBoundingClientRect().x) * this.player.videoWidth)) / this.player.videoWidth) - this.player.getBoundingClientRect().x;
    this.overlayXvalue = textOverObj['x'] * this.videoDownscaleWidthFactor;
    // this.overlayYvalue = (((textOverObj['y'] * this.player.clientHeight) + ((this.player.getBoundingClientRect().y) * this.player.videoHeight)) / this.player.videoHeight) - this.player.getBoundingClientRect().y;
    this.overlayYvalue = textOverObj['y'] * this.videoDownscaleHeightFactor;

    // text overlay initialization
    let temp = this.hexToRgb(textOverObj['boxcolor']);
    if (textOverObj.alpha == undefined) {
      // this.text_overlay_formObj[this.textOverlayCounter].get('boxColor').setValue(new Color(temp.r, temp.g, temp.b, 1));
      this.text_overlay_formObj[this.textOverlayCounter].get('alpha').setValue(1);
    } else {
      // this.text_overlay_formObj[this.textOverlayCounter].get('boxColor').setValue(new Color(temp.r, temp.g, temp.b, textOverObj.alpha));
      this.text_overlay_formObj[this.textOverlayCounter].get('alpha').setValue(textOverObj.alpha);
    }

    this.text_overlay_formObj[this.textOverlayCounter].get('overlayText').setValue(decodeURIComponent(textOverObj['text']));
    temp = this.hexToRgb(textOverObj['fontcolor']);
    // this.text_overlay_formObj[this.textOverlayCounter].get('fontColor').setValue(new Color(temp.r, temp.g, temp.b));
    this.text_overlay_formObj[this.textOverlayCounter].get('overlayFontSize').setValue(textOverObj['fontsize']);
    let xPosition = this.text_overlay_formObj[this.textOverlayCounter].get('overlayXPosition');

    // legacy case handle
    if (xPosition == 'left' || xPosition == 'right' || xPosition == 'middle') {
      this.text_overlay_formObj[this.textOverlayCounter].get('overlayXPosition').setValue(0);
      this.text_overlay_formObj[this.textOverlayCounter].get('overlayYPosition').setValue(0);
    } else {
      this.text_overlay_formObj[this.textOverlayCounter].get('overlayXPosition').setValue(textOverObj['x']);
      this.text_overlay_formObj[this.textOverlayCounter].get('overlayYPosition').setValue(textOverObj['y']);
    }

    this.text_overlay_formObj[this.textOverlayCounter].get('overlayEditX').setValue(Math.floor(this.overlayXvalue));
    this.text_overlay_formObj[this.textOverlayCounter].get('overlayEditY').setValue(Math.floor(this.overlayYvalue));
    this.text_overlay_formObj[this.textOverlayCounter].get('textOverStartTime').setValue(Number(textOverObj['from']));
    this.text_overlay_formObj[this.textOverlayCounter].get('textOverEndTime').setValue(textOverObj['to']);
    if (textOverObj['box'] == 1) {
      this.text_overlay_formObj[this.textOverlayCounter].get('overlayBox').setValue(true);
    } else {
      this.text_overlay_formObj[this.textOverlayCounter].get('overlayBox').setValue(false);
    }
    let text_overlay_Obj = this.text_overlay_formObj[this.textOverlayCounter].value;
    text_overlay_Obj['title'] = overlay_title;
    text_overlay_Obj['id'] = this.textOverlayCounter;
    this.getTextOverlayCanvas(text_overlay_Obj);

    this.text_overlay_Array.push(text_overlay_Obj);
  }


  getTextOverlayCanvas(overlayObj): void {
    // create canvas
    const canvasContainer = this.canvasRenderer.createElement('div');
    this.canvasRenderer.setProperty(canvasContainer, 'id', this.textOverlayCounter);
    this.canvasRenderer.setStyle(canvasContainer, 'height', 'max-content');
    this.canvasRenderer.setStyle(canvasContainer, 'width', 'max-content');
    this.canvasRenderer.setStyle(canvasContainer, 'line-height', 'normal');
    if (overlayObj['overlayBox']) {
      this.canvasRenderer.setStyle(canvasContainer, 'background-color', `#${overlayObj['boxColor']['hex']}`);  // box color
    } else {
      this.canvasRenderer.setStyle(canvasContainer, 'background', 'none');  // box color
    }

    this.canvasRenderer.setStyle(canvasContainer, 'position', 'absolute');
    let fontSize;
    if (overlayObj['overlayFontSize'] == 'small') {
      fontSize = this.videoPlayer.nativeElement.offsetHeight / 20;
    } else if (overlayObj['overlayFontSize'] == 'large') {
      fontSize = this.videoPlayer.nativeElement.offsetHeight / 10;
    } else {
      fontSize = this.videoPlayer.nativeElement.offsetHeight / 15;
    }
    this.canvasRenderer.setStyle(canvasContainer, 'text-align', 'left');
    this.canvasRenderer.setStyle(canvasContainer, 'font-family', 'font1');
    this.canvasRenderer.setStyle(canvasContainer, 'font-size', `${fontSize}px`);
    this.canvasRenderer.setStyle(canvasContainer, 'color', `#${overlayObj['fontColor']['hex']}`);  // box color
    // let temp_text: String = overlayObj['overlayText'].replaceAll('\n', '<br>').replaceAll(' ', '&nbsp;')
    // this.canvasRenderer.setProperty(canvasContainer, 'innerHTML', `${temp_text}`);

    this.canvasRenderer.appendChild(document.getElementById('canvasDiv'), canvasContainer);

    // creating child component dynamically for scroll
    const childComponent = this.componentFactoryResolver.resolveComponentFactory(TextOverlayDragDropComponent);
    this.overlay_child_Obj[this.textOverlayCounter] = this.dragDiv.createComponent(childComponent);
    // const loaderComponentElement = this.overlay_child_Obj[this.textOverlayCounter].location.nativeElement;
    // loaderComponentElement.setAttribute('style', 'width:inherit');
    // this.dragDiv.insert(Component.TooltipContainerComponent)


    // assigning overlay object value to child component
    this.overlay_child_Obj[this.textOverlayCounter].instance.id = this.textOverlayCounter;
    this.overlay_child_Obj[this.textOverlayCounter].instance.fontSize = `${fontSize}px`;
    this.overlay_child_Obj[this.textOverlayCounter].instance.overlayObj = overlayObj;
    this.overlay_child_Obj[this.textOverlayCounter].instance.noBackground = overlayObj['overlayBox'];

    //triggers when text overlay is scrolled
    this.overlay_child_Obj[this.textOverlayCounter].instance.onDrag.subscribe((val: any) => {
      let xCoordinate = (((val.x - this.player.getBoundingClientRect().x) / this.player.clientWidth) * this.player.videoWidth) + 15;
      let yCooridnate = (((val.y - this.player.getBoundingClientRect().y) / this.player.clientHeight) * this.player.videoHeight) + 15;
      this.text_overlay_formObj[val.id].get('overlayXPosition').setValue(xCoordinate);
      this.text_overlay_formObj[val.id].get('overlayYPosition').setValue(yCooridnate);
    });

    this.overlay_child_Obj[this.textOverlayCounter].instance.selectTextBox.subscribe((val: any) => {
      this.overlay_child_Obj[this.textOverlayCounter].instance.textBoxId = val.id;
      this.expansionTextBoxselected(val.id);
      this.overlay_child_Obj[this.textOverlayCounter].instance.ngOnChanges();
    })
    //passing video size to child component
    let videoSize = {
      'width': this.player.clientWidth,
      'height': this.player.clientHeight
    }
    this.overlay_child_Obj[this.textOverlayCounter].instance.videoSize = videoSize;

    // end canvas
  }

  setBlurBoxesfromAPIresp(blurObj) {
    this.shapeOverlayCounter++;
    // this.inputEnd.focus();
    let new_form = this.formBuilder.group({
      shapeOverStartTime: [''],
      shapeOverEndTime: [''],
      shapeWidth: [''],
      shapeHeight: [''],
      overlayXPosition: [''],
      overlayYPosition: [''],
    })

    // data in the absolute numbers, make it relative
    var x = blurObj['x'] * this.videoDownscaleHeightFactor;
    var y = blurObj['y'] * this.videoDownscaleWidthFactor;
    var height = blurObj['height'] * this.videoDownscaleHeightFactor;
    var width = blurObj['width'] * this.videoDownscaleWidthFactor;

    // Shape overlay initialization
    new_form.get('overlayXPosition').setValue(x);
    new_form.get('overlayYPosition').setValue(y);
    new_form.get('shapeWidth').setValue(width);
    new_form.get('shapeHeight').setValue(height);
    new_form.get('shapeOverStartTime').setValue(Number(blurObj['from']));
    new_form.get('shapeOverEndTime').setValue(Number(blurObj['to']));

    let shape_overlay_Obj = new_form.value;
    shape_overlay_Obj['id'] = this.shapeOverlayCounter;
    // draw a new component
    this.setResizeOverlay(shape_overlay_Obj);
    this.shape_overlay_formObj.push(new_form);
    this.shape_overlay_Array.push(shape_overlay_Obj);
  }

  setResizeOverlay(shapeOverlayObj): void {
    // this.inputEnd.focus();
    // this will get the values, not the form data
    // creating child component dynamically for scroll
    const childComponent = this.componentFactoryResolver.resolveComponentFactory(ShapeOverlayDivComponent);
    this.new_child = this.resizeDiv.createComponent(childComponent);
    //passing video size to child component
    let videoSize = {
      'width': this.player.clientWidth,
      'height': this.player.clientHeight
    }
    let videoNativeSize = {
      'width': this.videoPlayer.nativeElement.getBoundingClientRect().x,
      'height': this.videoPlayer.nativeElement.getBoundingClientRect().y
    }
    this.new_child.instance.videoSize = videoSize;
    this.new_child.instance.videoNativeSize = videoNativeSize;

    // assigning overlay object value to child component
    // new_child.instance.id = this.shape_overlay_Array.length;
    this.new_child.instance.shapeoverlayObj = shapeOverlayObj;
    this.new_child.instance.id = this.textOverlayCounter;

    //triggers when blur box is scrolled
    this.new_child.instance.onDrag.subscribe((val: any) => {
      // let xCoordinate = (((val.x - this.player.getBoundingClientRect().x) / this.player.clientWidth) * this.player.videoWidth);
      // let yCooridnate = (((val.y - this.player.getBoundingClientRect().y) / this.player.clientHeight) * this.player.videoHeight);
      shapeOverlayObj['overlayXPosition'] = val.x - this.videoPlayer.nativeElement.getBoundingClientRect().x;
      shapeOverlayObj['overlayYPosition'] = val.y - this.videoPlayer.nativeElement.getBoundingClientRect().y;
    });

    //triggers when blur box resized
    this.new_child.instance.onResize.subscribe((val: any) => {
      shapeOverlayObj['overlayXPosition'] = val.x - this.videoPlayer.nativeElement.getBoundingClientRect().x;
      shapeOverlayObj['overlayYPosition'] = val.y - this.videoPlayer.nativeElement.getBoundingClientRect().y;
      shapeOverlayObj['shapeWidth'] = Math.floor(val.width);
      shapeOverlayObj['shapeHeight'] = Math.floor(val.height);
    })
    this.new_child.instance.selectBlur.subscribe((val: any) => {
      this.new_child.instance.blurId = val.id;
      this.shapeOverlaySelected(val.id);

      //   this.resize_child_Obj[val.id].instance.update('border', '2px solid navajowhite');
      this.new_child.instance.ngOnChanges();
    })
    this.resize_child_Obj.push(this.new_child);
    // end canvas
  }

  expansionselected(index, item) {
    this.player.currentTime = item.shapeOverStartTime;
    this.playerBOTH.currentTime = item.shapeOverStartTime;
    this.activeIndex = index;
    for (let i = 0; i < this.resize_child_Obj.length; i++) {
      if (i == index) {
        this.resize_child_Obj[index].instance.update('border', '2px solid navajowhite');
        this.resize_child_Obj[index].instance.updateResize('border', 'navajowhite');
      } else {
        this.resize_child_Obj[i].instance.update('border', 'none');
        this.resize_child_Obj[i].instance.updateResize('border', 'transparent');
      }
    }
    this.new_child.instance.ngOnChanges();
    this.shapeClicked = !this.shapeClicked;
    // this.resizeBarIndex = index;
  }

  shapeOverlaySelected(index) {
    for (var i = 0; i < this.shape_overlay_Array.length; i++) {
      if (this.shape_overlay_Array[i].id == index) {
        this.ShapetabIndex = i;
        this.activeIndex = i;
      }
    }

    if (!this.blur_tab) {
      this.tabClicked('blur_tab');
    }

  }

  textexpansionselected(item) {
    this.player.currentTime = item.textOverStartTime;
    this.playerBOTH.currentTime = item.textOverStartTime;
  }

  expansionTextBoxselected(index) {
    for (var i = 0; i < this.text_overlay_Array.length; i++) {
      if (this.text_overlay_Array[i].id == index) {
        this.activeTextOverlay = i;
        this.textTabIndex = i;
      }
    }
    if (!this.textOverlay_tab) {
      this.tabClicked('textOverlay_tab');
    }
  }

  closeExpansion(index, item) {
    this.resize_child_Obj[index].instance.update('border', 'none');
    this.resize_child_Obj[index].instance.updateResize('border', 'transparent')
    // this.resize_child_Obj[index].instance.ngOnInit();
    this.new_child.instance.ngOnChanges();
  }


  VideoTrimBarState() {
    let startValue = this.vidValue['edit_parameters'].start;
    if (this.vidValue['edit_parameters'].delete_Array != null) {
      this.deletedValueArray = this.vidValue['edit_parameters'].delete_Array;
      this.deletedValueArray.map((data) => {
        let arr1 = [];
        data.map((val) => {
          let myarray = val.toFixed(1);
          arr1.push(Number(myarray));
        });
        this.deleteArr.push(arr1);
      });
      this.trimfromEdit = startValue.concat(this.deletedValueArray);
    } else {
      this.trimfromEdit = startValue;
    }

    for (var i = 0; i < this.trimfromEdit.length; i++) {
      let trimOuterArray = this.trimfromEdit[i];
      for (var j = 0; j < trimOuterArray.length; j++) {
        this.trimValue_Edit = this.trimfromEdit[i][j];
        this.splitVideo();
        break;
      }
      this.trimValue_Edit = undefined;
    }
    if (this.vidValue['edit_parameters'].delete_Array != null) {
      let mapArray = this.tiles.map((data1, index1) => {
        let innerArray = this.deleteArr.map((data2, index2) => {
          if ((data1.start).toFixed(3) === (data2[0]).toFixed(3) && (data1.end).toFixed(3) === (data2[1]).toFixed(3)) {
            this.tiles[index1]['color'] = this.redColor;
          }
        })
      });
    }
  }

  checkDomainAndStop(): void {

    const changed = this.currentTempTime !== this.player.currentTime;

    this.currentTempTime = this.player.currentTime;

    if (changed) {
      this.updateCurrentTime();
    }


  }

  toHHMMSS(val: number): string {
    const secNum = val;
    const minutes = Math.floor(secNum / 60);
    const seconds = Math.floor(secNum - (minutes * 60));
    const milliseconds = Math.round((secNum - seconds) * 1000);

    const minutesString = ('0' + minutes).slice(-2);
    const secondsString = ('0' + seconds).slice(-2);
    const millisecondsString = ('00' + milliseconds).slice(-3, -2);

    return minutesString + ':' + secondsString + '.' + millisecondsString;
  }

  stopVideo(): void {
    this.player.stop();
    this.playerBOTH.stop();
  }

  pauseVideo(): void {
    this.player.pause();
    this.playerBOTH.pause();
    if (this.audioURL) {
      this.audioPlayer.nativeElement.pause();
    }
  }

  playVideo(): void {
    this.player.play();
    this.playerBOTH.play();
    if (parseFloat(this.player.duration).toFixed(1) == this.player.currentTime) {
      this.player.currentTime = 0;
      this.playerBOTH.currentTime = 0;
      this.audioPlayer.nativeElement.currentTime = 0;
    }

    if (this.audioURL) {
      this.audioPlayer.nativeElement.play();
    }
  }

  timeUpdated(): void {
    if (this.player) {
      this.videoContainerHeight = {
        'padding-top': '0px',
        'height': `${this.player.clientHeight}px`,
      }
    }
    if (this.text_overlay_Array.length > 0) {
      this.updateTextOverlayVisibility();
    }
    this.updateShapeOverlayVisibility();

    // this function need not to be called when recording audio:
    this.updateCurrentTime();
    if (this.selectRecordAudio) {
      if (this.player.duration <= this.player.currentTime) {
        if (this.audioRecorderService.recording()) {
          this.stopAudioRecording();
        }
      }
      return;
    }
    for (let i in this.tiles) {
      if (this.player && this.player.currentTime >= this.tiles[i]['start'] && this.player.currentTime < this.tiles[i]['end']) {
        if (this.tiles[i]['color'] != this.redColor) {
          // play this tile
          // tile found
          if (this.icon != 'volume_off')
            this.player.muted = false;
          else
            this.player.muted = true;
          if (this.audioURL) {
            // play the audio player as per audio_start_time and audio_end_time information
            if (this.player.currentTime >= this.audio_start_time && this.player.currentTime <= this.audio_end_time) {
              // this.audioPlayer.nativeElement.currentTime = this.player.currentTime - this.audio_start_time;
              if (!this.player.paused) {
                this.audioPlayer.nativeElement.play();
              }
              if (this.icon != 'volume_off') {
                this.player.muted = true;
                this.audioPlayer.nativeElement.muted = false;
              } else {
                this.player.muted = true;
                this.audioPlayer.nativeElement.muted = true;
              }
            } else {
              this.audioPlayer.nativeElement.muted = true;
              this.audioPlayer.nativeElement.pause();
            }
          }
        } else {
          // go to next tile
          try {
            // update the current times to end of this tile or start of next tile
            this.player.currentTime = this.tiles[i]['end'];
            this.playerBOTH.currentTime = this.tiles[i]['end'];
            this.audioPlayer.nativeElement.currentTime = this.tiles[i]['end'] - this.audio_start_time;
          } catch (e) {
            console.log(e);
          }
        }
      }
    }


  }

  updateTextOverlayVisibility() {
    let objKeysArray = Object.keys(this.text_overlay_formObj);
    for (let i in objKeysArray) {
      let overlayObj = this.text_overlay_formObj[objKeysArray[i]].value;
      if (this.player && overlayObj['textOverStartTime'] <= this.player.currentTime && overlayObj['textOverEndTime'] > this.player.currentTime) {
        const canvasContainer = <HTMLCanvasElement>document.getElementById(this.text_overlay_Array[i]['id']);
        this.canvasRenderer.setStyle(canvasContainer, 'visibility', `visible`);

        this.overlay_child_Obj[this.text_overlay_Array[i]['id']].instance.visibility = true;
        this.overlay_child_Obj[this.text_overlay_Array[i]['id']].instance.ngOnInit();
      } else {
        const canvasContainer = <HTMLCanvasElement>document.getElementById(this.text_overlay_Array[i]['id']);

        this.canvasRenderer.setStyle(canvasContainer, 'visibility', `hidden`);
        this.overlay_child_Obj[this.text_overlay_Array[i]['id']].instance.visibility = false;
        this.overlay_child_Obj[this.text_overlay_Array[i]['id']].instance.ngOnInit();
      }
    }
  }

  updateShapeOverlayVisibility() {
    for (var index in this.shape_overlay_Array) {
      if (this.shape_overlay_Array[index]['shapeOverStartTime'] <= this.player.currentTime &&
        this.shape_overlay_Array[index]['shapeOverEndTime'] >= this.player.currentTime) {
        this.resize_child_Obj[index].instance.update('visibility', 'visible');
      } else {
        this.resize_child_Obj[index].instance.update('visibility', 'hidden');
      }
    }
    // let shapeKeysArray = Object.keys(this.shape_overlay_formObj);
    // for (let i in shapeKeysArray) {
    //     let shapeOverlayObj = this.shape_overlay_formObj[shapeKeysArray[i]].value;
    //     if (this.player && shapeOverlayObj['shapeOverStartTime'] <= this.player.currentTime && shapeOverlayObj['shapeOverEndTime'] > this.player.currentTime) {
    //         // this.overlay_child_Obj[this.shape_overlay_Array[i]['id']].instance.visibility = true;
    //         this.resize_child_Obj[this.shape_overlay_Array[i]['id']].instance.ngOnInit();
    //     } else {
    //         // this.overlay_child_Obj[this.shape_overlay_Array[i]['id']].instance.visibility = false;
    //         this.resize_child_Obj[this.shape_overlay_Array[i]['id']].instance.ngOnInit();
    //     }
    // }
  }

  backwardVideo(): void {
    this.currentTempTime -= 5;
    if (this.currentTempTime < 0) {
      this.player.currentTime = 0;
      this.playerBOTH.currentTime = 0;
    } else {
      this.player.currentTime = this.currentTempTime;
      this.playerBOTH.currentTime = this.currentTempTime;
    }
    this.updateCurrentTime();
  }

  forwardVideo(): void {
    this.currentTempTime += 5;
    if (this.currentTempTime > this.videoDuration) {
      this.player.currentTime = this.videoDuration;
      this.playerBOTH.currentTime = this.videoDuration;
    } else {
      this.player.currentTime = this.currentTempTime;
      this.playerBOTH.currentTime = this.currentTempTime;
    }
    this.updateCurrentTime();
  }

  updateCurrentTime(): void {
    // update the timer
    if (this.player) {
      this.videoContainerHeight = {
        'padding-top': '0px',
        'height': `${this.player.clientHeight}px`,
      }
      this.currentTempTime = this.player.currentTime;
      this.currentTime.nativeElement.innerHTML =
        this.toHHMMSS(Math.round(this.currentTempTime * 10) / 10) +
        ' | ' + this.toHHMMSS((this.player.duration).toFixed(1));

      this.seekerValue = this.player.currentTime * 10;
    }
  }

  processVideo(justUpdateEditParameters = false): void {
    let observable;
    // this.pauseVideo();  // this is not needed as we now go to a new page

    let trimmedArray = [];
    let deletedArray = [];
    // overlay text
    let draw_texts = [];
    let blur_boxes = [];
    let objKeysArray = Object.keys(this.text_overlay_formObj);
    for (let i in objKeysArray) {
      let overlayTextObj = {};
      if (this.text_overlay_formObj[objKeysArray[i]].get('overlayBox').value) {
        overlayTextObj['box'] = 1;
      } else {
        overlayTextObj['box'] = 0;
      }

      overlayTextObj['boxcolor'] = `#${this.text_overlay_formObj[objKeysArray[i]].get('boxColor').value.hex}`;
      overlayTextObj['fontcolor'] = `#${this.text_overlay_formObj[objKeysArray[i]].get('fontColor').value.hex}`;
      overlayTextObj['fontsize'] = this.text_overlay_formObj[objKeysArray[i]].get('overlayFontSize').value;
      overlayTextObj['x'] = Math.floor(this.text_overlay_formObj[objKeysArray[i]].get('overlayXPosition').value);
      overlayTextObj['y'] = Math.floor(this.text_overlay_formObj[objKeysArray[i]].get('overlayYPosition').value);
      overlayTextObj['from'] = Number(this.text_overlay_formObj[objKeysArray[i]].get('textOverStartTime').value);
      overlayTextObj['to'] = Number(this.text_overlay_formObj[objKeysArray[i]].get('textOverEndTime').value);
      overlayTextObj['text'] = this.text_overlay_formObj[objKeysArray[i]].get('overlayText').value;
      overlayTextObj['alpha'] = Number(this.text_overlay_formObj[objKeysArray[i]].get('alpha').value);
      draw_texts.push(overlayTextObj);
    }

    //blur boxes
    for (var box of this.shape_overlay_Array) {
      let overlayBlurObj = {};
      overlayBlurObj['x'] = Math.floor(box['overlayXPosition'] * this.videoUpscaleHeightFactor);
      overlayBlurObj['y'] = Math.floor(box['overlayYPosition'] * this.videoUpscaleWidthFactor);
      overlayBlurObj['height'] = Math.floor(box['shapeHeight'] * this.videoUpscaleHeightFactor);
      overlayBlurObj['width'] = Math.floor(box['shapeWidth'] * this.videoUpscaleWidthFactor);
      overlayBlurObj['from'] = Number(box['shapeOverStartTime']);
      overlayBlurObj['to'] = Number(box['shapeOverEndTime']);
      blur_boxes.push(overlayBlurObj);
    }

    // end
    for (let i in this.tiles) {
      if (this.tiles[i].color != this.redColor && this.tiles[i].start != this.tiles[i].end) {
        trimmedArray.push([+(this.tiles[i].start), +(this.tiles[i].end)]);
      } else if (this.tiles[i].color == this.redColor && this.tiles[i].start != this.tiles[i].end) {
        deletedArray.push([this.tiles[i].start, this.tiles[i].end]);
      }
    }

    let formBody = {
      start: trimmedArray,
      speed: this.speed,
      audio_vol: this.audio_vol,
      video_bg: this.video_bg,
      // noise_red: this.noise_red,
      noise_sup: this.noise_sup,
      overlay_code: this.overlay_code,
      ci_video_code: this.ci_video_code,
      delete_Array: deletedArray,
      draw_texts: draw_texts,
      blur_boxes: blur_boxes,
      base_video: this.base_video
    }
    if (draw_texts.length > 0) {
      formBody['draw_texts'] = draw_texts;
    }
    if (blur_boxes.length > 0) {
      formBody['blur_boxes'] = blur_boxes;
    }

    if (this.audio_end_time > 0) {
      // only send the start and end time if audio is recorded
      formBody['audio_start_time'] = this.audio_start_time;
      formBody['audio_end_time'] = this.audio_end_time;
    }

    if (this.notesChangeValue != null || this.notesChangeValue != "") {
      formBody['script'] = this.notesChangeValue;
    }

    let newFormBody = {};
    if(justUpdateEditParameters){
       newFormBody = {
        'edit_parameters': formBody
      }
    }
    else{
      newFormBody = formBody;
    }

    // PR case has new video processing method
    if(this.vidValue['type']=='PR'){
      if(justUpdateEditParameters){
        // in case of PR mode, send a PUT call with new edit parameters
        this.dataService.putURL(`user/videos/${this.vidValue['id']}/`, newFormBody, {}).subscribe();
      }
      else{
        this.dataService.postURL<VideoView>(`user/videos/${this.vidValue['id']}/medias/`, newFormBody,
        {observe: 'body', responseType: 'json'}).subscribe((res) => {
          this.router.navigate(['create-video/' + this.vidValue['id'] + '/review']);
          });
      }
    }

    else{
      observable = this.httpClient.put(
        `${environment.backendURL}/user/videos/${this.vidValue['id']}/`,
        newFormBody,
        {responseType: 'text'});

      this.showProgressSpinnerUntilExecuted(observable);
    }
  }

  showProgressSpinnerUntilExecuted(observable: Observable<any>): void {
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });
    const subscription = observable.subscribe(
      (res) => {
        let resData = JSON.parse(res);
        this.urlVideo = resData['processed_file'];

        if (resData['type'] === 'BO') {
          if (resData['overlay_video'] === null) {
            var action = 'Fertig';
            this.snackBar.open(
              this.translateService.instant('Verbindung unterbrochen'),
              action,
              {duration: 2500}
            );
          }
        }
        if (resData['video_file'] === null) {
          var action = 'Fertig';
          this.snackBar.open(
            this.translateService.instant('Verbindung unterbrochen'),
            action,
            {duration: 2500}
          );
        }

        if (res?.response == 'failure') {
          window.alert(res.reason);
        } else {
          this.router.navigate(['create-video/' + this.vidValue['id'] + '/review']);
        }
        dialogRef.close();
      },
      (err: HttpErrorResponse) => {
        var action = 'Fertig';
        this.snackBar.open(
          this.translateService.instant('Verbindung unterbrochen'),
          action,
          {duration: 2500}
        );
        dialogRef.close();
      }
    );
  }

  reset(): void {
    this.slider.slider.set([0, (this.player.duration).toFixed(1)]);
  }

  //new design
  tabClicked(event) {
    if (this.new_child != undefined) {
      for (let i = 0; i < this.resize_child_Obj.length; i++) {
        this.resize_child_Obj[i].instance.update('border', 'none');
        this.resize_child_Obj[i].instance.updateResize('border', 'transparent');
      }
      this.new_child.instance.ngOnChanges();
    }
    this.edit_tab = false;
    this.textOverlay_tab = false;
    this.blur_tab = false;
    switch (event) {
      case 'edit_tab':
        this.edit_tab = true;
        break;
      case 'textOverlay_tab':
        this.textOverlay_tab = true;
        // if (this.activeTextOverlay != undefined) {
        //     this.activeTextOverlay = this.textTabIndex;
        // }
        break;
      case 'blur_tab':
        this.blur_tab = true;
        // this.activeIndex = this.ShapetabIndex;
        if (this.new_child != undefined) {
          // this.expansionselected(this.activeIndex);
          if (this.activeIndex != undefined) {
            this.resize_child_Obj[this.activeIndex].instance.update('border', '2px solid navajowhite');
            this.resize_child_Obj[this.activeIndex].instance.updateResize('border', '2px solid navajowhite');
            this.new_child.instance.ngOnChanges();
          } else {
            for (let i = 0; i < this.resize_child_Obj.length; i++) {
              this.resize_child_Obj[i].instance.update('border', 'none');
              this.resize_child_Obj[i].instance.updateResize('border', 'transparent');
            }
            this.new_child.instance.ngOnChanges();
          }
        }
        break;
    }
  }

  audioToggle(): void {
    if (this.icon === 'volume_off') {
      this.icon = 'volume_up';
      this.audio_vol = 1;
      this.iconText = this.mic_on_text;
      this.player.muted = false;
      this.playerBOTH.muted = false;
      this.audioPlayer.muted = false;
    } else {
      this.icon = 'volume_off';
      this.audio_vol = 0;
      this.iconText = this.mic_off_text;
      // this.setBars({value: 0});
      this.player.muted = true;
      this.playerBOTH.muted = true;
      this.audioPlayer.muted = false;
      this.noise_sup = false;
      this.noiseIcon = 'noise_control_off';
    }
  }

  noiseToggle() {
    if (this.noiseIcon === 'noise_control_off') {
      this.noiseIcon = 'noise_aware';
      this.noiseIconText = this.noise_on_text;
      this.noise_sup = true;
    } else {
      this.noiseIcon = 'noise_control_off';
      this.noiseIconText = this.noise_off_text;
      this.noise_sup = false;
    }
  }

  webcamPosition(label): void {
    this.webcamObj['ai-left'] = false;
    this.webcamObj.tr = false;
    this.webcamObj.bl = false;
    this.webcamObj.br = false;
    this.webcamObj['ai-right'] = false;
    this.webcamObj[label] = true;

    switch (label) {
      case 'ai-left':
        this.overlay_code = 'ai-left';
        break;
      case 'tr':
        this.overlay_code = 'tr';
        break;
      case 'bl':
        this.overlay_code = 'bl';
        break;
      case 'br':
        this.overlay_code = 'br';
        break;
      case 'ai-right':
        this.overlay_code = 'ai-right';
        break;
    }
  }

  // noiseRedSelected(label): void {
  //     this.noiseRedObj.none = false;
  //     this.noiseRedObj.low = false;
  //     this.noiseRedObj.medium = false;
  //     this.noiseRedObj.high = false;
  //     this.noiseRedObj[label] = true;

  //     switch (label) {
  //         case 'none':
  //             this.noise_red = 0;
  //             this.noiseToolTip = this.translateService.instant("Wenn du in einem weitestgehend ruhigen Umfeld bist");
  //             break;
  //         case 'low':
  //             this.noise_red = 0.1;
  //             this.noiseToolTip = this.translateService.instant("Wenn du in einem Raum mit wenigen, störenden Geräuschen bist");
  //             break;
  //         case 'medium':
  //             this.noise_red = 0.2;
  //             this.noiseToolTip = this.translateService.instant("Wenn du in einem Raum mit mehreren, störenden Geräuschen bist");
  //             break;
  //         case 'high':
  //             this.noise_red = 0.4;
  //             this.noiseToolTip = this.translateService.instant("Wenn du dich in einem lauten Umfeld befindest (beeinflusst ggf. die Audioqualität)");
  //             break;
  //     }
  // }

  backgroundSelected(label): void {
    this.video_bgObj.orig = false;
    this.video_bgObj.blur = false;
    this.video_bgObj.cust = false;
    this.video_bgObj[label] = true;
    this.video_bg = label;
  }

  // trim video
  splitVideo(): void {
    if (this.trimValue_Edit != undefined) {
      this.trimCurrentTime = this.trimValue_Edit;
    } else {
      this.trimCurrentTime = parseFloat((this.player.currentTime).toFixed(1));
    }
    let currentTime = this.trimCurrentTime;
    let addiationTimestamp = 0;

    for (let i in this.timeStampArray) {

      let newItem = currentTime - addiationTimestamp;
      addiationTimestamp += this.timeStampArray[i];

      if (addiationTimestamp > currentTime) {
        this.timeStampArray.splice(parseInt(i), 0, parseFloat(newItem.toFixed(1)));

        this.timeStampArray[parseInt(i) + 1] = parseFloat((addiationTimestamp - currentTime).toFixed(1));
        this.tiles.splice(parseInt(i), 0, {
          cols: 0,
          rows: 1,
          color: this.tiles[i]?.color,
          start: 0,
          end: 0
        });
        for (let i in this.tiles) {
          this.tiles[i]['bordercolor'] = this.transparentColor;
        }
        break;
      }
    }

    for (let i in this.timeStampArray) {
      this.tiles[i]['cols'] = this.timeStampArray[i] * 10;
      if (parseInt(i) != 0) {
        this.tiles[i]['start'] = this.sumOfTimestamp(parseInt(i) - 1);
        this.tiles[i]['end'] = this.timeStampArray[i] + this.sumOfTimestamp(parseInt(i) - 1);
      } else {
        this.tiles[i]['start'] = 0;
        this.tiles[i]['end'] = this.timeStampArray[i];
      }
    }

    this.showDeleteSelectedBtn = false;
    this.showDiscardButton = false;
  }

  sumOfTimestamp(num) {
    if (num == 0) {
      return this.timeStampArray[0];
    }
    return this.timeStampArray[num] + this.sumOfTimestamp(num - 1);
  }

  trimBarSelected(tileObj, index): void {
    this.shapeClicked = !this.shapeClicked;
    this.activeIndex = index;
    this.shape_overlay_Array = this.shape_overlay_Array;
    this.opened = !this.opened;
    this.trimbarIndex = index;
    if (this.tiles.length > 1) {

      for (let i in this.tiles) {
        this.tiles[i]['bordercolor'] = this.transparentColor;
        if (this.tiles[i]['color'] == this.greenColor) {
          this.tiles[i]['color'] = this.greyColor;
        }
      }
      if (tileObj['color'] == this.greenColor || tileObj['color'] == this.greyColor) {
        this.showDeleteSelectedBtn = true;
        this.selectedSectionIndex = index;
        this.tiles[index]['color'] = this.greenColor;
        this.tiles[index]['bordercolor'] = this.blackColor;
        this.showDiscardButton = false;
      } else {
        this.tiles[index]['bordercolor'] = this.blackColor;
        this.showDeleteSelectedBtn = false;
        this.showDiscardButton = true;
        this.selectedSectionIndex = index;
      }
    }
  }

  deleteSelectedSection() {
    this.tiles[this.selectedSectionIndex]['color'] = this.redColor;
    this.tiles[this.selectedSectionIndex]['bordercolor'] = this.transparentColor;
    this.showDeleteSelectedBtn = false;
  }

  discardSelectedSection() {
    this.tiles[this.selectedSectionIndex]['color'] = this.greyColor;
    this.tiles[this.selectedSectionIndex]['bordercolor'] = this.transparentColor;
    this.showDiscardButton = false;
  }

  resetTrimmer() {
    if (confirm('Alle Schnittpunkte zurücksetzen?')) {
      // clear it!
      this.selectedSectionIndex = 0;
      this.tiles = [];
      this.tiles = [{
        cols: this.player.duration * 10,
        rows: 1,
        color: this.greyColor,
        start: 0,
        end: +(this.player.duration).toFixed(1)
      }];
      this.timeStampArray = [];
      this.timeStampArray.push((this.player.duration).toFixed(1));
    }
  }

  // end trimmer
  // range input
  onChangeSeekerTime($event): void {
    this.currentTempTime = $event / 10;
    this.player.currentTime = $event / 10;
    this.playerBOTH.currentTime = $event / 10;
    if (this.audioURL) {
      // update audio player time
      if (this.player.currentTime >= this.audio_start_time && this.player.currentTime <= this.audio_end_time) {
        this.audioPlayer.nativeElement.currentTime = this.player.currentTime - this.audio_start_time;
      } else {
        this.audioPlayer.nativeElement.currentTime = 0;
      }
    }
    this.updateCurrentTime();
  }

  // audio overlay
  async startAudioRecording(): Promise<void> {
    this.checkPermissions();

    this.pauseVideo();
    this.player.muted = true;
    this.disableSeeker = true;
    this.arecorder = await this.audioRecorderService.recordAudio();
    const audioContext = new AudioContext();
    this.file = undefined;

    await this.playDelay();
    const mediaStream = this.arecorder.start();
    const mediaStreamSource = audioContext.createMediaStreamSource(mediaStream);
    this.audioMeter = audioMeter.createAudioMeter(audioContext);
    mediaStreamSource.connect(this.audioMeter);
    this.playVideo();
    this.audio_start_time = parseFloat(this.player.currentTime);
    if (!this.disableAudio)
      this.onLevelChange();
  }

  async stopAudioRecording(): Promise<void> {
    if (this.arecorder) {
      this.pauseVideo();
      this.player.muted = false;
      this.audio_end_time = parseFloat(this.player.currentTime);
      let audioStopRecorder = await this.arecorder.stop();
      this.arecorder = null;
      this.audioLow.play();
      if (!this.disableAudio) {
        this.meter.nativeElement
          .getContext('2d')
          .clearRect(0, 0, this.WIDTH, this.HEIGHT);
        this.audioMeter.shutdown();
      }
      // file save
      let file = new File([audioStopRecorder.audioBlob], 'audioRecording', {
        type: 'audio/wav',
      });
      this.file = file;
      this.formData = new FormData();
      this.formData.append('overlay_audio', file, `${new Date().getTime()}.wav`);
      this.audioURL = this.sanitizer.bypassSecurityTrustUrl(audioStopRecorder.audioUrl);
      this.selectRecordAudio = false;
      let observable;
      observable = this.httpClient.put(
        `${environment.backendURL}/user/videos/${this.vidValue['id']}/`,
        this.formData,
        {responseType: 'text'});
      observable.subscribe((res: any) => {
      });
      this.disableSeeker = false;
    }

  }

  onLevelChange = (time = 0) => {
    if (this.audioRecorderService.recording()) {
      const context = this.meter.nativeElement.getContext('2d');

      context.clearRect(0, 0, this.WIDTH, this.HEIGHT);
      if (!this.audioMeter.checkClipping()) {
        context.fillStyle = 'green';
      }
      context.fillRect(
        0,
        0,
        this.audioMeter.volume * this.WIDTH * 1.4,
        this.HEIGHT
      );
      window.requestAnimationFrame(this.onLevelChange);
    }
  }

  onAudioDropChange(event) {
    let DeviceId = event.value.deviceId;
    this.audioRecorderService.getSelectionVal(DeviceId);
  }

  toggleAudio() {
    if (this.audioIcon === 'mic') {
      this.micVal = false;
      this.audioIcon = 'mic_off';
      this.disableAudio = true;
      this.audioRecorderService.getAudioToggle(false);
    } else {
      this.micVal = true;
      this.audioIcon = 'mic';
      this.disableAudio = false;
      this.audioRecorderService.getAudioToggle(true);
    }
  }

  selectRecordAudioOpt() {
    if (!this.audioRecorderService.recording()) {
      // i am not recording any audio, go back to prev state
      this.selectRecordAudio = !this.selectRecordAudio;
      // audio input dropdown value
      this.audioRecorderService.getAudioVal();
      this.audioRecorderService.audioVal.subscribe((data) => {
        this.audioinputVal = data;
        this.defaultValue = this.audioinputVal[0];
      });
    }
  }

  resetAudio() {
    this.audioURL = undefined;
    this.selectRecordAudio = false;
    this.formData = new FormData();
    this.audio_start_time = 0;
    this.audio_end_time = 0;
  }

  clickOutside() {
    this.showDeleteSelectedBtn = false;
    this.tiles[this.trimbarIndex]['bordercolor'] = this.transparentColor;
    this.opened = !this.opened;
  }

  clickOutsideShapeOverlay() {
    this.shapeClicked = !this.shapeClicked;
    if (this.new_child != undefined) {
      for (let i = 0; i < this.resize_child_Obj.length; i++) {
        this.resize_child_Obj[i].instance.update('border', 'none');
        this.resize_child_Obj[i].instance.updateResize('border', 'transparent');
      }
      this.new_child.instance.ngOnChanges();
    }
  }

  checkPermissions() {
    navigator.mediaDevices.enumerateDevices().then(devices =>
      devices.forEach(device => {
        if (device.kind === 'audioinput') {
          if (device.label != "") {
            this.permissionDenied = false;
          } else {
            navigator.mediaDevices.getUserMedia({audio: true})
              .then((stream) => {
                this.permissionDenied = false;
              })
              .catch((err) => {
                this.permissionDenied = true;
                this.icon = 'volume_up';
                this.audioToggle();
                this.snackBar.open(
                  this.translateService.instant('Mikrofon blockiert'),
                  '',
                  {duration: 3000}
                );
              });

          }
        }
      }));
  }

  // end audio overlay

  backRecording() {
    // if user goes back in PR mode, save the edit parameters
    if(this.vidValue['type']=='PR'){
      this.processVideo(true);
    }
    this.router.navigate(['create-video/' + this.vidValue['id'] + '/record']);
  }

  backToMetaPage() {
    this.router.navigate(['create-video/' + this.vidValue['id'] + '/review']);
  }

  setTextOverlay(): void {
    this.activeTextOverlay = this.textOverlayDeleteCounter;
    this.textOverlayCounter++;
    this.textOverlayDeleteCounter++;
    let overlay_title = `Text ${this.text_overlay_Array.length + 1}`;
    this.text_overlay_formObj[this.textOverlayCounter] = this.formBuilder.group({

      textOverStartTime: [''],
      textOverEndTime: [''],
      boxColor: ['', [Validators.pattern(/^#[0-9A-F]{6}$/i), Validators.required]],
      fontColor: ['', [Validators.pattern(/^#[0-9A-F]{6}$/i), Validators.required]],
      overlayBox: [true],
      overlayFontSize: [''],
      overlayXPosition: [''],
      overlayYPosition: [''],
      alpha: [''],
      overlayText: ['', [Validators.required]]
    });

    // text overlay initialization
    let primary_color_hex = localStorage.getItem("primary_color");
    let temp;
    if (this.bgColorChanged == undefined) {
      temp = this.hexToRgb(primary_color_hex);
    } else {
      temp = this.bgColorChanged;
    }

    // this.text_overlay_formObj[this.textOverlayCounter].get('boxColor').setValue(new Color(temp.r, temp.g, temp.b, 1));
    this.text_overlay_formObj[this.textOverlayCounter].get('overlayText').setValue(overlay_title);
    let temp1;
    if (this.fontColorChanged == undefined) {
      temp1 = this.hexToRgb('#FFFFFF');
    } else {
      temp1 = this.fontColorChanged;
    }
    // this.text_overlay_formObj[this.textOverlayCounter].get('fontColor').setValue(new Color(temp1.r, temp1.g, temp1.b));
    this.text_overlay_formObj[this.textOverlayCounter].get('overlayFontSize').setValue('medium');
    this.text_overlay_formObj[this.textOverlayCounter].get('overlayXPosition').setValue(0);
    this.text_overlay_formObj[this.textOverlayCounter].get('overlayYPosition').setValue(0);
    this.text_overlay_formObj[this.textOverlayCounter].get('alpha').setValue(0.9);
    this.text_overlay_formObj[this.textOverlayCounter].get('textOverStartTime').setValue(Number(Math.floor((this.currentTempTime) * 10) / 10));
    if (this.player.duration < this.currentTempTime + 5) {
      this.text_overlay_formObj[this.textOverlayCounter].get('textOverEndTime').setValue((this.player.duration).toFixed(1));
    } else {
      this.text_overlay_formObj[this.textOverlayCounter].get('textOverEndTime').setValue(Number(Math.floor((this.currentTempTime + 5) * 10) / 10));
    }

    let text_overlay_Obj = this.text_overlay_formObj[this.textOverlayCounter].value;
    text_overlay_Obj['title'] = overlay_title;
    text_overlay_Obj['id'] = this.textOverlayCounter;
    this.getTextOverlayCanvas(text_overlay_Obj);
    this.text_overlay_Array.push(text_overlay_Obj);
  }

  addShapeExpansionPanel() {
    this.shapeOverlayCounter++;
    this.shapeOverlayDeleteCounter++;
    this.activeIndex = this.shapeOverlayDeleteCounter;
    let new_form = this.formBuilder.group({
      shapeOverStartTime: [''],
      shapeOverEndTime: [''],
      shapeWidth: [''],
      shapeHeight: [''],
      overlayXPosition: [''],
      overlayYPosition: [''],
    })

    // Shape overlay initialization
    new_form.get('overlayXPosition').setValue(0);
    new_form.get('overlayYPosition').setValue(0);
    new_form.get('shapeWidth').setValue(Math.floor(this.player.clientWidth / 4));
    new_form.get('shapeHeight').setValue(Math.floor(this.player.clientHeight / 4));
    new_form.get('shapeOverStartTime').setValue(Math.floor((this.currentTempTime) * 10) / 10);
    if (this.player.duration < this.currentTempTime + 5) {
      new_form.get('shapeOverEndTime').setValue((this.player.duration).toFixed(1));
    } else {
      new_form.get('shapeOverEndTime').setValue(Math.floor((this.currentTempTime + 5) * 10) / 10);
    }

    let shape_overlay_Obj = new_form.value;
    shape_overlay_Obj['id'] = this.shapeOverlayCounter;
    // draw a new component
    this.setResizeOverlay(shape_overlay_Obj);

    this.shape_overlay_formObj.push(new_form);
    this.shape_overlay_Array.push(shape_overlay_Obj);
    //   if(!this.deletedShape){
    // this.expansionselected(this.activeIndex, shape_overlay_Obj);
    //   }

  }

  deleteExpansionPanel(index, id, title) {
    this.textOverlayDeleteCounter--;
    delete this.text_overlay_formObj[id];
    let canvs = <HTMLCanvasElement>document.getElementById(id);
    canvs.parentNode.removeChild(canvs);
    this.text_overlay_Array.splice(index, 1);
    //deleting scroll div in child component
    this.overlay_child_Obj[this.textOverlayCounter].instance.deleteDiv(id);
  }

  deleteShapeExpansionPanel(index) {
    this.shapeOverlayDeleteCounter--;

    this.deletedShape = true;
    // delete this.shape_overlay_formObj[id];
    this.shape_overlay_formObj.splice(index, 1);
    // let Shapecanvas = <HTMLCanvasElement>document.getElementById(index);
    // Shapecanvas.parentNode.removeChild(Shapecanvas)
    this.shape_overlay_Array.splice(index, 1);
    //deleting scroll div in child component
    this.resizeDiv.remove(index);
    this.resize_child_Obj.splice(index, 1);
    for (let i = 0; i < this.shape_overlay_Array.length; i++) {
      if (i == this.shapeOverlayDeleteCounter) {
        this.resize_child_Obj[index].instance.update('border', '2px solid navajowhite');
        this.resize_child_Obj[index].instance.updateResize('border', 'navajowhite');
      }
    }

    // this.resize_child_Obj[this.shapeOverlayCounter].instance.deleteShapeDiv(index);
    //    this.matExpansionPanelQueryList.changes.subscribe(
    //     change => {
    //     if(change.last != undefined){
    //       change.last.open();
    //     }
    //       this.cd.detectChanges();
    //     }
    //   );
  }

  changeOlTxtDrpDwnSelection(overlayObj) {
    let text_overlay_Obj = this.text_overlay_formObj[overlayObj['id']].value;
    text_overlay_Obj['title'] = overlayObj['title'];
    text_overlay_Obj['id'] = overlayObj['id'];
    this.editTextOverlayCanvas(text_overlay_Obj);
  }

  overlayTextChanged(overlayObj) {
    let text_overlay_Obj = this.text_overlay_formObj[overlayObj['id']].value;
    text_overlay_Obj['title'] = overlayObj['title'];
    text_overlay_Obj['id'] = overlayObj['id'];
    text_overlay_Obj['alpha'] = this.text_overlay_formObj[overlayObj['id']].get('boxColor').value.a;
    this.editTextOverlayCanvas(text_overlay_Obj);
    this.fontColorChanged = text_overlay_Obj['fontColor'];
    this.bgColorChanged = text_overlay_Obj['boxColor'];
    this.alphaChanged = this.text_overlay_formObj[overlayObj['id']].get('boxColor').value.a;
    this.text_overlay_formObj[overlayObj['id']].get('alpha').setValue(this.alphaChanged);
  }

  overlayBoxSelected(overlayObj) {
    // let temp = this.hexToRgb('#ffffff00');
    // if (!overlayObj['overlayBox']) {
    // this.text_overlay_formObj[overlayObj['id']].get('boxColor').setValue('none');
    // }
    let text_overlay_Obj = this.text_overlay_formObj[overlayObj['id']].value;
    text_overlay_Obj['title'] = overlayObj['title'];
    text_overlay_Obj['id'] = overlayObj['id'];
    this.editTextOverlayCanvas(text_overlay_Obj);
  }

  shapeResizeChanged(index, type) {
    var value = this.shape_overlay_Array[index][type];
    this.shape_overlay_formObj[index].get(type).setValue(value);
    // width + x < player width
    if (this.shape_overlay_Array[index]['shapeWidth'] + this.shape_overlay_Array[index]['overlayXPosition'] >
      this.player.clientWidth) {
      this.shape_overlay_Array[index]['overlayXPosition'] = 0;
      this.shape_overlay_formObj[index].get('overlayXPosition').setValue(0);
      this.shape_overlay_Array[index]['shapeWidth'] = this.player.clientWidth;
      this.shape_overlay_formObj[index].get('shapeWidth').setValue(this.player.clientWidth);
      // update width
      this.resize_child_Obj[index].instance.update(type, value);
      this.resize_child_Obj[index].instance.update('overlayXPosition', 0);
    } else {
      // update x-y
      this.resize_child_Obj[index].instance.update(type, value);
    }
    // height + y < player height
    if (this.shape_overlay_Array[index]['shapeHeight'] + this.shape_overlay_Array[index]['overlayYPosition'] >
      this.player.clientHeight) {
      this.shape_overlay_Array[index]['overlayYPosition'] = 0;
      this.shape_overlay_formObj[index].get('overlayYPosition').setValue(0);
      this.shape_overlay_Array[index]['shapeHeight'] = this.player.clientHeight;
      this.shape_overlay_formObj[index].get('shapeHeight').setValue(Math.floor(this.player.clientHeight));
      // update height
      this.resize_child_Obj[index].instance.update('shapeHeight', Math.floor(this.player.clientHeight));
      this.resize_child_Obj[index].instance.update('overlayYPosition', 0);
    } else {
      // update x-y
      this.resize_child_Obj[index].instance.update(type, value);
    }
  }

  onStartTimeChange(value, title, label) {
    if (value.trim().length !== 0) {
      // the values should lie bw player duration
      if (value < 0) {
        value = 0;
      } else if (value > this.player.duration) {
        value = this.player.duration;
      }

      // text overlay
      if (label == 'startT') {
        this.text_overlay_formObj[title].get('textOverStartTime').setValue(Number(value));
        // side bars under trimmer needs to be updated too
        this.text_overlay_Array[title - 1]['textOverStartTime'] = value;
      } else if (label == 'endT') {
        this.text_overlay_formObj[title].get('textOverEndTime').setValue(Number(value));
        this.text_overlay_Array[title - 1]['textOverEndTime'] = value;
      }
      // end > start
      if (this.text_overlay_formObj[title].get('textOverEndTime').value < this.text_overlay_formObj[title].get('textOverStartTime').value) {
        this.text_overlay_formObj[title].get('textOverEndTime').setValue(Number(this.text_overlay_formObj[title].get('textOverStartTime').value));
      }
    }
  }

  onShapeTimeChange(input_value, index, type) {
    var value = input_value;
    // the values should lie bw player duration
    if (value < 0) {
      value = 0;
    } else if (value > this.player.duration) {
      value = this.player.duration;
    }
    //shape overlay
    this.shape_overlay_formObj[index].get(type).setValue(value);
    this.shape_overlay_Array[index][type] = value;

    // end > start
    if (this.shape_overlay_formObj[index].get('shapeOverEndTime').value < this.shape_overlay_formObj[index].get('shapeOverStartTime').value) {
      this.shape_overlay_formObj[index].get('shapeOverEndTime').setValue(Number(this.shape_overlay_formObj[index].get('shapeOverStartTime').value));
      this.shape_overlay_Array[index]['shapeOverEndTime'] = Number(this.shape_overlay_formObj[index].get('shapeOverStartTime').value);
    }
  }

  editTextOverlayCanvas(overlayObj): void {
    // edit canvas
    const canvasContainer = <HTMLCanvasElement>document.getElementById(overlayObj['id']);
    if (overlayObj['overlayBox']) {
      this.canvasRenderer.setStyle(canvasContainer, 'background-color', `#${overlayObj['boxColor']['rgba']}`);  // box color
    } else {
      this.canvasRenderer.setStyle(canvasContainer, 'background', 'none');  // box color
    }

    let fontSize: number;
    if (overlayObj['overlayFontSize'] == 'small') {
      fontSize = this.videoPlayer.nativeElement.offsetHeight / 20;
    } else if (overlayObj['overlayFontSize'] == 'large') {
      fontSize = this.videoPlayer.nativeElement.offsetHeight / 10;
    } else {
      fontSize = this.videoPlayer.nativeElement.offsetHeight / 15;
    }
    this.canvasRenderer.setStyle(canvasContainer, 'font-size', `${fontSize}px`);
    this.canvasRenderer.setStyle(canvasContainer, 'color', `#${overlayObj['fontColor']['hex']}`);  // box color
    // let temp_text: String = overlayObj['overlayText'].replaceAll('\n', '<br>').replaceAll(' ', '&nbsp;')
    // this.canvasRenderer.setProperty(canvasContainer, 'innerHTML', `${temp_text}`);

    //assigning values dynamically to child component on edit
    this.overlay_child_Obj[overlayObj['id']].instance.fontSize = `${fontSize}px`;
    this.overlay_child_Obj[overlayObj['id']].instance.overlayObj = overlayObj;
    //passing video size to child component
    let videoSize = {
      'width': this.player.clientWidth,
      'height': this.player.clientWidth
    }
    this.overlay_child_Obj[overlayObj['id']].instance.videoSize = videoSize;
    this.overlay_child_Obj[overlayObj['id']].instance.noBackground = overlayObj['overlayBox'];
    this.overlay_child_Obj[overlayObj['id']].instance.ngOnInit();
  }

  editShapeOverlayDiv(shapeOverlayObj): void {
    //assigning values dynamically to child component on edit
    this.resize_child_Obj[shapeOverlayObj['id']].instance.overlayObj = shapeOverlayObj;
    //passing video size to child component
    let videoSize = {
      'width': this.player.clientWidth,
      'height': this.player.clientWidth
    }
    this.resize_child_Obj[shapeOverlayObj['id']].instance.videoSize = videoSize;
    this.resize_child_Obj[shapeOverlayObj['id']].instance.ngOnInit();
  }

  notesChange(event) {
    this.notesChangeValue = event[1];
  }

  resizeBar(index, item) {
    this.activeIndex = index;
    this.tabClicked('blur_tab');
    this.player.currentTime = item.shapeOverStartTime;
    this.playerBOTH.currentTime = item.shapeOverStartTime;
    // this.expansionselected(index,item);
  }

  textOverlayBar(index, item) {
    this.activeTextOverlay = index;
    this.player.currentTime = item.textOverStartTime;
    this.playerBOTH.currentTime = item.textOverStartTime;
    this.tabClicked('textOverlay_tab');
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.textOverlay_tab && !this.selectRecordAudio) {
      switch (event.key) {
        case ' ':
          if (this.player.paused) {
            this.playVideo();
          } else {
            this.pauseVideo();
          }
          break;
        case 'r':
          this.resetTrimmer();
          break;
        case 'c':
          this.splitVideo();
          break;
        case 'ArrowRight':
          this.forwardVideo();
          break;
        case 'ArrowLeft':
          this.backwardVideo();
          break;
      }
    }
  }

  onResize() {
    if (this.player != undefined) {
      this.videoCont = {
        'max-width': `${this.player.clientWidth}px`,
        'max-height': `${this.player.clientHeight}px`,
      }
      this.videoContainerHeight = {
        'padding-top': '0px',
        'height': `${this.player.clientHeight}px`,
      }
      this.cd.detectChanges();
    }
    if (this.textOverlayCounter != 0 && this.player != undefined) {
      let videoSize = {
        'width': this.player.clientWidth,
        'height': this.player.clientHeight
      }
      this.overlay_child_Obj[this.textOverlayCounter].instance.videoSize = videoSize;
    }

    if (this.shape_overlay_Array.length > 0 && this.player != undefined) {
      let videoSize = {
        'width': this.player.clientWidth,
        'height': this.player.clientHeight
      }
      for (let i = 0; i < this.shape_overlay_Array.length; i++) {
        this.resize_child_Obj[i].instance.videoSize = videoSize;
        this.resize_child_Obj[i].instance.ngOnChanges();
      }
      // this.resize_child_Obj[this.shape_overlay_Array.length].instance.videoSize = videoSize;
    }
  }

}
