<span style="display: inline">
  <h1 mat-dialog-title style="font-family: 'poppins-medium'; display: inline-block">
    {{ data.title }}
  </h1>
  <mat-icon (click)="closePopup()"
    style="font-size: 25px; position: relative; cursor: pointer; float: right">
    close
  </mat-icon>
</span>
<mat-dialog-content>
  <p style="font-family: nunito">{{ data.subtitle }}</p>

  <div style="display: inline">
    <br />
    <div>
      <form>
        <mat-form-field style="width: 100%;">
          <input autocomplete="off" [(ngModel)]="data.fieldText" [ngModelOptions]="{standalone: true}" matInput
            placeholder="{{ data.placeholder }}" maxlength="100" />
        </mat-form-field>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="formInputSubmit()" [disabled]="checkInputs()" [mat-dialog-close]="true" cdkFocusInitial color="primary" mat-raised-button>
    {{ data.actionText }}
  </button>
</mat-dialog-actions>
