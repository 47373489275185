import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public dialogRef: MatDialogRef<AlertDialogComponent>) {
    data.actionText = data.actionText ? data.actionText : "Ok";
  }

  ngOnInit(): void {
  }

  closePopup(): void {
    this.dialogRef.close({});
  }

  checkInputs(){
    return this.data.fieldText && this.data.fieldText.length == 0;
  }

  formInputSubmit(){
    this.dialogRef.close({...this.data, clickedSubmit: true});
  }
}
