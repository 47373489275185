<div class="d-flex justify-content-between mb-2" mat-dialog-title>
  <h1 class="text-nunito font-weight-bold w-100 text-center">
    {{'Zu Playlist hinzufügen'|translate}}
  </h1>
  <button mat-icon-button style="margin-top: -6px;">
    <mat-icon [mat-dialog-close]="true">close</mat-icon>
  </button>
</div>

<!-- <mat-divider></mat-divider> -->
<mat-dialog-content style="height: 78%;" class="mb-2">
  <div *ngIf="!playlistCreation">
    <span translate *ngIf='playLists.length == 0' class="text-nunito">
      You have not created a playlist yet
    </span>

    <div *ngIf='playLists.length > 0' class="fontStyle">
      <mat-selection-list #playlist (selectionChange)='addToPlaylist($event)'>
        <mat-list-option *ngFor="let item of playLists" [selected]="item.is_checked" [value]='item.id'>
          <span>{{item.title}}</span>
          <span *ngIf="item.is_draft" style="color: #979797">
            ({{'Privat'|translate}})
          </span>
          <span *ngIf="!item.is_draft" style="color: #979797">
            ({{'Public'|translate}})
          </span>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>

  <div *ngIf='playlistCreation' style="top: 40%; position: relative; text-align: center;">
    <form [formGroup]="playlistForm">
      <mat-card class="mat-elevation-z6">
        <mat-card-content>
          <mat-form-field class="fontStyle" class="w-100" hideRequiredMarker>
            <mat-label>{{'Titel dieser Playlist'|translate}}</mat-label>
            <input formControlName="title"
                   matInput
                   required
                   placeholder="{{'Wähle einen aussagekräftigen Titel'|translate}}"
                   type="text"/>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</mat-dialog-content>
<mat-progress-bar mode="buffer" *ngIf="inProgress"></mat-progress-bar>
<mat-divider></mat-divider>

<mat-dialog-actions *ngIf='!playlistCreation' >
  <button (click)='this.playlistCreation = !playlistCreation'
          style="justify-content: flex-end;float: right;"
          color="primary" mat-stroked-button>
    <span translate>Neue Playlist erstellen</span>
  </button>
</mat-dialog-actions>

<mat-dialog-actions *ngIf='playlistCreation' class="d-flex justify-content-between">
  <button (click)='cancelPlaylistCreation()' mat-stroked-button>
    <span translate>Cancel</span>
  </button>

  <button (click)='createNewPlaylist()'
          [disabled]="playlistForm.get('title').value == ''"
          color="primary" mat-stroked-button>
    <span translate>Erstellen</span>
  </button>
</mat-dialog-actions>
