import {Component, HostListener, OnDestroy, OnInit, ViewChild,} from '@angular/core'
import {environment} from '../../environments/environment'
import {DataService} from '../services/data.service'
import {NavbarService} from '../services/navbar.service'
import {MatGridList} from '@angular/material/grid-list'
import {MatDialog} from '@angular/material/dialog'
import {MatSnackBar} from '@angular/material/snack-bar'
import {Router} from '@angular/router'
import {TranscriptPopupComponent} from '../shared/transcript-popup/transcript-popup.component'
import {TranslateService} from '@ngx-translate/core'
import {ThumbnailPopupComponent} from '../shared/thumbnail-popup/thumbnail-popup.component'
import {AddToPlaylistComponent} from '../playlists/add-to-playlist/add-to-playlist.component'
import {VideoView} from "../models/video/video.interface";
import {UtilityService} from "../services/utility.service";
import {ChapterCreationPopupComponent} from "../chapter-creation-popup/chapter-creation-popup.component";
import {
  UploadClyppsPopupComponent
} from '../dashboard/video-management/upload-clypps-popup/upload-clypps-popup.component';
import {AuthService} from "../services/auth.service";
import {UploaderChangeDialogComponent} from "../dashboard/uploader-change-dialog/uploader-change-dialog.component";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-my-videos',
  templateUrl: './my-videos.component.html',
  styleUrls: ['./my-videos.component.scss'],
})
export class MyVideosComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid: MatGridList
  @ViewChild('input') public search: any

  all_videos: VideoView[] = [];
  filteredVideos: VideoView[] = [];
  environment = environment
  searchQuery: string = "";
  sort_option: string = 'last_edited_on';
  showSpinner: boolean = false;
  filter_option: string = 'all';
  is_creator: boolean = false;  // to check if user is a creator or not

  constructor(
    public dataService: DataService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    public navbarService: NavbarService,
    private translateService: TranslateService,
    public utilityService: UtilityService,
  ) {
  }

  ngOnInit(): void {
    this.navbarService.getUserProfileDetails();
    this.navbarService.disableCreationMode();
    this.getVideos();
  }

  getVideos() {
    this.showSpinner = true;
    this.dataService.getURL('user/videos/myvideos/').subscribe((data: any) => {
        this.all_videos = data;
        this.showSpinner = false;
        this.applyFilter();
        this.is_creator = this.authService.userDetails.is_creator;
      },
      (err) => {
        this.showSpinner = false;
        window.alert(this.translateService.instant('Ein Fehler ist aufgetreten'));
        console.log(err);
      }
    )
  }

  savePassword(accessKey) {
    navigator.clipboard.writeText(accessKey)
    this.snackBar.open(this.translateService.instant('Zugangscode kopiert'), '', {duration: 2500});
  }


  deleteVideo(video: VideoView): void {
    let message = this.translateService.instant('Dein Video wird unwiederbringlich gelöscht. Fortfahren?');
    if (window.confirm(message)) {
      this.showSpinner = true;
      this.dataService.deleteURL(`user/videos/${video.id}/`).subscribe((res) => {
        this.showSpinner = false;
        this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2500});
        let index = this.all_videos.indexOf(video);
        if (index != -1) {
          this.all_videos.splice(index, 1);
          this.applyFilter();
        }
      }, (err) => {
        this.showSpinner = false;
        window.alert(this.translateService.instant('Ein Fehler ist aufgetreten'));
        console.log(err);
      });
    }
  }

  changeUploader(videoObj: VideoView): void {
    // videoView does not contain uploader details, and the popup needs these two things
    const popup_data = {
      title: videoObj.title,
      uploader: {
        id: this.authService.userDetails.user
      }
    };

    const dialogRef = this.dialog.open(UploaderChangeDialogComponent, {
      minWidth: '400px',
      width: '50vw',
      maxWidth: '600px',
      data: popup_data,
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // confirm and save
          if (result != this.authService.userDetails.user) {
            // find user's name
            const user_object = this.authService.checklist_data.find(e => e.id == result && e.type == 'user');
            let message = user_object.name;
            message += " "
            message += this.translateService.instant("will also be notified about this.");
            message += '\n\n';
            message += this.translateService.instant("Would you like to continue?");
            if (window.confirm(message)) {
              this.showSpinner = true;
              this.dataService.putURL(`user/videos/${videoObj.id}/`, {uploader: result}).subscribe((res: VideoView) => {
                this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
                const index = this.all_videos.findIndex(e => e.id == videoObj.id);
                if (index > -1) {
                  this.all_videos.splice(index, 1);
                  this.applyFilter();
                }
                this.showSpinner = false;
              }, (err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.error(err);
                window.alert(err.error);
              });
            }
          }
        }
      }
    );
  }

  draftChange(draftStats, video: VideoView): void {
    this.showSpinner = true;
    this.dataService.putURL(`user/videos/${video.id}/?done=true`, {is_draft: draftStats}).subscribe((res) => {
      this.showSpinner = false;
      let index = this.all_videos.indexOf(video);
      if (index != -1) {
        this.all_videos[index].is_draft = draftStats;
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
        this.applyFilter();
      }
    }, (err) => {
      this.showSpinner = false;
      window.alert(this.translateService.instant('Ein Fehler ist aufgetreten'));
      console.log(err);
    })
  }

  editVideo(video: VideoView) {
    if (video.type != 'PR') {
      // old video type
      this.router.navigate(['create-video', video.id, 'edit']);
      return;
    }

    // go to a page based on state
    switch (video.state) {
      case 'PR':
      case 'CO':
        this.router.navigate(['create-video', video.id, 'review']);
        break;
      case 'FA':
      case 'UP':
        this.router.navigate(['create-video', video.id, 'trim']);
        break;
      case 'NO':
        this.router.navigate(['create-video', video.id, 'record']);
        break;
      default:
        // just go to record page
        this.router.navigate(['create-video', video.id, 'record']);
        break;
    }
    return;
  }

  viewVideo(videoRes: VideoView): void {
    if (videoRes.is_draft) {
      // video is draft, go to edit page
      this.editVideo(videoRes);
    } else {
      // video is published
      if (videoRes.processed_file) {
        // go to video view page
        this.router.navigate(['/view', videoRes.id]);
      } else {
        // there may not be any processed file
        // go to edit page
        this.editVideo(videoRes);
      }
    }
  }

  ownThumbnail(video: VideoView): void {
    this.dialog
      .open(ThumbnailPopupComponent, {
        minWidth: '50vw',
        height: '420px',
        disableClose: true,
        data: video,
      })
      .afterClosed()
      .subscribe((res) => {
        console.log(res);
        if (res == true) {
          // dialog is closed without any upload
        } else {
          let index = this.all_videos.indexOf(video);
          if (index != -1) {
            this.all_videos[index] = res;
            this.applyFilter();
          }
        }
      })
  }

  openChapterPopup(video_object: VideoView) {
    const dialogData = {
      id: video_object.id,
      duration: video_object.duration,
      chapter_file: video_object.chapter_file,
      processed_file: video_object.processed_file,
    };
    const dialogRef = this.dialog.open(ChapterCreationPopupComponent, {
      maxWidth: '99vw',  // default is 80vw
      disableClose: true,
      // autoFocus: false,
      data: dialogData,
      // panelClass: 'chapter-container'
    });

    dialogRef.afterClosed().subscribe((res) => {
      const original_video_object = this.all_videos.find(ele => ele.id == video_object.id);
      original_video_object.chapter_file = res;
      this.applyFilter();
    });
  }

  transcriptPopup(videoObj): void {
    this.dialog
      .open(TranscriptPopupComponent, {
        width: '80%',
        disableClose: true,
        data: videoObj,
        panelClass: 'my-panel',
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          // replace current video with the res
          let index = this.all_videos.indexOf(videoObj);
          if (index != -1) {
            if (res == 'subtitles_deleted') {
              // update the video card
              this.all_videos[index].vtt_file = null;
            } else {
              // subtitles updated
              this.all_videos[index] = res;
            }
            this.applyFilter();
          }
        }
      })
  }

  addtoPlaylist(videoobj): void {
    this.dialog.open(AddToPlaylistComponent, {
      width: '50%',
      // height: '70%',
      disableClose: false,
      data: {
        content: JSON.stringify(videoobj),
      },
    })
  }

  ngOnDestroy(): void {
  }

  applyFilter() {
    // performs both search and filter
    // first search
    this.filteredVideos = this.all_videos.filter(r => r.title.toLowerCase().includes(this.searchQuery.toLowerCase()));

    // then filter
    switch (this.filter_option) {
      case 'drafts':
        this.filteredVideos = this.filteredVideos.filter(r => r.is_draft);
        break;
      case 'published':
        this.filteredVideos = this.filteredVideos.filter(r => !r.is_draft);
        break;
      case 'external':
        this.filteredVideos = this.filteredVideos.filter(r => r.is_external);
        break;
      default:
        break;
    }

    // then sort
    switch (this.sort_option) {
      case 'added_on':
        this.filteredVideos = this.filteredVideos.sort((a, b) => new Date(b.added_on).getTime() - new Date(a.added_on).getTime());
        break;
      case 'last_edited_on':
        this.filteredVideos = this.filteredVideos.sort((a, b) => new Date(b.last_edited_on).getTime() - new Date(a.last_edited_on).getTime());
        break;
      case 'views':
        this.filteredVideos = this.filteredVideos.sort((a, b) => b.views - a.views);
        break;
      case 'duration':
        this.filteredVideos = this.filteredVideos.sort((a, b) => b.duration - a.duration);
        break;
      default:
        break;
    }
  }

  duplicateVideo(video: VideoView) {
    // show spinner
    this.showSpinner = true;
    this.dataService.getURL(`user/videos/${video.id}/duplicate/`, {observe: 'body', responseType: 'text'})
      .subscribe((res) => {
        this.showSpinner = false;
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
        this.editVideo(video);
        // this.router.navigate(['create-video', res, 'record']);
      }, (err: HttpErrorResponse) => {
        this.showSpinner = false;
        window.alert(err.error);
        console.error(err);
      });
  }

  // mass clypp upload
  massClyppUpload() {
    // only for pro users
    if (this.authService.company.max_upload_video_height == '720') {
      this.snackBar.open(this.translateService.instant('Upgrade to use this feature'),
        '', {duration: 2500});
      return;
    }
    const dialogRef = this.dialog.open(UploadClyppsPopupComponent, {
      width: '50vw',
      minWidth: "400px",
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((dialogResponse: boolean) => {
      if (dialogResponse) {
        this.getVideos();
      }
    });
  }

  // this is to avoid pressing enter on a mat menu item
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
    if (e.key == 'Enter') {
      e.preventDefault();
      e.stopPropagation();
    }
  }

}
