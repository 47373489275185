import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
import { DataService } from '../services/data.service';

@Pipe({
    name: 'image'
})
export class ImagePipe implements PipeTransform {

    constructor(private dataService: DataService, private domSanitizer: DomSanitizer) {
    }

    async transform(url: string, sanitize = true): Promise<SafeUrl> {
        const imageBlob = await this.dataService.getURL<any>(url, {responseType: 'blob'}).pipe(first()).toPromise();
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                const result = reader.result as string;
                resolve(sanitize ? this.domSanitizer.bypassSecurityTrustUrl(result) : result);
            };
            reader.readAsDataURL(imageBlob);
        });
    }

}
