import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService,
                private translateService: TranslateService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let modified = request;
        let token = AuthService.getAccessToken();
        modified = request.clone({
          headers: request.headers.set("Authorization", "Token " + token)
        });

        return next.handle(modified).pipe(retry(0), catchError((err: HttpErrorResponse) => {
            if ([401].includes(err.status)) {
                // auto logout if 401 response returned from api
                return this.authService.logout();
            } else if ([403].includes(err.status)) {
              // 403 from WAF
              let message = this.translateService.instant("Dear user, your request was blocked due to security reasons.");
              message += "\n\n";
              message += this.translateService.instant("Would you like to report it to the Clypp team?");
              if (window.confirm(message)) {
                // create mailto link
                let href: string = `mailto:support@clypp.app?subject=Blocked request ${err.status}&body=`;
                href += encodeURIComponent(`${request.method} ${request.urlWithParams}`);
                window.open(href, "_blank");
              }
            }
            return this.parseErrorBlob(err);
        }), catchError(this.handleError));
    }

    parseErrorBlob(err: HttpErrorResponse) {
        if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json') {
            // https://github.com/angular/angular/issues/19888
            // When request of type Blob, the error is also in Blob instead of object of the json data
            return new Promise<any>((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e: Event) => {
                    try {
                        const errmsg = JSON.parse((<any>e.target).result);
                        reject(new HttpErrorResponse({
                            error: errmsg,
                            headers: err.headers,
                            status: err.status,
                            statusText: err.statusText,
                            url: err.url
                        }));
                    } catch (e) {
                        reject(err);
                    }
                };
                reader.onerror = (e) => {
                    reject(err);
                };
                reader.readAsText(err.error);
            });
        }
        return throwError(err);
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.log(error);
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error.reason}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            error.error.reason ? error.error.reason : error);
    }
}
