<!-- friends, team, dept, groups -->
<div class="m-3 d-flex align-items-center" style="flex-flow: wrap;">
  <mat-icon class="mr-1" color="primary">
    groups
  </mat-icon>
  <span class="fontStyle mr-4" translate style="font-size: x-large;">Gruppen</span>
  <button (click)="navbarService.addGroup();" class="border-radius-20" mat-stroked-button>
    <mat-icon class="mr-1">
      add
    </mat-icon>
    {{ "Neue Gruppe" | translate }}
  </button>
</div>

<mat-card-subtitle class="m-3" translate>
  Here you can find the department, team and groups you belong to.
</mat-card-subtitle>

<mat-grid-list #grid (window:resize)="onResize($event)" [cols]="cols" [gutterSize]="'1rem'" class="mt-2 mx-2"
               rowHeight="230px">

  <mat-grid-tile *ngFor="let company of friendCompanies;">
    <mat-card (click)="viewFriendCompany(company.id)" class="group-card">
      <h2 class="two-lines">{{company.sharing_company.name}}</h2>
      <h4 class="typeheading" translate>Partner workspace</h4>
      <mat-card-content>
        <img [src]="company.sharing_company.company_logo" class="friend-logo" alt="friend company logo" />
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>

  <mat-grid-tile *ngFor="let g of overallgroups;">
    <mat-card (click)="viewGroups(g)" class="group-card">
      <h2 style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{g.title}}</h2>
      <h4 *ngIf="g.type=='team'" class="typeheading">{{authService.company?.team_alias}} </h4>
      <h4 *ngIf="g.type=='department'" class="typeheading" translate>{{authService.company?.dept_alias}}</h4>
      <h4 *ngIf="g.company != authService.userDetails.company && g.type == null" class="typeheading" translate>Externe Organisation</h4>
      <mat-card-content class="group-card-content">
        <div *ngFor="let m of g.members; let i = index;" class="member-avatar">
          <div *ngIf="g.last_edited_on && i<9;" [matTooltip]="m.first_name + ' ' + m.last_name"
               mat-card-avatar
               style="background-size: cover; background-image: url({{m.userprofile.profile_pic}})">
          </div>
          <div *ngIf="!g.last_edited_on && i<9;" mat-card-avatar
               style="background-size: cover; background-image: url({{m.profile_pic}})">
          </div>
          <div *ngIf="i==9;" style="text-align-last: center;">
            <mat-icon [matTooltip]="g.members.length - 9 + ' weitere'" class="avatar" style="padding-top: 10px;">
              more_horiz
            </mat-icon>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions *ngIf="g.last_edited_on">
          <span class="d-flex align-items-center"><mat-icon matTooltip="{{'Last modified at' | translate}}">published_with_changes</mat-icon>&nbsp;
            {{g.last_edited_on | date: 'dd.MM.yyyy HH:mm'}}</span>
      </mat-card-actions>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>

<!--group invite-->
<div *ngIf="invitations.length != 0" class="m-4">
  <h2 class="mb-1 fontStyle" translate>Group invitations from other workspaces</h2>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let inv of invitations">
      <mat-expansion-panel-header>
        <mat-panel-title class="overflow-auto">
          {{inv.invited_group?.title}}
        </mat-panel-title>
        <mat-panel-description>
          <button (click)="acceptRejectInvite(inv.id, 'reject');stopPropogation($event)" mat-stroked-button class="mr-2">
            <span translate>Reject</span>
          </button>
          <button (click)="acceptRejectInvite(inv.id, 'accept');stopPropogation($event)" color="primary" mat-stroked-button>
            <span translate>Accept</span>
          </button>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <span [matTooltip]="inv.invited_by.email">
          {{'Invited by' | translate}}:&nbsp;{{inv.invited_by.first_name}}&nbsp;{{inv.invited_by.last_name}}
        </span>

        <h3 class="mt-2" translate>Mitwirkende</h3>

        <div id="group-members" class="d-flex justify-content-start" style="flex-flow: wrap;">
          <mat-card *ngFor="let m of inv.invited_group?.members" style="width: 146px;">
            <mat-card-header>
              <img mat-card-avatar style="margin-left: 32px;" [matTooltip]="m.email" [src]="m.userprofile.profile_pic" class="avatar">
            </mat-card-header>
            <mat-card-content>
              <mat-card-subtitle style="word-break: break-all; text-align-last: center;">
                {{m.first_name}} {{m.last_name}}
              </mat-card-subtitle>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>


