import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';


export interface Friend {
  id: number;
  sharing_company: Company,
  shared_company: Company,
  is_accepted: boolean
}

export interface Company {
  company_logo: string;
  dept_alias: string;
  id: number;
  name: string;
  primary_color: string;
  team_alias: string;
}


@Component({
  selector: 'app-partner-organizations',
  templateUrl: './partner-organizations.component.html',
  styleUrls: ['./partner-organizations.component.scss']
})
export class PartnerOrganizationsComponent implements OnInit {

  friend_organisations: Friend[] = [];
  global_admin_email: string = "";

  language: string = 'en';
  @ViewChild('friend_table') friends_table: MatTable<Friend[]>;

  constructor(
    public authService: AuthService,
    public dataService: DataService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    if(this.authService.userDetails.is_global_manager){
      this.loadFriends();
    }
    this.language = this.translateService.currentLang;
  }

  loadFriends() {
    this.dataService.getURL<Friend[]>('manager/friends/', {observe: 'body', responseType: 'json'})
      .subscribe((res) => {
          this.friend_organisations = res;
        }, (err) => {
          window.alert(err.error);
        }
      );
  }

  inviteCompany(){
    if (this.global_admin_email.length > 0) {
      // todo: enforce the ip address format
      this.dataService.postURL<Friend>('manager/friends/', {global_admin_email: this.global_admin_email},
        {observe: 'body', responseType: 'json', headers:{'Accept-Language': this.language}})
        .subscribe((res) => {
          // then clear
          this.global_admin_email = "";
          // success snackbar
          this.snackBar.open(this.translateService.instant('Erfolgreich'), '', { duration: 2500 });
          this.friend_organisations.push(res);
          this.friends_table.renderRows();
        }, (err) => {
          window.alert(err.error);
        });
    }
  }

  acceptInvite(id){
    // accept
    let message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      this.dataService.putURL(`user/friends/${id}/`, {}).subscribe(() => {
        this.loadFriends();
        this.friends_table.renderRows();
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', { duration: 2500 });
      }, () => {});
    }
  }

  deleteInvite(id){
    // delete
    let message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      this.dataService.deleteURL(`user/friends/${id}/`).subscribe(() => {
        this.loadFriends();
        this.friends_table.renderRows();
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', { duration: 2500 });
      }, () => {});
    }
  }

}
