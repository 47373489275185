<div *ngIf="progress > 0" class="spinner-overlay">
  <div style="position: absolute;top:47%;left :50%;z-index:999;opacity: 1;" class="mt-3 text-center text-white">
    <div class="spinner-background">{{progress}}%</div>
    <mat-progress-spinner class="spinner" #spinner color="primary" mode="determinate" [value]="progress">
    </mat-progress-spinner>
    <div *ngIf="progress == 100 || processFinish" class="processText"><span translate>Bitte warten...</span></div>
  </div>
</div>
<div style="position: relative;" class="w-100 quickwin-creation backgroundpng" (window:pageshow)="eventFunction($event)">

  <div style="height: 100%;">
    <div fxLayout="row">
      <div fxFlex="50%">
        <img class="imgresizeupload" style="justify-content:flex-start ;margin-top:0rem;display: flex; height: 65px;"
          [src]="authService.company?.company_logo" alt="logo"
          onerror="this.onerror=null; this.src='assets/logo/clypp_logo_small.png'">
      </div>
      <div fxFlex="50%">
        <div style="display: flex;justify-content: flex-end; float: right; margin-right:1rem;margin-top:0.5rem;height: 30px;">
          <a href="{{'TIPS_URL' | translate}}" target="_blank">
            <p class="tips" translate>Tipps ansehen
              <mat-icon style="vertical-align: bottom;">tips_and_updates</mat-icon></p>
          </a>
        </div>
      </div>
    </div>
    <div fxLayout="row" class="videoUploadHeight flex-lg-nowrap flex-wrap justify-content-center">
      <div fxFlex="25%" fxFlex.xs="0%" fxFlex.sm="0%"></div>
      <div fxFlex="50%" fxFlex.xs="100%"  fxFlex.sm="100%">
        <div class="uploadVal" >
          <mat-card rowHeight="100%" class="w-60" style="width:100%" class="videoUploadClass">

            <div class="row" style="margin-left:0px;margin-right: 0px;">
              <div class="col-12">
                <p class="maxfiletext" [translate] ="'_upload_Titles'" [translateParams]="{val: max_video_Size / 1000000}"></p>
                <app-video-upload-viewer (videoUploaded)="uploadedVideo($event)">
                </app-video-upload-viewer>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <div fxFlex="25%" fxFlex.xs="100%"  fxFlex.sm="100%">
        <div >
          <div style="display:flex;align-items: center;
                justify-content: center;
              margin-top: 0rem;position: relative;">
            <button mat-fab [ngClass]="!alreadyEnteredTrimmingStep ?  'disablebigIcon' : 'bigButton'"
              (click)="metaReviewButton()" style="box-shadow: none !important;"
              [disabled]="!alreadyEnteredTrimmingStep">
              <mat-icon class="restartBig">keyboard_tab</mat-icon>
            </button>
          </div>

          <div class="textcolor1" [ngStyle]="{'color':!alreadyEnteredTrimmingStep ? '#DADADA' :'black'}"
            style="display: flex;justify-content:center; text-align:center; position: relative;top:1rem;">
            <span translate>Hochgeladene Dateien bearbeiten</span>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
