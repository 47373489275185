import { DataService } from 'src/app/services/data.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, } from '@angular/material/dialog';
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { Playlist } from "../../models/playlist/playlist";
import {UserProfileWithName} from "../../models/video/video.interface";

interface PublishedVideo {
  id: string,
  title: string
}

@Component({
  selector: 'app-user-content-dialog',
  templateUrl: './user-content-dialog.component.html',
  styleUrls: ['./user-content-dialog.component.scss']
})
export class UserContentDialogComponent implements OnInit {
  candidates: UserProfileWithName[] = [];
  selected_user_id: number = 0;
  profile_id: number = 0;
  published_videos: PublishedVideo[] = [];
  published_topics: PublishedVideo[] = [];
  published_playlists: Playlist[] = [];
  n_published_videos: number = 0;
  n_published_topics: number = 0;
  n_published_playlists: number = 0;

  // to manage tables
  displayedColumns: string[] = ['select', 'title'];

  videoDataSource: MatTableDataSource<PublishedVideo>;
  videoSelection = new SelectionModel<PublishedVideo>(true, []);

  topicDataSource: MatTableDataSource<PublishedVideo>;
  topicSelection = new SelectionModel<PublishedVideo>(true, []);

  playlistDataSource: MatTableDataSource<Playlist>;
  playlistSelection = new SelectionModel<Playlist>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllVideosSelected() {
    const numSelected = this.videoSelection.selected.length;
    const numRows = this.videoDataSource.data.length;
    return numSelected === numRows;
  }

  isAllTopicsSelected() {
    const numSelected = this.topicSelection.selected.length;
    const numRows = this.topicDataSource.data.length;
    return numSelected === numRows;
  }

  isAllPlaylistsSelected() {
    const numSelected = this.playlistSelection.selected.length;
    const numRows = this.playlistDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllVideos() {
    if (this.isAllVideosSelected()) {
      this.videoSelection.clear();
      return;
    }
    this.videoSelection.select(...this.videoDataSource.data);
  }

  toggleAllTopics() {
    if (this.isAllTopicsSelected()) {
      this.topicSelection.clear();
      return;
    }
    this.topicSelection.select(...this.topicDataSource.data);
  }

  toggleAllPlaylists() {
    if (this.isAllPlaylistsSelected()) {
      this.playlistSelection.clear();
    }
    else {
      this.playlistSelection.select(...this.playlistDataSource.data);
    }
  }

  constructor(
    private dataService: DataService,
    public dialogRef: MatDialogRef<UserContentDialogComponent>,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.profile_id = data.id;
  }

  ngOnInit(): void {
    this.loadData();
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  loadData() {
    this.dataService.getManagerDeleteUser(this.profile_id).subscribe((res: any) => {
      if (res) {
        this.n_published_videos = res['n_published_videos'];
        this.published_videos = res['published_videos'];

        this.n_published_topics = res['n_published_topics'];
        this.published_topics = res['published_topics'];

        this.n_published_playlists = res['n_published_playlists'];
        this.published_playlists = res['published_playlists'];
        this.candidates = res['candidates'];

        this.videoSelection.clear();
        delete this.videoDataSource;
        this.videoDataSource = new MatTableDataSource<PublishedVideo>(this.published_videos);

        this.topicSelection.clear();
        delete this.topicDataSource;
        this.topicDataSource = new MatTableDataSource<PublishedVideo>(this.published_topics);

        this.playlistSelection.clear();
        delete this.playlistDataSource;
        this.playlistDataSource = new MatTableDataSource<Playlist>(this.published_playlists);
      }
      // this.dialogRef.close(true);
    });
  }

  reassignContent() {
    const selected_user_profile = this.candidates.find(x => x.id == this.selected_user_id);

    let message = `${selected_user_profile.user.first_name} ${selected_user_profile.user.last_name} & `;
    message += `${this.data['first_name']} ${this.data['last_name']} `;
    message += this.translateService.instant("will be informed about this.");
    message += '\n\n';
    message += this.translateService.instant("Would you like to continue?");

    if (window.confirm(message)) {
      let selected_videos: string[] = [];
      for (let i of this.videoSelection.selected) {
        selected_videos.push(i['id']);
      }

      let selected_topics: string[] = [];
      for (let i of this.topicSelection.selected) {
        selected_topics.push(i['id']);
      }

      let selected_playlists: number[] = [];
      for (let i of this.playlistSelection.selected) {
        selected_playlists.push(i.id);
      }
      let payload = {
        video_ids: selected_videos,
        topic_ids: selected_topics,
        playlist_ids: selected_playlists,
        candidate_id: this.selected_user_id,
      };
      this.dataService.putURL(`manager/users/user/${this.profile_id}/`, payload, {
        observe: 'body',
        responseType: 'json'
      }).subscribe((res) => {
        if (res['response'] == 'success') {
          this.loadData();
          this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
        } else {
          window.alert(res['reason']);
        }
      });
    }
  }

  applyFilter(event: Event, type: 'video' | 'topic' | 'playlist'): void {
    event.stopPropagation();
    if (event != undefined) {
      const filterValue = (event instanceof Event) ? (event.target as HTMLInputElement).value : event;
      switch (type) {
        case 'video':
          filterValue ? (this.videoDataSource.filter = filterValue.trim().toLowerCase()) : this.videoDataSource.filter = '';
          break;
        case 'topic':
          filterValue ? (this.topicDataSource.filter = filterValue.trim().toLowerCase()) : this.topicDataSource.filter = '';
          break;
        case "playlist":
          filterValue ? (this.playlistDataSource.filter = filterValue.trim().toLowerCase()) : this.playlistDataSource.filter = '';
          break;
      }
    }
  }

  stopToggle(event: Event): void {
    event.stopPropagation();
  }
}
