import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { PrimaryColorSetupService } from '../services/primary-color-setup.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private primaryColorSetupService : PrimaryColorSetupService,
    private router: Router,
    public dataService: DataService,
    public translateService: TranslateService) {
  }

  async login(label,email, password,afterSuccess?): Promise<any> {
      email = email.toLowerCase();
      let creds = {
        email,
        password
      }
      await this.authService.authenticate(creds).then(res => {
        let responseValue: any = res['company'];
        let primaryColor = responseValue.primary_color;
        localStorage.setItem('primary_color', primaryColor);
        let secColor = responseValue.secondary_color;
        localStorage.setItem('secondary_color', secColor);
        // localStorage.setItem('max_creators', responseValue.max_creators);
        // localStorage.setItem('bg_image', responseValue.bg_image);
        // localStorage.setItem('is_transcription_service_enabled', responseValue.is_transcription_service_enabled);
        localStorage.setItem('max_video_duration_in_seconds', responseValue.max_video_duration_in_seconds);

        let RGB = secColor;
        let A = '0.2';
        let RGBA = 'rgba(' + parseInt(RGB.substring(5, 7), 16) + ',' + parseInt(RGB.substring(3, 5), 16) + ',' + parseInt(RGB.substring(1, 3), 16) + ','
        let s1 = RGBA + A + ')';
        let s2 = RGBA + 0.14 + ')';
        let s3 = RGBA + 0.12 + ')';

        localStorage.setItem('boxShadow1', s1);
        localStorage.setItem('boxShadow2', s2);
        localStorage.setItem('boxShadow3', s3);

        this.primaryColorSetupService.savePrimaryColor();
        this.primaryColorSetupService.saveSecondaryColor();
        this.primaryColorSetupService.savergbColor();

        if ('returnUrl' in this.route.snapshot.queryParams) {
          let qParams = this.route.snapshot.queryParams['qParams'];
          let qpArray = qParams.split(",");
          console.log(qParams);
          let qpString = "?";
          qpArray.forEach((element, index) => {
            if (index != qpArray.length - 1) {
              let item = element.split(":");
              qpString = `${qpString}${item[0]}=${item[1]}`
              if (index != qpArray.length - 2) {
                qpString = `${qpString}&`
              }
            }
          });
          let finalUrl;
          if(typeof this.route.snapshot.queryParams['returnUrl'] === 'string'){
            finalUrl = this.route.snapshot.queryParams['returnUrl'];
          }else{
            finalUrl = this.route.snapshot.queryParams['returnUrl']?.join("/");
          }
          var returnUrl = finalUrl || null;
          returnUrl = `${returnUrl}${qpString}`;
        }
        if (res) {
          this.dataService.dataCarrier.next({loginSuccess: true});
          // if (res.company['disable_vanilla_registration'] != undefined) {
          //   let disableReg = res.company['disable_vanilla_registration'];
          //   localStorage.setItem('disable_vanilla_registration', String(disableReg));
          // }
          if (!this.authService.currentUser?.is_profile_completed) {
            this.router.navigate(['user_profile']);
          } else {
            returnUrl ? this.router.navigateByUrl(returnUrl) : this.router.navigate(['start']);
          }
          afterSuccess && afterSuccess();
        }
      }, error => {
        console.error(error); // Error!
        if (error.status == 429) {
          // too many requests
          window.alert(error.error['detail']);
        } else {
          // user not found, subscription exp, ip address restricted, wrong password, account blocked
          window.alert(error.error);
        }
      });
  }
}
