import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {MatSnackBar} from "@angular/material/snack-bar";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-to-playlist',
  templateUrl: './add-to-playlist.component.html',
  styleUrls: ['./add-to-playlist.component.scss']
})
export class AddToPlaylistComponent implements OnInit {
  @ViewChild("playlist") playlist;
  videoObj = JSON.parse(this.data.content);

  playLists = [];
  playlistCreation = false;
  listAdded: boolean = false;
  inProgress: boolean = false;  // to show buffer bar
  playlistForm = this.formBuilder.group({
    title: ['', Validators.required]
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private dialog: MatDialog,private translateService:TranslateService, private formBuilder: UntypedFormBuilder, private dataService: DataService, private snackBar: MatSnackBar) {
   }

  ngOnInit(): void {
    this.getPlaylists();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  getPlaylists(): void {
    this.inProgress = true;
    this.dataService.getURL(`user/videos/${this.videoObj.id}/my-playlists/`).subscribe((res: any) => {
      this.inProgress = false;
      if (res.length > 0) {
        this.playLists = res;
      }
    })
  }

  cancelPlaylistCreation(): void {
    this.playlistCreation = !this.playlistCreation;
    this.playlistForm.get('title').setValue('');
  }

  addToPlaylist(event): void {
    let option = event.options[0];
    let playlistID = option._value;
    let vidID = this.videoObj.id;
    this.inProgress = true;
    if (option._selected) {
      this.listAdded = true;
      this.dataService.getURL(`user/playlists/${playlistID}/${vidID}/add/`).subscribe((res: any) => {
        this.inProgress = false;
        this.snackBar.open(this.translateService.instant('Hinzugefügt'), '', {duration: 1500});
      });
    } else {
      this.dataService.getURL(`user/playlists/${playlistID}/${vidID}/remove/`).subscribe((res: any) => {
        this.inProgress = false;
        this.snackBar.open(this.translateService.instant('Entfernt'), '', {duration: 1500});
      });
    }

  }

  createNewPlaylist(): void {
    if (this.playlistForm.status == 'VALID') {
      this.dataService.postURL(`user/playlists/`, this.playlistForm.value).subscribe((res: any) => {
        if (res['response']=='failure') {
          window.alert(res['reason']);
        } else {
          this.getPlaylists();
          this.playlistCreation = false;
          this.playlistForm.get('title').setValue('');
        }
      });
    }

  }

}
