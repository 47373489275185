import {Component, Inject, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {FileValidator} from 'ngx-material-file-input';
import {VideoView} from "../../models/video/video.interface";


@Component({
  selector: 'app-thumbnail-popup',
  templateUrl: './thumbnail-popup.component.html',
  styleUrls: ['./thumbnail-popup.component.scss']
})
export class ThumbnailPopupComponent implements OnInit {
  video_object: VideoView;
  maxSize5MB: number = 5000000;  // simple calculation
  max_file_size_string = '5 MB';
  imageSrc: string = '';
  thumbnailForm = this.formBuilder.group({
    attachment: [undefined, FileValidator.maxContentSize(this.maxSize5MB)]
  });
  formData: FormData;

  constructor(private snackBar: MatSnackBar,
              private dataService: DataService,
              public dialogRef: MatDialogRef<ThumbnailPopupComponent>,
              public formBuilder: UntypedFormBuilder,
              private translateService: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.video_object = data;
  }

  ngOnInit(): void {

  }

  fileSelected(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.attachFile(file);
  }

  readFileAsString(file: File){
    const reader = new FileReader();
    reader.onload = () => {
      this.imageSrc = reader.result as string;
    }
    reader.readAsDataURL(file);
  }

  // upload and close popup
  uploadThumbnail() {
    this.dataService.putURL(`user/videos/${this.video_object['id']}/`, this.formData, {
      responseType: 'json',
      reportProgress: true,
      observe: 'body'
    }).subscribe((resp: VideoView) => {
      this.snackBar.open(
        this.translateService.instant('Gespeichert'), '', {duration: 2500}
      );
      this.dialogRef.close(resp);
    }, (error) => {
      this.snackBar.open(this.translateService.instant('Etwas ist schief gelaufen'),
        '', {duration: 2500});
    });
  }

  removeThumbnail() {
    this.imageSrc = '';
    delete this.formData;
    this.thumbnailForm.get('attachment').setValue(undefined);
    const fileInput = <HTMLInputElement>document.querySelector('ngx-mat-file-input[formcontrolname="attachment"] input[type="file"]');
    fileInput.value = null;
  }

  // make the drag drop div visible or hidden
  showHideDropDiv(hidden: boolean = false) {
    document.getElementById('drop-div').hidden = hidden;
  }

  // when user drops a file
  dropHandler(event) {
    event.preventDefault();
    this.showHideDropDiv(true);
    this.removeThumbnail();  // remove existing if any
    const file = event.dataTransfer.files[0];
    // attach file
    this.attachFile(file);
  }

  attachFile(file_obj: File) {
    // todo: check file type
    if (file_obj.size > this.maxSize5MB) {
      const message = this.translateService.instant("Limit Exceeded: ") +
        `${Math.floor(file_obj.size / 1000000)} MB / ${this.max_file_size_string}`;
      window.alert(message);
      return;
    }

    if (!file_obj.type.includes('image')) {
      // file is not an image, reject
      const message = this.translateService.instant("Please select an image");
      window.alert(message);
      return;
    }

    this.readFileAsString(file_obj);
    this.formData = new FormData();

    // take the last 99 characters of file name, because backend limit is 100 characters
    let file_name = file_obj.name.slice(-99);
    // add the data to the form
    this.formData.append("thumbnail", file_obj, file_name);
  }
}
