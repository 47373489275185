import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
// .then( ()=>{
//   console.log("value in cookie")
//   if(!navigator.cookieEnabled){
//   console.log("cookies disabled");
//   }
// })
  .catch(err => console.error(err));
