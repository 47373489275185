import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import * as ysFixWebmDuration from '../scripts/fix-webm-duration.js';

@Injectable({
    providedIn: 'root'
})
export class ScreencastWebcamService {

    stoppedCapturing: Subject<void> = new Subject();
    stoppedCapturingVideo: Subject<void> = new Subject();

    public mediaRecorder: MediaRecorder;
    public mediaRecorderVideo: MediaRecorder;
    screenCast: any;

    private startTime: number;
    private duration: number;
    audio: any;
    videoCap: MediaStream;
    videoDeviceId: any;
    private isPaused = false;
    audioMuted: boolean = true;
    noiseSuppress: boolean = true;
    videodevice: any;
    echoCancellation:boolean = true;
    audioInputDetected: any;
    videoInputDetect: any;
    private audioInputVal = new Subject<string>();
    audioVal = this.audioInputVal.asObservable();
    private videoInputVal = new Subject<string>();
    videoVal = this.videoInputVal.asObservable();
    selval: any;
    selectedVal: any;
    selectedVideoVal: any;
    audioSource = undefined;
    videoSource = undefined;
    videoMode: any;


    constructor() {
    }

    // tslint:disable-next-line:variable-name
    private _chunksScreencast: Array<Blob>;

    get chunks(): Array<Blob> {
        return this._chunksScreencast;
    }

    // tslint:disable-next-line:variable-name
    private _chunksMedia: Array<Blob>;

    get chunksMedia(): Array<Blob> {
        return this._chunksMedia;
    }


    async onSelectScreen() : Promise<MediaStream> {
        // this.videoDeviceId = deviceId.deviceId;
        const mediaDevices = navigator.mediaDevices as any;
        if (this.audioSource === 'undefined') {
            this.selval = {
                audio: true
                // audio:
                // {
                // echoCancellation: this.echoCancellation, noiseSuppression: this.noiseSuppress
                // }
            };
        } else {
            this.selval = {
                audio: {
                    deviceId: { exact: this.audioSource },
                    echoCancellation: this.echoCancellation,
                    noiseSuppression: this.noiseSuppress
                }
            }
        }
        if(this.videoSource  === undefined){
            this.videoMode = {
                video: {
                    width: { ideal: 320 },
                    height: { ideal: 240 },
                    facingMode: "user",
                    frameRate: 30
                },
                audio: false
            }
        }else{
            this.videoMode ={
                video: {
                    deviceId: { exact:this.videoSource }
                },
                audio: false
            }

        }
        this.screenCast = await mediaDevices.getDisplayMedia({
            video: {
                cursor: 'always',
                frameRate: 30,
                height: 1440
            }
        }).then((strem: any) => strem)
            .catch((err) => { console.log('Access Denied', err); return 'deniedDisplay'; });

        if (this.screenCast == 'deniedDisplay') {
            return null;
        }

        this.audio = await navigator.mediaDevices.getUserMedia(this.selval);

        this.videoCap = await navigator.mediaDevices.getUserMedia(this.videoMode);

        return this.screenCast;
    }

    async startCapture(): Promise<any> {
        if (this.screenCast == 'deniedDisplay') {
            return null;
        };
        //    { video: {
        //         width: { ideal: 720 },
        //         height: { ideal: 480 },
        //         facingMode: "user"
        //     },
        //     audio: false
        // }


        this._chunksScreencast = [];
        this._chunksMedia = [];
        this.screenCast.addEventListener('inactive', () => {
            this.screenCast.getTracks().forEach(track => track.stop());
            this.audio.getTracks().forEach(track => track.stop());
            this.videoCap.getTracks().forEach(track => track.stop());
            if (this.mediaRecorder.state != 'inactive') {
                this.mediaRecorder.stop();
            }
            if (this.mediaRecorderVideo.state != 'inactive') {
                this.mediaRecorderVideo.stop();
            }
        });
        const options = {
            audioBitsPerSecond: 128000,
            videoBitsPerSecond: 5000000
        };
        const supportedMimeTypes = this.getSupportedMimeTypes();
        options['mimeType'] = supportedMimeTypes[0];
        if (!MediaRecorder.isTypeSupported(options['mimeType'])) {
            console.error(`${options['mimeType']} is not supported`);
            options['mimeType'] = '';
        }
        this.mediaRecorder = new MediaRecorder(new MediaStream([...this.screenCast.getTracks(), ...this.audio.getTracks()]), options);
        this.mediaRecorderVideo = new MediaRecorder(new MediaStream(this.videoCap.getTracks()), options);

        this.mediaRecorder.addEventListener('dataavailable', event => {
            if (event.data && event.data.size > 0) {
                this._chunksScreencast.push(event.data);
                this.stoppedCapturing.next();
            }
        });
        this.mediaRecorderVideo.addEventListener('dataavailable', event => {
            if (event.data && event.data.size > 0) {
                this.chunksMedia.push(event.data);
                this.stoppedCapturingVideo.next();
            }
        });
        setTimeout(() => {
            if (!this.audioMuted) {
                this.audio.getTracks().forEach(function (track) {
                    track.enabled = false;
                });
            }
            this.duration = 0;
            this.mediaRecorder.start();
            this.mediaRecorderVideo.start();
            this.startTime = Date.now();
            this.isPaused = false;
        }, 2800);

        return [this.screenCast, this.videoCap];

    }

    stopScreen(): void {
        this.screenCast.getTracks().forEach(track => track.stop());
    }

    stopCapture(): void {
        if (!this.isPaused) {
            this.duration = this.duration + (Date.now() - this.startTime);
        }
        if (this.mediaRecorder.state != 'inactive') {
            this.mediaRecorder.stop();
        }
        if (this.mediaRecorderVideo.state != 'inactive') {
            this.mediaRecorderVideo.stop();
        }
        this.screenCast.getTracks().forEach(track => track.stop());
        this.audio.getTracks().forEach(track => track.stop());
        this.videoCap.getTracks().forEach(track => track.stop());
    }

    pauseScreencast(): void {
        this.mediaRecorder.pause();
        this.mediaRecorderVideo.pause();
        this.duration = this.duration + Date.now() - this.startTime;
        this.isPaused = true;
    }
    resumeScreencast(): any {
        this.mediaRecorder.resume();
        this.mediaRecorderVideo.resume();
        this.startTime = Date.now();
        this.isPaused = false;
        return [this.screenCast, this.videoCap];
    }

    recording(): boolean {
        return this.mediaRecorder?.state === 'recording';
    }

    getFixedDurationBlobURL(): Promise<string> {
        return new Promise(((resolve, reject) => {
            ysFixWebmDuration(new Blob([this.chunks[0]]), this.duration, res => {
                resolve(window.URL.createObjectURL(res));
            });
        }));
    }

    getFixedDurationVideoBlobURL(): Promise<string> {
        return new Promise(((resolve, reject) => {
            ysFixWebmDuration(new Blob([this.chunksMedia[0]]), this.duration, res => {
                resolve(window.URL.createObjectURL(res));
            });
        }));
    }
    getSupportedMimeTypes(): object {
        const VIDEO_TYPES = [
            "webm",
            "ogg",
            "mp4",
            "x-matroska"
        ];
        const VIDEO_CODECS = [
            "vp9",
            "vp9.0",
            "vp8",
            "vp8.0",
            "avc1",
            "av1",
            "h265",
            "h.265",
            "h264",
            "h.264",
            "opus",
        ];

        const supportedTypes = [];
        VIDEO_TYPES.forEach((videoType) => {
            const type = `video/${videoType}`;
            VIDEO_CODECS.forEach((codec) => {
                const variations = [
                    `${type};codecs=${codec}`,
                    `${type};codecs:${codec}`,
                    `${type};codecs=${codec.toUpperCase()}`,
                    `${type};codecs:${codec.toUpperCase()}`
                ];
                variations.forEach(variation => {
                    if (MediaRecorder.isTypeSupported(variation)) {
                        supportedTypes.push(variation);
                    }
                })
            });
            if (MediaRecorder.isTypeSupported(type)) {
                supportedTypes.push(type);
            }
        });
        return supportedTypes;
    }

    getAudioToggle(value): any {
        if (this.audio == undefined) {
            this.audioMuted = value;
        }
        else if (!value) {
            this.audioMuted = false;
            this.audio.getTracks().forEach(function (track) {
                track.enabled = false;
            });
        } else {
            this.audioMuted = true;
            this.audio.getTracks().forEach(function (track) {
                track.enabled = true;
            });

        }
    }
    getAudioVal() {
        var audioArr = [];
        var videoArr = [];
        this.selectedVal = navigator.mediaDevices.getUserMedia({ audio: true})
            .then(() => navigator.mediaDevices.enumerateDevices())
            .then(devices => {
                devices.forEach(function (d) {
                    if (d.kind === 'audioinput') {
                        audioArr.push({ label: d.label, deviceId: d.deviceId });
                    } else if (d.kind === 'videoinput') {
                        videoArr.push({ label: d.label, deviceId: d.deviceId });
                      }
                    }
                    );
                     this.getVideoValue(videoArr);
                     this.getAudioValue(audioArr);
            })
            .catch(e => console.log(e)); 
    }


    // getVideoVal() {
    //     var videoInputArr = [];
    //     this.selectedVideoVal = navigator.mediaDevices.getUserMedia({video: true })
    //         .then(() => navigator.mediaDevices.enumerateDevices())
    //         .then(devices => {
    //             devices.forEach(function (d) {
    //                 if (d.kind === 'videoinput') {
    //                     videoInputArr.push({ label: d.label, deviceId: d.deviceId })
    //                 }
    //             }
    //             );
    //             this.getVideoValue(videoInputArr);
    //         })
    //         .catch(e => console.log(e));
    // }
    getNoiseToggle(value): any {
        this.noiseSuppress = value;
        this.echoCancellation = value;
    }

    getAudioValue(data) {
        this.audioInputDetected = data;
        this.audioInputVal.next(this.audioInputDetected);
    }
    getVideoValue(data) {
        this.videoInputDetect = data;
        this.videoInputVal.next(this.videoInputDetect);
    }
    getSelectionVal(data) {
        this.audioSource = data;
    }
    getVideoSelectionVal(data) {
        this.videoSource = data;
    }

    destroySubject() {
        // this.stoppedCapturing.complete();
        // this.stoppedCapturingVideo.unsubscribe();
        // this.stoppedCapturingVideo.complete()
        this.stoppedCapturing = new Subject();
        this.stoppedCapturingVideo = new Subject();
    }
}
