<div *ngIf="showSpinner"
     class="d-flex justify-content-center align-items-center w-100 h-100 position-fixed bg-dark"
     style="z-index: 11; opacity: 0.6;">
  <mat-spinner></mat-spinner>
</div>

<div class="p-3 d-flex justify-content-between sticky-head" style="flex-flow: wrap;">
  <div class="mt-2">
    <button (click)="navbarService.addPlaylist()" class="border-radius-20" mat-stroked-button
            matTooltip="{{ 'Neue Playlist erstellen' | translate }}">
      <mat-icon class="mr-1">
        add
      </mat-icon>
      {{ "Neue Playlist" | translate }}
    </button>
  </div>

  <div>
    <mat-form-field appearance="outline" class="mr-3">
      <mat-label translate>Search for…</mat-label>
      <input (keyup)="applyFilter();" [(ngModel)]="searchQuery" autocomplete="off" matInput
             placeholder="{{'Titel' | translate}}">
    </mat-form-field>

    <mat-form-field appearance="outline" class="mr-3">
      <mat-label translate>Sort</mat-label>
      <mat-select (selectionChange)="applyFilter()" [(ngModel)]="sort_option" value="last_edited_on">
        <mat-option value="added_on">{{'Newest'|translate}}</mat-option>
        <mat-option value="last_edited_on">{{'Last edited'|translate}}</mat-option>
        <mat-option value="title">{{'Titel'|translate}}</mat-option>
        <mat-option value="videos">Clypps</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label translate>Filter</mat-label>
      <mat-select (selectionChange)="applyFilter();" [(ngModel)]="filter_option" value="all">
        <mat-option value="all"><span translate>Alle</span></mat-option>
        <mat-option value="drafts"><span translate>Privat</span></mat-option>
        <mat-option value="published"><span translate>Veröffentlicht</span></mat-option>
        <mat-option value="empty"><span translate>Empty</span></mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<!--In case of no clypps, show text-->
<mat-card-subtitle class="mx-2 text-nunito"
                   *ngIf="all_playlists.length == 0"
                   translate>
  You have not created a playlist yet
</mat-card-subtitle>


<mat-grid-list cols="1" gutterSize="1rem" rowHeight="160">
  <mat-grid-tile *ngFor="let playlist of filtered_playlist" class="playlist-tile">
    <mat-card class="playlist-card w-100 ml-1 bg-transparent inherit-height d-flex justify-content-between">
      <mat-card-header class="cursor-pointer">

        <!--image part-->
        <div class="imageContainer d-none d-md-flex">
          <div class="position-absolute w-100 h-100 border-radius-10"
            style="background-color: #979797; transform: rotate(-5.627deg);">
            <!--tilted gray block-->
          </div>
          <div class="edit-div cursor-pointer"
               [routerLink]="['/createPlaylistMyVideos', playlist.id]">
            <span class="d-flex align-items-center text-nunito"
                  style="color:white" translate>
              <mat-icon class="mr-1 material-icons-outlined">edit</mat-icon>
              Bearbeiten
            </span>
          </div>
          <img alt="thumbnail" class="div-card-thumbnail w-100 h-100"
               onerror="this.onerror=null; this.src='assets/images/no_videos.png'" src="{{playlist.thumbnail}}"
               style="all: inherit;">
          <div (click)="viewPlaylist(playlist)" class="video-counter" style="
                     display: flex;
                     flex-direction: column;
                     justify-content: center;
                     align-items: center;
                     cursor: pointer;
                   ">
            <div style="font-size: 16px; font-weight: bolder; color: white">
              {{ playlist.video_count }}
            </div>
            <div>
              <mat-icon style="
                         font-size: 25px;
                         width: 25px;
                         height: 25px;
                         color: white;
                         margin-top: 1.5rem;
                         margin-left: 0.5rem;
                       ">
                playlist_play
              </mat-icon>
            </div>
          </div>
        </div>

        <!--title part-->
        <div class="ml-4 mt-3" style="overflow: hidden;" (click)="viewPlaylist(playlist)">
          <mat-card-title [title]="playlist.title" class="card-title text-nunito"
                          style="font-size: 15px;">
            {{playlist.title}}
          </mat-card-title>
          <mat-card-subtitle class="text-nunito">
            <span matTooltip="{{'Erstellt am'|translate}}&nbsp;{{playlist.added_on | date:'dd.MM.y'}}"
                  matTooltipPosition="below">
              {{'Updated' | translate}}
              {{utilityService.timeSince(playlist.last_edited_on, "ago", false) |async}}
            </span>
          </mat-card-subtitle>
        </div>
      </mat-card-header>

      <mat-card-content class="d-flex align-items-center justify-content-end">
        <span *ngIf="!playlist.is_draft" class="badge mx-2 text-nunito font-medium"
              style="background-color: #DCF4E8; color: darkgreen;" translate>
          Public
        </span>
        <span *ngIf="playlist.is_draft" class="badge mx-2 text-nunito font-medium"
              style="background-color: #FEE2E2; color: darkred;" translate>
          Privat
        </span>

        <button [matMenuTriggerFor]="menu" aria-label="more options" mat-icon-button>
          <mat-icon>more_horiz</mat-icon>
        </button>

        <!-- Options part -->
        <mat-menu #menu="matMenu">
          <button [routerLink]="['/createPlaylistMyVideos', playlist.id]" mat-menu-item>
            <mat-icon class="mr-2 material-icons-outlined" mat-list-icon>edit</mat-icon>
            <span class="text-nunito" translate>Bearbeiten</span>
          </button>
          <button (click)="copyLink(playlist.id)"
                  [disabled]="playlist.is_draft" mat-menu-item>
            <mat-icon class="mr-2 material-icons-outlined" mat-list-icon>link</mat-icon>
            <span class="text-nunito" translate>Copy link</span>
          </button>
          <button (click)="setPrivate(playlist, false)"
                  *ngIf="playlist.is_draft"
                  [disabled]="!(playlist.video_count && is_creator)"
                  matTooltipPosition="left"
                  [matTooltip]="is_creator ? (playlist.video_count ? '' : ('This playlist is empty'|translate)) : ('You need Creator rights'|translate)"
                  class="text-nunito" mat-menu-item>
            <mat-icon class="mr-2 material-icons-outlined" mat-list-icon>cloud_done</mat-icon>
            <span class="text-nunito" translate>Veröffentlichen</span>
          </button>
          <button (click)="setPrivate(playlist, true)"
                  *ngIf="!playlist.is_draft" mat-menu-item>
            <mat-icon class="mr-2 material-icons-outlined" mat-list-icon>cloud_off</mat-icon>
            <span class="text-nunito" translate>Save as private</span>
          </button>
          <hr class="m-0">
          <!--Only drafts can be re-assigned by a creator-->
          <button (click)="changeUploader(playlist)"
                  matTooltipPosition="left"
                  [disabled]="!(playlist.is_draft && is_creator)"
                  [matTooltip]="is_creator ? (playlist.is_draft ? '' : ('Only drafts can be transferred to others'|translate)) : ('You need Creator rights'|translate)"
                  mat-menu-item>
            <mat-icon class="mr-2" mat-list-icon>drive_file_move_outline</mat-icon>
            <span class="text-nunito" translate>Transfer</span>
          </button>
          <button (click)="deletePlaylist(playlist)" mat-menu-item>
            <mat-icon class="mr-2 material-icons-outlined" mat-list-icon>delete</mat-icon>
            <span class="text-nunito" translate>Löschen</span>
          </button>
        </mat-menu>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
