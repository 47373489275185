<div class="d-flex flex-column h-100">
  <!-- <div *ngIf="!permissionDenied" class=" mb-0 text-center flex-grow-1">
    <video style="margin-top: -150px" id="webcam" [ngClass]="type === 'BOTH' ? 'vid-size' : 'webcamSize'"   #webcamVideo autoplay [muted]="true" [srcObject]="src" class="screen-container w-100">
    </video>
    </div> -->
  <div *ngIf="!permissionDenied"  class="screen-container mb-0 text-center flex-grow-1" >
    <video id="webcam" style="margin-top: 2rem;margin-bottom: 0.5rem;" [ngClass]="type === 'BOTH' ? 'vid-size' : 'webcamSize'" #webcamVideo autoplay [muted]="true" [srcObject]="src"  class="screen-container">
    </video>
  </div>

  <div *ngIf="permissionDenied" class="fontNormal jumbotron jumbotron-fluid mb-0 text-center flex-grow-1 recording-window1" (click)="startButtonClicked()">

   <button mat-raised-button color="primary" style="color: white" >{{'Recheck webcam mic' | translate}}</button>
    <p style="align-items: center;padding-top: 1.2rem;" translate>Please allow the application to access your microphone and / or your camera.</p>
    <p translate>This can be done in the settings of your browser.</p>
  </div>
</div>
