<div class="app-sc-record-dialog" (window:resize)="windowResized();" (dragenter)="fileDragged=true;">

  <!-- to manage overlays -->
  <div *ngIf="count_down_number || is_long_video || inProgress || fileDragged"
       class="in-progress-case w-100 h-100 position-absolute justify-content-center align-items-center d-flex flex-column"
       (dragleave)="fileDragged=false;"
       (dragover)="$event.preventDefault();"
       (drop)="dropHandler($event);">
    <!-- occurs at 180th second and 600th second-->
    <ng-container *ngIf="is_long_video">
      <p class="text-white text-nunito line-height-initial" style="font-size: 50px">
        {{long_video_title}}
      </p>
      <p class="text-white text-nunito mt-3" style="font-size: 20px">
        {{long_video_subtitle}}
      </p>
      <button mat-stroked-button class="mt-2" (click)="dismissMessage()" style="border-color: white !important;">
        <span class="text-white text-nunito" translate>Dismiss</span>
      </button>
    </ng-container>

    <!-- occurs when recording is started -->
    <ng-container *ngIf="count_down_number">
      <span class="text-white text-nunito" style="font-size: 100px;">
        {{ count_down_number }}
      </span>
    </ng-container>

    <!--occurs when file is dragged-->
    <div *ngIf="fileDragged" class="text-white text-nunito" style="font-size: 24px;">
      {{ 'Drag and drop files to upload' | translate }}
    </div>

    <!-- occurs when uploading or joining videos or saving notes or creating audio overlays-->
    <ng-container *ngIf="inProgress">
      <!-- occurs when joining videos -->
      <mat-spinner class="position-absolute"></mat-spinner>
      <span class="text-white text-nunito" style="margin-top: 160px;">
        {{in_progress_text}}
      </span>
    </ng-container>
  </div>

  <mat-toolbar mat-toolbar class="bg-white mat-elevation-z2">
    <mat-toolbar-row class="justify-content-between">
      <div class="d-flex justify-content-start w-25">
        <button mat-stroked-button color="primary" class="mr-1 mt-3 mb-3 ml-3"
                style="border-bottom-left-radius: 20px; border-top-left-radius: 20px;"
                [disabled]="show_pause_button"
                matTooltip="{{'Delete Clypp'|translate}}"
                (click)="deleteVideo();">
          <mat-icon class="icon-for-standard-button">delete_outline</mat-icon>
        </button>
        <button mat-stroked-button color="primary" (click)="saveNotes(true);" class="ml-0 mt-3 mb-3"
                matTooltip="{{'Back'|translate}}"
                style="border-bottom-right-radius: 20px; border-top-right-radius: 20px;">
          <mat-icon class="icon-for-standard-button">arrow_back_ios</mat-icon>
          <span translate class="d-none d-lg-inline">Back</span>
        </button>
      </div>

      <img (click)="back();"
           title="{{'To homepage'|translate}}" alt="Company Logo"
           class="cursor-pointer company_logo"
           onerror="this.onerror=null; this.src='assets/logo/clypp_logo_small.png'"
           src="{{authService.company.company_logo}}">

      <div class="d-flex justify-content-end w-25">
        <button mat-button (click)="needHelp()">
          <mat-icon class="icon-for-standard-button">help_outline</mat-icon>
        </button>

        <!--Show edit button and skip button on desktop window-->
        <!--Show edit button and skip button on mobile window-->
        <button mat-flat-button color="primary" class="w-50 mr-0 mt-3 ml-3 mb-3"
                style="border-bottom-left-radius: 20px; border-top-left-radius: 20px;"
                *ngIf="!pending_media_count"
                matTooltip="{{'Bearbeiten'|translate}}"
                (click)="finish(false);" [disabled]="!show_finish_button"
                [ngStyle]="show_finish_button ? {} : {'background-color': 'lightgray'}">
          <span translate class="d-none d-lg-inline">Bearbeiten</span>
          <mat-icon class="icon-for-standard-button">arrow_forward_ios</mat-icon>
        </button>
        <button mat-flat-button color="primary" class="ml-1 mt-3 mr-3 mb-3"
                style="border-bottom-right-radius: 20px; border-top-right-radius: 20px;"
                *ngIf="!pending_media_count"
                matTooltip="{{'Skip editing'|translate}}"
                (click)="finish(true);" [disabled]="!show_finish_button"
                [ngStyle]="show_finish_button ? {} : {'background-color': 'lightgray'}">
          <mat-icon class="icon-for-standard-button material-icons-outlined">fast_forward</mat-icon>
        </button>

        <button mat-flat-button color="primary" class="m-3 border-radius-20 w-50"
                *ngIf="pending_media_count"
                disabled>
          <!-- disabled buttons does not show tooltips -->
          <span [matTooltip]="translateService.instant('n file(s) remaining', {n: pending_media_count})">{{pending_media_count}}</span>
          <mat-icon class="icon-for-standard-button ml-2" *ngIf="progress!=100" matTooltip="{{'Uploading'|translate}}">
            hourglass_top
          </mat-icon>
          <mat-icon id="hourglass" class="icon-for-standard-button ml-2" *ngIf="progress==100" matTooltip="{{'Putting on the finishing touches…'|translate}}">
            hourglass_bottom
          </mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- A progress bar to show that medias are being uploaded -->
  <mat-progress-bar
    *ngIf="pending_media_count"
    style="overflow: hidden"
    color="primary"
    mode="determinate"
    [value]="progress / pending_media_count">
    <!--    todo: check for divide by zero error  -->
  </mat-progress-bar>

  <mat-grid-list [cols]="total_columns" rowHeight="10%">
    <!--Assuming that the top toolbar is 10%, we can have upto 9 rows without scroll-->
    <!--when changing row height, must change in button-toggle in css file-->

    <!-- row 1-2-3-4 column 1 -->
    <!--  Record / pause / upload button -->
    <mat-grid-tile [rowspan]="record_button_row_span" colspan="1">
      <div class="w-100 text-center d-flex flex-flow-column align-items-center">
        <button (click)="record()" *ngIf="show_record_button" class="mt-2 text-white w-75 border-radius-10 height-50"
                color="warn"
                mat-flat-button>
          <mat-icon class="icon-for-standard-button">radio_button_checked</mat-icon>
          <span *ngIf="!show_reset_button" translate>Start recording</span>
          <span *ngIf="show_reset_button" translate>Add recording</span>
        </button>

        <button (click)="pause()" *ngIf="show_pause_button"
                class="mt-2 text-white w-75 border-radius-10 height-50"
                color="warn"
                id="pause-button"
                mat-flat-button>
          <mat-icon class="icon-for-standard-button">pause</mat-icon>
          <span translate>Pause recording</span>
        </button>

        <label *ngIf="show_upload_button"
               class="mt-2 w-75 border-radius-10 file-upload-label-button height-50"
               for="file-upload">
          <div class="d-flex justify-content-center w-100 align-items-center" style="margin-top: 12px;">
            <mat-icon>upload_file</mat-icon>
            <span translate>Select a file</span>
          </div>
        </label>

        <button (click)="reset()" mat-stroked-button
                class="border-radius-10 w-75 mt-3 height-50"
                [disabled]="pending_media_count > 0 || !show_reset_button"
                style="border-color: lightgray !important;">
          <mat-icon class="icon-for-standard-button">cached</mat-icon>
          <span translate>Reset everything</span>
        </button>

        <input (change)="fileSelected($event)"
               accept=".mp4,.mov,.m4v,.webm,.jpg,.png,.gif,.pdf,.mkv,.mts,.mp3,.m4a,.wav"
               hidden id="file-upload" type="file"/>

        <div class="text-center w-100 mt-3" style="color: darkslategray">
          <h3 class="mb-1">
            <label id="currentMinutes">00</label>
            <span id="colon">:</span>
            <label id="currentSeconds">00</label>&nbsp;/
            <label id="maxMinutes">00</label>:<label id="maxSeconds">00</label>
          </h3>
          <h4 class="mb-3" translate>
            Try to keep your Clypps short
            <mat-icon class="icon-for-standard-button"
                      matTooltip="{{'A viewers attention can significantly decrease after watching even 2 Minutes of the same content.' | translate}}"
                      matTooltipPosition="right"
                      style="vertical-align: middle">help_outline
            </mat-icon>
          </h4>
        </div>
      </div>
    </mat-grid-tile>

    <!-- row 1-2-3-4 column 2-3 -->
    <mat-grid-tile colspan="2" [rowspan]="notes_row_span" class="justify-content-between" *ngIf="show_notes">
      <div class="d-flex flex-column" [style.width.px]="recordingDivWidth">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label translate>What is your Clypp about?</mat-label>
          <input [(ngModel)]="video_title" class="notes-text-area" matInput maxlength="100"
                 placeholder="{{'Titel'|translate}}" id="title-input"
                 required>
        </mat-form-field>

        <!--video script-->
        <mat-form-field appearance="outline" class="w-100">
          <mat-label translate="Write your script here or auto-create from title"></mat-label>
          <!--text area needs max width as there are buttons-->
          <textarea [(ngModel)]="video_notes" class="notes-text-area" matInput
                    style="max-width: 95%"
                    placeholder="{{'In this video'|translate}}" [rows]="text_area_rows">
          </textarea>

          <!--expand collapse notes button-->
          <button mat-icon-button matTooltip="{{'Expand'|translate}}" matTooltipPosition="right"
                  (click)="expandNotes();" color="primary"
                  class="position-absolute" style="right: -10px;">
            <mat-icon>expand</mat-icon>
          </button>

          <!--menu button: create notes or video script-->
          <button mat-icon-button matTooltip="{{'Ask Clypp AI'|translate}}" matTooltipPosition="right"
                  *ngIf="authService.company.is_transcription_service_enabled"
                  color="primary" [matMenuTriggerFor]="scriptMenu"
                  class="position-absolute" style="right: -10px; top: 60px;">
            <mat-icon class="material-icons-outlined">auto_fix_high</mat-icon>
          </button>

          <mat-menu #scriptMenu="matMenu">
            <button (click)="predictScript('predict');" [disabled]="video_title.length == 0" mat-menu-item>
              <mat-icon class="material-icons-outlined">auto_awesome</mat-icon>
              <span translate="Generate with AI"></span>
            </button>
            <button matTooltip="pdf, jpg (beta)" matTooltipPosition="right" mat-menu-item>
              <label class="mb-0 d-flex align-items-center" for="notes-file-upload">
                <mat-icon class="material-icons-outlined">movie_filter</mat-icon>
                <span translate="Generate video from file"></span>
              </label>
            </button>
            <input (change)="fileSelectedForVideoGeneration($event);"
                   accept=".pdf,.jpg" hidden id="notes-file-upload"
                   type="file"/>
            <hr class="m-0">
            <button (click)="predictScript('improve');" [disabled]="video_notes.length == 0" mat-menu-item>
              <mat-icon>spellcheck</mat-icon>
              <span translate="Improve text"></span>
            </button>
            <button (click)="predictScript('compact');" [disabled]="video_notes.length == 0" mat-menu-item>
              <mat-icon>short_text</mat-icon>
              <span translate="Make it shorter"></span>
            </button>
          </mat-menu>
        </mat-form-field>
      </div>
    </mat-grid-tile>

    <!-- row 1-2-3-4 column 4 -->
    <!-- hide all options in smaller window -->
    <mat-grid-tile [colspan]="mode_selector_col_span" [rowspan]="record_button_row_span">
      <div class="w-100 d-flex flex-column align-items-center">

        <mat-form-field appearance="outline" class="sourceField">
          <mat-label translate>
            Camera
          </mat-label>
          <mat-select [(ngModel)]="camera_source"
            (selectionChange)="sourceChanged(video_source, mic_source, $event.value)" [disabled]="!show_cam_selection">
            <!--(mouseenter)="cameraSelectorMouseOver(camera.deviceId)" (mouseleave)="stopTracks();"-->
            <mat-option [value]="camera.deviceId" *ngFor="let camera of cameras; let index = index;">
              <!--In some cases, label is not provided by the browser, so we user text like "Microphone 1"-->
              {{camera.label || ("Camera" | translate) + " " + (index+1) }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="sourceField">
          <mat-label translate>
            Microphone
          </mat-label>
          <mat-select [(ngModel)]="mic_source"
            (selectionChange)="sourceChanged(video_source, $event.value, camera_source)"
            [disabled]="!show_mic_selection">
            <mat-option [value]="mic.deviceId" *ngFor="let mic of mics; let index = index;">
              <!--In some cases, label is not provided by the browser, so we user text like "Microphone 1"-->
              {{mic.label || ("Microphone" | translate) + " " + (index+1) }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="sourceField">
          <mat-label translate>Recording Quality</mat-label>
          <!--it should be disabled if there are medias or we are recording something-->
          <mat-select [(ngModel)]="videoObj.project_settings"
                      (selectionChange)="updateProjectSettings($event);"
                      matTooltipPosition="left"
                      matTooltip="{{medias.length > 0 ? disabled_quality_tooltip: ''}}"
                      [disabled]="show_pause_button || medias.length > 0 || pending_media_count > 0">
            <mat-option value="720">
              {{'Fast Processing (720p)'|translate}}
            </mat-option>
            <mat-option value="1080"
                        [disabled]="disable_medium_quality"
                        matTooltipPosition="left"
                        [matTooltip]="medium_quality_tooltip">
              {{'Optimal (1080p)'|translate}}
            </mat-option>
            <mat-option value="1440"
                        [disabled]="disable_high_quality"
                        matTooltipPosition="left"
                        matTooltip="{{high_quality_tooltip}}">
              {{'High Resolution (1440p)'|translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--only show this field if you are a global manager or there are more than 0 options-->
        <mat-form-field *ngIf="videoProfileOptions.length || authService.userDetails.is_global_manager || authService.userDetails.is_quality_manager"
                        appearance="outline"
                        class="sourceField">
          <!--Translation is not needed as same in German-->
          <mat-label>Content Branding</mat-label>
          <mat-select [value]="initial_value_for_ci_selector"
                      id="ci-profile-select"
                      (selectionChange)="updateVideoProfile($event);"
                      matTooltipPosition="left"
                      [matTooltip]="medium_quality_tooltip"
                      [disabled]="disable_medium_quality">
            <mat-option [value]="0">
              {{'Default'|translate}}
            </mat-option>
            <mat-option *ngFor="let option of videoProfileOptions"
                        [value]="option.id">
              {{option.name}}
            </mat-option>
            <mat-option value=-1
                        [disabled]="this.show_pause_button || this.pending_media_count > 0"
                        *ngIf="authService.userDetails.is_global_manager || authService.userDetails.is_quality_manager">
              <mat-icon>add</mat-icon>
              {{'Add new'|translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-grid-tile>

    <!-- row 5-6-7-8-9 column 1 -->
    <mat-grid-tile [colspan]="mode_selector_col_span" rowspan="5" style="margin-top: 1rem;">
      <div class="w-75 border-radius-10" style="height: unset;">
        <mat-button-toggle-group [(ngModel)]="video_source" [disabled]="!show_type_selection"
                                 (change)="sourceChanged($event.value, mic_source, camera_source)"
                                 vertical="true" class="w-100 border-radius-20">
          <mat-button-toggle [disabled]="disable_screen_source" [value]="'screen'">
            <mat-icon class="material-icons-outlined">screen_share</mat-icon>
            {{'Screen only'|translate}}
          </mat-button-toggle>
          <mat-button-toggle [disabled]="disable_camera_source" [value]="'camera'">
            <mat-icon class="material-icons-outlined">videocam</mat-icon>
            {{'Webcam only'|translate}}
          </mat-button-toggle>
          <mat-button-toggle [disabled]="disable_camera_source || disable_mic_source || disable_screen_source" [value]="'both'">
            <mat-icon class="material-icons-outlined">picture_in_picture_alt</mat-icon>
            {{'Both screen & webcam'|translate}}
          </mat-button-toggle>
          <mat-button-toggle [disabled]="disable_mic_source" [value]="'mic'">
            <mat-icon class="material-icons-outlined">volume_up</mat-icon>
            {{'Audio only'|translate}}
          </mat-button-toggle>
          <mat-button-toggle [value]="'upload'">
            <mat-icon class="material-icons-outlined">cloud_upload</mat-icon>
            {{'Upload file'|translate}}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-grid-tile>

    <!-- row 5-6-7-8-9 column 2-3 -->
    <mat-grid-tile [colspan]="video_col_span" [rowspan]="video_row_span" style="margin-top: 1rem;">
      <div class="w-100 h-100" style="text-align: -webkit-center; text-align: -moz-center;">
        <!--video screen-->
        <div class="recording-div" [style.background-image]="'url(' + custom_bg_url + ')'">
          <video #htmlFirstVideoElement autoplay playsinline [muted]="true" [hidden]="!video"
                 [height]="firstHTMLVideoElementHeight"
                 [width]="firstHTMLVideoElementWidth"
                 style="width: 100%;height: 100%; z-index: 10;border-radius: 15px;">
          </video>
          <video autoplay playsinline [muted]="true" [hidden]="!overlay" id="overlay-video"
                 class="overlay-div {{webcam_overlay_style}} {{webcam_position}} w-auto">
            <!-- The above 3 classes can not be defined using ng-class -->
            <source [src]="overlay">
          </video>

          <!--canvas for the blur webcam-->
          <!--to fit the canvas perfectly, we need to scale it down as per resolution-->
          <canvas [hidden]="!performBlurInference"
                  id="videoCanvas"
                  [style.scale]="firstHTMLVideoElementClientHeight / firstHTMLVideoElementHeight"
                  style="z-index:12; position:absolute; border-radius: 15px;"></canvas>

          <!--show the overlay logo based on company data-->
          <img (error)="updatePreviewImages();"
               [class]="overlay_code"
               [src]="overlay_logo"
               onerror="this.onerror=null;"
               title="{{'This appears on the Clypps after processing.'|translate}}"
               alt="Clypp logo" style="height: 10%; z-index: 13;">
          <!--in case of error, try to reload the images. In case of onerror, nullify it, to avoid inf loop-->

          <span [hidden]="!(audio && !video)" translate class="text-white">
            Recording audio
            <!--   audio message is hidden if audio is not there but video is there  -->
          </span>

          <div *ngIf="is_entire_screen_shared" class="entire-screen-shared">
            <span translate>
              Your whole screen is being shared
            </span>
          </div>

          <!--image to show when in both mode-->
          <div class="overlay-div cursor-pointer"
               matTooltip="{{'Click to change layout'|translate}}" matTooltipPosition="above"
               matRipple
               [hidden]="video" *ngIf="video_source=='both'"
               (click)="toggleWebcamOverlayStyle();"
               [ngClass]="webcam_position">
            <img src="assets/images/wc_template_square.png" class="w-100 h-100" *ngIf="webcam_overlay_style=='rectangle'">
            <img src="assets/images/wc_template_cutout.png" class="w-100 h-100" *ngIf="webcam_overlay_style=='cutout'">
            <img src="assets/images/wc_template_circle.png" class="w-100 h-100" *ngIf="webcam_overlay_style=='circle'">
          </div>

          <!--image to show whe in webcam cutout mode-->
          <!--todo: on click, change to next image-->
          <img class="webcam-div"
               [hidden]="webcam_style!='cutout'"
               id="cutoutWebcamImage"
               [style.z-index]="1">

          <!--image to show when in webcam mode-->
          <img src="assets/images/wc_template_rectangle.png"
               [style.z-index]="2"
               class="webcam-div"
               id="webcam-template-image"
               *ngIf="video_source=='camera'" [hidden]="video">

          <span *ngIf="message" class="text-white text-nunito" style="mix-blend-mode: difference; z-index: 14">
            {{message}}
          </span>

          <!--toggle to select webcam position for both videos or camera selection-->
          <div class="webcam-position-toggle-div" [hidden]="video">
            <!--visible in case of both; hidden when recording-->
            <mat-button-toggle-group aria-label="webcam position" class="border-radius-20"
                                     *ngIf="video_source=='both'"
                                     (change)="webcamPositionChanged()"
                                     [(ngModel)]="webcam_position">
              <mat-button-toggle value="tl" matTooltip="{{'Top left'|translate}}">
                <mat-icon style="transform: scale(-1, 1);">picture_in_picture</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="tr" matTooltip="{{'Top right'|translate}}">
                <mat-icon>picture_in_picture</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="bl" matTooltip="{{'Bottom left'|translate}}">
                <mat-icon style="transform: scale(-1, 1);">picture_in_picture_alt</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="br" matTooltip="{{'Bottom right'|translate}}">
                <mat-icon>picture_in_picture_alt</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>

            <!--visible for all users in camera mode only-->
            <!--disabled in safari browser as webGL fails to load-->
            <!--only shown in 720p mode-->
            <mat-button-toggle-group aria-label="camera style" class="border-radius-20"
                                     [disabled]="isSafariBrowser"
                                     hidden
                                     *ngIf="video_source=='camera'"
                                     (change)="updateWebcamStyle();"
                                     [(ngModel)]="webcam_style">
              <!--todo: unhide or remove: [hidden]="videoObj.project_settings == '1440'" -->
              <mat-button-toggle value="rectangle" matTooltip="{{'Original'|translate}}">
                <mat-icon>blur_off</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="blur" matTooltip="{{'Blur'|translate}}">
                <mat-icon>blur_on</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle hidden matTooltip="{{'Cutout mediapipe'|translate}}"
                                 value="cutout">
                <mat-icon>person</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <!--todo: upload screen-->
        <div class="h-100" style="border: 2px dashed grey; border-radius: 15px;" hidden>
          <label for="file-upload" class="w-100 h-100">
            <mat-icon class="upload-icon">cloud_upload</mat-icon>
            <span translate class="upload-text">Select a file to upload</span>
          </label>
        </div>
      </div>
    </mat-grid-tile>

    <!-- row 5-6-7-8-9 column 4 -->
    <mat-grid-tile colspan="1" rowspan="5" style="margin-top: 1rem;">
      <mat-list cdkDropList (cdkDropListDropped)="mediaSwapped($event)"
                *ngIf="show_media_table"
                class="media-list border-radius-10 w-75">
        <!--Show a message if there are no medias-->
        <mat-list-item *ngIf="medias.length == 0"
                       class="text-nunito justify-content-center mt-3 text-center"
                       style="color: grey;">
          <span translate>Your recorded or uploaded files will appear here</span>
        </mat-list-item>

        <!--Show list if there are medias-->
        <mat-list-item *ngFor="let media of medias;let i = index" cdkDrag>
          <mat-card-subtitle class="d-flex justify-content-around w-100">
            <!-- todo: show the handle below and update the BE accordingly -->
            <mat-icon cdkDragHandle style="cursor: move; margin-top: 8px;">drag_indicator</mat-icon>
            <button (click)="playMedia(media)" aria-label="play media" mat-button
                    style="overflow: hidden; text-align: left; width: inherit;">
              <!--if there is no processed file, show a strike-through-->
              <s *ngIf="!media.processed_file" matTooltip="{{'Please refresh the page after some time'|translate}}"
                 matTooltipPosition="left">
                <!--if there is a video file, ask user to refresh after a minute-->
                <!--if there is no video file, tell user that upload failed-->
                {{media.name}}
              </s>
              <span *ngIf="media.processed_file" [title]="media.name">
                <!--if there is a processed file, show a span-->
                {{media.name}}
              </span>
            </button>
            <button mat-icon-button [matMenuTriggerFor]="mediaMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #mediaMenu="matMenu">
              <!--show download button for pro+ users-->
              <button mat-menu-item
                      matTooltipPosition="left"
                      [disabled]="disable_medium_quality"
                      [matTooltip]="medium_quality_tooltip"
                      (click)="downloadMediaFiles(media);"
                      aria-label="Download media">
                <mat-icon class="material-icons-outlined">file_download</mat-icon>
                <span translate>Herunterladen</span>
              </button>

              <!--if there is a processed file and user is pro+, show blur_bg button-->
              <button mat-menu-item
                      matTooltipPosition="left"
                      [disabled]="media.processed_file == null || disable_medium_quality"
                      [matTooltip]="medium_quality_tooltip"
                      (click)="customMediaBG(media, 'blur_bg');"
                      aria-label="Blur background">
                <mat-icon>blur_on</mat-icon>
                <span translate>Blur background</span>
              </button>

              <button mat-menu-item
                      matTooltipPosition="left"
                      [disabled]="media.processed_file == null || disable_medium_quality"
                      [matTooltip]="medium_quality_tooltip"
                      (click)="customMediaBG(media, 'custom_bg');"
                      aria-label="Remove background">
                <mat-icon>person</mat-icon>
                <span translate>Remove background</span>
              </button>

              <button mat-menu-item
                      matTooltipPosition="left"
                      [disabled]="disable_medium_quality"
                      [matTooltip]="medium_quality_tooltip"
                      (click)="customMediaBG(media, 're_process');"
                      aria-label="Re-process media">
                <mat-icon>refresh</mat-icon>
                <span translate>Revert to original</span>
              </button>

              <button mat-menu-item
                      matTooltipPosition="left"
                      [disabled]="media.processed_file == null || disable_medium_quality"
                      [matTooltip]="medium_quality_tooltip"
                      (click)="duplicateMedia(media.id);"
                      aria-label="Duplicate media">
                <mat-icon>content_copy</mat-icon>
                <span translate>Duplicate</span>
              </button>

              <!--show rename button-->
              <button mat-menu-item
                      (click)="renameMedia(media)"
                      aria-label="Rename media">
                <mat-icon>drive_file_rename_outline</mat-icon>
                <span translate>Rename</span>
              </button>

              <!--show delete button in all cases-->
              <button mat-menu-item (click)="deleteMedia(media)" aria-label="Delete media">
                <mat-icon>delete_outline</mat-icon>
                <span translate>Löschen</span>
              </button>
            </mat-menu>
          </mat-card-subtitle>
        </mat-list-item>

        <mat-progress-bar mode="indeterminate" *ngIf="show_media_progress"></mat-progress-bar>
      </mat-list>
    </mat-grid-tile>
  </mat-grid-list>
</div>
