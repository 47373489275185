import {Component, OnInit} from '@angular/core';
import {NavItem} from "../interfaces/nav-item";

@Component({
  selector: 'app-my-libraries',
  templateUrl: './my-libraries.component.html',
  styleUrls: ['./my-libraries.component.scss']
})
export class MyLibrariesComponent implements OnInit {
  routePath: NavItem[] = [];

  constructor() {
    this.routePath = [
      {
        link: 'clypps',
        name: 'Clypps',
        icon: 'play_circle'
      },
      {
        link: 'pages',
        name: 'Pages',
        icon: 'sticky_note_2'
      },
      {
        link: 'playlists',
        name: 'Playlists',
        icon: 'playlist_play'
      }
    ]
  }

  ngOnInit(): void {
  }

}
