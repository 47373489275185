import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ImageCarouselComponent implements OnInit {
  @Input('imageCarouselIndex') index: any;
  @Input('imageCarouselsrcs') srcs: any;
  @Input('carouselClickIndex') clickIndex: any;
  closeGallery: boolean = false;
  src: string = "";
  @Output() closed = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.openLink(this.clickIndex);
  }

  close_gallery() {
    this.closeGallery = false;
    this.closed.emit(this.closeGallery);
    document.getElementsByClassName('mat-sidenav')[0]['style'].visibility = 'visible';
  }
  
  move_img(event) {
    if (event.keyCode == 37)
      this.move_left();
    if (event.keyCode == 39)
      this.move_right();
  }
  
  move_left() {
    if (this.index > 0) {
      this.index -= 1;
      this.src = this.srcs[this.index]
    }
  }
  
  move_right() {
    if (this.index < this.srcs.length - 1) {
      this.index += 1;
      this.src = this.srcs[this.index]
    }
  }
  
  openLink(i) {
    this.closeGallery = true;
    this.index = i;
    this.src = this.srcs[i];
    document.getElementsByClassName('mat-sidenav')[0]['style'].visibility = 'hidden';
  }
    
}
