<div class="w-100 h-100 overflow-hidden" (dragenter)="showHideDropDiv(false);">

  <div id="drop-div" class="w-100 h-100 bg-dark border-radius-10"
       style="top: 0; left: 0; z-index: 2; display: flex;"
       hidden
       (dragover)="$event.preventDefault();"
       (dragleave)="showHideDropDiv(true);"
       (drop)="dropHandler($event);">
    <mat-card-title translate class="text-white d-flex align-items-center justify-content-center w-100 h-100">
      Drag and drop files to upload
    </mat-card-title>
  </div>

  <div class="d-flex justify-content-between mb-2" mat-dialog-title>
    <h1 class="text-nunito font-weight-bold w-100 text-center">
      {{'Update thumbnail' | translate}}
    </h1>
    <button mat-icon-button style="margin-top: -6px;">
      <mat-icon [mat-dialog-close]="true">close</mat-icon>
    </button>
  </div>

  <div class="m-2 text-nunito" translate>
    Lade ein Bild hoch, das als Video-Thumbnail verwendet werden soll (wird ggf. auf 16:9 zugeschnitten)
  </div>

  <div class="mt-4 row w-100 thumbnail-container">
    <div class="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center" style="min-height: 200px;">
      <mat-card class="crop">
        <mat-card-content [ngStyle]="imageSrc?{'border':'transparent'}:{'border':'2px dashed grey'}"
                          class="select-thumbnail">

          <form [formGroup]="thumbnailForm" style="height: 100%;">
            <mat-form-field [hidden]="imageSrc" class="w-100 fontNormal text-center" style="height:100%;color:black">
              <ngx-mat-file-input (change)="fileSelected($event)" [accept]="'.jpeg, .jpg, .png,'" [multiple]="false"
                                  class="attach" formControlName="attachment">
              </ngx-mat-file-input>
              <mat-icon class="material-icons-outlined mr-2">add_a_photo</mat-icon>
              <span translate>Update thumbnail</span>
            </mat-form-field>

            <span [hidden]="!imageSrc" class="img-wrap">
              <img [src]="imageSrc" class="h-100 position-absolute" />
              <button (click)="removeThumbnail()" class="close cursor-pointer" mat-mini-fab>
                <mat-icon>close</mat-icon>
              </button>
            </span>
          </form>

        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 col-12 d-inline-block">
      <mat-card class="thumbnail">
        <a class="a-text-simple">
          <div>
            <img [src]="video_object.thumbnail" alt="user" height="720" style="width: inherit;border-radius: 0px;" mat-card-image>
          </div>
        </a>
      </mat-card>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-2">
    <button (click)="uploadThumbnail();"
            [disabled]="!imageSrc"
            class="h-auto w-75 border-radius-20" mat-stroked-button>
      <mat-icon class="icon-for-standard-button">upload_file</mat-icon>
      <span class="text-nunito" translate>
        Update
      </span>
    </button>
  </div>
</div>
