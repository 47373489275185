<div *ngIf="inProgress" class="spinner-overlay d-flex justify-content-center align-items-center">
  <div class="mt-3 text-center text-white d-flex align-items-center flex-column">
    <mat-spinner [diameter]="60"></mat-spinner>
    <div class="processText"><span translate>Bitte warten...</span></div>
  </div>
</div>
<div style="padding-bottom: 3rem;">
    <mat-icon (click)="closePopup()" style="font-size: 25px; position: relative; cursor: pointer; float: right; margin-top: 10px; margin-bottom: 10px;">
        close
    </mat-icon>
</div>
<br>
<div>
  <canvas id="myCanvas" #meter ></canvas>
</div>
