import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from './data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  sideBarExpansion = new BehaviorSubject<boolean>(false);
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public dataService: DataService) {
  }

}
