<div class="row" style="width: 100% !important;height: 100%;margin-right: 0px;">
  <div class="col-md-12" style="display: flex;flex-direction: column;justify-content: center;">
    <div mat-dialog-title style="font-size: 24px; font-family: poppins-medium;display: flex;justify-content: center;" translate>
      Dein Profil ist unvollständig
    </div>
    <span style="font-family: nunito;display: flex;justify-content: center;" translate>
      Bitte stelle sicher, dass du die folgenden Felder ausgefüllt hast.
    </span>
    <mat-dialog-content style="left:20px;position: relative;">
      <mat-list *ngFor='let item of data' style="font-size: 18px;display: flex;justify-content: center;">
        <mat-list-item> {{item}} </mat-list-item>
      </mat-list>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: center; padding: 34px">
      <button mat-dialog-close mat-flat-button color="primary">
        {{'Verstanden' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
