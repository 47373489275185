<div *ngIf="showSpinner"
     class="d-flex justify-content-center align-items-center w-100 h-100 position-fixed bg-dark"
     style="z-index: 11; opacity: 0.6;">
  <mat-spinner></mat-spinner>
</div>

<div class="d-flex justify-content-between flex-wrap p-3 sticky-head">
  <div class="mt-2">
    <button class="border-radius-20" mat-stroked-button (click)="navbarService.createTopic()">
      <mat-icon class="material-icons-outlined mr-1">
        add
      </mat-icon>
      {{'New Page'|translate}}
    </button>
  </div>

  <div>
    <mat-form-field appearance="outline" class="mr-3">
      <mat-label translate>Search for…</mat-label>
      <input (keyup)="applyFilter();" [(ngModel)]="searchQuery" autocomplete="off" matInput
        placeholder="{{'Titel' | translate}}">
    </mat-form-field>

    <mat-form-field appearance="outline" class="mr-3">
      <mat-label translate>Sort</mat-label>
      <mat-select (selectionChange)="applyFilter()" [(ngModel)]="sort_option" value="last_edited_on">
        <mat-option value="added_on">{{'Newest'|translate}}</mat-option>
        <mat-option value="last_edited_on">{{'Last edited'|translate}}</mat-option>
        <mat-option value="title">{{'Titel'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Filter</mat-label>
      <mat-select (selectionChange)="applyFilter();" [(ngModel)]="filterValue" class="sort-input">
        <mat-option value="all"><span translate>Alle</span></mat-option>
        <mat-option value="drafts"><span translate>Drafts</span></mat-option>
        <mat-option value="published"><span translate>Veröffentlicht</span></mat-option>
        <mat-option value="external"><span translate>External</span></mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<!--In case of no pages, show text-->
<mat-card-subtitle class="mx-2 text-nunito"
                   *ngIf="topics.length == 0"
                   translate>
  You have not created a page yet
</mat-card-subtitle>

<!--all topics-->
<mat-grid-list cols="1" rowHeight="100">
  <mat-grid-tile *ngFor="let topic of filteredTopics" class="topic-tile">
    <!--cover on hover-->
    <div class="position-absolute w-100 h-100 thumbnail-div"
         *ngIf="topic.thumbnail"
         [style.background-position]="topic.object_position"
         [style.background-image]="'url(' + topic.thumbnail + ')'">
    </div>
    <!--emoji and title-->
    <mat-card style="height: inherit;"
              class="topic-card w-100 bg-transparent mx-3 d-flex justify-content-between align-items-center">
      <mat-card-header aria-label="view topic"
                       style="height: 50px; max-width: 70%">
        <div class="emoji-box cursor-pointer" mat-card-avatar (click)="topicAction(topic.id, 'edit')">
          <span class="emoji-icon class-to-hide-this">{{topic.emoji}}</span>
          <mat-icon class="material-icons-outlined class-to-show-this">edit</mat-icon>
        </div>
        <mat-card-title [title]="topic.title" class="text-nunito cursor-pointer"
                        (click)="viewTopic(topic);"
                        style="max-width: 50vw;"
                        aria-label="click to view topic">
          {{topic.title}}
        </mat-card-title>
        <mat-card-subtitle class="text-nunito d-flex align-items-center">
          <span class="badge mr-2 d-none d-md-block"
                style="background-color: #F8F8F8; color: #7A7A7D; border: 1px solid #e0dfdf; font-size: small;">
            {{ topic.n_sections }}&nbsp;{{ topic.n_sections == 1 ? ('section' | translate) : ('sections' | translate) }}
          </span>

          <span matTooltipPosition="below"
                matTooltip="{{'Erstellt am'|translate}}&nbsp;{{topic.added_on | date:'dd.MM.y'}}">
            {{'Updated' | translate}}
            {{utilityService.timeSince(topic.last_edited_on, "ago", false) |async}}
          </span>

          <!--external icon-->
          <mat-icon *ngIf="topic.is_external && !topic.is_access_key_needed" class="material-icons-outlined ml-1"
                    matTooltip="{{'External' | translate}}"
                    style="vertical-align: middle;">public
          </mat-icon>

          <!--unlisted icon-->
          <mat-icon *ngIf="topic.is_external && topic.is_access_key_needed" class="material-icons-outlined ml-1"
                    matTooltip="{{'Unlisted' | translate}}"
                    style="vertical-align: middle;">vpn_lock
          </mat-icon>
        </mat-card-subtitle>
      </mat-card-header>

      <mat-card-content class="card-content">
        <span *ngIf="!topic.is_draft" class="badge mx-2 text-nunito font-medium"
              style="background-color: #DCF4E8; color: darkgreen;" translate>
          Veröffentlicht
        </span>
        <span *ngIf="topic.is_draft" class="badge mx-2 text-nunito font-medium"
              style="background-color: #FEE2E2; color: darkred;" translate>
          Draft
        </span>
        <button [matMenuTriggerFor]="menu" aria-label="more options"
                class="mx-2" mat-icon-button>
          <mat-icon>more_horiz</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button (click)="topicAction(topic.id, 'edit');"
                  aria-label="edit topic" mat-menu-item>
            <mat-icon class="material-icons-outlined">edit</mat-icon>
            <span translate>Bearbeiten</span>
          </button>
          <button (click)="topicAction(topic.id, 'publish');" *ngIf="topic.is_draft" aria-label="publish topic"
                  mat-menu-item>
            <mat-icon class="material-icons-outlined">cloud_done</mat-icon>
            <span translate>Veröffentlichen</span>
          </button>
          <button (click)="topicAction(topic.id, 'draft');" *ngIf="!topic.is_draft"
                  aria-label="draft topic"
                  mat-menu-item>
            <mat-icon>cloud_off</mat-icon>
            <span translate>Save as draft</span>
          </button>
          <button (click)="topicAction(topic.id, 'external');"
                  *ngIf="!topic.is_external"
                  [disabled]="is_external_sharing_disabled" aria-label="publish topic"
                  mat-menu-item>
            <mat-icon class="material-icons-outlined">public</mat-icon>
            <span translate>
              Enable external sharing
            </span>
          </button>
          <button (click)="topicAction(topic.id, 'internal');" *ngIf="topic.is_external"
                  aria-label="draft topic"
                  mat-menu-item>
            <mat-icon>public_off</mat-icon>
            <span translate>Deactivate external sharing</span>
          </button>
          <hr class="m-0">
          <!--Only drafts can be re-assigned by a creator-->
          <button (click)="changeUploader(topic)"
                  matTooltipPosition="left"
                  [disabled]="!(topic.is_draft && is_creator)"
                  [matTooltip]="is_creator ? (topic.is_draft ? '' : ('Only drafts can be transferred to others'|translate)) : ('You need Creator rights'|translate)"
                  mat-menu-item>
            <mat-icon>drive_file_move_outline</mat-icon>
            <span translate>Transfer</span>
          </button>

          <!--a topic can be duplicated as longs as user is a creator-->
          <button (click)="duplicateTopic(topic.id)"
                  matTooltipPosition="left"
                  mat-menu-item>
            <mat-icon>content_copy</mat-icon>
            <span translate>Duplicate</span>
          </button>

          <button (click)="deleteTopic(topic.id, topic.title);" aria-label="delete topic" mat-menu-item>
            <mat-icon>delete_outline</mat-icon>
            <span translate>Löschen</span>
          </button>
        </mat-menu>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>

