import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserProfileWithName} from "../../models/video/video.interface";

@Component({
  selector: 'app-members-list-popup',
  templateUrl: './members-list-popup.component.html',
  styleUrls: ['./members-list-popup.component.scss']
})
export class MembersListPopupComponent {
  members: UserProfileWithName[] = [];
  title: string = "";

  constructor(public dialogRef: MatDialogRef<MembersListPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.members = data.res;
    this.title = data.title;
  }
}


