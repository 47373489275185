<div *ngIf="inProgress" class="spinner-overlay d-flex justify-content-center align-items-center">
    <div class="mt-3 text-center text-white d-flex align-items-center flex-column">
      <mat-spinner [diameter]="60"></mat-spinner>
      <div class="processText"><span translate>Bitte warten...</span></div>
    </div>
  </div>
<div class="container-fluid m-sm-3 mt-1 mb-1" style="display: flex;">
    <mat-icon class="mr-1 material-icons-outlined" color="primary" mat-icon>
        local_activity
    </mat-icon>
    <span class="h4 fontStyle mr-4" translate>Meine Aktivität</span>
    <button style="margin-top: -3px;" (click)="deleteActivity()" mat-stroked-button
            matTooltip="{{ 'Delete My Activity' | translate }}"
            *ngIf="authService.currentUser?.is_profile_completed">
      <mat-icon class="mr-1" mat-list-icon>
        delete_outline
      </mat-icon>
      {{ "Delete My Activity" | translate }}
    </button>
  </div>
<div [fluidHeight]="250" class="activity-list">
    <table mat-table [dataSource]="dataSource" >
        <ng-container matColumnDef="activity_code">
            <th mat-header-cell *matHeaderCellDef >{{'Titel' | translate}}
            </th>
            <td class="pointer" mat-cell *matCellDef="let element" [matTooltip]="element.activity_code">
                {{element.activity_code ? element.activity_code : '-'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="supporting_text">
            <th mat-header-cell *matHeaderCellDef >{{'Supporting Text' | translate}}
            </th>
            <td class="pointer" mat-cell *matCellDef="let element" [matTooltip]="element.supporting_text">
                {{element.supporting_text ? element.supporting_text : '-'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="created_on">
            <th *matHeaderCellDef mat-header-cell>{{'Erstellt am' | translate}}</th>
            <td *matCellDef="let element" [matTooltip]="element.created_on ? (element.created_on | date:'dd.MM.y HH:mm') : 'null'" mat-cell>
              {{element.created_on | date:'dd.MM.y HH:mm'}}
            </td>
          </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator *ngIf=" activityCurrentPage > 0" [length]="activityCurrentPage"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="updateActivites($event)"
    ></mat-paginator>
</div>
