<h2 class="fontStyle" mat-dialog-title translate>Select Clypp</h2>

<!--search field-->
<mat-form-field appearance="outline" class="w-100" style="line-height: inherit;">
  <mat-label translate>Search for…</mat-label>
  <mat-icon (click)="showSearchResults();" class="pointer" matSuffix>search</mat-icon>
  <input [formControl]="myControl"
         [matAutocomplete]="auto"
         aria-label="search input"
         autocomplete="off"
         matInput
         maxlength="100"
         (keyup.enter)="showSearchResults();"
         placeholder="{{'Titel' | translate}}" type="text">
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete">
  <mat-option (onSelectionChange)="showUserVideos(option)"
              *ngFor="let option of filteredOptions | async"
              [title]="option.string"
              [value]="option.name">
    <mat-icon *ngIf="option.type == 'user'">person_outline</mat-icon>
    {{option.name}}
  </mat-option>
</mat-autocomplete>

<mat-dialog-content style="height: 60vh;">
  <mat-card-subtitle *ngIf="search_videos.length == 0 && !showSpinner" translate>
    No results. Potentially search for other terms.
  </mat-card-subtitle>

  <!--videos list with image and desc-->
  <div *ngFor="let video of search_videos"
       class="video-tile p-2 d-flex justify-content-between">
    <!--on hover, show gif, handle onerror case-->
    <!--on click, play video-->
    <!--image, click to play -->
    <div class="thumbnail-container mr-3 cursor-pointer"
         [mat-dialog-close]="{ id: video.id, title: video.title }">
      <img [src]="video.thumbnail"
         class="thumbnail" height="60" style="aspect-ratio: 16/9;">
      <img [src]="video.gif" class="gif" height="60" style="aspect-ratio: 16/9;">
    </div>

    <!-- desc, click to select -->
    <div class="d-flex flex-column w-fill-available cursor-pointer"
         [mat-dialog-close]="{ id: video.id, title: video.title }">
      <b [title]="video.title" class="text-nunito mb-0"
         style="overflow: hidden; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;">
        {{video.title}}
      </b>
      <mat-card-subtitle class="text-nunito mb-0" [title]="video.uploader.email">
        {{video.uploader.first_name}} {{video.uploader.last_name}}
      </mat-card-subtitle>
      <mat-card-subtitle [title]="video.added_on">
        <span *ngIf="video.views == 1">
          {{video.views | number}}&nbsp;{{'Aufruf' |translate}}&nbsp;{{utilityService.timeSince(video.added_on) | async }}
        </span>
        <span *ngIf="video.views > 1 || video.views == 0">
          {{video.views | number}}&nbsp;{{'Aufrufe' |translate}}&nbsp;{{utilityService.timeSince(video.added_on) | async }}
        </span>
      </mat-card-subtitle>
    </div>

    <div class="d-flex justify-content-end align-items-center">
      <button mat-icon-button
              (click)="editVideo(video.id);"
              title="{{'Bearbeiten'|translate}}"
              *ngIf="video.uploader.id == current_user_id"
              color="primary">
        <mat-icon class="material-icons-outlined">
          edit
        </mat-icon>
      </button>
      <mat-icon *ngIf="video.is_held_for_review"
                class="text-gray"
                title="{{ 'Wird überprüft' | translate }}">
        pending_actions
      </mat-icon>
      <mat-icon *ngIf="video.is_restricted"
                class="material-icons-outlined text-gray"
                title="{{'Restricted visibility inside the workspace'|translate}}">
        network_locked
      </mat-icon>
      <mat-icon *ngIf="video.is_external && !video.is_access_key_needed"
                class="text-gray"
                title="{{'Public'|translate}}">
        public
      </mat-icon>
      <mat-icon *ngIf="video.is_external && video.is_access_key_needed"
                class="text-gray"
                title="{{'Unlisted'|translate}}">
        vpn_lock
      </mat-icon>
    </div>
  </div>
</mat-dialog-content>
<mat-progress-bar *ngIf="showSpinner" class="w-100" mode="indeterminate">
</mat-progress-bar>
