<div *ngFor="let element of authService.banners"
     [style.background-color]="element.bg_color"
     class="d-flex mb-1">
  <div *ngIf="element.language.slice(0, 2) == translateService.currentLang"
       class="d-flex justify-content-center text-nunito w-100"
       style="min-height: 1.75rem; font-size: medium">
    <span style="padding-top: 0.25rem;" [innerHTML]="element.site_notice">
    </span>&nbsp;
    <mat-icon style="margin-top: 4px; font-size: larger;"
              matTooltip="{{'Displayed until' | translate}} {{element.valid_till | date:'dd.MM.y HH:mm'}}">
      schedule
    </mat-icon>
    <!--    <a *ngIf="element.supporting_video" href="{{element.supporting_video }}" style=" padding-top: 0.25rem; color: white;"-->
    <!--       target="_blank" translate>{{element.supporting_video }}-->
    <!--    </a>-->
  </div>
</div>
