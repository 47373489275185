import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Subtitle, VideoView} from "../models/video/video.interface";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-my-videos-video-popup',
  templateUrl: './my-videos-video-popup.component.html',
  styleUrls: ['./my-videos-video-popup.component.scss']
})
export class MyVideosVideoPopupComponent implements OnInit {
  title: string = "";
  message: string = undefined;  // message to display in case of an issue

  constructor(public dialogRef: MatDialogRef<MyVideosVideoPopupComponent>,
              private translateService: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: VideoView) {
  }

  ngOnInit(): void {
    this.title = this.data.title;


    // initiate video source
    if (this.data.processed_file) {
      // pass
    } else {
      // there is neither video file nor processed file
      // blank page would appear
      this.message = this.translateService.instant('This Clypp is still processing');
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
