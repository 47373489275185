<div class="closebutton">
  <h2 class="fontStyle" translate>Los geht's!</h2>
  <button (click)="closeDialog()" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <span class="fontNormal w-100 mb-1" translate>
    Welche Art von Video möchtest du aufnehmen?
  </span>
  <div class="d-flex justify-content-center">
    <div style="min-width: 75%; height:100%; margin-top: 25px;">

      <div style="margin-bottom:10px;" [matTooltip]="disable_webcam ? disabled_button_tooltip : ''">
        <button (click)="postAPiType('WC')" class="mat-button1" mat-stroked-button style="width:100%" type="submit"
                [disabled]="disable_webcam" [ngClass]="{'disabled-button': disable_webcam}">
          <mat-icon class="iconStyle material-icons-outlined">videocam</mat-icon>
          <span class="span-float fontNormal">Webcam</span>
        </button>
      </div>

      <div style="margin-bottom:10px;height: 15%;" [matTooltip]="disable_sc_bo ? disabled_button_tooltip : ''">
        <button (click)="postAPiType('SC')" class="mat-button1" mat-stroked-button style="width:100%" type="submit"
                [disabled]="disable_sc_bo" [ngClass]="{'disabled-button': disable_sc_bo}">
          <mat-icon class="iconStyle material-icons-outlined">screen_share</mat-icon>
          <span class="span-float fontNormal" translate>Bildschirmaufnahme</span>
        </button>
      </div>

      <div style="margin-bottom:10px;height: 15%;" [matTooltip]="disable_sc_bo ? disabled_button_tooltip : ''">
        <button (click)="postAPiType('BO')" class="mat-button1" mat-stroked-button style="width:100%" type="submit"
                [disabled]="disable_sc_bo" [ngClass]="{'disabled-button': disable_sc_bo}">
          <mat-icon class="iconStyle material-icons-outlined">picture_in_picture_alt</mat-icon>
          <span class="span-float fontNormal" translate>Bildschirm + Webcam</span>
        </button>
      </div>

      <div style="margin-bottom:10px;height: 15%;">
        <button (click)="postAPiType('UP')" class="mat-button1" mat-stroked-button style="width:100%" type="submit">
          <mat-icon class="iconStyle material-icons-outlined">cloud_upload</mat-icon>
          <span class="span-float fontNormal" translate>Video hochladen</span>
        </button>
      </div>

      <div>
        <hr [attr.data-content]="'oder' | translate" class="hr-text">
      </div>

      <div class="mb-2">
        <button (click)="postAPiType('PR')" class="" mat-flat-button style="width:100%"
                type="submit" color="primary"
                matTooltip="{{'The combined mode allows you to record multiple Clypps in different formats and to re-arrange their order'|translate}}"
                matTooltipPosition="after">
          <mat-icon class="iconStyle material-icons-outlined">movie</mat-icon>
          <span class="span-float fontNormal" translate>Combined mode</span>
        </button>
      </div>

      <div>
        <hr [attr.data-content]="'oder' | translate" class="hr-text">
      </div>

      <div style="margin-bottom:10px;height: 15%;">
        <button (click)="navbarService.openVideoRequestDialog()" class="mat-button1" mat-stroked-button style="width:100%" type="submit">
          <mat-icon class="iconStyle material-icons-outlined">maps_ugc</mat-icon>
          <span class="span-float fontNormal" translate>Request Clypp</span>
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
