<div class="d-flex justify-content-between align-items-baseline">
  <h2 class="fontStyle">
    {{ "Manage content of:" | translate }}
    {{data.first_name}}&nbsp;{{data.last_name}}
  </h2>
  <button mat-dialog-close mat-icon-button>
    <mat-icon>
      close
    </mat-icon>
  </button>
</div>

<span class="text-nunito mb-3" translate *ngIf="!(n_published_videos || n_published_playlists)">
  This user does not have any published content.
</span>


<span class="text-nunito mb-3" translate *ngIf="(n_published_videos || n_published_playlists)">
  What do you wish to do with the content?
</span>

<mat-tab-group mat-align-tabs="start" preserveContent>
  <mat-tab label="Clypps ({{n_published_videos}})">
    <section class="table-container">
      <table [dataSource]="videoDataSource" class="w-100" mat-table>
        <!-- checkbox -->
        <ng-container matColumnDef="select">
          <th *matHeaderCellDef mat-header-cell style="min-width: 50px">
            <mat-checkbox (change)="$event ? toggleAllVideos() : null"
                          [checked]="videoSelection.hasValue() && isAllVideosSelected()"
                          [indeterminate]="videoSelection.hasValue() && !isAllVideosSelected()"
                          aria-label="select all checkbox">
            </mat-checkbox>
          </th>
          <td *matCellDef="let row" mat-cell>
            <mat-checkbox (change)="$event ? videoSelection.toggle(row) : null"
                          (click)="$event.stopPropagation()"
                          [checked]="videoSelection.isSelected(row)" aria-label="select a video">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- video title -->
        <ng-container matColumnDef="title">
          <th *matHeaderCellDef class="d-flex justify-content-between align-items-center" mat-header-cell
              translate>
            <span translate>Select all</span>
            <mat-form-field appearance="outline" class="">
              <mat-label translate>Search for…</mat-label>
              <input (click)="stopToggle($event)" (focus)="stopToggle($event)"
                     (keyup)="applyFilter($event, 'video')" autocomplete="off" matInput
                     placeholder="{{'Titel' | translate}}">
            </mat-form-field>
          </th>
          <td *matCellDef="let element" mat-cell>
            <a href="/view/{{element.id}}" target="_blank">
              {{element.title}}
            </a>
          </td>
        </ng-container>

        <!-- table rows => display data -->
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr (click)="videoSelection.toggle(row)" *matRowDef="let row; columns: displayedColumns;" mat-row>
        </tr>
      </table>
    </section>
  </mat-tab>

  <mat-tab label="{{'Pages'|translate}} ({{n_published_topics}})">
    <section class="table-container">
      <table [dataSource]="topicDataSource" class="w-100" mat-table>
        <!-- checkbox -->
        <ng-container matColumnDef="select">
          <th *matHeaderCellDef mat-header-cell style="min-width: 50px">
            <mat-checkbox (change)="$event ? toggleAllTopics() : null"
                          [checked]="topicSelection.hasValue() && isAllTopicsSelected()"
                          [indeterminate]="topicSelection.hasValue() && !isAllTopicsSelected()"
                          aria-label="select all pages checkbox">
            </mat-checkbox>
          </th>
          <td *matCellDef="let row" mat-cell>
            <mat-checkbox (change)="$event ? topicSelection.toggle(row) : null"
                          (click)="$event.stopPropagation()"
                          [checked]="topicSelection.isSelected(row)" aria-label="select a page">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- topic title -->
        <ng-container matColumnDef="title">
          <th *matHeaderCellDef class="d-flex justify-content-between align-items-center" mat-header-cell
              translate>
            <span translate>Select all</span>
            <mat-form-field appearance="outline" class="">
              <mat-label translate>Search for…</mat-label>
              <input (click)="stopToggle($event)" (focus)="stopToggle($event)"
                     (keyup)="applyFilter($event, 'topic')" autocomplete="off" matInput
                     placeholder="{{'Titel' | translate}}">
            </mat-form-field>
          </th>
          <td *matCellDef="let element" mat-cell>
            <a href="/pages/view/{{element.id}}" target="_blank">
              {{element.title}}
            </a>
          </td>
        </ng-container>

        <!-- table rows => display data -->
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr (click)="topicSelection.toggle(row)" *matRowDef="let row; columns: displayedColumns;" mat-row>
        </tr>
      </table>
    </section>
  </mat-tab>

  <mat-tab label="Playlists ({{n_published_playlists}})">
    <section class="table-container">
      <table [dataSource]="playlistDataSource" class="w-100" mat-table>
        <!-- checkbox -->
        <ng-container matColumnDef="select">
          <th *matHeaderCellDef mat-header-cell style="min-width: 50px">
            <mat-checkbox (change)="$event ? toggleAllPlaylists() : null"
                          [checked]="playlistSelection.hasValue() && isAllPlaylistsSelected()"
                          [indeterminate]="playlistSelection.hasValue() && !isAllPlaylistsSelected()"
                          aria-label="select all checkbox">
            </mat-checkbox>
          </th>
          <td *matCellDef="let row" mat-cell>
            <mat-checkbox (change)="$event ? playlistSelection.toggle(row) : null"
                          (click)="$event.stopPropagation()"
                          [checked]="playlistSelection.isSelected(row)" aria-label="select a playlist">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- video title -->
        <ng-container matColumnDef="title">
          <th *matHeaderCellDef class="d-flex justify-content-between align-items-center" mat-header-cell
              translate>
            <span translate>Select all</span>
            <mat-form-field appearance="outline" class="">
              <mat-label translate>Search for…</mat-label>
              <input (click)="stopToggle($event)" (focus)="stopToggle($event)"
                     (keyup)="applyFilter($event, 'playlist')" autocomplete="off" matInput
                     placeholder="{{'Titel' | translate}}">
            </mat-form-field>
          </th>
          <td *matCellDef="let element" mat-cell>
            <a href="/playlistView/{{element.id}}" target="_blank">
              {{element.title}}
            </a>
          </td>
        </ng-container>

        <!-- table rows => display data -->
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr (click)="playlistSelection.toggle(row)" *matRowDef="let row; columns: displayedColumns;"
            mat-row>
        </tr>
      </table>
    </section>
  </mat-tab>
</mat-tab-group>

<div mat-dialog-actions class="justify-content-between">
  <mat-form-field appearance="outline" class="d-block w-50 mr-3">
    <mat-label translate>Re-assign selected content to</mat-label>
    <mat-select [(ngModel)]="selected_user_id">
      <mat-option *ngFor="let user of candidates" [value]="user.id">
        {{ user.user.first_name + " " + user.user.last_name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!--enabled = (videos.length or playlist.length) and user is selected-->
  <button (click)="reassignContent()"
          [disabled]="!((videoSelection.selected.length || topicSelection.selected.length || playlistSelection.selected.length) && selected_user_id)"
          class="w-25 align-self-center cursor-pointer" mat-stroked-button>
    {{ "Re-assign" | translate }}
  </button>
</div>
