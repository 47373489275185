import {Component, OnInit, ViewChild} from '@angular/core';
import {MatGridList} from '@angular/material/grid-list';
import {environment} from '../../environments/environment';
import {AuthService} from '../services/auth.service';
import {DataService} from '../services/data.service';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {PlaylistCard} from '../models/playlist/playlist';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-playlist-viewall-page',
  templateUrl: './playlist-viewall-page.component.html',
  styleUrls: ['./playlist-viewall-page.component.scss']
})
export class PlaylistViewallPageComponent implements OnInit {

  playlists: PlaylistCard[] = [];
  backendUrl: string;
  page: number = 1;
  maxPages: number = 1;
  showSpinner: boolean = false;
  isFeatured: boolean = false;
  query_subscription: Subscription = null;

  constructor(public dataService: DataService,
              public route: ActivatedRoute,
              private router: Router,
              public authService: AuthService) {
  }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    this.query_subscription = this.route.queryParams.subscribe(params => {
      if ('is_featured' in params) {
        this.isFeatured = JSON.parse(params.is_featured);
      }
      this.page = 1;
      this.playlists = [];
      this.getAllPlaylists();  // fetch new page 1 results
    });
  }

  getAllPlaylists(): void {
    // load results if max pages limit is not reached
    if (this.page <= this.maxPages && !this.showSpinner) {
      let queryConstruct = `user/playlists/?page=${this.page}`;
      if (this.isFeatured) {
        queryConstruct += `&is_featured=${this.isFeatured}`;
      }

      this.showSpinner = true;
      this.dataService.getURL(queryConstruct, {observe: 'body', responseType: 'json'}).subscribe((resp: any) => {
        this.maxPages = Math.ceil(resp.length / resp.pageSize);
        this.playlists.push(...resp['data']);
        this.showSpinner = false;
        this.page = resp.pageIndex + 1;
        // load more videos if we are on page 1
        if (this.page == 2) {
          this.getAllPlaylists();
        }
      }), (err) => {
        console.error(err);
        // this.router.navigate(['/start']);
        this.showSpinner = false;
      };
    }
  }

  ngOnDestroy(): void {
    this.query_subscription.unsubscribe();
  }

  featuredToggle(event) {
    this.isFeatured = event.checked;
    this.router.navigate(['allPlaylists'], {
      queryParams: {
        is_featured: this.isFeatured,
      }
    });
  }
}
