import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef,} from '@angular/material/dialog';
import {DataService} from '../../services/data.service';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from 'src/app/services/auth.service';
import {ManageUserDialogComponent} from 'src/app/manage-user-dialog/manage-user-dialog.component';
import {MiniDetails} from "../../models/video/video.interface";
import {ManagerUser} from "../../dashboard/user-management/user-management.component";
import {UserContentDialogComponent} from "../user-content-dialog/user-content-dialog.component";
import {HttpErrorResponse} from "@angular/common/http";


@Component({
  selector: 'app-user-details-dialog',
  templateUrl: './user-details-dialog.component.html',
  styleUrls: ['./user-details-dialog.component.scss'],
})
export class UserDetailsDialogComponent implements OnInit {
  locationList: MiniDetails[] = [];
  departList: MiniDetails[] = [];
  teamsList: MiniDetails[] = [];
  rolesList: MiniDetails[] = [];

  isGlobalOrCompManager: boolean = false;  // only global or company manager can change anyone's basic details
  teamsManagementText: string = "";
  deptManagementText: string = "";
  userDetailForm;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ManagerUser = null,
    public dialog: MatDialog,
    public dataService: DataService,
    private formBuilder: UntypedFormBuilder,
    public authService: AuthService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<UserDetailsDialogComponent>,
    public snackBar: MatSnackBar
  ) {

    this.translateService.get('Dept_Management', {
      val_1: this.authService.company.dept_alias,
    }).subscribe((res: string) => {
      this.deptManagementText = res;
    });

    this.translateService.get('Teams_Management', {
      val_1: this.authService.company.team_alias,
    }).subscribe((res: string) => {
      this.teamsManagementText = res;
    });
  }

  ngOnInit(): void {
    // load roles
    this.dataService.getURL(`user/company/roles/`).subscribe((res: MiniDetails[]) => {
      this.rolesList = res;
    });

    // load locations
    this.dataService.getURL(`user/company/locations/`).subscribe((res: MiniDetails[]) => {
      this.locationList = res;
    });

    // fetch departments
    this.departList = this.authService.checklist_data.filter(e => e.type == 'dept');
    // this.dataService.getURL<any>('user/company/department/').subscribe((res) => {
    //   this.departList = res;
    // });

    // if there is a dept value
    if (this.data.department) {
      // fetch the teams
      this.dataService.getURL<any>(`user/company/department/${this.data.department}/teams/`)
        .subscribe((res: MiniDetails[]) => {
          this.teamsList = res;
        });
    }

    this.isGlobalOrCompManager = this.authService.userDetails.is_company_manager || this.authService.userDetails.is_global_manager;

    this.userDetailForm = this.formBuilder.group({
      email: [
        {
          value: this.data.user.email,
          disabled: true,
        },
        Validators.required,
      ],
      first_name: [
        {
          value: this.data.user.first_name,
          disabled: !this.isGlobalOrCompManager,
        },
        Validators.required,
      ],
      last_name: [
        {
          value: this.data.user.last_name,
          disabled: !this.isGlobalOrCompManager,
        },
        Validators.required,
      ],
      ip_network: [''],
      location: [this.data.location, Validators.required],
      department: [
        {
          value: this.data.department,
          disabled: !this.isGlobalOrCompManager,
        },
        Validators.required,
      ],
      team: [
        {
          value: this.data.team,
          disabled: !this.authService.userDetails.is_department_manager,
        },
        Validators.required,
      ],
      user_role: [{
        value: this.data.user_role,
        disabled: false
      }],
      // following 5 roles must be disabled as per manager's role
      is_global_manager: [
        {
          value: this.data.is_global_manager,
          disabled: !this.authService.userDetails.is_global_manager,
          // I must be global manager to make anyone global manager
        },
      ],
      is_company_manager: [
        {
          value: this.data.is_company_manager,
          disabled: this.data.is_global_manager || !this.authService.userDetails.is_global_manager,
          // I must be global manager to make anyone company manager
        },
      ],
      is_department_manager: [
        {
          value: this.data.is_department_manager,
          disabled: this.data.is_company_manager || !this.authService.userDetails.is_company_manager,
          // I must be at least company manager to make anyone dept manager
        },
      ],
      is_team_manager: [
        {
          value: this.data.is_team_manager,
          disabled: this.data.is_department_manager || !this.authService.userDetails.is_department_manager,
          // I must be at least dept manager to make anyone team manager
        },
      ],
      is_quality_manager: [
        {
          value: this.data.is_quality_manager,
          disabled: this.data.is_global_manager || !this.authService.userDetails.is_global_manager,
          // I must be global manager to make anyone quality manager
        },
      ],
      profile_pic: [this.data.profile_pic],
      is_approved: [
        {value: this.data.is_approved, disabled: false},
      ],
      is_creator: [
        {value: this.data.is_creator, disabled: false},
      ],
    });
  }

  changeGM(evt) {
    this.userDetailForm.get('is_company_manager').setValue(evt.checked);
    this.userDetailForm.get('is_department_manager').setValue(evt.checked);
    this.userDetailForm.get('is_team_manager').setValue(evt.checked);
    this.userDetailForm.get('is_quality_manager').setValue(evt.checked);
    if (evt.checked) {
      this.userDetailForm.get('is_company_manager').disable();
      this.userDetailForm.get('is_department_manager').disable();
      this.userDetailForm.get('is_team_manager').disable();
      this.userDetailForm.get('is_quality_manager').disable();
    } else {
      this.userDetailForm.get('is_company_manager').enable();
      this.userDetailForm.get('is_department_manager').enable();
      this.userDetailForm.get('is_team_manager').enable();
      this.userDetailForm.get('is_quality_manager').enable();
    }
  }

  changeCM(evt) {
    this.userDetailForm.get('is_department_manager').setValue(evt.checked);
    this.userDetailForm.get('is_team_manager').setValue(evt.checked);
    if (evt.checked) {
      this.userDetailForm.get('is_department_manager').disable();
      this.userDetailForm.get('is_team_manager').disable();
    } else {
      this.userDetailForm.get('is_department_manager').enable();
      this.userDetailForm.get('is_team_manager').enable();
    }
  }

  changeDM(evt) {
    this.userDetailForm.get('is_team_manager').setValue(evt.checked);
    if (evt.checked) {
      this.userDetailForm.get('is_team_manager').disable();
    } else {
      this.userDetailForm.get('is_team_manager').enable();
    }
  }

  departChange() {
    this.dataService.getURL<any>(`user/company/department/${this.userDetailForm.get('department').value}/teams/`)
      .subscribe((res: MiniDetails[]) => {
        this.teamsList = res;
        if (this.teamsList.length) {
          // set first item
          this.userDetailForm.get('team').setValue(this.teamsList[0].id);
        } else {
          // set empty
          this.userDetailForm.get('team').setValue('');
        }
      });
  }

  assertPopup(): void {
    if (this.userDetailForm.invalid) {
      return;
    }
    var formBasic = {
      first_name: this.userDetailForm.value.first_name,
      last_name: this.userDetailForm.value.last_name,
      email: this.data.user.email,
    };

    //Using getRawValue because disabled inp such as is_dept_man was not being sent
    let formData = {
      ...this.userDetailForm.getRawValue()
    };

    delete formData.profile_pic;
    delete formData.first_name;
    delete formData.last_name;
    delete formData.email;

    if (this.isGlobalOrCompManager){
      // save basic data first, then save other data
      this.dataService.patchURL(`manager/users/${this.data.id}/`, formBasic).subscribe((res) => {
        // success, now save other data
        this.saveUserProfile(formData);
      }, (err) => {
        // failed, let user know
        console.error(err);
        this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '',
          {duration: 2500});
      });
    } else {
      // just save other data
      this.saveUserProfile(formData);
    }

    // popup is closed upon successfully saving profile data
  }

  saveUserProfile(formData: any){
    this.dataService.putURL(`manager/users/${this.data.id}/`, formData,
      {observe: 'body', responseType: 'text'}).subscribe((res: string) => {
        if(res.length){
          // there is some response from the BE which should be shown
          window.alert(res);
        }
    }, (err) => {
      console.error(err);
      this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '',
        {duration: 2500});
    }, () => {
      this.dialogRef.close(true);
      // if I am change my own data, then => reload page
      if (this.authService.userDetails.id == this.data.id) {
        location.reload();
      }
    });
  }

  closePopup() {
    this.dialogRef.close();
  }

  blockUnblockUser() {
    let message: string = "";
    const profiles: number[] = [this.data.id];
    let action: string = "unblock";
    if (this.data.user.is_active) {
      // manager is blocking user
      action = 'block';
      message += this.translateService.instant("The user will not be able to log in and perform any action.");
      message += '\n';
      message += this.translateService.instant("However, their content would still be visible to other users.");
      message += '\n\n';
    }
    message += `${this.data.user.first_name} ${this.data.user.last_name} `;
    message += this.translateService.instant("will also be notified about this.");
    message += '\n\n';
    message += this.translateService.instant("Would you like to continue?");

    if (window.confirm(message)) {
      this.dataService.putURL<any>(`manager/users/`, {profiles, action},
        {observe: 'body', responseType: 'text'})
        .subscribe(
          (res) => {
            this.snackBar.open(res, '', {duration: 2000});
            this.dialogRef.close(true);
          },
          (err: HttpErrorResponse) => {
            window.alert(err.error);
            console.error(err);
          });
    }
  }

  deleteUser() {
    const confirmationDialog = this.dialog.open(ManageUserDialogComponent, {
      data: {
        id: this.data.id,
        first_name: this.data.user.first_name,  // needed to pass to another popup
        last_name: this.data.user.last_name
      }
    });
    confirmationDialog.afterClosed().subscribe((dialogResponse: boolean|undefined) => {
      if (dialogResponse) {
        this.dialogRef.close(true);
      }
    });
  }

  manageContent(){
    this.dialog.open(UserContentDialogComponent, {
      // maxWidth: 'none',
      data: {
        id: this.data.id,
        first_name: this.data.user.first_name,
        last_name: this.data.user.last_name
      }
    });
    // no need to handle close case
  }
}

