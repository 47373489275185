export const environment = {
  production: false,
  baseURL: 'https://dev.zesavi.com/',
  backendURL: 'https://dev.zesavi.com/api',
  bucketURL: "https://clypp-thumbnails-bucket.obs.eu-de.otc.t-systems.com/",
  videoSizeLimit: '50MB',
  videoSizeByte: 50 * 1000 * 1000,
  timeoutDuration: 1800, // 1200 for clypp, 1800 for dev
  bodySize: 2000000 // 2 MB
};
