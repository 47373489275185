import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {DataService} from '../services/data.service';
import {HttpErrorResponse} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";


interface Value {
  id: string,
  name: string,
  parentReference: {
    driveId: string
  },
  webUrl: string,
  folder: {
    childCount: number
  },
  file: {
    mimeType: string
  }
}

interface Team {
  id: string,
  displayName: string,
}


@Component({
  selector: 'app-select-onedrive-popup',
  templateUrl: './select-onedrive-popup.component.html',
  styleUrls: ['./select-onedrive-popup.component.scss']
})
export class SelectOnedrivePopupComponent implements OnInit {
  path: string = "me/drive?select=id,name"
  onedrive_token: string = undefined;
  all_items: Value[] = [];
  show_spinner: boolean = false;
  message: string = "";
  all_teams: Team[] = [];
  get_url: string = "onedrive/get-login-url/";
  search_text: string = "";

  constructor(private dataService: DataService,
              private translateService: TranslateService,
              public dialogRef: MatDialogRef<SelectOnedrivePopupComponent>,
  ) {
    // if there is onedrive_token in cookie, then open popup, else open new window
  }

  ngOnInit(): void {

    this.loadTokenAndInitiatePopup();

    if (this.onedrive_token) {
      // do nothing as data is already loaded
    } else {
      this.openLoginPopup();
    }
  }

  // open login popup
  openLoginPopup() {
    this.dataService.getURL(this.get_url, {observe: 'body', responseType: 'text'})
      .subscribe((res: string) => {
        // https://stackoverflow.com/questions/2909645/open-new-popup-window-without-address-bars-in-firefox-ie
        const login_window = window.open(res, '_blank', 'toolbar=no,popup=yes');
        this.message = this.translateService.instant("Upon successful login, please close this open and open again.");
        // wait for login window to close
        const timer = setInterval(() => {
          if (login_window.closed) {
            clearInterval(timer);
            this.loadTokenAndInitiatePopup();
          }
        }, 1000);  // check every 1 second if the window is closed or not
      });
  }

  // load token from local storage or cookie and then load recent items
  loadTokenAndInitiatePopup() {
    // first try to fetch the token from cookie
    if (document.cookie.includes('onedrive_token')) {
      const value = `; ${document.cookie}`;
      const parts = value.split("; onedrive_token=");
      if (parts.length == 2) {
        this.onedrive_token = parts.pop().split(';').shift();
      }
    } else {
      // try in local storage
      if (localStorage.getItem("onedrive_token")) {
        this.onedrive_token = localStorage.getItem("onedrive_token");
      }
    }

    if (this.onedrive_token) {
      // load items: select recent files
      this.loadRecent();
      this.loadTeams();
    }
  }

  loadRecent() {
    this.search_text = "";
    this.path = "me/drive/recent?select=remoteItem($select=id,name,webUrl,file,folder,parentReference)";
    this.loadItems(true);
  }

  loadShared() {
    this.search_text = "";
    this.path = "me/drive/sharedWithMe?select=remoteItem";
    this.loadItems(true);
  }

  loadMyFiles() {
    this.search_text = "";
    this.path = "me/drive/root/children?select=id,name,webUrl,file,folder,parentReference";
    this.loadItems(false);
  }

  // load all the teams this user is part of
  loadTeams() {
    const path: string = "teams?select=id,displayName";
    this.dataService.postURL(this.get_url, {onedrive_token: this.onedrive_token, path: path},
      {observe: 'body', responseType: 'json'}).subscribe((res: any) => {
        this.all_teams = res['value'];
    }, (err) => {
        // failed to load teams
        console.error(err);
        // check if token is expired
        if (err.error.includes('token is expired')) {
          localStorage.removeItem('onedrive_token');
          this.onedrive_token = undefined;
          this.openLoginPopup();
        }
    });
  }

  // list children of that team
  teamSelected(id: string) {
    this.search_text = "";
    this.path = `groups/${id}/drive/items/root/children?select=id,name,webUrl,file,folder,parentReference`;
    this.loadItems(false);
  }

  loadItems(remoteItem: boolean = false) {
    // if remote item is true, data is stored one level down
    this.all_items = [];
    this.show_spinner = true;

    this.dataService.postURL(this.get_url, {onedrive_token: this.onedrive_token, path: this.path},
      {observe: 'body', responseType: 'json'}).subscribe((res) => {
        if (remoteItem) {
          for (let item of res['value']) {
            this.all_items.push(item['remoteItem']);
          }
        } else {
          this.all_items = res['value'];
        }

      this.message = "";  // clear message
      this.show_spinner = false;
    }, (err: HttpErrorResponse) => {
      this.all_items = [];
      this.message = err.error;
      console.error(err);
      this.show_spinner = false;
    }, () => {
      this.show_spinner = false;
    });
  }

  // when user selects a file, load its eTag id, create an embed link and close the popup
  fileSelected(file: Value) {
    const item_path = `drives/${file.parentReference.driveId}/items/${file.id}/?select=name,eTag,webUrl`;
    // do not change above /?select=name,eTag,webUrl, because BE creates an activity if path in such a format
    this.show_spinner = true;

    if (file.name.endsWith('.xlsx')) {
      // Excel files can not embed easily
      this.fileSelectedWithThumbnail(file);
      return;
    }

    this.dataService.postURL(this.get_url, {onedrive_token: this.onedrive_token, path: item_path},
      {observe: 'body', responseType: 'json'}).subscribe((res: any) => {
        // res contains:
        // {
        //     "eTag": "\"{C50FF092-198F-42E7-8909-BAD1E224A1C3},95\"",
        //     "name": "Book12.xlsx",
        //     "webUrl": "https://zesavi0-my.sharepoint.com/personal/h_singla_zesavi_com/_layouts/15/Doc.aspx?sourcedoc=%7BC50FF092-198F-42E7-8909-BAD1E224A1C3%7D&file=Book12.xlsx&action=default&mobileredirect=true"
        // }

        // webUrl can also be like https://zesavi0.sharepoint.com/sites/intern/Freigegebene%20Dokumente/All%20members/Emergency%20Contact%20List.pdf

        try {
          // extract eTag
          let eTag = res['eTag'];
          eTag = eTag.split('{')[1];
          eTag = eTag.split('}')[0];

          // extract baseUrl
          const webUrlParts = res['webUrl'].split('/')

          // create embed url
          const embedUrl = `https://${webUrlParts[2]}/${webUrlParts[3]}/${webUrlParts[4]}/_layouts/15/embed.aspx?UniqueId=${eTag}`;
          const block =
            `<a href="${file.webUrl}" target="_blank">${file.name}</a><br>` +
            `<iframe src="${embedUrl}" title="${res['name']}" ` +
            `style="width: 100%; aspect-ratio: 16/9;" allowfullscreen></iframe><br>`;
          this.dialogRef.close(block);
        }
        catch (err) {
          console.error(err);
          // then try to attach thumbnail
          this.fileSelectedWithThumbnail(file);
        }
    }, (err) => {
        this.show_spinner = false;
        console.error(err);
        // then try to attach thumbnail
        this.fileSelectedWithThumbnail(file);
    })
  }

  // not in use
  fileSelectedWithThumbnail(file: Value) {
    const thumbnail_path = `drives/${file.parentReference.driveId}/items/${file.id}/thumbnails/0/large`;
    this.show_spinner = true;

    this.dataService.postURL(this.get_url, {onedrive_token: this.onedrive_token, path: thumbnail_path},
      {observe: 'body', responseType: 'json'}).subscribe((res: any) => {
        const block = `<a href="${file.webUrl}" target="_blank">
                                <span>${file.name}</span>
                                <br>
                                <img src="${res['url']}" alt="${file.name}">
                              </a><br>`;
        this.dialogRef.close(block);
    }, (err: HttpErrorResponse) => {
        this.show_spinner = false;
        console.error(err);
        this.message = err.error;
    })
  }

  getSearchResult() {
    // https://learn.microsoft.com/en-us/graph/api/driveitem-search?view=graph-rest-1.0&tabs=http
    // can only search for items
    this.path = `me/drive/root/search(q='${encodeURIComponent(this.search_text)}')?select=id,name,webUrl,file,folder,parentReference`;
    this.loadItems();
  }

  // update the path and load the sub items
  folderSelected(folder: Value) {
    this.path = `drives/${folder.parentReference.driveId}/items/${folder.id}/children?select=id,name,webUrl,file,folder,parentReference`;
    this.loadItems();
  }
}
