<div class="m-3 d-flex align-items-center" style="flex-flow: wrap; min-height: 36px;">
  <mat-icon class="mr-1 material-icons-outlined" color="primary">
    thumb_up
  </mat-icon>
  <span class="fontStyle mr-4" style="font-size: x-large;" translate>
    Clypps I like
  </span>
</div>

<div class="container-fluid">
  <!--container fluid adds left-right padding 15px, width 100% and margin auto-->
  <mat-grid-list #grid (window:resize)="onResize($event)" [cols]="cols" [gutterSize]="'1rem'" class="mt-2"
                 rowHeight="320px">
    <mat-grid-tile *ngFor="let video of videos ">
      <app-video-card [backendUrl]="backendUrl" [videoObject]="video"
                      class="VideoCardStyle"></app-video-card>
    </mat-grid-tile>
  </mat-grid-list>
  <br>
  <mat-paginator (page)="updateVideos($event)" *ngIf="videoCurrentPage > 0" [length]="videoCurrentPage"
                 [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"></mat-paginator>
</div>
