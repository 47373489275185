import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from "../services/utility.service";
import { TopicCard } from "../models/video/video.interface";
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-topic-card',
  templateUrl: './topic-card.component.html',
  styleUrls: ['./topic-card.component.scss']
})
export class TopicCardComponent {
  @Input('topic_object') topic_object: TopicCard;
  @Input('routeUrl') routeUrl: string = "pages/view/";
  show_language_label: boolean = false;

  constructor(private router: Router,
    public utilityService: UtilityService,
    private translateService: TranslateService,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    // show language label or not
    if (this.authService.userDetails) {
      // logged in case
      if (this.authService.userDetails.email_language.slice(0, 2) != this.topic_object.language.slice(0, 2)) {
        this.show_language_label = true;
      }
    }
    else {
      // public case
      if (this.translateService.currentLang != this.topic_object.language.slice(0, 2)) {
        this.show_language_label = true;
      }
    }
  }

  viewTopic(topic_id: string) {
    this.router.navigate([this.routeUrl, topic_id]);
  }

}
