import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './guards/auth-guard.service';
import {IsSignedInGuardService} from './guards/is-signed-in-guard.service';
import {LoginComponent} from './login/login.component';
import {SignUpComponent} from './sign-up/sign-up.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {QuickwinOverviewComponent} from './quickwin-overview/quickwin-overview.component';
import {QuickwinViewComponent} from './quickwin-view/quickwin-view.component';
import {HistoryPageComponent} from './history-page/history-page.component';
import {UserProfileComponent} from './shared/user-profile/user-profile.component';
import {PublicVideoViewComponent} from './public-video-view/public-video-view.component';
import {PlaylistsComponent} from './playlists/playlists.component';
import {FavoriteVideoComponent} from './favorite-video/favorite-video.component';
import {ViewAllCommentsComponent} from './view-all-comments/view-all-comments.component';
import {SearchedContentComponent} from './searched-content/searched-content.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {VideoManagementComponent} from './dashboard/video-management/video-management.component';
import {UserManagementComponent} from './dashboard/user-management/user-management.component';
import {GlobalAdministrationComponent} from './dashboard/global-administration/global-administration.component';
import {
  CompanyManagementComponent
} from './dashboard/global-administration/company-management/company-management.component';
import {AnalyticsDashboardComponent} from './dashboard/analytics-dashboard/analytics-dashboard.component';
import {CreatePlaylistComponent} from './playlists/create-playlist/create-playlist.component';
import {
  CreatePlaylistMyVidoesComponent
} from './playlists/create-playlist-my-vidoes/create-playlist-my-vidoes.component';
import {PlaylistViewComponent} from './playlist-view/playlist-view.component';
import {GroupsComponent} from './groups/groups.component';
import {TeamDeptGroupsViewComponent} from './groups/team-dept-groups-view/team-dept-groups-view.component'
import {GroupsViewComponent} from './groups/groups-view/groups-view.component';
import {FriendOrganisationViewComponent} from './friend-organisation-view/friend-organisation-view.component';
import {VideoCreationOverallComponent} from './video-creation-overall/video-creation-overall.component';
import {VideoTrimmerComponent} from './quickwin-creation/video-trimmer/video-trimmer.component';
import {VideoEditPageComponent} from './video-edit-page/video-edit-page.component';
import {
  LocationPositionComponent
} from './dashboard/global-administration/location-position/location-position.component';
import {VideoRequestDashboardComponent} from './video-request-dashboard/video-request-dashboard.component';
import {CompanyRegistrationComponent} from './company-registration/company-registration.component';
import {CompanyWiseVideoComponent} from './company-wise-video/company-wise-video.component';
import {InternalVideoComponent} from './internal-video/internal-video.component';
import {MyActivitiesComponent} from './my-activities/my-activities.component';
import {TagsViewComponent} from './tags-view/tags-view.component';
import {VideoEmbedComponent} from "./video-embed/video-embed.component";
import {UserViewComponent} from "./user-view/user-view.component";
import {VideoMetaPageComponent} from "./video-meta-page/video-meta-page.component";
import {ClyppsPageComponent} from './clypps-page/clypps-page.component';
import {PlaylistViewallPageComponent} from './playlist-viewall-page/playlist-viewall-page.component';
import {EditTopicsComponent} from './edit-topics/edit-topics.component';
import {TopicsOverviewComponent} from './topics-overview/topics-overview.component';
import {ViewTopicsComponent} from './view-topics/view-topics.component';
import {MyLibrariesComponent} from './my-libraries/my-libraries.component';
import {MyVideosComponent} from "./my-videos/my-videos.component";
import {inIframe} from "./login/login.component";
import {TopicPageManagementComponent} from './dashboard/topic-page-management/topic-page-management.component';
import {ContentDashboardComponent} from './dashboard/content-dashboard/content-dashboard.component';
import {PlaylistManagementComponent} from './dashboard/playlist-management/playlist-management.component';
import { TopicViewAllComponent } from './topic-view-all/topic-view-all.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'create-video/:id/review',
    component: VideoMetaPageComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Überprüfen', sideBar: false, topBar: false}
  },
  {
    path: 'create-video/:id/record',
    component: VideoCreationOverallComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Erstellen', sideBar: false, topBar: false}
  },
  {
    path: 'create-video/:id/edit',
    component: VideoTrimmerComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Bearbeiten', sideBar: false, topBar: false}
  },
  {
    path: 'create-video/:id/trim',
    component: VideoEditPageComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Bearbeiten', sideBar: false, topBar: false}
  },
  {
    path: 'create-video/:param',
    component: VideoCreationOverallComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Erstellen'}
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {title: 'Anmelden', sideBar: false, topBar: true, footer: true}
  },
  {
    path: 'signUp',
    component: SignUpComponent,
    canActivate: [IsSignedInGuardService],
    data: {title: 'Register', sideBar: false, topBar: true, footer: true}
  },

  {
    path: 'companyRegistration',
    component: CompanyRegistrationComponent,
    canActivate: [IsSignedInGuardService],
    data: {title: 'Organisation Registrieren', sideBar: false, topBar: true, footer: true}
  },

  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent,
    canActivate: [IsSignedInGuardService],
    data: {title: 'Reset Password', sideBar: false, topBar: true, footer: true}
  },
  {
    path: 'start',
    component: QuickwinOverviewComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Start', sideBar: true, topBar: true}
  },
  {
    path: 'embed/video/:id',
    component: VideoEmbedComponent,
    data: {title: 'View Clypp', sideBar: false, topBar: false}
  },
  {
    path: 'publicVideoView/:id',
    component: PublicVideoViewComponent,
    data: {title: 'View Clypp', sideBar: false, topBar: true}
  },
  {
    path: 'search',
    component: SearchedContentComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Search results'}
  },

  {
    path: 'history',
    component: HistoryPageComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Verlauf'}
  },
  {
    path: 'library',
    component: MyLibrariesComponent,
    canActivate: [AuthGuardService],
    data: {title: 'My Library'},
    children: [
      {
        path: '',
        redirectTo: 'clypps',
        pathMatch: 'full'
      },
      {
        path: 'clypps',
        component: MyVideosComponent,
        canActivate: [AuthGuardService],
        data: {title: 'My Library'},
      },
      {
        path: 'pages',
        component: TopicsOverviewComponent,
        canActivate: [AuthGuardService],
        data: {title: 'Pages'},
      },
      {
        path: 'playlists',
        component: PlaylistsComponent,
        canActivate: [AuthGuardService],
        data: {title: 'Playlists'},
      }
    ]
  },
  {
    path: 'playlistView/:id',
    component: PlaylistViewComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Playlist'}
  },
  {
    path: 'createPlaylist/:param',
    component: CreatePlaylistComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Playlist erstellen'}
  },
  {
    path: 'createPlaylistMyVideos/:param',
    component: CreatePlaylistMyVidoesComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Playlist bearbeiten'}
  },
  {
    path: 'favVideos',
    component: FavoriteVideoComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Likes'}
  },
  {
    path: 'public/:company_id',
    component: CompanyWiseVideoComponent,
    data: {title: 'Public', sideBar: false, topBar: true}

  },
  {
    path: 'internal',
    component: InternalVideoComponent,
    canActivate: [AuthGuardService],
    data: {title: 'All Clypps', sideBar: false, topBar: false}

  },
  {
    path: 'user_profile',
    component: UserProfileComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Mein Profil'}
  },
  {
    path: 'view/:id',
    component: QuickwinViewComponent,
    canActivate: [AuthGuardService],
    data: {title: 'View Clypp'}
  },
  {
    path: 'viewComment',
    component: ViewAllCommentsComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Kommentare'}
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Dashboard'},
    children: [
      {
        path: '',
        redirectTo: 'content',
        pathMatch: 'full'
      },
      {
        path: 'content',
        component: ContentDashboardComponent,
        children: [
          {
            path: '',
            redirectTo: 'clypps',
            pathMatch: 'full'
          },
          {
            path: 'clypps',
            component: VideoManagementComponent,
            canActivate: [AuthGuardService],
            data: { title: 'All Clypps' },
          },
          {
            path: 'pages',
            component: TopicPageManagementComponent,
            canActivate: [AuthGuardService],
            data: { title: 'All Pages' },
          },
          {
            path: 'playlists',
            component: PlaylistManagementComponent,
            canActivate: [AuthGuardService],
            data: { title: 'All Playlists' },
          }
        ]
      },
      {
        path: 'user',
        component: UserManagementComponent,
        canActivate: [AuthGuardService],
        data: { title: 'Dashboard' },
      },
      {
        path: 'global',
        component: GlobalAdministrationComponent,
        canActivate: [AuthGuardService],
        data: { title: 'Dashboard' },
      },
      {
        path: 'company',
        component: CompanyManagementComponent,
        canActivate: [AuthGuardService],
        data: { title: 'Dashboard' },
      },
      {
        path: 'analytics',
        component: AnalyticsDashboardComponent,
        canActivate: [AuthGuardService],
        data: { title: 'Dashboard' },
      }
    ]
  },
  {
    path: 'requests',
    component: VideoRequestDashboardComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Request Clypp'}
  },
  {
    path: 'groups',
    component: GroupsComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Gruppen'}
  },
  {
    path: 'groups/:id',
    component: GroupsViewComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Gruppen'}
  },

  {
    path: 'group/:param',
    component: TeamDeptGroupsViewComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Gruppen'}
  },
  {
    path: 'partnership/:id',
    component: FriendOrganisationViewComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Partner content'}
  },
  {
    path: 'tag/:id',
    component: TagsViewComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Keyword'}
  },
  {
    path: 'loc',
    component: LocationPositionComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Location'}
  },
  {
    path: 'activities',
    component: MyActivitiesComponent,
    canActivate: [AuthGuardService],
    data: {title: 'My Activities'}
  },
  {
    path: 'clypps',
    component: ClyppsPageComponent,
    canActivate: [AuthGuardService],
    data: {title: 'All Clypps'}
  },
  {
    path: 'allPlaylists',
    component: PlaylistViewallPageComponent,
    canActivate: [AuthGuardService],
    data: {title: 'All Playlists'}
  },
  {
    path: 'user/:id',
    component: UserViewComponent,
    canActivate: [AuthGuardService],
    data: {title: 'All Clypps'}
  },
  {
    path: 'pages/edit/:id',
    component: EditTopicsComponent,
    canActivate: [AuthGuardService],
    data: {title: 'Bearbeiten'}
  },
  {
    path: 'pages/view/:id',
    component: ViewTopicsComponent,
    // canActivate: [AuthGuardService],
    // if we are not authenticated, then hide sideBar
    // if we are in iframe, then hide topBar
    data: {
      title: 'View',
      sideBar: false,
      topBar: !inIframe()
    }
  },
  {
  path: 'pages/all',
  component: TopicViewAllComponent,
  canActivate: [AuthGuardService],
  data:{title: 'All Topics'}
  },
  {
    path: '**',
    redirectTo: '',
    canActivate: [AuthGuardService]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() {
  }
}
