<div class="m-3 d-flex align-items-center" style="flex-flow: wrap;">
  <mat-icon class="mr-1" color="primary">
    history
  </mat-icon>
  <span class="fontStyle mr-4" translate style="font-size: x-large;">Verlauf</span>
  <button (click)="deleteHistory()" mat-stroked-button class="border-radius-20"
          matTooltip="{{ 'Delete history' | translate }}">
    <mat-icon class="mr-1">
      delete_outline
    </mat-icon>
    {{ "Delete history" | translate }}
  </button>
</div>

<div>
  <mat-grid-list #grid (window:resize)="onResize($event)" [cols]="cols" [gutterSize]="'1rem'" class="mt-2"
                 rowHeight="320px">
    <mat-grid-tile *ngFor="let v of videos">
      <app-video-card-history [backendUrl]="backendUrl" [videoObject]="v">
      </app-video-card-history>
    </mat-grid-tile>
  </mat-grid-list>
  <br>
  <mat-paginator (page)="updateVideos($event)" *ngIf=" videoCurrentPage > 0"
                 [length]="videoCurrentPage"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize"
  ></mat-paginator>
</div>
