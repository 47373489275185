import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from 'src/app/services/data.service';
import {MembersListPopupComponent} from '../../../shared/members-list-popup/members-list-popup.component';
import {AuthService} from "../../../services/auth.service";
import {LocationPosition} from "../../../models/interfaces/auth-response";


@Component({
  selector: 'location-position',
  templateUrl: './location-position.component.html',
  styleUrls: ['./location-position.component.scss']
})
export class LocationPositionComponent implements OnInit {
  locationDataSource: MatTableDataSource<LocationPosition>;
  displayedColumns: string[] = ['name', 'users', 'delete'];
  all_locations: LocationPosition[] = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('locTableSort') public locTableSort: MatSort;

  authorised: boolean = false;  // only global or company manager can change data

  constructor(private dialog: MatDialog,
              private authService: AuthService,
              private dataService: DataService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.authorised = this.authService.userDetails.is_global_manager || this.authService.userDetails.is_company_manager;
    this.loadLocations();
  }

  loadLocations() {
    this.dataService.getURL<LocationPosition[]>('user/company/locations/').subscribe((res) => {
      this.all_locations = res;
      this.initiateLocations();
    });
  }

  initiateLocations(){
    delete this.locationDataSource;
    this.locationDataSource = new MatTableDataSource(this.all_locations);
    this.locationDataSource.sort = this.locTableSort;
  }

  addLocation(){
    // top 2 managers can add a location
    const message = this.translateService.instant("Please provide a name:");
    let name = window.prompt(message, '');
    if(name){
      name = name.trim().slice(0, 99);
      if(name){
        this.dataService.postURL<LocationPosition>('user/company/locations/', {name: name},
          {observe: 'body', responseType: 'json'}).subscribe((res) => {
            this.all_locations.push(res);
            this.initiateLocations();
            this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
        }, (err) => {
          if (err.status == 400) {
            // invalid body or duplicate
            this.snackBar.open(this.translateService.instant('Already exists'), '', {duration: 2500});
          } else {
            // 500 status code
            console.error(err);
            this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
          }
        });
      }
    }
  }

  renameLocation(location: LocationPosition){
    if (!this.authorised) {
      return;
    }
    const message = this.translateService.instant('Please provide a new name:');
    let name = window.prompt(message, location.name);
    if(name){
      // not undefined
      name = name.trim().slice(0, 99);
      if (name) {
        // not empty, send call, it will be trimmed and shortened to 50 characters in the BE
        this.dataService.putURL(`user/company/locations/${location.id}/`, {name: name}, {
          observe: 'body',
          responseType: 'json'
        }).subscribe((res: LocationPosition) => {
          location.name = res.name;  // update table
          this.all_locations.find(el => el.id == res.id).name = res.name;  // update array
          this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
        }, (err) => {
          if (err.status == 400) {
            // invalid body or location exists
            this.snackBar.open(this.translateService.instant('Already exists'), '', {duration: 2500});
          } else {
            // 500 status code
            console.error(err);
            this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
          }
        });
      }
    }
  }

  deleteLocation(location: LocationPosition) {
    if (location.users) {
      const errorMsg = this.translateService.instant("Error: There are users in this object");
      this.snackBar.open(errorMsg, '', {duration: 2500});
    } else {
      if (window.confirm(this.translateService.instant("Bist du sicher?"))) {
        this.dataService.deleteURL(`user/company/locations/${location.id}/`).subscribe(res => {
          this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2500});
          const deleted_loc_index = this.all_locations.findIndex(el => el.id == location.id);
          this.all_locations.splice(deleted_loc_index, 1);
          this.initiateLocations();
        }, (err) => {
          console.error(err);
          this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
        });
      }
    }
  }

  userCountLocations(value): void {
    if (!this.authorised) {
      let message = this.translateService.instant("You need global or company management rights");
      this.snackBar.open(message, '', {duration: 2000});
      return;
    }

    if (value.users != 0) {
      this.dataService.getURL<any>(`user/company/locations/${value.id}/?members=true`).subscribe((res) => {
        const dialogRef = this.dialog.open(MembersListPopupComponent, {
          width: "40vw",
          minWidth: "350px",
          height: "80vh",
          data: {
            res,
            adminBadgeTooltip: '',
            title: this.translateService.instant("Mitwirkende"),
          } // 1 = visible for dept , 0 = visible for team , -1 = invisible
        });
      });
    }
  }


}
