import { Component, OnInit } from '@angular/core';
import { NavItem } from '../../interfaces/nav-item';

@Component({
  selector: 'app-content-dashboard',
  templateUrl: './content-dashboard.component.html',
  styleUrls: ['./content-dashboard.component.scss']
})
export class ContentDashboardComponent implements OnInit {
  routePath: NavItem[] = [];

  constructor() {
    this.routePath = [
      {
        link: 'clypps',
        name: 'Clypps',
        icon: 'play_circle'
      },
      {
        link: 'pages',
        name: 'Pages',
        icon: 'sticky_note_2'
      },
      {
        link: 'playlists',
        name: 'Playlists',
        icon: 'playlist_play'
      }]
  }

  ngOnInit(): void {
  }

}
